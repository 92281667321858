import React from "react";
import makeStyles from "@mui/styles/makeStyles";

type Props = {
  children: React.Node,
  tab: string,
  name: string
};

const useStyles = makeStyles((theme) => ({
  tabContent: {
    background: theme.palette.background.mainBg,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  tabWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

const ContentTab = ({
  children,
  tab,
  name,
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.tabContent}
    >
      {tab === name && (
        <div className={classes.tabWrapper}>
          {children}
        </div>
      )}
    </div>
  );
};

export default ContentTab;
