// @flow
import {
  put,
  call,
  debounce,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { changeTableItemsTotalAmount, setTableData } from "@fas/ui-framework/lib/redux/actions/table";
import setLoading from "../../actions/loading";
import { fetchTopOffersList } from "../../services/topOffersApi";
import { GET_TOPOFFERS_LIST_SAGA, TOPOFFERS_TABLE } from "../../helpers/constants/topOffers";

export function* getTopOffers(): Saga<void> {
  try {
    yield put(setLoading(TOPOFFERS_TABLE, true));

    const page: number = yield select(getTablePage, TOPOFFERS_TABLE);
    const limit: number = yield select(getTablePageSize, TOPOFFERS_TABLE);
    const sorting: Sorting = yield select(getTableSorting, TOPOFFERS_TABLE);
    const filters: Filters = yield select(getTableFilters, TOPOFFERS_TABLE);

    const {
      data: {
        data,
        count,
      },
    } = yield call(fetchTopOffersList, {
      page,
      limit,
      sorting,
      filters,
    });

    yield put(setTableData(TOPOFFERS_TABLE, data));
    yield put(changeTableItemsTotalAmount(TOPOFFERS_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch top offers", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(TOPOFFERS_TABLE, false));
  }
}

export default function* watchGetTopOffers(): Saga<void> {
  yield debounce(1000, GET_TOPOFFERS_LIST_SAGA, (getTopOffers: Function));
}
