// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import ProductCompanyFormContainer from "../../containers/ProductCompanyForm";

function ProductCompanyForm() {
  const store = useMemo(() => configureStore());

  return (
    <Provider store={store}>
      <NotificationsContainer />
      <ProductCompanyFormContainer />
    </Provider>
  );
}

export default ProductCompanyForm;
