// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

type Props = {
  globalCap: number,
  globalCounter: number,
};

type Classes = {
  globalCounterSuccess: { [string]: * },
  globalCounterDanger: { [string]: * },
  text: { [string]: * },
};

const useStyles: () => Classes = makeStyles((): Classes => ({
  globalCounterSuccess: {
    color: "#2EB000",
  },
  globalCounterDanger: {
    color: "#C80022",
  },
  text: {
    fontSize: "inherit",
    marginRight: 4,
  },
}));

const GlobalCapWrapper: StatelessFunctionalComponent<Props> = ({
  globalCap,
  globalCounter,
}: Props) => {
  const classes: Classes = useStyles();

  return (
    (globalCap || globalCap === 0) && (
      <Box display="flex">
        <Typography className={classes.text}>{globalCap}</Typography>
        <Typography
          className={clsx(
            classes.text,
            globalCounter < globalCap ? classes.globalCounterSuccess : classes.globalCounterDanger
          )}
        >
          {`(${globalCounter || 0})`}
        </Typography>
      </Box>
    )
  );
};

export default React.memo<Props>(GlobalCapWrapper);
