// @flow
import {
  select,
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import {
  getManageAffiliatePaymentInfo,
  getManageAffiliatePersonalInfo,
} from "../../selectors/manageAffiliate";
import {
  SetAffType, setPaymentInfo, setPersonalInfo,
} from "../../actions/manageAffiliate";
import { getAffiliateByid } from "../../services/affiliateLinksApi";
import { mapFetchedAffiliateFields, mapPaymentInfoFields } from "../../actions/manageAffiliate/utils";
import type { GetAffiliateSaga } from "../../actions/manageAffiliate";
import { GET_AFFILIATE_SAGA } from "../../helpers/constants/manageAffiliate";

export function* makeFetch(action: GetAffiliateSaga): Saga<void> {
  const { payload }: GetAffiliateSaga = action;
  const personalInfo = yield select(getManageAffiliatePersonalInfo);
  const paymentInfo = yield select(getManageAffiliatePaymentInfo);
  try {
    yield put(setLoading("getPersonalInfo", true));

    const response = yield call(getAffiliateByid, payload);

    const sanitizedPersonalInfo = mapFetchedAffiliateFields(personalInfo, response);
    yield put(setPersonalInfo(sanitizedPersonalInfo));
    const sanitizedPaymentInfo = mapPaymentInfoFields(paymentInfo, response);
    yield put(setPaymentInfo(sanitizedPaymentInfo));
    yield put(SetAffType(sanitizedPersonalInfo.type));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getPersonalInfo", false));
  }
}

export default function* watch(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_AFFILIATE_SAGA, (makeFetch: Function));
}
