// @flow
import {
  GET_CRM_TEMPLATES_LIST_SAGA,
  DELETE_CRM_TEMPLATES_SAGA,
} from "../../helpers/constants/crm";

export type GetCrmTemplatesListSaga = {|
  type: typeof GET_CRM_TEMPLATES_LIST_SAGA
|};

export type DeleteCrmTemplatesSaga = {|
  type: typeof DELETE_CRM_TEMPLATES_SAGA,
|};

export type Actions = GetCrmTemplatesListSaga
  | DeleteCrmTemplatesSaga;

export const getCrmTemplatesListSaga: () => GetCrmTemplatesListSaga = () => ({
  type: GET_CRM_TEMPLATES_LIST_SAGA,
});

export const deleteCrmTemplatesSaga: () => DeleteCrmTemplatesSaga = () => ({
  type: DELETE_CRM_TEMPLATES_SAGA,
});
