// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CHANGE_STATUS_POTENTIAL_PARTNER_SAGA, POTENTIAL_PARTNER_TABLE } from "../../helpers/constants/potentialPartner";
import {
  getPotentialPartnerListSaga,
  type ChangeStatusPotentialPartnerSagaType,
} from "../../actions/potentialPartner/actions";
import { saveMassAdjustApi } from "../../services/potentialPartnerApi";
import type { PotentialPartnerType } from "../../containers/PotentialPartner/types/PotentialPartner.types";
import type { State } from "../../pages/PotentialPartner";

export function* makeFetch({ status }: ChangeStatusPotentialPartnerSagaType): Saga<void> {
  try {
    yield put(setLoading(POTENTIAL_PARTNER_TABLE, true));
    const selections: PotentialPartnerType[] = yield select((
      state: State
    ): PotentialPartnerType[] => getTableSelections(state, POTENTIAL_PARTNER_TABLE));

    yield call(saveMassAdjustApi, selections.map(({ id }: PotentialPartnerType): { id: string, status: string } => ({
      id,
      status,
    })));

    yield put(addNotification({ message: "Status changed", severity: "success" }));
    yield put(setTableSelection(POTENTIAL_PARTNER_TABLE, []));
    yield put(getPotentialPartnerListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to change status", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(POTENTIAL_PARTNER_TABLE, false));
  }
}

export default function* watchChangePotentialPartnerStatus(): Saga<void> {
  yield takeEvery(CHANGE_STATUS_POTENTIAL_PARTNER_SAGA, (makeFetch: Function));
}
