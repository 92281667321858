import type { FiltersType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const defaultCrBoosterFilters: FiltersType = {
  offer: null,
  offerSite: "",
  country: "",
  utmContent: "",
  s3: "",
};
