// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { REMOVE_AFFILIATE_LINK_SAGA } from "../../helpers/constants/affiliateLinks";
import { deleteAffiliateLink } from "../../services/affiliateLinksApi";
import { setLoader, getAffiliateLinksSaga, type RemoveAffiliateLinkSaga } from "../../actions/affiliateLinks";

export function* removeAffiliateLink({ id }: RemoveAffiliateLinkSaga): Saga<void> {
  yield put(setLoader({
    key: "isAffiliateLinksLoaded",
    value: true,
  }));
  try {
    yield call(deleteAffiliateLink, id);
    yield put(addNotification({ message: `Successfully deleted ${id}`, severity: "success" }));
    yield put(getAffiliateLinksSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || `Failed to delete ${id}`, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);

    yield put(setLoader({
      key: "isAffiliateLinksLoaded",
      value: false,
    }));
  }
}

export function* watchRemoveAffiliateLink(): Saga<void> {
  yield takeEvery(REMOVE_AFFILIATE_LINK_SAGA, (removeAffiliateLink: Function));
}
