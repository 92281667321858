// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useCallback,
  useMemo,
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import {
  Table,
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { User } from "@fas/ui-framework/lib/services/user";
import { userService } from "../../services/user";
import {
  getSubratesIndexListSaga,
  getSubratesDictionarySaga,
  saveTemplateSaga,
  setTemplate,
  type GetSubratesIndexListSaga,
  type GetSubratesDictionarySagaType,
  type SaveTemplateSagaType,
} from "../../actions/subrates";
import { getSubratesListTemplate } from "../../selectors/subrates";
import { SUBRATES_INDEX_TABLE } from "../../helpers/constants/subrates";
import type { SubrateDataObjType, TemplateObjType } from "../../reducers/subrates";
import { getSubratesDictionariesDropdownLists } from "../../selectors/dictionaries";
import SubratesActionRow from "../../components/SubratesActionRow";
import MessageInfo from "../../components/MessageBar";
import { getMessage } from "../../selectors/message";
import { setMessage } from "../../actions/message";
import { FactoryModel } from "../../services/templateModels";
import type { DropDownObjType } from "../../reducers/dictionaries";
import type { StateObj } from "../../reducers/message";

const onCreate: () => void = () => {
  window.location = "/subrates/create";
};

const SubratesIndex: StatelessFunctionalComponent<{}> = () => {
  const user: User | null = userService.getUser();

  const template: TemplateObjType = useSelector(getSubratesListTemplate);
  const dropdowns: DropDownObjType = useSelector(getSubratesDictionariesDropdownLists);
  const messageInfo: StateObj = useSelector(getMessage);

  const tableProps: TableProps = useTable(SUBRATES_INDEX_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const { onChangeFilters, filters, selected }: TableProps = tableProps;

  const onGetSubratesIndexList: () => GetSubratesIndexListSaga = () => dispatch(getSubratesIndexListSaga());
  const onGetSubratesDictionary: () => GetSubratesDictionarySagaType = () => dispatch(getSubratesDictionarySaga());
  const onSetTemplateHeaders: (test: TemplateObjType) => void = (test) => {
    dispatch(setTemplate(test));
    onGetSubratesIndexList();
  };
  const onSaveTemplate: (test: TemplateObjType) => SaveTemplateSagaType = (test) => dispatch(saveTemplateSaga(test));
  const onCloseMessage: () => void = () => {
    dispatch(setMessage({ text: "", open: false, severity: "success" }));
  };

  useEffect(() => {
    onGetSubratesDictionary();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      onChangeFilters({ affiliateManager: user.id });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onClone: () => void = useCallback(() => {
    const {
      affId,
      offerId,
      country,
      defaultShaveAmount = "",
      defaultPayoutAmount = "",
    }: SubrateDataObjType = selected[0];
    const affIdParam: string = affId ? `affId=${affId}&` : "";
    const offerIdParam: string = offerId ? `offerId=${offerId}&` : "";
    const countryParam: string = country ? `country=${country}&` : "country=all&";
    const shave: string = defaultShaveAmount || String(defaultShaveAmount) === "0" ? `shaveAmount=${defaultShaveAmount}&` : "";
    const commission: string = defaultPayoutAmount || String(defaultPayoutAmount) === "0" ? `payoutAmount=${defaultPayoutAmount}` : "";

    window.location = `/subrates/create?${affIdParam}${offerIdParam}${countryParam}${shave}${commission}`;
  }, [selected]);

  const tableColumns: Column<SubrateDataObjType>[] = useMemo((): Column<SubrateDataObjType>[] => {
    const columns: Column<SubrateDataObjType>[] = FactoryModel.getAllSubrateAttrApiKey()
      .map((modelKey: string): Column<SubrateDataObjType> => (
        FactoryModel.getSubrateModel(modelKey).getHeaderColProps(filters, dropdowns)));

    return template.headersOrder
      .map((key: string): Column<SubrateDataObjType> => columns
        // $FlowFixMe
        .find((column: Column<SubrateDataObjType>): boolean => column.field === key) || { field: "", label: "" });
  }, [filters, dropdowns, template]);

  return (
    <Box p={3}>
      <MessageInfo
        open={messageInfo.open}
        text={messageInfo.text}
        severity={messageInfo.severity}
        closeMessage={onCloseMessage}
      />
      <SubratesActionRow
        template={template}
        onTemplateChange={onSetTemplateHeaders}
        onSaveTemplate={onSaveTemplate}
        filters={filters}
        onChangeFilters={onChangeFilters}
      />
      <Table
        {...tableProps}
        title="CPA Subrates"
        columns={tableColumns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => false}
        onCreate={onCreate}
        onLoad={onGetSubratesIndexList}
        Actions={(
          <TableActions>
            <TableActionGroup>
              <SimpleTableActionButton
                tooltip="Clone"
                disabled={selected.length > 1}
                onClick={onClone}
                Icon={<FileCopy />}
                data-testid="cloneButton"
              />
            </TableActionGroup>
          </TableActions>
        )}
      />
    </Box>
  );
};

export default SubratesIndex;
