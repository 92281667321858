// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Event,
} from "@mui/icons-material";
import type { NextPaymentDateType } from "./types/NextPaymentDateLabel.types";

type Props = {
  payoutPeriod: $PropertyType<NextPaymentDateType, "payoutPeriod">,
}

type Classes = {
  root: mixed,
}

const useStyles: () => Classes = makeStyles((theme): Classes => ({
  root: {
    background: theme.palette.success.light,
    color: "#FFFFFF",
  },
}));

const NextPaymentDateAvailable: StatelessFunctionalComponent<Props> = ({
  payoutPeriod,
}) => {
  const classes: Classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" className={classes.root} data-testid="next-payment-date-block">
      <Box m={2}>
        <Event fontSize="large" />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
        <Typography variant="h6">{payoutPeriod}</Typography>
        <Typography variant="body1">Next payment date</Typography>
      </Box>
    </Box>
  );
};

export default NextPaymentDateAvailable;
