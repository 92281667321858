// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  reducer as errors,
  initState as initErrorsState,
  type State as ErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import form, { initFormState } from "@fas/ui-framework/lib/redux/reducers/form";
import type { State as FormState } from "@fas/ui-framework/lib/redux/reducers/form/reducer";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";

import mainSaga from "./saga";
import loading, {
  type State as LoadingState,
  type Action as LoadingAction,
  initState as initLoadingState,
} from "../../reducers/loading";

import type { LoadingTypes } from "../../selectors/loading/types";
import { FORM_KEY_SMU_PRODUCT_COMPANY } from "../../helpers/constants/productCompanyForm";

export type State = $ReadOnly<{|
  form: FormState,
  dictionaries: DictionariesState,
  errors: ErrorsState,
  loading: LoadingState<LoadingTypes>,
  notifications: NotificationsState,
|}>

export function mapPageToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_SMU_PRODUCT_COMPANY]: {
        id: "",
        name: "",
        companyType: "customer",
        performers: [],
        isActive: true,
      },
    }),
    dictionaries: initDictionariesState(),
    errors: initErrorsState(),
    loading: initLoadingState({}),
    notifications: initNotificationsState(),
  };
}

export type Actions = LoadingAction<"getPayoutListLoading"> | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  dictionaries,
  errors,
  loading,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPageToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
