// @flow
import {
  takeEvery,
  put,
  call,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_OFFER_GROUPS_LIST_SAGA } from "../../helpers/constants/cpaoffer";
import { setOfferListsField } from "../../actions/cpaoffer";
import { getCpaOfferFormData } from "../../selectors/cpaOfferForm";
import type { RowForm } from "../../reducers/cpaoffer/types";
import { getOfferGroupList } from "../../services/cpaOffersApi";

export function* makeFetch(): Saga<void> {
  yield put(setLoading("isOfferGroupList", true));
  const row: RowForm = yield select(getCpaOfferFormData);
  try {
    const { data } = yield call(getOfferGroupList, row, "groups");
    yield put(setOfferListsField("offerGroup", data.data));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("isOfferGroupList", false));
  }
}

export default function* watchGetOffergroupList(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_OFFER_GROUPS_LIST_SAGA, (makeFetch: Function));
}
