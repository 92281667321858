// @flow
import {
  select,
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { DELETE_POSTBACK_EVENT_SAGA } from "../../helpers/constants/EventsPostback";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";
import { deletePostbackEvent } from "../../services/manageAffiliateApi";
import { getPostbackEventsSaga } from "../../actions/eventsPostback";
import type { DeletePostbackEventSaga } from "../../actions/eventsPostback";

export function* makeFetch(action: DeletePostbackEventSaga): Saga<void> {
  const { eventId } = action;
  const affiliateId = yield select(getManageAffiliateId);

  try {
    yield call(deletePostbackEvent, affiliateId, eventId);
    yield put(addNotification({ message: "Event deleted", severity: "success" }));
    yield put(getPostbackEventsSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchDeletePostback(): Saga<void> {
  // todo add event type here
  yield takeEvery(DELETE_POSTBACK_EVENT_SAGA, (makeFetch: Function));
}
