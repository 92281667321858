// @flow
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_DEFAULT_TEMPLATE_SAGA } from "../../helpers/constants/templates";
import { fetchDefaultTemplate } from "../../services/templatesApi";
import { getReportName } from "../../selectors/templates";
import { setActiveTemplate } from "../../actions/templates";
import type { Template } from "../../reducers/templates";

export function* getDefaultTemplate(): Saga<void> {
  yield put(setLoading("getDefaultTemplate", true));
  try {
    const reportName: string = yield select(getReportName);

    const { template }: { template: Template } = yield call(fetchDefaultTemplate, reportName);

    yield put(setActiveTemplate(template));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch default template", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getDefaultTemplate", false));
  }
}

export function* watchGetDefaultTemplate(): Saga<void> {
  yield takeEvery(GET_DEFAULT_TEMPLATE_SAGA, getDefaultTemplate);
}
