import { MenuItem, TextField } from "@mui/material";
import type React from "react";
import { useTenant } from "./TenantContext";

export default function TenantSwitcher() {
  const { currentTenant, setTenant, tenants } = useTenant();

  if (tenants.length < 2) return null;

  return (
    <TextField
      sx={{
        minWidth: 100,
        position: "absolute",
        top: 12,
        right: 80,
        zIndex: "modal",
        "& .MuiInputBase-root": {
          backgroundColor: "white",
        },
      }}
      select
      value={currentTenant}
      onChange={(e) => setTenant(e.target.value)}
      title="Tenant"
      size="small"
      data-testid="tenant"
    >
      {tenants.map((unit) => (
        <MenuItem key={unit} value={unit}>
          {unit}
        </MenuItem>
      ))}
    </TextField>
  );
}
