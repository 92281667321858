// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectPostbackInfoField } from "../../../../../../selectors/manageAffiliate";
import { setPostbackField } from "../../../../../../actions/postbacks";

type Props = {
  field: string,
  label: string,
};

type UsePostbackField = {
  value: mixed,
  onChange: (mixed) => mixed,
};

export const usePostbackField: () => UsePostbackField = (field) => {
  const dispatch: <A>(A) => A = useDispatch();
  const value: string = useSelector((state) => selectPostbackInfoField(state, field));

  const onChange: (string) => mixed = (v) => dispatch(setPostbackField({ field, value: v }));

  return { value, onChange };
};

const PostbackSwitcher: StatelessFunctionalComponent<Props> = ({ field, label }) => {
  const { value, onChange }: UsePostbackField = usePostbackField(field);

  return (
    <FormControlLabel
      label={label}
      control={(
        <Switch
          data-testid={field}
          checked={!!value}
          onChange={(e) => onChange(Number(e.target.checked))}
          color="primary"
        />
      )}
    />
  );
};

export default PostbackSwitcher;
