// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { SAVE_FORM_DATA_SAGA } from "@fas/ui-framework/lib/redux/constants";
import type { SaveFormDataSagaType } from "@fas/ui-framework/lib/redux/actions/form";
import setLoading from "../../actions/loading/actions";
import { apiMapSaveFormData } from "../../services/form";
import { SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";

export function* makeFetch<T>(action: SaveFormDataSagaType<T>): Saga<void> {
  try {
    yield put(setLoading(SAVE_FORM_DATA_LOADING, true));
    const data = yield select((state) => getFormData(state, action.key));
    yield call(apiMapSaveFormData[action.key], data);

    if (action.redirect) {
      window.location = action.redirect;
    }
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
      const unfieldError = err.response.data.errors.find(({ field }) => !field);
      if (unfieldError) {
        yield put(addNotification({ message: unfieldError.error || unfieldError.message, severity: "error" }));
      }
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to save", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SAVE_FORM_DATA_LOADING, false));
  }
}

export default function* watchSaveFormDataSaga(): Saga<void> {
  yield takeEvery(SAVE_FORM_DATA_SAGA, (makeFetch: Function));
}
