// @flow
import type { Element, StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { type TableProps, useTable } from "@fas/ui-framework/lib/services/table";
import {
  SimpleTableActionButton, Table, TableActionGroup, TableActions,
} from "@fas/ui-core";
import { Edit, GetApp } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { ADVERTISER_INVOICES_TABLE, AVAILABLE_QUERY_FILTER_KEYS } from "../../helpers/constants/advertiserInvoices";
import getColumns from "./getColumns";
import externalRedirect from "../../components/App/ExternalRedirect";
import { downloadAdvertiserInvoicesSaga, getAdvertiserInvoicesListSaga } from "../../actions/advertiserInvoices";
import type { Classes } from "./types/AdvertiserInvoices.types";
import { setQueryFilter } from "../../helpers/query/queryFilter";
import { useDictionaryList } from "../../hoocks/useDictionary";
import { userService } from "../../services/user";

export type Props = $ReadOnly<{||}>;

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    maxWidth: "100px",
  },
  middleTableCol: {
    minWidth: "150px",
    width: "350px",
  },
  longTableCol: {
    maxWidth: "250px",
  },
  veryLongTableCol: {
    minWidth: "110px",
    maxWidth: "350px",
    wordBreak: "break-word",
  },
}));

const AdvertiserInvoicesList: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const tableProps: TableProps = useTable(ADVERTISER_INVOICES_TABLE);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [paymentMethods] = useDictionaryList("advertiser", "paymentMethod");

  const onCreate: (() => void) = () => {
    externalRedirect({ to: "/advertiserInvoices/create" });
  };

  const handleDownload: (() => void) = () => {
    dispatch(downloadAdvertiserInvoicesSaga());
  };

  const onLoad: () => mixed = () => dispatch(getAdvertiserInvoicesListSaga());
  const selectAvailable: () => boolean = () => true;

  useEffect(() => {
    setQueryFilter(tableProps.filters, AVAILABLE_QUERY_FILTER_KEYS);
  }, [tableProps.filters]);

  const canEditPaid: boolean = userService.can(["cpaAdmin.advertiserInvoices.updatePaidInvoice"]);
  const canEdit: boolean = tableProps.selected.every(({ status }) => status !== "paid" || canEditPaid);

  const Actions: Element<typeof TableActions> = (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={tableProps.selected.length > 1 || !canEdit}
          onClick={(): null => externalRedirect({ to: `/advertiserInvoices/${tableProps.selected[0].id}` })}
          Icon={<Edit />}
        />
        <SimpleTableActionButton
          tooltip="Download"
          onClick={handleDownload}
          Icon={<GetApp />}
        />
      </TableActionGroup>
    </TableActions>
  );

  return (
    <Table
      {...tableProps}
      columns={getColumns(classes, paymentMethods)}
      title="Advertiser Invoices"
      onLoad={onLoad}
      onCreate={onCreate}
      rowSelectAvailable={selectAvailable}
      allSelectAvailable={selectAvailable}
      Actions={Actions}
    />
  );
};

export default AdvertiserInvoicesList;
