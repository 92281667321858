// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA } from "../../helpers/constants/manageAffiliate";
import { getManagerListByAffiliateType } from "../../services/manageAffiliateApi";
import type { GetManagersListByAffiliateTypeSaga } from "../../actions/manageAffiliate";
import { setLoader, setManagersList } from "../../actions/manageAffiliate";

export function* makeFetch(action: GetManagersListByAffiliateTypeSaga): Saga<void> {
  const { payload }: GetManagersListByAffiliateTypeSaga = action;
  try {
    yield put(setLoader({
      key: "isManagersListsLoaded",
      value: false,
    }));
    const { data } = yield call(getManagerListByAffiliateType, payload);
    yield put(setManagersList(data.data));
    yield put(setLoader({
      key: "isManagersListsLoaded",
      value: true,
    }));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetManagersListByAffiliateType(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA, (makeFetch: Function));
}
