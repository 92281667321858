import { get } from "lodash";
import type { Path } from "react-hook-form";

type Filters<T> = Partial<Record<Path<T>, string | void>>;

function filter<T extends object>(data: T[], filters: Filters<T>): T[] {
  return data.filter((item) => {
    const keys = Object.keys(filters) as Array<keyof typeof filters>;
    return keys.every((key) => {
      const values: string[] = String(filters[key] !== undefined && filters[key] !== null ? filters[key] : "").split(" ");

      let currentValue = get(item, key, "");
      if (Array.isArray(currentValue)) {
        currentValue = currentValue.join(", ");
      }
      return values.every((value) => String(currentValue).toLowerCase().includes(value.toLowerCase()));
    });
  });
}

export default filter;
