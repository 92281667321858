// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { DeleteOutline, Add } from "@mui/icons-material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { Loader, ComboBox } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { useList } from "../../hoocks/useDictionary";
import { useLoading } from "../../hoocks/useLoading";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { FORM_KEY_CRM_TEMPLATE } from "../../helpers/constants/crm";
import type { State } from "../../pages/CrmTemplates";
import type { Content } from "./CrmTemplatesForm.types";
import { onChangeItem, onAddItem, onRemoveItem } from "./utils";

const emptyContent: Content = { subject: "", content: "", locale: "" };

const Contents = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();

  // $FlowFixMe
  const contents: Content[] = useSelector((state: State) => getFormField(state, FORM_KEY_CRM_TEMPLATE, "content"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const localeTypes: DropDownObjItemType[] = useList("defaultLocale");

  const onChangeContent: (Content[]) => void = (value) => {
    dispatch(setFormData(FORM_KEY_CRM_TEMPLATE, { content: value }));
  };

  return (
    <SetupCard title="Content">
      <Box width={1} mt={1} pb={1}>
        <Loader loading={loading}>
          {contents.map((content: Content, index: number): Node => (
            <Box mb={3}>
              {/* eslint-disable-next-line react/no-array-index-key */}
              <Box mb={1} gap={2} display="flex" alignItems="center" key={index}>
                <ComboBox
                  fullWidth
                  size="small"
                  label="Locale"
                  data-testid={`content-locale-${index}`}
                  onChange={(_, value: string) => {
                    onChangeItem({
                      index,
                      value,
                      fieldName: "locale",
                      handler: onChangeContent,
                      arr: contents,
                    });
                  }}
                  value={content.locale}
                  options={localeTypes}
                />
                <IconButton
                  color="primary"
                  data-testid={`content-delete-${index}`}
                  onClick={() => {
                    onRemoveItem({
                      index,
                      handler: onChangeContent,
                      arr: contents,
                    });
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </Box>
              <Box mb={1}>
                <TextField
                  fullWidth
                  data-testid={`content-subject-${index}`}
                  size="small"
                  label="Subject"
                  value={content.subject}
                  onChange={({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
                    onChangeItem({
                      index,
                      value,
                      fieldName: "subject",
                      handler: onChangeContent,
                      arr: contents,
                    });
                  }}
                />
              </Box>
              <Box mb={1}>
                <TextField
                  fullWidth
                  multiline
                  data-testid={`content-content-${index}`}
                  size="small"
                  label="Content"
                  value={content.content}
                  onChange={({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
                    onChangeItem({
                      index,
                      value,
                      fieldName: "content",
                      handler: onChangeContent,
                      arr: contents,
                    });
                  }}
                />
              </Box>
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              onClick={() => {
                onAddItem({
                  handler: onChangeContent,
                  arr: contents,
                  emptyItem: emptyContent,
                });
              }}
              data-testid="content-add"
            >
              <Add />
              ADD CONTENT
            </Button>
          </Box>
        </Loader>
      </Box>
    </SetupCard>
  );
};

export default Contents;
