// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  debounce,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize, getTableSelections,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  changeTableItemsTotalAmount,
  setTableData,
  setTableSelection,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Offer } from "../../containers/CpaOfferIndex/types/CpaOfferIndex.types";
import { fetchPotentialPartnerList } from "../../services/potentialPartnerApi";
import {
  GET_POTENTIAL_PARTNER_LIST_SAGA,
  POTENTIAL_PARTNER_TABLE,
} from "../../helpers/constants/potentialPartner";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(POTENTIAL_PARTNER_TABLE, true));

    const page: number = yield select(getTablePage, POTENTIAL_PARTNER_TABLE);
    const limit: number = yield select(getTablePageSize, POTENTIAL_PARTNER_TABLE);
    const sorting: Sorting = yield select(getTableSorting, POTENTIAL_PARTNER_TABLE);
    const filters: Filters = yield select(getTableFilters, POTENTIAL_PARTNER_TABLE);
    const selections: Offer[] = yield select(getTableSelections, POTENTIAL_PARTNER_TABLE);

    if (selections.length) {
      yield put(setTableSelection(POTENTIAL_PARTNER_TABLE, []));
    }

    const {
      data: { data, count = 0 },
    }: { data: { data: *[], count: number } } = yield call(fetchPotentialPartnerList, {
      page,
      limit,
      filters,
      sorting,
    });

    yield put(setTableData(POTENTIAL_PARTNER_TABLE, data));
    yield put(changeTableItemsTotalAmount(POTENTIAL_PARTNER_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(POTENTIAL_PARTNER_TABLE, false));
  }
}

export default function* watchGetPotentialPartnerListSaga(): Saga<void> {
  yield debounce(1000, GET_POTENTIAL_PARTNER_LIST_SAGA, (makeFetch: Function));
}
