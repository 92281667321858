// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchPotentialPartnerDropdownList from "../../sagas/getPotentialPartnerDropdownLists";
import watchGetPotentialPartnerListSaga from "../../sagas/getPotentialPartnerList";
import watchChangePotentialPartnerStatus from "../../sagas/changePotentialPartnerStatus";
import watchRemovePotentialPartnerSaga from "../../sagas/removePotentialPartner";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchPotentialPartnerDropdownList),
    call(watchGetPotentialPartnerListSaga),
    call(watchChangePotentialPartnerStatus),
    call(watchRemovePotentialPartnerSaga),
  ]);
}
