// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useRef,
  useEffect,
  type Node,
  type StatelessFunctionalComponent,
  useState,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  TextField,
  InputAdornment,
  Chip,
  Tooltip,
  FormControlLabel,
  Switch,
  Box,
  Alert,
  Collapse,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonalInfoTablet from "../../../PersonalInfoTablet";
import {
  getManagersListByAffiliateTypeSaga,
  setLoader,
  getDropdownsByAffiliateTypeSaga,
} from "../../../../../../actions/manageAffiliate";
import { setTabState } from "../../../../../../services/middlewares/tabSwitchConfirm";
import {
  companyTypesWithOutNameAvailable,
  howFoundOutListWithComment,
} from "../../../../../../helpers/manageAffiliate/affiliateHelper";
import { mapManageAffiliates } from "../../utils";
import { getChangeStatusInfoText } from "./getChangeStatusInfoText";

type Props = {
  title: string,
  name: string,
  editTitle: string,
  currentTablet: string,
  personalInfo: Object,
  lists: Array<any>,
  errors: Object,
  fields: Array<string>,
  handleChangeTablet: () => any,
};

// type State = {};

type Classes = {
  inputField: mixed,
  selectLabel: mixed,
  input: mixed,
  multilineInput: mixed,
  select: mixed,
  helperIcon: mixed,
}

const mapStateToProps = (state) => ({
  isManagersListsLoaded: state.manageAffiliate.getIn(["loaders", "isManagersListsLoaded"]),
  isManageAffLoaders: state.manageAffiliate.get("loaders").toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchManagerList: (affiliateType) => dispatch(getManagersListByAffiliateTypeSaga(affiliateType)),
  setManagersListsLoading: () => dispatch(setLoader({ key: "isManagersListsLoaded", value: true })),
});

const useStyles: () => Classes = makeStyles((theme): Classes => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectLabel: {
    marginLeft: 45,
    zIndex: 1,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  multilineInput: {
    background: theme.palette.background.mainBg,
    marginTop: 10,
    marginBottom: 10,
  },
  select: {
    "&:focus": {
      background: theme.palette.background.mainBg,
    },
  },
  helperIcon: {
    marginLeft: 5,
  },
}));

function useInitialValue(fieldName: string) {
  const { personalInfo: { id, [fieldName]: value } } = useSelector(mapManageAffiliates);
  const submitCount = useSelector((state) => state.manageAffiliate.get("submitCount"));
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [id, submitCount]);

  return val;
}

// eslint-disable-next-line complexity
const ManagerSettings: StatelessFunctionalComponent<Props> = ({
  title,
  name,
  editTitle,
  currentTablet,
  handleChangeTablet,
  personalInfo,
  lists,
  errors,
  fields,
  setAffiliateField,
  affiliateId,

  fetchManagerList,
  setManagersListsLoading,
  isManagersListsLoaded,
}: Props) => {
  const classes: Classes = useStyles();
  const dispatch = useDispatch();

  const companyNameRef = useRef(null);
  const declineReasonDescriptionRef = useRef(null);
  const topCountriesRef = useRef(null);
  const howFoundOutRef = useRef(null);
  const refSrcCommentRef = useRef(null);
  const refererUrlRef = useRef(null);
  const registrationUrlRef = useRef(null);
  const companyUrlRef = useRef(null);

  const {
    id,
    companyName,
    declineReasonDescription,
    topCountries,
    manager,
    revenue,
    howFoundOut = {
      label: "",
      value: "",
    },
    refSrc,
    trafficType,
    topVerticals,
    declineReasonId,
    type: affiliateType,
    currency,
    status,
    refSrcComment,
    refererUrl,
    registrationUrl,
    sourceComplianceStatus,
    fraudStatus,
    topVerticalsComment,
    howFoundOutComment,
    howFoundOutGroup,
    companyType,
    companyTypeComment,
    companyUrl,
  }: $PropertyType<Props, "personalInfo"> = personalInfo;

  const declineStatusInfoText = getChangeStatusInfoText(useInitialValue("status"), status);
  const isOpenStatusDeclineInfo = Boolean(id) && Boolean(declineStatusInfoText);

  const {
    managers = [],
    revenues = [],
    trafficTypes = [],
    declineReasons = [],
    affiliateTypes = [],
    currencyType = [],
    statuses = [],
    fraudStatus: fraudStatuses = [],
    topVerticals: topVerticalsList = [],
    howFoundOut: howFoundOutList = {
      "default": {
        topLevel: [],
      },
    },
    companyType: companyTypeList = [],
  }: $PropertyType<Props, "lists"> = lists;

  const howFoundOutListByType = affiliateType
    ? howFoundOutList[affiliateType] || howFoundOutList.default
    : {
      topLevel: [],
    };
  const { topLevel, ...otherItems } = howFoundOutListByType;
  let howFoundOutListGrouped = topLevel.map((i) => ({ label: i, value: i, group: "" }));
  Object.keys(otherItems).forEach((key) => {
    howFoundOutListGrouped = [
      ...howFoundOutListGrouped,
      ...howFoundOutListByType[key].map((i) => ({ label: i, value: i, group: key }))];
  });

  const howFoundOutValue = howFoundOutGroup ? `${howFoundOutGroup}/${howFoundOut}` : howFoundOut;

  const handleChangeAffiliateType = (e, item) => {
    if (item) {
      setAffiliateField("type", item);
    }
    else {
      setAffiliateField("type", "");
    }
    setAffiliateField("manager", { id: 0, name: "" });
    setAffiliateField("companyType", "");
    setAffiliateField("companyTypeComment", "");
    setAffiliateField("howFoundOut", "");
    setAffiliateField("howFoundOutGroup", "");
    setAffiliateField("howFoundOutComment", "");
    setAffiliateField("topVerticals", "");
    setAffiliateField("topVerticalsComment", "");
    setAffiliateField("trafficType", []);
    setAffiliateField("companyName", "");
    setAffiliateField("companyUrl", "");
    setAffiliateField("revenue", "");
  };

  const refSrces = lists.refSrc ? lists.refSrc : [];

  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name: fieldName } = e.target;
    setAffiliateField(fieldName, value);
  };

  const handleChangeCheckbox = (e) => {
    const { name: fieldName, checked: value } = e.target;
    setAffiliateField(fieldName, value);
  };

  const handleRenderTags = (tagValue, getTagProps) => tagValue.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      label={option}
    />
  ));
  const handleRenderInput: (*) => Node = (params) => (
    <TextField
      {...params}
      placeholder={trafficType.length ? "" : "Traffic type"}
      error={!!errors.trafficType}
      helperText={errors.trafficType && errors.trafficType}
      variant="outlined"
      label="Traffic type"
      data-testid="trafficType"
      InputLabelProps={{
        error: false,
      }}
      InputProps={{
        ...params.InputProps,
        classes: { adornedStart: classes.input },
        startAdornment: (
          <>
            <InputAdornment
              className={classes.helperIcon}
              position="start"
            >
              <Tooltip title="Some text">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
            {params.InputProps.startAdornment}
          </>
        ),
      }}
      FormHelperTextProps={{
        style: { marginLeft: 0, marginRight: 0 },
      }}
    />
  );

  const renderInputComponent = (field, placeholder) => (params): Node => {
    const { InputProps } = params;
    return (
      <TextField
        {...params}
        placeholder={placeholder}
        variant="outlined"
        label={placeholder}
        error={!!errors[field]}
        helperText={errors[field] && errors[field]}
        InputProps={{
          ...InputProps,
          classes: { adornedStart: classes.input },
          startAdornment: (
            <>
              <InputAdornment
                className={classes.helperIcon}
                position="start"
              >
                <Tooltip title="Some text">
                  <HelpOutlineIcon />
                </Tooltip>
              </InputAdornment>
              {InputProps.startAdornment}
            </>
          ),
        }}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
      />
    );
  };

  const handleTrafficTypeChange = (e, item, reason, details) => {
    if (reason === "removeOption") {
      setAffiliateField("trafficType", item);
    }
    else if (reason === "clear") {
      setAffiliateField("trafficType", []);
    }
    else {
      setAffiliateField("trafficType", [...trafficType, details.option.value]);
    }
  };

  const handleChangeHowFoundOut = (e, item, reason) => {
    if (reason === "clear") {
      setAffiliateField("howFoundOut", "");
      setAffiliateField("howFoundOutGroup", "");
    }
    else {
      setAffiliateField("howFoundOut", item.value);
      setAffiliateField("howFoundOutGroup", item.group);
    }
    setAffiliateField("howFoundOutComment", "");
  };

  const handleChangeTopVerticals = (e, item, reason) => {
    if (reason === "clear") {
      setAffiliateField("topVerticals", "");
    }
    else {
      setAffiliateField("topVerticals", item.value);
    }
    setAffiliateField("topVerticalsComment", "");
  };

  const handleChangeRevenue = (e, item, reason) => {
    if (reason === "clear") {
      setAffiliateField("revenue", "");
    }
    else {
      setAffiliateField("revenue", item.value);
    }
  };

  useEffect(() => {
    if (companyNameRef.current) {
      companyNameRef.current.value = companyName;
    }
    if (declineReasonDescriptionRef.current) {
      declineReasonDescriptionRef.current.value = declineReasonDescription;
    }
    if (topCountriesRef.current) {
      topCountriesRef.current.value = topCountries;
    }
    if (howFoundOutRef.current) {
      howFoundOutRef.current.value = howFoundOut;
    }
    if (refSrcCommentRef.current) {
      refSrcCommentRef.current.value = refSrcComment;
    }
    if (refererUrlRef.current) {
      refererUrlRef.current.value = refererUrl;
    }
    if (registrationUrlRef.current) {
      registrationUrlRef.current.value = registrationUrl;
    }
    if (companyUrlRef.current) {
      companyUrlRef.current.value = companyUrl;
    }
  });

  useEffect(() => {
    if (!declineReasonId.id) {
      const reason = declineReasons.find((item) => item.value === "other");
      setAffiliateField("declineReasonId", reason);
    }
  }, []);

  useEffect(() => {
    if (affiliateType) {
      setManagersListsLoading();
      fetchManagerList(affiliateType);
      setTabState(false);
    }
  }, [affiliateType, setManagersListsLoading, fetchManagerList]);

  useEffect(() => {
    dispatch(getDropdownsByAffiliateTypeSaga({ type: affiliateType, filter: "companyType" }));
    dispatch(getDropdownsByAffiliateTypeSaga({ type: affiliateType, filter: "topVerticals" }));
    dispatch(getDropdownsByAffiliateTypeSaga({ type: affiliateType, filter: "trafficTypes" }));
    dispatch(getDropdownsByAffiliateTypeSaga({ type: affiliateType, filter: "revenues" }));
  }, [dispatch, affiliateType]);

  const isCompanyNameAvailable: boolean = !companyTypesWithOutNameAvailable.includes(companyType);
  const isCompanyCommentActive: boolean = companyType === "Other";
  const handleChangeCompanyType = (e, item) => {
    setAffiliateField("companyType", item ? item.value : "");
    if (!isCompanyCommentActive) {
      setAffiliateField("companyTypeComment", "");
    }
  };
  useEffect(() => {
    if (companyTypesWithOutNameAvailable.includes(companyType)) {
      setAffiliateField("companyName", "");
      setAffiliateField("companyUrl", "");
    }
  }, [companyType]);

  return (
    <PersonalInfoTablet
      title={title}
      name={name}
      currentTablet={currentTablet}
      editTitle={editTitle}
      handleChangeTablet={handleChangeTablet}
      errors={errors}
      fields={fields}
    >
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={affiliateTypes}
        value={affiliateType}
        data-testid="type"
        onChange={handleChangeAffiliateType}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Affiliate type"
            variant="outlined"
            label="Affiliate type"
            error={!!errors.type || !!errors.paymentMethodId}
            helperText={(errors.type && errors.type) || (errors.paymentMethodId && errors.paymentMethodId)}
            InputProps={{
              ...params.InputProps,
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    className={classes.helperIcon}
                    position="start"
                  >
                    <Tooltip title="Some text">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
          />
        )}
      />
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={managers}
        value={manager}
        data-testid="manager"
        onChange={(e, item) => setAffiliateField("manager", item)}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        renderInput={renderInputComponent("manager", "Manager")}
        disabled={!affiliateType || !isManagersListsLoaded}
      />
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={revenues}
        value={revenue}
        isOptionEqualToValue={(opt, val) => opt.value === val}
        data-testid="revenue"
        onChange={handleChangeRevenue}
        renderInput={renderInputComponent("revenue", "How much revenue do you get monthly?")}
      />
      <Box display="flex">
        <Autocomplete
          fullWidth
          classes={{
            inputRoot: classes.input,
          }}
          data-testid="howFoundOut"
          options={howFoundOutListGrouped}
          groupBy={(option) => option.group}
          value={howFoundOutValue}
          isOptionEqualToValue={(opt, val) => (opt.group ? `${opt.group}/${opt.value}` === val : opt.value === val)}
          getOptionLabel={(option) => option.label || option}
          renderInput={renderInputComponent("howFoundOut", "How did you get to know about?")}
          onChange={handleChangeHowFoundOut}
          getOptionDisabled={(opt) => opt.value === howFoundOut && opt.group === howFoundOutGroup}
        />
        {howFoundOutListWithComment.includes(howFoundOutValue) && (
          <TextField
            error={!!errors.howFoundOutComment}
            helperText={errors.howFoundOutComment && errors.howFoundOutComment}
            variant="outlined"
            label="Comment"
            placeholder="Comment"
            value={howFoundOutComment}
            data-testid="howFoundOutComment"
            fullWidth
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Some text">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setAffiliateField("howFoundOutComment", e.target.value)}
          />
        )}
      </Box>
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={refSrces}
        value={refSrc}
        data-testid="refSrc"
        onChange={(e, item) => setAffiliateField("refSrc", item)}
        getOptionLabel={(option) => option}
        renderInput={renderInputComponent("refSrc", "Referal source")}
      />
      {refSrc === "Other" && (
        <TextField
          className={classes.inputField}
          fullWidth
          variant="outlined"
          label="Referal source comment"
          error={!!errors.refSrcComment}
          helperText={errors.refSrcComment && errors.refSrcComment}
          inputProps={{
            style: { background: classes.input },
          }}
          inputRef={refSrcCommentRef}
          FormHelperTextProps={{
            style: { marginLeft: 0, marginRight: 0 },
          }}
          placeholder="Referal source comment"
          name="refSrcComment"
          data-testid="refSrcComment"
          onBlur={handleChangeField}
          InputProps={{
            classes: { adornedStart: classes.input },
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="Some text">
                  <HelpOutlineIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Box display="flex">
        <Autocomplete
          className={classes.inputField}
          fullWidth
          multiple
          classes={{
            inputRoot: classes.input,
          }}
          value={trafficType}
          onChange={handleTrafficTypeChange}
          options={trafficTypes}
          filterOptions={(options, state) => options
            .filter((opt) => !trafficType
              .includes(opt.value) && (!state.inputValue || opt.value.toLowerCase().includes(state.inputValue)))}
          getOptionLabel={(option) => option.label || option}
          renderTags={handleRenderTags}
          renderInput={handleRenderInput}
          data-testid="trafficType"
        />
      </Box>
      <Box display="flex" mb={2}>
        <Autocomplete
          fullWidth
          data-testid="topVerticals"
          classes={{
            inputRoot: classes.input,
          }}
          options={topVerticalsList}
          value={topVerticals}
          onChange={handleChangeTopVerticals}
          getOptionLabel={(option) => option.label || option}
          isOptionEqualToValue={(opt, val) => opt.value === val}
          renderInput={renderInputComponent("topVerticals", "Top verticals")}
        />
        {topVerticals === "Other" && (
          <TextField
            error={!!errors.topVerticalsComment}
            helperText={errors.topVerticalsComment && errors.topVerticalsComment}
            variant="outlined"
            label="Сomment"
            placeholder="Сomment"
            value={topVerticalsComment}
            data-testid="topVerticalsComment"
            fullWidth
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Some text">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setAffiliateField("topVerticalsComment", e.target.value)}
          />
        )}

      </Box>
      <Box display="flex">
        <Autocomplete
          fullWidth
          value={companyType}
          onChange={handleChangeCompanyType}
          options={companyTypeList}
          isOptionEqualToValue={(opt, val) => opt.value === val}
          classes={{
            inputRoot: classes.input,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors.companyType}
              helperText={errors.companyType && errors.companyType}
              variant="outlined"
              label="Company type"
              placeholder="Company type"
              InputLabelProps={{
                error: false,
                shrink: true,
              }}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              data-testid="companyType"
            />
          )}
        />
        {isCompanyCommentActive && (
          <TextField
            error={!!errors.companyTypeComment}
            helperText={errors.companyTypeComment && errors.companyTypeComment}
            variant="outlined"
            label="Comment"
            placeholder="Comment"
            value={companyTypeComment}
            data-testid="companyTypeComment"
            fullWidth
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Some text">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setAffiliateField("companyTypeComment", e.target.value)}
          />
        )}
      </Box>
      <TextField
        disabled={!isCompanyNameAvailable}
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Company name"
        error={!!errors.companyName}
        helperText={errors.companyName && errors.companyName}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={companyNameRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="Company name"
        name="companyName"
        data-testid="companyName"
        onBlur={handleChangeField}
        InputProps={{
          classes: { adornedStart: classes.input },
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Some text">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        disabled={!isCompanyNameAvailable}
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Company url"
        error={!!errors.companyUrl}
        helperText={errors.companyUrl && errors.companyUrl}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={companyUrlRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="Company url"
        name="companyUrl"
        data-testid="companyUrl"
        onBlur={handleChangeField}
        InputProps={{
          classes: { adornedStart: classes.input },
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Some text">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="What top countries do you mostly run?"
        error={!!errors.topCountries}
        helperText={errors.topCountries && errors.topCountries}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={topCountriesRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="What top countries do you mostly run?"
        name="topCountries"
        data-testid="topCountries"
        onBlur={handleChangeField}
        InputProps={{
          classes: { adornedStart: classes.input },
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Some text">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        disableClearable
        options={statuses}
        value={status}
        data-testid="status"
        onChange={(e, item) => setAffiliateField("status", item)}
        getOptionLabel={(option) => option}
        renderInput={renderInputComponent("status", "Status")}
      />
      <Collapse in={isOpenStatusDeclineInfo}>
        <Alert severity="warning" data-testid="aff-decline-info">
          <pre style={{ textAlign: "initial", whiteSpace: "break-spaces" }}>{declineStatusInfoText}</pre>
        </Alert>
      </Collapse>
      {(status === "Decline" || status === "Pending") && (
        <>
          <Autocomplete
            className={classes.inputField}
            fullWidth
            classes={{
              inputRoot: classes.input,
            }}
            disableClearable
            options={declineReasons}
            value={declineReasonId}
            data-testid="declineReasonId"
            onChange={(e, item) => setAffiliateField("declineReasonId", item)}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            renderInput={renderInputComponent("declineReasonId", "Decline reason")}
          />
          <TextField
            className={classes.multilineInput}
            fullWidth
            variant="outlined"
            error={!!errors.declineReasonDescription}
            helperText={errors.declineReasonDescription && errors.declineReasonDescription}
            multiline
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            inputRef={declineReasonDescriptionRef}
            label="Decline reason description"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Type decline reason"
            name="declineReasonDescription"
            data-testid="declineReasonDescription"
            onBlur={handleChangeField}
          />
        </>
      )}
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={currencyType}
        value={currency}
        data-testid="currency"
        onChange={(e, item) => setAffiliateField("currency", item)}
        getOptionLabel={(option) => option}
        renderInput={renderInputComponent("currency", "Currency")}
      />
      {affiliateId && (
        <>
          <TextField
            className={classes.inputField}
            fullWidth
            disabled
            variant="outlined"
            label="Referer url"
            error={!!errors.refererUrl}
            helperText={errors.refererUrl}
            inputProps={{
              style: { background: classes.input },
            }}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            placeholder="Referer url"
            name="refererUrl"
            data-testid="refererUrl"
            onBlur={handleChangeField}
            inputRef={refererUrlRef}
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Some text">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className={classes.inputField}
            fullWidth
            disabled
            variant="outlined"
            label="Registration Url"
            error={!!errors.registrationUrl}
            helperText={errors.registrationUrl}
            inputProps={{
              style: { background: classes.input },
            }}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            placeholder="Registration Url"
            name="registrationUrl"
            data-testid="registrationUrl"
            onBlur={handleChangeField}
            inputRef={registrationUrlRef}
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Some text">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        disableClearable
        options={fraudStatuses}
        value={fraudStatus}
        data-testid="fraudStatus"
        onChange={(e, item) => setAffiliateField("fraudStatus", item)}
        getOptionLabel={(option) => option}
        renderInput={renderInputComponent("fraudStatus", "Fraud status")}
      />
      <Box width={1} display="flex" p={1}>
        <Tooltip title={!sourceComplianceStatus ? "Only affiliate is able to confirm source compliance. Manager can only withdraw affiliate concent." : ""} enterDelay={500} leaveDelay={200}>
          <FormControlLabel
            label="Manual traffic sources confirmation"
            data-testid="sourceComplianceStatus"
            control={(
              <Switch
                checked={sourceComplianceStatus}
                onChange={handleChangeCheckbox}
                name="sourceComplianceStatus"
                color="primary"
                disabled={!sourceComplianceStatus}
              />
            )}
          />
        </Tooltip>
      </Box>
    </PersonalInfoTablet>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerSettings);
