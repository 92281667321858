// @flow
import { GET_UTM_CAMPAIGN_LIST_SAGA } from "../../helpers/constants/utmCampaign";

export type GetUtmCampaignListSagaType = {
  type: typeof GET_UTM_CAMPAIGN_LIST_SAGA,
}

export type Action = GetUtmCampaignListSagaType;

export function getUtmCampaignListSaga(): GetUtmCampaignListSagaType {
  return {
    type: GET_UTM_CAMPAIGN_LIST_SAGA,
  };
}
