// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import thunkMiddleware from "redux-thunk";
import Immutable from "immutable";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import cpaOffer, { initState as initCpaOfferState, type Action as CpaOfferAction, type State as CpaOfferState } from "../../reducers/cpaofferIndex";
import templates, {
  makeTemplatesListState,
  type TemplatesListState,
} from "../../reducers/templates";
import filters, {
  initState as initFiltersState,
  type State as FiltersState,
} from "../../reducers/filters";
import type { Actions as TemplateActions } from "../../actions/templates";
import type { Actions as FiltersActions } from "../../actions/filters";
import dictionaries, { initState as initDictionariesState, type State as DictionariesState } from "../../reducers/dictionaries";
import { CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import type { CpaOfferListLoadingTypes } from "../../selectors/cpaOfferIndex/types";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  loading: LoadingState<CpaOfferListLoadingTypes>,
  tables: TableState,
  cpaOffer: CpaOfferState,
  dictionaries: DictionariesState,
  notifications: NotificationsState,
  templates: TemplatesListState,
  filters: FiltersState,
|}>

function mapPaymentInfoToState(): State {
  return {
    loading: initLoadingState<CpaOfferListLoadingTypes>({
      [CPA_OFFER_TABLE]: false,
    }),
    tables: initTableState({
      [CPA_OFFER_TABLE]: {
        pageSize: 20,
      },
    }),
    cpaOffer: initCpaOfferState(),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
    templates: makeTemplatesListState({
      reportName: "cpaOffer",
    }),
    filters: initFiltersState(),
  };
}

export type Actions = TableActions
  | LoadingActions<CpaOfferListLoadingTypes>
  | CpaOfferAction
  | NotificationsActions
  | TemplateActions
  | FiltersActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  tables,
  cpaOffer,
  dictionaries,
  notifications,
  templates,
  filters,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;
  const middlewares = [thunkMiddleware, sagaMiddleware];

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPaymentInfoToState(),
    composeEnhancers(applyMiddleware(
      ...middlewares
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
