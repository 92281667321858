// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../axiosRequest";
import type { AffiliateInfoPayloadType } from "../../components/AffiliateInfoCard";
import type { PaymentDetailsPayloadType } from "../../components/PaymentDetailsCard";
import type { PaymentStatsType } from "../../components/PaymentInfoCard";
import type {
  PayoutIncomingType,
} from "../../components/PayoutHistoryTable/types/PayoutHistoryTable.types";

const qs = require("qs");

export const fetchAffiliateInfo: (string) => PromiseResponse<
  mixed,
  { data: AffiliateInfoPayloadType }
> = (id) => requestService.get(environment.endpoints.get.getAffiliatePaymentReportPersonalInfo.replace("{affiliateId}", id));

export const fetchPaymentStats: (string) => PromiseResponse<mixed, { data: PaymentStatsType }> = (id) => requestService
  .get(environment.endpoints.get.getAffiliatePaymentReportPaymentStats.replace("{affiliateId}", id));

export const fetchSimilarAffiliates: (string) => PromiseResponse<
  mixed,
  { data: { paymentInfo: PaymentDetailsPayloadType }[] }
> = (id) => requestService.get(environment.endpoints.get.getAffiliatePaymentReportSimilarAffiliates.replace("{affiliateId}", id));

export const fetchPayoutHistory: (string, any) => PromiseResponse<
  mixed,
  { data: PayoutIncomingType[], count: number }
> = (id, options) => requestService
  .get(environment.endpoints.get.getAffiliatePaymentReportPayoutHistory.replace("{affiliateId}", id), {
    params: options,
    paramsSerializer: (params): string => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  });

export const fetchNextPaymentDate: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.getAffiliatePaymentReportPaymentDate.replace("{affiliateId}", id));

export const downloadInvoiceApi: (string[]) => PromiseResponse<mixed> = (ids) => requestService
  .post(environment.endpoints.post.downloadInvoice, { ids }, { responseType: "blob" });
