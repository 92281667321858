// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetPaymentsAffiliateInfoSaga from "../../sagas/getPaymentsAffiliateInfoSaga";
import watchGetPaymentsNextPaymentDateSaga from "../../sagas/getPaymentsNextPaymentDate";
import watchGetPaymentsPaymentInfoSaga from "../../sagas/getPaymentsPaymentInfoSaga";
import watchGetPaymentsSimilarAffiliatesSaga from "../../sagas/getPaymentsSimilarAffiliatesSaga";
import watchGetPayoutHistoryListSaga from "../../sagas/getPayoutHistoryListSaga";
import watchDownloadInvoiceSaga from "../../sagas/downloadInvoice";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetPaymentsAffiliateInfoSaga),
    call(watchGetPaymentsNextPaymentDateSaga),
    call(watchGetPaymentsPaymentInfoSaga),
    call(watchGetPaymentsSimilarAffiliatesSaga),
    call(watchGetPayoutHistoryListSaga),
    call(watchDownloadInvoiceSaga),
  ]);
}
