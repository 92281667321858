// @flow
import {
  call, put, select, debounce,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { fetchPayoutTermsList } from "../../services/payoutTermsApi";
import { GET_PAYOUT_TERMS_LIST_SAGA, PAYOUT_TERMS_LIST_TABLE } from "../../helpers/constants/payoutTermsList";

export function* getPayoutTermsList(): Saga<void> {
  const page: number = yield select(getTablePage, PAYOUT_TERMS_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, PAYOUT_TERMS_LIST_TABLE);
  const sorting: Sorting = yield select(getTableSorting, PAYOUT_TERMS_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, PAYOUT_TERMS_LIST_TABLE);

  try {
    yield put(setLoading(PAYOUT_TERMS_LIST_TABLE, true));

    const { data: { data, count } } = yield call(fetchPayoutTermsList, {
      page,
      limit,
      sorting,
      filters,
    });
    yield put(setTableData(PAYOUT_TERMS_LIST_TABLE, data));
    yield put(changeTableItemsTotalAmount(PAYOUT_TERMS_LIST_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch Payout Terms List", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(PAYOUT_TERMS_LIST_TABLE, false));
  }
}

export default function* watchGetPayoutTermsList(): Saga<void> {
  yield debounce(1000, GET_PAYOUT_TERMS_LIST_SAGA, (getPayoutTermsList: Function));
}
