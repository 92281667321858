// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useState,
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import {
  Box,
  Drawer,
  Typography,
  Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ArrowDropDown,
  FilterList,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import MassAdjustContainer from "../MassAdjustContainer";
import { getMessage } from "../../selectors/message";
import type { UseStylesTypes, UseStateType } from "./types";
import SubratesFilterModal from "../../components/SubratesFilterModal";
import MessageInfo from "../../components/MessageBar";
import { setMessage } from "../../actions/message";
import SubrateInfoTableContainer from "../SubrateInfoTable";
import type { StateObj } from "../../reducers/message";

const useStyles: UseStylesTypes = makeStyles((theme) => ({
  paper: {
    width: "30%",
  },
  root: {
    zIndex: theme.zIndex.modal,
  },
}));

const SubrateInfo: StatelessFunctionalComponent<{}> = () => {
  const messageInfo: StateObj = useSelector(getMessage);

  const { filters, onChangeFilters, onChangePage }: TableProps = useTable(SUBRATES_INFO_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onCloseMessage: () => void = () => {
    dispatch(setMessage({ text: "", open: false, severity: "success" }));
  };

  const search: URLSearchParams = new URLSearchParams(useLocation().search);
  const affId: string | null = search.get("affId");
  const offerId: string | null = search.get("offerId");
  const country: string | null = search.get("country");

  const classes: mixed = useStyles();
  const [filterModalState, setFilterModalState]: UseStateType<boolean> = useState(false);
  const [isMassAdjustOpen, setIsMassAdjustOpen]: UseStateType<boolean> = useState(false);

  useEffect(() => {
    const params = {};
    if (affId) {
      params.affId = affId;
    }
    if (offerId) {
      params.offerId = offerId;
    }
    if (country) {
      params.country = country;
    }
    onChangeFilters(params);
  }, [affId, offerId, country, onChangeFilters]);

  return (
    <Box p={3}>
      <MassAdjustContainer
        isMassAdjustOpen={isMassAdjustOpen}
        setIsMassAdjustOpen={setIsMassAdjustOpen}
      />
      <MessageInfo
        open={messageInfo.open}
        text={messageInfo.text}
        severity={messageInfo.severity}
        closeMessage={onCloseMessage}
      />
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <Box display="flex" alignItems="center">
          <Button
            data-testid="Button-setFilterModal-true"
            className="white-btn"
            variant="outlined"
            startIcon={<FilterList />}
            endIcon={<ArrowDropDown />}
            onClick={() => {
              setFilterModalState(true);
            }}
          >
            <Typography variant="button">
              Filters
            </Typography>
          </Button>
        </Box>
      </Box>
      <Drawer
        data-testid="Drawer-setFilterModal-false"
        anchor="right"
        onClose={() => {
          setFilterModalState(false);
        }}
        open={filterModalState}
        classes={classes}
      >
        <SubratesFilterModal
          data-testid="SubratesFilterModal"
          // $FlowFixMe
          filters={filters}
          handleClose={() => {
            setFilterModalState(false);
          }}
          handleSave={(val) => {
            setFilterModalState(false);
            onChangePage(1);
            // $FlowFixMe
            onChangeFilters(val);
          }}
        />
      </Drawer>
      <SubrateInfoTableContainer
        setIsMassAdjustOpen={setIsMassAdjustOpen}
      />
    </Box>
  );
};

export default SubrateInfo;
