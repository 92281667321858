import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Map } from "immutable";

type Props = {
  data: Array<Map<string, number, number, number, number>>
};

const useStyles = makeStyles(() => ({
  chart: {
    flex: 1,
  },
}));

const ChartSpendings = ({
  data,
}: Props) => {
  const classes = useStyles();

  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        className={classes.chart}
        data={data}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <Tooltip />
        <Legend />
        <Bar
          name="Conversion"
          dataKey="pv"
          barSize={30}
          fill="#cdb9f4"
          legendType="square"
        />
        <Line
          name="Revenues"
          type="monotone"
          dataKey="uv"
          stroke="#687afb"
          strokeWidth={2}
          dot={{ r: 4 }}
        />
        <Line
          name="Earnings"
          type="monotone"
          dataKey="amt"
          stroke="#85d07f"
          strokeWidth={2}
          dot={{ r: 4 }}
        />
        <Line
          name="Clicks"
          type="monotone"
          dataKey="cnt"
          stroke="#ffce84"
          strokeWidth={2}
          dot={{ r: 4 }}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartSpendings;
