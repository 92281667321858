// @flow
/* eslint-disable import/max-dependencies */
import environment from "environment";
import { serialize } from "object-to-formdata";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import qs from "qs";
import { requestService } from "../axiosRequest";
import { type AdvertiserInvoicesType } from "../../containers/AdvertiserInvoicesForm/types/AdvertiserInvoicesForm.types";
import { type AdvertiserInvoicesType as AdvertiserInvoicesListType } from "../../containers/AdvertiserInvoices/types/AdvertiserInvoices.types";
import { fetchDropdownList } from "../dictionariesApi";
import { userService } from "../user";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { getFileNameFromHeaders } from "../../helpers/downloadCsvFile/downloadCsvFile";

export async function fetchAdvertiserInvoicesById(id: string): Promise<AdvertiserInvoicesType> {
  const requestAdvertiserInvoices = requestService.get(environment.endpoints.get.getAdvertiserInvoicesById.replace("{id}", id)).then(({ data: { data } }) => data);
  const requestFile = fetchAdvertiserInvoicesFile([id]);
  return Promise.all([requestAdvertiserInvoices, requestFile])
    .then(([advertiser, invoice]) => ({ ...advertiser, invoice }));
}

export function fetchAdvertiserInvoicesFile(ids: string[]): Promise<File> {
  return requestService.get<File>(environment.endpoints.get.getAdvertiserInvoicesFileById, {
    params: {
      ids: JSON.stringify(ids.map((id) => Number(id))),
    },
    responseType: "blob",
  }).then((
    response
  ) => new File([response.data], getFileNameFromHeaders(response.headers, "file.pdf"), { type: "application/pdf" }))
    .catch((error) => {
      if (
        error && error.request && error.request.responseType === "blob"
      && error.response.data instanceof Blob
      && error.response.data.type
      && error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            // eslint-disable-next-line no-param-reassign
            error.response.data = JSON.parse(String(reader.result));
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            resolve(Promise.reject(error));
          };

          reader.readAsText(error.response.data);
        });
      }
      return Promise.reject(error);
    });
}

export function fetchSaveAdvertiserInvoices(
  {
    id,
    periodFrom,
    periodTo,
    currencyId,
    paymentMethodId,
    cpaAdvertiserId,
    payout,
    paidAt,
    status,
    invoice,
  }: AdvertiserInvoicesType
): Promise<*> {
  const body: * = {
    status,
    periodFrom,
    periodTo,
    currencyId,
    paymentMethodId,
    paidAt: paidAt || undefined,
    payout: Number(payout),
    invoice,
    cpaAdvertiserId,
  };
  return (id
    ? requestService.put(environment.endpoints.put.updateAdvertiserInvoices.replace("{id}", id), serialize(body), {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Api-Key": userService.getApiKey(),
      },
      withCredentials: true,
    }).then(({ data: { data } }) => data)
    : requestService.post(environment.endpoints.post.createAdvertiserInvoices, serialize({
      ...body,
    }), {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Api-Key": userService.getApiKey(),
      },
      withCredentials: true,
    }).then(({ data: { data } }) => data));
}

export async function fetchCpaAdvertiser(str: string): Promise<DropDownObjItemType[]> {
  return fetchDropdownList({
    dictionary: "advertiser",
    type: "cpaAdvertiser",
    filter: str,
  });
}

export function fetchAdvertiserInvoicesList(options: {
  page: number,
  limit: number,
  filters: Filters,
  sorting: Sorting,
  headers: $Keys<AdvertiserInvoicesListType>[]
}): Promise<{ data: *[], count: number }> {
  const [[current, direction] = []] = Object.entries(options.sorting);

  const { cpaAdvertiser, paymentMethod, ...otherFilters } = options.filters;
  const preparedFilters: { [string]: mixed } = { ...otherFilters };

  if (paymentMethod) {
    preparedFilters.paymentMethodId = paymentMethod;
  }

  if (cpaAdvertiser) {
    // $FlowFixMe this type for AutocompleteWithFetch
    preparedFilters.cpaAdvertiserId = cpaAdvertiser.value;
  }

  return requestService
    .get(environment.endpoints.get.getAdvertiserInvoicesList, {
      params: {
        ...options,
        filters: preparedFilters,
        sorting: current ? { current, direction } : undefined,
      },
      paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: true }),
    }).then(({ data }) => data);
}
