// @flow
import Joi from "joi";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

const schema = Joi.object({
  cpaAdvertiserId: Joi.number(),
  paymentMethodId: Joi.number(),
  currencyId: Joi.alternatives([Joi.number(), Joi.string()]),
  periodFrom: Joi.date().min("2000-01-01"),
  periodTo: Joi.date().min("2000-01-01"),
  payout: Joi.alternatives([Joi.number(), Joi.string()]),
  paidAt: Joi.alternatives().conditional("status", { is: "paid", then: Joi.date().min("2000-01-01"), otherwise: Joi.valid(null) }),
  status: Joi.string(),
  invoice: Joi.object().custom((value, helpers) => {
    const fileName = String(value && value.name);
    if (!/^[A-Za-z0-9 !@#$%^&()\-=+_,.`;'{}\[\]]+\.[A-Za-z0-9]{3}$/.test(fileName)) {
      return helpers.error("file.name");
    }
    if (fileName.length > 127) {
      return helpers.error("file.name.length");
    }
    return value;
  }).error((errors) => {
    errors.forEach((err) => {
      switch (err.code) {
        /* eslint-disable no-param-reassign */
        case "file.name":
          err.message = "File name must be contain English characters only, digital and special symbols (!@#$%^&()-=+_,.`;'{}[])";
          break;
        case "file.name.length":
          err.message = "File name must be no longer than 127 characters";
          break;
        default:
          err.message = "This field is required and must be less than 1 MB";
          break;
      }
    });
    return errors;
  }),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "date.min": "Date should not be before minimal date",
  "date.max": "Date should not be after maximal date",
  "date.base": "This field is required",
  "object.base": "This field is required",
}).options({ stripUnknown: true });

export default function (values: Object): ErrorsMapper {
  const { error: { details = {} } = {} } = schema.validate(values, { abortEarly: false });
  return Object.keys(details).reduce((acc: ErrorsMapper, key: string) => ({
    ...acc,
    [details[key].path.join(".")]: { message: details[key].message },
  }), {});
}
