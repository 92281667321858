// @flow
import environment from "environment";
import qs from "qs";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";
import type { ProductCompanyType } from "../../containers/ProductCompanyForm/types/ProductCompanyForm.types";

export const fetchProductCompanyById: (string) => PromiseResponse<mixed> = (id) => requestService
  .get(environment.endpoints.get.getProductCompanyById.replace("{id}", id))
  .then(({
    data: {
      data: {
        isActive,
        ...data
      },
    },
  }) => ({
    ...data,
    isActive: !!isActive,
  }));

export const saveMassAdjustApi: (Object) => PromiseResponse<mixed> = (params) => requestService
  .put(environment.endpoints.put.bulkProductCompanyChange, params);

export const fetchSaveProductCompany: (ProductCompanyType) => PromiseResponse<mixed> = ({
  id, isActive, performers, name, companyType,
}) => {
  const company = {
    name,
    companyType,
    performers,
    isActive: isActive ? 1 : 0,
  };

  return (
    id
      ? requestService.put(environment.endpoints.put.updateProductCompany.replace("{id}", id), company)
      : requestService.post(environment.endpoints.post.saveProductCompany, company)
  );
};

export const fetchProductCompanyList: ({
  page: number,
  limit: number,
  filters: Filters,
  sorting: Sorting,
}) => PromiseResponse<mixed, { data: *[], count: number }> = (options) => {
  const [[current, direction] = []] = Object.entries(options.sorting);

  return requestService
    .get(environment.endpoints.post.getProductCompanyList, {
      params: {
        ...options,
      },
      paramsSerializer: (params: *): string => qs.stringify({
        ...params,
        // $FlowFixMe
        filters: Object.keys(params.filters).length ? params.filters : JSON.stringify({}),
        sorting: current ? {
          current,
          direction,
        } : JSON.stringify({}),
        // $FlowFixMe
        headers: params.headers.length ? params.headers : JSON.stringify([]),
      }, { arrayFormat: "brackets", encode: false }),
    });
};
