// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import { DeleteOutline, Add } from "@mui/icons-material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { Loader, ComboBox } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { useList } from "../../hoocks/useDictionary";
import { useLoading } from "../../hoocks/useLoading";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { FORM_KEY_CRM_TEMPLATE } from "../../helpers/constants/crm";
import type { State } from "../../pages/CrmTemplates";
import type { Tag } from "./CrmTemplatesForm.types";
import { onChangeItem, onAddItem, onRemoveItem } from "./utils";

const emptyTag: Tag = { name: "", value: "" };

const Tags = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();

  // $FlowFixMe
  const tags: Tag[] = useSelector((state: State) => getFormField(state, FORM_KEY_CRM_TEMPLATE, "tags"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const tagValues: DropDownObjItemType[] = useList("tagValue");

  const onChangeTag: (Tag[]) => void = (value) => {
    dispatch(setFormData(FORM_KEY_CRM_TEMPLATE, { tags: value }));
  };

  return (
    <SetupCard title="Tags">
      <Box width={1} mt={1} pb={1}>
        <Loader loading={loading}>
          {tags.map((tag: Tag, index: number): Node => (
            // eslint-disable-next-line react/no-array-index-key
            <Box mb={1} gap={2} display="flex" alignItems="center" key={index}>
              <TextField
                fullWidth
                data-testid={`tag-name-${index}`}
                size="small"
                label="Name"
                value={tag.name}
                onChange={({ target: { value } }: SyntheticInputEvent<HTMLInputElement>) => {
                  onChangeItem({
                    index,
                    value,
                    fieldName: "name",
                    handler: onChangeTag,
                    arr: tags,
                  });
                }}
              />
              <ComboBox
                fullWidth
                size="small"
                label="Value"
                data-testid={`tag-value-${index}`}
                onChange={(_, value: string) => {
                  onChangeItem({
                    index,
                    value,
                    fieldName: "value",
                    handler: onChangeTag,
                    arr: tags,
                  });
                }}
                value={tag.value}
                options={tagValues}
              />
              <IconButton
                color="primary"
                data-testid={`tag-delete-${index}`}
                onClick={() => {
                  onRemoveItem({
                    index,
                    handler: onChangeTag,
                    arr: tags,
                  });
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              onClick={() => {
                onAddItem({
                  handler: onChangeTag,
                  arr: tags,
                  emptyItem: emptyTag,
                });
              }}
              data-testid="tag-add"
            >
              <Add />
              ADD TAG
            </Button>
          </Box>
        </Loader>
      </Box>
    </SetupCard>
  );
};

export default Tags;
