// @flow
import manageAffiliate from "./manageAffilatesTemplates";
import * as AttributeModels from "./subratesTemplates";

const { Attribute, ...SubrateModels } = AttributeModels;

class FactoryAttributes {
  generatedModels: { [string]: * };

  constructor() {
    this.generatedModels = {};
    Object.values(SubrateModels).forEach((Model) => {
      // $FlowFixMe
      const newModel = new Model();
      this.generatedModels[newModel.getApiKey()] = newModel;
    });
  }

  getSubrateModel(modelName: string): Attribute {
    return this.generatedModels[modelName];
  }

  getSubrateModelByKey(modelName: string): Attribute {
    if (this.generatedModels[modelName]) {
      return this.generatedModels[modelName];
    }
    return new Attribute();
  }

  getAllSubrateAttrModelKeys(): Array<string> {
    return Object.keys(SubrateModels);
  }

  getAllSubrateAttrApiKey(): Array<string> {
    return Object.keys(this.generatedModels);
  }

  filterAttrByGroup(groupName: string)
    : Array<{ filterKey: string, title: string, isDisabled: boolean }> {
    const result: Array<{
      filterKey: string,
      title: string,
      isDisabled: boolean
    }> = [];
    Object.keys(this.generatedModels).forEach((key: string) => {
      const attrModel: Attribute = this.getSubrateModel(key);
      if (attrModel.getGroup() === groupName) {
        result.push({
          filterKey: attrModel.getApiKey(),
          title: attrModel.getTitle(),
          isDisabled: attrModel.isDisabled(),
        });
      }
    });

    return result;
  }

  getSubratesConfig(): Array<{
    title: string,
    filters: Array<{
      filterKey: string,
      title: string,
      isDisabled: boolean
    }> }> {
    return [
      {
        title: "Offer data",
        filters: this.filterAttrByGroup("Offer data"),
      },
      {
        title: "Affiliate data",
        filters: this.filterAttrByGroup("Affiliate data"),
      },
      {
        title: "Subrate data",
        filters: this.filterAttrByGroup("Subrate data"),
      },
      {
        title: "Default data",
        filters: this.filterAttrByGroup("Default data"),
      },
    ];
  }

  getSubratesGroups(): Array<string> {
    return ["Default data", "Offer data", "Affiliate data", "Subrate data"];
  }
}

export const FactoryModel = new FactoryAttributes();
const config = {
  manageAffiliate,
  subrates: FactoryModel.getSubratesConfig(),
};

export default config;
