// @flow
import { Record } from "immutable";
import type { RecordFactory, RecordOf } from "immutable";
import { SET_MESSAGE } from "../../helpers/constants/message";
import type { SetMessageType } from "../../actions/message";

export type StateObj = {
  open: boolean,
  text: string,
  severity: string,
};

export type State = RecordOf<StateObj>;

const makeState: RecordFactory<{
  open: boolean,
  text: string,
  severity: string,
}> = Record({
  open: false,
  text: "",
  severity: "success",
});
export const initialState: State = makeState({});

export function initMessageState() {
  return initialState;
}

export default (state: State = initialState, action: SetMessageType) => {
  switch (action.type) {
    case SET_MESSAGE: {
      return state.merge(action.payload);
    }
    default:
      return state;
  }
};
