// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { State } from "../../pages/CreateAffiliate";

export const getIsOfferSaveLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.manageAffiliate.getIn("loaders", "isOfferSaveLoading"),
  (value: boolean): boolean => value
);

export const getIsCampaignSettingsModalOpen: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.manageAffiliate.getIn(["campaignSettings", "isCampaignSettingsModalOpen"]),
  (value: boolean) => value
);

export const getCampaignSettingsFormData: OutputSelector<State, *, Object> = createSelector(
  (state: State) => state.manageAffiliate.getIn(["campaignSettings", "formData"]),
  (value): Object => value.toObject()
);

export const getCampaignSettingsTable: OutputSelector<State, *, Object> = createSelector(
  (state: State) => state.manageAffiliate.getIn(["campaignSettings", "table"]),
  (value): Object => value.toJS()
);

export const getLoading: OutputSelector<
  State, string, boolean
> = createLoadingSelector<string>();
