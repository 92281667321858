// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useCallback,
  useEffect,
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import {
  Table, NumberSearchComponent,
} from "@fas/ui-core";
import moment from "moment";
import { useLocation } from "react-router-dom";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import {
  Typography,
  GlobalStyles,
} from "@mui/material";
import { SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import {
  getSubratesInfoListSaga,
  searchOfferForSubrate,
  type GetSubratesInfoListSaga,
  type SearchOfferForSubrate,
} from "../../actions/subrates";
import type { SubrateDataObjType, TemplateObjType } from "../../reducers/subrates";
import { getSubratesInfoListTemplate, getSubratesInfoOfferOptions } from "../../selectors/subrates";
import type { Props } from ".";
import SubrateInfoActions from "../SubrateInfoActions";
import { getValue } from "../../helpers/generators";

const hasDefaultSubrate: (SubrateDataObjType[]) => boolean = (data) => data[0] && Boolean(data[0].isDefault);
const defaultSubrateStyles: mixed = {
  tbody: {
    "&> tr:first-of-type": {
      backgroundColor: "rgb(212, 215, 237)",
    },
  },
};

const SubrateInfoTableContainer: StatelessFunctionalComponent<Props> = ({
  setIsMassAdjustOpen,
}: Props) => {
  const tableProps: TableProps = useTable(SUBRATES_INFO_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const template: TemplateObjType = useSelector(getSubratesInfoListTemplate);
  const offerInfo: Array<{ label: string, value: string }> = useSelector(getSubratesInfoOfferOptions);

  const onGetSubratesInfoList: () => GetSubratesInfoListSaga = () => dispatch(getSubratesInfoListSaga());
  const onSearchOfferForSubrate: (key: string, value: string, field: string) => SearchOfferForSubrate = useCallback((
    key: string, value: string, field: string
  ): SearchOfferForSubrate => dispatch(searchOfferForSubrate(key, value, field)), [dispatch]);

  const search: URLSearchParams = new URLSearchParams(useLocation().search);
  const affId: string | null = search.get("affId");
  const offerId: string | null = search.get("offerId");
  const country: string | null = search.get("country");

  useEffect(() => {
    if (offerId) {
      onSearchOfferForSubrate("subratesInfo", offerId, "id");
    }
  }, [offerId, onSearchOfferForSubrate]);

  const handleCreate: () => void = useCallback(() => {
    const affIdParam: string = affId ? `affId=${affId}&` : "";
    const offerIdParam: string = offerId ? `offerId=${offerId}&` : "";
    const countryParam: string = country ? `country=${country}` : "";
    window.location = `/subrates/create?${affIdParam}${offerIdParam}${countryParam}`;
  }, [affId, offerId, country]);

  const tableColumns: Array<Column<SubrateDataObjType>> = [
    {
      label: "Affiliate id",
      field: "affId",
    },
    {
      label: "utm_content",
      field: "utmContent",
      sortable: true,
      searchable: true,
      render: ({ utmContent }: SubrateDataObjType): Element<*> => (<Typography>{utmContent || "-"}</Typography>
      ),
    },
    {
      label: "s3",
      field: "s3",
      sortable: true,
      searchable: true,
      render: ({ s3 }: SubrateDataObjType): Element<*> => (<Typography>{s3 || "all"}</Typography>
      ),
    },
    {
      label: "Commission",
      field: "payoutAmount",
      sortable: true,
      searchable: false,
      render: ({ payoutAmount }: SubrateDataObjType): Element<*> => (<Typography>{getValue(payoutAmount, "-")}</Typography>),
    },
    {
      label: "Default commission",
      field: "offerPayoutAmount",
      sortable: true,
      searchable: true,
      SearchComponent: NumberSearchComponent,
      render: ({ offerPayoutAmount }: SubrateDataObjType): Element<*> => (<Typography>{getValue(offerPayoutAmount, "-")}</Typography>),
    },
    {
      field: "offerPayoutAmountFirst",
      label: "Default commission for firsts %",
      sortable: true,
      searchable: true,
      SearchComponent: NumberSearchComponent,
      render: ({ offerPayoutAmountFirst }: SubrateDataObjType): Element<*> => (<Typography>{getValue(offerPayoutAmountFirst, "-")}</Typography>),
    },
    {
      field: "offerPayoutAmountRepeats",
      label: "Default commission for repeats %",
      sortable: true,
      searchable: true,
      SearchComponent: NumberSearchComponent,
      render: ({ offerPayoutAmountRepeats }: SubrateDataObjType): Element<*> => (<Typography>{getValue(offerPayoutAmountRepeats, "-")}</Typography>),
    },
    {
      field: "payoutAmountFirst",
      label: "Commission for firsts %",
      sortable: true,
      render: ({ payoutAmountFirst }: SubrateDataObjType): Element<*> => (<Typography>{getValue(payoutAmountFirst, "-")}</Typography>),
    },
    {
      label: "Commission for repeats %",
      field: "payoutAmountRepeats",
      sortable: true,
      render: ({ payoutAmountRepeats }: SubrateDataObjType): Element<*> => (<Typography>{getValue(payoutAmountRepeats, "-")}</Typography>),
    },
    {
      label: "Shave %",
      field: "shaveAmount",
      sortable: true,
      searchable: false,
      render: ({ shaveAmount }: SubrateDataObjType): Element<*> => (<Typography>{getValue(shaveAmount, "-")}</Typography>),
    },
    {
      label: "Default shave %",
      field: "offerShaveAmount",
      sortable: true,
      searchable: true,
      SearchComponent: NumberSearchComponent,
      render: ({ offerShaveAmount }: SubrateDataObjType): Element<*> => (<Typography>{getValue(offerShaveAmount, "-")}</Typography>),
    },
    {
      label: "Updated at",
      field: "updatedAt",
      sortable: true,
      searchable: false,
      render: ({ updatedAt }: SubrateDataObjType): Element<*> => (<Typography><span>{moment(updatedAt).format("YYYY-MM-DD HH:mm:ss")}</span></Typography>),
    },
  ];

  const tableHeaders: Array<Column<SubrateDataObjType>> = template.headersOrder
    .map((key: string): Column<SubrateDataObjType> => tableColumns
      // $FlowFixMe
      .find((column: Column<SubrateDataObjType>): boolean => column.field === key) || { field: "", label: "" });

  const title: string = offerInfo && offerInfo.length
    ? `Affiliate commission and shave for offer ${offerInfo[0].label}`
    : "Affiliate commission and shave";
  const titleWithCountry: string = country ? `${title} for country ${country}` : title;

  return (
    <>
      <GlobalStyles styles={hasDefaultSubrate(tableProps.data) ? defaultSubrateStyles : {}} />
      <Table
        {...tableProps}
        title={titleWithCountry}
        columns={tableHeaders}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={handleCreate}
        onLoad={onGetSubratesInfoList}
        Actions={<SubrateInfoActions onToggleMassAdjust={setIsMassAdjustOpen} />}
      />
    </>
  );
};

export default SubrateInfoTableContainer;
