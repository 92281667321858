/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  select,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { CHANGE_AFFILIATE_STATUS_SAGA, CPA_AFFILIATES_LIST_TABLE } from "../../helpers/constants/manageAffiliate";
import { getManageAffiliatesDataSaga } from "../../actions/manageAffiliate";
import { changeAffiliateStatus } from "../../services/manageAffiliateApi";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";

export function* makeFetch(): Saga<void> {
  const rows: CpaAffiliatesListItem[] = yield select(getTableSelections, CPA_AFFILIATES_LIST_TABLE);
  try {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, true));
    const { id, status }: CpaAffiliatesListItem = rows[0];
    const newStatus: string = status !== "Approve" ? "Approve" : "Pending";

    yield call(changeAffiliateStatus, id, newStatus);
    yield put(addNotification({ message: "Status changed", severity: "success" }));
    yield put(setTableSelection(CPA_AFFILIATES_LIST_TABLE, []));
    yield put(getManageAffiliatesDataSaga());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, false));
  }
}

export default function* watchChangeAffiliateStatusSaga(): Saga<void> {
  // todo add event type here
  yield takeEvery(CHANGE_AFFILIATE_STATUS_SAGA, (makeFetch: Function));
}
