// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import qs from "qs";
import { requestService } from "../axiosRequest";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";

export function fetchIp(affiliateId: string): PromiseResponse<*, {
  ipWhiteList: string,
  apiKey: string,
}> {
  return requestService
    .get(environment.endpoints.get.getApiIpList.replace("{affiliateId}", affiliateId));
}

export function generateKey(): PromiseResponse<*, {
  status: number,
  apiKey: string,
  ipWhiteList: Array<string>,
}> {
  return requestService.get(environment.endpoints.get.generateApiKey);
}

export function saveKey(affiliateId: string, fields: {
  ipWhiteList: Array<string>,
  apiKey: string,
}): PromiseResponse<*, { status: number }> {
  return requestService.post(environment.endpoints.post.saveApiIp.replace("{affiliateId}", affiliateId),
    fields);
}

export function getPostbackEvents(id: string): PromiseResponse<*, {
  status: number,
  events: Array<*>
}> {
  return requestService.get(environment.endpoints.get.getPostbackEvents.replace("{affiliateId}", id));
}

export function savePostbackEvent(id: string, fields: { [string]: string }): PromiseResponse<*, {
  status: number,
}> {
  return requestService.post(environment.endpoints.post.savePostbackEvent.replace("{affiliateId}", id),
    { ...fields });
}

export function deletePostbackEvent(affId: string, eventId: string): PromiseResponse<*, {
  status: number,
}> {
  return requestService.delete(environment.endpoints.delete.deletePostbackEvent
    .replace("{affiliateId}", affId)
    .replace("{postbackId}", eventId));
}

export function getPostbackOffers(value: string): PromiseResponse<*, {
  value: string,
}> {
  return requestService.get(environment.endpoints.get.searchOffers,
    {
      params: {
        q: value,
      },
      paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: true }),
    });
}

export function getPostbackInfo(affiliateId: string, data: { sorting: *, filters: * }): PromiseResponse<*, {
  status: number,
  postbacks: Array<*>,
  defaultPostback: string,
  affiliatePostbackLink: string,
  boSoWebpush: string,
}> {
  return requestService.get(environment.endpoints.get.getPostbackInfo.replace("{affiliateId}", affiliateId),
    {
      params: {
        ...data,
      },
    });
}

export function getListsPostback(): PromiseResponse<*, {
  status: number,
  lists: Array<*>
}> {
  return requestService.get(environment.endpoints.get.getListsPostback);
}

export function getOffersCountries(offerId: string): PromiseResponse<*, { status: number }> {
  return requestService.get(environment.endpoints.get.getOfferCountries, {
    params: {
      offerId,
    },
  });
}

export function changeStatusPostback(data: { id: string, isActive: boolean }): PromiseResponse<*, { status: number }> {
  return requestService.post(environment.endpoints.post.postbackAction, data);
}

export function savePostbackInfo(affId: string, fields: { [string]: * }): PromiseResponse<*, { status: number }> {
  return requestService.post(environment.endpoints.post.savePostbackInfo.replace("{affiliateId}", affId),
    fields);
}

export function saveAdditionalPostback(affId: string, fields: { [string]: * }): PromiseResponse<*, { status: number }> {
  return requestService.post(environment.endpoints.post.savePostback.replace("{affiliateId}", affId),
    fields);
}

export function getManageAffiliateData(params: { [string]: * }): PromiseResponse<mixed, {
  status: number,
  data: {
    count: number,
    data: CpaAffiliatesListItem[],
  }
}> {
  return requestService.post(environment.endpoints.post.getAffiliates, params);
}

export function getPersonalInfoLists(affId?: string): PromiseResponse<*, *> {
  const postfix: string = affId ? `/${affId}` : "";
  return requestService.get(`${environment.endpoints.get.personalInfoLists}${postfix}`);
}

export function saveAffiliate(params: { [string]: * }): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.addAffiliate, params);
}

export function updateAffiliate(id: string, params: { [string]: * }): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.updateAffiliate.replace("{affiliateId}", id), params);
}

export function bulkChangeAffiliateStatus(payload: {
  activate: Array<{id: string, isActive: number
  }>}): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.applyActivesAffiliate, payload);
}

export function updatePaymentInfo(affId: string, payload: *): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.editPaymentInfo.replace("{affiliateId}", affId), payload);
}

export function changeAffiliateStatus(id: string, status: string): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.changeAffiliateStatus, {
    id,
    status,
  });
}

export function changeAffiliatePaymentVerify(paymentInfoId: number, isVerified: number): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.changeAffiliatePaymentVerify, {
    paymentInfoId,
    isVerified,
  });
}

export function generateLoginLink(id: string, role: string): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.generateLoginLink, {
    affId: id,
    role,
  });
}

export function getManageAffiliateTemplates(searchParams: string = ""): PromiseResponse<*, *> {
  return requestService.get(environment.endpoints.get.getManageAffiliateTemplates, {
    params: { name: searchParams },
  });
}

export function saveTemplateManageAffiliate(payload: *): PromiseResponse<*, *> {
  return requestService.post(environment.endpoints.post.saveAffiliateTemplate, payload);
}

export function getAuthorsLists(marketingGroup: {id: number | string, name: string}): PromiseResponse<*, *> {
  return requestService.get(`${environment.endpoints.get.getManagersListByMarketingGroup}?type=manager&filter=${marketingGroup.name}`);
}

export function bulkChangeAuthor(user: *, affiliates: Array<number | string>): PromiseResponse<*, *> {
  return requestService.put(environment.endpoints.put.bulkCpaAffiliatesAuthorsChange, {
    filters: { id: affiliates },
    data: { manager: user.value },
  });
}

export function getManagerListByAffiliateType(type: string): PromiseResponse<*, *> {
  return requestService.get(environment.endpoints.get.getCpaAffiliateDropdowns, {
    params: {
      type: "manager",
      filter: type,
    },
  });
}

export function getDropdownsByAffiliateType({ type, filter }: { type: string, filter: string }): PromiseResponse<*, *> {
  if (!type) {
    return requestService.get(`${environment.endpoints.get.getCpaAffiliateDropdowns}?type=${filter}`);
  }
  return requestService.get(`${environment.endpoints.get.getCpaAffiliateDropdowns}?type=${filter}&filter=${type}`);
}

export function copyPaymentDetailsById(currentId: string, copyId: string): PromiseResponse<*, *> {
  return requestService
    .get(environment.endpoints.get.getPaymentInfoById.replace("{affiliateId}", currentId).replace("{parentAffiliateId}", copyId))
    .then(({ data }) => data);
}
