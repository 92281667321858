// @flow
export const prepareFilters = (affiliates: *) => {
  const {
    type: affiliateType,
    status: affiliateStatus,
  } = affiliates.filters;
  const { field, direction } = affiliates.sorting;
  const filters = {
    ...affiliates.filters,
    status: affiliateStatus.filter((status) => status.isChecked)
      .map((status) => status.name),
    type: affiliateType.filter((type) => type.isChecked)
      .map((type) => type.name),
  };

  return {
    filters: `filters=${JSON.stringify(filters)}`,
    sorting: field && direction
      ? `sorting=${JSON.stringify(affiliates.sorting)}` : "",
  };
};

export function mapQueryPostbacksV2(data: *) {
  const { sorting = {}, filters = {} } = data;
  const result: { [key: string]: * } = {
    ...filters,
  };
  if (result.isActive) {
    result.status = filters.isActive;
    delete result.isActive;
  }

  if (sorting.field) {
    result.currentSort = sorting.field;
    result.direction = sorting.direction;
  }

  return result;
}

export const howFoundOutListWithComment: string[] = [
  "Recommendation",
  "Webinar",
  "Other",
  "Event/Conference/Other",
  "Forum/Media/Other",
  "Social Media/Other",
  "Conference",
  "Forum",
  "Recommendation/Another network",
  "Recommendation/Friend",
  "Recommendation/Other",
];

export const companyTypesWithOutNameAvailable: string[] = [];
