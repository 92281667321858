// @flow
import type { Dispatch } from "redux";
import environments from "environment";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import {
  SET_SORT_DASHBOARD,
  SET_FILTER_DASHBOARD,
  DELETE_FILTER_DASHBOARD,
  SET_SELECTED_ROWS_DASHBOARD,
  CLEANUP_TABLE_DASHBOARD,
  SET_FETCH_DATA,
} from "../../helpers/constants/dashboard";
import type {
  SortType,
  FilterType,
  SelectedRowsType,
} from "../../reducers/dashboard";
import { apiService } from "../../services/request";
import type {
  CleanupTableAction,
  DeleteFilterAction,
  FetchData,
  SetFetchData,
  SetFilterAction,
  SetSelectedRowsAction,
  SetSortAction
} from "./types";

// Actions
export const setSort = (
  value: SortType
): SetSortAction => ({
  type: SET_SORT_DASHBOARD,
  value,
});
export const setFilter = (
  value: FilterType
): SetFilterAction => ({
  type: SET_FILTER_DASHBOARD,
  value,
});
export const deleteFilter = (
  field: string
): DeleteFilterAction => ({
  type: DELETE_FILTER_DASHBOARD,
  field,
});
export const setSelectedRows = (
  value: SelectedRowsType
): SetSelectedRowsAction => ({
  type: SET_SELECTED_ROWS_DASHBOARD,
  value,
});
export const cleanupTable = (): CleanupTableAction => ({
  type: CLEANUP_TABLE_DASHBOARD,
});
export const setFetchData = (
  value: FetchData
): SetFetchData => ({
  type: SET_FETCH_DATA,
  value,
});
export const fetchDataGet = () => (dispatch: Dispatch<*>) => apiService
  .get(environments.endpoints.get.getDasboardGET)
  .then(({ data }) => dispatch(setFetchData(data)))
  .catch((error) => {
    dispatch(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  });
export const fetchDataPost = (body: {[string]: string}) => (dispatch: Dispatch<*>) => apiService
  .post(environments.endpoints.post.getDasboardPOST, body)
  .then(({ data }) => dispatch(setFetchData(data)))
  .catch((error) => {
    dispatch(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  });
