// @flow
import React, { useState, type StatelessFunctionalComponent } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ComboBox, ConfirmDialog } from "@fas/ui-core";
import getOptionLabel from "@fas/ui-core/lib/SearchComponents/helpers/getOptionLabel";
import type { Manager, ManagerList } from "../../actions/cpaoffer";
import type { UseState, User } from "./types/ChangeUserModal.types";

const useStyles = makeStyles((theme) => ({
  comboBox: {
    minWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

type Props = {
  isChangeAuthorModalOpened: boolean,
  closeChangeAuthorModal: () => void,
  changeAuthorAction: (selectedUser: Manager) => void,
  managersList: ManagerList,
  isManagersListLoaded: boolean,
};

const ChangeUserModal: StatelessFunctionalComponent<Props> = ({
  isChangeAuthorModalOpened,
  closeChangeAuthorModal,
  changeAuthorAction,
  managersList,
  isManagersListLoaded,
}: Props) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser]: UseState<User> = useState({ label: "", value: "" });

  return (
    <>
      <ConfirmDialog
        open={isChangeAuthorModalOpened}
        title="Change author"
        controls={
          [
            <Button
              onClick={closeChangeAuthorModal}
              color="primary"
              data-testid="ui-core-confirm-dialog-cancel-button"
              key="ui-core-confirm-dialog-cancel-button"
            >
              Cancel
            </Button>,
            <Button
              onClick={() => changeAuthorAction(selectedUser)}
              color="primary"
              disabled={selectedUser !== null && selectedUser.value === ""}
              data-testid="ui-core-confirm-dialog-apply-button"
              key="ui-core-confirm-dialog-apply-button"
            >
              Apply
            </Button>,
          ]
        }
      >
        <ComboBox
          className={classes.comboBox}
          label="Select new author"
          onChange={(e, value) => setSelectedUser(value)}
          options={managersList}
          data-testid="createdBy"
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {getOptionLabel(option)}
            </li>
          )}
          loading={!isManagersListLoaded}
        />
      </ConfirmDialog>
    </>
  );
};

export default ChangeUserModal;
