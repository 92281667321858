// @flow
import type { List } from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type {
  AffiliateInfoStateType,
  PaymentInfoStateType,
  PaymentDetailsStateType,
} from "../../reducers/paymentInfo";
import type { PaymentStatsType } from "../../components/PaymentInfoCard";
import type { AffiliateInfoType } from "../../components/AffiliateInfoCard";
import type { PaymentDetailsType } from "../../components/PaymentDetailsCard";
import type { State as PaymentInfoStoreState } from "../../pages/PaymentInfo";

export const getAffiliateId: OutputSelector<PaymentInfoStoreState, *, string> = createSelector(
  (state: PaymentInfoStoreState): string => state.paymentInfo.get("affiliateId"),
  (affiliateId: string): string => affiliateId
);

export const getAffiliateInfo: OutputSelector<PaymentInfoStoreState, *, AffiliateInfoType> = createSelector(
  (state: PaymentInfoStoreState): AffiliateInfoStateType => state.paymentInfo.get("affiliateInfo"),
  // $FlowFixMe
  (affiliateInfo: AffiliateInfoStateType): AffiliateInfoType => affiliateInfo.toJS()
);

export const getPaymentInfo: OutputSelector<PaymentInfoStoreState, *, PaymentStatsType> = createSelector(
  (state: PaymentInfoStoreState): PaymentInfoStateType => state.paymentInfo.get("paymentInfo"),
  (paymentInfo: PaymentInfoStateType): PaymentStatsType => paymentInfo.toObject()
);

export const getPaymentDetails: OutputSelector<PaymentInfoStoreState, *, PaymentDetailsType> = createSelector(
  (state: PaymentInfoStoreState): PaymentDetailsStateType => state.paymentInfo.get("paymentDetails"),
  // $FlowFixMe
  (paymentDetails: PaymentDetailsStateType): PaymentDetailsType => paymentDetails.toJS()
);

export const getSimilarAffiliates: OutputSelector<
  PaymentInfoStoreState,
  *,
  Array<PaymentDetailsType>
> = createSelector(
  (state: PaymentInfoStoreState): List<PaymentDetailsStateType> => state.paymentInfo.get("similarAffiliates"),
  // $FlowFixMe
  (similarAffiliates: List<PaymentDetailsStateType>): Array<PaymentDetailsType> => similarAffiliates.toJS()
);

export const getNextPaymentDatePayoutPeriod: OutputSelector<PaymentInfoStoreState, *, string> = createSelector(
  (state: PaymentInfoStoreState): string => state.paymentInfo.getIn(["nextPaymentDate", "payoutPeriod"], ""),
  (payoutPeriod: string): string => payoutPeriod
);

export const getNextPaymentDateComment: OutputSelector<PaymentInfoStoreState, *, string> = createSelector(
  (state: PaymentInfoStoreState): string => state.paymentInfo.getIn(["nextPaymentDate", "comment"], ""),
  (comment: string): string => comment
);
