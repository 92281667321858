// @flow
import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
} from "react";
import { connect, useSelector } from "react-redux";
import {
  TextField,
  InputAdornment,
  Tooltip,
  Box,
  IconButton,
  Autocomplete,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Loader, ViewLink } from "@fas/ui-core";
import {
  HelpOutline as HelpOutlineIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { userService } from "../../../../../../services/user";
import PaymentInfoTablet from "../PaymentInfoTablet";
import { changeAffiliatePaymentVerifySaga, getAffiliateSaga } from "../../../../../../actions/manageAffiliate";

type Props = {
  loading: boolean,
  lists: Array<any>,
  errors: Object,
  fields: Array<string>,
  paymentInfo: Object,
  personalInfo: Object,
  setField: (string, string) => any,
  setPersonalInfoField: (string, string) => any,
  copyPaymnetDetailsById: (string) => mixed,
  clearFields: () => any,
  affiliateId: string,
  parentAffIdPayment: string,
  changeAffiliatePaymentVerify: ( id: number, value: number ) => void,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  helperIcon: {
    marginLeft: 5,
  },
}));

const mapDispatchToProps = (dispatch) => ({
  changeAffiliatePaymentVerify: (id, value) => dispatch(changeAffiliatePaymentVerifySaga(id, value)),
  getPaymentInfo: (affiliateId) => dispatch(getAffiliateSaga(affiliateId)),
});

const getPaymentTypesList = (list) => {
  if (!userService.can(["cpaInvoiceManage.cpaAdmin.paymentsType"])) {
    return list.filter((item) => !["vacation", "netting"].includes(item));
  }
  return list;
};

const getPaymentTypeDisabled = (value) => !userService.can(["cpaInvoiceManage.cpaAdmin.paymentsType"]) && ["vacation", "netting"].includes(value);

const PaymentInfoMain = ({
  loading,
  title,
  lists,
  errors,
  fields,
  paymentInfo,
  affiliateId,
  affType,
  personalInfo,
  setField,
  clearFields,
  resetErrors,
  copyPaymnetDetailsById,
  setPersonalInfoField,
  parentAffIdPayment,
  childAffiliates = [],
  changeAffiliatePaymentVerify,
  getPaymentInfo,
}: Props) => {
  const classes = useStyles();
  const [copyAffiliateId, setCopyAffiliateId] = useState("");
  const hasUserPaymentCommentPermission: boolean = userService.can(["cpaAdmin.manageAffiliate.paymentInfo.paymentComment"]);

  const paymentCommentRef = useRef(null);
  const paymentMinAmountRef = useRef(null);

  const {
    paymentMethodId,
    paymentComment,
    paymentsType,
    paymentMinAmount,
    paymentAvailable,
    isDataChanged,
    id: paymentInfoId,
    isVerified,
  } = paymentInfo;

  const { currency }: $PropertyType<Props, "personalInfo"> = personalInfo;

  useEffect(() => {
    if (parentAffIdPayment) {
      setCopyAffiliateId(parentAffIdPayment);
    }
  }, [parentAffIdPayment]);

  const {
    paymentMethods = [],
    currencyType = [],
  } = lists;
  const paymentAvailableList = lists.paymentAvailable
    ? lists.paymentAvailable : [];

  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setField(name, value);
  };

  const handleChangePaymentMethod = (e, item) => {
    resetErrors();
    clearFields();
    setField("paymentMethodId", item);
  };

  const handleKeyDownParentId = (e) => {
    if (e.keyCode === 13) {
      handleChangeParentId();
    }
  };

  const handleChangeParentId = () => {
    const value = copyAffiliateId.trim();
    if (value) {
      copyPaymnetDetailsById(value);
      setField("isDataChanged", true);
    }
  };

  const handleChangeCopyAffiliateId = (e) => {
    setCopyAffiliateId(e.target.value);
  };

  const handleDeleteParentId = () => {
    setPersonalInfoField("parentAffIdPayment", "");
    setCopyAffiliateId("");
    setField("isDataChanged", true);
  };

  useEffect(() => {
    if (paymentCommentRef.current) {
      paymentCommentRef.current.value = paymentComment;
    }
    if (paymentMinAmountRef.current) {
      paymentMinAmountRef.current.value = paymentMinAmount;
    }
  });
  
  const hasUserPaymentVerifyPermission = userService.can([{ path: "/api/v1/cpa/verifyPaymentInfo", method: "POST" }]);

  //Bank Wire Transfer, Paxum, Payoneer, PayPal
  const isAvailableVerify = ( [1, 2, 9, 10].includes(paymentMethodId.id) && ["adromeda", "adsempiresmartlink", "adsempiredirect"].includes(affType)) ? true : false;
  const isDisabledVerifyButton = (loading || !hasUserPaymentVerifyPermission || isDataChanged) ? true : false;

  const handleVerify = (value) => {
    changeAffiliatePaymentVerify(paymentInfoId, value);
  }

  return (
    <Loader loading={loading}>
      <PaymentInfoTablet
        title={title}
        errors={errors}
        fields={fields}
      >
        {userService.can(["dictionary.cpaAffiliate.skipPayment"]) && (
          <Autocomplete
            disableClearable
            className={classes.inputField}
            fullWidth
            classes={{
              inputRoot: classes.input,
            }}
            options={paymentAvailableList}
            value={paymentAvailable}
            data-testid="paymentAvailable"
            onChange={(e, item) => setField("paymentAvailable", item)}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Payment available"
                variant="outlined"
                label="Payment available"
                error={!!errors.paymentAvailable}
                helperText={errors.paymentAvailable}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <>
                      <InputAdornment
                        className={classes.helperIcon}
                        position="start"
                      >
                        <Tooltip title="Some text">
                          <HelpOutlineIcon />
                        </Tooltip>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        <Autocomplete
          disabled={!!parentAffIdPayment}
          className={classes.inputField}
          fullWidth
          disableClearable
          classes={{
            inputRoot: classes.input,
          }}
          options={paymentMethods}
          value={paymentMethodId}
          data-testid="paymentMethodId"
          onChange={handleChangePaymentMethod}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Payment method"
              variant="outlined"
              label="Payment method"
              error={!!errors.paymentMethodId}
              helperText={errors.paymentMethodId && errors.paymentMethodId}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          className={classes.inputField}
          fullWidth
          classes={{
            inputRoot: classes.input,
          }}
          options={getPaymentTypesList(lists.paymentsType || [])}
          value={paymentsType}
          data-testid="paymentsType"
          disabled={getPaymentTypeDisabled(paymentsType)}
          onChange={(e, item) => setField("paymentsType", item)}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Payment type"
              variant="outlined"
              label="Payment type"
              error={!!errors.paymentsType}
              helperText={errors.paymentsType && errors.paymentsType}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          disabled
          className={classes.inputField}
          fullWidth
          classes={{
            inputRoot: classes.input,
          }}
          options={currencyType}
          value={currency}
          data-testid="currency"
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Currency"
              variant="outlined"
              label="Currency"
              error={!!errors.currency}
              helperText={errors.currency}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        <TextField
          fullWidth
          className={classes.inputField}
          name="paymentMinAmount"
          data-testid="paymentMinAmount"
          variant="outlined"
          label="Min payment"
          placeholder="Min payment"
          error={!!errors.paymentMinAmount}
          helperText={errors.paymentMinAmount && errors.paymentMinAmount}
          inputProps={{
            style: { background: classes.input },
          }}
          inputRef={paymentMinAmountRef}
          FormHelperTextProps={{
            style: { marginLeft: 0, marginRight: 0 },
          }}
          onBlur={handleChangeField}
          InputProps={{
            classes: { adornedStart: classes.input },
            startAdornment: (
              <>
                <InputAdornment
                  position="start"
                >
                  <Tooltip title="Some text">
                    <HelpOutlineIcon />
                  </Tooltip>
                </InputAdornment>
              </>
            ),
          }}
        />
        <TextField
          className={classes.inputField}
          disabled={!hasUserPaymentCommentPermission}
          fullWidth
          multiline
          variant="outlined"
          label="Payment comments"
          placeholder="Type your payment comments"
          name="paymentComment"
          data-testid="paymentComment"
          error={!!errors.paymentComment}
          helperText={errors.paymentComment && errors.paymentComment}
          InputProps={{
            classes: { root: classes.input },
          }}
          inputRef={paymentCommentRef}
          onBlur={handleChangeField}
          InputLabelProps={{
            shrink: true,
          }}
          rows={4}
        />
        <Box display="flex" alignItems="center">
          <TextField
            disabled={!!parentAffIdPayment || childAffiliates.length > 0}
            fullWidth
            autoComplete="off"
            className={classes.inputField}
            name="parentAffIdPayment"
            data-testid="parentAffIdPayment"
            variant="outlined"
            label="Copy the payment details from the Affiliate ID"
            placeholder="Copy the payment details from the Affiliate ID"
            inputProps={{
              style: { background: classes.input },
            }}
            value={copyAffiliateId}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            onKeyDown={handleKeyDownParentId}
            onChange={handleChangeCopyAffiliateId}
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                  >
                    <Tooltip title="Copy the payment details from the Affiliate ID">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                </>
              ),
            }}
          />
          {parentAffIdPayment && (
            <IconButton onClick={handleDeleteParentId} data-testid="clearIdButton">
              <Tooltip title="Clear ID">
                <CloseIcon />
              </Tooltip>
            </IconButton>
          )}
          {!parentAffIdPayment && (
            <IconButton onClick={handleChangeParentId} disabled={!copyAffiliateId} data-testid="linkIdButton">
              <Tooltip title="Link ID">
                <CheckIcon />
              </Tooltip>
            </IconButton>
          )}
        </Box>
        {childAffiliates.length > 0 && (
          <Box ml={1}>
            <Typography variant="body1" textAlign="left">
              Child Affiliates:
              {" "}
              {childAffiliates.map((childId, index) => (
                <Fragment key={childId}>
                  <ViewLink
                    id={childId}
                    name={childId}
                    viewPath="/manageAffiliate/edit/:id/paymentInfo"
                  >
                    {childId}
                  </ViewLink>
                  {(index + 1) < childAffiliates.length && ", "}
                </Fragment>
              ))}
            </Typography>
          </Box>
        )}
        {isAvailableVerify && (
          <Box width={1} display="flex" p={1}>
            <FormControlLabel
              label={!!isVerified ? "Payment information is verified." : "Payment information is not verified."}
              data-testid="verifyPaymentInfoSwitch"
              control={(
                <Checkbox 
                  checked={Boolean(isVerified)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                  disableRipple={true}
                  disabled
                />
              )}
            />
            {hasUserPaymentVerifyPermission && !!isVerified &&(
              <Button
                disabled={isDisabledVerifyButton}
                color="primary"
                variant="contained"
                size="medium"
                data-testid="unverifyPaymentInfoButton"
                onClick={() => handleVerify(0)}
              >
                Unverify
              </Button>
            )}
            {hasUserPaymentVerifyPermission && !isVerified &&(
              <Button
                disabled={isDisabledVerifyButton}
                color="secondary"
                variant="contained"
                size="medium"
                data-testid="verifyPaymentInfoButton"
                onClick={() => handleVerify(1)}
              >
                Verify
              </Button>
            )}
          </Box>
        )}
      </PaymentInfoTablet>
    </Loader>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(PaymentInfoMain);
