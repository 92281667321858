// @flow
import React from "react";
import DocumentTitle from "react-document-title";
import { Box } from "@mui/material";
import { Provider } from "react-redux";
import configureStore from "./store";
import CpaOfferCampaigns from "../../components/CpaOfferCampaigns";
import NotificationsContainer from "../../components/NotificationsContainer";

function CpaOfferCampaignsPage() {
  return (
    <DocumentTitle title="CPA - CpaOffers list">
      <Provider store={configureStore()}>
        <NotificationsContainer />
        <Box p={2}>
          <CpaOfferCampaigns />
        </Box>
      </Provider>
    </DocumentTitle>
  );
}

export default CpaOfferCampaignsPage;
