// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  put,
  select,
  debounce,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_PRODUCT_COMPANY_LIST_SAGA, PRODUCT_COMPANY_LIST_TABLE } from "../../helpers/constants/productCompany";
import { fetchProductCompanyList } from "../../services/productCompanyApi";

export function* getProductCompanyList(): Saga<void> {
  try {
    yield put(setLoading(PRODUCT_COMPANY_LIST_TABLE, true));

    const page: number = yield select(getTablePage, PRODUCT_COMPANY_LIST_TABLE);
    const limit: number = yield select(getTablePageSize, PRODUCT_COMPANY_LIST_TABLE);
    const sorting: Sorting = yield select(getTableSorting, PRODUCT_COMPANY_LIST_TABLE);
    const filters: Filters = yield select(getTableFilters, PRODUCT_COMPANY_LIST_TABLE);

    const payload = {
      page,
      limit,
      filters: {
        ...filters,
        companyType: "customer",
      },
      sorting,
      headers: ["id", "name", "companyType", "isActive"],
    };

    const { data } = yield call(fetchProductCompanyList, payload);

    yield put(setTableData(PRODUCT_COMPANY_LIST_TABLE, data.data));
    yield put(changeTableItemsTotalAmount(PRODUCT_COMPANY_LIST_TABLE, data.count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch company list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(PRODUCT_COMPANY_LIST_TABLE, false));
  }
}

export default function* watchGetProductCompanyList(): Saga<void> {
  yield debounce(1000, GET_PRODUCT_COMPANY_LIST_SAGA, (getProductCompanyList: Function));
}
