// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import type { NextPaymentDateType } from "./types/NextPaymentDateLabel.types";

type Props = {
  comment: $PropertyType<NextPaymentDateType, "comment">,
}

type Classes = {
  root: mixed,
}

const useStyles: () => Classes = makeStyles((theme): Classes => ({
  root: {
    background: theme.palette.error.main,
    color: "#FFFFFF",
  },
}));

const NextPaymentDateUnavailable: StatelessFunctionalComponent<Props> = ({
  comment,
}: Props) => {
  const classes: Classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" className={classes.root}>
      <Box m={2}>
        <Typography variant="subtitle1">{comment}</Typography>
      </Box>
    </Box>
  );
};

export default NextPaymentDateUnavailable;
