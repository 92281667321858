import type React from "react";
import type { HTMLAttributeAnchorTarget } from "react";
import {
  Alert,
  Box,
  lighten,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowOutward } from "@mui/icons-material";
import { useEffect } from "react";
import cleanPath from "../Affiliates/utils/clearPath";

function DashboardLabel({ label, count }: { label: string, count?: number | string }) {
  /* eslint-disable-next-line no-nested-ternary */
  const color = typeof count !== "number" ? "info" : (count > 0 ? "error" : "success");

  return (
    <>
      <Alert
        color={color}
        icon={false}
        sx={(theme) => ({
          width: 1,
          borderRadius: 4,
          "& .MuiAlert-message": {
            width: 1, display: "flex", justifyContent: "space-between", alignItems: "flex-start",
          },
          "&:hover": {
            backgroundColor: lighten(theme.palette[color]?.light, 0.85),
          },
        })}
      >
        <Box display="flex" gap={1} flexDirection="column">
          {count !== undefined && <Box fontSize={60}>{count}</Box>}
          <Box fontSize={16} lineHeight={1.5}>{label}</Box>
        </Box>
        <ArrowOutward />
      </Alert>
    </>
  );
}

function TabLabel({ label, count = 0 }: { label: string, count?: number | string }) {
  const postfix = count > 0 ? `(${count})` : "";
  return (
    <>
      {label}
      {postfix}
    </>
  );
}
type LabelSlotProps = {
  layout: string;
  label: string;
  useCount?: () => { data?: number | string, refetch: () => unknown };
  selected?: boolean;
}
export function LabelSlot({
  layout,
  label,
  useCount,
  selected,
} : LabelSlotProps) {
  const { data, refetch } = useCount?.() || {};
  useEffect(() => {
    if (selected && refetch) {
      refetch();
    }
  }, [refetch, selected]);
  return (
    <>
      {layout === "dashboard"
        ? <DashboardLabel label={label} count={data} />
        : <TabLabel label={label} count={data} />}
    </>
  );
}

export function LabelSlotLink({ url, target, ...props }: LabelSlotProps & {
  url: string,
  target?: HTMLAttributeAnchorTarget | undefined,
}) {
  return (
    <Link style={{ textDecoration: "none" }} to={cleanPath(url)} target={target}>
      <LabelSlot {...props} />
    </Link>
  );
}

export const DashboardBlockTitle: React.FC<TypographyProps> = (props) => (
  <Typography component="div" fontSize={24} lineHeight={1} {...props} />
);
