import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ExternalRedirect from "./ExternalRedirect";
import Can from "../Can";

type Props = {
  component: ComponentType<*>,
  permissions: Array<Permission>,
}

export default ({ permissions, component: Component }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Can permissions={permissions} renderNoAccess={<ExternalRedirect to="/accessDenied" />}>
      <Component location={location} history={navigate} match={params} />
    </Can>
  );
};
