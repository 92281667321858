// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";
import { TOP_OFFERS_PROJECT_TYPES } from "./types/TopOffersForm.types";

const adsempiresmartlinkSchema = Joi.object({
  projectType: Joi.string(),
  vertical: Joi.string(),
  geo: Joi.array().min(1),
  platform: Joi.array().min(1),
  eCPM: [Joi.string(), Joi.number()],
});

const adsempiredirectSchema = Joi.object({
  projectType: Joi.string(),
  geo: Joi.array().min(1),
  offerId: Joi.string(),
  payout: [Joi.string(), Joi.number()],
  currencyId: Joi.number(),
  image: [Joi.object(), Joi.string().messages({
    "string.empty": "Logo is required and must be less than 10 MB",
  })],
});

const schema = Joi
  .when(Joi.object({ projectType: TOP_OFFERS_PROJECT_TYPES.adsempiresmartlink }).unknown(), {
    then: adsempiresmartlinkSchema.unknown(),
  })
  .when(Joi.object({ projectType: TOP_OFFERS_PROJECT_TYPES.adsempiredirect }).unknown(), {
    then: adsempiredirectSchema.unknown(),
  })
  .when(Joi.any(), {
    then: Joi.object({
      projectType: Joi.valid(
        TOP_OFFERS_PROJECT_TYPES.adsempiresmartlink,
        TOP_OFFERS_PROJECT_TYPES.adsempiredirect
      ).messages({
        "any.only": "Select valid project type",
      }),
    }).unknown(),
  })
  .messages({
    "number.base": "This field is required",
    "string.empty": "This field is required",
    "array.min": "This field is required",
    "date.base": "This field is required",
    "object.base": "This field is required",
  });

export default joiResolver(schema);
