// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { watchGetAffiliateLinks } from "../../sagas/getAffiliateLinks";
import { watchRemoveAffiliateLink } from "../../sagas/removeAffiliateLink";
import { watchSaveAffiliateLink } from "../../sagas/saveAffiliateLink";
import watchGetApiIp from "../../sagas/getApiIp";
import watchGenerateApiKey from "../../sagas/generateApiKey";
import watchSaveApiKey from "../../sagas/saveApiKey";
import { watchSaveCampaignSettingsOffer } from "../../sagas/saveCampaignSettingsOffer";
import { watchGetCampaignSettingsOffers } from "../../sagas/getCampaignSettingsOffers";
import { watchRestoreCampaignSettingsOffer } from "../../sagas/restoreCampaignSettingsOffer";
import watchGetPostback from "../../sagas/getPostbackEvents";
import watchSavePostback from "../../sagas/savePostbackEvent";
import watchDeletePostback from "../../sagas/deletePostbackEvent";
import watchGetPostbackInfo from "../../sagas/getPostbackInfo";
import watchGetListsPostback from "../../sagas/getListsPostback";
import watchChangePostbackStatus from "../../sagas/changePostbackStatus";
import watchSavePostbackInfo from "../../sagas/savePostbackInfo";
import watchSaveAdditionalPostback from "../../sagas/saveAdditionalPostback";
import watchGetPersonalInfoListsSaga from "../../sagas/getPersonalInfoLists/getPersonalInfoLists";
import watchGetAffiliate from "../../sagas/getAffiliate";
import watchUpdateAffiliate from "../../sagas/updateAffiliate";
import watchUpdatePaymentInfo from "../../sagas/updatePaymentInfo";
import watchSaveAffiliateSaga from "../../sagas/saveAffiliate";
import watchGetManagersListByAffiliateType from "../../sagas/getManagersListByAffiliateType";
import watchCopyAffIdPayment from "../../sagas/copyAffIdPayment";
import watchGetAffiliateSmartOffersListSaga from "../../sagas/getAffiliateSmartOffersListSaga";
import watchDeleteAffiliateSmartOffers from "../../sagas/deleteAffiliateSmartOffers";
import watchGetDropdownsByAffiliateTypeSaga from "../../sagas/getDropdownsByAffiliateType";
import watchChangeAffiliatePaymentVerifySaga from "../../sagas/changeAffiliatePaymentVerify";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetAffiliateLinks),
    call(watchRemoveAffiliateLink),
    call(watchSaveAffiliateLink),
    call(watchGetApiIp),
    call(watchGenerateApiKey),
    call(watchSaveApiKey),
    call(watchSaveCampaignSettingsOffer),
    call(watchGetCampaignSettingsOffers),
    call(watchRestoreCampaignSettingsOffer),
    call(watchGetPostback),
    call(watchSavePostback),
    call(watchDeletePostback),
    call(watchGetPostbackInfo),
    call(watchGetListsPostback),
    call(watchChangePostbackStatus),
    call(watchSavePostbackInfo),
    call(watchSaveAdditionalPostback),
    call(watchGetPersonalInfoListsSaga),
    call(watchGetAffiliate),
    call(watchUpdateAffiliate),
    call(watchUpdatePaymentInfo),
    call(watchSaveAffiliateSaga),
    call(watchGetManagersListByAffiliateType),
    call(watchCopyAffIdPayment),
    call(watchGetAffiliateSmartOffersListSaga),
    call(watchDeleteAffiliateSmartOffers),
    call(watchGetDropdownsByAffiliateTypeSaga),
    call(watchChangeAffiliatePaymentVerifySaga),
  ]);
}
