// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type Element,
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import {
  Check,
  Edit,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Table,
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  SelectSearchComponent,
  NumberSearchComponent,
  ViewLink,
  ShowMoreItems,
  IsActiveLabel,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { SMU_BRAND_LIST_TABLE } from "../../helpers/constants/smu";
import { userService } from "../../services/user";
import {
  getSmuBrandsSaga,
  changeSmuBrandsActivesSaga,
  type GetSmuBrandsSaga,
  type ChangeSmuBrandsActivesSaga,
} from "../../actions/smuBrands";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import type { PayloadItemType } from "./types/SmuBrandList.types";
import { useDictionaryList } from "../../hoocks/useDictionary";

const onCreate: () => void = () => {
  window.location = "/smuBrands/create";
};

const SmuBrandList: StatelessFunctionalComponent<{}> = () => {
  const tableProps: TableProps = useTable(SMU_BRAND_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const { selected }: TableProps = tableProps;
  const [countriesDropdown]: [DropDownObjItemType[], boolean] = useDictionaryList("smu", "country");

  const onGetSmuBrands: () => GetSmuBrandsSaga = () => (
    dispatch(getSmuBrandsSaga()));

  const onChangeSmuBrandsActives: (isActive: boolean) => ChangeSmuBrandsActivesSaga = (isActive) => (
    dispatch(changeSmuBrandsActivesSaga(isActive)));

  const columns: Array<Column<PayloadItemType>> = [
    {
      field: "id",
      label: "ID",
      searchable: true,
      sortable: true,
      SearchComponent: NumberSearchComponent,
      render: ({ id }: PayloadItemType): Element<typeof ViewLink> => <ViewLink id={id} name={id} viewPath="/smuBrands/:id" />,
    },
    {
      field: "productCompanyName",
      label: "Product company name",
      sortable: true,
      searchable: true,
    },
    {
      field: "domain",
      label: "Domain",
      sortable: true,
      searchable: true,
    },
    {
      field: "vertical",
      label: "Vertical",
      searchable: true,
      sortable: true,
    },
    {
      field: "niche",
      label: "Niche",
      searchable: true,
      sortable: true,
    },
    {
      field: "countries",
      label: "Countries",
      searchable: true,
      sortable: true,
      render: ({ countries }: PayloadItemType): Element<typeof ShowMoreItems> => <ShowMoreItems data={countries} />,
      SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={[
            {
              value: "",
              label: "All",
            },
            ...countriesDropdown,
          ]}
        />
      ),
    },
    {
      field: "url",
      label: "Url",
      searchable: true,
      sortable: true,
      render: ({ url } : PayloadItemType): Element<typeof IsActiveLabel> => <IsActiveLabel isActive={!!url} />,
      SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { value: "", label: "All" },
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
        />
      ),
    },
    {
      field: "image",
      label: "Banner",
      searchable: true,
      sortable: true,
      render: ({ image } : PayloadItemType): Element<typeof IsActiveLabel> => <IsActiveLabel isActive={!!image} />,
      SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { value: "", label: "All" },
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
        />
      ),
    },
    {
      field: "isActive",
      label: "Is Active",
      searchable: true,
      sortable: true,
      render: IsActiveLabel,
      SearchComponent: (searchProps: SearchComponentProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { value: "", label: "All" },
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
        />
      ),
    },
  ];

  return (
    <Box p={3}>
      {/* $FlowFixMe */}
      <Table
        {...tableProps}
        title="SMU Brands"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetSmuBrands}
        Actions={(
          <TableActions>
            <TableActionGroup>
              <SimpleTableActionButton
                tooltip="Edit"
                disabled={selected.length > 1 || !userService.can([{ path: "/api/v1/bank/brands", method: "POST" }])}
                onClick={() => {
                  const { id }: PayloadItemType = selected[0];
                  window.location = `/smuBrands/${id}`;
                }}
                Icon={<Edit />}
                data-testid="editButton"
              />
              <SimpleTableActionButton
                tooltip="Activate"
                disabled={(
                  selected.some(({ isActive }: PayloadItemType): boolean => isActive)
                  || !userService.can([{ path: "/api/v1/brands/bank/activate", method: "PUT" }]) // FIXME: fix acl route in backend - switch `brands` and `bank`
                )}
                onClick={(): ChangeSmuBrandsActivesSaga => onChangeSmuBrandsActives(true)}
                Icon={<Check />}
                data-testid="activateButton"
              />
              <SimpleTableActionButton
                tooltip="Deactivate"
                disabled={(
                  selected.some(({ isActive }: PayloadItemType): boolean => !isActive)
                  || !userService.can([{ path: "/api/v1/brands/bank/activate", method: "PUT" }]) // FIXME: fix acl route in backend - switch `brands` and `bank`
                )}
                onClick={(): ChangeSmuBrandsActivesSaga => onChangeSmuBrandsActives(false)}
                Icon={<RemoveCircle />}
                data-testid="deactivateButton"
              />
            </TableActionGroup>
          </TableActions>
        )}
      />
    </Box>
  );
};

export default SmuBrandList;
