// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import PayoutTermsFormContainer from "../../containers/PayoutTermsFormContainer";

function PayoutTermsCreate() {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="Payout terms">
      <Provider store={store}>
        <NotificationsContainer />
        <PayoutTermsFormContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default PayoutTermsCreate;
