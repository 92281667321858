/* eslint-disable no-unused-vars */
/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useState,
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import {
  Checkbox,
  Button,
  Drawer,
  IconButton,
  Paper,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  InputAdornment,
  MenuItem,
  Tooltip,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import { Close, HelpOutline } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import {
  findAffiliateValues,
} from "../../utils";
import type { UseState } from "../ManageAffiliateContent/types/ManageAffiliateContent.types";

type Props = {
  open: boolean,
  closeHandler: () => void,
  filters: $PropertyType<TableProps, "filters">,
  onChangeFilters: $PropertyType<TableProps, "onChangeFilters">,

  affiliateTypes: Array<string | Object>,
  statuses: Array<string | Object>,
  countries: Array<Object>,
  paymentMethods: Array<Object>,
};

type Classes = {
  findAffContent: string,
  input: string,
  inputField: string,
  filters: string,
  filtersPaper: string,
  filtersCapture: string,
  applyButton: string,
  filtersContent: string,
  title: string,
  hr: string,
}

const upperFirstLetter: (str: string) => string = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const switchPlaceHolder = (value) => {
  let placeholder;

  switch (value) {
    case "beName": placeholder = "Type beneficiary name";
      break;
    case "bankAccountName": placeholder = "Type bank account name";
      break;
    case "countryName": placeholder = "Type payout country";
      break;
    case "city": placeholder = "Type city/town";
      break;
    case "imName": placeholder = "Type im name";
      break;
    case "address": placeholder = "Type address";
      break;
    default: placeholder = "";
  }

  return placeholder;
};

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  findAffContent: {
    marginTop: 5,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  filters: {
    width: 400,
    flexShrink: 0,
    zIndex: theme.zIndex.modal,
  },
  filtersPaper: {
    width: 400,
    borderRight: 0,
  },
  filtersCapture: {
    margin: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    fontSize: 25,
  },
  applyButton: {
    marginLeft: theme.spacing(20),
  },
  filtersContent: {
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  hr: {
    width: "90%",
  },
}));

const Filters: StatelessFunctionalComponent<Props> = ({
  open,
  closeHandler,
  affiliateTypes,
  statuses: affiliateStatuses,
  countries,
  paymentMethods,
  filters,
  onChangeFilters,
}: Props) => {
  const classes = useStyles();

  const {
    status: filtersStatus,
    payoutCountryId,
    paymentMethodId,
    beName,
    bankAccountName,
    city,
    imName,
    address,
    ...restFilters
  }: $PropertyType<TableProps, "filters"> = filters;

  const [types, setTypes]: UseState<string[]> = useState(affiliateTypes || []);
  const [statuses, setStatuses]: UseState<string[]> = useState(Array.isArray(filtersStatus) ? filtersStatus : []);

  const [payoutCountry, setPayoutCountry]: UseState<{ id: number, country_name: string}> = useState((): {
    id: number,
    country_name: string
  } => countries.find((item: Object): boolean => item.id === payoutCountryId) || { id: 0, country_name: "" });

  const [findAffiliate, setFindAffiliate]: UseState<string> = useState();
  const [findAffValue, setFindAffValue]: UseState<string> = useState();

  const handleAffiliateTypeChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
    const { name, checked }: HTMLInputElement = event.target;
    if (name === "all") {
      setTypes(checked ? [...affiliateTypes, "all"] : []);
    }
    else if (checked) {
      setTypes((prevTypes: string[]): string[] => ([...prevTypes.filter((item: string): boolean => item !== "all"), name]));
    }
    else {
      setTypes((prevTypes: string[]): string[] => prevTypes.filter((item: string): boolean => item !== name && item !== "all"));
    }
  };

  const handleAffiliateStatusChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
    const { name, checked }: HTMLInputElement = event.target;
    if (name === "all") {
      setStatuses(checked ? [...affiliateStatuses, "all"] : []);
    }
    else if (checked) {
      setStatuses((prevTypes: string[]): string[] => ([...prevTypes.filter((item: string): boolean => item !== "all"), name]));
    }
    else {
      setStatuses((prevTypes: string[]): string[] => prevTypes.filter((item: string): boolean => item !== name && item !== "all"));
    }
  };

  const handleFindAffiliate: (event: SyntheticInputEvent<HTMLInputElement>) => void = (event) => {
    const { target: { value } }: SyntheticInputEvent<HTMLInputElement> = event;
    setPayoutCountry({ id: 0, country_name: "" });
    setFindAffValue("");
    setFindAffiliate(value);
  };

  const handleApply: () => void = () => {
    onChangeFilters({
      ...restFilters,
      type: types.filter((item: string): boolean => item !== "all"),
      status: statuses.filter((item: string): boolean => item !== "all"),
      [findAffiliate]: findAffiliate === "payoutCountryId" ? payoutCountry.id : findAffValue,
    });
    closeHandler();
  };

  const handleKeyDown: (event: SyntheticKeyboardEvent<typeof TextField>) => void = (e) => {
    if (e.key === "Enter") {
      handleApply();
    }
  };

  const inputField = {
    paymentMethodId: (
      <TextField
        select
        value={findAffValue || ""}
        onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
          const { target: { value } }: SyntheticInputEvent<HTMLInputElement> = event;
          setFindAffValue(value);
        }}
        variant="outlined"
        className={classes.findAffContent}
        data-testid="filter-value-select"
        InputProps={{
          classes: { adornedStart: classes.input },
          startAdornment: (
            <InputAdornment position="start">
              <HelpOutline />
            </InputAdornment>
          ),
        }}
      >
        {paymentMethods.map(({ id, name }: Object): Element<typeof MenuItem> => (
          <MenuItem key={id} value={String(id)}>{name}</MenuItem>
        ))}
      </TextField>
    ),
    payoutCountryId: (
      <Autocomplete
        className={classes.inputField}
        data-testid="filter-value-select"
        fullWidth
        disableClearable
        classes={{
          inputRoot: classes.input,
        }}
        options={countries}
        value={payoutCountry}
        onChange={(e, item) => setPayoutCountry(item)}
        getOptionLabel={(option) => option.country_name}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Payout country"
            variant="outlined"
            label="Payout country"
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            InputProps={{
              ...params.InputProps,
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment position="start">
                    <Tooltip title="Some text">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
    ),
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleApply}
      className={classes.filters}
      classes={{
        paper: classes.filtersPaper,
      }}
    >
      <Paper elevation={3}>
        <div className={classes.filtersCapture}>
          <IconButton aria-label="close" color="inherit" onClick={closeHandler} size="large">
            <Close fontSize="inherit" />
          </IconButton>
          <div>Filters</div>
          <Button
            className={classes.applyButton}
            variant="contained"
            color="primary"
            data-testid="applyButton"
            onClick={handleApply}
          >
            APPLY
          </Button>
        </div>
      </Paper>
      <div className={classes.filtersContent}>
        <div className={classes.title}>AFFILIATE TYPE</div>
        <FormControl component="fieldset" variant="standard" data-testid="affiliate-type-block">
          <FormGroup>
            {["all", ...affiliateTypes].map((item: string): Element<typeof FormControlLabel> => (
              <FormControlLabel
                key={item}
                control={(
                  <Checkbox
                    color="primary"
                    name={item}
                    data-testid={item}
                    onChange={handleAffiliateTypeChange}
                    checked={types.includes(item) || (types.length === affiliateTypes.length)} // FIXME: 2nd condition is required to check "all" option, when all options selected one by one. Filters should be moved in table
                  />
                )}
                label={upperFirstLetter(item)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
      <hr className={classes.hr} />
      <div className={classes.filtersContent}>
        <div className={classes.title}>AFFILIATE STATUS</div>
        <FormControl component="fieldset" variant="standard" data-testid="affiliate-status-block">
          <FormGroup>
            {["all", ...affiliateStatuses].map((item: string): Element<typeof FormControlLabel> => (
              <FormControlLabel
                key={item}
                control={(
                  <Checkbox
                    color="primary"
                    name={item}
                    data-testid={item}
                    onChange={handleAffiliateStatusChange}
                    checked={statuses.includes(item) || (statuses.length === affiliateStatuses.length)} // FIXME: 2nd condition is required to check "all" option, when all options selected one by one. Filters should be moved in table
                  />
                )}
                label={upperFirstLetter(item)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
      <hr className={classes.hr} />
      <div className={classes.filtersContent}>
        <div className={classes.title}>FIND AFFILIATE</div>
        <TextField
          select
          data-testid="filter-name-select"
          value={findAffiliate || ""}
          onChange={handleFindAffiliate}
          variant="outlined"
          className={classes.findAffContent}
          InputProps={{
            classes: { adornedStart: classes.input },
            startAdornment: (
              <InputAdornment position="start">
                <HelpOutline />
              </InputAdornment>
            ),
          }}
        >
          {findAffiliateValues}
        </TextField>
        {
          (inputField[findAffiliate])
            ? (inputField[findAffiliate])
            : (
              <TextField
                className={classes.findAffContent}
                fullWidth
                value={findAffValue || ""}
                data-testid="filter-value-input"
                variant="outlined"
                onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
                  const { target: { value } }: SyntheticInputEvent<HTMLInputElement> = event;
                  setFindAffValue(value);
                }}
                onKeyDown={handleKeyDown}
                FormHelperTextProps={{
                  style: { marginLeft: 0, marginRight: 0 },
                }}
                name="address"
                placeholder={switchPlaceHolder(findAffiliate)}
                InputProps={{
                  classes: { adornedStart: classes.input },
                  startAdornment: (
                    <InputAdornment position="start">
                      <HelpOutline />
                    </InputAdornment>
                  ),
                }}
              />
            )
        }
      </div>
    </Drawer>
  );
};

export default Filters;
