// @flow
import React, { useState, useCallback, useMemo } from "react";
import {
  Box, Grid, AppBar, Toolbar, Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavigationList, ListWithGroups, FooterInput } from "@fas/ui-core";
import HeadersList from "../HeadersList";
import {
  FactoryModel,
} from "../../services/templateModels";

const config = FactoryModel.getSubratesConfig();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  rootToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  rootBtn: {
    margin: `0 ${theme.spacing(2)}`,
  },
  modal: {
    background: theme.palette.background.mainBg,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: `${theme.shape.borderRadius}`,
  },
  modalHeader: {
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: "#fff",
  },
  modalFooter: {
    borderTop: `1px solid ${theme.palette.borderColor}`,
    padding: theme.spacing(2),
  },
  bodyContainer: {
    height: "100%",
    overflow: "hidden",
    minHeight: "400px",
  },
  navigatonListContainer: {
    background: "white",
    padding: `${theme.spacing(2)} 0 0`,
    borderRight: `1px solid ${theme.palette.borderColor}`,
  },
  listWithGroups: {
    background: "white",
    "& .search-result": {
      display: "none",
    },
  },
}));

type useStateType<S> = [S, ((S => S) | S) => void];

type TemplateType = {
  name: string,
  headersOrder: Array<string>,
  id?: string,
};

type Props = {
  template: TemplateType,
  onSave: (TemplateType) => void,
  onApply: (TemplateType) => void,
  onCancel: () => void,
  disableSave: boolean,
};

const TemplateModal = (props: Props) => {
  const {
    template, onSave, onApply, onCancel, disableSave,
  } = props;
  const classes = useStyles();
  const [isNavigated, setIsNavigated] = useState(false);
  const [selectedGroup, setSelectedGroup]: useStateType<string> = useState("General options");
  const [selectedHeaders, setSelectedHeaders]: useStateType<Array<string>> = useState(template.headersOrder);
  const [templateName, setTemplateName]: useStateType<string> = useState(template.name);

  /* first column */
  const groups = useMemo(() => FactoryModel.getSubratesGroups().map((groupName) => ({ value: groupName })), []);
  const navigationListClick = useCallback((val) => {
    setSelectedGroup(val);
    setIsNavigated(true);
  }, []);
  /* end first column */

  /* second col */
  const groupsFormated = useMemo(() => config.map((group) => ({
    ...group,
    filters: group.filters.map((filter) => ({
      ...filter,
      selected: selectedHeaders.includes(filter.filterKey),
    })),
  })), [selectedHeaders]);
  const listGroupToggleAll = useCallback((val) => {
    if (val) {
      setSelectedHeaders([
        ...selectedHeaders,
        ...FactoryModel.getAllSubrateAttrApiKey().filter((item: string): boolean => !selectedHeaders.includes(item)),
      ]);
    }
    else {
      setSelectedHeaders([]);
    }
  }, [selectedHeaders]);
  const isAllSelected: boolean = selectedHeaders.length === FactoryModel.getAllSubrateAttrModelKeys().length;
  const onScroll = useCallback(() => {
    if (selectedGroup && !isNavigated) {
      setIsNavigated(false);
      setSelectedGroup("");
    }
    setIsNavigated(false);
  }, [isNavigated, selectedGroup]);
  const onItemClick = useCallback((item) => {
    if (item.value) {
      setSelectedHeaders([...selectedHeaders, item.filterKey]);
    }
    else {
      setSelectedHeaders(selectedHeaders.filter((header) => header !== item.filterKey));
    }
  }, [selectedHeaders]);
  /* end  second col */

  /* third col */
  const prepearedList = useMemo(
    () => selectedHeaders.map(
      (i) => {
        const Model = FactoryModel.getSubrateModelByKey(i);
        return {
          title: Model.getTitle(),
          key: Model.getApiKey(),
        };
      }
    ),
    [selectedHeaders]
  );
  const changeOrder = useCallback((result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    const currentHeader = selectedHeaders[source.index];
    const rest = selectedHeaders.filter((v, index) => index !== source.index);
    setSelectedHeaders([
      ...rest.slice(0, destination.index),
      currentHeader,
      ...rest.slice(destination.index),
    ]);
  }, [selectedHeaders]);
  const onDeleteItemClick = useCallback((item) => {
    setSelectedHeaders(selectedHeaders.filter((header) => header !== item.key));
  }, [selectedHeaders]);
  /* end  third col */

  /* footer */
  const updateTemplate = useCallback(() => {
    onSave({ ...template, headersOrder: selectedHeaders, name: templateName });
  }, [selectedHeaders, templateName, template, onSave]);

  const saveTemplate = useCallback(() => {
    onSave({ headersOrder: selectedHeaders, name: templateName });
  }, [selectedHeaders, templateName, onSave]);
  const onChangeNameTemplate = useCallback((e) => setTemplateName(e), []);
  const handleApplyBtn = () => {
    onApply({ headersOrder: selectedHeaders, name: templateName });
  };
  /* end footer */

  return (
    <Box className={classes.modal} data-testid="templateModal">
      <Box className={classes.modalHeader}> Template options</Box>
      <Grid container className={classes.bodyContainer}>
        <Grid item xs={4} className={classes.navigatonListContainer}>
          <NavigationList
            list={groups}
            selected={selectedGroup}
            onChange={navigationListClick}
          />
        </Grid>
        <Grid className={classes.listWithGroups} item xs={4}>
          <ListWithGroups
            // $FlowFixMe
            groups={groupsFormated}
            isAllSelected={isAllSelected}
            onItemClick={onItemClick}
            toggleAll={listGroupToggleAll}
            onScroll={onScroll}
            scrollTo={selectedGroup}
          />
        </Grid>
        <Grid item xs={4}>
          <HeadersList
            list={prepearedList}
            onOrderChange={changeOrder}
            onDeleteItem={onDeleteItemClick}
          />
        </Grid>
      </Grid>
      <AppBar classes={{ root: classes.root }} position="static">
        <Toolbar classes={{ root: classes.rootToolbar }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FooterInput
              onSave={saveTemplate}
              onChangeName={onChangeNameTemplate}
              templateName={templateName}
              disableSave={!templateName}
              disableUpdate={!template.id}
              onUpdate={updateTemplate}
              disabled={!templateName.length || disableSave}
              updateTooltip=""
              buttonLabel="Save template"
              updateLabel="Update template"
              saveLabel="Save as new template"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button
              classes={{ root: classes.rootBtn }}
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              classes={{ root: classes.rootBtn }}
              color="primary"
              onClick={handleApplyBtn}
              disabled={!prepearedList.length}
              data-testid="template-modal-apply-btn"
            >
              Apply
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TemplateModal;
