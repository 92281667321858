// @flow
import {
  CHANGE_STATUS_POTENTIAL_PARTNER_SAGA,
  DELETE_POTENTIAL_PARTNER_SAGA,
  GET_POTENTIAL_PARTNER_DROPDOWN_SAGA,
  GET_POTENTIAL_PARTNER_LIST_SAGA,
} from "../../helpers/constants/potentialPartner";

export type DeletePotentialPartnerSagaType = {|
  type: typeof DELETE_POTENTIAL_PARTNER_SAGA,
|}

export type GetPotentialPartnerDropdownListsSagaType = {|
  type: typeof GET_POTENTIAL_PARTNER_DROPDOWN_SAGA,
|}

export type GetPotentialPartnerSagaType = {|
  type: typeof GET_POTENTIAL_PARTNER_LIST_SAGA,
|}

export type ChangeStatusPotentialPartnerSagaType = {|
  type: typeof CHANGE_STATUS_POTENTIAL_PARTNER_SAGA,
  status: string,
|}

export type Actions = DeletePotentialPartnerSagaType
| GetPotentialPartnerDropdownListsSagaType
| GetPotentialPartnerSagaType
| ChangeStatusPotentialPartnerSagaType

export function deletePotentialPartnerSaga(): DeletePotentialPartnerSagaType {
  return {
    type: DELETE_POTENTIAL_PARTNER_SAGA,
  };
}

export function changeStatusPotentialPartnerSaga(status: string): ChangeStatusPotentialPartnerSagaType {
  return {
    type: CHANGE_STATUS_POTENTIAL_PARTNER_SAGA,
    status,
  };
}

export function getPotentialPartnerListSaga(): GetPotentialPartnerSagaType {
  return {
    type: GET_POTENTIAL_PARTNER_LIST_SAGA,
  };
}
