// @flow
import {
  GET_PRODUCT_COMPANY_LIST_SAGA,
  CHANGE_PRODUCT_COMPANY_ACTIVES_SAGA,
} from "../../helpers/constants/productCompany";

export type GetProductCompanyListSaga = {|
  type: typeof GET_PRODUCT_COMPANY_LIST_SAGA,
|}

export type ChangeProductCompanyActivesSaga = {|
  type: typeof CHANGE_PRODUCT_COMPANY_ACTIVES_SAGA,
  isActive: boolean,
|}

export type Actions =
  GetProductCompanyListSaga
  | ChangeProductCompanyActivesSaga

export const getProductCompanyListSaga: () => GetProductCompanyListSaga = () => ({
  type: GET_PRODUCT_COMPANY_LIST_SAGA,
});

export const changeProductCompanyActivesSaga: (isActive: boolean) => ChangeProductCompanyActivesSaga = (isActive) => ({
  type: CHANGE_PRODUCT_COMPANY_ACTIVES_SAGA,
  isActive,
});
