// @flow
import {
  call,
  put,
  select,
  debounce,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_TEMPLATES_SAGA, GET_TEMPLATES_SAGA_DEBOUNCED } from "../../helpers/constants/templates";
import { getTemplates as fetchTemplates } from "../../services/templatesApi";
import { getSearchString, getCategory, getReportName } from "../../selectors/templates";
import { setTemplatesList } from "../../actions/templates";
import type { Template } from "../../reducers/templates";

export function* getTemplatesList(): Saga<void> {
  yield put(setLoading("getTemplatesList", true));
  try {
    const searchString: string = yield select(getSearchString);
    const category: string = yield select(getCategory);
    const reportName: string = yield select(getReportName);

    const { list: templates }: { list: Array<Template> } = yield call(fetchTemplates, reportName, {
      name: searchString,
      relationType: category !== "all" ? category : "",
    });

    yield put(setTemplatesList(templates));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch templates", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getTemplatesList", false));
  }
}

export function* watchGetTemplatesListDebounced(): Saga<void> {
  yield debounce(1000, GET_TEMPLATES_SAGA_DEBOUNCED, getTemplatesList);
}

export function* watchGetTemplatesList(): Saga<void> {
  yield takeEvery(GET_TEMPLATES_SAGA, getTemplatesList);
}
