// @flow
import React, { useRef, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonalInfoTablet from "../../../PersonalInfoTablet";

type Props = {
  title: string,
  name: string,
  editTitle: string,
  currentTablet: string,
  personalInfo: Object,
  lists: Array<any>,
  errors: Object,
  fields: Array<string>,
  handleChangeTablet: () => any,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectLabel: {
    marginLeft: 45,
    zIndex: 1,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  select: {
    "&:focus": {
      background: theme.palette.background.mainBg,
    },
  },
  helperIcon: {
    marginLeft: 5,
  },
  affiliateInfoItem: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
}));

// eslint-disable-next-line complexity
const AffiliateInfo = ({
  title,
  name,
  editTitle,
  currentTablet,
  handleChangeTablet,
  personalInfo,
  lists,
  errors,
  fields,
  setAffiliateField,
  affiliateId,
}: Props) => {
  const classes = useStyles();

  const idRef = useRef(null);
  const nameRef = useRef(null);
  const affiliateGroupRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const imNameRef = useRef(null);
  const phoneRef = useRef(null);

  const {
    id,
    firstName,
    lastName,
    email,
    imName,
    imType,
    phone,
    type,
  } = personalInfo;
  const {
    messengers = [],
  } = lists;
  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name: targetName } = e.target;
    setAffiliateField(targetName, value);
  };

  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Some text">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };

  useEffect(() => {
    if (idRef.current) {
      idRef.current.value = id;
    }
    if (nameRef.current) {
      nameRef.current.value = personalInfo.name;
    }
    if (affiliateGroupRef.current) {
      affiliateGroupRef.current.value = personalInfo.affiliateGroup;
    }
    if (firstNameRef.current) {
      firstNameRef.current.value = firstName;
    }
    if (lastNameRef.current) {
      lastNameRef.current.value = lastName;
    }
    if (emailRef.current) {
      emailRef.current.value = email;
    }
    if (imNameRef.current) {
      imNameRef.current.value = imName;
    }
    if (phoneRef.current) {
      phoneRef.current.value = phone;
    }
  });

  return (
    <PersonalInfoTablet
      title={title}
      name={name}
      currentTablet={currentTablet}
      editTitle={editTitle}
      handleChangeTablet={handleChangeTablet}
      errors={errors}
      fields={fields}
    >
      {type === "cpa" && (
        <TextField
          className={classes.inputField}
          fullWidth
          disabled={!!affiliateId}
          error={!!errors.id}
          helperText={errors.id && errors.id}
          variant="outlined"
          label="Affiliate ID"
          inputProps={{
            style: { background: classes.input },
          }}
          inputRef={idRef}
          FormHelperTextProps={{
            style: { marginLeft: 0, marginRight: 0 },
          }}
          placeholder="Affiliate ID"
          name="id"
          data-testid="id"
          onBlur={handleChangeField}
          InputProps={inputPropsTextField}
        />
      )}
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Affiliate name"
        error={!!errors.name}
        helperText={errors.name && errors.name}
        placeholder="Affiliate name"
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={nameRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="name"
        data-testid="name"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      {
        ["adromeda", "profitsocial", "topoffers"].includes(type) ? (
          <TextField
            className={classes.inputField}
            fullWidth
            variant="outlined"
            label="Affiliate group"
            error={!!errors.affiliateGroup}
            helperText={errors.affiliateGroup && errors.affiliateGroup}
            placeholder="Affiliate group"
            inputProps={{
              style: { background: classes.input },
            }}
            inputRef={affiliateGroupRef}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            name="affiliateGroup"
            data-testid="affiliateGroup"
            onBlur={handleChangeField}
            InputProps={inputPropsTextField}
          />
        ) : null
      }
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="First name"
        error={!!errors.firstName}
        helperText={errors.firstName && errors.firstName}
        placeholder="First name"
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={firstNameRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="firstName"
        data-testid="firstName"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Last name"
        error={!!errors.lastName}
        helperText={errors.lastName && errors.lastName}
        placeholder="Last name"
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={lastNameRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="lastName"
        data-testid="lastName"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Email"
        error={!!errors.email}
        helperText={errors.email && errors.email}
        placeholder="Email"
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={emailRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="email"
        data-testid="email"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={messengers}
        value={imType}
        data-testid="imType"
        onChange={(e, item) => setAffiliateField("imType", item)}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Instant messenger"
            variant="outlined"
            label="Instant messenger"
            error={!!errors.imType}
            helperText={errors.imType && errors.imType}
            InputProps={{
              ...params.InputProps,
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    className={classes.helperIcon}
                    position="start"
                  >
                    <Tooltip title="Some text">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
          />
        )}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Im name"
        error={!!errors.imName}
        helperText={errors.imName && errors.imName}
        placeholder="Im name"
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={imNameRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="imName"
        data-testid="imName"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        inputProps={{
          style: { background: classes.input },
        }}
        fullWidth
        variant="outlined"
        label="Phone number"
        error={!!errors.phone}
        helperText={errors.phone && errors.phone}
        inputRef={phoneRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="Phone number"
        name="phone"
        data-testid="phone"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />

    </PersonalInfoTablet>
  );
};

export default AffiliateInfo;
