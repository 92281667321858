// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box, Typography, Link } from "@mui/material";
import {
  Edit,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Table,
  TableActions,
  SimpleTableActionButton,
  SelectSearchComponent,
  ViewLink,
  NumberSearchComponent,
} from "@fas/ui-core";
import ConfirmActionButton from "@fas/ui-core/lib/TableActions/ConfirmActionButton";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import makeStyles from "@mui/styles/makeStyles";
import { PAYOUT_TERMS_LIST_TABLE } from "../../helpers/constants/payoutTermsList";
import externalRedirect from "../../components/App/ExternalRedirect";
import {
  getPayoutTermsListSaga,
  changePayoutTermsActives,
  type GetPayoutTermsListSaga,
  type ChangePayoutTermsActives,
} from "../../actions/payoutTermsList";
import type { PayoutTermsObjectType } from "./types/PayoutTermsList.types";
import { useDictionaryList } from "../../hoocks/useDictionary";

const onCreate: () => void = () => {
  window.location = "/payoutTerms/create";
};

export type Classes = {|
  smallTableCol: string,
  middleTableCol: string,
  longTableCol: string,
  dateTableCol: string,
|}

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    minWidth: "120px",
    maxWidth: "120px",
  },
  middleTableCol: {
    minWidth: "150px",
    maxWidth: "1500px",
  },
  longTableCol: {
    minWidth: "200px",
    maxWidth: "250px",
  },
  dateTableCol: {
    minWidth: "130px",
    maxWidth: "130px",
  },
}));

const PayoutTermsList: () => Element<typeof Box> = () => {
  const tableProps: TableProps = useTable(PAYOUT_TERMS_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();
  const classes: Classes = useStyles();

  const { selected }: TableProps = tableProps;

  const [payoutCompanies] = useDictionaryList("payoutTerms", "payoutCompanies");
  const [currencies] = useDictionaryList("payoutTerms", "currency");
  const [paymentMethods] = useDictionaryList("payoutTerms", "paymentMethod");
  const [affiliateTypes] = useDictionaryList("payoutTerms", "affiliateTypes");
  const [paymentTypes] = useDictionaryList("payoutTerms", "paymentTypes");

  const onGetPayoutTermsList: () => GetPayoutTermsListSaga = () => (
    dispatch(getPayoutTermsListSaga()));
  const onChangePayoutTermsActives: (isActive: 1 | 0) => ChangePayoutTermsActives = (isActive) => (
    dispatch(changePayoutTermsActives(isActive)));

  const columns: Array<Column<PayoutTermsObjectType>> = [
    {
      field: "id",
      label: "ID",
      searchable: true,
      sortable: true,
      className: classes.smallTableCol,
      render: ({ id }: PayoutTermsObjectType): Element<typeof ViewLink> => <ViewLink id={id} name={id} viewPath="/payoutTerms/:id" />,
      SearchComponent: NumberSearchComponent,
    },
    {
      field: "affiliateType",
      label: "Affiliate type",
      searchable: true,
      sortable: true,
      className: classes.longTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={[
            { label: "All", value: "" },
            ...affiliateTypes,
          ]}
          label="affiliateType"
        />
      ),
    },
    {
      field: "paymentsType",
      label: "Payments type",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={[
            { label: "All", value: "" },
            ...paymentTypes,
          ]}
          label="paymentsType"
        />
      ),
    },
    {
      field: "paymentMethod",
      label: "Payment method",
      searchable: true,
      sortable: true,
      className: classes.longTableCol,
      render: ({ paymentMethod }: PayoutTermsObjectType): Element<typeof Typography> => (
        <Typography>{paymentMethod.name}</Typography>
      ),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={[
            { label: "All", value: "" },
            ...paymentMethods,
          ]}
          label="paymentMethod"
        />
      ),
    },
    {
      field: "paymentMinAmount",
      label: "Min payment, $",
      sortable: true,
      className: classes.smallTableCol,
      render: ({ paymentMinAmount }: PayoutTermsObjectType): Element<typeof Typography> => <Typography>{paymentMinAmount === null ? "-" : paymentMinAmount}</Typography>,
    },
    {
      field: "paymentMaxAmount",
      label: "Max payment, $",
      sortable: true,
      className: classes.smallTableCol,
      render: ({ paymentMaxAmount }: PayoutTermsObjectType): Element<typeof Typography> => <Typography>{paymentMaxAmount === null ? "-" : paymentMaxAmount}</Typography>,
    },
    {
      field: "currency",
      label: "Currency",
      searchable: true,
      sortable: true,
      className: classes.smallTableCol,
      render: ({ currency }: PayoutTermsObjectType): Element<typeof Typography> => (
        <Typography>{currency.currency}</Typography>
      ),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={[
            { label: "All", value: "" },
            ...currencies,
          ]}
          label="currency"
        />
      ),
    },
    {
      field: "payoutCompany",
      label: "Payout company",
      searchable: true,
      sortable: true,
      className: classes.longTableCol,
      render: ({ payoutCompany }: PayoutTermsObjectType): Element<typeof Typography> => (
        <Typography>{payoutCompany.name}</Typography>
      ),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={[
            { label: "All", value: "" },
            ...payoutCompanies,
          ]}
          label="payoutCompany"
        />
      ),
    },
    {
      field: "updatedAt",
      label: "Updated at",
      sortable: true,
      className: classes.dateTableCol,
      render: ({ updatedAt }: PayoutTermsObjectType): Element<typeof Typography> => <Typography>{moment(updatedAt).format("YYYY-MM-DD HH:mm:ss")}</Typography>,
    },
    {
      field: "updated",
      label: "Updated by",
      sortable: true,
      className: classes.longTableCol,
      render: ({ updated }: PayoutTermsObjectType): Element<typeof Typography> => (
        <Typography>{updated.name}</Typography>
      ),
    },
  ];
  return (
    <Box p={3} data-testid="payout-terms">
      {/* $FlowFixMe */}
      <Table
        {...tableProps}
        title="Payout Terms"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        rowsPerPage={[100, 500, 1000]}
        onCreate={onCreate}
        onLoad={onGetPayoutTermsList}
        Actions={(
          <TableActions>
            <SimpleTableActionButton
              tooltip="Edit"
              disabled={selected.length > 1}
              onClick={(): null => externalRedirect({ to: "/payoutTerms/:id".replace(":id", selected[0].id) })}
              Icon={<Edit />}
            />
            <ConfirmActionButton
              items={selected}
              tooltip="Deactivate"
              confirmText="Are you sure you want to delete "
              confirmTitle="Confirm deletion"
              onClick={(): mixed => onChangePayoutTermsActives(0)}
              Icon={<RemoveCircle />}
              ItemRow={({ id }: PayoutTermsObjectType): Element<Typography> => (
                <Typography component="p" key={id}>
                  id:
                  {" "}
                  <Link component="a" href={`payoutTerms/${id}`} underline="always">
                    {id}
                  </Link>
                </Typography>
              )}
            />
          </TableActions>
        )}
      />
    </Box>
  );
};

export default PayoutTermsList;
