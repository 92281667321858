// @flow
import {
  all, call, put, takeEvery,
} from "redux-saga/effects";
import type { Effect, Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_POTENTIAL_PARTNER_DROPDOWN_SAGA } from "../../helpers/constants/potentialPartner";
import { setDictionary } from "../../actions/dictionary";
import { getPotentialPartnerDropdown } from "../../services/potentialPartnerApi";

export function* makeFetch(): Saga<void> {
  try {
    const tasks: Effect[] = [
      call(getPotentialPartnerDropdown, "country"),
      call(getPotentialPartnerDropdown, "accountType"),
      call(getPotentialPartnerDropdown, "vertical"),
      call(getPotentialPartnerDropdown, "status"),
    ];

    const [
      country,
      accountType,
      vertical,
      status,
    ] = yield all(tasks);

    yield put(setDictionary({
      country,
      accountType,
      vertical,
      status,
    }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch dropdown lists", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchPotentialPartnerDropdownList(): Saga<void> {
  yield takeEvery(GET_POTENTIAL_PARTNER_DROPDOWN_SAGA, (makeFetch: Function));
}
