import React, { useEffect, useRef } from "react";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Loader } from "@fas/ui-core";
import PaymentInfoTablet from "../PaymentInfoTablet";

type Props = {
  loading: boolean,
  lists: Array<any>,
  errors: Object,
  fields: Array<string>,
  setField: (string, string) => any,
  hasCopyId: boolean,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  helperIcon: {
    marginLeft: 5,
  },
}));

const beAddressPayMethodIds = {
  2: ["adsempiredirect", "adsempiresmartlink", "adromeda", "directdeals", "adveryarbitrage"],
  9: ["adsempiredirect", "adsempiresmartlink", "adromeda"],
  10: ["adsempiredirect", "adsempiresmartlink", "adromeda", "directdeals", "adveryarbitrage"],
  11: ["adromeda"],
  12: ["adromeda"],
}

const RegistrationDetails = ({
  loading,
  title,
  lists,
  affType,
  errors,
  fields,
  paymentInfo,
  hasCopyId,
  setField,
}: Props) => {
  const classes = useStyles();

  const bankAccountNameRef = useRef(null);
  const beNameRef = useRef(null);
  const beAddressRef = useRef(null);

  const {
    paymentMethodId,
    bankAccountName,
    beAddress,
    beCountry,
    beName,
  } = paymentInfo;
  const {
    countries = [],
  } = lists;

  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setField(name, value);
  };

  useEffect(() => {
    if (bankAccountNameRef.current) {
      bankAccountNameRef.current.value = bankAccountName;
    }
    if (beNameRef.current) {
      beNameRef.current.value = beName;
    }
    if (beAddressRef.current) {
      beAddressRef.current.value = beAddress;
    }
  });

  return (
    <Loader loading={loading}>
      <PaymentInfoTablet
        title={title}
        errors={errors}
        fields={fields}
      >
        {paymentMethodId && paymentMethodId.id === 1 ? (
          <>
            <TextField
              disabled={hasCopyId}
              fullWidth
              className={classes.inputField}
              name="bankAccountName"
              data-testid="bankAccountName"
              variant="outlined"
              label="Bank account name"
              placeholder="LivkMan Limited"
              error={!!errors.bankAccountName}
              helperText={errors.bankAccountName && errors.bankAccountName}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={bankAccountNameRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
            <TextField
              fullWidth
              className={classes.inputField}
              disabled={!(paymentMethodId && paymentMethodId.id === 1 && !hasCopyId)}
              name="beAddress"
              data-testid="beAddress"
              variant="outlined"
              label="Beneficiary address"
              placeholder="1 Northnumberland Avenue Trafalgar Square, London"
              error={!!errors.beAddress}
              helperText={errors.beAddress && errors.beAddress}
              inputProps={{
                style: { background: classes.input },
              }}
              inputRef={beAddressRef}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              onBlur={handleChangeField}
              InputProps={{
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </>
        ) : (
          <TextField
            disabled={hasCopyId}
            fullWidth
            className={classes.inputField}
            name="beName"
            data-testid="beName"
            variant="outlined"
            label="Beneficiary name"
            placeholder="John Smith"
            error={!!errors.beName}
            helperText={errors.beName && errors.beName}
            inputProps={{
              style: { background: classes.input },
            }}
            inputRef={beNameRef}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            onBlur={handleChangeField}
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                  >
                    <Tooltip title="Some text">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
        {(paymentMethodId && beAddressPayMethodIds[paymentMethodId.id] && beAddressPayMethodIds[paymentMethodId.id].includes(affType)) && (
          <TextField
            disabled={hasCopyId}
            fullWidth
            className={classes.inputField}
            name="beAddress"
            data-testid="beAddress"
            variant="outlined"
            label="Beneficiary address"
            placeholder="1 Northnumberland Avenue Trafalgar Square, London"
            error={!!errors.beAddress}
            helperText={errors.beAddress && errors.beAddress}
            inputProps={{
              style: { background: classes.input },
            }}
            inputRef={beAddressRef}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
            onBlur={handleChangeField}
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                  >
                    <Tooltip title="Some text">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
        <Autocomplete
          disabled={hasCopyId}
          className={classes.inputField}
          fullWidth
          classes={{
            inputRoot: classes.input,
          }}
          options={countries}
          value={beCountry}
          data-testid="beCountry"
          onChange={(e, item) => setField("beCountry", item)}
          getOptionLabel={(option) => option.country_name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose country"
              variant="outlined"
              label="Beneficiary country"
              error={!!errors.beCountry}
              helperText={errors.beCountry && errors.beCountry}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </PaymentInfoTablet>
    </Loader>
  );
};

export default RegistrationDetails;
