// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Edit,
  MarkEmailRead,
  EmailOutlined,
  Send,
  ScheduleSend,
} from "@mui/icons-material";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import { userService } from "../../services/user";
import type { ActionsProps } from "./CrmMailsList.types";

const Actions: StatelessFunctionalComponent<ActionsProps> = ({
  onEdit,
  onChangeCrmMailsStatus,
  onSendCrmMails,
}) => (
  <TableActions>
    <TableActionGroup>
      <SimpleTableActionButton
        tooltip="Edit"
        disabled={!userService.can([])}
        onClick={onEdit}
        Icon={<Edit />}
        data-testid="editButton"
      />
      <SimpleTableActionButton
        tooltip="Ready to send"
        disabled={!userService.can([])}
        onClick={() => {
          onChangeCrmMailsStatus("Ready to send");
        }}
        Icon={<MarkEmailRead />}
        data-testid="readyToSend"
      />
      <SimpleTableActionButton
        tooltip="Change to new"
        disabled={!userService.can([])}
        onClick={() => {
          onChangeCrmMailsStatus("New");
        }}
        Icon={<EmailOutlined />}
        data-testid="changeToNew"
      />
      <SimpleTableActionButton
        tooltip="Test send"
        disabled={!userService.can([])}
        onClick={() => {
          onSendCrmMails("test");
        }}
        Icon={<ScheduleSend />}
        data-testid="testSend"
      />
      <SimpleTableActionButton
        tooltip="Send now"
        disabled={!userService.can([])}
        onClick={() => {
          onSendCrmMails("send");
        }}
        Icon={<Send />}
        data-testid="sendNow"
      />
    </TableActionGroup>
  </TableActions>
);

export default Actions;
