// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put,
  takeEvery,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import FileSaver from "file-saver";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import type { StoreType } from "@fas/ui-framework/lib/services/table/types/table.types";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import setLoading from "../../actions/loading/actions";
import {
  ADVERTISER_INVOICES_DOWNLOAD_SAGA,
  ADVERTISER_INVOICES_TABLE,
} from "../../helpers/constants/advertiserInvoices";
import { fetchAdvertiserInvoicesFile } from "../../services/advertiserInvoicesApi";

export function* downloadAdvertiserInvoice(): Saga<void> {
  try {
    yield put(setLoading(ADVERTISER_INVOICES_DOWNLOAD_SAGA, true));
    // eslint-disable-next-line max-len
    const selected: DataItem[] = yield select((state: StoreType): DataItem[] => getTableSelections(state, ADVERTISER_INVOICES_TABLE));

    const file: File = yield call(fetchAdvertiserInvoicesFile, selected.map(({ id }) => id));
    FileSaver.saveAs(file, file.name || `file.${file.type.split("/").pop()}`);
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error) || "Invoice download failed", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(ADVERTISER_INVOICES_DOWNLOAD_SAGA, false));
  }
}

export default function* watchDownloadAdvertiserInvoice(): Saga<void> {
  yield takeEvery(ADVERTISER_INVOICES_DOWNLOAD_SAGA, (downloadAdvertiserInvoice: Function));
}
