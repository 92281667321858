// @flow
import {
  takeEvery,
  put,
  call,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { SAVE_CPA_OFFER_SAGA } from "../../helpers/constants/cpaoffer";
import { offerIsFetching, offerError } from "../../actions/cpaoffer";
import { sanitizeFormExt, sanitizeFormInt } from "../../actions/cpaoffer/utils";
import { saveCpaOffer } from "../../services/cpaOffersApi";
import {
  getCpaOfferFormData, getCpaOfferFormList,
  getCpaOfferIsCloneStatus,
} from "../../selectors/cpaOfferForm";
import type { RowForm } from "../../reducers/cpaoffer/types";

export function* makeFetch(): Saga<void> {
  yield put(offerIsFetching(true));
  const data: RowForm = yield select(getCpaOfferFormData);
  const lists = yield select(getCpaOfferFormList);
  const isClone: boolean = yield select(getCpaOfferIsCloneStatus);
  try {
    const form = data.isExt && data.isExt.isExt === 0 ? sanitizeFormInt(data) : sanitizeFormExt(data);
    // eslint-disable-next-line no-unused-vars
    const { id, isExt, ...withoutId } = form;
    // spike because of CPA
    let { siteId, group } = form;
    const isSiteAvailable = Boolean(lists.offerName.find((i) => i.id === siteId));
    const isBrandAvailable = lists.offerGroup.includes(group);
    if (isClone && !isExt && !isSiteAvailable) {
      siteId = "";
    }
    if (isClone && !isExt && !isBrandAvailable) {
      group = "";
    }
    // end spike
    const response = yield call(saveCpaOffer, isClone ? {
      ...withoutId, siteId, group, isExt,
    } : {
      ...form, siteId, group,
    });
    yield put(addNotification({ message: `Saved successfuly - ${response.data.data?.id || data.id}`, severity: "success" }));
    window.location = "/cpaOffers";
  }
  catch (error) {
    yield put(offerError(error.response.data.errors));
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(offerIsFetching(false));
  }
}

export default function* watchSaveCpaOffer(): Saga<void> {
  // todo add event type here
  yield takeEvery(SAVE_CPA_OFFER_SAGA, (makeFetch: Function));
}
