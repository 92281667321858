import React from "react";
import { AppBar, Tabs } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

type Props = {
  tab: string,
  handleChangeTab: () => any,
};

const useStyles = makeStyles((theme) => ({
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const NavigationTabs = ({
  tab,
  handleChangeTab,
  children,
}: Props) => {
  const classes = useStyles();

  return (
    <AppBar position="static" color="default">
      <Tabs
        classes={{
          indicator: classes.tabsIndicator,
        }}
        value={tab}
        onChange={handleChangeTab}
      >
        {React.Children.map(children, (child) => child)}
      </Tabs>
    </AppBar>
  );
};

export default NavigationTabs;
