// @flow
import React, {
  type Element,
} from "react";
import {
  SelectSearchComponent,
  ViewLink,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { CrmMailsListData, Classes } from "./CrmMailsList.types";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

export default function getColumns(
  classes: Classes,
  dropdowns: {
    statusMsg: Array<DropDownObjItemType>,
    userPlatforms: Array<DropDownObjItemType>,
  }
): Array<Column<CrmMailsListData>> {
  return [
    {
      field: "id",
      label: "ID",
      searchable: true,
      sortable: true,
      className: classes.smallTableCol,
      render: ({ id }: CrmMailsListData): Element<typeof ViewLink> => <ViewLink id={String(id)} name={String(id)} viewPath="/crm/mails/:id" />,
    },
    {
      field: "name",
      label: "Name",
      sortable: true,
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "dateSend",
      label: "Date send",
      sortable: true,
      className: classes.smallTableCol,
    },
    {
      field: "statusMsg",
      label: "Status Message",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdowns.statusMsg}
        />
      ),
    },
    {
      field: "userPlatform",
      label: "User Platform",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdowns.userPlatforms}
        />
      ),
    },
    {
      field: "createdAt",
      label: "Created At",
      sortable: true,
      className: classes.smallTableCol,
    },
    {
      field: "updatedBy",
      label: "Updated By",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
    },
  ];
}
