// @flow
import React, { type Node } from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

export type Props = {|
  name: string,
  error: string,
  propName: string,
  value: *,
  onChange: (*) => *,
  children: Node,
|}

function PassPropForm({
  value, onChange, children, propName,
}: Props): Node {
  function capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
  const [child]: Node[] = React.Children.toArray(children);
  if (React.isValidElement(child)) {
    // $FlowFixMe this is valid child element
    return React.cloneElement(child, {
      [propName]: value,
      [`onChange${capitalize(propName)}`]: onChange,
    });
  }

  return child;
}

PassPropForm.defaultProps = {
  onChange: () => {},
  error: "",
  value: "",
};

export default withFormField(PassPropForm);
