// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Loader,
} from "@fas/ui-core";
import {
  Box,
} from "@mui/material";
import NextPaymentDateAvailable from "./NextPaymentDateAvailable";
import NextPaymentDateUnavailable from "./NextPaymentDateUnavailable";
import type { NextPaymentDateType } from "./types/NextPaymentDateLabel.types";

type Props = {
  loading: boolean,
  ...NextPaymentDateType,
}

const NextPaymentDateLabel: StatelessFunctionalComponent<Props> = ({
  loading,
  payoutPeriod,
  comment,
}: Props) => (
  <Box width={1}>
    <Loader loading={loading}>
      {payoutPeriod && !comment
        ? <NextPaymentDateAvailable payoutPeriod={payoutPeriod} />
        : <NextPaymentDateUnavailable comment={comment} />}
    </Loader>
  </Box>
);

export default NextPaymentDateLabel;
