// @flow
import environment from "environment";
import { requestService } from "../axiosRequest";
import type { AffiliateSmartOfferType } from "../../containers/AffiliateSmartOffersList/types/AffiliateSmartOffersList.types";

export const fetchAffiliateSmartOffersList: (
  options: {
    affiliateId: string,
  },
) => Promise<{ data: Array<AffiliateSmartOfferType> }> = (
  { affiliateId }
) => requestService
  .get(environment.endpoints.get.getAffiliateSmartOffersList.replace("{affiliateId}", affiliateId))
  .then(({ data }) => data);

export function deleteAffiliateSmartOffers(ids: string[]): Promise<{}> {
  return requestService
    .post(environment.endpoints.post.deactivateAffiliateSmartOffers, {
      ids,
    });
}
