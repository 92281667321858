// @flow
/* eslint-disable import/max-dependencies */
import {
  debounce,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
  getTableSelections,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { setTableData, changeTableItemsTotalAmount, setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import type { Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  GET_CRM_TEMPLATES_LIST_SAGA,
  CRM_TEMPLATES_TABLE,
} from "../../helpers/constants/crm";
import { fetchCrmTemplatesList } from "../../services/crmTemplatesApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(CRM_TEMPLATES_TABLE, true));

    const page: number = yield select(getTablePage, CRM_TEMPLATES_TABLE);
    const limit: number = yield select(getTablePageSize, CRM_TEMPLATES_TABLE);
    const sorting: Sorting = yield select(getTableSorting, CRM_TEMPLATES_TABLE);
    const { productId, defaultLocale, ...filters } = yield select(getTableFilters, CRM_TEMPLATES_TABLE);
    const selections = yield select(getTableSelections, CRM_TEMPLATES_TABLE);

    if (selections.length) {
      yield put(setTableSelection(CRM_TEMPLATES_TABLE, []));
    }

    const options: {
      page: number,
      limit: number,
      filters: *,
      sorting: {
        current: string,
        direction: "asc" | "desc",
      },
      headers: Array<string>,
    } = {
      page,
      limit,
      filters: {
        ...filters,
        ...(defaultLocale && { defaultLocale: defaultLocale.map(({ value }) => value) }),
        ...(productId && { productId: productId.map(({ value }) => value) }),
      },
      sorting: {
        current: Object.keys(sorting)[0],
        direction: (Object.values(sorting)[0] : any),
      },
      headers: [
        "id",
        "name",
        "productId",
        "defaultLocale",
        "createdAt",
      ],
    };
    const {
      data: {
        data,
        count,
      },
    }: { data: { data: { [key: string]: * }[], count: number } } = yield call(fetchCrmTemplatesList, options);

    yield put(setTableData(CRM_TEMPLATES_TABLE, data));
    yield put(changeTableItemsTotalAmount(CRM_TEMPLATES_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));

    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CRM_TEMPLATES_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(600, GET_CRM_TEMPLATES_LIST_SAGA, (makeFetch: Function));
}
