// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { GET_SMU_MARKETING_COMPANIES_SAGA, SMU_DEPARTMENT_LIST_TABLE } from "../../helpers/constants/smu";
import { getSmuMarketingCompanies } from "../../services/smuApi";
import type { IncomePayloadItemType, PayloadItemType } from "../../containers/SmuDepartmentsList/types/SmuDepartmentsList.types";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SMU_DEPARTMENT_LIST_TABLE, true));

    const page: number = yield select(getTablePage, SMU_DEPARTMENT_LIST_TABLE);
    const limit: number = yield select(getTablePageSize, SMU_DEPARTMENT_LIST_TABLE);
    const sorting: Sorting = yield select(getTableSorting, SMU_DEPARTMENT_LIST_TABLE);
    const filters: Filters = yield select(getTableFilters, SMU_DEPARTMENT_LIST_TABLE);

    const {
      data: { data: payload, count = 0 },
    }: {
      data: {
        data: IncomePayloadItemType[],
        count: number
      }
    } = yield call(getSmuMarketingCompanies, {
      page, limit, sorting, filters, headers: ["company", "department", "id"],
    });

    const data: PayloadItemType[] = payload.map((item: IncomePayloadItemType): PayloadItemType => ({
      id: item.id,
      marketingCompanyId: item.marketingCompanyId,
      departments: item.department.split(","),
      title: item.company.name,
    }));

    yield put(setTableData(SMU_DEPARTMENT_LIST_TABLE, data));
    yield put(changeTableItemsTotalAmount(SMU_DEPARTMENT_LIST_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch companies list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SMU_DEPARTMENT_LIST_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_SMU_MARKETING_COMPANIES_SAGA, (makeFetch: Function));
}
