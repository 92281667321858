/* eslint-disable import/max-dependencies */
// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Outlet } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";

const GlobalCss = withStyles({
  "@global": {
    ".middle-table-col": {
      minWidth: "150px",
      maxWidth: "150px",
      wordBreak: "break-all",
    },
    ".small-table-col": {
      width: "100px",
      minWidth: "100px",
      wordBreak: "break-all",
    },
    ".long-table-col": {
      minWidth: "250px",
      maxWidth: "250px",
      wordBreak: "break-all",
    },
    ".very-long-table-col": {
      minWidth: "400px",
      maxWidth: "400px",
      wordBreak: "break-all",
    },
    ".table-panel-active-buttons": {
      background: "#6ba0fb",
    },
    ".white-btn": {
      background: "white",
    },
    ".hidden": {
      visibility: "hidden",
    },
  },
})(() => null);

function Subrates() {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="CPA - Subrates">
      <Provider store={store}>
        <NotificationsContainer />
        <GlobalCss />
        <Outlet />
      </Provider>
    </DocumentTitle>
  );
}

export default Subrates;
