// @flow
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

function joiResolver(schema: Object) {
  return function (values: Object): ErrorsMapper {
    const { error: { details = {} } = {} } = schema.validate(values, { abortEarly: false });
    return Object.keys(details).reduce((acc: ErrorsMapper, key: string) => ({
      ...acc,
      [details[key].path.join(".")]: { message: details[key].message },
    }), {});
  };
}

export default joiResolver;
