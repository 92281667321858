import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  contentBody: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(4),
  },
  hr: {
    display: "flex",
    backgroundColor: "lightgrey",
    width: "100%",
    height: "1px",
  },
  summaryDropdown: {
    width: "11%",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.mainBg,
  },
  inlineSpace: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
  },
  managers: {
    width: "11%",
    float: "left",
    margin: theme.spacing(2),
  },
}));
