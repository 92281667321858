// @flow
import React, {
  useRef,
  type StatelessFunctionalComponent,
  type Element,
  type Node,
} from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
  type DroppableProvided,
  type DraggableRubric,
} from "react-beautiful-dnd";
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from "@enykeev/react-virtualized";
import {
  makeStyles,
  type ClassNameMap,
  type Theme,
} from "@mui/styles";
import { ColumnItemCard } from "@fas/ui-core";
import {
  Box,
  Typography,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactDOM from "react-dom";
import type { UseStylesTypes } from "../../../helpers/types";

const useStyles: UseStylesTypes = makeStyles((theme: Theme): ClassNameMap => ({
  filtersColumn: {
    background: theme.palette.background.mainBg,
    height: "100%",
  },
  list: {
    paddingRight: theme.spacing(2),
  },
}));

export type Item = {
  title: string,
  key: string,
  InputComponent: Node,
  isDragDisabled: boolean,
};

type Props = {
  list: Array<Item>,
  onOrderChange: (result: DropResult) => void,
  invisibleCount: number,
};

const cache: CellMeasurerCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 0,
});

const ReportColumns : StatelessFunctionalComponent<Props> = ({
  list,
  onOrderChange,
  invisibleCount,
}: Props) => {
  const classes: ClassNameMap = useStyles();

  const header = useRef<HTMLElement | null>(null);
  const headerHeight: number = header.current ? header.current.getBoundingClientRect().height : 0;

  const rowRender = ({
    index,
    key,
    style,
    parent,
  }): Element<Box> => {
    const { InputComponent, isDragDisabled, title }: Item = list[index];

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        <Box key={key} style={style} data-testid={`column-${title}`}>
          {!isDragDisabled && (
            <Draggable draggableId={index.toString()} index={index}>
              {(provided): Element<Box> => (
                <Box
                  mb={1}
                  ref={provided.innerRef}
                  onClick={(e) => e.currentTarget.scrollIntoView()}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {InputComponent}
                </Box>
              )}
            </Draggable>
          )}
          {isDragDisabled && (
            <Box
              mb={1}
              onClick={(e) => e.currentTarget.scrollIntoView()}
            >
              {InputComponent}
            </Box>
          )}
        </Box>
      </CellMeasurer>
    );
  };

  const cloneRender = (provided: *, snapshot: *, rubric: DraggableRubric): Element<Box> => (
    <Box mb={1} height={cache.rowHeight}>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ColumnItemCard
          name={list[rubric.source.index].title}
          item={list[rubric.source.index].key}
        />
      </div>
    </Box>
  );

  return (
    <Box className={classes.filtersColumn} pl={2}>
      <Box py={2} display="flex" justifyContent="space-between" ref={header}>
        <Typography variant="subtitle1">
          REPORT COLUMNS
        </Typography>
        {!!invisibleCount && (
          <Box display="flex" alignItems="center" pr={2}>
            <Typography variant="subtitle1">
              {invisibleCount}
            </Typography>
            <VisibilityOffIcon />
          </Box>
        )}
      </Box>
      <DragDropContext onDragEnd={onOrderChange}>
        <Droppable
          droppableId="droppable"
          mode="virtual"
          renderClone={cloneRender}
        >
          {(droppableProvided: DroppableProvided): Element<AutoSizer> => (
            <AutoSizer>
              {({ width, height }: { width: number, height: number }): Element<List> => (
                <List
                  width={width}
                  height={height - headerHeight}
                  rowHeight={cache.rowHeight}
                  rowCount={list.length}
                  rowRenderer={rowRender}
                  className={classes.list}
                  style={{
                    paddingBottom: height - headerHeight,
                    overflowY: "scroll",
                  }}
                  overscanRowCount={4}
                  id="template-attributes"
                  ref={(ref) => {
                    if (ref) {
                      // eslint-disable-next-line react/no-find-dom-node
                      const domNode = ReactDOM.findDOMNode(ref);
                      if (domNode instanceof HTMLElement) {
                        droppableProvided.innerRef(domNode);
                      }
                      ref.forceUpdateGrid();
                    }
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default React.memo<Props>(ReportColumns);
