import { useQuery } from "@tanstack/react-query";
import {
  addNotification,
  changeTableItemsTotalAmount,
  setTableData,
  TableProps,
  useTable,
} from "@fas/ui-framework";
import { useEffect } from "react";
import useTenantId from "../useTenantId";
import api from "../../../services/api";
import type { FiltersType, ShaveModificationType } from "./types";
import useShaveModificationsQueryKey from "./useShaveModificationsQueryKey";
import { useDispatch } from "../store";
import { SHAVE_MODIFICATIONS_TABLE_KEY } from "../../../helpers/constants/affiliates";

export function getShaveModificationId(item?: ShaveModificationType | null) {
  if (!item) return "";
  return `${item.affiliateId}-${item.country}`;
}

export function isEqualShaveModification(a?: ShaveModificationType, b?: ShaveModificationType) {
  return !!a && !!b && getShaveModificationId(a) === getShaveModificationId(b);
}

export const defaultShaveModificationsFilters: FiltersType = {};

function useShaveModifications() {
  const dispatch = useDispatch();
  const tableProps = useTable<ShaveModificationType, FiltersType>(SHAVE_MODIFICATIONS_TABLE_KEY);
  const tenantId = useTenantId();

  const queryKey = useShaveModificationsQueryKey();
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery(
    queryKey,
    async () => api.getShaveModifications(tenantId),
    {
      onError: (e: { errorMessage: string }) => {
        dispatch(addNotification({ message: e.errorMessage || "Something went wrong...", severity: "error" }));
      },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setTableData(SHAVE_MODIFICATIONS_TABLE_KEY, data.data));
      dispatch(changeTableItemsTotalAmount(SHAVE_MODIFICATIONS_TABLE_KEY, data.count));
    }
  }, [data, dispatch]);

  return {
    ...tableProps,
    isLoading,
    isRefetching,
  } as TableProps<ShaveModificationType, FiltersType> & { isRefetching: boolean };
}

export default useShaveModifications;
