// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useState,
  useEffect,
  useCallback,
  type Element,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Grid,
  CircularProgress,
  Dialog,
  Box,
  Theme,
  Alert,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MessageInfo from "@mui/material/Snackbar";
import {
  FilterList,
  ArrowDropDown,
  CheckCircleOutline,
} from "@mui/icons-material";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { ConfirmDialog } from "@fas/ui-core";
import { userService } from "../../../../services/user";
import {
  setTemplateHeaders,
  setMessages,
  getManageAffiliatesDataSaga,
  getTemplatesListSaga,
  saveTemplateSaga,
  switchChangeAuthorModalOpened,
  switchChangeStatusModalOpened,
  bulkChangeAuthorSaga,
  changeAffiliateStatusSaga,
  type SaveTemplateSaga,
  type GetManageAffiliatesDataSaga,
  type SetTemplateHeaders,
  type GetTemplatesListSaga,
} from "../../../../actions/manageAffiliate";
import { CPA_AFFILIATES_LIST_TABLE, COPY_PAYMENT_DETAILS_INFO } from "../../../../helpers/constants/manageAffiliate";
import Filters from "../Filters";
import Datepicker from "../Datepicker";
import ChangeUserModal from "../../../../components/ChangeUserModal";
import TemplateModal, { type TemplateType } from "../../../../components/TemplateModal";
import { TemplatesList } from "../../../../components/TemplatesList";
import config from "../../../../services/templateModels";
import {
  getDropdownList,
  getLoading,
  getLoaders,
  getMessages,
  getManageAffiliateTableHeaders,
  getManageAffiliateTableTemplateList,
} from "../../../../selectors/manageAffiliate";
import type { Loaders } from "../../../../reducers/manageAffiliate";
import type {
  UseState,
  Classes,
} from "./types/ManageAffiliateContent.types";
import type { CpaAffiliatesListItem } from "../../types/ManageAffiliate.types";
import type { State } from "../../../../pages/ManageAffiliate";

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  filtersButton: {
    background: "white",
    width: "150px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  templateButton: {
    background: "white",
    width: "auto",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loader: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 1,
    left: 0,
    top: 0,
  },
}));

const ManageAffiliateContent: () => Element<"div"> = () => {
  const tableProps: TableProps = useTable(CPA_AFFILIATES_LIST_TABLE);

  const countries: Array<string | Object> = useSelector((state: State): Array<string | Object> => getDropdownList(state, "countries"));
  const affiliateTypes: Array<string | Object> = useSelector((state: State): Array<string | Object> => getDropdownList(state, "affiliateTypes"));
  const statuses: Array<string | Object> = useSelector((state: State): Array<string | Object> => getDropdownList(state, "statuses"));
  const paymentMethods: Array<string | Object> = useSelector((state: State): Array<string | Object> => getDropdownList(state, "paymentMethods"));
  const isTemplatesListLoading: boolean = useSelector((state: State): boolean => getLoading(state, "isTemplatesListLoading"));
  const loaders: Loaders = useSelector((state: State): Loaders => getLoaders(state));
  const messages: { status: string, msg: string } = useSelector((
    state: State
  ): { status: string, msg: string } => getMessages(state));
  const headers: Object = useSelector((state: State): Object => getManageAffiliateTableHeaders(state));
  const templateList: Array<{}> = useSelector((state: State): Array<{}> => getManageAffiliateTableTemplateList(state));
  const isChangeAuthorModalOpened = useSelector((state) => state.manageAffiliate.get("isChangeAuthorModalOpened"));
  const isChangeStatusModalOpened = useSelector((state) => state.manageAffiliate.get("isChangeStatusModalOpened"));
  const managersList = useSelector((state) => state.manageAffiliate.get("managersList"));
  const isManagersListLoaded = useSelector((state) => state.manageAffiliate.get("isManagersListLoaded"));

  const dispatch: <A>(A) => A = useDispatch();

  const onGetTemplateHeaders: (payload: string) => GetTemplatesListSaga = useCallback((
    payload: string
  ): GetTemplatesListSaga => dispatch(getTemplatesListSaga(payload)), [dispatch]);
  const onSetTemplateHeaders: (template: TemplateType<$Keys<CpaAffiliatesListItem>>) => SetTemplateHeaders = (
    template
  ) => dispatch(setTemplateHeaders(template));
  const onGetCpaAffiliatesList: () => GetManageAffiliatesDataSaga = () => dispatch(getManageAffiliatesDataSaga());
  const onSaveTemplateSaga: (template: TemplateType<$Keys<CpaAffiliatesListItem>>) => SaveTemplateSaga = (
    template
  ) => dispatch(saveTemplateSaga(template));
  const onSetMessages: (value: { status: string, msg: string }) => mixed = (value) => dispatch(setMessages(value));
  const closeChangeAuthorModal = () => {
    dispatch(switchChangeAuthorModalOpened());
  };
  const closeChangeStatusModal = () => {
    dispatch(switchChangeStatusModalOpened());
  };
  const changeAuthor = (selectedUser, selectedAffiliatesId) => {
    dispatch(bulkChangeAuthorSaga(selectedUser, selectedAffiliatesId));
  };
  const changeAffiliateStatus = () => {
    dispatch(changeAffiliateStatusSaga());
    dispatch(switchChangeStatusModalOpened());
  };

  const { filters, onChangeFilters, selected }: TableProps = tableProps;

  const classes: Classes = useStyles();

  const template = { ...headers };
  const templatesList = [...templateList];

  const canUserUpdate = userService.can([{ path: "/api/v1/template", method: "POST" }]);

  const [isTemplateModalOpened, setIsTemplateModalOpened]: UseState<boolean> = useState(false);
  const [isTeplateListOpened, setTemplateListOpened]: UseState<boolean> = useState(false);
  const [open, setOpen]: UseState<boolean> = useState(false);
  const [choicedTemplate, setChoicedTemplate] = useState({
    name: "",
    headersOrder: [],
  });

  useEffect(() => {
    onChangeFilters({ ...filters, type: affiliateTypes });
  }, [affiliateTypes, onChangeFilters]);

  useEffect(() => {
    if (isTeplateListOpened) {
      onGetTemplateHeaders("");
    }
  }, [onGetTemplateHeaders, isTeplateListOpened]);

  const handleOpenTemplate = () => setTemplateListOpened(!isTeplateListOpened);
  const onTemplateModalCancel = () => setIsTemplateModalOpened(false);

  const handleTemplateModalApply: (template: TemplateType<$Keys<CpaAffiliatesListItem>>) => void = (temp) => {
    setIsTemplateModalOpened(false);
    onSetTemplateHeaders(temp);
    onGetCpaAffiliatesList();
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentWrapper}>
        <Dialog
          fullWidth
          maxWidth="md"
          open={isTemplateModalOpened}
          onClose={onTemplateModalCancel}
        >
          <TemplateModal
            config={config.manageAffiliate}
            onSave={(temp: TemplateType<$Keys<CpaAffiliatesListItem>>) => {
              onSaveTemplateSaga(temp);
            }}
            onApply={(temp: TemplateType<$Keys<CpaAffiliatesListItem>>) => {
              handleTemplateModalApply(temp);
            }}
            onCancel={onTemplateModalCancel}
            template={choicedTemplate}
            disableSave={!canUserUpdate}
          />
        </Dialog>
        <Grid container spacing={2} justifyContent="space-between" direction="row" alignItems="center">
          <Grid item>
            <Box display="flex">
              <Button
                className={classes.filtersButton}
                variant="outlined"
                onMouseDown={() => {
                  setOpen((prev: boolean): boolean => !prev);
                }}
                startIcon={<FilterList />}
                endIcon={<ArrowDropDown />}
                data-testid="filters-list-open-btn"
              >
                <Typography variant="button" style={{ width: "100px" }}>
                  Filters
                </Typography>
              </Button>
              <Datepicker
                setFiltering={(value) => {
                  onChangeFilters({
                    ...filters,
                    ...value,
                  });
                }}
                handleSendFilters={onGetCpaAffiliatesList}
              />
              <Box position="relative">
                <Button
                  className={classes.templateButton}
                  variant="outlined"
                  startIcon={<CheckCircleOutline />}
                  endIcon={<ArrowDropDown />}
                  onClick={handleOpenTemplate}
                  data-testid="template-list-open-btn"
                >
                  <Typography variant="button" style={{ width: "90px" }}>
                    Template
                  </Typography>
                </Button>
                {isTeplateListOpened && (
                  <TemplatesList
                    onClickAway={() => setTemplateListOpened(!isTeplateListOpened)}
                    templatesList={templatesList}
                    onSearchStringChange={(val: string) => {
                      onGetTemplateHeaders(val);
                    }}
                    onAddNew={() => {
                      setIsTemplateModalOpened(true);
                      setTemplateListOpened(!isTeplateListOpened);
                      setChoicedTemplate({ name: "", headersOrder: template.headersOrder });
                    }}
                    onSelect={(temp) => {
                      setIsTemplateModalOpened(true);
                      setTemplateListOpened(false);
                      setChoicedTemplate({ id: temp.id, name: temp.name, headersOrder: temp.headersOrder });
                    }}
                    isLoadingTemplate={isTemplatesListLoading}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        {
          loaders.isListsLoaded
        && loaders.isStatusChanged
        && loaders.isFiltersLoaded
            ? null
            : (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )
        }
        {
          loaders.isListsLoaded
            ? (
              <Filters
                affiliateTypes={affiliateTypes}
                statuses={statuses}
                countries={countries}
                paymentMethods={paymentMethods}
                filters={filters}
                onChangeFilters={onChangeFilters}
                key="FIlter"
                open={open}
                closeHandler={() => {
                  setOpen(false);
                }}
              />
            )
            : null
        }
      </div>
      <ChangeUserModal
        isChangeAuthorModalOpened={isChangeAuthorModalOpened}
        closeChangeAuthorModal={closeChangeAuthorModal}
        changeAuthorAction={(selectedUser) => changeAuthor(selectedUser, selected.map((el) => el.id))}
        managersList={managersList}
        isManagersListLoaded={isManagersListLoaded}
      />
      <ConfirmDialog
        open={isChangeStatusModalOpened}
        title="Change status"
        message={COPY_PAYMENT_DETAILS_INFO}
        onMainBtnClick={changeAffiliateStatus}
        onSecondaryBtnClick={closeChangeStatusModal}
      />
      <MessageInfo
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!messages.msg}
        autoHideDuration={6000}
        onClose={() => onSetMessages({})}
      >
        <Alert variant="filled" onClose={() => onSetMessages({})} severity={messages.status}>
          {messages.msg}
        </Alert>
      </MessageInfo>
    </div>
  );
};

export default ManageAffiliateContent;
