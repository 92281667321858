// @flow
import {
  takeEvery,
  put,
  call,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CHECK_VALIDATION_STATUS_SAGA } from "../../helpers/constants/cpaoffer";
import { checkValidationStatus } from "../../services/cpaOffersApi";
import { getCpaOfferFormData } from "../../selectors/cpaOfferForm";
import { setUrlStatusMessage } from "../../actions/cpaoffer";

export function* makeFetch(): Saga<void> {
  try {
    const row: DataItem = yield select(getCpaOfferFormData);
    const { data } = yield call(checkValidationStatus, row.dataProviderId.id, row.location);
    yield put(setUrlStatusMessage(data.status, data.message));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchCheckValidationStatus(): Saga<void> {
  // todo add event type here
  yield takeEvery(CHECK_VALIDATION_STATUS_SAGA, (makeFetch: Function));
}
