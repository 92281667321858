// @flow
import UserApi from "@fas/ui-framework/lib/services/userApi";
import type { User as UserType } from "@fas/ui-framework/lib/services/user/types/user.types";
import { requestService } from "../axiosRequest";
import { userService } from "../user";

class UserApiService extends UserApi {
  getUser(): UserType | null {
    return this._user?.getUser?.();
  }
}

// eslint-disable-next-line import/prefer-default-export
export const userApiService: UserApi = new UserApiService(userService, requestService);
