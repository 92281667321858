// @flow
import {
  SET_SORT_CAMPAIGN_SETTINGS,
  SET_FILTER_CAMPAIGN_SETTINGS,
  DELETE_FILTER_CAMPAIGN_SETTINGS,
  CLEANUP_TABLE_CAMPAIGN_SETTINGS,
  SET_SELECTED_ROWS_CAMPAIGN_SETTINGS,
  SET_OFFERS,
  SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA,
  SET_CAMPAIGN_SETTINGS_FORM_DATA,
  OPEN_CAMPAIGN_SETTINGS_FORM_MODAL,
  CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL,
  GET_CAMPAIGN_SETTINGS_OFFERS_SAGA,
  RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA,
} from "../../helpers/constants/campaignSettings";
import type {
  SetSortAction,
  SetFilterAction,
  DeleteFilterAction,
  SetSelectedRowsAction,
  CleanupTableAction,
  SetOffersAction,
  SaveCampaignSettingsSaga,
  SetCampaignSettingsFormData,
  OpenCampaignSettingsModal,
  CloseCampaignSettingsModal,
  GetCampaignSettingsOffersSaga,
  RestoreCampaignSettingsSaga,
} from "./types";
import type {
  SortType,
  FilterType,
  SelectedRowsType,
} from "../../reducers/manageAffiliate";

export const setSort = (
  value: SortType
): SetSortAction => ({
  type: SET_SORT_CAMPAIGN_SETTINGS,
  value,
});

export const setFilter = (
  value: FilterType
): SetFilterAction => ({
  type: SET_FILTER_CAMPAIGN_SETTINGS,
  value,
});

export const deleteFilter = (
  field: string
): DeleteFilterAction => ({
  type: DELETE_FILTER_CAMPAIGN_SETTINGS,
  field,
});

export const setSelectedRows = (
  value: SelectedRowsType
): SetSelectedRowsAction => ({
  type: SET_SELECTED_ROWS_CAMPAIGN_SETTINGS,
  value,
});

export const cleanupTable = (): CleanupTableAction => ({
  type: CLEANUP_TABLE_CAMPAIGN_SETTINGS,
});

export const setOffers = (
  value: Object
): SetOffersAction => ({
  type: SET_OFFERS,
  value,
});

export const saveCampaignSettingsSaga = (): SaveCampaignSettingsSaga => ({
  type: SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA,
});

export const getCampaignSettingsOffersSaga = (): GetCampaignSettingsOffersSaga => ({
  type: GET_CAMPAIGN_SETTINGS_OFFERS_SAGA,
});

export const restoreCampaignSettingsOffersSaga = (offerId: string): RestoreCampaignSettingsSaga => ({
  type: RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA,
  offerId,
});

export const setCampaignSettingsFormData: (Object) => SetCampaignSettingsFormData = (value) => ({
  type: SET_CAMPAIGN_SETTINGS_FORM_DATA,
  value,
});

export const openCampaignSettingsModal: () => OpenCampaignSettingsModal = () => ({
  type: OPEN_CAMPAIGN_SETTINGS_FORM_MODAL,
});

export const closeCampaignSettingsModal: () => CloseCampaignSettingsModal = () => ({
  type: CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL,
});
