// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import { Outlet } from "react-router-dom";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";

function CrmMails() {
  const store = useMemo(configureStore, []);

  return (
    <DocumentTitle title="CRM - Mails">
      <Provider store={store}>
        <NotificationsContainer />
        <Outlet />
      </Provider>
    </DocumentTitle>
  );
}

export default CrmMails;
