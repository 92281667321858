// @flow
import React, { useState } from "react";
import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

type Props = {
  value: string,
  limit?: number,
};

const useStyles = makeStyles((theme) => ({
  text: {
    wordBreak: "break-word",
    fontSize: "inherit",
  },
  showMore: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: "inherit",
  },
  showValue: {
    fontSize: "inherit",
  },
}));

const CountryCell = ({
  value,
  limit = 3,
}: Props) => {
  const classes = useStyles();

  const [readMore, setReadMore] = useState(false);
  const items = value ? value.split(",") : [];
  const itemsLength = items.length;

  return (
    itemsLength > limit ? (
      <>
        <Typography
          className={classes.text}
        >
          {readMore ? items.join(", ") : (
            `${items.slice(0, limit).join(", ")}`
          )}
        </Typography>
        <Typography
          className={classes.showMore}
          onClick={() => setReadMore(!readMore)}
        >
          {readMore ? "Less" : `and ${itemsLength - limit} other`}
        </Typography>
      </>
    ) : (
      <Typography
        className={classes.showValue}
      >
        {items.join(", ")}
      </Typography>
    )
  );
};

export default CountryCell;
