// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControlLabel,
  Switch,
  RadioGroup,
  Radio,
} from "@mui/material";
import { selectPostbackInfoField } from "../../selectors/manageAffiliate";
import type { PostbackTypes, PostbackValues, PostbackValueToLabelMapper } from "./types/PostbackRadioGroup.types";
import { setPostbackField } from "../../actions/postbacks";

export type Props = {
  field: PostbackTypes,
};

type UsePostbackRadioGroup = {
  radioGroupValue: PostbackValues,
  checked: boolean,
  onSwitcherChange: () => mixed,
  onRadioGroupChange: (PostbackValues) => mixed,
};

const usePostbackRadioGroup: (PostbackTypes) => UsePostbackRadioGroup = (field) => {
  const dispatch: <A>(A) => A = useDispatch();
  const radioGroupValue: PostbackValues = useSelector((state) => selectPostbackInfoField(state, field));

  const checked: boolean = radioGroupValue !== "off";

  const onSwitcherChange: () => mixed = () => {
    const value: PostbackValues = checked ? "off" : "show_add";
    dispatch(setPostbackField({ field, value }));
  };
  const onRadioGroupChange: (PostbackValues) => mixed = (value) => dispatch(setPostbackField({ field, value }));

  return {
    radioGroupValue,
    checked,
    onSwitcherChange,
    onRadioGroupChange,
  };
};

const valueToLabel: PostbackValueToLabelMapper = {
  boSoWebpush: "Bo, so, push",
  gfToggle: "GF",
  lpFilter: "LP filter",
};

const PostbackRadioGroup: StatelessFunctionalComponent<Props> = ({ field }) => {
  const {
    radioGroupValue,
    checked,
    onSwitcherChange,
    onRadioGroupChange,
  }: UsePostbackRadioGroup = usePostbackRadioGroup(field);

  return (
    <>
      <FormControlLabel
        label={valueToLabel[field]}
        control={(
          <Switch
            data-testid={field}
            checked={checked}
            onChange={onSwitcherChange}
            color="primary"
          />
        )}
      />
      {checked && (
        <RadioGroup
          data-testid={`${field}-radio-group`}
          value={radioGroupValue}
          onChange={(e): mixed => onRadioGroupChange(e.target.value)}
        >
          <FormControlLabel
            data-testid="radio-value-show_add"
            value="show_add"
            control={<Radio color="primary" />}
            label="Don't send postback, but show in aff panel"
            color="primary"
          />
          <FormControlLabel
            data-testid="radio-value-send_def"
            value="send_def"
            control={<Radio color="primary" />}
            label="Send postback as usual (default link + global)"
          />
          <FormControlLabel
            data-testid="radio-value-send_add"
            value="send_add"
            control={<Radio color="primary" />}
            label="Send postback by another link (please install link in postback info section)"
          />
        </RadioGroup>
      )}
    </>
  );
};

export default PostbackRadioGroup;
