// @flow
import React from "react";
import type { StatelessFunctionalComponent, Node } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import NamedItem from "./NamedItem";
import type { Props, FieldItem, Classes } from "./types/CardGridContent.types";

const useStyles: (props: {[key: string]: number}) => Classes = makeStyles((): Classes => ({
  container: ({
    gridCols,
    gridRows,
    tableRows,
    tableRowsDividerRow,
  }: {[key: string]: number}): mixed => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: `repeat(${gridCols}, 1fr)`,
    gridTemplateRows: `repeat(${gridRows}, auto)`,
    justifyItems: "stretch",
    alignItems: "center",
    columnGap: "8px",
    gridAutoFlow: "column",
    [`& > *:nth-child(${tableRowsDividerRow}n)`]: tableRows > 1
      ? {
        alignSelf: "end",
        marginTop: "16px",
        "&:before": {
          content: "''",
          display: "block",
          position: "absolute",
          top: "-16px",
          left: "-4px",
          height: "2px",
          width: "calc(100% + 8px)",
          background: "#f6f8ff",
        },
      }
      : undefined,
  }),
  gridItem: ({
    gridRows,
    additionalRows,
    messageRow,
  }: {[key: string]: number}): mixed => ({
    position: "relative",
    alignSelf: "stretch",
    "&:nth-child(2n)": {
      backgroundColor: "#f6f8ff",
    },
    "&:nth-child(2n - 1)": {
      backgroundColor: "#fff",
    },
    [`&:nth-child(${(gridRows - messageRow) % 2 !== 0 ? `2n + ${gridRows + 1}` : "2n"})`]: {
      backgroundColor: "#f6f8ff",
    },
    [`&:nth-child(${(gridRows - messageRow) % 2 !== 0 ? `2n + ${gridRows}` : "2n - 1"})`]: {
      backgroundColor: "#fff",
    },
    [`&:nth-child(${gridRows % 2 === 0 && additionalRows ? `2n + ${gridRows + additionalRows + 1}` : "2n"})`]: {
      backgroundColor: "#f6f8ff",
    },
    [`&:nth-child(${gridRows % 2 === 0 && additionalRows ? `2n + ${gridRows + additionalRows}` : "2n - 1"})`]: {
      backgroundColor: "#fff",
    },
  }),
  messageItem: {
    backgroundColor: "#fff",
    marginTop: "16px",
    gridColumn: (props: {[key: string]: number}): string => `1 / ${props.gridCols + 1}`,
    gridRow: (props: {[key: string]: number}): string => `${props.gridRows}`,
  },
}));

const CardGridContent: StatelessFunctionalComponent<Props> = ({
  fieldsMapper,
  gridCols,
  tableRows,
}: Props) => {
  const message: FieldItem | void = fieldsMapper.find((item: FieldItem): boolean => item.label === "message" && !!item.value);
  const fields: FieldItem[] = fieldsMapper.filter((field: FieldItem): boolean => field.label !== "message");

  const additionalRows: number = fields.reduce((result: number, { rows: fieldRows }: FieldItem): number => (
    fieldRows
      ? result + fieldRows - 1
      : result
  ), 0);
  const gridRows: number = Math.round((fields.length + additionalRows) / gridCols) + Number(!!message);
  const tableRowsDividerRow: number = (gridRows - Number(!!message)) / tableRows + 1;

  const classes: Classes = useStyles({
    gridCols,
    gridRows,
    tableRows,
    tableRowsDividerRow,
    additionalRows,
    messageRow: Number(!!message),
  });
  return (
    <div className={classes.container}>
      {
        fields
          .map(({
            label,
            value,
            color,
            rows: fieldRows,
          }: FieldItem, i: number): Node => (fieldRows
            ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                key={label}
                data-testid={label}
                className={classes.gridItem}
                style={{
                  gridColumn: "1 / 1",
                  gridRow: `${i + 1} / span ${fieldRows}`,
                  alignSelf: "stretch",
                }}
              >
                <Typography variant="body2" color="textSecondary">{label}</Typography>
                <Typography>{value || "\u2E3A"}</Typography>
              </Box>
            )
            : (
              <div key={label} className={classes.gridItem}>
                <NamedItem
                  label={label}
                  value={value}
                  key={label}
                  styles={{
                    color: color || "#000000DA",
                  }}
                />
              </div>
            )))
      }
      {message && (
        <div className={classes.messageItem}>
          <Typography variant="subtitle1" color="error" align="center">
            {message.value}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default React.memo<Props>(CardGridContent);
