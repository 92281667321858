/* eslint-disable no-nested-ternary */
// @flow
import React, { useEffect } from "react";
import { TextField, InputAdornment, Tooltip, FormControl, FormHelperText, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from '@mui/material/Autocomplete';
import { getFormErrors } from "./checkForm";
import type { Errors } from "./checkForm";
import type { CpaOfferLists } from "../../../../../reducers/cpaoffer/types";

type Props = {
  row: any,
  isClone: boolean,
  lists: CpaOfferLists,
  onChange: any,
  offerType: string,
  errors: Errors,
};

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.background.mainBg,
  },
}));

const OfferClass = (props: Props) => {
  const classes = useStyles();
  const {
    row,
    lists,
    offerType,
    errors,
    isClone,
  } = props;
  const {
    offerClass,
    offerGroup,
    offerName,
    vertical,
    offerOS,
  } = lists;
  const formErrors = getFormErrors(errors);
  const isEdit: boolean = Boolean(row.id && !isClone);

  const changeInput = (fieldName, value) => {
    let timeout = null;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => props.onChange(fieldName, value), 200);
  };

  const tooltipInput = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title="Some text">
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
      </>
    ),
  };

  const tooltipSelect = (params) => ({
    ...params.InputProps,
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title="Some text">
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
      </>
    ),
  });

  useEffect(() => {
    const isSiteAvailable = Boolean(lists.offerName.find((i) => {
      return i.id === row.offerName.id;
    }));
    if (isClone && !isSiteAvailable && row.offerName.id) {
      props.onChange("offerName", {domain: '', siteId: ''})
    }
  }, [row.offerName, lists.offerName]);

  useEffect(() => {
    const isBrandAvailable = lists.offerGroup.includes(row.group);

    if (isClone && !isBrandAvailable && row.group) {
      props.onChange("group", "")
    }
  }, [row.group, lists.offerGroup]);

  return (
    <Box mb={3} width={1}>
      {
        offerType === "Int" ? (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.class}
            variant="standard">
            <Autocomplete
              fullWidth
              disableClearable
              options={offerClass || []}
              disabled={isClone ? false : !!row.id}
              getOptionLabel={(option) => option}
              onChange={(e, value) => props.onChange("class", value)}
              value={row.class}
              data-testid="class"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Offer class"
                  variant="outlined"
                  margin="normal"
                  InputProps={tooltipSelect(params)}
                  className={classes.input}
                  error={!!formErrors.class}
                />
              )}
            />
            <FormHelperText>{formErrors.class}</FormHelperText>
          </FormControl>
        ) : null
      }
      {
        offerType === "Int" && row.class.toLowerCase() === "application" ? (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.offerOS}
            variant="standard">
            <Autocomplete
              fullWidth
              options={offerOS}
              getOptionLabel={(option) => option}
              onChange={(e, value) => props.onChange("offerOS", value)}
              disableClearable
              disabled={isClone ? false : !!row.id}
              value={row.offerOS}
              data-testid="offerOS"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Offer os"
                  variant="outlined"
                  margin="normal"
                  InputProps={tooltipSelect(params)}
                  className={classes.input}
                  error={!!formErrors.offerOS}
                />
              )}
            />

            <FormHelperText>{formErrors.offerOS}</FormHelperText>
          </FormControl>
        ) : null
      }
      {
        offerType === "Int" ? (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.group}
            variant="standard">
            <Autocomplete
              fullWidth
              disableClearable
              disabled={isClone ? false : !!(row.id || !row.class)}
              options={offerGroup}
              getOptionLabel={(option) => option}
              onChange={(e, value) => props.onChange("group", value)}
              value={row.group}
              data-testid="group"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Offer group"
                  variant="outlined"
                  margin="normal"
                  InputProps={tooltipSelect(params)}
                  className={classes.input}
                  error={!!formErrors.group}
                />
              )}
            />
            <FormHelperText>{formErrors.group}</FormHelperText>
          </FormControl>
        ) : (
          <TextField
            fullWidth
            label="Offer group"
            variant="outlined"
            margin="normal"
            onChange={(e) => changeInput("extOfferGroup", e.target.value)}
            InputProps={tooltipInput}
            defaultValue={row.extOfferGroup}
            error={!!formErrors.extOfferGroup}
            helperText={formErrors.extOfferGroup}
            data-testid="extOfferGroup"
          />
        )
      }
      {
        offerType === "Int" ? (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.siteId}
            variant="standard">
            <Autocomplete
              fullWidth
              disableClearable
              disabled={(isEdit || !row.group)}
              options={offerName}
              getOptionLabel={(option) => option.domain || ""}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              onChange={(e, value) => props.onChange("offerName", value)}
              value={row.offerName}
              data-testid="offerName"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Offer name"
                  variant="outlined"
                  margin="normal"
                  InputProps={tooltipSelect(params)}
                  className={classes.input}
                  error={!!formErrors.siteId}
                />
              )}
            />
            <FormHelperText>{formErrors.siteId}</FormHelperText>
          </FormControl>
        ) : null
      }
      {
        offerType === "Int" ? (
          <TextField
            fullWidth
            disabled
            label="Vertical"
            variant="outlined"
            margin="normal"
            onChange={(e) => changeInput("vertical", e.target.value)}
            InputProps={tooltipInput}
            defaultValue={row.vertical}
            error={!!formErrors.vertical}
            helperText={formErrors.vertical}
            data-testid="vertical"
          />
        ) : (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.vertical}
            variant="standard">
            <Autocomplete
              fullWidth
              disableClearable
              options={vertical}
              getOptionLabel={(option) => option}
              onChange={(e, value) => props.onChange("vertical", value)}
              value={row.vertical}
              data-testid="vertical"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vertical"
                  variant="outlined"
                  margin="normal"
                  InputProps={tooltipSelect(params)}
                  className={classes.input}
                  error={!!formErrors.vertical}
                />
              )}
            />
            <FormHelperText>{formErrors.vertical}</FormHelperText>
          </FormControl>
        )
      }
      {
        offerType === "Int" ? (
          <TextField
            fullWidth
            disabled={offerType === "Int"}
            label="Niche"
            variant="outlined"
            margin="normal"
            onChange={(e, value) => props.onChange("niche", value)}
            InputProps={tooltipInput}
            defaultValue={row.niche}
            error={!!formErrors.niche}
            helperText={formErrors.niche}
            data-testid="niche"
          />
        ) : (
          <TextField
            fullWidth
            label="Niche"
            variant="outlined"
            margin="normal"
            onChange={(e, value) => props.onChange("extNiche", e.target.value)}
            InputProps={tooltipInput}
            defaultValue={row.extNiche}
            error={!!formErrors.extNiche}
            helperText={formErrors.extNiche}
            data-testid="extNiche"
          />
        )
      }
    </Box>
  );
};

export default OfferClass;
