// @flow
/* eslint-disable import/max-dependencies */
/* eslint-disable max-len */
import environment from "environment";
import qs from "qs";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type {
  DataItem,
  Sorting,
} from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";
import type { TableDataType } from "../../reducers/cpaofferIndex/reducer";
import { getOfferProvider } from "../../actions/cpaoffer/utils";
import type { RowForm } from "../../reducers/cpaoffer/types";
import type { Manager } from "../../actions/cpaoffer";
import type { DropDownObjType } from "../../reducers/dictionaries";
import type { TemplateAttribute } from "../../reducers/templates";

export const getCpaOfferListList: (
  options: {
    page: number,
    limit: number,
    filters: *,
    currentSort?: string,
    direction?: $Values<Sorting>
  },
) => PromiseResponse<mixed, { payload: TableDataType[], count: number }> = ({
  page,
  limit,
  filters,
  currentSort,
  direction,
}) => requestService
  .get(environment.endpoints.get.getOfferList, {
    params: {
      ...filters,
      page,
      limit,
      currentSort,
      direction,
    },
    paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: true }),
  });

export const getDictionaryList: (string, string) => PromiseResponse<
  *,
  { list: Array<*> }
> = (dictionaryName, str) => requestService
  .get(environment.endpoints.get.getSuggestList.replace("{dictionaryName}", dictionaryName), {
    params: {
      search: str,
    },
  });

export const getCpaOfferDropdownLists: () => Promise<
  DropDownObjType
  > = () => requestService.get(environment.endpoints.get.getDropdownStatic.replace("{dictionaryName}", "cpaOffer"))
    .then(({ data: { data } }) => data);

export const getCpaOffersData: (options: {
  page: number,
  limit: number,
  filters: *,
  sorting: {
    current: string,
    direction: "asc" | "desc"
  },
  headers: Array<string>,
}) => PromiseResponse<*, { list: * }> = ({
  headers,
  filters,
  sorting,
  limit,
  page,
}) => requestService
  .post(environment.endpoints.post.getCpaOffersData,
    {
      headers,
      filters,
      sorting,
      limit,
      page,
    });

export const getOfferGroupList : (RowForm, string) => PromiseResponse<
  *,
  *
> = (row, purpose) => {
  const dict: string = row.offerOS ? "application" : row.class && row.class.toLowerCase();
  const platform: string = row.offerOS ? row.offerOS.toLowerCase() : "site";
  const offerProvider = getOfferProvider(row.group);
  return requestService.get(environment.endpoints.get.offerOptions, {
    params: {
      dict,
      purpose,
      platform,
      offerProvider,
      group: row.group,
    },
    paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: true }),
  });
};

export const getCpaOffersLists: () => PromiseResponse<*, *> = () => requestService
  .get(environment.endpoints.get.getOffersLists);

export const getCpaOfferForm: (string) => PromiseResponse<*, *> = (id) => requestService
  .get(environment.endpoints.get.getOffer.replace("{id}", id));

export const saveCpaOffer: (data: RowForm) => PromiseResponse<*, *> = (data) => {
  const { id, ...form }: RowForm = data;
  if (id) {
    return requestService.post(environment.endpoints.post.updateCpaOffer.replace("{id}", id), form);
  }
  return requestService.post(environment.endpoints.post.saveCpaOffer, form);
};

export const resetGlobalCap: (id: string) => PromiseResponse<*, *> = (id) => requestService
  .post(environment.endpoints.post.resetCap, { id });

export const changeCpaOfferStatus: (Array<{ id: string, isActive: number }>) => PromiseResponse<*, *> = (offers) => {
  const actives = {
    activate: offers,
  };
  return requestService
    .post(environment.endpoints.post.applyActivesOffer, actives);
};

export const changeCpaOfferValidationStatus: (boolean, Array<DataItem>) => PromiseResponse<
  *,
  *> = (status, offers) => requestService
    .post(environment.endpoints.post.changeIsValid, { status, offers });

export const checkValidationStatus: (string, string) => PromiseResponse<
  *,
  *> = (dataProviderId, checkUrl) => requestService
    .post(environment.endpoints.post.checkValidationStatus, { dataProviderId, url: checkUrl });

export const getManagersListByMarketingGroup: ({ id: string, name: string }) => PromiseResponse<
  *,
  *> = (marketingGroup) => requestService.get(environment.endpoints.get.getManagersListByMarketingGroup,
    {
      params: {
        type: "manager",
        filter: marketingGroup.name,
      },
      paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: true }),
    });

export const changeCpaOfferAuthor: (manager: Manager, offers: Array<number | string>) => PromiseResponse<
  *,
  *> = (manager, offers) => requestService.put(environment.endpoints.put.bulkCpaOffersAuthorsChange, {
    filters: { id: offers },
    data: { createdBy: manager.value },
  });

export type Filters = {
  offerId: Array<string>,
  offerName: Array<string>,
  campaignId: Array<string>,
  campaignName: Array<string>,
  split: string,
  funnelName: Array<string>,
  createdAt: number,
  campaignStatus: boolean,
  offerStatus: boolean,
  offerInCampaignStatus: boolean,
}
export type Headers = "offerId" | "offerName" | "campaignId" | "campaignName" | "funnelName" | "created" | "split" | "campaignStatus" | "offerStatus" | "offerInCampaignStatus";
export type CpaOfferCampaignsProps = {
  page: number,
  limit: number,
  filters: {

  },
  sorting: {
    current?: string,
    direction?: "asc" | "desc"
  },
  headers: Array<Headers>,
}
export type CpaOfferCampaignData = {
  offerId: string,
  offerName: string,
  campaignId: string,
  campaignName: string,
  split: string,
  funnel: {|
    funnelName: string,
    funnelIndex: number,
    funnelType: string,
  |},
  created: string,
  campaignStatus: boolean,
  offerStatus: boolean,
  offerInCampaignStatus: boolean,
}
export const getCpaOffersCampaigns: (CpaOfferCampaignsProps) => PromiseResponse<*, {
  data: Array<CpaOfferCampaignData>,
  count: number,
}> = (
  props
) => requestService.post(environment.endpoints.post.getCpaOfferCampaigns, props);

export const getCpaOffersList:
  (string, string, string) => PromiseResponse<
    *,
    { data: Array<{ label: string, value: string }> },
    > = (name, additionalParameter, filter) => requestService.get(environment.endpoints.get.getCpaCampaignsOptions, {
      params: {
        type: name,
        filter,
        additionalParameter,
      },
    });

export async function fetchCpaOffersList(name: string, additionalParameter: string, filter: string): Promise<Array<{ label: string, value: string }>> {
  try {
    const { data }: { data: { data: Array<{ label: string, value: string }> } } = await getCpaOffersList(name, additionalParameter, filter);
    return data.data;
  }
  catch (err) {
    return [];
  }
}

export const exportCpaOffers: (
  attributes: TemplateAttribute[]
) => PromiseResponse<mixed> = (attributes) => requestService
  .post(environment.endpoints.post.exportCpaOffers, {
    attributes: attributes
      .map(({ name, filters }: TemplateAttribute) => ({
        name,
        filters: filters
          .filter(({ value }) => value !== "")
          .map(({ value }) => value),
      })),
  });
