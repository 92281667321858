/* eslint-disable import/max-dependencies */
// @flow
import {
  Error, FormHeader, Loader, SetupCard,
} from "@fas/ui-core";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Container } from "@mui/material";
import type { StatelessFunctionalComponent } from "react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { SelectForm, TextFieldForm } from "@fas/ui-core/lib/Form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { useDictionaryList } from "../../hoocks/useDictionary";
import { FORM_KEY_PAYOUT_TERMS } from "../../helpers/constants/payoutTerms";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import Form from "../Form";
import SelectFormWithDefaultValue from "./SelectFormWithDefaultValue";

export type Props = $ReadOnly<{||}>;

const useStyles = makeStyles(() => ({
  listbox: {
    textTransform: "capitalize",
  },
  input: {
    textTransform: "capitalize",
  },
}));

const numberInputProps = {
  inputProps: {
    step: 1,
    min: 0,
    max: 99999999,
  },
};

const PayoutTermsFormContainer: StatelessFunctionalComponent<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_PAYOUT_TERMS, id));
    }
  }, [id]);
  const isEditForm: boolean = Boolean(id);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const [affiliateTypes, affiliateTypesLoading] = useDictionaryList("payoutTerms", "affiliateTypes");
  const [paymentTypes, paymentTypesLoading] = useDictionaryList("payoutTerms", "paymentTypes");
  const [paymentMethods, paymentMethodsLoading] = useDictionaryList("payoutTerms", "paymentMethod");
  const [currencies, currenciesLoading] = useDictionaryList("payoutTerms", "currency");
  const [payoutCompanies, payoutCompaniesLoading] = useDictionaryList("payoutTerms", "payoutCompanies");

  const isError: mixed = useSelector((state) => getFormField(state, FORM_KEY_PAYOUT_TERMS, "isError"));

  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_PAYOUT_TERMS} redirectOnSave="/payoutTerms">
      <FormHeader
        title={id ? `Edit payout terms: ${String(id)}` : "Create payout terms"}
        loading={loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width="100%">
            <Loader loading={loading}>
              <SelectForm
                classes={classes}
                name="affiliateType"
                label="Affiliate type"
                options={affiliateTypes}
                loading={affiliateTypesLoading}
                disabled={isEditForm}
              />
              <SelectForm
                classes={classes}
                name="paymentsType"
                label="Payments type"
                options={paymentTypes}
                loading={paymentTypesLoading}
                disabled={isEditForm}
              />
              <SelectFormWithDefaultValue
                name="paymentMethod"
                title="paymentMethodTitle"
                label="Payment method"
                options={paymentMethods}
                loading={paymentMethodsLoading}
                disabled={isEditForm}
              />
              <TextFieldForm
                {...numberInputProps}
                name="paymentMinAmount"
                label="Min payment, $"
                type="number"
              />
              <TextFieldForm
                {...numberInputProps}
                name="paymentMaxAmount"
                label="Max payment, $"
                type="number"
              />
              <SelectFormWithDefaultValue
                name="currency"
                title="currencyTitle"
                label="Currency"
                options={currencies}
                loading={currenciesLoading}
                disabled={isEditForm}
              />
              <SelectFormWithDefaultValue
                name="payoutCompany"
                title="payoutCompanyTitle"
                label="Payout company"
                options={payoutCompanies}
                loading={payoutCompaniesLoading}
              />
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default PayoutTermsFormContainer;
