// @flow
import environment from "environment";
import { request as RequestService } from "@fas/ui-framework";
import { userService } from "../user";

const getAuthorizationHeaders: () => { [key: string]: mixed } = () => ({
  "X-Api-Key": userService.getApiKey() || "",
});

// eslint-disable-next-line import/prefer-default-export
export const requestService: RequestService = new RequestService({
  timeout: 180000 /* 3 min */,
  withCredentials: true,
  customAttr: {
    getAuthorizationHeaders,
    redirect: () => {
      window.location = `${environment.endpoints.get.loginUrl}?r=${window.location.href}`;
    },
  },
});
