// @flow
/* eslint-disable import/max-dependencies */
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery,
} from "redux-saga/effects";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { getDictionaryKey, getDictionaryLoadingKey } from "../../helpers/generators";
import { type DictionaryParams, type GetDictionarySaga, setDictionary } from "../../actions/dictionary";
import { GET_DICTIONARY_SAGA } from "../../helpers/constants/dictionary";
import { fetchDropdownList } from "../../services/dictionariesApi";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { getSmuDropdownList } from "../../services/smuApi";

type DictionaryFunction = (DictionaryParams) => Promise<DropDownObjItemType[]>;

/**
 * Non-standard dictionary dropdown routes
 */
const dictionaryMethodMap: { [string]: DictionaryFunction } = {
  smu: getSmuDropdownList,
};

export function* makeFetch(action: GetDictionarySaga): Saga<void> {
  const { params }: GetDictionarySaga = action;
  try {
    yield put(setLoading(getDictionaryLoadingKey(params), true));

    const method: DictionaryFunction = dictionaryMethodMap[params.dictionary] || fetchDropdownList;

    const data: DropDownObjItemType[] = yield call(method, params);

    yield put(setDictionary({
      [getDictionaryKey(params)]: data,
    }));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(getDictionaryLoadingKey(params), false));
  }
}

export default function* watchGetDropdownListSaga(): Saga<void> {
  yield takeEvery(GET_DICTIONARY_SAGA, (makeFetch: Function));
}
