// @flow
export const GET_AFFILIATE_LINKS_SAGA: "GET_AFFILIATE_LINKS_SAGA" = "GET_AFFILIATE_LINKS_SAGA";
export const REMOVE_AFFILIATE_LINK_SAGA: "REMOVE_AFFILIATE_LINK_SAGA" = "REMOVE_AFFILIATE_LINK_SAGA";
export const SAVE_AFFILIATE_LINK_SAGA: "SAVE_AFFILIATE_LINK_SAGA" = "SAVE_AFFILIATE_LINK_SAGA";
export const SET_AFFILIATE_LINK_FORM_DATA: "SET_AFFILIATE_LINK_FORM_DATA" = "SET_AFFILIATE_LINK_FORM_DATA";
export const SET_AFFILIATE_LINKS: "SET_AFFILIATE_LINKS" = "SET_AFFILIATE_LINKS";
export const OPEN_AFFILIATE_LINK_MODAL: "OPEN_AFFILIATE_LINK_MODAL" = "OPEN_AFFILIATE_LINK_MODAL";
export const CLOSE_AFFILIATE_LINK_MODAL: "CLOSE_AFFILIATE_LINK_MODAL" = "CLOSE_AFFILIATE_LINK_MODAL";

export const AFFILIATE_LINKS_TABLE: "AFFILIATE_LINKS_TABLE" = "AFFILIATE_LINKS_TABLE";
