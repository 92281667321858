// @flow
export const SET_FETCH_DATA_LISTS: "SET_FETCH_DATA_LISTS" = "SET_FETCH_DATA_LISTS";
export const CHANGE_FORM_FIELD_OFFER: "CHANGE_FORM_FIELD_OFFER" = "CHANGE_FORM_FIELD_OFFER";
export const CHANGE_FORM_FIELD_OFFER_TYPE: "CHANGE_FORM_FIELD_OFFER_TYPE" = "CHANGE_FORM_FIELD_OFFER_TYPE";
export const GET_OFFER_FORM: "GET_OFFER_FORM" = "GET_OFFER_FORM";
export const OFFER_IS_FETCHING: "OFFER_IS_FETCHING" = "OFFER_IS_FETCHING";
export const OFFER_ERROR: "OFFER_ERROR" = "OFFER_ERROR";
export const SET_OFFER_LISTS_FIELD: "SET_OFFER_LISTS_FIELD" = "SET_OFFER_LISTS_FIELD";
export const ADD_IS_ACTIVE_OFFER: "ADD_IS_ACTIVE_OFFER" = "ADD_IS_ACTIVE_OFFER";
export const REMOVE_IS_ACTIVE_OFFER: "REMOVE_IS_ACTIVE_OFFER" = "REMOVE_IS_ACTIVE_OFFER";
export const CLEAR_IS_ACTIVES_OFFER: "CLEAR_IS_ACTIVES_OFFER" = "CLEAR_IS_ACTIVES_OFFER";
export const CHANGE_FORM_LINK_VALIDATION_TYPE: "CHANGE_FORM_LINK_VALIDATION_TYPE" = "CHANGE_FORM_LINK_VALIDATION_TYPE";
export const SET_URL_STATUS_MESSAGE: "SET_URL_STATUS_MESSAGE" = "SET_URL_STATUS_MESSAGE";
export const CHANGE_FORM_FIELD_PARTNER: "CHANGE_FORM_FIELD_PARTNER" = "CHANGE_FORM_FIELD_PARTNER";
export const CHANGE_FORM_FIELD_LINK_TYPE: "CHANGE_FORM_FIELD_LINK_TYPE" = "CHANGE_FORM_FIELD_LINK_TYPE";
export const SWITCH_CHANGE_AUTHOR_MODAL_OPENED: "SWITCH_CHANGE_AUTHOR_MODAL_OPENED" = "SWITCH_CHANGE_AUTHOR_MODAL_OPENED";
export const SET_FETCHED_MARKETERS: "SET_FETCHED_MARKETERS" = "SET_FETCHED_MARKETERS";
export const CHANGE_IS_MANAGER_LIST_LOADED: "CHANGE_IS_MANAGER_LIST_LOADED" = "CHANGE_IS_MANAGER_LIST_LOADED";
export const SET_OFFER_FOUND: "SET_OFFER_FOUND" = "SET_OFFER_FOUND";

export const GET_CPA_OFFER_LIST_SAGA: "GET_CPA_OFFER_LIST_SAGA" = "GET_CPA_OFFER_LIST_SAGA";
export const GET_CPA_OFFER_DROPDOWN_LISTS: "GET_CPA_OFFER_DROPDOWN_LISTS" = "GET_CPA_OFFER_DROPDOWN_LISTS";
export const CPA_OFFER_TABLE: "CPA_OFFER_TABLE" = "CPA_OFFER_TABLE";
export const GET_OFFER_GROUPS_LIST_SAGA: "GET_OFFER_GROUPS_LIST_SAGA" = "GET_OFFER_GROUPS_LIST_SAGA";
export const GET_OFFER_NAME_LIST_SAGA: "GET_OFFER_NAME_LIST_SAGA" = "GET_OFFER_NAME_LIST_SAGA";
export const GET_CPA_OFFERS_LISTS_SAGA: "GET_CPA_OFFERS_LISTS_SAGA" = "GET_CPA_OFFERS_LISTS_SAGA";
export const GET_CPA_OFFER_SAGA: "GET_CPA_OFFER_SAGA" = "GET_CPA_OFFER_SAGA";
export const SAVE_CPA_OFFER_SAGA: "SAVE_CPA_OFFER_SAGA" = "SAVE_CPA_OFFER_SAGA";
export const SET_IS_CLONE: "SET_IS_CLONE" = "SET_IS_CLONE";
export const RESET_GLOBAL_CAP_SAGA: "RESET_GLOBAL_CAP_SAGA" = "RESET_GLOBAL_CAP_SAGA";
export const CHANGE_CPA_OFFER_STATUS_SAGA: "CHANGE_CPA_OFFER_STATUS_SAGA" = "CHANGE_CPA_OFFER_STATUS_SAGA";
export const CHANGE_VALIDATION_STATUS_SAGA: "CHANGE_VALIDATION_STATUS_SAGA" = "CHANGE_VALIDATION_STATUS_SAGA";
export const CHECK_VALIDATION_STATUS_SAGA: "CHECK_VALIDATION_STATUS_SAGA" = "CHECK_VALIDATION_STATUS_SAGA";
export const GET_MANAGERS_LIST_SAGA: "GET_MANAGERS_LIST_SAGA" = "GET_MANAGERS_LIST_SAGA";
export const CHANGE_AUTHOR_SAGA: "CHANGE_AUTHOR_SAGA" = "CHANGE_AUTHOR_SAGA";

// cpa offer campaigns
export const CPA_OFFER_CAMPAIGNS_TABLE: "CPA_OFFER_CAMPAIGNS_TABLE" = "CPA_OFFER_CAMPAIGNS_TABLE";
export const GET_CPA_OFFER_CAMPAIGNS_SAGA: "GET_CPA_OFFER_CAMPAIGNS_SAGA" = "GET_CPA_OFFER_CAMPAIGNS_SAGA";
export const DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA: "DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA" = "DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA";
