// @flow
/* eslint-disable import/max-dependencies */
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../axiosRequest";
import type { CrmTemplate } from "../../containers/CrmTemplatesForm";

export const fetchCrmTemplatesList: (options: {
  page: number,
  limit: number,
  filters: *,
  sorting: {
    current: string,
    direction: "asc" | "desc"
  },
  headers: Array<string>,
}) => PromiseResponse<*, { list: * }> = (options) => requestService
  .post(environment.endpoints.post.getMailTemplatesList,
    {
      ...options,
    });

export const deleteCrmTemplate: (
  id: string
) => PromiseResponse<*> = (id) => requestService
  .delete(environment.endpoints.delete.deleteCrmTemplate.replace("{id}", id));

export const fetchCrmTemplate: (id: number) => Promise<*> = (id) => requestService
  .get(environment.endpoints.get.getCrmTemplateById.replace("{id}", String(id)))
  .then(({ data }) => data);

export async function saveCrmTemplate({
  id,
  ...body
}: CrmTemplate) {
  if (id) {
    await requestService.put(environment.endpoints.put.updateCrmTemplate.replace("{id}", String(id)), { ...body, id });
  }
  else {
    await requestService.post(environment.endpoints.post.createCrmTemplate, body)
      .then(({ data }) => data.id);
  }
}
