import type React from "react";
import {
  Box, Card, CardContent, Typography,
} from "@mui/material";
import DocumentTitle from "react-document-title";
import type { GroupFeature } from "../types";
import filterByOperation from "../filterByOperation";
import { DashboardBlockTitle } from "../../components/DashboardLink";

const Dashboard: React.FC<{slots: GroupFeature[] }> = ({ slots }) => {
  const availableFeaturesWithoutDuplicate = Object.values(slots.reduce((acc, feature) => {
    const key = feature.url || typeof feature.label === "string" ? feature.label as string : "";
    const existingFeature = acc[key];
    if (!existingFeature || !filterByOperation(existingFeature)) {
      acc[key] = feature;
    }
    return acc;
  }, {} as { [key: string]: GroupFeature }))
    .sort((a, b) => slots.indexOf(a) - slots.indexOf(b));
  const availableFeatures = availableFeaturesWithoutDuplicate.filter(filterByOperation);

  return (
    <DocumentTitle title="CPA - Dashboard">
      <Box
        width={1}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        gap={2}
        maxWidth="xl"
        margin="auto"
        py={2}
        px={1}
      >
        {availableFeatures
          .map(({
            label, url, CardComponent, sx,
          }) => {
            if (CardComponent) {
              return (
                <Card
                  key={url + label}
                  sx={{
                    flexBasis: "calc(50% - 8px)",
                    minHeight: 200,
                    borderRadius: 4,
                    boxShadow: "none",
                    flexGrow: 1,
                    minWidth: 550,
                    ...sx,
                  }}
                  data-testid={label}
                >
                  <CardContent>
                    <DashboardBlockTitle>
                      {label}
                    </DashboardBlockTitle>
                    <CardComponent />
                  </CardContent>
                </Card>
              );
            }
            return null;
          })}
      </Box>
    </DocumentTitle>
  );
};
export default Dashboard;
