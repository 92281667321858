import React from "react";
import Actions from "./components/Actions";
import Status from "./components/Status";

export const mapCountry = (value) => {
  if (value && value.country) {
    return value.country.split(",");
  }

  return [];
};
// eslint-disable-next-line complexity
export const sanitizeCountry = (
  countries = [],
  fieldCountries = [],
  offerCountries = [],
  offerId
) => {
  if (
    offerId === "all"
    && fieldCountries.includes("all") && fieldCountries.length === 1) {
    return [];
  }
  if (offerId === "all" && !fieldCountries.includes("all")) {
    if (fieldCountries.length >= 1) {
      return countries.filter((item) => item !== "all");
    }
    return countries;
  }
  if (
    offerId !== 0
    && offerId !== "all"
    && fieldCountries.includes("all")
    && fieldCountries.length === 1
  ) {
    return [];
  }
  if (
    offerId !== 0
    && offerId !== "all"
    && !fieldCountries.includes("all")
  ) {
    if (fieldCountries.length >= 1) {
      return offerCountries.filter((item) => item !== "all");
    }
    return [
      "all",
      ...offerCountries,
    ];
  }

  return [];
};
export const mapConversionType = (value) => {
  if (value && value.conversionType) {
    return value.conversionType.split(",");
  }

  return [];
};
export const mapOfferId = (value) => {
  if (value) {
    return {
      id: value.offerId,
      name: value.offerName,
    };
  }

  return {
    id: 0,
    name: "",
  };
};

export const getErrors = (errors) => errors.reduce((res, item) => ({ ...res, [item.field]: item.error }), {});

export const tableColumns = [
  {
    field: "id",
    title: "Actions",
    customCell: (value, field, row) => (<Actions value={value} row={row} field={field} />),
  },
  {
    field: "url",
    title: "Additional postback link",
    width: 350,
    filter: {},
  },
  {
    field: "conversionType",
    title: "Conversion type",
    width: 150,
    filter: {
      select: ["click", "1000clicks", "registration", "confirm", "install", "payment", "return", "webPushSubscription"],
    },
  },
  {
    field: "offerName",
    title: "Cpa offer",
    width: 100,
  },
  {
    field: "country",
    title: "Country",
    width: 100,
    filter: {
      select: ["all", "abw", "ago", "aia", "ala", "alb", "and", "ant", "are", "arg", "arm", "asm", "ata", "atf"],
    },
  },
  {
    field: "isActive",
    title: "Status",
    width: 100,
    filter: {
      select: [0, 1],
    },
    customCell: (value) => (<Status value={value} />),
  },
];

export const dataTable = [
  {
    id: 898,
    url: "https://jira.togethernetworks.com/browse/MTU-43763",
    conversionType: "click",
    offerName: "all",
    offerId: "all",
    country: "all",
    isActive: 0,
  },
  {
    id: 900,
    url: "https://jira.togethernetworks.com/secure",
    conversionType: "click,1000clicks",
    offerName: "some_name115",
    offerId: "19afb3db",
    country: "aus",
    isActive: 1,
  },
];
