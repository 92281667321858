// @flow
import type { State, ChartDataType, TableDataType } from "../../../../reducers/dashboard";

export type DashboardObjectType = {
  sorting: {
    field: string,
    direction: string,
  },
  filters: { [string]: string },
  selectedRows: Array<number | string>,
  isActives: Array<{id: string, isActive: number}>,
  tableRows: Array<TableDataType>,
  chartRows: Array<ChartDataType>,
  summaryBoard: Array<string>,
  managers: Array<{id: number, name: string}>,
}

export const mapDashboard = (state: { dashboard: State}): DashboardObjectType => {
  const {
    sorting,
    filters,
    selectedRows,
    isActives,
    tableRows,
    chartRows,
    summaryBoard,
    managers,
  } = ((state.dashboard.toJS(): any): DashboardObjectType);
  return {
    sorting,
    filters,
    selectedRows,
    isActives,
    tableRows,
    chartRows,
    summaryBoard,
    managers,
  };
};
