// @flow

// eslint-disable-next-line import/prefer-default-export
export const TOP_OFFERS_PROJECT_TYPES: { [string]: string } = {
  adsempiresmartlink: "adsempiresmartlink",
  adsempiredirect: "adsempiredirect",
};

export type PayloadItemType = {|
  id?: string,
  projectType: string,
  vertical: string,
  geo: string[],
  platform: string[],
  eCPM: string,
  offerId: string,
  payout: string,
  currencyId: number | string,
  image: string | Object | null,
  isActive: boolean,
|}
