/* eslint-disable import/max-dependencies */
/* eslint-disable no-nested-ternary */
// @flow
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, CircularProgress, Paper, Typography, Button, IconButton, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import type { Location, NavigateFunction } from 'react-router-dom';
import {
  ConfirmDialog,
  Error,
} from "@fas/ui-core";
import { userService } from "../../../../services/user";
import type {
  State as CpaOffer,
} from "../../../../reducers/cpaoffer";
import {
  changeForm,
  changePartner,
  changeLinkType,
  changeFormOfferType,
  changeLinkValidationType,
  getOfferGroupsListSaga,
  getOfferNameListSaga,
  getCpaOffersListsSaga,
  getCpaOfferSaga,
  saveCpaOfferSaga,
  setIsClone,
  checkValidationStatusSaga,
} from "../../../../actions/cpaoffer";
import SetupCardForm from "../../../../components/SetupCardForm";
import GeneralOptions from "./typesForm/GeneralOptions";
import OfferClass from "./typesForm/OfferClass";
import Targetings from "./typesForm/Targetings";
import PaymentSettings from "./typesForm/PaymentSettings";
import { checkForm } from "./typesForm/checkForm";
import type { CpaOfferLists, RowForm } from "../../../../reducers/cpaoffer/types";
import type { Errors } from "./typesForm/checkForm";
import type {
  GetOfferNameListSaga,
  GetOfferGroupsListSaga,
  GetCpaOffersListsSaga,
  GetCpaOfferSaga,
  SaveCpaOfferSaga, SetIsClone, CheckValidationStatusSaga,
} from "../../../../actions/cpaoffer";
import { getCpaOfferFormData, getLoading } from "../../../../selectors/cpaOfferForm";

export type StatePropsType = {|
  tableInfo: CpaOffer,
  row: *,
  isCpaOffersListsLoading: boolean,
  isOfferGroupListLoading: boolean,
|};

export type OwnProps = {|
  history: NavigateFunction,
  location: Location,
|};



export type DispatchPropType = {|
  handleChangeForm: typeof changeForm,
  handleChangePartner: typeof changePartner,
  handleChangeLinkType: typeof changeLinkType,
  handleChangeFormOfferType: typeof changeFormOfferType,
  handleChangeLinkValidationType: typeof changeLinkValidationType,
  handleGetOfferGroupsList: () => GetOfferGroupsListSaga,
  handleGetOfferNameList: () => GetOfferNameListSaga,
  handleGetCpaOffersListsSaga: () => GetCpaOffersListsSaga,
  handleGetCpaOfferForm: (string) => GetCpaOfferSaga,
  handleSaveCpaOffer: () => SaveCpaOfferSaga,
  handleSetIsClone: (boolean) => SetIsClone,
  handleCheckValidationStatus: () => CheckValidationStatusSaga,
|};

export type Props = {
  ...OwnProps,
  ...StatePropsType,
  ...DispatchPropType,
};

const mapStateToProps = (state) => ({
  tableInfo: state.cpaOffer,
  row: getCpaOfferFormData(state),
  isCpaOffersListsLoading: getLoading(state, "isCpaOffersLists"),
  isOfferGroupListLoading: getLoading(state, "isOfferGroupList"),
});

const mapDispatchToProps = (dispatch): DispatchPropType => ({
  handleChangeForm: (fieldName, value) => dispatch(changeForm(fieldName, value)),
  handleChangePartner: (fieldName, value) => dispatch(changePartner(fieldName, value)),
  handleChangeLinkType: (fieldName, value) => dispatch(changeLinkType(fieldName, value)),
  handleChangeFormOfferType: (fieldName, value) => dispatch(changeFormOfferType(fieldName, value)),
  handleChangeLinkValidationType: (value) => dispatch(changeLinkValidationType(value)),
  handleCheckValidationStatus: () => dispatch(checkValidationStatusSaga()),
  handleGetOfferGroupsList: () => dispatch(getOfferGroupsListSaga()),
  handleGetOfferNameList: () => dispatch(getOfferNameListSaga()),
  handleGetCpaOffersListsSaga: () => dispatch(getCpaOffersListsSaga()),
  handleGetCpaOfferForm: (id) => dispatch(getCpaOfferSaga(id)),
  handleSaveCpaOffer: () => dispatch(saveCpaOfferSaga()),
  handleSetIsClone: (val) => dispatch(setIsClone(val)),
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  form: {
    width: "40%",
  },
  formHeadr: {},
  loader: {
    height: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialogHeader: {
    flex: 0,
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: theme.palette.primary.main,
  },
}));

type FormInfoObject = {
  errors: Errors,
  lists: CpaOfferLists,
  row: RowForm,
  isFetching: boolean,
  isSaved: boolean,
  isLists: boolean,
  urlValidationStatus: string,
  urlValidationStatusMessage: string,
  isOfferFound: boolean,
};

export function userHaveSomeMarketingGroup(marketingGroups: Array<number> = []): boolean {
  const user = userService.getUser();
  // $FlowFixMe user don't have allowedMarketingGroups in type, need add allowedMarketingGroups to userService User type
  const allowedMarketingGroups: Array<number> = (user && user.allowedMarketingGroups) || [];
  return allowedMarketingGroups.some((group) => marketingGroups.includes(group));
}

const Form = ({
  tableInfo,
  handleChangeForm,
  handleChangePartner,
  handleChangeLinkType,
  handleChangeLinkValidationType,
  handleCheckValidationStatus,
  handleChangeFormOfferType,
  handleGetCpaOffersListsSaga,
  handleGetCpaOfferForm,
  handleSaveCpaOffer,
  handleGetOfferGroupsList,
  handleGetOfferNameList,
  location,
  handleSetIsClone,
  row,
  isCpaOffersListsLoading,
  isOfferGroupListLoading,
}: Props) => {
  const classes = useStyles();
  const [openCard, setOpenCard] = useState("card-general-options");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessages, setConfirmMessages] = useState([]);
  const {
    errors,
    lists,
    isFetching,
    isLists,
    urlValidationStatus,
    urlValidationStatusMessage,
    isOfferFound,
  } = ((tableInfo.toJS(): any): FormInfoObject);
  const offerType = row.isExt && row.isExt.isExt === 1 ? "Ext" : "Int";
  const { id } = useParams();
  const errorsFormType = checkForm(errors);
  const isClone = location.pathname.split("/").pop() === "clone";
  const isLoading = isFetching || isCpaOffersListsLoading || isOfferGroupListLoading;

  const handleOpenCard = (name) => {
    setOpenCard(name);
  };

  const change = (fieldName, value) => {
    handleChangeForm(fieldName, value);
    if (fieldName === "class") {
      handleChangeForm("offerOS", "");
      handleChangeForm("group", "");
      handleChangeForm("offerName", "");
      handleGetOfferGroupsList();
      return;
    }
    if (fieldName === "offerOS") {
      handleChangeForm("offerName", "");
      handleChangeForm("group", "");
      handleGetOfferGroupsList();
      return;
    }
    if (fieldName === "group") {
      handleChangeForm("offerName", "");
      handleGetOfferNameList();
    }
    if (fieldName === "department") {
      handleChangeForm("forOfferwall", false);
    }
  };

  const changePartner = (fieldName, value) => {
    handleChangePartner(fieldName, value);
  };

  const changeLinkType = (fieldName, value) => {
    handleChangeLinkType(fieldName, value);
  };

  const changeOfferType = (fieldName, value) => {
    handleChangeFormOfferType(fieldName, value);
    handleChangeForm("forOfferwall", false);
  };

  const saveOffer = () => {
    const rtbOfferTypes = ["rtb", "rtb_email", "rtb_bo", "rtb_pop", "rtb_push", "rtb_so", "rtb_ipp"];
    const isRtbOffer = rtbOfferTypes.includes(row.offerType.id);
    const isExtRtbOffer = row.isExt.isExt && isRtbOffer;
    if (isExtRtbOffer) {
      const messages = [
        `Conversion type: ${row.conversionType}`
      ];

      try {
        const parsedURL = new URL(row.location);
        const TLP: string | null = parsedURL.searchParams.get("type");
        messages.push(`Type in link: ${String(TLP)}`);
      } catch (e) {
        messages.push(`Type in link: null`);
      }

      setConfirmMessages(messages);
      setShowConfirm(true);
    } else {
      handleSaveCpaOffer();
    }
  };

  const isAllowedEdit = () => {
    const isCreateNew = !id || isClone;
    return isCreateNew
      || userHaveSomeMarketingGroup(row.marketingGroups)
      || row.managerId === userService.getId()
      || userService.can(["cpa.offers.editAllOffers"]);
  };

  useEffect(() => {
    handleGetCpaOffersListsSaga();
  }, []);

  useEffect(() => {
    if(id) {
      handleGetOfferGroupsList();
      handleGetOfferNameList();
    }
  }, [row.offerOS, row.class, row.group]);

  useEffect(() => {
    if (id && isLists) {
      if(isClone) {
        handleSetIsClone(true);
      }
      handleGetCpaOfferForm(id);
    }
  }, [id, isLists]);

  return <>
    {isLoading ? (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    ) : isAllowedEdit() && (!id || isOfferFound) ? (
      <>
        <Paper className={classes.formHeadr} square>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={11}
            >
              <IconButton onClick={() => window.location = "/cpaOffers"} size="large">
                <CloseIcon />
              </IconButton>
              <Typography
                className={classes.title}
                variant="h6"
              >
                {id ? isClone ? (
                  "Clone offer"
                ) : (
                  `Edit offer: ${id}`
                ) : (
                  "Add offer"
                )}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Button
                color="primary"
                variant="contained"
                disabled={offerType === "Int" && !userService.can(["accessCpaOfferInt"])}
                size="medium"
                onClick={saveOffer}
                data-testid="applyButton"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="md">
          <SetupCardForm
            title="General options"
            titleButton="Edit general options"
            isOpen={openCard === "card-general-options"}
            nameCard="card-general-options"
            onOpen={(name) => handleOpenCard(name)}
            errors={errorsFormType.generalOptions}
          >
            <GeneralOptions
              lists={lists}
              offerType={offerType}
              urlValidationStatus={urlValidationStatus}
              urlValidationStatusMessage={urlValidationStatusMessage}
              checkValidationStatus={handleCheckValidationStatus}
              onChange={change}
              onPartnerChange={changePartner}
              onLinkTypeChange={changeLinkType}
              changeLinkValidationType={handleChangeLinkValidationType}
              onChangeOfferType={changeOfferType}
              row={row}
              errors={errors}
              isClone={isClone}
            />
          </SetupCardForm>
          <SetupCardForm
            title="Offer class"
            titleButton="Edit offer class"
            isOpen={openCard === "card-offer-class"}
            nameCard="card-offer-class"
            onOpen={(name) => handleOpenCard(name)}
            errors={errorsFormType.offerClass}
          >
            <OfferClass
              lists={lists}
              offerType={offerType}
              onChange={change}
              row={row}
              errors={errors}
              isClone={isClone}
            />
          </SetupCardForm>
          <SetupCardForm
            title="Targetings"
            titleButton="Edit targetings"
            isOpen={openCard === "card-targetings"}
            nameCard="card-targetings"
            onOpen={(name) => handleOpenCard(name)}
            errors={errorsFormType.targetings}
          >
            <Targetings
              lists={lists}
              offerType={offerType}
              onChange={change}
              row={row}
              errors={errors}
            />
          </SetupCardForm>
          <SetupCardForm
            title="Payment settings"
            titleButton="Edit payment settings"
            isOpen={openCard === "card-payment-settings"}
            nameCard="card-payment-settings"
            onOpen={(name) => handleOpenCard(name)}
            errors={errorsFormType.paymentSettings}
          >
            <PaymentSettings
              lists={lists}
              offerType={offerType}
              onChange={change}
              row={row}
              errors={errors}
              isClone={isClone}
            />
          </SetupCardForm>
        </Container>
        <ConfirmDialog
          open={showConfirm}
          title="Confirm"
          children={
            <>
              {
                confirmMessages.map((message, index) => (<p key={"confirmMessage_"+index}>{message}</p>))
              }
            </>
          }
          onMainBtnClick={() => {
            setShowConfirm(false);
            handleSaveCpaOffer();
          }}
          onSecondaryBtnClick={() => {
            setShowConfirm(false);
          }}
        />
      </>
    ) : (
      <Error />
    )}
  </>;
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(Form);
