// @flow
import type { Node } from "react";
import type { Permission } from "@fas/ui-framework/lib/services/user";
import { userService } from "../../services/user";

type Props = {
  permissions: Array<Permission>,
  children: Node,
  renderNoAccess?: Node,
}

const Can: (Props) => Node = ({
  permissions,
  children,
  renderNoAccess = null,
}: Props): Node => (userService.can(permissions) ? children : renderNoAccess);

export default Can;
