// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import type { StatelessFunctionalComponent, Node, Element } from "react";
import moment from "moment";
import {
  Loader, LoadingButton, Table, TableActions, SimpleTableActionButton,
} from "@fas/ui-core";
import {
  Box, Typography,
} from "@mui/material";
import { Download, GetAppOutlined } from "@mui/icons-material";
import type { Permission } from "@fas/ui-framework/lib/services/user/types/user.types";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { getPayoutAmount } from "../../helpers/generators";
import type { Props, PayoutObjectType } from "./types/PayoutHistoryTable.types";
import Can from "../Can";
import { PAYOUT_HISTORY_LIST_TABLE } from "../../helpers/constants/payoutHistory";
import { getPayoutHistoryListSaga } from "../../actions/payoutHistory";
import type { GetPayoutHistoryListSaga } from "../../actions/payoutHistory";

const PayoutHistoryTable: StatelessFunctionalComponent<Props> = ({
  onDownloadInvoice,
  isDownloadInvoiceLoading,
}: Props) => {
  const tableProps: TableProps = useTable(PAYOUT_HISTORY_LIST_TABLE);
  const {
    selected, data, isLoading, onSelectAll,
  }: TableProps = tableProps;

  const dispatch: <A>(A) => A = useDispatch();

  const onGetPayoutHistoryList: () => GetPayoutHistoryListSaga = () => (
    dispatch(getPayoutHistoryListSaga()));
  const selectedIds: Array<string> = selected.map(({ id }: { id: string }): string => id);

  const availableStatusToDownload: string[] = ["Paid", "Refund"];

  const columns: Array<Column<PayoutObjectType>> = [
    {
      field: "id",
      label: "Invoice id",
      sortable: true,
      render: ({ id }: PayoutObjectType): Element<typeof Typography> => <Typography>{id}</Typography>,
    },
    {
      field: "affiliate",
      label: "Name",
      sortable: false,
      render: ({ affiliate }: PayoutObjectType): Element<typeof Typography> => {
        const { name } = affiliate;
        return (
          <Typography>{name}</Typography>
        );
      },
    },
    {
      field: "payoutCompany",
      label: "Payout company",
      sortable: true,
      render: ({ payoutCompany }: PayoutObjectType): Element<typeof Typography> => {
        const { name } = payoutCompany;
        return (
          <Typography>
            {name}
          </Typography>
        );
      },
    },
    {
      // $FlowFixMe paymentsType name of sorting
      field: "paymentsType",
      label: "Payment type",
      sortable: true,
      render: ({ affiliate }: PayoutObjectType): Element<typeof Typography> => {
        const { paymentsType } = affiliate;
        return (
          <Typography>
            {paymentsType}
          </Typography>
        );
      },
    },
    {
      // $FlowFixMe paymentMethod name of sorting
      field: "paymentMethod",
      label: "Payment method",
      sortable: true,
      render: ({ paymentInfo }: PayoutObjectType): Element<typeof Typography> => {
        const { paymentMethod } = paymentInfo;
        return (
          <Typography>
            {paymentMethod.name}
          </Typography>
        );
      },
    },
    {
      field: "createdAt",
      label: "Created at",
      render: ({ createdAt }: PayoutObjectType): string => moment(createdAt).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      field: "periodFrom",
      label: "Period from",
      render: ({ periodFrom }: PayoutObjectType): string => moment(periodFrom).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      field: "periodTo",
      label: "Period to",
      render: ({ periodTo }: PayoutObjectType): string => moment(periodTo).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      field: "payoutInCurrency",
      label: "Paid total",
      render: ({
        currency,
        payoutInCurrency,
      }: PayoutObjectType): string => getPayoutAmount(currency.currency, parseFloat(payoutInCurrency)),
      sortable: false,
    },
    {
      field: "payout",
      label: "Paid total, $",
      render: ({ payout }: PayoutObjectType): string => getPayoutAmount("USD", parseFloat(payout)),
      sortable: false,
    },
    {
      field: "payoutExt",
      label: "Paid total ext, $",
      render: ({ payoutExt }: PayoutObjectType): string => getPayoutAmount("USD", parseFloat(payoutExt)),
      sortable: false,
    },
    {
      field: "payoutExtInCurrency",
      label: "Paid total ext",
      render: ({
        currency,
        payoutExtInCurrency,
      }: PayoutObjectType): string => getPayoutAmount(currency.currency, parseFloat(payoutExtInCurrency)),
      sortable: false,
    },
    {
      field: "paidAt",
      label: "Payment date",
      render: ({ paidAt }: PayoutObjectType): Element<typeof Typography> => (
        <Typography>
          {(paidAt ? moment(paidAt).format("YYYY-MM-DD") : "\u2E3A")}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "state",
      label: "Status",
      sortable: false,
      render: ({ state }: PayoutObjectType): Element<typeof Typography> => {
        const { name } = state;
        return (
          <Typography>
            {name}
          </Typography>
        );
      },
    },
    {
      field: "state",
      key: "invoice",
      label: "Invoice",
      render: ({ state, id } : PayoutObjectType): Node => {
        const { name } = state;
        return (
          (!availableStatusToDownload.includes(name)
            ? ""
            : (
              <Box>
                <Can permissions={downloadPermission}>
                  <LoadingButton
                    loading={isDownloadInvoiceLoading}
                    // $FlowFixMe
                    onClick={() => onDownloadInvoice([id])}
                    data-id={id}
                    title="Download"
                    data-testid="actions-download-button"
                  >
                    <GetAppOutlined fontSize="small" color="secondary" />
                  </LoadingButton>
                </Can>
              </Box>
            ))
        );
      },
      sortable: false,
    },
  ];

  const dataWithDisabledRows = data.map((item) => ({
    ...item,
    disabled: !availableStatusToDownload.includes(item.state.name),
  }));

  const downloadPermission: Permission[] = [{ path: "/api/v1/cpa/invoices/archive", method: "POST" }];
  return (
    <Box width="100%" data-testid="payout-history">
      {/* $FlowFixMe */}
      <Table
        {...tableProps}
        onSelectAll={(items) => onSelectAll(items.filter(({ disabled }) => !disabled))}
        data={dataWithDisabledRows}
        title="Payout History"
        rowSelectAvailable={(item): boolean => availableStatusToDownload.includes(item.state.name)}
        allSelectAvailable={(): boolean => true}
        columns={columns}
        rowsPerPage={[200, 500, 1000]}
        onLoad={onGetPayoutHistoryList}
        Actions={(
          <TableActions>
            <Can permissions={downloadPermission} renderNoAccess={<Box py={2} />}>
              <Loader loading={isDownloadInvoiceLoading}>
                <SimpleTableActionButton
                  tooltip="Download"
                  disabled={isDownloadInvoiceLoading}
                  onClick={() => onDownloadInvoice(selectedIds)}
                  Icon={<Download />}
                  data-testid="table-panel-download-button"
                />
              </Loader>
            </Can>
          </TableActions>
        )}
      />
      {!isLoading && data.length === 0 && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography>Cpa affiliate has no payout history yet</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PayoutHistoryTable;
