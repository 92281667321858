/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  select,
  debounce,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { setTableData, changeTableItemsTotalAmount, setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import type { Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { GET_TEMPLATE_FILTER_DATA_SAGA } from "../../helpers/constants/templates";
import { CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import { fetchTemplateFilterData } from "../../services/templatesApi";
import { getReportName, getConfig } from "../../selectors/templates";
import { getFiltersList } from "../../selectors/filters";
import type { Filter } from "../../reducers/filters";
import type { ConfigAttribute } from "../../reducers/templates";

export function* getTemplateFilterData(): Saga<void> {
  yield put(setLoading(CPA_OFFER_TABLE, true));
  try {
    const reportName: string = yield select(getReportName);
    const page: number = yield select(getTablePage, CPA_OFFER_TABLE);
    const limit: number = yield select(getTablePageSize, CPA_OFFER_TABLE);
    const sorting: Sorting = yield select(getTableSorting, CPA_OFFER_TABLE);
    const attrFilters: Array<Filter> = yield select(getFiltersList);
    const { attributes }: { attributes: Array<ConfigAttribute> } = yield select(getConfig);

    const [current, direction]: [string, mixed] = Object.entries(sorting)[0] || [];

    yield put(setTableSelection(CPA_OFFER_TABLE, []));

    const { data, count }: {
      data: Array<{ [string]: string | number }>, count: number
    } = yield call(fetchTemplateFilterData, {
      reportName,
      page,
      limit,
      sorting: { current, direction },
      attributes: [
        ...attributes
          .filter(({ isVisible }) => !isVisible)
          .map(({ name }: ConfigAttribute) => ({
            name,
            filters: [],
          })),
        ...attrFilters.map(({ name, filters }: Filter) => ({
          name,
          filters: filters
            .filter(({ value }) => value !== "")
            .map(({ value }) => value),
        })),
      ],
    });

    yield put(setTableData(CPA_OFFER_TABLE, data));
    yield put(changeTableItemsTotalAmount(CPA_OFFER_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch data", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_OFFER_TABLE, false));
  }
}

export function* watchGetTemplateFilterData(): Saga<void> {
  yield debounce(500, GET_TEMPLATE_FILTER_DATA_SAGA, getTemplateFilterData);
}
