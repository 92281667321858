// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { useParams, type Match } from "react-router-dom";
import { getFormDataSaga, setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import {
  SelectForm,
  TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { FormHeader, Loader } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { useDropdownsStaticList, useList } from "../../hoocks/useDictionary";
import { useLoading } from "../../hoocks/useLoading";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { FORM_KEY_CRM_TEMPLATE } from "../../helpers/constants/crm";
import Form from "../Form";
import validate from "./validate";
import { defaultFormData, type State } from "../../pages/CrmTemplates";
import Tags from "./Tags";
import Content from "./Content";

const CrmTemplatesForm = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();
  const { id: objectId }: $PropertyType<Match, "params"> = useParams();

  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_CRM_TEMPLATE, objectId));
    }
  }, [dispatch, objectId]);

  useEffect(() => () => {
    dispatch(setFormData(FORM_KEY_CRM_TEMPLATE, defaultFormData));
  }, []);

  const id = useSelector((state: State) => getFormField(state, FORM_KEY_CRM_TEMPLATE, "id"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  useDropdownsStaticList("mailTemplate");
  const productTypes: DropDownObjItemType[] = useList("productId");
  const localeTypes: DropDownObjItemType[] = useList("defaultLocale");

  return (
    <Form id={FORM_KEY_CRM_TEMPLATE} redirectOnSave="/crm/templates" validate={validate}>
      <FormHeader
        title={id ? `Edit template: ${String(id)}` : "Create template"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="Mail Template">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <SelectForm
                name="productId"
                label="Product"
                options={productTypes}
              />
              <SelectForm
                name="defaultLocale"
                label="Locale"
                options={localeTypes}
              />
            </Loader>
          </Box>
        </SetupCard>

        <Tags />
        <Content />
      </Container>
    </Form>
  );
};

export default CrmTemplatesForm;
