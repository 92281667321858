// @flow
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Attribute, FactoryAttributes } from "../defaultModels";
import * as AttributeModels from "./cpaOfferModels";
import type { Offer } from "./types";
import type { Dropdown, TemplateAttribute } from "../../../reducers/templates";
import type { Classes } from "../../../containers/CpaOfferIndex/CpaOfferIndex";

class CpaFactoryAttributes extends FactoryAttributes {
  constructor() {
    super();
    Object.values(AttributeModels).forEach((Model) => {
      // $FlowFixMe
      const newModel: Attribute = new Model();
      this.generatedModels[newModel.getName()] = newModel;
    });
  }
}

export const CpaFactoryModel: CpaFactoryAttributes = new CpaFactoryAttributes();

export const getColumns: ({
  attributes: Array<TemplateAttribute>,
  dropdownsList: { [string]: Array<Dropdown> },
  classes: Classes,
}) => Column<Offer>[] = ({ attributes, classes, dropdownsList }) => attributes
  .filter(({ isHidden }: TemplateAttribute): boolean => !isHidden)
  .map(({ name }: TemplateAttribute): Column<Offer> => {
    const model: Attribute = CpaFactoryModel.getModel(name);
    const {
      label,
      className,
      sortable,
      searchable,
      render,
      SearchComponent,
    }: Attribute = model;

    model.setDropdowns(dropdownsList);

    return {
      label,
      field: name,
      sortable,
      searchable,
      className: classes[className],
      render,
      SearchComponent,
    };
  });
