// @flow
/* eslint-disable import/max-dependencies */
import moment from "moment";
import React, { type ChildrenArray } from "react";
import { userService } from "../../services/user";
import { userHaveSomeMarketingGroup } from "../../pages/CpaOffer/components/Form/Form";
import { type DictionaryParams } from "../../actions/dictionary";

export const getAffiliateInfoMsg: (string) => string = (lastConversionDate): string => {
  const d1: moment$Moment = moment(new Date());
  const d2: moment$Moment = moment(lastConversionDate);

  const duration: moment$MomentDuration = moment.duration(d1.diff(d2));
  const months: number = duration.asMonths();

  return months > 5 ? "Партнер может попасть на списание, так как больше 6 месяцев не было конверсий" : "";
};

export const getPayoutAmount: (
  currency: string,
  amount: number
) => string = (
  currency: string,
  amount: number
): string => {
  const payoutAmount: number = amount || 0;
  if (!currency) {
    return String(payoutAmount);
  }
  const format: Intl.NumberFormat = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return format.format(payoutAmount);
};

export type ParsedDate = {|
  month: string,
  day: string,
  year: string,
  hour: string,
  minute: string,
  second: string,
|}

export type FormatToPartsType = | 'day' | 'dayPeriod' | 'era' | 'hour' | 'literal'
  | 'minute' | 'month' | 'second' | 'timeZoneName' | 'weekday' | 'year';

const parseDate: (date: string | number, options?: Intl$DateTimeFormatOptions) => ParsedDate = (date, options = {}) => {
  const currentOptions: Intl$DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    ...options,
  };

  const [
    { value: month }, ,
    { value: day }, ,
    { value: year }, ,
    { value: hour }, ,
    { value: minute }, ,
    { value: second },
  ]: Array<{
    value: string,
    type: FormatToPartsType
  }> = new Intl.DateTimeFormat("en-US", currentOptions).formatToParts(new Date(date || Date.now()));

  return {
    month,
    day,
    year,
    hour,
    minute,
    second,
  };
};

export const formatDate: (string | number) => string = (date) => {
  const {
    month,
    day,
    year,
    hour,
    minute,
    second,
  }: ParsedDate = parseDate(date);

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export function convertBytesToMbsOrKbs(filesize: number): string {
  let size: string = "";
  if (filesize >= 1048576) {
    size = `${filesize / 1048576} megabytes`;
  }
  else if (filesize >= 1024) {
    size = `${filesize / 1024} kilobytes`;
  }
  else {
    size = `${filesize} bytes`;
  }
  return size;
}

export function transformOfferType(val: string): string {
  const mapper = {
    Ext: "1",
    Int: "0",
    "1": "Ext",
    "0": "Int",
  };
  return mapper[val];
}

export function recursiveMap<T: Object>(children: ChildrenArray<T>, fn: $NonMaybeType<T>): $NonMaybeType<T> {
  return React.Children.map(children, (child: $NonMaybeType<T>): $NonMaybeType<T> => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      return fn(React.cloneElement(child, {
        children: recursiveMap(child.props.children, fn),
      }));
    }

    return fn(child);
  });
}

export function copyToClipboard(val: string) {
  const input = document.createElement("input");
  input.value = val;
  // $FlowFixMe
  document.body.appendChild(input);
  input.select();
  document.execCommand("copy");
  input.remove();
}

export const compareById: (*, *) => boolean = (current, selected) => current.id === selected.id;

/**
 * Check offer allowed changing activate in current user
 * @param {{ managerId: string, marketingGroups: string[] }} offer to check
 * @returns {boolean} is allowed ?
 */
export function isOfferAllowedActivate(offer: { managerId: string | null, marketingGroups: number[] }): boolean {
  const canActivateAll: boolean = userService.can(["cpa.offers.activateAll"]);
  const canActivateIt: boolean = userService.can(["/cpa/offers/activate"]);
  const isManager: boolean = offer.managerId === userService.getId();
  const isAllowedByMarketingGroup: boolean = userHaveSomeMarketingGroup(offer.marketingGroups);

  return ((canActivateIt) && (isAllowedByMarketingGroup || isManager)) || canActivateAll;
}

export function getDictionaryLoadingKey(params: DictionaryParams): string {
  return `${getDictionaryKey(params)}-loading`;
}

export function getDictionaryKey(params: DictionaryParams): string {
  return `${params.dictionary}-${params.type}`;
}

/**
 * Helper, convert base64 to blob file
 * @param {String} b64Data data
 * @param {String} contentType mime type
 * @param {number} [sliceSize] part size
 * @returns {Blob} file
 */
export const b64toBlob = (b64Data: string, contentType: string = "", sliceSize: number = 512): Blob => {
  const byteCharacters: string = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset: number = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice: string = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers: number[] = new Array(slice.length);
    for (let i: number = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray: Uint8Array = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export function getStaticLoadingKey(dictionayName: string): string {
  return `${dictionayName}-static`;
}

/**
 * Get value or default value if value is null or undefined
 * @param {number|string|null|undefined} value optional value
 * @param {string} defaultValue is default value returned if no value
 * @returns {string} value
 */
export function getValue(value: ?number | string, defaultValue: string): number | string {
  return value === null || value === undefined ? defaultValue : value;
}
