/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA } from "../../helpers/constants/campaignSettings";
import { getAffiliateId } from "../../selectors/manageAffiliate";
import { getCampaignSettingsFormData } from "../../selectors/campaignSettings";
import { saveOffer } from "../../services/campaignSettingsApi";
import {
  closeCampaignSettingsModal,
  getCampaignSettingsOffersSaga,
} from "../../actions/campaignSettings";

const mapFormSubscription = (form) => ({
  offerId: form.offerId,
  ncrAmount: form.ncrAmount,
  ncrUnit: form.ncrUnit,
  shave: form.shaveAmount,
  payout: form.payoutAmount,
  payoutFirst: form.payoutAmountFirst,
  payoutRepeats: form.payoutAmountRepeats,
  postback: form.postbackUrl,
  isActive: form.isActive,
});

export function* saveCampaignSettingsOffer(): Saga<void> {
  const affiliateId: string = yield select(getAffiliateId);
  const fields = yield select(getCampaignSettingsFormData);
  const form = { ...fields };

  if (form.conversionType === "revshare") {
    form.payoutAmount = form.payoutAmountFirst;
  }

  yield put(setLoading("isOfferSaveLoading", true));

  try {
    yield call(saveOffer, affiliateId, mapFormSubscription(form));
    yield put(addNotification({ message: "Successfully saved", severity: "success" }));
    yield put(closeCampaignSettingsModal());
    yield put(setErrors({}));
    yield put(getCampaignSettingsOffersSaga());
  }
  catch (error) {
    const errorsArr: Array<{ field: string, error: string }> = error?.response?.data?.errors;
    if (errorsArr) {
      yield put(setErrors(prepareFetchErrors(error.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: error.errorMessage || "Failed to save offer", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  finally {
    yield put(setLoading("isOfferSaveLoading", false));
  }
}

export function* watchSaveCampaignSettingsOffer(): Saga<void> {
  yield takeEvery(SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA, (saveCampaignSettingsOffer: Function));
}
