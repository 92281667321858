// @flow
/* eslint-disable import/max-dependencies */
import {
  take,
  debounce,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { GET_MANAGE_AFFILIATES_DATA_SAGA, CPA_AFFILIATES_LIST_TABLE } from "../../helpers/constants/manageAffiliate";
import {
  getManageAffiliateTableHeaders,
} from "../../selectors/manageAffiliate";
import { getManageAffiliateData } from "../../services/manageAffiliateApi";
import { setLoader } from "../../actions/manageAffiliate";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";

export function* makeFetch(): Saga<void> {
  const headers: { name: string, headersOrder: Array<string> } = yield select(getManageAffiliateTableHeaders);

  const page: number = yield select(getTablePage, CPA_AFFILIATES_LIST_TABLE);
  const limit: number = yield select(getTablePageSize, CPA_AFFILIATES_LIST_TABLE);
  const sorting: Sorting = yield select(getTableSorting, CPA_AFFILIATES_LIST_TABLE);
  const filters: Filters = yield select(getTableFilters, CPA_AFFILIATES_LIST_TABLE);

  try {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, true));

    const [current]: $Keys<Sorting>[] = Object.keys(sorting);
    const direction: $Values<Sorting> = current ? sorting[current] : "asc";

    const {
      data: { data, count },
    }: {
      data: { data: CpaAffiliatesListItem[], count: number}
    } = yield call(getManageAffiliateData, {
      headers: [...headers.headersOrder, "isActive"],
      filters: Object.keys(filters).reduce((acc, key) => { // todo remove cast in MTU-72544
        const numberKeys: string[] = ["isActive"];
        const val = numberKeys.includes(key) ? parseFloat(filters[key]) : filters[key];
        return { ...acc, [key]: val };
      }, {}),
      sorting: {
        ...(current && { current, direction }), // TODO: fix api to receive sorting as it comes from redux reducer
      },
      limit,
      page,
    });

    yield put(setLoader({
      key: "isFiltersLoaded",
      value: true,
    })); // need remove this loader after refactor component

    yield put(setTableData(CPA_AFFILIATES_LIST_TABLE, data));
    yield put(changeTableItemsTotalAmount(CPA_AFFILIATES_LIST_TABLE, count));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
  }
  finally {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, false));
  }
}

export default function* watchGetManageAffiliatesDataSaga(): Saga<void> {
  yield take(GET_MANAGE_AFFILIATES_DATA_SAGA);
  yield call(makeFetch);
  yield debounce(1000, GET_MANAGE_AFFILIATES_DATA_SAGA, (makeFetch: Function));
}
