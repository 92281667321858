// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetSubratesIndexList from "../../sagas/getSubratesIndexList";
import watchGetSubratesInfolist from "../../sagas/getSubratesInfolist";
import watchDeleteSubrateSaga from "../../sagas/deleteSubrateSaga";
import watchMassAdjustSaga from "../../sagas/saveMassAdjustSaga";
import watchGetSubratesDictionary from "../../sagas/getSubratesDictionary";
import watchSaveSubrateTemplate from "../../sagas/saveSubrateTemplate";
import watchSelectOfferForSubrate from "../../sagas/selectOfferForSubrate";
import watchSelectFieldsByLocationForSubrate from "../../sagas/selectFieldsByLocationForSubrate";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";
import watchSearchOfferForSubrate from "../../sagas/searchOfferForSubrate";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetSubratesIndexList),
    call(watchGetSubratesInfolist),
    call(watchGetSubratesDictionary),
    call(watchSaveSubrateTemplate),
    call(watchDeleteSubrateSaga),
    call(watchSaveFormDataSaga),
    call(watchSelectOfferForSubrate),
    call(watchSelectFieldsByLocationForSubrate),
    call(watchMassAdjustSaga),
    call(watchGetFormDataSaga),
    call(watchSearchOfferForSubrate),
  ]);
}
