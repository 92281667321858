// @flow
export const SET_SORT_CAMPAIGN_SETTINGS: "SET_SORT_CAMPAIGN_SETTINGS" = "SET_SORT_CAMPAIGN_SETTINGS";
export const SET_FILTER_CAMPAIGN_SETTINGS: "SET_FILTER_CAMPAIGN_SETTINGS" = "SET_FILTER_CAMPAIGN_SETTINGS";
export const DELETE_FILTER_CAMPAIGN_SETTINGS: "DELETE_FILTER_CAMPAIGN_SETTINGS" = "DELETE_FILTER_CAMPAIGN_SETTINGS";
export const CLEANUP_TABLE_CAMPAIGN_SETTINGS: "CLEANUP_TABLE_CAMPAIGN_SETTINGS" = "CLEANUP_TABLE_CAMPAIGN_SETTINGS";
export const SET_SELECTED_ROWS_CAMPAIGN_SETTINGS: "SET_SELECTED_ROWS_CAMPAIGN_SETTINGS" = "SET_SELECTED_ROWS_CAMPAIGN_SETTINGS";
export const SET_OFFERS: "SET_OFFERS" = "SET_OFFERS";

export const GET_CAMPAIGN_SETTINGS_OFFERS_SAGA: "GET_CAMPAIGN_SETTINGS_OFFERS_SAGA" = "GET_CAMPAIGN_SETTINGS_OFFERS_SAGA";
export const SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA: "SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA" = "SAVE_CAMPAIGN_SETTINGS_OFFER_SAGA";
export const RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA: "RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA" = "RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA";
export const SET_CAMPAIGN_SETTINGS_FORM_DATA: "SET_CAMPAIGN_SETTINGS_FORM_DATA" = "SET_CAMPAIGN_SETTINGS_FORM_DATA";
export const OPEN_CAMPAIGN_SETTINGS_FORM_MODAL: "OPEN_CAMPAIGN_SETTINGS_FORM_MODAL" = "OPEN_CAMPAIGN_SETTINGS_FORM_MODAL";
export const CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL: "CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL" = "CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL";

export const CAMPAIGN_SETTINGS_TABLE: "CAMPAIGN_SETTINGS_TABLE" = "CAMPAIGN_SETTINGS_TABLE";
