// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import {
  Edit,
  Link as LinkIcon,
  Settings as SettingsIcon,
  Check,
  RemoveCircle,
  Clear,
  Done,
  PersonOutline,
} from "@mui/icons-material";
import externalRedirect from "../App/ExternalRedirect";
import { userService } from "../../services/user";
import type { Props } from "./types/ManageAffiliateTableActions.types";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";

const CpaAdminTableActions: StatelessFunctionalComponent<Props> = ({
  items,
  onCopyAutoLoginLink,
  onAutoLogin,
  onChangeIsActive,
  onChangeAffiliateStatus,
  onGetAuthorsList,
  onSwitchChangeAuthorModalOpened,
  onSwitchChangeStatusModalOpened,
}: Props) => {
  const [item] = items;
  const { status }: CpaAffiliatesListItem = item;

  const marketingGroup = userService.getMarketingGroupInfo();

  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={items.length > 1}
          onClick={(): null => externalRedirect({ to: "/manageAffiliate/edit/:id/personalInfo".replace(":id", items[0].id) })}
          Icon={<Edit />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Activate"
          disabled={!onChangeIsActive}
          onClick={(): mixed => onChangeIsActive(1)}
          Icon={<Check />}
        />
        <SimpleTableActionButton
          tooltip="Deactivate"
          disabled={!onChangeIsActive}
          onClick={(): mixed => onChangeIsActive(0)}
          Icon={<RemoveCircle />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Auto login link"
          disabled={!onCopyAutoLoginLink || items.length > 1}
          onClick={(): mixed => onCopyAutoLoginLink()}
          Icon={<LinkIcon />}
        />
        <SimpleTableActionButton
          tooltip="Login as affiliate"
          disabled={!onCopyAutoLoginLink || items.length > 1}
          onClick={(): mixed => onAutoLogin()}
          Icon={<SettingsIcon />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip={status && status === "Approve" ? "Change to pending" : "Change to approve"}
          disabled={items.length > 1 || !status}
          onClick={
            status === "Approve"
              ? (): mixed => onSwitchChangeStatusModalOpened()
              : (): mixed => onChangeAffiliateStatus()
          }
          Icon={status && status === "Approve" ? <Clear /> : <Done />}
        />
        <SimpleTableActionButton
          tooltip="Change author"
          disabled={!userService.can(["CpaAffiliate.bulkUpdateManager"])}
          onClick={() => {
            // $FlowFixMe
            onGetAuthorsList(marketingGroup);
            onSwitchChangeAuthorModalOpened();
          }}
          Icon={<PersonOutline />}
        />
      </TableActionGroup>
    </TableActions>
  );
};

export default CpaAdminTableActions;
