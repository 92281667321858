// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDropdownListSaga from "../../sagas/getDropdownList";
import watchGetAdvertiserInvoicesListListSaga from "../../sagas/getAdvertiserInvoicesList";
import watchDownloadAdvertiserInvoice from "../../sagas/downloadAdvertiserInvoice";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetDropdownListSaga),
    call(watchGetAdvertiserInvoicesListListSaga),
    call(watchDownloadAdvertiserInvoice),
  ]);
}
