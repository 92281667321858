// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDropdownsStaticSaga from "../../sagas/getDropdownsStatic";
import watchDeleteCrmTemplates from "../../sagas/deleteCrmTemplates";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";
import watchGetCrmTemplatesList from "../../sagas/getCrmMailTemplateList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetCrmTemplatesList),
    call(watchDeleteCrmTemplates),
    call(watchGetDropdownsStaticSaga),
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
  ]);
}
