// @flow
import {
  takeEvery,
  call, select, put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { SAVE_POSTBACK_INFO_SAGA } from "../../helpers/constants/postbacks";
import { getManageAffiliateId, getPostbackInfoFields } from "../../selectors/manageAffiliate";
import { savePostbackInfo } from "../../services/manageAffiliateApi";
import { getPostbacksInfoSaga } from "../../actions/postbacks";
import { setTabState } from "../../services/middlewares/tabSwitchConfirm";

export function* makeFetch(): Saga<void> {
  const affiliateId: string = yield select(getManageAffiliateId);
  const fields: { [string]: * } = yield select(getPostbackInfoFields);

  Object.keys(fields).forEach((field: string) => {
    fields[field] = typeof fields[field] === "string" ? String(fields[field]).trim() : fields[field];
  });

  try {
    yield call(savePostbackInfo, affiliateId, fields);
    yield put(getPostbacksInfoSaga());
    yield put(addNotification({ message: "Postback info saved", severity: "success" }));
    setTabState(false); // need refactor this
  }
  catch (error) {
    yield put(setErrors(error.errorMessage));
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watch(): Saga<void> {
  // todo add event type here
  yield takeEvery(SAVE_POSTBACK_INFO_SAGA, (makeFetch: Function));
}
