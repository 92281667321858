// @flow
import type { Node } from "react";
import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { type Dayjs } from "dayjs";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

type ParsableDate = string | null | void;

export type Props = {|
  name: string,
  label: string,
  error: string,
  format: string,
  mask: string,
  minDateMessage: ?string,
  maxDateMessage: ?string,
  value: ParsableDate,
  onChange: (ParsableDate) => *,
  minDate: ParsableDate;
  maxDate: ParsableDate;
  disabled: boolean,
  disablePast: boolean;
  disableFuture: boolean;
|}

const formatISO = (v: Dayjs): string => dayjs(v).format("YYYY-MM-DD");
const parseISO = (v: string): Dayjs => dayjs(v);

function DatePickerForm({
  value: initialValue,
  onChange,
  error,
  label,
  name,
  format,
  mask,
  minDate,
  maxDate,
  minDateMessage,
  maxDateMessage,
  ...props
}: Props): Node {
  const [localValue, setLocalValue] = useState<Dayjs | null>(null);
  const [localError, setLocalError] = useState<string>("");

  const isValid: boolean = !!localValue && localValue.isValid();

  useEffect(() => {
    setLocalValue(initialValue ? parseISO(initialValue) : null);
  }, [initialValue]);

  const handleChange = (date: Dayjs | null) => {
    onChange(date ? formatISO(date) : null);
  };

  const onError = (reason: string) => {
    switch (reason) {
      case "invalidDate": {
        setLocalError("Invalid date format");
        break;
      }
      case "disablePast": {
        setLocalError("Values in the past are not allowed");
        break;
      }
      case "maxDate": {
        setLocalError(maxDateMessage || `Date should not be after ${maxDate ? formatISO(parseISO(maxDate)) : ""}`);
        break;
      }
      case "minDate": {
        setLocalError(minDateMessage || `Date should not be before ${minDate ? formatISO(parseISO(minDate)) : ""}`);
        break;
      }
      default: {
        setLocalError("");
      }
    }
  };

  const TextFieldComponent = (p) => (
    <TextField
      {...p}
      data-testid={name}
      error={Boolean(localError || error)}
      helperText={localError || error}
      size="small"
      margin="dense"
      onBlur={(e) => {
        if (isValid && initialValue !== (localValue && formatISO(localValue))) {
          handleChange(localValue);
        }
        else if (!e.target.value) {
          handleChange(null);
        }
      }}
    />
  );

  return (
    <FormControl fullWidth variant="outlined">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...props}
          allowSameDateSelection
          autoOk
          clearable
          renderInput={TextFieldComponent}
          disableToolbar
          size="small"
          variant="inline"
          inputFormat={format}
          mask={mask}
          onError={onError}
          data-testid={name}
          label={label}
          value={localValue}
          onChange={(date) => setLocalValue(date)}
          onAccept={(date) => handleChange(date)}
          minDate={minDate ? parseISO(minDate) : null}
          maxDate={maxDate ? parseISO(maxDate) : null}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

DatePickerForm.defaultProps = {
  onChange: () => {},
  error: "",
  value: "",
  minDate: dayjs().subtract(5, "y").format("YYYY-MM-DD"),
  maxDate: dayjs().add(5, "y").format("YYYY-MM-DD"),
  disableFuture: false,
  disablePast: false,
  disabled: false,
  format: "YYYY-MM-DD",
  mask: "____-__-__",
  minDateMessage: undefined,
  maxDateMessage: undefined,
};

export default withFormField(DatePickerForm);
