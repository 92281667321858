// @flow
import type { Node } from "react";
import React from "react";
import { Box } from "@mui/material";

type Props = {
  children: Node,
  weight?: number,
  padding?: number,
}

function SplitComponent({ children, weight = 1, padding = 1 }: Props): Node {
  const [left, right] = React.Children.toArray(children);
  return (
    <Box display="flex">
      <Box flex={weight} pr={padding}>
        {left}
      </Box>
      <Box flex={1}>
        {right}
      </Box>
    </Box>
  );
}

export default SplitComponent;
