// @flow
import {
  CHANGE_AFFILIATE_ID,
  GET_AFFILIATE_INFO_SAGA,
  GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA,
  SET_AFFILIATE_INFO,
  SET_PAYMENT_STATS,
  SET_PAYMENT_DETAILS,
  SET_PAYMENT_SIMILAR_AFFILIATES,
  SET_PAYMENT_NEXT_PAYMENT_DATE,
  SET_EMPTY_PAYMENT_INFO_STATE,
} from "../../helpers/constants/payments";
import type { DefaultStateType } from "../../reducers/paymentInfo";
import type { AffiliateInfoPayloadType } from "../../components/AffiliateInfoCard";
import type { NextPaymentDateType } from "../../components/NextPaymentDateLabel/types/NextPaymentDateLabel.types";
import type { PaymentStatsType } from "../../components/PaymentInfoCard";
import type { SimilarAffiliateType } from "../../components/SimilarAffiliatesCard/types/SimilarAffiliatesCard.types";

export type ChangeAffiliateIdAction = {|
  type: typeof CHANGE_AFFILIATE_ID,
  affId: string,
|};

export type GetAffiliateInfoSagaAction = {|
  type: typeof GET_AFFILIATE_INFO_SAGA,
|};

export type GetPaymentNextPaymentDateSagaAction = {|
  type: typeof GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA,
|};

export type SetAffiliateInfoAction = {|
  type: typeof SET_AFFILIATE_INFO,
  payload: AffiliateInfoPayloadType,
|};

export type SetPaymentNextPaymentDateAction = {|
  type: typeof SET_PAYMENT_NEXT_PAYMENT_DATE,
  payload: NextPaymentDateType,
|};

export type SetPaymentStatsAction = {|
  type: typeof SET_PAYMENT_STATS,
  payload: PaymentStatsType,
|};

export type SetPaymentDetailsAction = {|
  type: typeof SET_PAYMENT_DETAILS,
  payload: AffiliateInfoPayloadType,
|};

export type SetSimilarAffiliatesAction = {|
  type: typeof SET_PAYMENT_SIMILAR_AFFILIATES,
  payload: Array<SimilarAffiliateType>,
|};

export type SetEmptyPaymentInfoState = {|
  type: typeof SET_EMPTY_PAYMENT_INFO_STATE,
  keys: Array<$Keys<DefaultStateType>>
|};

export type Actions =
  ChangeAffiliateIdAction
  | GetAffiliateInfoSagaAction
  | GetPaymentNextPaymentDateSagaAction
  | SetAffiliateInfoAction
  | SetPaymentNextPaymentDateAction
  | SetPaymentStatsAction
  | SetPaymentDetailsAction
  | SetSimilarAffiliatesAction
  | SetEmptyPaymentInfoState;

export const changeAffiliateId: (affId: string) => ChangeAffiliateIdAction = (affId) => ({
  type: CHANGE_AFFILIATE_ID,
  affId,
});

export const getAffiliateInfoSaga: () => GetAffiliateInfoSagaAction = () => ({
  type: GET_AFFILIATE_INFO_SAGA,
});

export const getPaymentNextPaymentDateSaga:() => GetPaymentNextPaymentDateSagaAction = () => ({
  type: GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA,
});

export const setAffiliateInfo: (payload: AffiliateInfoPayloadType) => SetAffiliateInfoAction = (payload) => ({
  type: SET_AFFILIATE_INFO,
  payload,
});

export const setPaymentNextPaymentDate: (
  payload: NextPaymentDateType
) => SetPaymentNextPaymentDateAction = (payload) => ({
  type: SET_PAYMENT_NEXT_PAYMENT_DATE,
  payload,
});

export const setPaymentStats: (payload: PaymentStatsType) => SetPaymentStatsAction = (payload) => ({
  type: SET_PAYMENT_STATS,
  payload,
});

export const setPaymentDetails: (payload: AffiliateInfoPayloadType) => SetPaymentDetailsAction = (payload) => ({
  type: SET_PAYMENT_DETAILS,
  payload,
});

export const setSimilarAffiliates: (
  payload: Array<SimilarAffiliateType>
) => SetSimilarAffiliatesAction = (payload) => ({
  type: SET_PAYMENT_SIMILAR_AFFILIATES,
  payload,
});

export const setEmptyPaymentInfoState: (keys: Array<$Keys<DefaultStateType>>) => SetEmptyPaymentInfoState = (keys) => ({
  type: SET_EMPTY_PAYMENT_INFO_STATE,
  keys,
});
