// @flow
import {
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { SAVE_TEMPLATE_SAGA } from "../../helpers/constants/subrates";
import { saveTemplateManageAffiliate } from "../../services/manageAffiliateApi";
import type { SaveTemplateSaga } from "../../actions/manageAffiliate";

export function* makeFetch(action: SaveTemplateSaga): Saga<void> {
  const { payload }: SaveTemplateSaga = action;
  try {
    yield call(saveTemplateManageAffiliate, payload);
    yield put(addNotification({ message: "Template was saved.", severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveTemplateManageAffiliates(): Saga<void> {
  // todo add event type here
  yield takeEvery(SAVE_TEMPLATE_SAGA, (makeFetch: Function));
}
