// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  reducer as errors,
  type State as ErrorsState,
  initState as initErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import mainSaga from "./saga";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import { CRM_MAILS_TABLE, FORM_KEY_CRM_MAILS, CRM_MAILS_TARGETING_TYPES } from "../../helpers/constants/crm";
import type { CrmMailFromData } from "../../containers/CrmMailsForm";

export const defaultFormData: CrmMailFromData = {
  id: 0,
  name: "",
  subject: "",
  targetingType: CRM_MAILS_TARGETING_TYPES.country,
  userType: "",
  userGroup: "",
  userDeclineReasonId: null,
  userCountry: [],
  userEmails: [],
  sender: "",
  templateId: "",
  dateSend: "",
  statusMsg: "New",
  userPlatform: "",
};

export type State = $ReadOnly<{|
  tables: TableState,
  loading: LoadingState<string>,
  notifications: NotificationsState,
  errors: ErrorsState,
  form: FormState,
  dictionaries: DictionariesState,
|}>

function mapMailsToState(): State {
  return {
    tables: initTableState({
      [CRM_MAILS_TABLE]: {
        pageSize: 20,
      },
    }),
    form: initFormState({
      [FORM_KEY_CRM_MAILS]: defaultFormData,
    }),
    loading: initLoadingState({
      [CRM_MAILS_TABLE]: false,
    }),
    notifications: initNotificationsState(),
    errors: initErrorsState(),
    dictionaries: initDictionariesState(),
  };
}

export type Actions = LoadingActions<string>
  | TableActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  tables,
  loading,
  notifications,
  errors,
  form,
  dictionaries,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapMailsToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
