// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { RecordInstance } from "immutable";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { RowForm } from "../../reducers/cpaoffer/types";
import type { DefaultStateType } from "../../reducers/cpaoffer/reducer";
import type { State } from "../../pages/CreateAffiliate";

export type StoreWithCpaOffer = $ReadOnly<{ cpaOffer: RecordInstance<DefaultStateType> }>;

export const getCpaOfferFormData: OutputSelector<StoreWithCpaOffer, *, RowForm> = createSelector(
  (state: StoreWithCpaOffer): RecordInstance<RowForm> => state.cpaOffer.get("row"),
  // $FlowFixMe
  (row: RecordInstance<RowForm>): RowForm => row.toJS()
);

export const getCpaOfferFormList: OutputSelector<*, *, *> = createSelector(
  // $FlowFixMe
  (state: StoreWithCpaOffer): RecordInstance<RowForm> => state.cpaOffer.get("lists"),
  // $FlowFixMe
  (row: RecordInstance<RowForm>): RowForm => row.toJS()
);

export function getCpaOfferIsCloneStatus(state: StoreWithCpaOffer) {
  return state.cpaOffer.get("isClone");
}

export const getLoading: OutputSelector<
  State, string, boolean
  > = createLoadingSelector<string>();
