// @flow

import { useSelector } from "react-redux";
import { getLoading } from "../selectors/loading";
// eslint-disable-next-line no-unused-vars
import type { LoadingTypes, StoreWithLoadingType } from "../selectors/loading/types";

/**
 * Get loading
 * @example
 * const loading = useLoading<>("someKey");
 * @param {string} keys of loading
 * @returns {boolean} loading
 */
// eslint-disable-next-line import/prefer-default-export
export function useLoading<T: string = LoadingTypes>(...keys: T[]): boolean {
  return useSelector((
    state: StoreWithLoadingType<T>
  ): boolean => keys.some(((key: T): boolean => getLoading<T>(state, key))));
}
