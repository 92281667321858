// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { connect, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import {
  SelectSearchComponent,
  Table,
} from "@fas/ui-core";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { isNumber } from "lodash";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { Element } from "react";
import Actions from "./components/Actions";
import CountryCell from "./components/CountryCell";
import PublicCell from "./components/PublicCell";
import StatusCell from "./components/StatusCell";
import PayoutCell from "./components/PayoutCell";
import {
  getCampaignSettingsOffersSaga,
} from "../../../../actions/campaignSettings";
import {
  getDropdownList,
} from "../../../../selectors/manageAffiliate";
import type { State } from "../../../../pages/CreateAffiliate";
import type {
  Offer,
  OwnProps,
  StateToProps,
} from "./types/CampaignSettings.types";
import FieldsForm from "./components/FieldsForm";
import { CAMPAIGN_SETTINGS_TABLE } from "../../../../helpers/constants/campaignSettings";
import type { GetCampaignSettingsOffersSaga } from "../../../../actions/campaignSettings";

type Props = {
  ...OwnProps,
  ...StateToProps,
};

const mapStateToProps: (state: State) => StateToProps = (state): StateToProps => ({
  countries: getDropdownList(state, "countries"),
  platforms: getDropdownList(state, "platforms"),
});

const CampaignSettings: StatelessFunctionalComponent<Props> = ({
  countries,
  platforms,
}: Props) => {
  const tableProps: TableProps = useTable(CAMPAIGN_SETTINGS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetCampaignSettingsOffersSaga: () => GetCampaignSettingsOffersSaga = () => (
    dispatch(getCampaignSettingsOffersSaga()));

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.value + option.title,
  });

  const columns: Array<Column<Offer>> = [
    {
      label: "Actions",
      render: (row: Offer): Element<typeof Actions> => {
        const { offerId } = row;
        return (
          <Actions row={row} field="offerId" value={offerId} />
        );
      },
    },
    {
      label: "Offer id",
      field: "offerId",
      sortable: true,
      searchable: true,
    },
    {
      label: "Offer name",
      field: "offerName",
      sortable: true,
      searchable: true,
    },
    {
      field: "country",
      label: "Countries",
      sortable: false,
      searchable: true,
      render: (row: Offer): Element<typeof CountryCell> => {
        const { country }: Offer = row;
        return (
          <CountryCell value={country} />
        );
      },
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          filterOptions={filterOptions}
          values={countries && countries.length > 0 && [
            { title: "All", value: "" },
            ...countries.map((item) => ({ title: item.country_name, value: item.country_short_name })),
          ]}
        />
      ),
    },
    {
      field: "platform",
      label: "Platform",
      sortable: false,
      searchable: true,
      render: (row: Offer): Node => (row.platform || "-"),
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={platforms && platforms.length > 0 && [
            { title: "All", value: "" },
            ...platforms.map((item) => ({ title: item, value: item })),
          ]}
        />
      ),
    },
    {
      label: "Conversion type",
      field: "conversionType",
      sortable: true,
      searchable: true,
    },
    {
      label: "Is public",
      field: "isPublic",
      searchable: true,
      render: (row: Offer): Node => {
        const { isPublic }: Offer = row;
        return (
          <PublicCell value={isPublic} />
        );
      },
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Public", value: "1" },
            { title: "Private", value: "0" },
          ]}
        />
      ),
    },
    {
      label: "Status",
      field: "isActive",
      searchable: true,
      render: (row: Offer): Node => {
        const { isActive }: Offer = row;
        return (
          <StatusCell value={isActive} />
        );
      },
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { title: "All", value: "" },
            { title: "Approved", value: "1" },
            { title: "Pending", value: "0" },
          ]}
        />
      ),
    },
    {
      label: "Custom postback",
      field: "postbackUrl",
      render: (row: Offer): Node => (row.postbackUrl || ""),
    },
    {
      label: "Custom commission",
      field: "payoutAmount",
      render: (row: Offer): Element<typeof PayoutCell> => <PayoutCell row={row} />,
    },
    {
      label: "Default commission",
      field: "payoutAmountOffer",
      render: (row: Offer): Node => (row.payoutAmountOffer || ""),
    },
    {
      label: "Custom shave %",
      field: "shaveAmount",
      render: (row: Offer): Node => (isNumber(row.shaveAmount) ? row.shaveAmount : ""),
    },
    {
      label: "Default shave %",
      field: "shaveAmountOffer",
      render: (row: Offer): Node => (isNumber(row.shaveAmountOffer) ? row.shaveAmountOffer : ""),
    },
    {
      label: "Ncr",
      field: "ncrAmount",
      render: (row:Offer): Node => (row.ncrAmount || ""),
    },
    {
      label: "Ncr unit",
      field: "ncrUnit",
      render: (row: Offer): Node => (row.ncrUnit || ""),
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      m={2}
    >
      <Box width="100%" data-testid="campaign-settings">
        {/* $FlowFixMe */}
        <Table
          {...tableProps}
          title="Campaign settings"
          rowSelectAvailable={(): boolean => false}
          allSelectAvailable={(): boolean => false}
          columns={columns}
          onLoad={onGetCampaignSettingsOffersSaga}
        />
      </Box>
      <FieldsForm />
    </Box>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps
)(CampaignSettings);
