// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import type { Classes, DialogClasses } from "./types/SubrateInfoConfirmActionModal.types";
import type { SubrateDataObjType } from "../../reducers/subrates";

type Props = {
  items: SubrateDataObjType[],
  open: boolean,
  onClose: () => mixed,
  onApply: () => mixed,
};

const useStyles: () => Classes = makeStyles((): { [$Keys<Classes>]: mixed } => ({
  borderRadius: {
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    backgroundColor: "#fff",
  },
  title: {
    color: "#fff",
  },
  actions: {
    backgroundColor: "#fff",
  },
}));

const useDialogStyles: () => DialogClasses = makeStyles((theme): { [$Keys<DialogClasses>]: mixed } => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const MAX_DISPLAY_COUNT: number = 100;

const ItemRow: StatelessFunctionalComponent<{ id: string }> = ({ id }) => (
  <Typography component="p" key={id}>
    id:
    {" "}
    <Link component="a" href={`/subrates/edit/${id}`} underline="always">
      {id}
    </Link>
  </Typography>
);

const SubrateInfoConfirmActionModal: StatelessFunctionalComponent<Props> = ({
  items,
  open,
  onClose,
  onApply,
}) => {
  const classes: Classes = useStyles();
  const dialogClasses: DialogClasses = useDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      classes={dialogClasses}
      fullWidth
    >
      <DialogTitle className={classes.title}>Confirm deletion</DialogTitle>
      <DialogContent dividers className={classes.borderRadius}>
        <Typography component="div" data-testid="confirm-subrate-info-action-modal-label">
          {"Are you sure you want to delete "}
          {items.length > MAX_DISPLAY_COUNT && (
            <Typography component="p" color="primary" display="inline">
              {items.length}
              &nbsp;
            </Typography>
          )}
          items
          {items.length > MAX_DISPLAY_COUNT ? "?" : ":"}
        </Typography>
        {items.length <= MAX_DISPLAY_COUNT && (
          <div
            data-testid="confirm-subrate-info-action-modal-content"
          >
            {items
              .map((item: SubrateDataObjType): Element<typeof ItemRow> => (
                <ItemRow key={item.id} id={String(item.id)} />
              ))}
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="primary"
          onClick={onClose}
          data-testid="confirm-subrate-info-action-modal-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={(): mixed => {
            onApply();
            onClose();
          }}
          data-testid="confirm-subrate-info-action-modal-apply-btn"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubrateInfoConfirmActionModal;
