// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import type { SagaMiddleware } from "redux-saga";
import {
  reducer as errors,
  type State as ErrorsState,
  initState as initErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import form, { initFormState } from "@fas/ui-framework/lib/redux/reducers/form";
import type { State as FormState } from "@fas/ui-framework/lib/redux/reducers/form/reducer";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import subrates, {
  type StateType as SubratesState,
  initState as initSubratesState,
} from "../../reducers/subrates";
import type { Actions as SubratesActions } from "../../actions/subrates";
import message, { initMessageState } from "../../reducers/message";
import type { State as MessageState } from "../../reducers/message";
import { SUBRATES_INDEX_TABLE, SUBRATES_INFO_TABLE, FORM_KEY_SUBRATE } from "../../helpers/constants/subrates";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  errors: ErrorsState,
  tables: TableState,
  loading: LoadingState<string>,
  notifications: NotificationsState,
  dictionaries: DictionariesState,
  message: MessageState,
  form: FormState,
  subrates: SubratesState,
|}>

export type Actions = TableActions
  | LoadingActions<string>
  | NotificationsActions
  | SubratesActions

export function mapPageToState(): State {
  return {
    tables: initTableState({
      [SUBRATES_INDEX_TABLE]: {
        pageSize: 20,
      },
      [SUBRATES_INFO_TABLE]: {
        pageSize: 20,
      },
    }),
    loading: initLoadingState({
      [SUBRATES_INDEX_TABLE]: false,
      [SUBRATES_INFO_TABLE]: false,
    }),
    notifications: initNotificationsState(),
    errors: initErrorsState(),
    dictionaries: initDictionariesState(),
    message: initMessageState(),
    subrates: initSubratesState(),
    form: initFormState({
      [FORM_KEY_SUBRATE]: {
        id: "",
        affiliate: { label: "", value: "" },
        cpaCampaign: { label: "", value: "" },
        country: "",
        utmContent: "",
        s3: "",
        conversionType: "",
        payoutAmount: "",
        payoutAmountFirst: "",
        payoutAmountRepeats: "",
        shaveAmount: "",
        increasedCommissionAmount: "",
        increasedCommissionThreshold: "",
        isActive: 1,
        yesterdayConversionCount: "",
      },
    }),
  };
}

const reducers: CombinedReducer<State, Actions> = combineReducers({
  errors,
  tables,
  loading,
  notifications,
  dictionaries,
  message,
  subrates,
  form,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPageToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
