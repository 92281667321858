// @flow
import type { Node } from "react";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Box, Button, Link } from "@mui/material";
import { NumberSearchComponent, SelectSearchComponent } from "@fas/ui-core";
import React from "react";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { userService } from "../../services/user";
import CountryCell from "../ManageAffiliate/components/CampaignSettings/components/CountryCell";
import type {
  TopOffersDropdownListsType,
  TopOffersType,
} from "./types/TopOffersTable.types";

export default function getColumns({ classes, dropdownLists }: {
  classes: Object,
  dropdownLists: TopOffersDropdownListsType,
}): Column<TopOffersType>[] {
  return [
    {
      field: "id",
      label: "ID",
      sortable: true,
      searchable: true,
      className: classes.smallTableCol,
      render: ({ id }: TopOffersType): Node => (
        <Button
          fullWidth
          component={Link}
          underline="none"
          color="primary"
          target="_blank"
          className={classes.buttonLink}
          disabled={!userService.can([{ path: "/api/v1/topOffers", method: "POST" }])}
          href={`/topOffers/${id}`}
        >
          { id }
        </Button>
      ),
      SearchComponent: NumberSearchComponent,
    },
    {
      field: "projectType",
      label: "Project",
      sortable: true,
      searchable: true,
      className: classes.longTableCol,
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownLists.projectTypes ? [{
            title: "All",
            value: "",
          }].concat(dropdownLists.projectTypes) : []}
        />
      ),
    },
    {
      field: "offerName",
      label: "Offer name",
      sortable: true,
      searchable: true,
      render: (row: TopOffersType): Node => (row.offerName || "-"),
      className: classes.veryLongTableCol,
    },
    {
      field: "geo",
      label: "Country",
      sortable: true,
      searchable: true,
      className: classes.longTableCol,
      render: ({ geo = [] }: TopOffersType): Node => (
        <CountryCell
          value={geo.join(", ")}
        />
      ),
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownLists.countries ? [{ title: "All", value: "" }].concat(dropdownLists.countries) : []}
        />
      ),
    },
    {
      field: "platform",
      label: "Platform",
      sortable: true,
      searchable: true,
      className: classes.smallTableCol,
      render: ({ platform = [] }: TopOffersType): Node => platform.join(", "),
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownLists.platforms ? [{ title: "All", value: "" }].concat(dropdownLists.platforms) : []}
        />
      ),
    },
    {
      field: "conversionType",
      label: "Conversion type",
      sortable: true,
      searchable: true,
      className: classes.middleTableCol,
      render: ({ conversionType }: TopOffersType): Node => (conversionType || "-"),
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownLists.conversionTypes ? [{
            title: "All",
            value: "",
          }].concat(dropdownLists.conversionTypes) : []}
        />
      ),
    },
    {
      field: "vertical",
      label: "Vertical",
      sortable: true,
      searchable: true,
      className: classes.smallTableCol,
      render: ({ vertical }: TopOffersType): Node => (vertical || "-"),
      SearchComponent: (searchProps: SearchComponentProps): Node => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownLists.verticals ? [{ title: "All", value: "" }].concat(dropdownLists.verticals) : []}
        />
      ),
    },
    {
      field: "payout",
      label: "Payout",
      sortable: true,
      searchable: true,
      className: classes.smallTableCol,
      render: ({ payout, currency }: TopOffersType): Node => {
        if (typeof payout === "number") {
          return (
            <Box display="flex">
              <Box minWidth={15}>{ currency }</Box>
              <Box>{ payout }</Box>
            </Box>
          );
        }
        return "-";
      },
      SearchComponent: NumberSearchComponent,
    },
    {
      field: "eCPM",
      label: "eCPM, $",
      sortable: true,
      searchable: true,
      className: classes.smallTableCol,
      render: ({ eCPM }: TopOffersType): Node => {
        if (typeof eCPM === "number") {
          return eCPM;
        }
        return "-";
      },
      SearchComponent: NumberSearchComponent,
    },
  ];
}
