import React, { createContext, useContext, useState } from "react";
import useUserMe from "../Affiliates/useUserMe";
import { userService } from "../../services/user";
import useStorageTenantId from "./useStorageTenantId";

type TenantContextType = {
  currentTenant: string;
  tenants: string[];
  setTenant: (tenant: string) => void;
};

const TenantContext = createContext<TenantContextType | undefined>(undefined);

export const TenantProvider: React.FC = ({ children }) => {
  const { data: { allowedTenantIds = [""], allowedTenantOperationIds } = {} } = useUserMe();
  const [tenantId = "unknown"] = allowedTenantIds;
  const [storageCurrentTenant, setStorageCurrentTenant] = useStorageTenantId();
  // eslint-disable-next-line max-len
  const [currentTenant, setCurrentTenant] = useState<string>(() => (storageCurrentTenant && allowedTenantIds.includes(storageCurrentTenant) ? storageCurrentTenant : tenantId));

  const setTenant = (tenant: string) => {
    setCurrentTenant(tenant);
    setStorageCurrentTenant(tenant);
    const list = allowedTenantOperationIds?.[tenant];
    userService.setDynamicPermissions(list || []);
  };

  return (
    <TenantContext.Provider value={{ currentTenant, setTenant, tenants: allowedTenantIds }}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error("useTenant must be used within a TenantProvider");
  }
  return context;
};
