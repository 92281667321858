// @flow
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  IconButton,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  removeAffiliateLinkSaga,
  openAffiliateLinkModal,
  setAffiliateLinkFormData,
  type Link,
} from "../../../../../../actions/affiliateLinks";

type Props = {
  row: Link,
  onRemoveAffiliateLink: typeof removeAffiliateLinkSaga,
  onOpenAffiliateLinkModal: typeof openAffiliateLinkModal,
  onsSetAffiliateLinkFormData: typeof setAffiliateLinkFormData,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRemoveAffiliateLink: removeAffiliateLinkSaga,
  onOpenAffiliateLinkModal: openAffiliateLinkModal,
  onsSetAffiliateLinkFormData: setAffiliateLinkFormData,
}, dispatch);

const Actions = ({
  row,
  onsSetAffiliateLinkFormData,
  onOpenAffiliateLinkModal,
  onRemoveAffiliateLink,
}: Props) => (
  <Box
    minWidth={100}
  >
    <IconButton
      onClick={() => {
        onsSetAffiliateLinkFormData(row);
        onOpenAffiliateLinkModal();
      }}
      data-testid="editButton"
      size="large"
    >
      <EditIcon />
    </IconButton>
    <IconButton
      onClick={() => {
        onRemoveAffiliateLink(row.id);
      }}
      size="large"
      data-testid="deleteButton"
    >
      <DeleteIcon />
    </IconButton>
  </Box>
);

export default connect(null, mapDispatchToProps)(Actions);
