// @flow
export const SET_SORT_EVENTS_POSTBACK: "SET_SORT_EVENTS_POSTBACK" = "SET_SORT_EVENTS_POSTBACK";
export const SET_FILTER_EVENTS_POSTBACK: "SET_FILTER_EVENTS_POSTBACK" = "SET_FILTER_EVENTS_POSTBACK";
export const DELETE_FILTER_EVENTS_POSTBACK: "DELETE_FILTER_EVENTS_POSTBACK" = "DELETE_FILTER_EVENTS_POSTBACK";
export const CLEANUP_TABLE_EVENTS_POSTBACK: "CLEANUP_TABLE_EVENTS_POSTBACK" = "CLEANUP_TABLE_EVENTS_POSTBACK";
export const SET_SELECTED_ROWS_EVENTS_POSTBACK: "SET_SELECTED_ROWS_EVENTS_POSTBACK" = "SET_SELECTED_ROWS_EVENTS_POSTBACK";
export const SET_EVENTS_POSTBACK_LIST: "SET_EVENTS_POSTBACK_LIST" = "SET_EVENTS_POSTBACK_LIST";
export const GET_POSTBACK_EVENTS_SAGA: "GET_POSTBACK_EVENTS_SAGA" = "GET_POSTBACK_EVENTS_SAGA";
export const SAVE_POSTBACK_EVENT_SAGA: "SAVE_POSTBACK_EVENT_SAGA" = "SAVE_POSTBACK_EVENT_SAGA";
export const DELETE_POSTBACK_EVENT_SAGA: "DELETE_POSTBACK_EVENT_SAGA" = "DELETE_POSTBACK_EVENT_SAGA";
export const CHANGE_EVENT_POSTBACK_MODAL_OPEN: "CHANGE_EVENT_POSTBACK_MODAL_OPEN" = "CHANGE_EVENT_POSTBACK_MODAL_OPEN";
export const CHANGE_CURRENT_EVENT_POSTBACK: "CHANGE_CURRENT_EVENT_POSTBACK" = "CHANGE_CURRENT_EVENT_POSTBACK";

export const POSTBACK_EVENTS_TABLE: "POSTBACK_EVENTS_TABLE" = "POSTBACK_EVENTS_TABLE";
