// @flow
import Joi from "joi";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";

const schema = Joi.object({
  firstName: Joi.string(),
  lastName: Joi.string(),
  email: Joi.string().email({ tlds: false }),
  skype: Joi.string(),
  companyName: Joi.string(),
  country: Joi.string(),
  accountType: Joi.string(),
  vertical: Joi.string(),
  status: Joi.string(),
  isSubscribed: Joi.boolean(),
  referrer: Joi.string().allow(""),
  visitUrl: Joi.string().allow(""),
  additionalData: Joi.string().allow(""),
  id: Joi.optional(),
}).messages({
  "string.empty": "This field is required",
  "string.email": "Email is not valid",
});

export default function (values: Object): ErrorsMapper {
  const { error: { details = {} } = {} } = schema.validate(values, { abortEarly: false });
  return Object.keys(details).reduce((acc: ErrorsMapper, key: string) => ({
    ...acc,
    [details[key].path.join(".")]: { message: details[key].message },
  }), {});
}
