// @flow
import React, { useMemo } from "react";
import Form from "./components/Form";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import DocumentTitle from "react-document-title";
import { useLocation, useNavigate } from 'react-router-dom';

const CpaOfferForm = () => {
  const store = useMemo(() => configureStore(), []);
  const location = useLocation();
  const history = useNavigate();

  return (
    <DocumentTitle title="CPA - CpaOffers">
      <Provider store={store}>
        <NotificationsContainer />
        <Form location={location} history={history} />
      </Provider>
    </DocumentTitle>
  );
};

export default CpaOfferForm;
