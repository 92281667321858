// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { SelectPaper } from "@fas/ui-core";
import type { Template } from "../../../reducers/templates";
import type { TemplateListBodyProps } from "./TemplatesList.types";

const TemplatesListBody: StatelessFunctionalComponent<TemplateListBodyProps> = ({
  templatesList,
  onClose,
  classes = {},
  isTemplateListLoading,
  onSetSearchString,
  onSetTemplateModalOpen,
  onSetCurrentTemplate,
  onSetActiveTemplate,
  onSetCategory,
  onChangeTemplateStatusSaga,
  onDelete,
}: TemplateListBodyProps) => (
  <SelectPaper
    data-testid="selectPaper"
    searchStringPlaceholder="Search template"
    classes={{ paper: classes.paper }}
    list={templatesList.map((template: Template) => ({ ...template }))}
    onSearchStringChange={onSetSearchString}
    onAddNew={() => {
      onSetTemplateModalOpen(true);
      onClose();
    }}
    addButtonText="New Template"
    // $FlowFixMe
    onSelect={(template: Template) => {
      onSetActiveTemplate(template);
      onClose();
    }}
    loading={isTemplateListLoading}
    onSetTab={onSetCategory}
    onChangeStatus={onChangeTemplateStatusSaga}
    onRemoveItem={onDelete}
    // $FlowFixMe
    onEdit={(template: Template) => {
      onSetCurrentTemplate(template);
      onSetTemplateModalOpen(true);
      onClose();
    }}
  />
);

export default TemplatesListBody;
