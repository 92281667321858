// @flow
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_TEMPLATES_CONFIG_SAGA } from "../../helpers/constants/templates";
import { getTemplatesConfig as fetchTemplatesConfig } from "../../services/templatesApi";
import { getReportName } from "../../selectors/templates";
import { setTemplatesConfig } from "../../actions/templates";
import type { ConfigGroup, ConfigAttribute, FilterType } from "../../reducers/templates";
import FactoryModels from "../../services/templateModels/templateModels";

const searchableFilters: Array<FilterType> = [
  "multiSelectManual",
  "multiSelectWithSuggest",
  "simpleMultiSelect",
  "simpleSelect",
];

export function* getTemplatesConfig(): Saga<void> {
  yield put(setLoading("getTemplatesConfig", true));
  try {
    const reportName: string = yield select(getReportName);

    const {
      groups,
      items: attributes,
    }: { groups: Array<ConfigGroup>, items: Array<ConfigAttribute> } = yield call(fetchTemplatesConfig, reportName);

    attributes.forEach(({
      id,
      name,
      label,
      dictionaryName,
      isDefault,
      filterType,
      valueType,
    }: ConfigAttribute) => {
      const model = FactoryModels[reportName].getModel(name);
      model.setConfig({
        id,
        label,
        dictionaryName,
        isDefault,
        filterType,
        valueType,
        searchable: searchableFilters.includes(filterType),
        reportName,
      });
    });

    yield put(setTemplatesConfig({ groups, attributes }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch templates config", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getTemplatesConfig", false));
  }
}

export function* watchGetTemplatesConfig(): Saga<void> {
  yield takeEvery(GET_TEMPLATES_CONFIG_SAGA, getTemplatesConfig);
}
