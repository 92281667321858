// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  id: Joi.number().allow(""),
  yesterdayConversionCount: Joi.number().allow(null).allow(""),
  isActive: Joi.number(),
  affiliate: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }).messages({ "string.empty": "Affiliate name is required and must be real Affiliate name" }),
  cpaCampaign: Joi.object({
    label: Joi.string(),
    value: Joi.string(),
  }).messages({ "string.empty": "CPA Offer is required and must be active CPA Offer" }),
  country: Joi.string().allow(null).messages({
    "string.empty": "Country is invalid",
  }),
  utmContent: Joi.string().allow(""),
  s3: Joi.string().allow(""),
  conversionType: Joi.string().allow(null).allow(""),
  payoutAmount: Joi.string().allow(null, ""),
  payoutAmountFirst: Joi.string().allow(null, ""),
  payoutAmountRepeats: Joi.string().allow(null, ""),
  shaveAmount: Joi.string().allow(null, ""),
  increasedCommissionAmount: Joi.string().allow(null, ""),
  increasedCommissionThreshold: Joi.string().allow(null, ""),
});

export default joiResolver(schema);
