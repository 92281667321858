// @flow
import React, { useCallback, useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AutoSizer, List } from "@enykeev/react-virtualized";
import makeStyles from "@mui/styles/makeStyles";
import { ColumnItemCard } from "@fas/ui-core";
import { Box } from "@mui/material";
import ReactDOM from "react-dom";
import type { FilterItem, RowRender } from "./types/HeadersList.types";

const useStyles = makeStyles((theme) => ({
  filtersColumn: {
    backgroundColor: theme.palette.background.mainBg,
    height: "100%",
    boxSizing: "border-box",
  },
}));

type Props<S> = {
  list: Array<{
    title: string | S,
    key: S,
  }>,
  onOrderChange: (result: Object) => void,
  onDeleteItem: (item: FilterItem<S>) => void,
};
const HeadersList = <S>(props: Props<S>) => {
  const classes = useStyles();
  const { list, onOrderChange } = props;
  const rowHeight = 70;

  const memorizedList: FilterItem<S>[] = useMemo<FilterItem<S>[]>(() => list, [list]);
  const handleOrderChange = useCallback((result) => {
    onOrderChange(result);
  }, [onOrderChange]);

  const rowRender = ({ index, key, style }: RowRender) => {
    const { onDeleteItem } = props;
    const item = memorizedList[index];

    return (
      <Box key={key} style={style}>
        <Draggable draggableId={index.toString()} index={index}>
          {(provided) => (
            <Box
              mb={1}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ColumnItemCard
                name={String(item.title)}
                item={item}
                disabled
                onDelete={onDeleteItem}
              />
            </Box>
          )}
        </Draggable>
      </Box>
    );
  };

  const cloneRender = (provided, snapshot, rubric) => (
    <Box mb={1} height={rowHeight}>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <ColumnItemCard
          // $FlowFixMe
          name={memorizedList[rubric.source.index].title}
          item={memorizedList[rubric.source.index].key}
          disabled
        />
      </div>
    </Box>
  );

  return (
    <Box px={2} pt={2} spacing={2} className={classes.filtersColumn}>
      <DragDropContext onDragEnd={handleOrderChange}>
        <Droppable
          droppableId="droppable"
          mode="virtual"
          renderClone={cloneRender}
        >
          {(droppableProvided) => (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={rowHeight}
                  rowCount={memorizedList.length}
                  rowRenderer={rowRender}
                  overscanRowCount={4}
                  id="choised-filters-scroll-view"
                  ref={(ref) => {
                    // react-virtualized has no way to get the list's ref that I can so
                    // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                    if (ref) {
                      // eslint-disable-next-line react/no-find-dom-node
                      const domNode = ReactDOM.findDOMNode(ref);
                      if (domNode instanceof HTMLElement) {
                        droppableProvided.innerRef(domNode);
                      }
                      ref.forceUpdateGrid();
                    }
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default HeadersList;
