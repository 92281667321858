import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import ContentTab from "./components/ContentTab";

type Props = {
  data: Array<any>,
  tab: string,
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 10,
  },
}));

const ContentTabs = ({
  data,
  tab,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((item) => (
        <ContentTab
          key={item.name}
          tab={tab}
          name={item.name}
        >
          {item.component}
        </ContentTab>
      ))}
    </div>
  );
};

export default ContentTabs;
