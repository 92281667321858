/* eslint-disable*/
function toNumber(num) {
  const r = /^\d+\.?\d?$/;
  return r.test(String(num)) ? parseFloat(num) : (num || null);
}

const sanitizeFormInt = (form) => {
  return {
    class: form.class ? form.class.toLowerCase() : "",
    conversionType: form.conversionType,
    country: form.country,
    currencyId: form.currency.id,
    department: form.department ? form.department.id : form.department,
    gender: form.gender,
    globalCap: toNumber(form.globalCap),
    globalCapLogic: form.globalCap && form.globalCapLogic ? form.globalCapLogic.id : "",
    group: form.group,
    id: form.id,
    isActive: form.isActive ? 1 : 0,
    forOfferwall: form.forOfferwall ? 1 : 0,
    isAvailableForAllGroups: form.isAvailableForAllGroups === "Yes" ? 1 : 0,
    isExt: form.isExt ? form.isExt.isExt : form.isExt,
    isPublic: form.isPublic === "Public" ? 1 : 0,
    name: form.name,
    ncrAmount: toNumber(form.ncrAmount),
    ncrUnit: form.ncrUnit === "%" ? form.ncrUnit : "$",
    niche: form.niche,
    offerOS: form.offerOS && form.class.toLowerCase() === "application" ? form.offerOS.toLowerCase() : undefined,
    offerType: form.offerType ? form.offerType.id : form.offerType,
    offer_provider: form.offer_provider,
    payoutAmount: form.conversionType !== "revshara" ? toNumber(form.payoutAmount) : null,
    payoutAmountFirst: toNumber(form.payoutAmountFirst),
    payoutAmountRepeats: toNumber(form.payoutAmountRepeats),
    platform: form.platform,
    shaveAmount: toNumber(form.shaveAmount),
    // eslint-disable-next-line no-nested-ternary
    siteId: form.offerName ? form.offerName.id
      : form.offerName,
    vertical: form.vertical,
    globalCapDistributionMode: sanitizeGlobalCapDistributionMode(
      form.globalCap,
      form.globalCapDistributionMode
    ),
  };
};

const sanitizeFormExt = (form) => {
  return {
    account: form.account,
    conversionType: form.conversionType,
    country: form.country,
    currencyId: form.currency.id,
    dataProvider: form.dataProvider ? form.dataProvider : {},
    dataProviderId: form.dataProviderId ? form.dataProviderId.id : "",
    department: form.department ? form.department.id : form.department,
    extNiche: form.extNiche,
    extOfferGroup: form.extOfferGroup,
    gender: form.gender,
    globalCap: toNumber(form.globalCap),
    globalCapLogic: form.globalCap && form.globalCapLogic ? form.globalCapLogic.id : "",
    id: form.id,
    isActive: form.isActive ? 1 : 0,
    forOfferwall: form.forOfferwall ? 1 : 0,
    isAvailableForAllGroups: form.isAvailableForAllGroups === "Yes" ? 1 : 0,
    isExt: form.isExt ? form.isExt.isExt : form.isExt,
    isPublic: form.isPublic === "Public" ? 1 : 0,
    isTest: form.isTest ? 1 : 0,
    location: form.location,
    name: form.name,
    ncrAmount: toNumber(form.ncrAmount),
    ncrUnit: form.ncrUnit === "%" ? form.ncrUnit : "$",
    offerType: form.offerType ? form.offerType.id : form.offerType,
    payment_model: form.payment_model,
    payoutAmount: toNumber(form.payoutAmount),
    platform: form.platform,
    shaveAmount: toNumber(form.shaveAmount),
    timeout: toNumber(form.timeout),
    vertical: form.vertical,
    description: form.description,
    restriction: form.restriction,
    blackList: form.blackList,
    globalCapDistributionMode: sanitizeGlobalCapDistributionMode(
      form.globalCap,
      form.globalCapDistributionMode
    ),
    linkValidationType: form.linkValidationType,
    adverIDAttr: form.adverIDAttr,
    adverIDValue: form.adverIDValue,
    stream: form.stream,
    nextPartnerCommission: toNumber(form.nextPartnerCommission),
    campaignID: toNumber(form.campaignID),
    partnerOfferName: form.partnerOfferName || null,
    frequencyCap: form.frequencyCap,
  };
};

const getOfferOptions = (fieldName, value, offer) => {
  // TODO: need to think how refactor this code
  let dict = "site";
  let purpose = "groups";
  let offerProvider = "phoenix";
  let url = `dict=${dict}&purpose=${purpose}&offer_provider=${offerProvider}`;

  if (fieldName === "class") {
    url = `dict=${value.toLowerCase()}&purpose=${purpose}&platform=${dict.toLowerCase()}`;
  }

  if (fieldName === "group" && offer.offerOS !== null) {
    dict = offer.class && offer.class.toLowerCase() === "application"
    ? "application" : "site";
    purpose = "offers";
    offerProvider = getOfferProvider(value);
    url = `dict=${dict}&purpose=${purpose}&offer_provider=${offerProvider}&group=${value}&platform=${offer.offerOS.toLowerCase()}`;
  }
  if (fieldName === "group" && offer.offerOS === null) {
    dict = offer.class && offer.class.toLowerCase() === "application"
    ? "application" : "site";
    purpose = "offers";
    offerProvider = getOfferProvider(value);
    url = `dict=${dict}&purpose=${purpose}&offer_provider=${offerProvider}&group=${value}`;
  }

  if (fieldName === "offerOS") {
    dict = "application";
    url = `dict=${dict}&purpose=${purpose}&platform=${value.toLowerCase()}`;
  }

  return { url };
};

export const getOfferProvider = (group) => {
  switch (group) {
    case "STRV": return "siteReview";
    case "phnxseo": return "phoenixSeo";
    default: return "phoenix";
  }
};

const filterOffers = (params, sorting) => {
  let newParams = { ...params };
  let sanitizedFilters = "";
  let sort = "";

  if (newParams.isExt) {
    newParams.isExt = newParams.isExt === "Int" ? 0 : 1;
  }

  if (newParams.name) {
    newParams.name = encodeURIComponent(params.name);
  }

  sanitizedFilters = Object.keys(newParams).filter((item) => newParams[item] !== "").map(item => `${item}=${newParams[item]}`).join("&");

  if (sorting.field && sorting.direction) {
    sort = `&currentSort=${sorting.field}&direction=${sorting.direction}`;
  }

  return { sanitizedFilters, sort };
};

const sanitizeGlobalCapDistributionMode = (globalCap, globalCapDistributionMode) => {
  if (globalCap) {
    return globalCapDistributionMode ? globalCapDistributionMode.id : null;
  }
  return null;
};

export {
  sanitizeFormInt,
  sanitizeFormExt,
  getOfferOptions,
  filterOffers,
};
