// @flow
import {
  call, put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import FileSaver from "file-saver";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { DownloadInvoiceSaga } from "../../actions/payoutHistory/actions";
import { DOWNLOAD_INVOICE_SAGA } from "../../helpers/constants/payoutHistory";
import { downloadInvoiceApi } from "../../services/payments";
import { DOWNLOAD_INVOICE_LOADING } from "../../helpers/constants/payments";

export function* downloadInvoice(action: DownloadInvoiceSaga): Saga<void> {
  try {
    yield put(setLoading(DOWNLOAD_INVOICE_LOADING, true));

    const response = yield call(downloadInvoiceApi, action.ids);

    const disposition = response.headers["content-disposition"] || response.headers["Content-Disposition"] || "";
    const filenameMatch = disposition.match(/filename=(.+)/);
    const filename = filenameMatch && filenameMatch[1];

    FileSaver.saveAs(new Blob([response.data]), filename || "invoice.zip");
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Invoice download failed", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(DOWNLOAD_INVOICE_LOADING, false));
  }
}

export default function* watchDownloadInvoice(): Saga<void> {
  yield takeEvery(DOWNLOAD_INVOICE_SAGA, (downloadInvoice: Function));
}
