// @flow
import {
  call,
  takeEvery,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { SAVE_TEMPLATE_SAGA } from "../../helpers/constants/subrates";
import { saveSubrateTemplateApi } from "../../services/subratesApi";
import type { SaveTemplateSagaType } from "../../actions/subrates";

export function* saveSubrateTemplate(action: SaveTemplateSagaType): Saga<void> {
  try {
    const response = yield call(saveSubrateTemplateApi, action.payload);
    if (response.status === 200) {
      yield put(addNotification({ message: "Template saved", severity: "success" }));
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save template", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveSubrateTemplate(): Saga<void> {
  // todo add event type here
  yield takeEvery(SAVE_TEMPLATE_SAGA, (saveSubrateTemplate: Function));
}
