// @flow
import React, { useRef, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Tooltip,
  Switch,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Box,
  Alert,
  Autocomplete
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { userService } from "../../../../../services/user";
import { getFormErrors } from "./checkForm";
import { userHaveSomeMarketingGroup } from "../Form";
import type { Errors } from "./checkForm";
import type { CpaOfferLists } from "../../../../../reducers/cpaoffer/types";

type Props = {
  row: any,
  lists: CpaOfferLists,
  urlValidationStatus: string,
  urlValidationStatusMessage: string,
  checkValidationStatus: () => mixed,
  onChange: any,
  onPartnerChange: (string, string) => mixed,
  changeLinkValidationType: (string) => mixed,
  onLinkTypeChange: (string, string) => mixed,
  isClone: boolean,
  onChangeOfferType: any,
  offerType: string,
  errors: Errors,
};

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.background.mainBg,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  multilineInput: {
    background: theme.palette.background.mainBg,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// eslint-disable-next-line complexity
const GeneralOptions = (props: Props) => {
  const classes = useStyles();
  const {
    row,
    lists,
    urlValidationStatus,
    urlValidationStatusMessage,
    checkValidationStatus,
    offerType,
    errors,
    isClone,
  } = props;
  const { isExt } = row.isExt;
  const isForOfferwallVisible = (["adsempiredirect"].includes(row.department?.id))
    || (["resale"].includes(row.department?.id) && isExt);
  const canActivateAll = userService.can(["cpa.offers.activateAll"]);
  const canActivateIt = userService.can(["/cpa/offers/activate"]);
  const isManager = row.createdById === userService.getId();
  const isAllowedByMarketingGroup = userHaveSomeMarketingGroup(row.marketingGroups);
  const isAllowedActivate = ((canActivateIt) && (isAllowedByMarketingGroup || isManager)) || canActivateAll;

  const descriptionRef = useRef(null);
  const restrictionRef = useRef(null);
  const blackListRef = useRef(null);
  const getSelectedName = () => {
    const finRes = validationRuleLinkType.find((el) => el.value === row.linkValidationType) || {};
    return finRes.name || "";
  };
  const urlValidationsMessagesMap = {
    error: {
      severity: "error",
    },
    valid: {
      severity: "success",
    },
  };

  const dataProviderId = row.dataProviderId ? row.dataProviderId.id : "";

  const isPartnerManualCheck = (dataProviderId) => {
    if (!dataProviderId) return;
    const { onlyManualCheck } = partners.find((partner) => partner.id === dataProviderId) || {};
    return !!onlyManualCheck;
  };

  const isPartnerInValidGroup = (dataProviderId) => {
    if (!dataProviderId) return;
    const { dataProviderGroupId } = partners.find((partner) => partner.id === dataProviderId) || {};
    return [35, 37].includes(dataProviderGroupId);
  };

  const showUrlValidationMessage = () => {
    if (
        !dataProviderId ||
        !row.location ||
        row.offerType.id !== "redirect" ||
        !isPartnerInValidGroup(dataProviderId)
    ) {
      return;
    }

    if (row.id && row.isValid) {
      return (
          <Alert severity={urlValidationsMessagesMap.valid.severity}>
            {urlValidationStatusMessage}
          </Alert>
      );
    }

    if (urlValidationStatus) {
      return (
          <Alert severity={urlValidationsMessagesMap[urlValidationStatus].severity}>
            {urlValidationStatusMessage}
          </Alert>
      );
    }

    if (row.hasOwnProperty("isValid") && !row.isValid && !isPartnerManualCheck(dataProviderId)) {
      return (
          <Alert severity={urlValidationsMessagesMap.error.severity}>
            {urlValidationStatusMessage}
          </Alert>
      );
    }

    return null;
  };
  const {
    department,
    type,
    isPublic,
    isAvailableForAllGroups,
    streams = [],
    conversionTypes,
    conversionTypesExtForm,
    globalCapLogic,
    linkTypeInt,
    linkTypeExt,
    partners,
    validationRuleLinkType,
    manager,
  } = lists;

  const formErrors = getFormErrors(errors);

  const changeInput = (fieldName, value) => {
    let timeout = null;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => props.onChange(fieldName, value), 0);
  };
  const handleChangeGlobalCap = (e) => {
    if (e.target.value === "") {
      if (!row.globalCapDistributionMode) {
        props.onChange("globalCapDistributionMode", null);
      }
    }
    if (e.target.value) {
      if (!row.globalCapLogic) {
        props.onChange("globalCapLogic", { id: "return", label: "Return to smartlink" });
      }
      if (!row.globalCapDistributionMode) {
        props.onChange("globalCapDistributionMode", { id: "daily", label: "Daily" });
      }
    }
    changeInput("globalCap", e.target.value);
  };

  const tooltipInput = (text = "Some text") => ({
    classes: { adornedStart: classes.input },
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title={text}>
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
      </>
    ),
  });

  const tooltipSelect = (params) => ({
    classes: { adornedStart: classes.input },
    ...params.InputProps,
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title="Some text">
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
      </>
    ),
  });

  const defaultErrorStyle = {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    background: "white"
  };

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.value = row.description;
    }
    if (restrictionRef.current) {
      restrictionRef.current.value = row.restriction;
    }
    if (blackListRef.current) {
      blackListRef.current.value = row.blackList;
    }
  });

  useEffect(() => { // !offer.manualCheck && !dp.manualCheck
    if (
        !row.location ||
        !row.dataProviderId.id ||
        row.offerType.id !== "redirect" ||
        !isPartnerInValidGroup(dataProviderId) ||
        row.linkValidationType === "manual" ||
        isPartnerManualCheck(dataProviderId)
    ) return;

    checkValidationStatus();
  }, [row.location, dataProviderId, row.offerType.id, row.linkValidationType]);

  const globalCapMods = [
    { id: "daily", label: "Daily" },
    { id: "hourly", label: "Hourly" },
  ];

  const isFieldVisible = offerType === "Ext" && ["click", "1000clicks", "webPushSubscription"].includes(row.conversionType);

  const isFrequencyCapVisible = ["click", "1000clicks", "conversion"].includes(row.conversionType);
  useEffect(() => {
    if (!["click", "1000clicks", "conversion"].includes(row.conversionType)) {
      props.onChange("frequencyCap", 0);
    }
  }, [row.conversionType]);

  return (
    <Box mb={3} width={1}>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.department}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={department}
          disabled={!!row.id}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(e, value) => props.onChange("department", value)}
          value={row.department}
          data-testid="department"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Department"
              variant="outlined"
              margin="normal"
              InputProps={tooltipSelect(params)}
              className={classes.input}
              error={!!formErrors.department}
            />
          )}
        />
        <FormHelperText>{formErrors.department}</FormHelperText>
      </FormControl>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.isExt}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={type}
          disabled={!!row.id}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(o, v) => o.isExt === v.isExt}
          onChange={(e, value) => props.onChangeOfferType("isExt", value)}
          value={row.isExt}
          data-testid="isExt"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Offer type"
              variant="outlined"
              margin="normal"
              InputProps={tooltipSelect(params)}
              className={classes.input}
              error={!!formErrors.isExt}
            />
          )}
        />
        <FormHelperText>{formErrors.isExt}</FormHelperText>
      </FormControl>
      {offerType === "Ext" && (
        <FormControl
          fullWidth
          className={classes.formControl}
          error={!!formErrors.partner}
          variant="standard">
          <Autocomplete
            fullWidth
            disableClearable
            disabled={!!row.id}
            options={partners}
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(o, v) => o.name === v.name}
            onChange={(e, value) => props.onPartnerChange("dataProviderId", value)}
            value={row.dataProviderId}
            data-testid="dataProviderId"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Partner"
                variant="outlined"
                margin="normal"
                InputProps={tooltipSelect(params)}
                error={!!formErrors.dataProviderId}
                helperText={formErrors.dataProviderId}
              />
            )}
          />
          <FormHelperText>{formErrors.partner}</FormHelperText>
        </FormControl>
      )}
      <TextField
        fullWidth
        label="Cpa offer name"
        variant="outlined"
        margin="normal"
        onChange={(e) => changeInput("name", e.target.value)}
        InputProps={tooltipInput()}
        defaultValue={row.name}
        error={!!formErrors.name}
        helperText={formErrors.name}
        data-testid="name"
      />
      {offerType === "Ext" && (
        <TextField
          fullWidth
          label="Partner offer name"
          variant="outlined"
          margin="normal"
          onChange={(e) => changeInput("partnerOfferName", e.target.value)}
          InputProps={tooltipInput("")}
          defaultValue={row.partnerOfferName}
          error={!!formErrors.partnerOfferName}
          helperText={formErrors.partnerOfferName}
          data-testid="partnerOfferName"
        />
      )}
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.isPublic}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={isPublic}
          getOptionLabel={(option) => option}
          onChange={(e, value) => props.onChange("isPublic", value)}
          value={row.isPublic}
          data-testid="isPublic"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Is public"
              variant="outlined"
              margin="normal"
              className={classes.input}
              error={!!formErrors.isPublic}
            />
          )}
        />
        <FormHelperText>{formErrors.isPublic}</FormHelperText>
      </FormControl>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.isAvailableForAllGroups}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={isAvailableForAllGroups}
          getOptionLabel={(option) => option}
          onChange={(e, value) => props.onChange("isAvailableForAllGroups", value)}
          value={row.isAvailableForAllGroups}
          data-testid="isAvailableForAllGroups"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Is available for other marketer groups"
              variant="outlined"
              margin="normal"
              className={classes.input}
              error={!!formErrors.isAvailableForAllGroups}
            />
          )}
        />
        <FormHelperText>{formErrors.isAvailableForAllGroups}</FormHelperText>
      </FormControl>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.conversionType}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={isExt === 1 ? conversionTypesExtForm : conversionTypes}
          disabled={isClone ? false : !!row.id}
          getOptionLabel={(option) => option}
          onChange={(e, value) => {
            props.onChange("stream", "");
            props.onChange("campaignID", "");
            props.onChange("conversionType", value);
          }}
          value={row.conversionType || ""}
          data-testid="conversionType"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Conversion type"
              variant="outlined"
              margin="normal"
              className={classes.input}
              error={!!formErrors.conversionType}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <FormHelperText>{formErrors.conversionType}</FormHelperText>
      </FormControl>
      {isFieldVisible && (
        <>
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.stream}
            variant="standard">
            <Autocomplete
              fullWidth
              disableClearable
              options={streams}
              getOptionLabel={(option) => option}
              onChange={(e, value) => props.onChange("stream", value)}
              value={row.stream}
              data-testid="stream"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Stream"
                  variant="outlined"
                  margin="normal"
                  className={classes.input}
                  error={!!formErrors.stream}
                  helperText={formErrors.stream && formErrors.stream}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  FormHelperTextProps={{
                    style: defaultErrorStyle
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </FormControl>
          <TextField
            disabled={!!row.id && !isClone}
            fullWidth
            label="Campaign ID"
            variant="outlined"
            margin="normal"
            onChange={(e) => changeInput("campaignID", e.target.value)}
            InputProps={tooltipInput()}
            value={row.campaignID}
            error={!!formErrors.campaignID}
            helperText={formErrors.campaignID}
            data-testid="campaignId"
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 }
            }}
          />
        </>
      )}
      {isFrequencyCapVisible && (
        <FormControlLabel
          sx={{
            width: "100%",
          }}
          label="Frequency cap"
          control={(
            <Switch
              checked={Boolean(row.frequencyCap)}
              onChange={(e, value) => props.onChange("frequencyCap", Number(value))}
              color="secondary"
              data-testid="frequencyCap"
            />
          )}
        />
      )}
      <TextField
        fullWidth
        label="Global cap"
        variant="outlined"
        margin="normal"
        onChange={handleChangeGlobalCap}
        InputProps={tooltipInput()}
        value={row.globalCap || ""}
        error={!!formErrors.globalCap}
        helperText={formErrors.globalCap}
        data-testid="globalCap"
      />
      {(+row.globalCap || row.globalCap === 0) && (
        <FormControl fullWidth error={formErrors.globalCapDistributionMode} variant="standard">
          <Autocomplete
            fullWidth
            disableClearable
            options={globalCapMods}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(e, value) => props.onChange("globalCapDistributionMode", value)}
            value={row.globalCapDistributionMode}
            data-testid="globalCapDistributionMode"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Global cap distribution mode"
                variant="outlined"
                margin="normal"
                className={classes.input}
                error={!!formErrors.globalCapDistributionMode}
              />
            )}
          />
          <FormHelperText>{formErrors.globalCapDistributionMode}</FormHelperText>
        </FormControl>
      )}
      {isExt === 1 && (
        <>
          <TextField
            className={classes.multilineInput}
            fullWidth
            multiline
            label="Offer description"
            placeholder="Type offer description"
            variant="outlined"
            rows={4}
            margin="normal"
            onBlur={(e) => changeInput("description", e.target.value)}
            inputRef={descriptionRef}
            error={!!formErrors.description}
            helperText={formErrors.description}
            data-testid="description"
          />
          <TextField
            className={classes.multilineInput}
            fullWidth
            multiline
            label="Restriction"
            type="Type restriction"
            variant="outlined"
            rows={4}
            margin="normal"
            onBlur={(e) => changeInput("restriction", e.target.value)}
            inputRef={restrictionRef}
            error={!!formErrors.restriction}
            helperText={formErrors.restriction}
            data-testid="restriction"
          />
          <TextField
            className={classes.multilineInput}
            fullWidth
            multiline
            label="Black list"
            placeholder="Type black list"
            variant="outlined"
            rows={4}
            margin="normal"
            onBlur={(e) => changeInput("blackList", e.target.value)}
            inputRef={blackListRef}
            error={!!formErrors.blackList}
            helperText={formErrors.blackList}
            data-testid="blackList"
          />
        </>
      )}
      {row.globalCap && (
        <FormControl
          fullWidth
          className={classes.formControl}
          error={!!formErrors.globalCapLogic}
          variant="standard">
          <Autocomplete
            fullWidth
            disableClearable
            disabled={!row.globalCap}
            options={globalCapLogic}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(e, value) => props.onChange("globalCapLogic", value)}
            value={row.globalCapLogic}
            data-testid="globalCapLogic"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Overcap flow"
                variant="outlined"
                margin="normal"
                className={classes.input}
                error={!!formErrors.globalCapLogic}
              />
            )}
          />
          <FormHelperText>{formErrors.globalCapLogic}</FormHelperText>
        </FormControl>
      )}
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.offerType}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          disabled={!!row.id && !isClone}
          options={offerType === "Int" ? linkTypeInt : linkTypeExt}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(e, value) => props.onLinkTypeChange("offerType", value)}
          value={row.offerType}
          data-testid="offerType"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Link type"
              variant="outlined"
              margin="normal"
              className={classes.input}
              error={!!formErrors.offerType}
            />
          )}
        />
        <FormHelperText>{formErrors.offerType}</FormHelperText>
      </FormControl>
      {
        offerType === "Ext" ? (
          <>
            <TextField
              fullWidth
              label="Link"
              variant="outlined"
              margin="normal"
              onChange={(e) => changeInput("location", e.target.value)}
              InputProps={tooltipInput()}
              defaultValue={row.location}
              error={!!formErrors.location}
              helperText={formErrors.location}
              data-testid="location"
            />
            {(
                showUrlValidationMessage()
            )}
            {(
                (isPartnerManualCheck(row.dataProviderId.id) && !row.isValid)
                || (!isPartnerManualCheck(row.dataProviderId.id) && urlValidationStatus === "error" && !row.isValid)
                || (row.hasOwnProperty("isValid") && !row.isValid && urlValidationStatus !== "valid")
                || (!row.id && isPartnerManualCheck(row.dataProviderId.id))
            ) && row.offerType.id === "redirect" && isPartnerInValidGroup(dataProviderId)
            && (
              <FormControl
                fullWidth
                className={classes.formControl}
                error={!!formErrors.offerType}
                variant="standard">
                <Autocomplete
                  fullWidth
                  disableClearable
                  options={validationRuleLinkType}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(o, v) => o.value === v.value}
                  onChange={(e, options) => props.changeLinkValidationType(options && options.value || "")}
                  value={{ value: row.linkValidationType, name: getSelectedName() }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  disabled={isPartnerManualCheck(row.dataProviderId.id)}
                  data-testid="linkValidationType"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Link validation type"
                      variant="outlined"
                      margin="normal"
                      className={classes.input}
                      error={!!formErrors.offerType}
                    />
                  )}
                />
                <FormHelperText>{formErrors.offerType}</FormHelperText>
              </FormControl>
            )}
            {(urlValidationStatus !== "valid" && !row.isValid && !isPartnerManualCheck(row.dataProviderId.id) && row.linkValidationType !== "manual") && row.offerType.id === "redirect"
              && (
                <>
                  {row.linkValidationType === "attribute"
                    && (
                      <TextField
                        fullWidth
                        label="Advertiser id attribute"
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => changeInput("adverIDAttr", e.target.value)}
                        InputProps={tooltipInput("Advertiser id attribute is a parameter that contains advertiser id of the partner")}
                        defaultValue={row.adverIDAttr}
                        error={!!formErrors.adverIDAttr}
                        helperText={formErrors.adverIDAttr}
                        data-testid="adverIDAttr"
                      />
                    )}
                  {(row.linkValidationType === "attribute" || row.linkValidationType === "route")
                    && (
                      <TextField
                        fullWidth
                        label="Advertiser id value"
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => changeInput("adverIDValue", e.target.value)}
                        InputProps={tooltipInput("Advertiser id value is the value of advertiser id assigned to us by our partner")}
                        defaultValue={row.adverIDValue}
                        error={!!formErrors.adverIDValue}
                        helperText={formErrors.adverIDValue}
                        data-testid="adverIDValue"
                      />
                    )}
                </>
              )}
            {
              row.id ? (
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  error={!!formErrors.manager}
                  variant="standard">
                  <Autocomplete
                    fullWidth
                    disableClearable
                    disabled={!!row.id}
                    options={manager}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => props.onChange("manager", value)}
                    value={row.manager}
                    data-testid="manager"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Manager"
                        variant="outlined"
                        margin="normal"
                        InputProps={tooltipSelect(params)}
                        error={!!formErrors.manager}
                        helperText={formErrors.manager}
                      />
                    )}
                  />
                  <FormHelperText>{formErrors.manager}</FormHelperText>
                </FormControl>
              ) : null
            }
            <TextField
              fullWidth
              label="Account"
              variant="outlined"
              margin="normal"
              onChange={(e) => changeInput("account", e.target.value)}
              InputProps={tooltipInput()}
              defaultValue={row.account}
              error={!!formErrors.account}
              helperText={formErrors.account}
              data-testid="account"
            />
            <TextField
              fullWidth
              label="Timeout"
              variant="outlined"
              margin="normal"
              onChange={(e) => changeInput("timeout", e.target.value)}
              InputProps={tooltipInput()}
              defaultValue={row.timeout}
              error={!!formErrors.timeout}
              helperText={formErrors.timeout}
              data-testid="timeout"
            />
            {
              row.readyForGdpr !== null && (
                <FormControlLabel
                  style={{ width: "100%" }}
                  control={(
                    <Switch
                      checked={!!row.readyForGdpr}
                      color="secondary"
                      disabled
                      data-testid="readyForGdpr"
                    />
                  )}
                  label="Is ready for gdpr"
                />
              )
            }
            {
              userService.can(["cpa.offers.isTest"]) ? (
                <FormControlLabel
                  style={{ width: "100%" }}
                  control={(
                    <Switch
                      checked={!!row.isTest}
                      onChange={(e, value) => props.onChange("isTest", value)}
                      color="secondary"
                      data-testid="isTest"
                    />
                  )}
                  label="Is test"
                />
              ) : null
            }
          </>
        ) : null
      }
      {
        isAllowedActivate ? (
          <FormControlLabel
            style={{ width: "100%" }}
            control={(
              <Switch
                checked={!row.id ? true : !!row.isActive}
                onChange={(e, value) => props.onChange("isActive", value)}
                color="secondary"
                data-testid="isActive"
                disabled={isClone ? true : !row.id}
              />
            )}
            label="Is active"
          />
        ) : null
      }
      {
        isForOfferwallVisible ? (
          <FormControlLabel
            style={{ width: "100%" }}
            label="For offerwall"
            control={(
              <Switch
                disabled={!userService.can(["cpa.offers.setForOfferwall"])}
                checked={!!row.forOfferwall}
                onChange={(e, value) => props.onChange("forOfferwall", value)}
                color="secondary"
                data-testid="forOfferwall"
              />
            )}
          />
        ) : null
      }
    </Box>
  );
};

export default GeneralOptions;
