// @flow
import type { RecordFactory, RecordInstance } from "immutable";
import { List, Map, Record, fromJS } from "immutable";
import {
  CHANGE_FORM_FIELD_LINK_TYPE,
  CHANGE_FORM_FIELD_OFFER,
  CHANGE_FORM_FIELD_OFFER_TYPE,
  CHANGE_FORM_FIELD_PARTNER,
  CHANGE_FORM_LINK_VALIDATION_TYPE,
  GET_OFFER_FORM,
  OFFER_ERROR,
  OFFER_IS_FETCHING,
  SET_FETCH_DATA_LISTS,
  SET_OFFER_LISTS_FIELD,
  SET_URL_STATUS_MESSAGE,
  SET_OFFER_FOUND,
  SET_IS_CLONE,
} from "../../helpers/constants/cpaoffer";
import type { Actions as CpaOfferActions } from "../../actions/cpaoffer";
import type { CpaOfferLists, RowForm } from "./types";

export type SortType = {
  field: string,
  direction: "asc" | "desc",
};

export type FilterType = {
  field: string,
  filter: string | number,
};

export type IsActiveType = {
  id: string,
  isActive: number,
};

export type SelectedRowsType = Array<number | string>;

const mapGlobalCapDistributionMode = (value) => {
  switch (value) {
    case "daily": {
      return { id: "daily", label: "Daily" };
    }
    case "hourly": {
      return { id: "hourly", label: "Hourly" };
    }
    default: {
      return null;
    }
  }
};

export const departments = {
  resale: { id: "resale", label: "Resale" },
  rndmediabuy: { id: "rndmediabuy", label: "RnD Media Buy" },
  adromeda: { id: "adromeda", label: "Adromeda" },
  adsempiredirect: { id: "adsempiredirect", label: "AdsEmpire Direct" },
  adsempiresmartlink: { id: "adsempiresmartlink", label: "AdsEmpire Smartlink" },
};

export type DefaultStateType = {
  lists: CpaOfferLists,
  row: RecordInstance<RowForm>,
  errors: List<any>,
  isFetching: boolean,
  isLists: boolean,
  urlValidationStatus: string | null,
  urlValidationStatusMessage: string,
  isValid: boolean,
  isOfferFound: boolean,
  isClone: boolean,
}

const defaultState: DefaultStateType = ({
  lists: Map({
    country: List([]),
    currency: List([]),
    platform: List([]),
    department: List([]),
    conversionTypesIntForm: List([]),
    conversionTypesExtForm: List([]),
    type: List([
      {
        label: "Int",
        isExt: 0,
      },
      {
        label: "Ext",
        isExt: 1,
      },
    ]),
    vertical: List([]),
    isPublic: List([
      "Public",
      "Private",
    ]),
    linkTypeInt: List([
      {
        id: "redirect",
        label: "Redirect",
      },
      {
        id: "api",
        label: "API",
      },
    ]),
    linkTypeExt: List([
      {
        id: "redirect",
        label: "Redirect",
      },
      {
        id: "api",
        label: "API",
      },
      {
        id: "iframe",
        label: "IFrame",
      },
      {
        id: "richmedia",
        label: "RichMedia",
      },
      {
        id: "rtb",
        label: "RTB",
      },
      {
        id: "rtb_email",
        label: "RTB_email",
      },
      {
        id: "rtb_bo",
        label: "RTB_bo",
      },
      {
        id: "rtb_pop",
        label: "RTB_pop",
      },
      {
        id: "rtb_push",
        label: "RTB_push",
      },
      {
        id: "rtb_so",
        label: "RTB_so",
      },
      {
        id: "rtb_ipp",
        label: "RTB_ipp",
      }
    ]),
    linkType: List([
      {
        id: "redirect",
        label: "Redirect",
      },
      {
        id: "api",
        label: "API",
      },
      {
        id: "iframe",
        label: "IFrame",
      },
      {
        id: "richmedia",
        label: "RichMedia",
      },
      {
        id: "rtb",
        label: "RTB",
      },
      {
        id: "rtb_email",
        label: "RTB_email",
      },
      {
        id: "rtb_bo",
        label: "RTB_bo",
      },
      {
        id: "rtb_pop",
        label: "RTB_pop",
      },
      {
        id: "rtb_push",
        label: "RTB_push",
      },
      {
        id: "rtb_so",
        label: "RTB_so",
      },
      {
        id: "rtb_ipp",
        label: "RTB_ipp",
      }
    ]),
    isAvailableForAllGroups: List([]),
    globalCapLogic: List([
      {
        id: "return",
        label: "Return to smartlink",
      },
      {
        id: "exclude",
        label: "Exclude from roulette",
      },
    ]),
    offerClass: List(["Site", "Application"]),
    offerName: List([]),
    niche: List([]),
    genders: List([]),
    ncrType: List(["USD", "%"]),
    partners: List([]),
    offerProviders: List([]),
    offerOS: List(["iOS", "Android"]),
    offerGroup: List([]),
    paymentModels: List([]),
    manager: List([]),
    validationRuleLinkType: List([
      {
        name: "ID in attribute",
        value: "attribute",
      },
      {
        name: "ID in route",
        value: "route",
      },
      {
        name: "Offer manual check",
        value: "manual",
      },
    ]),
    streams: List([]),
  }),
  row: Map({
    stream: "",
    campaignID: null,
    department: Map({
      id: "",
      label: "",
    }),
    vertical: "Dating",
    gender: "all",
    currency: Map({
      id: 1,
      label: "USD",
    }),
    ncrUnit: "USD",
    isPublic: "Public",
    isAvailableForAllGroups: "Yes",
    globalCapLogic: Map({
      id: "return",
      label: "Return to smartlink",
    }),
    offerType: Map({
      id: "redirect",
      label: "Redirect",
    }),
    paymentModel: "CPA",
    class: "",
    conversionType: "",
    country: [],
    globalCap: "",
    globalCapDistributionMode: { id: "daily", label: "Daily" },
    description: "",
    restriction: "",
    blackList: "",
    group: "",
    isActive: 1,
    frequencyCap: 0,
    isExt: Map({
      label: "Int",
      isExt: 0,
    }),
    name: "",
    niche: "",
    offerOS: null,
    offer_provider: null,
    platform: [],
    offerName: Map({
      domain: "",
      siteId: "",
    }),
    account: "",
    currencyId: 1,
    dataProviderId: Map({}),
    extNiche: "",
    extOfferGroup: "",
    readyForGdpr: null,
    isTest: 0,
    location: "",
    payment_model: "CPA",
    timeout: "",
    manager: "",
    linkValidationType: "",
    adverIDAttr: "",
    adverIDValue: "",
    dataProvider: Map({
      onlyManualCheck: false,
    }),
    partnerOfferName: "",
    partnerCommission: null,
    nextPartnerCommission: null,
  }),
  errors: List([]),
  isFetching: true,
  isLists: false,
  urlValidationStatus: null,
  urlValidationStatusMessage: "",
  isValid: false,
  isOfferFound: false,
  isClone: false,
});

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);
export type State = RecordInstance<DefaultStateType>;
export type Action = CpaOfferActions;

export const initState = (): State => makeState({});

export const initialState: State = makeState({});

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SET_FETCH_DATA_LISTS: {
      return state
        .setIn(["lists", "country"], action.value.list.countries.sort())
        .setIn(["lists", "currency"], action.value.list.currencies.map((item) => ({ label: item.currency, id: item.id })))
        .setIn(["lists", "platform"], action.value.list.platforms)
        .setIn(["lists", "vertical"], action.value.list.vertical)
        .setIn(["lists", "isAvailableForAllGroups"], action.value.list.biChoice)
        .setIn(["lists", "niche"], action.value.list.niches)
        .setIn(["lists", "genders"], action.value.list.genders)
        .setIn(["lists", "conversionTypesIntForm"], action.value.list.conversionTypesIntForm)
        .setIn(["lists", "conversionTypesExtForm"], action.value.list.conversionTypesExtForm)
        .setIn(["lists", "conversionTypes"], state.getIn(["row", "isExt", "label"]) === "Int"
          ? action.value.list.conversionTypesIntForm : action.value.list.conversionTypesExtForm)
        .setIn(["lists", "partners"], action.value.list.partners)
        .setIn(["lists", "paymentModels"], action.value.list.paymentModels)
        .setIn(["lists", "streams"], action.value.list.streams)
        .setIn(["lists", "department"], action.value.list.departments.map((item) => departments[item]))
        .set("isLists", true);
    }
    case CHANGE_FORM_FIELD_PARTNER: {
      const selectedPartner = action.value || {};
      return state.setIn(["row", action.fieldName], selectedPartner)
        .setIn(["row", "linkValidationType"], selectedPartner.onlyManualCheck ? "manual" : "")
        .setIn(["urlValidationStatus"], null)
        .setIn(["urlValidationStatusMessage"], "");
    }
    case CHANGE_FORM_FIELD_LINK_TYPE: {
      const {
        dataProvider = {},
        conversionType,
        nextPartnerCommission,
      } = state.get("row");
      const newNextPartnerCommission = [
        "rtb",
        "rtb_email",
        "rtb_bo",
        "rtb_pop",
        "rtb_push",
        "rtb_so",
        "rtb_ipp"
      ].includes(action.value) &&
      [
        "click",
        "1000clicks",
        "webPushSubscription"
      ].includes(conversionType) ? null : nextPartnerCommission;

      return state.setIn(["row", action.fieldName], action.value)
        .setIn(["row", "linkValidationType"], dataProvider.onlyManualCheck ? "manual" : "")
        .setIn(["row", "adverIDAttr"], "")
        .setIn(["row", "adverIDValue"], "")
        .setIn(["row", "nextPartnerCommission"], newNextPartnerCommission);
    }
    case CHANGE_FORM_FIELD_OFFER: {
      const newState = state.setIn(["row", action.fieldName], action.value);
      return newState
        .setIn(["row", "nextPartnerCommission"], action.fieldName === "conversionType" ? null: newState.getIn(["row", "nextPartnerCommission"]))
        .setIn(["row", "isValid"], action.fieldName === "location" ? false : newState.getIn(["row", "isValid"]));
    }
    case CHANGE_FORM_LINK_VALIDATION_TYPE: {
      if (action.value === "") {
        return state
          .setIn(["row", "linkValidationType"], action.value)
          .setIn(["row", "adverIDAttr"], "")
          .setIn(["row", "adverIDValue"], "")
      }

      return state.setIn(["row", "linkValidationType"], action.value);
    }
    case SET_URL_STATUS_MESSAGE: {
      const urlValidationStatus = action.status ? "valid" : "error";

      if (urlValidationStatus === "valid") {
        return state
          .set("urlValidationStatus", urlValidationStatus)
          .set("urlValidationStatusMessage", action.message)
          .set("isValid", action.status)
          .setIn(["row", "linkValidationType"], "")
          .setIn(["row", "adverIDAttr"], "")
          .setIn(["row", "adverIDValue"], "");
      }

      return state
        .set("urlValidationStatus", urlValidationStatus)
        .set("urlValidationStatusMessage", action.message)
        .set("isValid", action.status);
    }
    case CHANGE_FORM_FIELD_OFFER_TYPE: {
      return state
        .setIn(["row", action.fieldName], action.value)
        .setIn(["row", "location"], action.value.label === "Int" ? "" : state.getIn(["row", "location"]))
        .deleteIn(["row", "niche"])
        .setIn(["row", "vertical"], action.value.label === "Int" ? "Dating" : "")
        .setIn(["row", "description"], "")
        .setIn(["row", "restriction"], "")
        .setIn(["row", "blackList"], "")
        .setIn(["row", "stream"], "")
        .setIn(["row", "nextPartnerCommission"], null)
        .setIn(["row", "partnerOfferName"], "")
        .setIn(["row", "campaignID"], null)
        .setIn(["row", "offerType"], state.getIn(["lists", action.value.label === "Int" ? "linkTypeInt" : "linkTypeExt"]).toJS()[0])
        .deleteIn(["row", "conversionType"])
        .setIn(["lists", "conversionTypes"], action.value.label === "Int"
          ? state.getIn(["lists", "conversionTypesIntForm"]) : state.getIn(["lists", "conversionTypesExtForm"]))
        .set("errors", List([]));
    }
    case SET_OFFER_LISTS_FIELD: {
      const { field, value } = action.value;
      return state.setIn(["lists", field], value || []);
    }
    case GET_OFFER_FORM: {
      return state
        .set("row", fromJS(action.offer))
        .setIn(["row", "department"], state.getIn(["lists", "department"]).filter((item) => item.id === action.offer.department)[0])
        .setIn(["row", "isExt"], state.getIn(["lists", "type"]).filter((item) => item.isExt === action.offer.isExt).toJS()[0])
        .setIn(["row", "isPublic"], action.offer.isPublic === 1 ? "Public" : "Private")
        .setIn(["row", "isAvailableForAllGroups"], action.offer.isAvailableForAllGroups === 1 ? "Yes" : "No")
        .setIn(["row", "conversionType"], action.offer.conversionType)
        .setIn(["row", "offerType"], state.getIn(["lists", "linkType"]).filter((item) => item.id === action.offer.offerType).toJS()[0])
        .setIn(["row", "country"], action.offer.country.split(","))
        .setIn(["row", "platform"], action.offer.platform.split(","))
        .setIn(["row", "offerName"], {
          domain: action.offer.offerName,
          id: action.offer.siteId,
        })
        .setIn(["row", "globalCapLogic"], state.getIn(["lists", "globalCapLogic"]).filter((item) => item.id === action.offer.globalCapLogic).toJS()[0])
        .setIn(["row", "currency"], state.getIn(["lists", "currency"]).filter((item) => item.label === action.offer.currency)[0])
        .setIn(["row", "dataProviderId"], state.getIn(["lists", "partners"]).filter((item) => item.id === action.offer.dataProviderId)[0])
        .setIn(["lists", "offerName"], action.list.offers)
        .setIn(["row", "globalCapDistributionMode"], mapGlobalCapDistributionMode(action.offer.globalCapDistributionMode));
    }
    case OFFER_IS_FETCHING: {
      return state.set("isFetching", action.status);
    }
    case OFFER_ERROR: {
      return state.set("errors", action.errors);
    }
    case SET_OFFER_FOUND: {
      return state.set("isOfferFound", action.isOfferFound);
    }
    case SET_IS_CLONE: {
      return state.set("isClone", action.payload);
    }
    default:
      return state;
  }
};
