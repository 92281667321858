// @flow
import type { StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SplitComponent } from "@fas/ui-core";
import { SelectForm, TextFieldForm } from "@fas/ui-core/lib/Form";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { useList } from "../../hoocks/useDictionary";
import AutocompleteWithFetchForm from "../AdvertiserInvoicesForm/AutocompleteWithFetchForm";
import { fetchSearchTopOffersByName } from "../../services/topOffersApi";
import PassPropForm from "../AdvertiserInvoicesForm/PassPropForm";
import MultiSelectForm from "../Form/MultiSelectForm";
import { FORM_KEY_TOP_OFFERS } from "../../helpers/constants/topOffers";
import type { State } from "../../pages/PotentialPartnerForm/store";

export type Props = $ReadOnly<{||}>;

const numberInputProps = { inputProps: { min: 0 } };

const DirectTopOffers: StatelessFunctionalComponent<Props> = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const onChangeOption = useCallback((e: any) => {
    dispatch(setFormData(FORM_KEY_TOP_OFFERS, {
      geoOffers: e ? e.geo : [],
      geo: [],
      vertical: e ? e.vertical : "",
      conversionType: e ? e.conversionType : "",
    }));
  }, []);

  const currencies: DropDownObjItemType[] = useList("currencies");
  const countries: any = useSelector((state: State) => getFormField(state, FORM_KEY_TOP_OFFERS, "geoOffers") || []);

  return (
    <>
      <PassPropForm name="offerName" propName="title">
        <AutocompleteWithFetchForm
          name="offerId"
          label="Offer name"
          fetchMethod={fetchSearchTopOffersByName}
          onChangeOption={onChangeOption}
        />
      </PassPropForm>
      <MultiSelectForm
        name="geo"
        label="Country"
        options={countries}
        enableAddAllBtn
      />
      <TextFieldForm
        name="vertical"
        label="Vertical"
        disabled
      />
      <TextFieldForm
        name="conversionType"
        label="Conversion type"
        disabled
      />
      <SplitComponent weight={5}>
        <TextFieldForm
          {...numberInputProps}
          name="payout"
          label="Payout"
          type="number"
        />
        <SelectForm
          name="currencyId"
          label="Currency"
          options={currencies}
        />
      </SplitComponent>
    </>
  );
};

export default DirectTopOffers;
