// @flow
import {
  call,
  select,
  takeEvery,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import type { Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { getTableFilters, getTableSorting } from "@fas/ui-framework/lib/redux/selectors/table";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";
import { getPostbackInfo } from "../../services/manageAffiliateApi";
import { mapQueryPostbacksV2 } from "../../helpers/manageAffiliate/affiliateHelper";
import { setPostbackFields } from "../../actions/postbacks/actions";
// eslint-disable-next-line import/max-dependencies
import { ADDITIONAL_POSTBACKS_TABLE, GET_POSTBACK_INFO_SAGA } from "../../helpers/constants/postbacks";

export function* makeFetch(): Saga<void> {
  const affiliateId = yield select(getManageAffiliateId);
  const filters: Sorting = yield select(getTableFilters, ADDITIONAL_POSTBACKS_TABLE);
  const sorting: Sorting = yield select(getTableSorting, ADDITIONAL_POSTBACKS_TABLE);

  try {
    yield put(setLoading(ADDITIONAL_POSTBACKS_TABLE, true));
    const { data } = yield call(getPostbackInfo, affiliateId, mapQueryPostbacksV2({ sorting, filters }));

    const {
      postbacks, message, status, ...rest // eslint-disable-line
    } = data;

    yield put(setTableData(ADDITIONAL_POSTBACKS_TABLE, postbacks));
    yield put(changeTableItemsTotalAmount(ADDITIONAL_POSTBACKS_TABLE, postbacks.length));
    yield put(setPostbackFields({ ...rest }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(ADDITIONAL_POSTBACKS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_POSTBACK_INFO_SAGA, (makeFetch: Function));
}
