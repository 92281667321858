// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type {
  StoreWithLoading,
  StoreWithLoadingType,
  // eslint-disable-next-line no-unused-vars
  LoadingTypes,
} from "./types";

/**
 * @deprecated Use getLoading or useLoading
 */
export const getTopOffersSaveLoading: OutputSelector<StoreWithLoading, *, boolean> = createSelector(
  (state: StoreWithLoading): boolean => state.loading.get("saveTopOffersLoading", false),
  (value: boolean): boolean => value
);

/**
 * @deprecated Use getLoading or useLoading
 */
export const getTopOffersSearchOffersLoading: OutputSelector<StoreWithLoading, *, boolean> = createSelector(
  (state: StoreWithLoading): boolean => state.loading.get("getTopOffersSearchOffersLoading", false),
  (value: boolean): boolean => value
);

/**
 * Get loading state
 * @param {StoreWithLoadingType} state redux state
 * @param {string} key loading key to get from loading state
 * @returns {boolean} loading
 */
export function getLoading<T: string = LoadingTypes>(state: StoreWithLoadingType<T>, key: T): boolean {
  return state.loading.get(key, false);
}
