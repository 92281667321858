// @flow
export const GET_SUBRATES_INDEX_LIST_SAGA: "GET_SUBRATES_INDEX_LIST_SAGA" = "GET_SUBRATES_INDEX_LIST_SAGA";
export const GET_SUBRATES_INFO_LIST_SAGA: "GET_SUBRATES_INFO_LIST_SAGA" = "GET_SUBRATES_INFO_LIST_SAGA";
export const GET_SUBRATES_DICTIONARY_SAGA: "GET_SUBRATES_DICTIONARY_SAGA" = "GET_SUBRATES_DICTIONARY_SAGA";
export const SET_TEMPLATE: "SET_TEMPLATE" = "SET_TEMPLATE";
export const SAVE_TEMPLATE_SAGA: "SAVE_TEMPLATE_SAGA" = "SAVE_TEMPLATE_SAGA";
export const DELETE_SUBRATE_SAGA: "DELETE_SUBRATE_SAGA" = "DELETE_SUBRATE_SAGA";
export const SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA: "SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA" = "SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA";
export const SET_OPTIONS_OFFER_FOR_SUBRATE: "SET_OPTIONS_OFFER_FOR_SUBRATE" = "SET_OPTIONS_OFFER_FOR_SUBRATE";
export const SEARCH_OFFER_FOR_SUBRATE: "SEARCH_OFFER_FOR_SUBRATE" = "SEARCH_OFFER_FOR_SUBRATE";
export const SELECT_OFFER_FOR_SUBRATE_SAGA: "SELECT_OFFER_FOR_SUBRATE_SAGA" = "SELECT_OFFER_FOR_SUBRATE_SAGA";
export const SET_SUBRATES_OPTIONS_OFFER_COUNTRY_FOR_SUBRATE: "SET_SUBRATES_OPTIONS_OFFER_COUNTRY_FOR_SUBRATE" = "SET_SUBRATES_OPTIONS_OFFER_COUNTRY_FOR_SUBRATE";
export const COUNTRY_NULL_URL_VIEW: "all" = "all";
export const MASS_ADJUST_SUBRATES_SAGA: "MASS_ADJUST_SUBRATES_SAGA" = "MASS_ADJUST_SUBRATES_SAGA";

export const SUBRATES_INDEX_TABLE: "SUBRATES_INDEX_TABLE" = "SUBRATES_INDEX_TABLE";
export const SUBRATES_INFO_TABLE: "SUBRATES_INFO_TABLE" = "SUBRATES_INFO_TABLE";

export const FORM_KEY_SUBRATE: "FORM_KEY_SUBRATE" = "FORM_KEY_SUBRATE";
