// @flow
import { Record, List } from "immutable";
import type { RecordFactory, RecordInstance } from "immutable";
import {
  SET_FETCHED_MARKETERS,
  SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
  CHANGE_IS_MANAGER_LIST_LOADED,
} from "../../helpers/constants/cpaoffer";
import type { Actions as CpaOfferActions } from "../../actions/cpaOfferIndex";
import type { ManagerList } from "../../actions/cpaoffer";

export type TableDataType = {
  id: string,
  managerId: string | null,
  createdById: string,
  marketingGroups: number[],
  conversionType: string,
  currency: string,
  payoutAmount: string,
  globalCap: number,
  globalCapCounter: number,
  globalCapDistributionMode: string,
  globalCapLogic: string,
  isActive: number,
  isPublic: number,
  isValid: string | boolean,
  payoutAmountFirst: string,
  department: string,
  account: string | null,
  name: string,
  group: string,
  location: string | null,
  linkValidationType: string | number,
  adverIDAttr: string,
  adverIDValue: string,
  dataProvider: string,
  manager: string | null,
  vertical: string | null,
  niche: string | null,
  country: string,
  platform: string,
  createdBy: string,
  createdAt: string,
  updatedBy: string,
  updatedAt: string,
  offerType: string,
  isExt: string,
  isAvailableForAllGroups: number,
  description: string,
  restriction: string,
  blackList: string,
  class: string,
  stream: string,
  camрaignID: string,
  shaveAmount: number,
  ncrUnit: string,
  ncrAmount: number,
  paymentModel: string,
};

type DefaultStateType = {
  isLists: boolean,
  isActives: List<{id: string, isActive: number}>,
  isChangeAuthorModalOpened: boolean,
  managersList: ManagerList,
  isManagersListLoaded: boolean,
};

const defaultState: DefaultStateType = {
  isLists: true,
  isActives: List(),
  isChangeAuthorModalOpened: false,
  managersList: [],
  isManagersListLoaded: false,
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordInstance<DefaultStateType>;

export const initialState: State = makeState({});

export const initState = (): State => initialState;

export type Action = CpaOfferActions

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    // $FlowFixMe
    case SWITCH_CHANGE_AUTHOR_MODAL_OPENED: {
      return state.set("isChangeAuthorModalOpened", !state.get("isChangeAuthorModalOpened")).set("isManagersListLoaded", false);
    }
    // $FlowFixMe
    case SET_FETCHED_MARKETERS: {
      return state.set("managersList", action.managersList);
    }
    // $FlowFixMe
    case CHANGE_IS_MANAGER_LIST_LOADED: {
      return state.set("isManagersListLoaded", true);
    }
    default:
      return state;
  }
};
