// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetSmuMarketingCompaniesListSaga from "../../sagas/getSmuMarketingCompaniesList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetSmuMarketingCompaniesListSaga),
  ]);
}
