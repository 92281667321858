// @flow
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  CircularProgress,
  Box,
} from "@mui/material";
import {
  Error,
} from "@fas/ui-core";
import {
  setAffiliateId,
  getPersonalInfoListsSaga,
  getAffiliateSaga,
} from "../../actions/manageAffiliate";
import Affiliate from "./components/Affiliate";
import {
  tabs,
  getIsListsLoaded,
} from "./utils";
import { userService } from "../../services/user";

type Props = {
  match: Object,
  history: Object,
  getAffiliate: (
    id: string
  ) => void,
  setId: (
    value: string
  ) => void,
  getLists: (affId?: string) => void,
  isListsLoaded: boolean,
  mode: string,
};

const mapStateToProps = (state) => ({
  isListsLoaded: getIsListsLoaded(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAffiliate: (id) => dispatch(getAffiliateSaga(id)),
  setId: (value) => dispatch(setAffiliateId(value)),
  getLists: (affId?: string) => dispatch(getPersonalInfoListsSaga(affId)),
});

const CreateAffiliate = ({
  match,
  history,
  mode,
  getAffiliate,
  setId,
  getLists,
  isListsLoaded,
}: Props) => {
  const [isAffiliateFound, setAffiliateFound] = useState(true);
  const availableTabs = tabs.filter((tabItem) => userService.can(tabItem.permission)).map(({ name }) => name);
  const firstAvailableTab = availableTabs[0];
  const { id, tab = firstAvailableTab } = match;
  const currentTab = availableTabs.includes(tab) ? tab : firstAvailableTab;

  useEffect(() => {
    getLists(id);
  }, [tab, id, getLists]);

  useEffect(() => {
    if (id) {
      getAffiliate(id)
        .then((result) => {
          if (result.message === "FAIL") {
            setAffiliateFound(false);
          }

          setId(id);
          if (availableTabs.includes(currentTab)) {
            history(`/manageAffiliate/edit/${id}/${currentTab}`);
          }
        })
        .catch((error) => Promise.reject(error));
    }
  }, []);

  return (
    (!isListsLoaded && isAffiliateFound) ? (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress disableShrink />
      </Box>
    ) : (
      <>
        <>
          {isAffiliateFound && availableTabs.includes(currentTab) ? (
            <Affiliate
              mode={mode}
              tab={currentTab}
              id={id}
              history={history}
            />
          ) : (
            <Error />
          )}
        </>
      </>
    )
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAffiliate);
