// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import moment from "moment";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { ExportTemplateSaga } from "../../actions/templates";
import { EXPORT_TEMPLATE_SAGA } from "../../helpers/constants/templates";
import { exportCpaOffers } from "../../services/cpaOffersApi";
import downloadCsv, { getFileNameFromHeaders } from "../../helpers/downloadCsvFile/downloadCsvFile";

export function* makeFetch(action: ExportTemplateSaga): Saga<void> {
  try {
    yield put(setLoading(EXPORT_TEMPLATE_SAGA, true));
    const { data, headers } = yield call(exportCpaOffers, action.payload);
    const fileName: string = getFileNameFromHeaders(headers, `CpaOffers_${moment().format("MM.DD.YY")}.csv`);
    downloadCsv(data, fileName);
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Error while downloading csv", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(EXPORT_TEMPLATE_SAGA, false));
  }
}

export default function* watchExportTemplateSaga(): Saga<void> {
  yield takeEvery(EXPORT_TEMPLATE_SAGA, (makeFetch: Function));
}
