// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, call, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getAffiliateId } from "../../selectors/payments";
import { GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA, NEXT_PAYMENT_DATE_LOADING } from "../../helpers/constants/payments";
import { fetchNextPaymentDate } from "../../services/payments";
import type { LoadingTypes } from "../../selectors/loading/types";
import {
  setPaymentNextPaymentDate,
  setEmptyPaymentInfoState,
} from "../../actions/paymentInfo";
import type { NextPaymentDateType } from "../../components/NextPaymentDateLabel/types/NextPaymentDateLabel.types";

export function* makeFetch(): Saga<void> {
  yield put(setEmptyPaymentInfoState(["nextPaymentDate"]));
  try {
    yield put(setLoading<LoadingTypes>(NEXT_PAYMENT_DATE_LOADING, true));
    const affId: string = yield select(getAffiliateId);
    const {
      data: nextPaymentDate,
    }: { data: NextPaymentDateType } = yield call(fetchNextPaymentDate, affId);

    yield put(setPaymentNextPaymentDate(nextPaymentDate));
  }
  catch (error) {
    if (
      error.response
      && (error.response.status === 403 || error.response.status === 404)
      && error.response.data
      && Array.isArray(error.response.data.errors)
    ) {
      const errors: ErrorsMapper = error.response.data.errors
        .reduce((errorsMapper: ErrorsMapper, fetchError: {| message: string, code: string |}): ErrorsMapper => Object
          .assign(errorsMapper, {
            "nextPaymentDate": { message: fetchError.message },
          }), {}); // TODO: fix when backend is fixed
      yield put(setErrors(errors));
      yield put(setPaymentNextPaymentDate({
        payoutPeriod: "",
        comment: errors.nextPaymentDate.message,
      }));
    }
    else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  finally {
    yield put(setLoading<LoadingTypes>(NEXT_PAYMENT_DATE_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA, (makeFetch: Function));
}
