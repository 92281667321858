// @flow
import {
  select,
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { SAVE_POSTBACK_EVENT_SAGA } from "../../helpers/constants/EventsPostback";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";
import { savePostbackEvent } from "../../services/manageAffiliateApi";
import {
  changeCurrentEventPostback,
  getPostbackEventsSaga,
  openEventPostbackModal,
} from "../../actions/eventsPostback";
import type { SavePostbackEventSaga } from "../../actions/eventsPostback";

export function* makeFetch(action: SavePostbackEventSaga): Saga<void> {
  const { fields } = action;
  const affiliateId = yield select(getManageAffiliateId);
  try {
    yield call(savePostbackEvent, affiliateId, fields);
    yield put(changeCurrentEventPostback(null));
    yield put(addNotification({ message: "Event saved", severity: "success" }));
    yield put(openEventPostbackModal(false));
    yield put(getPostbackEventsSaga());
  }
  catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      yield put(setErrors(prepareFetchErrors(error.response.data.errors)));
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSavePostback(): Saga<void> {
  yield takeEvery(SAVE_POSTBACK_EVENT_SAGA, (makeFetch: Function));
}
