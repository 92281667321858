// @flow
import {
  call,
  put,
  select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { DELETE_TEMPLATE_SAGA } from "../../helpers/constants/templates";
import { removeTemplate } from "../../services/templatesApi";
import { getReportName } from "../../selectors/templates";
import { getTemplatesSaga, type DeleteTemplateSaga } from "../../actions/templates";

export function* deleteTemplate({ payload: item }: DeleteTemplateSaga): Saga<void> {
  yield put(setLoading("getTemplatesList", true));
  try {
    const reportName: string = yield select(getReportName);

    yield call(removeTemplate, reportName, item.id);
    yield put(addNotification({ message: `Template ${item.name} deleted`, severity: "success" }));

    yield put(getTemplatesSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to delete template", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setLoading("getTemplatesList", false));
  }
}

export function* watchDeleteTemplate(): Saga<void> {
  yield takeEvery(DELETE_TEMPLATE_SAGA, deleteTemplate);
}
