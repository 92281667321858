// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import moment from "moment";
import {
  Loader,
  SetupCard,
} from "@fas/ui-core";
import { Box } from "@mui/material";
import CardGridContent from "../CardGridContent";
import type { FieldItem } from "../CardGridContent/types/CardGridContent.types";
import {
  getAffiliateInfoMsg,
} from "../../helpers/generators";
import type { Props, AffiliateInfoType } from "./types/AffiliateInfoCard.types";

const AffiliateInfoCard: StatelessFunctionalComponent<Props> = ({
  loading,
  affiliateInfo,
}: Props) => {
  const {
    name,
    manager,
    affiliateType,
    affiliateStatus,
    affiliatePaymentsType,
    lastPaymentDate,
    lastConversionDate,
    payoutCompany,
    currency,
    benificiaryName,
    paymentMethod,
  }: AffiliateInfoType = affiliateInfo;
  const fieldsMapper: FieldItem[] = [
    { label: "message", value: getAffiliateInfoMsg(lastConversionDate) },
    { label: "Name", value: name },
    { label: paymentMethod === "Bank Wire Transfer" ? "Bank account name" : "Beneficiary name", value: benificiaryName || "\u2E3A" },
    { label: "Manager", value: manager },
    { label: "Affiliate type", value: affiliateType },
    { label: "Affiliate status", value: affiliateStatus },
    { label: "Last conversion date", value: lastConversionDate ? moment(lastConversionDate).format("YYYY-MM-DD") : "\u2E3A" },
    { label: "Payout company", value: payoutCompany },
    { label: "Payment method", value: paymentMethod },
    { label: "Payment type", value: affiliatePaymentsType },
    { label: "Payment currency", value: currency },
    { label: "Last payment date", value: lastPaymentDate ? moment(lastPaymentDate).format("YYYY-MM-DD") : "\u2E3A" },
  ];

  return (
    <SetupCard title="Affiliate info">
      <Box mt={2} width={1}>
        <Loader loading={loading} size={35}>
          <CardGridContent
            fieldsMapper={fieldsMapper}
            gridCols={2}
            tableRows={1}
          />
        </Loader>
      </Box>
    </SetupCard>
  );
};

export default AffiliateInfoCard;
