// @flow
/* eslint-disable import/max-dependencies */
import {
  Record,
  List,
} from "immutable";
import type { RecordFactory } from "immutable";
import {
  CHANGE_AFFILIATE_ID,
  SET_AFFILIATE_INFO,
  SET_PAYMENT_NEXT_PAYMENT_DATE,
  SET_PAYMENT_STATS,
  SET_PAYMENT_DETAILS,
  SET_PAYMENT_SIMILAR_AFFILIATES,
  SET_EMPTY_PAYMENT_INFO_STATE,
} from "../../helpers/constants/payments";

import type {
  Actions,
  ChangeAffiliateIdAction,
  SetAffiliateInfoAction,
  SetPaymentNextPaymentDateAction,
  SetPaymentStatsAction,
  SetPaymentDetailsAction,
  SetSimilarAffiliatesAction,
  SetEmptyPaymentInfoState,
} from "../../actions/paymentInfo";
import type { NextPaymentDateType } from "../../components/NextPaymentDateLabel";
import type { PaymentStatsType } from "../../components/PaymentInfoCard";
import type { AffiliateInfoPayloadType, AffiliateInfoType } from "../../components/AffiliateInfoCard";
import type { PaymentDetailsPayloadType, PaymentDetailsType } from "../../components/PaymentDetailsCard";
import type { SimilarAffiliateType } from "../../components/SimilarAffiliatesCard/types/SimilarAffiliatesCard.types";
import type {
  State as StateType,
  DefaultStateType,
  PaymentDetailsStateType,
} from "./types";
import { getPaymentTypeModel } from "../../pages/PaymentInfo/PaymentTypeFactory";
import PaymentType from "../../pages/PaymentInfo/PaymentTypeFactory/PaymentType";

export const makeAffiliateInfo: RecordFactory<AffiliateInfoType> = Record(({
  name: "",
  manager: "",
  affiliateType: "",
  affiliateStatus: "",
  affiliatePaymentsType: "",
  lastPaymentDate: "",
  lastConversionDate: "",
  payoutCompany: "",
  currency: "",
  benificiaryName: "",
  paymentMethod: "",
}: AffiliateInfoType));

export const makeNextPaymentDate: RecordFactory<NextPaymentDateType> = Record(({
  payoutPeriod: "",
  comment: "",
}: NextPaymentDateType));

export const makePaymentInfo: RecordFactory<PaymentStatsType> = Record(({
  totalCost: 0,
  totalCostExt: 0,
  totalCostInt: 0,
  totalPaidCost: 0,
  totalPaidCostExt: 0,
  totalPaidCostInt: 0,
  totalUnpaidCost: 0,
  totalUnpaidCostExt: 0,
  totalUnpaidCostInt: 0,
  totalUnpaidPeriod: "",
}: PaymentStatsType));

export const makePaymentDetails: RecordFactory<PaymentDetailsType> = Record(({
  affId: "",
  beAddress: "",
  beCountry: "",
  affiliateType: "",
  currency: "",
  lastUpdateDate: "",
  paymentMethodId: 0,
  paymentMethodCreds: {},
  paymentComment: "",
}: PaymentDetailsType));

const defaultState: DefaultStateType = {
  affiliateId: "",
  affiliateInfo: makeAffiliateInfo({}),
  nextPaymentDate: makeNextPaymentDate({}),
  paymentInfo: makePaymentInfo({}),
  paymentDetails: makePaymentDetails({}),
  similarAffiliates: List([makePaymentDetails({})]),
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export const initialState: StateType = makeState({});

export const initState: () => StateType = (): StateType => initialState;

// eslint-disable-next-line complexity
export default (state: StateType = initialState, action: Actions): StateType => {
  switch (action.type) {
    case CHANGE_AFFILIATE_ID: {
      const { affId }: ChangeAffiliateIdAction = action;
      return state.set("affiliateId", affId);
    }
    case SET_AFFILIATE_INFO: {
      const { payload }: SetAffiliateInfoAction = action;

      const {
        name,
        affiliateType,
        affiliateStatus,
        affiliatePaymentsType,
        lastPaymentDate,
        lastConversionDate,
        manager: { name: manager },
        payoutCompany: { name: payoutCompany },
        currency: { name: currency },
        paymentInfo: {
          paymentMethodId,
          paymentMethod: { name: paymentMethod },
          bankAccountName,
          beName,
        },
      }: AffiliateInfoPayloadType = payload;

      return state.mergeIn(["affiliateInfo"], {
        name,
        affiliateType,
        affiliateStatus,
        affiliatePaymentsType,
        lastPaymentDate,
        lastConversionDate,
        payoutCompany,
        manager,
        currency,
        benificiaryName: paymentMethodId === 1 ? bankAccountName : beName,
        paymentMethod,
      });
    }
    case SET_PAYMENT_NEXT_PAYMENT_DATE: {
      const { payload }: SetPaymentNextPaymentDateAction = action;
      const { payoutPeriod, comment }: NextPaymentDateType = payload;

      return state.mergeIn(["nextPaymentDate"], {
        payoutPeriod,
        comment,
      });
    }
    case SET_PAYMENT_STATS: {
      const { payload }: SetPaymentStatsAction = action;
      const {
        totalCost,
        totalCostExt,
        totalCostInt,
        totalPaidCost,
        totalPaidCostExt,
        totalPaidCostInt,
        totalUnpaidCost,
        totalUnpaidCostExt,
        totalUnpaidCostInt,
        totalUnpaidPeriod,
      }: PaymentStatsType = payload;

      return state.mergeIn(["paymentInfo"], {
        totalCost,
        totalCostExt,
        totalCostInt,
        totalPaidCost,
        totalPaidCostExt,
        totalPaidCostInt,
        totalUnpaidCost,
        totalUnpaidCostExt,
        totalUnpaidCostInt,
        totalUnpaidPeriod,
      });
    }
    case SET_PAYMENT_DETAILS: {
      const { payload }: SetPaymentDetailsAction = action;

      const {
        affiliateType,
        currency: { name: currency },
        paymentInfo,
        paymentComment,
      }: AffiliateInfoPayloadType = payload;
      const { paymentMethodId }: PaymentDetailsPayloadType = paymentInfo;

      const PaymentTypeModel: PaymentType = getPaymentTypeModel(paymentMethodId);
      const paymentDetails: PaymentDetailsType = PaymentTypeModel.getPaymentDetails({
        ...paymentInfo,
        affiliateType,
      });

      return state.mergeIn(["paymentDetails"], {
        ...paymentDetails,
        currency,
        paymentComment,
      });
    }
    case SET_PAYMENT_SIMILAR_AFFILIATES: {
      const { payload }: SetSimilarAffiliatesAction = action;

      return state.withMutations((newState: StateType) => {
        const affiliates: Array<PaymentDetailsStateType> = payload.map(({
          type: affiliateType,
          currency: { currency },
          paymentInfo,
          paymentComment,
        }: SimilarAffiliateType): PaymentDetailsStateType => {
          const { paymentMethodId }: PaymentDetailsPayloadType = paymentInfo;

          const PaymentTypeModel: PaymentType = getPaymentTypeModel(paymentMethodId);
          const paymentDetails: PaymentDetailsType = PaymentTypeModel.getPaymentDetails({
            ...paymentInfo,
            affiliateType,
          });

          return makePaymentDetails({
            ...paymentDetails,
            currency,
            paymentComment,
          });
        });

        newState.setIn(["similarAffiliates"], List(affiliates));
      });
    }
    case SET_EMPTY_PAYMENT_INFO_STATE: {
      const { keys }: SetEmptyPaymentInfoState = action;

      return state.withMutations((newState: StateType) => {
        keys.forEach((key: $Keys<DefaultStateType>) => {
          newState.delete(key);
        });
      });
    }
    default:
      return state;
  }
};
