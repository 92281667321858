// @flow
import React, { useRef, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MaskedInput from "react-text-mask";

type Props = {
  mask: string,
  value: string,
  name: string,
  label: string,
  error: String,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  helperIcon: {
    marginLeft: 5,
  },
  multilineInput: {
    "& .MuiOutlinedInput-multiline": {
      background: theme.palette.background.mainBg,
    },
    marginTop: 10,
    marginBottom: 10,
  },
}));

const TextMask = React.forwardRef((props, inputRef) => (
  <MaskedInput
    {...props}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/[A-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    showMask
    guide
    id="webmoneyWallet"
    onClick={(e) => {
      const { value } = e.target;
      const currentCursor = value.split("_")[0].length;
      const input = document.getElementById(e.target.id);
      input.focus();
      input.setSelectionRange(currentCursor, currentCursor);
    }}
  />
));

const WalletFieldMask = ({
  mask,
  value,
  name,
  label,
  error,
  setField,
  disabled,
}: Props) => {
  const classes = useStyles();

  const walletRef = useRef(null);

  const handleChangeField = (e) => setField(name, e.target.value);

  useEffect(() => {
    if (walletRef.current) {
      walletRef.current.value = value;
    }
  }, []);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      className={classes.inputField}
      name={name}
      data-testid={name}
      variant="outlined"
      label={label}
      error={!!error}
      data-mask={mask}
      helperText={error && error}
      inputProps={{
        style: { background: classes.input },
      }}
      FormHelperTextProps={{
        style: { marginLeft: 0, marginRight: 0 },
      }}
      inputRef={walletRef}
      onBlur={handleChangeField}
      InputProps={{
        classes: { adornedStart: classes.input },
        startAdornment: (
          <>
            <InputAdornment
              position="start"
            >
              <Tooltip title="Some text">
                <HelpOutlineIcon />
              </Tooltip>
            </InputAdornment>
          </>
        ),
        inputComponent: TextMask,
      }}
    />
  );
};

export default WalletFieldMask;
