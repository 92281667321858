// @flow
import type { StatelessFunctionalComponent } from "react";
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, Container } from "@mui/material";
import {
  Error, FormHeader, Loader, SetupCard,
} from "@fas/ui-core";
import { type Match, useParams } from "react-router-dom";
import { SelectForm, TextFieldForm } from "@fas/ui-core/lib/Form";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import moment from "moment";
import Form from "../Form";
import validate from "./validate";
import { FORM_KEY_ADVERTISER_INVOICES } from "../../helpers/constants/advertiserInvoices";
import { useDictionaryList } from "../../hoocks/useDictionary";
import { useLoading } from "../../hoocks/useLoading";
import DropzoneAreaForm from "./DropzoneAreaForm";
import AutocompleteWithFetchForm from "./AutocompleteWithFetchForm";
import DatePickerForm from "./DatePickerForm";
import SplitComponent from "./SplitComponent";
import PassPropForm from "./PassPropForm";
import ShowByValue from "./ShowByValue";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { fetchCpaAdvertiser } from "../../services/advertiserInvoicesApi";
import {
  useResetFieldByFieldChange,
  useDisabledFieldForm,
  useOptionalOption,
  useValue,
  type Optional,
} from "./hoocks";

export type Props = $ReadOnly<{||}>;

const statusOptions: Option[] = [{ label: "Pending", value: "pending" }, { label: "Paid", value: "paid" }, { label: "Declined", value: "declined" }];
const acceptedFiles: string[] = ["application/pdf"];

const numberInputProps = {
  inputProps: {
    step: 0.01,
    min: 0.01,
    max: 99999999.99,
  },
};

const AdvertiserInvoicesForm: StatelessFunctionalComponent<Props> = () => {
  const { id: objectId }: $PropertyType<Match, "params"> = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_ADVERTISER_INVOICES, objectId));
    }
  }, [objectId]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const id: string = useValue("id");
  const [currency] = useDictionaryList("advertiser", "currency");
  const [paymentMethod] = useDictionaryList("advertiser", "paymentMethod");
  useResetFieldByFieldChange("status", "paidAt", null);

  const periodToMinDate: string | null = useValue("periodFrom") || moment().subtract(5, "y").format("YYYY-MM-DD");
  const periodFromMaxDate: string | null = useValue("periodTo") || moment().add(5, "y").format("YYYY-MM-DD");

  const {
    lockUpdate,
    lockUpdatePaidAt,
    lockUpdateCpaAdvertiser,
  } = useDisabledFieldForm();

  const defaultPaymentMethod: Optional<Option> = useOptionalOption("paymentMethod", "paymentMethodId");
  const defaultCurrency: Optional<Option> = useOptionalOption("currency", "currencyId");

  const isError: boolean = useValue("isError");

  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_ADVERTISER_INVOICES} validate={validate} redirectOnSave="/advertiserInvoices">
      <FormHeader
        title={id ? `Edit advertiser invoice: ${String(id)}` : "Create advertiser invoice"}
        loading={loading}
        isActionVisible={!loading && !lockUpdate}
      />
      <Container maxWidth="md">
        <SetupCard title="Document">
          <Box width={1}>
            <Loader loading={loading}>
              <DropzoneAreaForm
                name="invoice"
                acceptedFiles={acceptedFiles}
                disabled={lockUpdate}
                showRemoveButton={!lockUpdate}
                maxSize={1024 * 1024}
                showErrors={false}
              />
            </Loader>
          </Box>
        </SetupCard>
        <SetupCard title="General options">
          <Box width={1}>
            <Loader loading={loading}>
              <PassPropForm name="cpaAdvertiser" propName="title">
                <AutocompleteWithFetchForm
                  name="cpaAdvertiserId"
                  label="Data provider"
                  fetchMethod={fetchCpaAdvertiser}
                  disabled={lockUpdateCpaAdvertiser}
                />
              </PassPropForm>
              <DatePickerForm
                name="paidAt"
                label="Paid on"
                disabled={lockUpdatePaidAt}
              />
              <SplitComponent weight={5}>
                <TextFieldForm
                  {...numberInputProps}
                  name="payout"
                  label="Paid total"
                  type="number"
                  disabled={lockUpdate}
                />
                <SelectForm
                  name="currencyId"
                  label="Currency"
                  options={currency}
                  disabled={lockUpdate}
                  defaultValue={defaultCurrency}
                />
              </SplitComponent>
              <SelectForm
                name="paymentMethodId"
                label="Payment method"
                options={paymentMethod}
                disabled={lockUpdate}
                defaultValue={defaultPaymentMethod}
              />
              <DatePickerForm
                name="periodFrom"
                label="Date from"
                maxDate={periodFromMaxDate}
                maxDateMessage="Date from should be lower than Date to"
                disabled={lockUpdate}
              />
              <DatePickerForm
                name="periodTo"
                label="Date to"
                minDate={periodToMinDate}
                minDateMessage="Date to should be greater than Date from"
                disabled={lockUpdate}
              />
              <SelectForm
                name="status"
                label="Status"
                options={statusOptions}
                disabled={lockUpdate}
              />
              <ShowByValue name="createdAt">
                <TextFieldForm
                  name="createdAt"
                  label="Created at"
                  disabled
                />
              </ShowByValue>
              <ShowByValue name="createdByUser">
                <TextFieldForm
                  name="createdByUser"
                  label="Created by"
                  disabled
                />
              </ShowByValue>
              <ShowByValue name="updatedAt">
                <TextFieldForm
                  name="updatedAt"
                  label="Updated at"
                  disabled
                />
              </ShowByValue>
              <ShowByValue name="updatedByUser">
                <TextFieldForm
                  name="updatedByUser"
                  label="Updated by"
                  disabled
                />
              </ShowByValue>
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default AdvertiserInvoicesForm;
