// @flow
import {
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_CPA_OFFERS_LISTS_SAGA } from "../../helpers/constants/cpaoffer";
import {
  offerIsFetching,
  setFetchDataLists,
} from "../../actions/cpaoffer";
import { getCpaOffersLists } from "../../services/cpaOffersApi";

const rtbOfferType: string[] = [
  "rtb",
  "rtb_email",
  "rtb_bo",
  "rtb_pop",
  "rtb_push",
  "rtb_so",
  "rtb_ipp",
];

const conversionTypesNoCommission: string[] = [
  "click",
  "1000clicks",
  "webPushSubscription",
];

export function* makeFetch(): Saga<void> {
  yield put(setLoading("isCpaOffersLists", true));
  try {
    const { data } = yield call(getCpaOffersLists);

    const newData = rtbOfferType.includes(data.offerType) && conversionTypesNoCommission.includes(data.conversionType)
      ? { ...data, nextPartnerCommission: null }
      : data;

    yield put(setFetchDataLists(newData));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("isCpaOffersLists", false));
    yield put(offerIsFetching(false)); // need remove this after refactor
  }
}

export default function* watchGetCpaOffersLists(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_CPA_OFFERS_LISTS_SAGA, (makeFetch: Function));
}
