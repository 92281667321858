// @flow
import {
  GET_SUBRATES_INDEX_LIST_SAGA,
  GET_SUBRATES_INFO_LIST_SAGA,
  GET_SUBRATES_DICTIONARY_SAGA,
  SET_TEMPLATE,
  SAVE_TEMPLATE_SAGA,
  DELETE_SUBRATE_SAGA,
  MASS_ADJUST_SUBRATES_SAGA,
  SEARCH_OFFER_FOR_SUBRATE,
  SET_OPTIONS_OFFER_FOR_SUBRATE,
  SELECT_OFFER_FOR_SUBRATE_SAGA,
  SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA,
} from "../../helpers/constants/subrates";
import type {
  GetSubratesIndexListSaga,
  GetSubratesInfoListSaga,
  GetSubratesDictionarySagaType,
  SetTemplatesType,
  SaveTemplateSagaType,
  DeleteSubrateSagaType,
  MassAdjustSubratesSagaType,
  SearchOfferForSubrate,
  SetOfferForSubrate,
  SelectOfferForSubrateSaga,
  SelectFieldByLocationForSubrateSaga,
} from ".";
import type { TemplateObjType } from "../../reducers/subrates";
import type { DropDownsOptions } from "../../reducers/subrates/types";

export const getSubratesIndexListSaga: () => GetSubratesIndexListSaga = () => ({
  type: GET_SUBRATES_INDEX_LIST_SAGA,
});

export const getSubratesInfoListSaga: () => GetSubratesInfoListSaga = () => ({
  type: GET_SUBRATES_INFO_LIST_SAGA,
});

export const getSubratesDictionarySaga: () => GetSubratesDictionarySagaType = () => ({
  type: GET_SUBRATES_DICTIONARY_SAGA,
});

export const setTemplate: (TemplateObjType) => SetTemplatesType = (payload) => ({
  type: SET_TEMPLATE,
  payload,
});

export const saveTemplateSaga: (TemplateObjType) => SaveTemplateSagaType = (payload) => ({
  type: SAVE_TEMPLATE_SAGA,
  payload,
});

export const deleteSubrateSaga: () => DeleteSubrateSagaType = () => ({
  type: DELETE_SUBRATE_SAGA,
});

export const massAdjustSubratesSaga: ({ [string]: string | number }) => MassAdjustSubratesSagaType = (payload) => ({
  type: MASS_ADJUST_SUBRATES_SAGA,
  payload,
});

/**
 * Form actions
 */

export const searchOfferForSubrate = (key: string, value: string, field: string): SearchOfferForSubrate => ({
  type: SEARCH_OFFER_FOR_SUBRATE,
  key,
  value,
  field,
});

export const setOptionsOffers = (key: string, offers: DropDownsOptions): SetOfferForSubrate => ({
  type: SET_OPTIONS_OFFER_FOR_SUBRATE,
  offers,
  key,
});

export const selectOfferForSubrateSaga = (offer: { label: string, value: string }): SelectOfferForSubrateSaga => ({
  type: SELECT_OFFER_FOR_SUBRATE_SAGA,
  offer,
});

// eslint-disable-next-line max-len
export const selectFieldByLocationForSubrateSaga = (props: {
  country: string,
  affId: string,
  offerId: string,
  shaveAmount: string,
  payoutAmount: string,
}): SelectFieldByLocationForSubrateSaga => ({
  type: SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA,
  props,
});
