// @flow
/* eslint-disable import/max-dependencies */
/* eslint-disable complexity */

import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { useParams } from "react-router-dom";
import {
  Box, Container, InputAdornment, TextField,
} from "@mui/material";
import {
  Error,
  FormHeader, SetupCard,
} from "@fas/ui-core";
import { SelectForm, AutocompleteWithFetchForm, TextFieldForm } from "@fas/ui-core/lib/Form";
import { useDispatch, useSelector } from "react-redux";
import { getFormData, getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { getFormDataSaga, setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getErrorsMessage } from "@fas/ui-framework/lib/redux/selectors/errors/selectors";
import type { FormObject } from "@fas/ui-framework/lib/redux/reducers/form";
import type { Dropdown } from "@fas/ui-core/lib/helpers/types";
import Form from "../Form";
import type { SearchQuery } from ".";
import { FORM_KEY_SUBRATE, COUNTRY_NULL_URL_VIEW } from "../../helpers/constants/subrates";
import { useLoading } from "../../hoocks/useLoading";
import { useList } from "../../hoocks/useDictionary";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING, SELECT_FIELDS_FOR_SUBRATE } from "../../helpers/constants/loading";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import {
  selectFieldByLocationForSubrateSaga,
  selectOfferForSubrateSaga,
} from "../../actions/subrates";
import { getSubratesDropdowns } from "../../services/subratesApi";
import type { State } from "../../pages/Subrates";
import validation from "./validate";

export function getQuery(): SearchQuery {
  const searchParams = new URLSearchParams(window.location.search);
  const result: SearchQuery = {};

  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }
  return result;
}
const inputNumberRange: { min: number, max: number } = { min: 0, max: 99999999.99 };
const inputPercentRange: { min: number, max: number } = { min: 0, max: 100 };

export type Props = {};
const SubrateForm: StatelessFunctionalComponent<Props> = () => {
  const { id } = useParams();
  const dispatch: <A>(A) => A = useDispatch();
  const {
    country,
    affId,
    offerId,
    shaveAmount,
    payoutAmount,
  }: SearchQuery = getQuery();

  // form data
  const errors: { [string]: string } = useSelector((state: State): { [string]: string } => getErrorsMessage(state));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING, SELECT_FIELDS_FOR_SUBRATE);
  const {
    cpaCampaign,
    affiliate,
    country: countryValue,
    conversionType,
    yesterdayConversionCount,
  }: FormObject = useSelector((state: State): FormObject => getFormData(state, FORM_KEY_SUBRATE));

  // calculated data
  const isNeedPreloadForm: boolean = Boolean(country && affId && offerId);
  const isDisabledFields: boolean = !!id;
  const isOfferSelect: boolean = !!cpaCampaign.value;
  const isRevshara: boolean = conversionType === "revshare";
  const redirectOnSave: string = `/subrates/info?affId=${affiliate.value}&offerId=${cpaCampaign.value}&country=${countryValue || COUNTRY_NULL_URL_VIEW}`;

  // dictionary
  const countriesList: Array<DropDownObjItemType> = useList("countriesList");

  // handlers
  const handleChangeCpaCampaignName: (Dropdown | string | Array<Dropdown | string> | null) => void = (option) => {
    if (!Array.isArray(option) && typeof option === "object") {
      dispatch(selectOfferForSubrateSaga(option === null ? { label: "", value: "" } : option));
    }
  };
  const handleChangeAffName: (Dropdown | string | Array<Dropdown | string> | null) => void = (option) => {
    dispatch(setFormData(FORM_KEY_SUBRATE, {
      affiliate: option || { label: "", value: "" },
    }));
  };
  // useEffects
  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_SUBRATE, id));
    }
    else if (isNeedPreloadForm) {
      dispatch(selectFieldByLocationForSubrateSaga({
        country, affId, offerId, shaveAmount, payoutAmount,
      }));
    }
  }, [affId, country, dispatch, id, isNeedPreloadForm, offerId, payoutAmount, shaveAmount]);

  const isError: mixed = useSelector((state) => getFormField(state, FORM_KEY_SUBRATE, "isError"));

  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_SUBRATE} validate={validation} redirectOnSave={redirectOnSave}>
      <FormHeader
        title={id ? `Edit subrate: ${String(id)}` : "Create subrate"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General options">
          <>
            <AutocompleteWithFetchForm
              name="cpaCampaignId"
              label="Offer name"
              disabled={isDisabledFields || loading}
              error={errors["cpaCampaign.value"] || errors.cpaCampaignId}
              value={cpaCampaign}
              onChange={handleChangeCpaCampaignName}
              fetchMethod={({ filter }: { filter: string, type: string }) => getSubratesDropdowns({
                type: "offer",
                field: "name",
                format: "id",
                filter,
              })}
              searchLimit={2}
            />
            <AutocompleteWithFetchForm
              name="affId"
              label="Affiliate name"
              error={errors["affiliate.value"] || errors.affId}
              value={affiliate}
              onChange={handleChangeAffName}
              fetchMethod={({ filter }: { filter: string, type: string }) => getSubratesDropdowns({
                type: "affiliate",
                field: "name",
                format: "id",
                filter,
              })}
              searchLimit={2}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {`(${option.value}) ${option.label}`}
                </li>
              )}
              disabled={isDisabledFields || loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: affiliate?.value && (
                      <InputAdornment position="end">
                        {`(${affiliate.value})`}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <SelectForm
              name="country"
              label="Country"
              options={countriesList}
              value={countryValue === null ? "All" : countryValue} // need remove this after refactor api
              disabled={isDisabledFields || !isOfferSelect}
            />
            <TextFieldForm
              name="utmContent"
              label="utm_content"
              disabled={isDisabledFields}
            />
            <TextFieldForm
              name="s3"
              label="s3"
              disabled={isDisabledFields}
            />
            <TextFieldForm
              name="shaveAmount"
              label="Shave %"
              type="number"
              inputProps={inputPercentRange}
            />
            {isOfferSelect && isRevshara && (
              <TextFieldForm
                name="payoutAmountFirst"
                label="Commission for firsts %"
                type="number"
                inputProps={inputPercentRange}
              />
            )}
            {isOfferSelect && isRevshara && (
              <TextFieldForm
                name="payoutAmountRepeats"
                label="Commission for repeats %"
                type="number"
                inputProps={inputPercentRange}
              />
            )}
            {isOfferSelect && !isRevshara && (
              <TextFieldForm
                name="increasedCommissionAmount"
                label="Increased commission"
                type="number"
                inputProps={inputNumberRange}
              />
            )}
            {isOfferSelect && !isRevshara && (
              <TextFieldForm
                name="increasedCommissionThreshold"
                label="Threshold"
                type="number"
                InputProps={{
                  inputProps: inputNumberRange,
                  endAdornment: <Box>{yesterdayConversionCount || 0}</Box>,
                }}
              />
            )}
            {isOfferSelect && !isRevshara && (
              <TextFieldForm
                name="payoutAmount"
                label="Commission"
                type="number"
                inputProps={inputNumberRange}
              />
            )}
          </>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default SubrateForm;
