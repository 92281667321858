// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers, applyMiddleware, createStore, compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  reducer as errors,
  initState as initErrorsState,
  type State as ErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import type { Actions as ErrorsAction } from "@fas/ui-framework/lib/redux/actions/errors";
import form, { initFormState } from "@fas/ui-framework/lib/redux/reducers/form";
import type { State as FormState } from "@fas/ui-framework/lib/redux/reducers/form/reducer";
import mainSaga from "./saga";

import loading, {
  type State as LoadingState,
  type Action as LoadingAction,
  initState as initLoadingState,
} from "../../reducers/loading";

import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import { FORM_KEY_PAYOUT_TERMS } from "../../helpers/constants/payoutTerms";

type LoadingTypes = "paymentMethods" | "currency" | "payoutTermSaving";
export type State = $ReadOnly<{|
  form: FormState,
  loading: LoadingState<LoadingTypes>,
  errors: ErrorsState,
  dictionaries: DictionariesState,
  notifications: NotificationsState,
|}>;

export function mapPageToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_PAYOUT_TERMS]: {
        id: "",
        payoutCompany: "",
        paymentsType: "",
        affiliateType: "",
        paymentMethod: "",
        paymentMaxAmount: "",
        paymentMinAmount: "",
        currency: "",
      },
    }),
    loading: initLoadingState<LoadingTypes>({
      paymentMethods: false,
      currency: false,
      payoutTermSaving: false,
    }),
    errors: initErrorsState(),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
  };
}

export type Actions =
  | LoadingAction<LoadingTypes>
  | ErrorsAction
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  loading,
  errors,
  dictionaries,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  })
    : compose;

  const store: Store<State, Actions> = createStore<
    State,
    Actions,
    Dispatch<Actions>
  >(
    reducers,
    mapPageToState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
