/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  takeEvery,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTableIsGlobalSelected,
  getTableFilters,
  getTableSelections,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setTableSelection, changeTableIsGlobalSelected } from "@fas/ui-framework/lib/redux/actions/table";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { MASS_ADJUST_SUBRATES_SAGA, SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import { saveMassAdjustApi } from "../../services/subratesApi";
import type { MassAdjustSubratesSagaType } from "../../actions/subrates";
import { getSubratesInfoListSaga } from "../../actions/subrates";
import type { SubrateDataObjType } from "../../reducers/subrates";

export function* makeFetch(action: MassAdjustSubratesSagaType): Saga<void> {
  const selected: SubrateDataObjType[] = yield select(getTableSelections, SUBRATES_INFO_TABLE);
  const isGlobalSelected: boolean = yield select(getTableIsGlobalSelected, SUBRATES_INFO_TABLE);
  const filters: Filters = yield select(getTableFilters, SUBRATES_INFO_TABLE);

  const transformedFilters = {};

  if (isGlobalSelected) {
    Object.keys(filters).forEach((key) => {
      const item = filters[key];
      if (Array.isArray(item) && !item.length) {
        return;
      }
      if (typeof item === "object" && !Array.isArray(item) && item.value) {
        transformedFilters[key] = item.value;
        return;
      }
      if (item === "all") {
        transformedFilters[key] = null;
        return;
      }
      // $FlowFixMe
      if (typeof item === "object" && ["payoutAmount", "shaveAmount"].includes(key) && item.condition === "") {
        transformedFilters[key] = "";
        return;
      }
      transformedFilters[key] = item;
    });
  }
  else {
    transformedFilters.id = selected.map(({ id }: SubrateDataObjType): string => String(id));
  }

  try {
    yield put(setLoading(SUBRATES_INFO_TABLE, true));

    yield call(saveMassAdjustApi, { filters: transformedFilters, data: action.payload });

    yield put(addNotification({ message: "Successfully updated", severity: "success" }));
    yield put(setTableSelection(SUBRATES_INFO_TABLE, []));
    yield put(changeTableIsGlobalSelected(SUBRATES_INFO_TABLE, false));
    yield put(getSubratesInfoListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save mass adjust", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SUBRATES_INFO_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(MASS_ADJUST_SUBRATES_SAGA, makeFetch);
}
