// @flow
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CHANGE_PRODUCT_COMPANY_ACTIVES_SAGA, PRODUCT_COMPANY_LIST_TABLE } from "../../helpers/constants/productCompany";
import { saveMassAdjustApi } from "../../services/productCompanyApi";
import type { ChangeProductCompanyActivesSaga } from "../../actions/productCompany";
import { getProductCompanyListSaga } from "../../actions/productCompany";
import type { ProductCompanyType } from "../../containers/ProductCompanyList/types/ProductCompanyList.types";

export function* changeProductCompanyActives({ isActive }: ChangeProductCompanyActivesSaga): Saga<void> {
  try {
    yield put(setLoading(PRODUCT_COMPANY_LIST_TABLE, true));

    const selections: ProductCompanyType[] = yield select(getTableSelections, PRODUCT_COMPANY_LIST_TABLE);
    yield call(saveMassAdjustApi, selections.map(({ id }: ProductCompanyType): { id: string, isActive: boolean } => ({
      id,
      isActive,
    })));

    yield put(setTableSelection(PRODUCT_COMPANY_LIST_TABLE, []));
    yield put(getProductCompanyListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Active status change failed", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(PRODUCT_COMPANY_LIST_TABLE, false));
  }
}

export default function* watchChangeProductCompanyActives(): Saga<void> {
  yield takeEvery(CHANGE_PRODUCT_COMPANY_ACTIVES_SAGA, (changeProductCompanyActives: Function));
}
