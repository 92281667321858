// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  reducer as errors,
  initState as initErrorsState,
  type State as ErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import type {
  Actions as ErrorsActions,
} from "@fas/ui-framework/lib/redux/actions/errors";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import form, { initFormState } from "@fas/ui-framework/lib/redux/reducers/form";
import type { State as FormState } from "@fas/ui-framework/lib/redux/reducers/form/reducer";
import mainSaga from "./saga";
import loading, {
  type State as LoadingState,
  type Action as LoadingAction,
  initState as initLoadingState,
} from "../../reducers/loading";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import type { LoadingTypes } from "../../selectors/loading/types";
import type { SetDictionary as DictionaryActions } from "../../actions/dictionary";
import { FORM_KEY_SMU_BRAND } from "../../helpers/constants/smu";

export type State = $ReadOnly<{|
  form: FormState,
  errors: ErrorsState,
  loading: LoadingState<LoadingTypes>,
  dictionaries: DictionariesState,
  notifications: NotificationsState,
|}>

export function mapPageToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_SMU_BRAND]: {
        id: "",
        companyId: "",
        domain: "",
        vertical: "",
        niche: "",
        country: "",
        url: "",
        banner: "",
        isActive: false,
      },
    }),
    errors: initErrorsState(),
    loading: initLoadingState({
      getSmuBrandLoading: false,
      getSmuBrandFormDropdownListLoading: false,
      saveSmuBrandLoading: false,
    }),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
  };
}

export type Actions =
  ErrorsActions
  | LoadingAction<LoadingTypes>
  | DictionaryActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  errors,
  loading,
  dictionaries,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPageToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
