// @flow
import React, { useMemo } from "react";
import NotificationsContainer from "../../components/NotificationsContainer";
import DashboardContent from "./components/DashboardContent";
import configureStore from "./store";
import { Provider } from "react-redux";

const Dashboard = () => {
  const store = useMemo(() => configureStore(), []);
  return (
    <Provider store={store}>
      <NotificationsContainer />
      <DashboardContent />
    </Provider>
  );
}

export default Dashboard;
