// @flow
import {
  SET_AFFILIATE_LINKS,
  SET_AFFILIATE_LINK_FORM_DATA,
  GET_AFFILIATE_LINKS_SAGA,
  SAVE_AFFILIATE_LINK_SAGA,
  REMOVE_AFFILIATE_LINK_SAGA,
  OPEN_AFFILIATE_LINK_MODAL,
  CLOSE_AFFILIATE_LINK_MODAL,
} from "../../helpers/constants/affiliateLinks";
import {
  SET_LOADER_MANAGE_AFFILIATE,
} from "../../helpers/constants/manageAffiliate";
import type {
  Link,
  Loader,
  SetAffiliateLinksAction,
  SetLoaderAction,
  GetAffiliateLinksSaga,
  SaveAffiliateLinkSaga,
  RemoveAffiliateLinkSaga,
  OpenAffiliateLinkModal,
  CloseAffiliateLinkModal,
  SetAffiliateLinkFormData,
} from "./types";

export const setAffiliateLinks = (
  value: Array<Link>
): SetAffiliateLinksAction => ({
  type: SET_AFFILIATE_LINKS,
  value,
});

export const setLoader = (
  value: Loader
): SetLoaderAction => ({
  type: SET_LOADER_MANAGE_AFFILIATE,
  value,
});

export const getAffiliateLinksSaga = (): GetAffiliateLinksSaga => ({
  type: GET_AFFILIATE_LINKS_SAGA,
});

export const removeAffiliateLinkSaga = (id: number): RemoveAffiliateLinkSaga => ({
  type: REMOVE_AFFILIATE_LINK_SAGA,
  id,
});

export const saveAffiliateLinkSaga = (): SaveAffiliateLinkSaga => ({
  type: SAVE_AFFILIATE_LINK_SAGA,
});

export const setAffiliateLinkFormData: ($Shape<Link>) => SetAffiliateLinkFormData = (value) => ({
  type: SET_AFFILIATE_LINK_FORM_DATA,
  value,
});

export const openAffiliateLinkModal: () => OpenAffiliateLinkModal = () => ({
  type: OPEN_AFFILIATE_LINK_MODAL,
});

export const closeAffiliateLinkModal: () => CloseAffiliateLinkModal = () => ({
  type: CLOSE_AFFILIATE_LINK_MODAL,
});
