// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import SmuBrandFormContainer from "../../containers/SmuBrandForm";

function SmuBrandForm() {
  const store = useMemo(() => configureStore());

  return (
    <Provider store={store}>
      <NotificationsContainer />
      <SmuBrandFormContainer />
    </Provider>
  );
}

export default SmuBrandForm;
