// @flow
import type { RecordOf, RecordFactory } from "immutable";
import {
  Record,
  Map,
  fromJS,
  List,
} from "immutable";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import { SET_DICTIONARY } from "../../helpers/constants/dictionary";

export type DropDownObjItemType = Option;

export type DropDownObjType = {
  [string]: Array<DropDownObjItemType>
}

export type DropDownType = Map<string, List<DropDownObjItemType>>;
export type DefaultStateType = {
  dropdownLists: DropDownType,
};

const defaultState: DefaultStateType = {
  dropdownLists: Map({}),
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export function initState(initialLists: DropDownObjType = {}): State {
  // $FlowFixMe
  return initialState.set("dropdownLists", initialState.get("dropdownLists", Map()).merge(fromJS(initialLists)));
}

type Action = any;

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_DICTIONARY: {
      // $FlowFixMe
      return state.set("dropdownLists", state.get("dropdownLists", Map()).merge(fromJS(action.payload)));
    }
    default:
      return state;
  }
};
