// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetOffergroupList from "../../sagas/getOfferGroupsList";
import watchGetOfferNameList from "../../sagas/getOfferNameList";
import watchGetCpaOffersLists from "../../sagas/getCpaOffersLists";
import watchGetCpaOffer from "../../sagas/getCpaOffer";
import watchSaveCpaOffer from "../../sagas/saveCpaOffer";
import watchResetGlobalCapSaga from "../../sagas/resetGlobalCap";
import watchChangeCpaOfferStatus from "../../sagas/changeCpaOfferStatus";
import watchCheckValidationStatus from "../../sagas/checkValidationStatus";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetOffergroupList),
    call(watchGetOfferNameList),
    call(watchGetCpaOffersLists),
    call(watchGetCpaOffer),
    call(watchSaveCpaOffer),
    call(watchResetGlobalCapSaga),
    call(watchChangeCpaOfferStatus),
    call(watchCheckValidationStatus),
  ]);
}
