// @flow
import React, { useRef, useEffect } from "react";
import { TextField, InputAdornment, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonalInfoTablet from "../../../PersonalInfoTablet";

type Props = {
  title: string,
  name: string,
  editTitle: string,
  currentTablet: string,
  personalInfo: Object,
  lists: Array<any>,
  errors: Object,
  fields: Array<string>,
  handleChangeTablet: () => any,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectLabel: {
    marginLeft: 45,
    zIndex: 1,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  helperIcon: {
    marginLeft: 5,
  },
}));

const AffiliateAddress = ({
  title,
  name,
  editTitle,
  currentTablet,
  personalInfo,
  lists,
  errors,
  fields,
  handleChangeTablet,
  setAffiliateField,
}: Props) => {
  const classes = useStyles();

  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const zipRef = useRef(null);
  const stateRef = useRef(null);
  const taxSSNRef = useRef(null);
  const countryByIpRef = useRef(null);

  const {
    address,
    city,
    state,
    country,
    zip,
    taxSSN,
    countryByIp,
  } = personalInfo;
  const { countries = [] } = lists;

  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setAffiliateField(name, value);
  };
  const handleCountries = (e, item) => setAffiliateField("country", item);
  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Some text">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };

  useEffect(() => {
    if (addressRef.current) {
      addressRef.current.value = address;
    }
    if (cityRef.current) {
      cityRef.current.value = city;
    }
    if (zipRef.current) {
      zipRef.current.value = zip;
    }
    if (stateRef.current) {
      stateRef.current.value = state;
    }
    if (taxSSNRef.current) {
      taxSSNRef.current.value = taxSSN;
    }
    if (countryByIpRef.current) {
      countryByIpRef.current.value = countryByIp;
    }
  });

  return (
    <PersonalInfoTablet
      title={title}
      name={name}
      currentTablet={currentTablet}
      editTitle={editTitle}
      handleChangeTablet={handleChangeTablet}
      errors={errors}
      fields={fields}
    >
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Address"
        error={!!errors.address}
        helperText={errors.address && errors.address}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={addressRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="address"
        data-testid="address"
        onBlur={handleChangeField}
        placeholder="Address"
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="City"
        error={!!errors.city}
        helperText={errors.city && errors.city}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={cityRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="city"
        data-testid="city"
        onBlur={handleChangeField}
        placeholder="City"
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Zip"
        error={!!errors.zip}
        helperText={errors.zip && errors.zip}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={zipRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="Zip"
        name="zip"
        data-testid="zip"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="State"
        error={!!errors.state}
        helperText={errors.state && errors.state}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={stateRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        name="state"
        data-testid="state"
        onBlur={handleChangeField}
        placeholder="State"
        InputProps={inputPropsTextField}
      />
      <Autocomplete
        className={classes.inputField}
        fullWidth
        classes={{
          inputRoot: classes.input,
        }}
        options={countries}
        value={country}
        data-testid="country"
        onChange={handleCountries}
        getOptionLabel={(option) => option.country_name}
        isOptionEqualToValue={(opt, val) => opt.country_short_name === val.country_short_name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Country"
            variant="outlined"
            label="Country"
            error={!!errors.country}
            helperText={errors.country && errors.country}
            InputProps={{
              ...params.InputProps,
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    className={classes.helperIcon}
                    position="start"
                  >
                    <Tooltip title="Some text">
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            FormHelperTextProps={{
              style: { marginLeft: 0, marginRight: 0 },
            }}
          />
        )}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Tax ssn/ein (usa only)"
        error={!!errors.taxSSN}
        helperText={errors.taxSSN && errors.taxSSN}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={taxSSNRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="Tax ssn/ein (usa only)"
        name="taxSSN"
        data-testid="taxSSN"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
      <TextField
        className={classes.inputField}
        fullWidth
        variant="outlined"
        label="Country by ip"
        disabled
        error={!!errors.countryByIp}
        helperText={errors.countryByIp && errors.countryByIp}
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={countryByIpRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        placeholder="Country by ip"
        name="countryByIp"
        data-testid="countryByIp"
        onBlur={handleChangeField}
        InputProps={inputPropsTextField}
      />
    </PersonalInfoTablet>
  );
};

export default AffiliateAddress;
