// @flow
import { GET_AFFILIATE_SMART_OFFERS_LIST_SAGA, DELETE_AFFILIATE_SMART_OFFERS_SAGA } from "../../helpers/constants/affiliateSmartOffers";

export type GetAffiliateSmartOffersListSagaType = {
  type: typeof GET_AFFILIATE_SMART_OFFERS_LIST_SAGA,
}
export type DeleteAffiliateSmartOffersSagaType = {
  type: typeof DELETE_AFFILIATE_SMART_OFFERS_SAGA,
}

export type Action = GetAffiliateSmartOffersListSagaType | DeleteAffiliateSmartOffersSagaType;

export function getAffiliateSmartOffersListSaga(): GetAffiliateSmartOffersListSagaType {
  return {
    type: GET_AFFILIATE_SMART_OFFERS_LIST_SAGA,
  };
}

export function deleteAffiliateSmartOffersSaga(): DeleteAffiliateSmartOffersSagaType {
  return {
    type: DELETE_AFFILIATE_SMART_OFFERS_SAGA,
  };
}
