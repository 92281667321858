// @flow
import React from "react";
import { TextField, InputAdornment, Tooltip, Chip, FormControl, FormHelperText, Box } from "@mui/material";
import { Multiselect } from "@fas/ui-core"
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from '@mui/material/Autocomplete';
import { getFormErrors } from "./checkForm";
import type { Errors } from "./checkForm";
import type { CpaOfferLists } from "../../../../../reducers/cpaoffer/types";

type Props = {
  row: any,
  lists: CpaOfferLists,
  onChange: any,
  offerType: string,
  errors: Errors,
};

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.background.mainBg,
  },
  presets: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

const Targetings = (props: Props) => {
  const classes = useStyles();
  const {
    row,
    lists,
    errors,
  } = props;
  const {
    country,
    platform,
    genders,
  } = lists;
  const formErrors = getFormErrors(errors);
  const tooltipSelect = (params) => ({
    ...params.InputProps,
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title="Some text">
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
        {params.InputProps.startAdornment}
      </>
    ),
  });

  const countries = country.map((i) => i.country_short_name);
  const selectAll = () => props.onChange("country", countries);

  const unSelectAll = () => props.onChange("country", []);

  return (
    <Box mb={3} width={1}>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.country}
        variant="standard">
        <Multiselect
          label="Country"
          size="normal"
          limitTags={20}
          InputLabelProps={{
            shrink: true,
          }}
          classes={{
            inputRoot: classes.input,
          }}
          error={{
            isError: !!formErrors.country,
            helperText: "",
          }}
          type="simpleMultiple"
          options={countries.map((item) => ({ value: item, label: item }))}
          selectedOptions={row.country.map((item) => ({ label: item, value: item }))}
          onChange={( value ) => props.onChange("country", value.map((item) => item.value))}
          onRemoveValue={( value ) => props.onChange("country", row.country.filter((item) => item !== value ))}
          dataTestId="country"
        />
        <FormHelperText>{formErrors.country}</FormHelperText>
      </FormControl>
      <div className={classes.presets}>
        <Chip color="primary" size="small" label="Select all" onClick={selectAll} />
        <Chip color="primary" size="small" label="Unselect all" onClick={unSelectAll} />
      </div>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.platform}
        variant="standard">
        <Autocomplete
          multiple
          fullWidth
          disableClearable
          options={platform}
          getOptionLabel={(option) => option}
          onChange={(e, value) => props.onChange("platform", value)}
          value={row.platform}
          data-testid="platform"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Platform"
              variant="outlined"
              margin="normal"
              InputProps={tooltipSelect(params)}
              className={classes.input}
              error={!!formErrors.platform}
            />
          )}
        />
        <FormHelperText>{formErrors.platform}</FormHelperText>
      </FormControl>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.gender}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={genders}
          getOptionLabel={(option) => option}
          onChange={(e, value) => props.onChange("gender", value)}
          value={row.gender}
          data-testid="gender"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Gender"
              variant="outlined"
              margin="normal"
              InputProps={tooltipSelect(params)}
              className={classes.input}
              error={!!formErrors.gender}
            />
          )}
        />
        <FormHelperText>{formErrors.gender}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default Targetings;
