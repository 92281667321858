// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { AFFILIATE_LINKS_TABLE, GET_AFFILIATE_LINKS_SAGA } from "../../helpers/constants/affiliateLinks";
import { fecthAffiliateLinks } from "../../services/affiliateLinksApi";
import { getAffiliateId } from "../../selectors/affiliateLinks";
import { type Link } from "../../actions/affiliateLinks";

export function* getAffiliateLinks(): Saga<void> {
  const affiliateId: string = yield select(getAffiliateId);
  yield put(setLoading(AFFILIATE_LINKS_TABLE, true));
  try {
    const { payload: affiliateLinks }: { payload: Array<Link> } = yield call(fecthAffiliateLinks, affiliateId);
    yield put(setTableData(AFFILIATE_LINKS_TABLE, affiliateLinks));
    yield put(changeTableItemsTotalAmount(AFFILIATE_LINKS_TABLE, affiliateLinks.length));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch affiliate links", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(AFFILIATE_LINKS_TABLE, false));
  }
}

export function* watchGetAffiliateLinks(): Saga<void> {
  yield takeEvery(GET_AFFILIATE_LINKS_SAGA, (getAffiliateLinks: Function));
}
