// @flow
import React, {
  type Element,
} from "react";
import {
  SimpleMultiselect,
  ViewLink,
} from "@fas/ui-core";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";

import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { Dropdown } from "@fas/ui-core/lib/SearchComponentsV2/helpers/types";
import type { CrmTemplateListData, Classes } from "./CrmTemplatesList.types";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

function convertOptionToDropdown(option: DropDownObjItemType): Dropdown {
  // $FlowFixMe MTU-94094
  return option;
}

export default function getColumns(
  classes: Classes,
  dropdowns: {
    productTypes: DropDownObjItemType[],
    localeTypes: DropDownObjItemType[],
  }
): Array<Column<CrmTemplateListData>> {
  const { productTypes, localeTypes } = dropdowns;
  const productTypesMap = productTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {});

  return [
    {
      field: "id",
      label: "ID",
      sortable: true,
      className: classes.smallTableCol,
      render: ({ id }: CrmTemplateListData): Element<typeof ViewLink> => <ViewLink id={String(id)} name={String(id)} viewPath="/crm/templates/:id" />,
    },
    {
      field: "name",
      label: "Name",
      sortable: true,
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "defaultLocale",
      label: "Default locale",
      sortable: true,
      searchable: true,
      className: classes.smallTableCol,
      SearchComponent: ({ value, onChange }: SearchComponentProps): Element<typeof SimpleMultiselect> => (
        <SimpleMultiselect
          selectedValues={value || []}
          onChange={onChange}
          options={localeTypes.map(convertOptionToDropdown)}
        />
      ),
    },
    {
      field: "createdAt",
      label: "Created at",
      sortable: true,
      className: classes.smallTableCol,
    },
    {
      field: "productId",
      label: "Product",
      searchable: true,
      sortable: true,
      className: classes.middleTableCol,
      SearchComponent: ({ value, onChange }: SearchComponentProps): Element<typeof SimpleMultiselect> => (
        <SimpleMultiselect
          selectedValues={value || []}
          onChange={onChange}
          options={productTypes.map(convertOptionToDropdown)}
        />
      ),
      render: ({ productId }: CrmTemplateListData): string => productTypesMap[productId] || "-",
    },
  ];
}
