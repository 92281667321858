// eslint-disable-next-line import/prefer-default-export
export const mapManageAffiliates = (state) => {
  const {
    personalInfo,
    paymentInfo,
    affiliateId,
    loaders: { isPersonalInfoFetcing },
    affType,
  } = state.manageAffiliate.toJS();
  return {
    personalInfo,
    paymentInfo,
    affiliateId,
    isPersonalInfoFetcing,
    affType,
  };
};
