// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";

export const getPayoutCompanies: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.getPayoutCompanies);

export const getCurrencies: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.getCurrencies);

export const getPaymentMethods: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.getPaymentMethods);

export const getAffiliateTypes: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.getAffiliateTypes);

export const getPaymentTypes: () => PromiseResponse<mixed> = () => requestService
  .get(environment.endpoints.get.getPaymentsTypes);

export const getPayoutTerms: (string) => * = (id) => requestService
  .get(environment.endpoints.get.getPayoutTermsById.replace("{id}", id))
  .then(({ data: { data } }) => {
    const {
      affiliateType,
      currency,
      paymentMethod,
      paymentMaxAmount,
      paymentMinAmount,
      paymentsType,
      payoutCompany,
    } = data;

    return {
      id,
      affiliateType,
      paymentsType,
      paymentMethod: paymentMethod.id,
      paymentMethodTitle: paymentMethod.name,
      payoutCompany: payoutCompany.id,
      payoutCompanyTitle: payoutCompany.name,
      currency: currency.id,
      currencyTitle: currency.currency,
      paymentMaxAmount,
      paymentMinAmount,
    };
  });

export const changePayoutTermsActive: (string[]) => PromiseResponse<mixed> = (ids) => requestService
  .put(environment.endpoints.put.changePayoutTermsActive, { ids });

export const savePayoutTerm: (*) => PromiseResponse<mixed> = (
  {
    id,
    affiliateType,
    currency,
    paymentMethod,
    paymentMaxAmount,
    paymentMinAmount,
    paymentsType,
    payoutCompany,
  }
) => {
  if (id) {
    return requestService.post(`${environment.endpoints.post.savePayoutTerm}/${id}`, {
      paymentMinAmount: paymentMinAmount ? Number(paymentMinAmount) : null,
      paymentMaxAmount: paymentMaxAmount ? Number(paymentMaxAmount) : null,
      payoutCompany,
    });
  }

  return requestService.post(`${environment.endpoints.post.savePayoutTerm}`, {
    paymentsType: paymentsType || null,
    affiliateType: affiliateType || null,
    paymentMethod: paymentMethod || null,
    paymentMinAmount: paymentMinAmount ? Number(paymentMinAmount) : null,
    paymentMaxAmount: paymentMaxAmount ? Number(paymentMaxAmount) : null,
    currency: currency || null,
    payoutCompany: payoutCompany || null,
    isActive: 1,
  });
};

export const fetchPayoutTermsList: ({
  page: number,
  limit: number,
  filters: Filters,
  sorting: Sorting,
}) => PromiseResponse<mixed, { data: *[], count: number }> = (params) => {
  const [[current, direction] = []] = Object.entries(params.sorting);

  return requestService
    .post(environment.endpoints.post.getPayoutTermsList, {
      ...params,
      filters: Object.keys(params.filters).reduce((acc, key) => { // todo remove cast in MTU-72544
        const numberKeys: string[] = ["id"];
        const val = numberKeys.includes(key) ? parseFloat(params.filters[key]) : params.filters[key];
        return { ...acc, [key]: val };
      }, {}),
      sorting: current ? {
        current,
        direction,
      } : {},
    });
};
