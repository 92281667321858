// @flow
import {
  call,
  debounce, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { changeTableItemsTotalAmount, setTableData } from "@fas/ui-framework/lib/redux/actions/table";
import { GET_UTM_CAMPAIGN_LIST_SAGA, UTM_CAMPAIGN_TABLE } from "../../helpers/constants/utmCampaign";
import { fetchUtmCampaignList } from "../../services/utmCampaignDictionariesApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(UTM_CAMPAIGN_TABLE, true));

    const page: number = yield select(getTablePage, UTM_CAMPAIGN_TABLE);
    const limit: number = yield select(getTablePageSize, UTM_CAMPAIGN_TABLE);
    const sorting: Sorting = yield select(getTableSorting, UTM_CAMPAIGN_TABLE);
    const filters: Filters = yield select(getTableFilters, UTM_CAMPAIGN_TABLE);

    const {
      data,
      count = 0,
    }: { data: *[], count: number } = yield call(fetchUtmCampaignList, {
      page,
      limit,
      filters,
      sorting,
    });

    yield put(setTableData(UTM_CAMPAIGN_TABLE, data));
    yield put(changeTableItemsTotalAmount(UTM_CAMPAIGN_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch utm campaign", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(UTM_CAMPAIGN_TABLE, false));
  }
}

export default function* watchGetUtmCampaignListSaga(): Saga<void> {
  yield debounce(1000, GET_UTM_CAMPAIGN_LIST_SAGA, (makeFetch: Function));
}
