// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { Box } from "@mui/material";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import TopOffersList from "../../containers/TopOffersList";

function TopOffers() {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="CPA - TopOffers">
      <Provider store={store}>
        <NotificationsContainer />
        <Box p={2}>
          <TopOffersList />
        </Box>
      </Provider>
    </DocumentTitle>
  );
}

export default TopOffers;
