// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSaga } from "../../sagas/getTableSaga";
import watchGetDropdownsStaticSaga from "../../sagas/getDropdownsStatic";
import watchDeleteCrmTemplates from "../../sagas/deleteCrmTemplates";
import { CRM_TEMPLATES_TABLE } from "../../helpers/constants/crm";
import { fetchCrmTemplatesList } from "../../services/crmTemplatesApi";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";

const watchGetCrmTemplatesList = getTableSaga({ tableName: CRM_TEMPLATES_TABLE, fetchMethod: fetchCrmTemplatesList });

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetCrmTemplatesList),
    call(watchDeleteCrmTemplates),
    call(watchGetDropdownsStaticSaga),
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
  ]);
}
