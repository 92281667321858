// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetManageAffiliatesDataSaga from "../../sagas/getManageAffiliatesData";
import watchGetPersonalInfoListsSaga from "../../sagas/getPersonalInfoLists";
import watchBulkChangeAffiliateStatus from "../../sagas/bulkChangeAffiliateStatus";
import watchChangeAffiliateStatusSaga from "../../sagas/changeAffiliateStatus";
import watchLoginAsAffiliateSaga from "../../sagas/loginAsAffiliate";
import watchGenerateAutoLoginLinkSaga from "../../sagas/generateAutoLoginLink";
import watchGetManageAffiliateTemplate from "../../sagas/getManageAffiliateTemplates";
import watchSaveTemplateManageAffiliates from "../../sagas/saveTemplateManageAffiliates";
import watchGetAuthorsList from "../../sagas/getAuthorsList";
import watchBulkChangeAuthor from "../../sagas/bulkChangeAuthor";
import watchGetManagersListByAffiliateType from "../../sagas/getManagersListByAffiliateType";
import watchGetDropdownsByAffiliateTypeSaga from "../../sagas/getDropdownsByAffiliateType";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetManageAffiliatesDataSaga),
    call(watchGetPersonalInfoListsSaga),
    call(watchBulkChangeAffiliateStatus),
    call(watchChangeAffiliateStatusSaga),
    call(watchLoginAsAffiliateSaga),
    call(watchGenerateAutoLoginLinkSaga),
    call(watchGetManageAffiliateTemplate),
    call(watchSaveTemplateManageAffiliates),
    call(watchGetAuthorsList),
    call(watchGetAuthorsList),
    call(watchBulkChangeAuthor),
    call(watchGetManagersListByAffiliateType),
    call(watchGetDropdownsByAffiliateTypeSaga),
  ]);
}
