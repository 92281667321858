// @flow
import { SelectPaper } from "@fas/ui-core";
import { ClickAwayListener } from "@mui/material";
import React from "react";
import Styles from "../../containers/ManageAffiliate/components/Styles/Styles";

type Props = {
  onClickAway: () => void,
  templatesList: Array<any>,
  onSearchStringChange: (string) => void,
  onAddNew: () => void,
  onSelect: (any) => void,
};

const TemplatesList = (props: Props) => {
  const classes = Styles();
  const {
    templatesList, onClickAway, onSearchStringChange, onAddNew, onSelect, isLoadingTemplate,
  } = props;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={classes.templatesList}>
        <SelectPaper
          searchStringPlaceholder="Search template"
          list={templatesList}
          onSearchStringChange={onSearchStringChange}
          onAddNew={onAddNew}
          addButtonText="New Template"
          onSelect={onSelect}
          loading={isLoadingTemplate}
        />
      </div>
    </ClickAwayListener>
  );
};

export default TemplatesList;
