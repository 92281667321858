// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { SAVE_AFFILIATE_LINK_SAGA } from "../../helpers/constants/affiliateLinks";
import { getAffiliateId, getAffiliateLinkFormData } from "../../selectors/affiliateLinks";
import { saveAffiliateLink as fetchSaveAffiliateLink } from "../../services/affiliateLinksApi";
import {
  getAffiliateLinksSaga,
  setLoader,
  closeAffiliateLinkModal,
  type Link,
} from "../../actions/affiliateLinks";

export function* saveAffiliateLink(): Saga<void> {
  const affiliateId: string = yield select(getAffiliateId);
  const { id, ...fields }: Link = yield select(getAffiliateLinkFormData);
  yield put(setLoader({
    key: "isAffiliateLinkSaveLoading",
    value: true,
  }));

  try {
    if (id) {
      yield call(fetchSaveAffiliateLink, affiliateId, { ...fields, id });
    }
    else {
      yield call(fetchSaveAffiliateLink, affiliateId, fields);
    }
    yield put(addNotification({ message: "Successfully saved", severity: "success" }));
    yield put(closeAffiliateLinkModal());
    yield put(setErrors({}));
    yield put(getAffiliateLinksSaga());
  }
  catch (error) {
    const errorsArr: Array<{ field: string, error: string }> = error?.response?.data?.errors;
    if (errorsArr) {
      yield put(setErrors(prepareFetchErrors(error.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: error.errorMessage || "Failed to fetch save link", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  finally {
    yield put(setLoader({
      key: "isAffiliateLinkSaveLoading",
      value: false,
    }));
  }
}

export function* watchSaveAffiliateLink(): Saga<void> {
  yield takeEvery(SAVE_AFFILIATE_LINK_SAGA, (saveAffiliateLink: Function));
}
