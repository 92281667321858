// @flow
import React from "react";
import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

type Props = {
  row: object,
};

const useStyles = makeStyles(() => ({
  payout: {
    fontSize: "inherit",
  },
}));

const PayoutCell = ({
  row,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.payout}
    >
      {row.conversionType === "revshare" ? row.payoutAmountFirst : row.payoutAmount}
    </Typography>
  );
};

export default PayoutCell;
