// @flow
import {
  select,
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { SAVE_API_KEY_SAGA } from "../../helpers/constants/apiPage";
import { getManageAffiliateId, getManageAffiliateApiKey } from "../../selectors/manageAffiliate";
import { saveKey } from "../../services/manageAffiliateApi";
import { getApiIpSaga } from "../../actions/api";

export function* makeFetch(): Saga<void> {
  const affiliateId = yield select(getManageAffiliateId);
  const fields = yield select(getManageAffiliateApiKey);
  try {
    yield call(saveKey, affiliateId, fields);
    yield put(addNotification({ message: "Successfully saved", severity: "success" }));
    yield put(getApiIpSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    yield put(setErrors({ ipWhiteList: { message: error.response.data.messages.error } }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveApiKey(): Saga<void> {
  yield takeEvery(SAVE_API_KEY_SAGA, (makeFetch: Function));
}
