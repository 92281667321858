// @flow
import React, { type Node } from "react";
import { AutocompleteWithFetch, NumberSearchComponent, SelectSearchComponent } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import moment from "moment";
import { fetchCpaAdvertiser } from "../../services/advertiserInvoicesApi";
import type { AdvertiserInvoicesType, Classes } from "./types/AdvertiserInvoices.types";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

export default function getColumns(
  classes: Classes,
  paymentMethods: DropDownObjItemType[]
): Column<AdvertiserInvoicesType>[] {
  return [
    {
      field: "cpaAdvertiser",
      label: "Partner name",
      sortable: true,
      searchable: true,
      SearchComponent: ({ value, ...searchProps }) => (
        <AutocompleteWithFetch
          {...searchProps}
          selectedValue={value}
          // $FlowFixMe MTU-94094
          fetchMethod={fetchCpaAdvertiser}
        />
      ),
      className: classes.middleTableCol,
    },
    {
      field: "createdAt",
      label: "Created at",
      sortable: true,
      className: classes.middleTableCol,
    },
    {
      field: "paidAt",
      label: "Paid on",
      sortable: true,
      className: classes.middleTableCol,
      render: ({ paidAt }: AdvertiserInvoicesType): Node => (paidAt ? moment(paidAt).format("YYYY-MM-DD") : "-"),
    },
    {
      field: "payout",
      label: "Paid total",
      sortable: true,
      searchable: true,
      SearchComponent: NumberSearchComponent,
      className: classes.middleTableCol,
      render: ({ currency, payout }: AdvertiserInvoicesType): Node => `${currency} ${payout}`,
    },
    {
      field: "paymentMethod",
      label: "Payment method",
      sortable: true,
      searchable: true,
      SearchComponent: (searchProps) => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={paymentMethods}
        />
      ),
      className: classes.middleTableCol,
    },
    {
      field: "periodFrom",
      label: "Date from",
      sortable: true,
      className: classes.middleTableCol,
      render: ({ periodFrom }: AdvertiserInvoicesType): Node => moment(periodFrom).format("YYYY-MM-DD"),
    },
    {
      field: "periodTo",
      label: "Date to",
      sortable: true,
      className: classes.middleTableCol,
      render: ({ periodTo }: AdvertiserInvoicesType): Node => moment(periodTo).format("YYYY-MM-DD"),
    },
    {
      field: "status",
      label: "Status",
      searchable: true,
      SearchComponent: (searchProps) => (
        <SelectSearchComponent
          {...searchProps}
          values={[{ label: "Pending", value: "pending" }, { label: "Paid", value: "paid" }, { label: "Declined", value: "declined" }]}
        />
      ),
      className: classes.middleTableCol,
    },
    {
      field: "updatedAt",
      label: "Updated at",
      sortable: true,
      className: classes.middleTableCol,
    },
    {
      field: "updatedBy",
      label: "Updated by",
      sortable: true,
      className: classes.middleTableCol,
      render: ({ updatedByUser }: AdvertiserInvoicesType): Node => updatedByUser,
    },
    {
      field: "createdBy",
      label: "Created by",
      sortable: true,
      className: classes.middleTableCol,
      render: ({ createdByUser }: AdvertiserInvoicesType): Node => createdByUser,
    },
  ];
}
