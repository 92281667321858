// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { DELETE_POTENTIAL_PARTNER_SAGA, POTENTIAL_PARTNER_TABLE } from "../../helpers/constants/potentialPartner";
import { getPotentialPartnerListSaga } from "../../actions/potentialPartner";
import { fetchRemovePotentialPartner } from "../../services/potentialPartnerApi";
import type { PotentialPartnerType } from "../../containers/PotentialPartner/types/PotentialPartner.types";
import type { State } from "../../pages/PotentialPartner";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(POTENTIAL_PARTNER_TABLE, true));
    const selections: PotentialPartnerType[] = yield select((
      state: State
    ): PotentialPartnerType[] => getTableSelections(state, POTENTIAL_PARTNER_TABLE));
    if (selections.length <= 0) {
      return;
    }

    yield call(fetchRemovePotentialPartner, selections.map(({ id }: PotentialPartnerType): string => id));

    yield put(addNotification({ message: "Removed", severity: "success" }));
    yield put(setTableSelection(POTENTIAL_PARTNER_TABLE, []));
    yield put(getPotentialPartnerListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch remove", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(POTENTIAL_PARTNER_TABLE, false));
  }
}

export default function* watchRemovePotentialPartnerSaga(): Saga<void> {
  yield takeEvery(DELETE_POTENTIAL_PARTNER_SAGA, (makeFetch: Function));
}
