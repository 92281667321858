// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetCpaOfferCampaignsList from "../../sagas/getCpaOfferCampaignsList";
import watchDownloadCpaOfferCampaigns from "../../sagas/downloadCpaOfferCampaigns";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetCpaOfferCampaignsList),
    call(watchDownloadCpaOfferCampaigns),
  ]);
}
