// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  name: Joi.string().required(),
  defaultLocale: Joi.string().required(),
  productId: Joi.number().required(),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "array.min": "This field is required",
  "object.base": "This field is required",
}).unknown();

export default joiResolver(schema);
