// @flow
import { GET_DICTIONARY_SAGA, SET_DICTIONARY, GET_DROPDOWNS_STATIC_SAGA } from "../../helpers/constants/dictionary";
import type { DropDownObjType } from "../../reducers/dictionaries";

export type SetDictionary = {|
  type: typeof SET_DICTIONARY,
  payload: DropDownObjType,
|}

export type DictionaryParams = {|
  dictionary: string,
  type: string,
  filter?: string,
  format?: string,
  field?: string,
|}

export type GetDictionarySaga = {|
  type: typeof GET_DICTIONARY_SAGA,
  params: DictionaryParams,
|}

export type GetDropdownsStaticSaga = {|
  type: typeof GET_DROPDOWNS_STATIC_SAGA,
  dictionaryName: string,
|}

export const setDictionary = (payload: DropDownObjType): SetDictionary => ({
  type: SET_DICTIONARY,
  payload,
});

export function getDictionarySaga(params: DictionaryParams): GetDictionarySaga {
  return {
    type: GET_DICTIONARY_SAGA,
    params,
  };
}

export function getDropdownsStaticSaga(dictionaryName: string): GetDropdownsStaticSaga {
  return {
    type: GET_DROPDOWNS_STATIC_SAGA,
    dictionaryName,
  };
}
