/* eslint-disable import/max-dependencies */
// @flow
import {
  call, debounce,
  put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import { setTableData, changeTableItemsTotalAmount } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_SUBRATES_INFO_LIST_SAGA, SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import { fetchSubratesList } from "../../services/subratesApi";
import { getSubratesInfoListTemplate } from "../../selectors/subrates";
import type { SubrateDataObjType } from "../../reducers/subrates";

export function* makeFetch(): Saga<void> {
  const page: number = yield select(getTablePage, SUBRATES_INFO_TABLE);
  const limit: number = yield select(getTablePageSize, SUBRATES_INFO_TABLE);
  const filters: Sorting = yield select(getTableFilters, SUBRATES_INFO_TABLE);
  const sorting: Filters = yield select(getTableSorting, SUBRATES_INFO_TABLE);

  const headers = yield select(getSubratesInfoListTemplate);

  const transformedSorting: {
    current: string,
    direction: string,
  } = {
    current: Object.keys(sorting)[0],
    // $FlowFixMe Object.value returns [mixed]
    direction: Object.values(sorting)[0],
  };
  // const jsFilters = filters.toJS();
  const transformedFilters = {};
  Object.keys(filters).forEach((key) => {
    const item = filters[key];
    if (Array.isArray(item) && !item.length) {
      return;
    }
    if (["offerPayoutAmount", "offerPayoutAmountFirst", "offerShaveAmount", "offerPayoutAmountRepeats"].includes(key)) {
      transformedFilters[key] = Number(item);
      return;
    }
    if (typeof item === "object" && !Array.isArray(item) && item.value) {
      transformedFilters[key] = item.value;
      return;
    }
    if (item === "all") {
      transformedFilters[key] = null;
      return;
    }
    if (typeof item === "object" && ["payoutAmount", "shaveAmount"].includes(key) && item.condition === "") {
      transformedFilters[key] = "";
      return;
    }
    transformedFilters[key] = item;
  });

  try {
    yield put(setLoading(SUBRATES_INFO_TABLE, true));

    const defaultSubrateFilters = {};

    ["affId", "country", "offerId"].forEach((key) => {
      defaultSubrateFilters[key] = transformedFilters[key] || "";
    });

    const {
      data: { data: subratesInfoStickListData },
    }: {
      data: {
        data: SubrateDataObjType[],
        count: number,
      }
      // $FlowFixMe
    } = yield call(fetchSubratesList, {
      headers: headers.headersOrder,
      filters: { ...defaultSubrateFilters, s3: null, utmContent: null },
      sorting: {},
      limit: 1,
      page: 1,
    });

    const {
      data: { data: subratesInfoListData, count },
    }: { data: { data: SubrateDataObjType[], count: number }} = yield call(fetchSubratesList, {
      headers: headers.headersOrder,
      filters: transformedFilters,
      sorting: transformedSorting,
      ignoreDefault: true,
      limit,
      page,
    });

    const data: SubrateDataObjType[] = subratesInfoStickListData
      .map((item: SubrateDataObjType): SubrateDataObjType => ({ ...item, isDefault: true }))
      .concat(subratesInfoListData);

    yield put(setTableData(SUBRATES_INFO_TABLE, data));
    yield put(changeTableItemsTotalAmount(SUBRATES_INFO_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch subrates list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SUBRATES_INFO_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_SUBRATES_INFO_LIST_SAGA, (makeFetch: Function));
}
