// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import SmuBrandsContainer from "../../containers/SmuBrandList";

function SmuBrandList() {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="SMU - Brands">
      <Provider store={store}>
        <NotificationsContainer />
        <SmuBrandsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default SmuBrandList;
