// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Dialog } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import MassAdjustModal from "../../components/MassAdjustModal";
import type { Props } from "./types";
import {
  massAdjustSubratesSaga,
  type MassAdjustSubratesSagaType,
} from "../../actions/subrates";

const massAdjustvalueList = [
  {
    title: "Commission", value: "payoutAmount", type: "number", min: 0, max: 99999999,
  },
  {
    title: "Increased commission", value: "increasedCommissionAmount", type: "number", min: 0, max: 99999999,
  },
  {
    title: "Treshold", value: "increasedCommissionThreshold", type: "number", min: 0, max: 99999999,
  },
  {
    title: "Shave", value: "shaveAmount", type: "number", min: 0, max: 99999999,
  },
  {
    title: "Commission for firsts %", value: "payoutAmountFirst", type: "number", min: 0, max: 100,
  },
  {
    title: "Commission for repeats %", value: "payoutAmountRepeats", type: "number", min: 0, max: 100,
  },
];

const MassAdjustContainer: StatelessFunctionalComponent<Props> = ({
  setIsMassAdjustOpen,
  isMassAdjustOpen,
}: Props) => {
  const {
    selected,
    isGlobalSelected,
    count,
  }: TableProps = useTable(SUBRATES_INFO_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onApplyMassAdjustSubrates: (fields: { [key: string]: string | number }) => MassAdjustSubratesSagaType = (
    fields
  ) => dispatch(massAdjustSubratesSaga(fields));

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isMassAdjustOpen}
      onClose={() => {
        setIsMassAdjustOpen(false);
      }}
    >
      <MassAdjustModal
        selectedCount={isGlobalSelected ? count : selected.length}
        handleCancel={() => {
          setIsMassAdjustOpen(false);
        }}
        handleApply={(fields: { [key: string]: string | number }) => {
          setIsMassAdjustOpen(false);
          onApplyMassAdjustSubrates(fields);
        }}
        valueList={massAdjustvalueList}
      />
    </Dialog>
  );
};

export default MassAdjustContainer;
