// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, call, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getAffiliateId } from "../../selectors/payments";
import { AFFILIATE_INFO_LOADING, GET_AFFILIATE_INFO_SAGA } from "../../helpers/constants/payments";
import { fetchAffiliateInfo } from "../../services/payments";
import type { LoadingTypes } from "../../selectors/loading/types";
import {
  setAffiliateInfo,
  setPaymentDetails,
  setEmptyPaymentInfoState,
} from "../../actions/paymentInfo";
import type { AffiliateInfoPayloadType } from "../../components/AffiliateInfoCard";

export function* makeFetch(): Saga<void> {
  yield put(setEmptyPaymentInfoState(["affiliateInfo", "paymentDetails"]));
  try {
    yield put(setLoading<LoadingTypes>(AFFILIATE_INFO_LOADING, true));

    const affId: string = yield select(getAffiliateId);
    const {
      data: affiliateInfo,
    }: { data: AffiliateInfoPayloadType } = yield call(fetchAffiliateInfo, affId);

    yield put(setAffiliateInfo(affiliateInfo));
    yield put(setPaymentDetails(affiliateInfo));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fecth affiliate info", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(AFFILIATE_INFO_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_AFFILIATE_INFO_SAGA, (makeFetch: Function));
}
