// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect, useState } from "react";
import type { StatelessFunctionalComponent, Node } from "react";
import {
  Loader,
  SetupCard,
  CardScrollSlider,
} from "@fas/ui-core";
import { SCROLL_VERTICAL_ORIENTATION } from "@fas/ui-core/lib/CardScrollSlider/CardScrollSlider";
import {
  Box,
  Typography,
} from "@mui/material";
import CardGridContent from "../CardGridContent";
import type { FieldItem } from "../CardGridContent/types/CardGridContent.types";
import {
  MAX_ROWS_PER_CARD,
} from "../../helpers/constants/payments";
import type { Props, UseStateType } from "./types/SimilarAffiliatesCard.types";
import type { PaymentDetailsType } from "../PaymentDetailsCard";
import { getPaymentTypeModel } from "../../pages/PaymentInfo/PaymentTypeFactory";
import PaymentType from "../../pages/PaymentInfo/PaymentTypeFactory/PaymentType";

const SimilarAffiliatesCard: StatelessFunctionalComponent<Props> = ({
  loading,
  paymentDetails,
  similarAffiliates,
}: Props) => {
  const [height, setHeight]: UseStateType<number> = useState(450);
  const fieldsMappers: Array<FieldItem[]> = similarAffiliates
    .map((
      details: PaymentDetailsType
    ): FieldItem[] => {
      const PaymentTypeModel: PaymentType = getPaymentTypeModel(details.paymentMethodId);
      return PaymentTypeModel.getPaymentDetailsMapper(details, paymentDetails);
    });

  const renderFields: Node[] = fieldsMappers.map((fieldMapper: FieldItem[]): Node => (
    <CardGridContent
      key={fieldMapper[0].value}
      fieldsMapper={fieldMapper}
      gridCols={fieldMapper.length > MAX_ROWS_PER_CARD ? 2 : 1}
      tableRows={1}
    />
  ));

  useEffect(() => {
    const sliderContainer: HTMLElement | null = document.getElementById("scroll-slider-container");
    if (sliderContainer) {
      const sliderContainerChildrenHeights: Array<number> = Array
        .from(sliderContainer.children)
        .map((item: HTMLElement): number => item.offsetHeight);
      const maxHeightOfContainer: number = Math.max(...sliderContainerChildrenHeights);

      setHeight(maxHeightOfContainer);
    }
  }, [fieldsMappers]);

  return (
    <SetupCard title="Similar affiliates">
      <Box width={1}>
        <Loader loading={loading} size={35}>
          <Box pb={2} height={`${height}px`}>
            {!loading && fieldsMappers.length > 0 && (
              <CardScrollSlider
                orientation={SCROLL_VERTICAL_ORIENTATION}
                timeout={200}
                slideCountVisible
              >
                {renderFields}
              </CardScrollSlider>
            )}
            {!loading && fieldsMappers.length === 0 && (
              <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                <Typography>No matching affiliates found</Typography>
              </Box>
            )}
          </Box>
        </Loader>
      </Box>
    </SetupCard>
  );
};

export default SimilarAffiliatesCard;
