// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";
import watchGetDropdownListSaga from "../../sagas/getDropdownList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
    call(watchGetDropdownListSaga),
  ]);
}
