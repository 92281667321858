// @flow
import type {
  GetCpaOfferListSaga,
  GetCpaOfferDropdownListsSaga,
} from "./types";
import {
  GET_CPA_OFFER_LIST_SAGA,
  GET_CPA_OFFER_DROPDOWN_LISTS,
} from "../../helpers/constants/cpaoffer";

export const getCpaOfferListSaga: () => GetCpaOfferListSaga = () => ({
  type: GET_CPA_OFFER_LIST_SAGA,
});

export const getCpaOfferDropdownListsSaga: () => GetCpaOfferDropdownListsSaga = () => ({
  type: GET_CPA_OFFER_DROPDOWN_LISTS,
});
