/* eslint-disable import/max-dependencies */
// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetCpaOfferListSaga from "../../sagas/getCpaOfferList";
import watchGetCpaOfferDropdownListsSaga from "../../sagas/getCpaOfferDropdownLists";
import { watchGetTemplatesList, watchGetTemplatesListDebounced } from "../../sagas/getTemplatesList";
import { watchChangeTemplateStatus } from "../../sagas/changeTemplateStatus";
import { watchDeleteTemplate } from "../../sagas/deleteTemplate";
import { watchSaveTemplate } from "../../sagas/saveTemplate";
import { watchChangeTemplateAttribute } from "../../sagas/changeTemplateAttribute";
import { watchGetTemplatesConfig } from "../../sagas/getTemplatesConfig";
import { watchGetDefaultTemplate } from "../../sagas/getDefaultTemplate";
import { watchGetTemplateFilterData } from "../../sagas/getTemplateFilterData";
import watchResetGlobalCapSaga from "../../sagas/resetGlobalCap";
import watchChangeValidationStatus from "../../sagas/changeValidationStatus";
import watchGetManagersList from "../../sagas/getManagersList";
import watchChangeAuthor from "../../sagas/changAuthorCpaOffer";
import watchCheckValidationStatus from "../../sagas/checkValidationStatus";
import watchChangeCpaOfferStatus from "../../sagas/changeCpaOfferStatus";
import watchExportTemplateSaga from "../../sagas/exportTemplateSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetCpaOfferListSaga),
    call(watchGetCpaOfferDropdownListsSaga),
    call(watchGetTemplatesList),
    call(watchGetTemplatesListDebounced),
    call(watchChangeTemplateStatus),
    call(watchDeleteTemplate),
    call(watchSaveTemplate),
    call(watchExportTemplateSaga),
    call(watchChangeTemplateAttribute),
    call(watchGetTemplatesConfig),
    call(watchGetTemplateFilterData),
    call(watchResetGlobalCapSaga),
    call(watchChangeValidationStatus),
    call(watchGetManagersList),
    call(watchChangeAuthor),
    call(watchChangeCpaOfferStatus),
    call(watchCheckValidationStatus),
    call(watchGetDefaultTemplate),
  ]);
}
