// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";
import { CRM_MAILS_TARGETING_TYPES, CRM_MAILS_USER_TYPES } from "../../helpers/constants/crm";

const schema = Joi.object({
  name: Joi.string(),
  subject: Joi.string(),
  targetingType: Joi.string().valid(CRM_MAILS_TARGETING_TYPES.country, CRM_MAILS_TARGETING_TYPES.email),
  userType: Joi.string().valid(CRM_MAILS_USER_TYPES.affiliate, CRM_MAILS_USER_TYPES.advertiser),
  sender: Joi.string(),
  templateId: Joi.number(),
  dateSend: Joi.string().isoDate(),
  statusMsg: Joi.string().valid("New", "Ready to send", "Send"),
  userPlatform: Joi.string(),
}).when(".targetingType", {
  is: CRM_MAILS_TARGETING_TYPES.country,
  then: Joi.object({
    userGroup: Joi.string(),
    userCountry: Joi.array().min(1),
  }),
  otherwise: Joi.object({
    userEmails: Joi.array().min(1),
  }),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "array.min": "This field is required",
  "object.base": "This field is required",
}).unknown();

export default joiResolver(schema);
