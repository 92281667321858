// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_MANAGERS_LIST_SAGA } from "../../helpers/constants/cpaoffer";
import { getManagersListByMarketingGroup } from "../../services/cpaOffersApi";
import type { GetManagersListSaga } from "../../actions/cpaoffer";
import { changeIsManagerListLoaded, setFetchedManagersList } from "../../actions/cpaoffer";

export function* makeFetch(action: GetManagersListSaga): Saga<void> {
  const { marketingGroup }: GetManagersListSaga = action;
  try {
    const { data: response } = yield call(getManagersListByMarketingGroup, marketingGroup);
    yield put(setFetchedManagersList(response.data));
    yield put(changeIsManagerListLoaded());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetManagersList(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_MANAGERS_LIST_SAGA, (makeFetch: Function));
}
