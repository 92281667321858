// @flow
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { DELETE_SUBRATE_SAGA, SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import { getSubratesInfoListSaga } from "../../actions/subrates";
import type { SubrateDataObjType } from "../../reducers/subrates";
import { deleteSubratesApi } from "../../services/subratesApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SUBRATES_INFO_TABLE, true));

    const selections: Array<SubrateDataObjType> = yield select(getTableSelections, SUBRATES_INFO_TABLE);
    const ids: string[] = selections.map(({ id }: SubrateDataObjType): string => String(id));

    yield call(deleteSubratesApi, ids);

    yield put(addNotification({ message: "Successfully deleted", severity: "success" }));
    yield put(setTableSelection(SUBRATES_INFO_TABLE, []));

    yield put(getSubratesInfoListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: "Error while deleting", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SUBRATES_INFO_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(DELETE_SUBRATE_SAGA, (makeFetch: Function));
}
