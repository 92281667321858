// @flow
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Chip, Typography } from "@mui/material";
import FilterGroup from "./FilterGroup";
import type { TemplateAttribute, TemplateAttributeWithConfig, AttributeFilter } from "../../../reducers/templates";

type Props = {|
  attributes: Array<TemplateAttributeWithConfig>,
  onSetFilters: (name: string, filters: Array<AttributeFilter>) => mixed;
  onSetAttributesList: (attributes: Array<TemplateAttribute>) => mixed;
|}
const FilteringValues = ({ attributes, onSetFilters, onSetAttributesList }: Props) => (
  <Box p={2}>
    <Box mb={2}>
      <Typography variant="subtitle1">
        FILTERS
      </Typography>
    </Box>
    <Chip
      label={<Typography>Clear all</Typography>}
      icon={<DeleteIcon />}
      color="secondary"
      size="small"
      data-testid="clearAll-chip"
      onClick={() => {
        onSetAttributesList(attributes.map(({ name, id, isHidden }) => ({
          name,
          id,
          isHidden,
          filters: [],
        })));
      }}
    />
    {attributes.map((attribute: TemplateAttributeWithConfig) => (
      <Box my={2} key={attribute.name}>
        <FilterGroup attribute={attribute} onSetFilters={onSetFilters} />
      </Box>
    ))}
  </Box>
);

export default React.memo<Props>(FilteringValues);
