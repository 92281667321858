// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Response } from "@fas/ui-framework/lib/services/request";
import { GENERATE_API_KEY_SAGA } from "../../helpers/constants/apiPage";
import { generateKey } from "../../services/manageAffiliateApi";
import { setApiField } from "../../actions/api";

export function* makeFetch(): Saga<void> {
  try {
    const { data: { data } }: Response<{ data: { apiKey: string } }> = yield call(generateKey);
    if (data) {
      yield put(setApiField("apiKey", data.apiKey));
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGenerateApiKey(): Saga<void> {
  yield takeEvery(GENERATE_API_KEY_SAGA, (makeFetch: Function));
}
