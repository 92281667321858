// @flow
/* eslint-disable max-classes-per-file */
/* eslint-disable import/max-dependencies */
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import {
  AutocompleteWithFetch,
  MultiselectSearchComponent,
  NumberSearchComponent,
  SelectSearchComponent,
} from "@fas/ui-core";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Clear } from "@mui/icons-material";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { Node } from "react";
import getOptionLabel from "@fas/ui-core/lib/SearchComponents/helpers/getOptionLabel";
import { getSubratesDropdowns } from "../subratesApi";
import { COUNTRY_NULL_URL_VIEW } from "../../helpers/constants/subrates";
import type { SubrateDataObjType } from "../../reducers/subrates";
import type { DropDownObjType } from "../../reducers/dictionaries";
import { getValue } from "../../helpers/generators";

const filterOptions: Array<*> = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => `${option.value} ${getOptionLabel(option)}`,
  trim: true,
});

const renderInput: (SearchComponentProps) => Node = ({
  value,
  onChange = () => {},
  ...rest
}: SearchComponentProps): Node => (
  <TextField
    {...rest}
    fullWidth
    variant="outlined"
    size="small"
    value={value}
    onChange={({ target: { value: newValue } }: SyntheticInputEvent<HTMLInputElement>): void => onChange(newValue)}
    InputProps={{
      endAdornment: (
        value && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={(): void => onChange("")} edge="end">
              <Clear />
            </IconButton>
          </InputAdornment>
        )
      ),
    }}
  />
);

export class Attribute {
  label: string;

  title: string;

  apiKey: string;

  group: string;

  tooltips: Tooltip[];

  columnClassName: string = "small-table-col";

  disabled: boolean = false;

  editable: boolean = false;

  searchable: boolean = true;

  sortable: boolean = true;

  getTitle(): string {
    return this.title;
  }

  getLabel(): string {
    return this.label;
  }

  getApiKey(): string {
    return this.apiKey;
  }

  getGroup(): string {
    return this.group;
  }

  isDisabled(): boolean {
    return this.disabled;
  }

  isEditable(): boolean {
    return this.editable;
  }

  validate(value: *): boolean {
    return !!value;
  }

  getErrorMsg(): string {
    return "Wrong value format";
  }

  getTooltips(): Tooltip[] {
    return this.tooltips;
  }

  isSearchable(): boolean {
    return this.searchable;
  }

  isSortable(): boolean {
    return this.sortable;
  }

  getColumnClassName(): string {
    return this.columnClassName;
  }

  renderColumn(): (row: SubrateDataObjType) => React.Node {
    return (row: SubrateDataObjType): React.Node => getValue(row[this.getApiKey()], "-");
  }

  // eslint-disable-next-line no-unused-vars
  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      // $FlowFixMe
      field: this.getApiKey(),
      label: this.getTitle(),
      searchable: this.isSearchable(),
      sortable: this.isSortable(),
      render: this.renderColumn(),
      className: this.getColumnClassName(),
      SearchComponent: renderInput,
    };
  }
}

export class AffId extends Attribute {
  title = "Affiliate id";

  apiKey = "affId";

  group = "Affiliate data";

  columnClassName = "small-table-col";

  renderColumn(): (row: SubrateDataObjType) => React.Node {
    return ({ affId, offerId, country }: SubrateDataObjType): React.Node => (
      <RouterLink
        style={{ color: "#0000EE", textDecoration: "none" }}
        to={`/subrates/info?affId=${affId || ""}&offerId=${offerId || ""}&country=${country || COUNTRY_NULL_URL_VIEW}`}
      >
        <Typography color="primary" component="span">{affId}</Typography>
      </RouterLink>
    );
  }
}

export class Name extends Attribute {
  title = "Affiliate name";

  apiKey = "name";

  group = "Affiliate data";

  columnClassName = "middle-table-col";

  // FIXME: filters should not contain an object
  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof AutocompleteWithFetch> => (
        <AutocompleteWithFetch
          {...searchProps}
          // $FlowFixMe
          selectedValue={filters.name}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {getOptionLabel(option)}
            </li>
          )}
          // $FlowFixMe
          fetchMethod={(str: string) => getSubratesDropdowns({
            type: "affiliate",
            field: "name",
            format: "name",
            filter: str,
          })}
        />
      ),
    };
  }
}

export class AffType extends Attribute {
  title = "Affiliate type";

  apiKey = "type";

  group = "Affiliate data";

  columnClassName = "long-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof MultiselectSearchComponent> => (
        <MultiselectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          options={dropdowns.affiliateTypes}
        />
      ),
    };
  }
}

export class OfferId extends Attribute {
  title = "Offer id";

  apiKey = "offerId";

  group = "Offer data";
}

export class OfferName extends Attribute {
  title = "Offer name";

  apiKey = "offerName";

  group = "Offer data";

  columnClassName = "very-long-table-col";

  // FIXME: filters should not contain an object
  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof AutocompleteWithFetch> => (
        <AutocompleteWithFetch
          {...searchProps}
          // $FlowFixMe
          selectedValue={filters.offerName}
          freeSolo
          // $FlowFixMe
          fetchMethod={(str: string): void => getSubratesDropdowns({
            type: "offer",
            field: "name",
            format: "name",
            filter: str,
          })}
        />
      ),
    };
  }
}

export class ConversionType extends Attribute {
  title = "Conversion type";

  apiKey = "conversionType";

  group = "Offer data";

  columnClassName = "middle-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof MultiselectSearchComponent> => (
        <MultiselectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          options={dropdowns.conversionTypes}
        />
      ),
    };
  }
}

export class Country extends Attribute {
  title = "Country";

  apiKey = "country";

  group = "Subrate data";

  columnClassName = "middle-table-col";

  renderColumn(): (row: SubrateDataObjType) => React.Node {
    return ({ country }: SubrateDataObjType): React.Node => (country || "all");
  }

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          filterOptions={filterOptions}
          values={(
            dropdowns.countries && dropdowns.countries
              // $FlowFixMe MTU-94094
              .map((item) => (item.value === null ? { title: "All", value: "all" } : item)))}
        />
      ),
    };
  }
}

export class Platform extends Attribute {
  title = "Platform";

  apiKey = "platform";

  group = "Offer data";

  columnClassName = "middle-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdowns.platforms}
        />
      ),
    };
  }
}

export class Currency extends Attribute {
  title = "Currency";

  apiKey = "currencyId";

  group = "Offer data";

  columnClassName = "middle-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: (
        searchProps: SearchComponentProps
      ): React.Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdowns.currencyId}
        />
      ),
    };
  }
}

export class OfferPayoutAmount extends Attribute {
  title = "Default commission";

  apiKey = "offerPayoutAmount";

  group = "Default data";

  columnClassName = "long-table-col";

  renderColumn(): (row: SubrateDataObjType) => React.Node {
    return (row: SubrateDataObjType): React.Node => {
      const noValue: string = "-";
      const v: string | number = getValue(row[this.getApiKey()], noValue);
      return v === noValue ? noValue : Number(v).toFixed(4);
    };
  }

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: NumberSearchComponent,
    };
  }
}

export class OfferPayoutAmountFirst extends Attribute {
  title = "Default commission for firsts %";

  apiKey = "offerPayoutAmountFirst";

  group = "Default data";

  columnClassName = "long-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: NumberSearchComponent,
    };
  }
}

export class OfferPayoutAmountRepeats extends Attribute {
  title = "Default commission for repeats %";

  apiKey = "offerPayoutAmountRepeats";

  group = "Default data";

  columnClassName = "long-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: NumberSearchComponent,
    };
  }
}

export class OfferShaveAmount extends Attribute {
  title = "Default shave %";

  apiKey = "offerShaveAmount";

  group = "Default data";

  columnClassName = "middle-table-col";

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      SearchComponent: NumberSearchComponent,
    };
  }
}

export class ShaveAmountDefault extends Attribute {
  title = "Shave %";

  apiKey = "defaultShaveAmount";

  group = "Subrate data";

  sortable = false;

  searchable = false;

  columnClassName = "small-table-col";

  tooltips = [{ type: "help", text: "From subrate without S3 and utm_content" }];

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      tooltips: this.getTooltips(),
    };
  }
}

export class PayoutAmountFirstDefault extends Attribute {
  title = "Commission for firsts %";

  apiKey = "defaultPayoutAmountFirst";

  group = "Subrate data";

  columnClassName = "long-table-col";

  sortable = false;

  searchable = false;

  tooltips = [{ type: "help", text: "From subrate without S3 and utm_content" }];

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      tooltips: this.getTooltips(),
    };
  }
}

export class PayoutAmountRepeatsDefault extends Attribute {
  title = "Commission for repeats %";

  columnClassName = "long-table-col";

  apiKey = "defaultPayoutAmountRepeats";

  group = "Subrate data";

  sortable = false;

  searchable = false;

  tooltips = [{ type: "help", text: "From subrate without S3 and utm_content" }];

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      tooltips: this.getTooltips(),
    };
  }
}

export class PayoutAmountDefault extends Attribute {
  title = "Commission";

  columnClassName = "middle-table-col";

  apiKey = "defaultPayoutAmount";

  group = "Subrate data";

  sortable = false;

  searchable = false;

  tooltips = [{ type: "help", text: "From subrate without S3 and utm_content" }];

  getHeaderColProps(filters: Filters, dropdowns: DropDownObjType): Column<SubrateDataObjType> {
    return {
      ...super.getHeaderColProps(filters, dropdowns),
      tooltips: this.getTooltips(),
    };
  }
}
