// @flow
import React from "react";
import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

type Props = {};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left"
  },
  errors: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

const PaymentInfoTablet = ({
  title,
  errors,
  fields,
  children,
}: Props) => {
  const classes = useStyles();

  const isAnyErrors = fields.some((field) => errors.hasOwnProperty(field));

  return (
    <Paper
      className={clsx(classes.root, isAnyErrors && classes.errors)}
      elevation={3}
    >
      <Typography
        className={classes.title}
        variant="h6"
      >
        {title}
      </Typography>
      {React.Children.map(children, (child, index) => (
        <div
          key={index}
        >
          {child}
        </div>
      ))}
    </Paper>
  );
};

export default PaymentInfoTablet;
