// @flow
import {
  call, debounce, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableData } from "@fas/ui-framework/lib/redux/actions/table";
import {
  AFFILIATE_SMART_OFFERS_TABLE,
  GET_AFFILIATE_SMART_OFFERS_LIST_SAGA,
} from "../../helpers/constants/affiliateSmartOffers";
import { fetchAffiliateSmartOffersList } from "../../services/affiliateSmartOffersApi";
import type {
  AffiliateSmartOfferType,
} from "../../containers/AffiliateSmartOffersList/types/AffiliateSmartOffersList.types";
import { getAffiliateId } from "../../selectors/affiliateLinks";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(AFFILIATE_SMART_OFFERS_TABLE, true));
    const affiliateId: string = yield select(getAffiliateId);

    const {
      payload,
    }: { payload: AffiliateSmartOfferType[] } = yield call(fetchAffiliateSmartOffersList, {
      affiliateId,
    });

    yield put(setTableData(AFFILIATE_SMART_OFFERS_TABLE, payload));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch affiliate smart offers", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(AFFILIATE_SMART_OFFERS_TABLE, false));
  }
}

export default function* watchGetAffiliateSmartOffersListSaga(): Saga<void> {
  yield debounce(1000, GET_AFFILIATE_SMART_OFFERS_LIST_SAGA, (makeFetch: Function));
}
