// @flow
import React, { useState, useCallback, useMemo } from "react";
import {
  Box, Grid, AppBar, Toolbar, Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavigationList, ListWithGroups, FooterInput } from "@fas/ui-core";
import HeadersList from "../HeadersList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  rootToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  rootBtn: {
    margin: `0 ${theme.spacing(2)}`,
  },
  modal: {
    background: theme.palette.background.mainBg,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: `${theme.shape.borderRadius}`,
  },
  modalHeader: {
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: "#fff",
  },
  modalFooter: {
    borderTop: `1px solid ${theme.palette.borderColor}`,
    padding: theme.spacing(2),
  },
  bodyContainer: {
    height: "100%",
    overflow: "hidden",
    minHeight: "400px",
  },
  navigatonListContainer: {
    background: "white",
    padding: `${theme.spacing(2)} 0 0`,
    borderRight: `1px solid ${theme.palette.borderColor}`,
  },
  listWithGroups: {
    background: "white",
    "& .search-result": {
      display: "none",
    },
  },
}));

type useStateType<S> = [S, ((S => S) | S) => void];

type ConfigItemType<S> = {
  title: string | S,
  key: S,
};

type ConfigGroupsType<S> = {
  title: string,
  headers: Array<ConfigItemType<S>>
}

export type TemplateType<S> = {
  name: string,
  headersOrder: Array<S>,
  id?: string,
};

type Props<S> = {
  template: TemplateType<S>,
  config: Array<ConfigGroupsType<S>>,
  onSave: (TemplateType<S>) => void,
  onApply: (TemplateType<S>) => void,
  onCancel: () => void,
  disableSave: boolean,
};

const TemplateModal = <S>(props: Props<S>) => {
  const {
    config, template, onSave, onApply, onCancel, disableSave,
  } = props;
  const classes = useStyles();
  const [isNavigated, setIsNavigated] = useState(false);
  const [selectedGroup, setSelectedGroup]: useStateType<string> = useState("General options");
  const [selectedHeaders, setSelectedHeaders]: useStateType<Array<S>> = useState(template.headersOrder);
  const [templateName, setTemplateName]: useStateType<string> = useState(template.name);

  const groups = useMemo(() => config.map((item) => ({ value: item.title })), [config]);
  const groupsFormated = useMemo(() => config.map((item) => ({
    title: item.title,
    filters: item.headers.map((filter) => ({
      isDisabled: false,
      title: filter.title,
      filterKey: filter.key,
      selected: selectedHeaders.includes(filter.key),
    })),
  })), [config, selectedHeaders]);
  const allFilters = useMemo(() => config.reduce((acc, curentItem) => acc.concat(curentItem.headers), []), [config]);
  const prepearedList = useMemo(() => selectedHeaders
    .map((item) => allFilters.find((f) => f.key === item) || { title: item, key: item }),
  [selectedHeaders, allFilters]);

  const changeOrder = useCallback((result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    const currentHeader = selectedHeaders[source.index];
    const rest = selectedHeaders.filter((v, index) => index !== source.index);
    setSelectedHeaders([
      ...rest.slice(0, destination.index),
      currentHeader,
      ...rest.slice(destination.index),
    ]);
  }, [selectedHeaders]);
  const navigationListClick = useCallback((val) => {
    setSelectedGroup(val);
    setIsNavigated(true);
  }, []);

  const listGroupToggleAll = useCallback((val) => {
    if (val) {
      setSelectedHeaders([
        ...selectedHeaders,
        ...allFilters.filter((item) => !selectedHeaders.includes(item.key)).map((item) => item.key),
      ]);
    }
    else {
      setSelectedHeaders([]);
    }
  }, [allFilters, selectedHeaders]);

  const isAllSelected = selectedHeaders.length === allFilters.length;

  const onScroll = useCallback(() => {
    if (selectedGroup && !isNavigated) {
      setIsNavigated(false);
      setSelectedGroup("");
    }
    setIsNavigated(false);
  }, [isNavigated, selectedGroup]);

  const onItemClick = useCallback((item) => {
    if (item.value) {
      // $FlowFixMe
      setSelectedHeaders([...selectedHeaders, item.filterKey]);
    }
    else {
      setSelectedHeaders(selectedHeaders.filter((header) => header !== item.filterKey));
    }
  }, [selectedHeaders]);

  const onDeleteItemClick = useCallback((item) => {
    setSelectedHeaders(selectedHeaders.filter((header) => header !== item.key));
  }, [selectedHeaders]);

  const updateTemplate = useCallback(() => {
    onSave({ ...template, headersOrder: selectedHeaders, name: templateName });
  }, [selectedHeaders, templateName, template, onSave]);

  const saveTemplate = useCallback(() => {
    onSave({ headersOrder: selectedHeaders, name: templateName });
  }, [selectedHeaders, templateName, onSave]);

  const onChangeNameTemplate = useCallback((e) => setTemplateName(e), []);

  const handleApplyBtn = () => {
    onApply({ headersOrder: selectedHeaders, name: templateName });
  };

  return (
    <Box className={classes.modal} data-testid="templateModal">
      <Box className={classes.modalHeader}> Template options</Box>
      <Grid container className={classes.bodyContainer}>
        <Grid item xs={4} className={classes.navigatonListContainer}>
          <NavigationList
            list={groups}
            selected={selectedGroup}
            onChange={navigationListClick}
          />
        </Grid>
        <Grid className={classes.listWithGroups} item xs={4}>
          <ListWithGroups
            // $FlowFixMe
            groups={groupsFormated}
            isAllSelected={isAllSelected}
            onItemClick={onItemClick}
            toggleAll={listGroupToggleAll}
            onScroll={onScroll}
            scrollTo={selectedGroup}
          />
        </Grid>
        <Grid item xs={4}>
          <HeadersList
            list={prepearedList}
            onOrderChange={changeOrder}
            onDeleteItem={onDeleteItemClick}
          />
        </Grid>
      </Grid>
      <AppBar classes={{ root: classes.root }} position="static">
        <Toolbar classes={{ root: classes.rootToolbar }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FooterInput
              onSave={saveTemplate}
              onChangeName={onChangeNameTemplate}
              templateName={templateName}
              disableSave={!templateName}
              disableUpdate={!template.id}
              onUpdate={updateTemplate}
              disabled={!templateName.length || disableSave}
              updateTooltip=""
              buttonLabel="Save template"
              updateLabel="Update template"
              saveLabel="Save as new template"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button
              classes={{ root: classes.rootBtn }}
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              classes={{ root: classes.rootBtn }}
              color="primary"
              onClick={handleApplyBtn}
              disabled={!prepearedList.length}
              data-testid="template-modal-apply-btn"
            >
              Apply
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TemplateModal;
