/* eslint-disable import/max-dependencies */
import type React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Provider } from "react-redux";
import { ConfirmOptions, ConfirmProvider } from "material-ui-confirm";
import { store } from "./store";
import NotificationsContainer from "../../components/NotificationsContainer";
import RouterSlots from "../components/RouterSlots";
import Dashboard from "./Dashboard";
import SlotResolver from "../components/SlotResolver";
import TenantSwitcher from "../components/TenantSwitcher";
import DevTools from "./DevTools";

const confirmProviderDefaultOptions: ConfirmOptions = {
  confirmationButtonProps: {
    autoFocus: true,
  },
  dialogProps: {
    maxWidth: "md",
  },
  contentProps: {
    sx: { padding: 0 },
  },
};

const Affiliates: React.FC = () => (
  <Box sx={{ p: 1 }}>
    <Provider store={store}>
      <NotificationsContainer />
      <TenantSwitcher />
      <ConfirmProvider defaultOptions={confirmProviderDefaultOptions}>
        <SlotResolver>
          {({ slots, isLoading }) => (
            isLoading ? (
              <CircularProgress />
            ) : (
              <RouterSlots slots={slots} Index={<Dashboard slots={slots} />} />
            )
          )}
        </SlotResolver>
      </ConfirmProvider>
      <DevTools />
    </Provider>
  </Box>
);

export default Affiliates;
