// @flow
import { CpaFactoryModel } from "./cpaOfferModels";
import { FactoryAttributes, FactoryModel } from "./defaultModels";

const FactoryModels: { [string]: FactoryAttributes } = {
  "default": FactoryModel,
  "cpaOffer": CpaFactoryModel,
};

export default FactoryModels;
export * from "./defaultModels";
