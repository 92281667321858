// @flow
import {
  SET_FILTERS,
} from "../../helpers/constants/filters";
import type { Filter } from "../../reducers/filters";

export type SetFilters = {|
  type: typeof SET_FILTERS,
  payload: Array<Filter>,
|};

export type Actions = SetFilters;

export const setFilters: (filters: Array<Filter>) => SetFilters = (filters) => ({
  type: SET_FILTERS,
  payload: filters,
});
