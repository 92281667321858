// @flow
import environment from "environment";
import { requestService } from "../axiosRequest";

export const fecthAffiliateLinks = (affiliateId: string) => requestService
  .get(environment.endpoints.get.getAffiliateLinks.replace("{affiliateId}", affiliateId))
  .then(({ data }) => data);

export const deleteAffiliateLink = (id: number) => requestService
  .delete(environment.endpoints.delete.removeAffiliateLink.replace("{smartlinkId}", String(id)))
  .then(({ data }) => data);

export const saveAffiliateLink = (affiliateId: string, fields: Object) => requestService
  .post(environment.endpoints.post.saveAffiliateLinks.replace("{affiliateId}", affiliateId), fields)
  .then(({ data }) => data);

export const getAffiliateByid = (affiliateId: string) => requestService
  .get(environment.endpoints.get.getAffiliate.replace("{affiliateId}", affiliateId))
  .then(({ data }) => data);
