// @flow
import React from "react";
import { useDispatch } from "react-redux";
import { SimpleTableActionButton, TableActionGroup, TableActions } from "@fas/ui-core";
import { FileDownload } from "@mui/icons-material";
import { downloadCpaOfferCampaignsSaga } from "../../actions/cpaOfferCampaigns";

const TableActionsRow = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const handleDownloadData: () => * = () => dispatch(downloadCpaOfferCampaignsSaga());
  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Download"
          onClick={handleDownloadData}
          Icon={<FileDownload />}
        />
      </TableActionGroup>
    </TableActions>
  );
};

export default TableActionsRow;
