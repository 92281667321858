// @flow
const manageAffiliate = [
  {
    title: "General options",
    headers: [
      {
        title: "Total shave",
        key: "shaveAmount",
      },
      {
        title: "Ncr amount",
        key: "ncrAmount",
      },
      {
        title: "Payment available",
        key: "paymentAvailable",
      },
      {
        title: "Ignore shave",
        key: "isShaveIgnored",
      },
      {
        title: "Shave all",
        key: "isShavedAll",
      },
      {
        title: "Is ad network",
        key: "isAdNetwork",
      },
      {
        key: "paid",
        title: "Paid, $",
      },
      {
        key: "approved",
        title: "Approved, $",
      },
      {
        key: "pending",
        title: "Pending, $",
      },
      {
        key: "declined",
        title: "Declined, $",
      },
      {
        title: "Is active",
        key: "isActive",
      },
    ],
  },
  {
    title: "Affiliate info",
    headers: [
      {
        title: "ID",
        key: "id",
      },
      {
        title: "Affiliate name",
        key: "name",
      },
      {
        title: "First name",
        key: "firstName",
      },
      {
        title: "Last name",
        key: "lastName",
      },
      {
        title: "Email",
        key: "email",
      },
      {
        title: "Instant messenger",
        key: "imType",
      },
      {
        title: "Im name",
        key: "imName",
      },
      {
        title: "Phone number",
        key: "phone",
      },
      {
        title: "Created at",
        key: "createdAt",
      },
    ],
  },
  {
    title: "Affiliate Address",
    headers: [
      {
        title: "Address",
        key: "address",
      },
      {
        title: "City",
        key: "city",
      },
      {
        title: "Zip",
        key: "zip",
      },
      {
        title: "State",
        key: "state",
      },
      {
        title: "Country",
        key: "country",
      },
      {
        title: "Tax ssn/ein (usa only)",
        key: "taxSSN",
      },
      {
        title: "Country by ip",
        key: "countryByIp",
      },
    ],
  },
  {
    title: "Manager Settings",
    headers: [
      {
        title: "Manager",
        key: "manager",
      },
      {
        title: "Commission",
        key: "revenue",
      },
      {
        title: "How did you get to know about?",
        key: "howFoundOut",
      },
      {
        title: "Hear about from",
        key: "refSrc",
      },
      {
        title: "Traffic type",
        key: "trafficType",
      },
      {
        title: "Top verticals",
        key: "topVerticals",
      },
      {
        title: "Company type",
        key: "companyType",
      },
      {
        title: "Company name",
        key: "companyName",
      },
      {
        title: "Company url",
        key: "companyUrl",
      },
      {
        title: "What top countries do you mostly run?",
        key: "topCountries",
      },
      {
        title: "Affiliate type",
        key: "type",
      },
      {
        title: "Status",
        key: "status",
      },
      {
        title: "Decline reason",
        key: "declineReason",
      },
      {
        title: "Decline reason description",
        key: "declineReasonDescription",
      },
      {
        title: "Currency",
        key: "currency",
      },
      {
        key: "refererUrl",
        title: "Came from",
      },
      {
        key: "registrationUrl",
        title: "Registration URL",
      },
      {
        key: "fraudStatus",
        title: "Fraud status",
      },
    ],
  },
];

export default manageAffiliate;
