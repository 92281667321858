// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useState,
  type StatelessFunctionalComponent,
} from "react";
import { connect } from "react-redux";
import { bindActionCreators, type Dispatch } from "redux";
import {
  Container,
  Box,
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import {
  SearchOutlined,
} from "@mui/icons-material";
import { GridUc } from "@fas/ui-core";
import { changeTablePageSize } from "@fas/ui-framework/lib/redux/actions/table";
import {
  getLoading,
} from "../../selectors/loading";
import {
  getAffiliateId,
  getAffiliateInfo,
  getPaymentInfo,
  getPaymentDetails,
  getSimilarAffiliates,
  getNextPaymentDatePayoutPeriod,
  getNextPaymentDateComment,
} from "../../selectors/payments";
import {
  changeAffiliateId,
  getAffiliateInfoSaga,
  getPaymentNextPaymentDateSaga,
  type ChangeAffiliateIdAction,
  type GetAffiliateInfoSagaAction,
  type GetPaymentNextPaymentDateSagaAction,
} from "../../actions/paymentInfo";
import {
  downloadInvoiceSaga, getPayoutHistoryListSaga,
} from "../../actions/payoutHistory";
import AffiliateInfoCard, { type AffiliateInfoType } from "../../components/AffiliateInfoCard";
import PaymentInfoCard, { type PaymentStatsType } from "../../components/PaymentInfoCard";
import PaymentDetailsCard, { type PaymentDetailsType } from "../../components/PaymentDetailsCard";
import SimilarAffiliatesCard from "../../components/SimilarAffiliatesCard";
import PayoutHistoryTable from "../../components/PayoutHistoryTable";
import NextPaymentDateLabel from "../../components/NextPaymentDateLabel";
import type { State as PaymentInfoStoreState } from "../../pages/PaymentInfo";
import type { GetPayoutHistoryListSaga } from "../../actions/payoutHistory";
import {
  AFFILIATE_INFO_LOADING, DOWNLOAD_INVOICE_LOADING,
  NEXT_PAYMENT_DATE_LOADING,
  NEXT_PAYMENT_INFO_LOADING, PAYMENT_DETAILS_LOADING, SIMILAR_AFFILIATE_LOADING,
} from "../../helpers/constants/payments";
import { PAYOUT_HISTORY_LIST_TABLE } from "../../helpers/constants/payoutHistory";

type OwnProps = $ReadOnly<{|
  store?: Object,
|}>;

type StateToProps = $ReadOnly<{|
  affId: string,
  affiliateInfo: AffiliateInfoType,
  nextPaymentDatePayoutPeriod: string,
  nextPaymentDateComment: string,
  paymentInfo: PaymentStatsType,
  paymentDetails: PaymentDetailsType,
  similarAffiliates: Array<PaymentDetailsType>,
  isPaymentsAffiliateInfoLoading: boolean,
  isPaymentsNextPaymentDateLoading: boolean,
  isPaymentsPaymentInfoLoading: boolean,
  isPaymentsPaymentDetailsLoading: boolean,
  isPaymentsSimilarAffiliateLoading: boolean,
  isDownloadInvoiceLoading: boolean,
|}>;

type DispatchToProps = $ReadOnly<{|
  onChangeAffiliateId: (affId: string) => ChangeAffiliateIdAction,
  onGetAffiliateInfo: () => GetAffiliateInfoSagaAction,
  onGetPayoutHistory: () => GetPayoutHistoryListSaga,
  onGetNextPaymentDate: () => GetPaymentNextPaymentDateSagaAction,
  onDownloadInvoice: typeof downloadInvoiceSaga,
|}>;

type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
  |}>;

const mapStateToProps: (
  state: PaymentInfoStoreState
) => StateToProps = (
  state: PaymentInfoStoreState
): StateToProps => ({
  affId: getAffiliateId(state),
  affiliateInfo: getAffiliateInfo(state),
  nextPaymentDatePayoutPeriod: getNextPaymentDatePayoutPeriod(state),
  nextPaymentDateComment: getNextPaymentDateComment(state),
  paymentInfo: getPaymentInfo(state),
  paymentDetails: getPaymentDetails(state),
  similarAffiliates: getSimilarAffiliates(state),
  isPaymentsAffiliateInfoLoading: getLoading(state, AFFILIATE_INFO_LOADING),
  isPaymentsNextPaymentDateLoading: getLoading(state, NEXT_PAYMENT_DATE_LOADING),
  isPaymentsPaymentInfoLoading: getLoading(state, NEXT_PAYMENT_INFO_LOADING),
  isPaymentsPaymentDetailsLoading: getLoading(state, PAYMENT_DETAILS_LOADING),
  isPaymentsSimilarAffiliateLoading: getLoading(state, SIMILAR_AFFILIATE_LOADING),
  isDownloadInvoiceLoading: getLoading(state, DOWNLOAD_INVOICE_LOADING),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onChangeAffiliateId: changeAffiliateId,
  onGetAffiliateInfo: getAffiliateInfoSaga,
  onGetPayoutHistory: getPayoutHistoryListSaga,
  onGetNextPaymentDate: getPaymentNextPaymentDateSaga,
  onDownloadInvoice: downloadInvoiceSaga,
}, dispatch);

const PaymentInfo: StatelessFunctionalComponent<Props> = ({
  affId,
  affiliateInfo,
  nextPaymentDatePayoutPeriod,
  nextPaymentDateComment,
  paymentInfo,
  paymentDetails,
  onGetPayoutHistory,
  similarAffiliates,
  isPaymentsAffiliateInfoLoading,
  isPaymentsNextPaymentDateLoading,
  isPaymentsPaymentInfoLoading,
  isPaymentsSimilarAffiliateLoading,
  onChangeAffiliateId,
  onGetAffiliateInfo,
  onGetNextPaymentDate,
  onDownloadInvoice,
  isDownloadInvoiceLoading,
}: Props) => {
  const [cardsVisible, setCardsVisible] = useState(false);

  const handleAffiliateInfoSearch: () => void = () => {
    setCardsVisible(true);
    onGetAffiliateInfo();
    onGetNextPaymentDate();
    onGetPayoutHistory();
    changeTablePageSize(PAYOUT_HISTORY_LIST_TABLE, 200);
  };

  return (
    <Container maxWidth={false}>
      <Box data-testid="payments-payment-info" width="100%">
        <Grid container spacing={3}>
          <GridUc my={3} item xs={6}>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <FormControl
                  variant="filled"
                  disabled={isPaymentsAffiliateInfoLoading}
                >
                  <TextField
                    id="mui-textfield-payment-info-aff-id"
                    variant="outlined"
                    size="small"
                    value={affId}
                    onChange={(
                      e: SyntheticInputEvent<HTMLInputElement>
                    ): ChangeAffiliateIdAction => onChangeAffiliateId(e.target.value.trim())}
                    data-testid="textfield-payment-info-aff-id"
                    onKeyDown={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
                      const { key, keyCode }: SyntheticKeyboardEvent<HTMLInputElement> = e;
                      if (key === "Enter" && keyCode === 13) {
                        handleAffiliateInfoSearch();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
              <Box mr={1}>
                <Button
                  variant="contained"
                  disabled={isPaymentsAffiliateInfoLoading}
                  color="secondary"
                  data-testid="button-payment-info-aff-id"
                  onClick={(): void => handleAffiliateInfoSearch()}
                >
                  Search
                </Button>
              </Box>
            </Box>
          </GridUc>
          <Grid item xs={3}>
            <></>
          </Grid>
          <GridUc my={3} item xs={3}>
            { cardsVisible && (
              <NextPaymentDateLabel
                loading={isPaymentsNextPaymentDateLoading}
                payoutPeriod={nextPaymentDatePayoutPeriod}
                comment={nextPaymentDateComment}
              />
            )}
          </GridUc>
          { cardsVisible && (
            <>
              <Grid item xs={6}>
                <AffiliateInfoCard
                  loading={isPaymentsAffiliateInfoLoading}
                  affiliateInfo={affiliateInfo}
                />
              </Grid>
              <Grid item xs={6}>
                <PaymentInfoCard
                  loading={isPaymentsPaymentInfoLoading}
                  paymentInfo={paymentInfo}
                  currency={affiliateInfo.currency}
                />
              </Grid>
              <Grid item xs={6}>
                <PaymentDetailsCard
                  loading={isPaymentsAffiliateInfoLoading}
                  paymentDetails={paymentDetails}
                />
              </Grid>
              <Grid item xs={6}>
                <SimilarAffiliatesCard
                  loading={isPaymentsSimilarAffiliateLoading}
                  paymentDetails={paymentDetails}
                  similarAffiliates={similarAffiliates}
                />
              </Grid>
              <Grid item xs={12}>
                <PayoutHistoryTable
                  onDownloadInvoice={onDownloadInvoice}
                  isDownloadInvoiceLoading={isDownloadInvoiceLoading}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(PaymentInfo);
