// @flow

export const getErrors = (errors) => errors.reduce((res, item) => ({ ...res, [item.field]: item.error }), {});

export const sanitizeFields = (fields) => {
  if (fields && fields.country[0] === "all") {
    return {
      ...fields,
      country: [],
    };
  }

  return fields;
};
