// @flow
import React, { useEffect, useState, type StatelessFunctionalComponent } from "react";
import {
  Box,
  Button,
  Typography,
  Popover,
  Fade,
  type ClassNameMap,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ConfirmDialog, Loader } from "@fas/ui-core";
import { ArrowDropDown, CheckCircleOutline, Edit } from "@mui/icons-material";
import type { UseState } from "../../../helpers/types";
import type { TemplatesListProps, Classes } from "./TemplatesList.types";
import TemplatesListBody from "./TemplatesListBody";
import type { Item, TemplateAttribute } from "../../../reducers/templates";

const useStyles: ClassNameMap = makeStyles((): mixed => ({
  whiteBtn: {
    backgroundColor: "white",
  },
  paper: {
    width: 400,
    zIndex: 100,
    textAlign: "start",
  },
}));

const TemplatesList: StatelessFunctionalComponent<TemplatesListProps> = ({
  searchString,
  category,
  templatesList,
  activeTemplate,
  filters,
  onSetCurrentTemplate,
  onSetTemplatesList,
  onGetTemplatesListSagaDebounced,
  onClearTemplatesListState,
  onDeleteTemplateSaga,
  onSetTemplateModalOpen,
  isTemplateConfigLoading,
  isDefaultTemplateLoading,
  ...props
}: TemplatesListProps) => {
  const classes: Classes = useStyles();
  const [listAnchorEl, setListAnchorEl]: UseState<HTMLButtonElement | null> = useState(null);
  const [openConfirmDelete, setOpenConfirmDelete]: UseState<boolean> = useState(false);
  const [templateToDelete, setTemplateToDelete]: UseState<Item> = useState({ id: "", name: "" });

  const handleOpenList: (event: SyntheticEvent<HTMLButtonElement>) => void = (event) => {
    setListAnchorEl(event.currentTarget);
  };

  const handleCloseList: () => void = () => {
    setListAnchorEl(null);
    onClearTemplatesListState();
  };

  const handleOpenConfirm: (item: Item) => void = (item) => {
    setTemplateToDelete(item);
    setOpenConfirmDelete(true);
  };

  const handleCloseConfirm: () => void = () => {
    setOpenConfirmDelete(false);
  };

  const handleAgreeToDelete: () => void = () => {
    onDeleteTemplateSaga(templateToDelete);
    setOpenConfirmDelete(false);
  };

  const handleOpenEditTemplate: () => void = () => {
    onSetTemplateModalOpen(true);
    onSetCurrentTemplate({
      ...activeTemplate,
      attributes: activeTemplate.attributes
        .map((attribute: TemplateAttribute, index: number): TemplateAttribute => ({
          ...attribute,
          filters: filters[index] ? filters[index].filters : [],
        })),
    });
  };

  useEffect(() => {
    if (listAnchorEl) {
      onGetTemplatesListSagaDebounced();
    }
  }, [searchString, category, listAnchorEl]);

  useEffect(() => {
    if (templatesList.length > 0) {
      onSetTemplatesList([]);
    }
  }, [category]);

  return (
    <Box display="flex" position="relative" alignItems="center">
      <Box mr={2}>
        <Loader loading={isTemplateConfigLoading}>
          <Button
            disabled={isTemplateConfigLoading}
            className={classes.whiteBtn}
            data-testid="template-list-open-btn"
            variant="outlined"
            startIcon={<CheckCircleOutline />}
            endIcon={<ArrowDropDown />}
            onClick={handleOpenList}
          >
            <Typography variant="button">
              Template
            </Typography>
          </Button>
        </Loader>
      </Box>
      <Box mr={2}>
        <Loader loading={isDefaultTemplateLoading || isTemplateConfigLoading}>
          <Button
            className={classes.whiteBtn}
            data-testid="save-edit-template-btn"
            disabled={!activeTemplate.attributes.length}
            variant="outlined"
            startIcon={<Edit />}
            onClick={handleOpenEditTemplate}
          >
            <Typography variant="button">
              Edit and Save Template
            </Typography>
          </Button>
        </Loader>
      </Box>
      <Popover
        open={!!listAnchorEl}
        anchorEl={listAnchorEl}
        TransitionComponent={Fade}
        onClose={handleCloseList}
      >
        <TemplatesListBody
          classes={classes}
          templatesList={templatesList}
          onClose={handleCloseList}
          onDelete={handleOpenConfirm}
          onSetTemplateModalOpen={onSetTemplateModalOpen}
          onSetCurrentTemplate={onSetCurrentTemplate}
          {...props}
        />
      </Popover>
      <ConfirmDialog
        open={openConfirmDelete}
        message={`Confrim delete ${templateToDelete.name} template?`}
        controls={[
          <Button
            onClick={handleCloseConfirm}
            color="primary"
            data-testid="cancel-delete-button"
            key="cancel-delete-button"
          >
            Disagree
          </Button>,
          <Button
            onClick={handleAgreeToDelete}
            color="primary"
            data-testid="confirm-delete-button"
            key="confirm-delete-button"
          >
            Agree
          </Button>,
        ]}
      />
    </Box>
  );
};

export default TemplatesList;
