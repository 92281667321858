// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  Loader,
  SetupCard,
} from "@fas/ui-core";
import { Box } from "@mui/material";
import CardGridContent from "../CardGridContent";
import { getPayoutAmount } from "../../helpers/generators";
import type { FieldItem } from "../CardGridContent/types/CardGridContent.types";
import type { Props, PaymentStatsType } from "./types/PaymentInfoCard.types";

const PaymentInfoCard: StatelessFunctionalComponent<Props> = ({
  loading,
  paymentInfo,
  currency,
}: Props) => {
  const {
    totalCost,
    totalCostExt,
    totalCostInt,
    totalPaidCost,
    totalPaidCostExt,
    totalPaidCostInt,
    totalUnpaidCost,
    totalUnpaidCostExt,
    totalUnpaidCostInt,
    totalUnpaidPeriod,
  }: PaymentStatsType = paymentInfo;
  const fieldsMapper: FieldItem[] = [
    { label: "Total unpaid cost, $", value: `${getPayoutAmount(currency, totalUnpaidCost)}`, color: "#C80022" },
    { label: "Total unpaid cost int, $", value: `${getPayoutAmount(currency, totalUnpaidCostInt)}`, color: "#C80022" },
    { label: "Total unpaid cost ext, $", value: `${getPayoutAmount(currency, totalUnpaidCostExt)}`, color: "#C80022" },
    { label: "Total unpaid period", value: totalUnpaidPeriod, rows: 3 },
    { label: "Total paid cost, $", value: `${getPayoutAmount(currency, totalPaidCost)}` },
    { label: "Total paid cost int, $", value: `${getPayoutAmount(currency, totalPaidCostInt)}` },
    { label: "Total paid cost ext, $", value: `${getPayoutAmount(currency, totalPaidCostExt)}` },
    { label: "Total cost, $", value: `${getPayoutAmount(currency, totalCost)}` },
    { label: "Total cost int, $", value: `${getPayoutAmount(currency, totalCostInt)}` },
    { label: "Total cost ext, $", value: `${getPayoutAmount(currency, totalCostExt)}` },
  ];

  return (
    <SetupCard title="Payment info">
      <Box mt={2} width={1}>
        <Loader loading={loading} size={35}>
          <CardGridContent
            fieldsMapper={fieldsMapper}
            gridCols={2}
            tableRows={2}
          />
        </Loader>
      </Box>
    </SetupCard>
  );
};

export default PaymentInfoCard;
