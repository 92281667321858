// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector, type StoreWithLoading } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { StoreWithCpaOffers, CpaOfferListLoadingTypes } from "./types";
import type { State as CpaOfferState } from "../../reducers/cpaofferIndex";
import type { ManagerList } from "../../actions/cpaoffer";

export const getIsChangeAuthorModalOpened: OutputSelector<StoreWithCpaOffers, *, boolean> = createSelector(
  (state: StoreWithCpaOffers): CpaOfferState => state.cpaOffer,
  (value: CpaOfferState): boolean => value.get("isChangeAuthorModalOpened")
);

export const getManagersList: OutputSelector<StoreWithCpaOffers, *, ManagerList> = createSelector(
  (state: StoreWithCpaOffers): CpaOfferState => state.cpaOffer,
  (value: CpaOfferState): ManagerList => value.get("managersList")
);

export const getIsManagersListLoaded: OutputSelector<StoreWithCpaOffers, *, boolean> = createSelector(
  (state: StoreWithCpaOffers): CpaOfferState => state.cpaOffer,
  (value: CpaOfferState): boolean => value.get("isManagersListLoaded")
);

export const getLoading: OutputSelector<
  StoreWithLoading<CpaOfferListLoadingTypes>, CpaOfferListLoadingTypes, boolean
> = createLoadingSelector<CpaOfferListLoadingTypes>();
