// @flow
import {
  GET_SMU_MARKETING_COMPANIES_SAGA,
} from "../../helpers/constants/smu";

export type GetSmuMarketingCompaniesSaga = {|
  type: typeof GET_SMU_MARKETING_COMPANIES_SAGA
|};

export type Actions = GetSmuMarketingCompaniesSaga;

export const getSmuMarketingCompaniesSaga: () => GetSmuMarketingCompaniesSaga = () => ({
  type: GET_SMU_MARKETING_COMPANIES_SAGA,
});
