// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State, StateObj } from "../../reducers/message";

type Store = $ReadOnly<{
  message: State,
  ... }>;
// eslint-disable-next-line import/prefer-default-export
export const getMessage: OutputSelector<Store, *, StateObj> = createSelector(
  (state) => state.message,
  (messageState) => (messageState.toJS(): any)
);
