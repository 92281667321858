/* eslint-disable max-classes-per-file, import/max-dependencies */
// @flow
import React, { type Node } from "react";
import { Typography } from "@mui/material";
import {
  ShowMoreItems,
} from "@fas/ui-core";
import ShowMoreText from "@fas/ui-core/lib/ShowMoreItems/ShowMoreText";
import type { Offer } from "./types";
import { transformOfferType } from "../../../helpers/generators/generators";
import CheckedIcon from "./CheckedIcon";
import LinkId from "./LinkId";
import GlobalCapWrapper from "./GlobalCapWrapper";
import LocationLink from "./Location";
import { Attribute } from "../defaultModels";

export class CpaOfferId extends Attribute {
  name = "id";

  render = ({ id, managerId, marketingGroups }: Offer): Node => (
    <LinkId id={id} tar managerId={managerId} marketingGroups={marketingGroups} />
  );
}

export class IsActive extends Attribute {
  name = "isActive";

  className = "smallTableCol";

  render = ({ isActive }:Offer): Node => <CheckedIcon value={Boolean(isActive)} />;
}

export class IsValid extends Attribute {
  name = "isValid";

  className = "smallTableCol";

  render = ({ isValid }: Offer): Node => (isValid === 2 ? "-" : <CheckedIcon value={Boolean(isValid)} />);
}

export class Account extends Attribute {
  name = "account";

  searchLimit = 0;

  render = ({ account }: Offer): Node => <ShowMoreText data={account || ""} />;
}

export class CpaOfferName extends Attribute {
  name = "name";

  className = "longTableCol";

  searchLimit = 0;

  render = ({ name }: Offer): Node => (
    <Typography style={{ wordBreak: "break-word", fontSize: "inherit" }}>{name}</Typography>
  );
}

export class IsExt extends Attribute {
  name = "isExt";

  className = "smallTableCol";

  render = ({ isExt }: Offer): Node => transformOfferType(isExt);
}

export class Location extends Attribute {
  name = "location";

  className = "veryLongTableCol";

  sortable = false;

  render = ({ location }: Offer): Node => <LocationLink location={location} />;
}

export class DataProvider extends Attribute {
  name = "dataProvider";

  searchLimit = 0;

  render = ({ dataProvider }: Offer): Node => <ShowMoreText data={dataProvider || ""} />;
}

export class Manager extends Attribute {
  name = "manager";

  render = ({ manager }: Offer): Node => <ShowMoreText data={manager || ""} />;
}

export class GlobalCap extends Attribute {
  name = "globalCap";

  render = ({ globalCap, globalCapCounter }: Offer): Node => (
    <GlobalCapWrapper globalCap={globalCap} globalCounter={globalCapCounter} />
  );
}

export class GlobalCapDistributionMode extends Attribute {
  name = "globalCapDistributionMode";

  className = "longTableCol";
}

export class GlobalCapLogic extends Attribute {
  name = "globalCapLogic";
}

export class IsPublic extends Attribute {
  name = "isPublic";

  className = "smallTableCol";

  render = ({ isPublic }: Offer): Node => (isPublic ? (
    <Typography style={{ color: "#2EB000" }}>Public</Typography>
  ) : (
    <Typography style={{ color: "#C80022" }}>Private</Typography>
  ));
}

export class OfferType extends Attribute {
  name = "offerType";
}

export class IsAvailableForAllGroups extends Attribute {
  name = "isAvailableForAllGroups";

  render = ({ isAvailableForAllGroups }: Offer): Node => (
    <CheckedIcon value={Boolean(isAvailableForAllGroups)} />
  );
}

export class BlackList extends Attribute {
  name = "blackList";

  sortable = false;

  render = ({ blackList }: Offer): Node => <ShowMoreText data={blackList || ""} />;
}

export class Description extends Attribute {
  name = "description";

  className = "longTableCol";

  sortable = false;

  render = ({ description }: Offer): Node => <ShowMoreText data={description || ""} />;
}

export class Restriction extends Attribute {
  name = "restriction";

  className = "longTableCol";

  sortable = false;

  render = ({ restriction }: Offer): Node => <ShowMoreText data={restriction || ""} />;
}

export class LinkValidationType extends Attribute {
  name = "linkValidationType";

  sortable = false;

  render = ({ linkValidationType }: Offer): Node => (linkValidationType === null ? "-" : linkValidationType);
}

export class AdverIDAttr extends Attribute {
  name = "adverIDAttr";

  sortable = false;

  className = "smallTableCol";
}

export class AdverIDValue extends Attribute {
  name = "adverIDValue";

  sortable = false;

  className = "smallTableCol";
}

export class Group extends Attribute {
  name = "group";

  searchLimit = 0;

  render = ({ group }: Offer): Node => <ShowMoreText data={group || ""} />;
}

export class Vertical extends Attribute {
  name = "vertical";

  render = ({ vertical }: Offer): Node => <ShowMoreText data={vertical || ""} />;
}

export class Niche extends Attribute {
  name = "niche";

  searchLimit = 0;

  render = ({ niche }: Offer): Node => <ShowMoreText data={niche || ""} />;
}

export class Country extends Attribute {
  name = "country";

  render = ({ country }: Offer): Node => <ShowMoreItems data={country ? country.split(",") : []} />;

  searchLimit = -1;
}

export class Platform extends Attribute {
  name = "platform";

  className = "smallTableCol";
}

export class Currency extends Attribute {
  name = "currency";

  className = "smallTableCol";

  searchLimit = -1;
}

export class PayoutAmount extends Attribute {
  name = "payoutAmount";

  render = ({ payoutAmount }: Offer): Node => payoutAmount || "";
}

export class CreatedAt extends Attribute {
  name = "createdAt";
}

export class UpdatedAt extends Attribute {
  name = "updatedAt";
}

export class CreatedBy extends Attribute {
  name = "createdBy";

  className = "longTableCol";

  render = ({ createdBy }: Offer): Node => <ShowMoreText data={createdBy || ""} />;
}

export class UpdatedBy extends Attribute {
  name = "updatedBy";

  className = "longTableCol";

  render = ({ updatedBy }: Offer): Node => <ShowMoreText data={updatedBy || ""} />;
}

export class PartnerOfferName extends Attribute {
  name = "partnerOfferName";

  searchLimit = 0;

  className = "longTableCol";

  render = ({ partnerOfferName }: Offer): Node => (
    <Typography style={{ wordBreak: "break-word", fontSize: "inherit" }}>{partnerOfferName}</Typography>
  );
}

export class NextPartnerCommission extends Attribute {
  name = "nextPartnerCommission";

  sortable = false;
}

export class PartnerCommission extends Attribute {
  name = "partnerCommission";

  sortable = false;
}

export class FrequencyCap extends Attribute {
  name = "frequencyCap";

  className = "smallTableCol";

  render = ({ frequencyCap }: Offer): Node => <Typography>{frequencyCap ? "on" : "off"}</Typography>;
}

export class FunnelCounter extends Attribute {
  name = "funnel_counter";

  className = "smallTableCol";
}

export class ReadyForGdpr extends Attribute {
  name = "readyForGdpr";

  className = "smallTableCol";

  sortable = false;

  render = ({ readyForGdpr }:Offer): Node => (readyForGdpr === null ? "-" : <CheckedIcon value={Boolean(readyForGdpr)} />);
}
