// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Table } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { CRM_MAILS_TABLE } from "../../helpers/constants/crm";
import {
  getCrmMailsListSaga,
  changeCrmMailsStatusSaga,
  sendCrmMailsSaga,
  type GetCrmMailsListSaga,
  type ChangeCrmMailsStatusSaga,
  type SendCrmMailsSaga,
} from "../../actions/crmMails";
import type { CrmMailsListData, Classes } from "./CrmMailsList.types";
import { useDropdownsStaticList, useList } from "../../hoocks/useDictionary";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import Actions from "./Actions";
import getColumns from "./getColumns";

const useStyles: () => Classes = makeStyles(() => ({
  smallTableCol: {
    width: "80px",
    minWidth: "80px",
  },
  middleTableCol: {
    width: "120px",
    minWidth: "120px",
  },
  longTableCol: {
    width: "180px",
    minWidth: "180px",
  },
}));

const CrmMailsList: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();
  const history = useNavigate();
  const tableProps: TableProps = useTable(CRM_MAILS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const { selected }: TableProps = tableProps;
  useDropdownsStaticList("mail");
  const statusMsg: Array<DropDownObjItemType> = useList("statusMsg");
  const userPlatforms: Array<DropDownObjItemType> = useList("userPlatforms");

  const onGetCrmMails: () => GetCrmMailsListSaga = () => (
    dispatch(getCrmMailsListSaga()));

  const onChangeCrmMailsStatus: (status: string) => ChangeCrmMailsStatusSaga = (status) => (
    dispatch(changeCrmMailsStatusSaga(status)));

  const onSendCrmMails: (type: string) => SendCrmMailsSaga = (type) => (
    dispatch(sendCrmMailsSaga(type)));

  const onCreate: () => void = () => {
    history("/crm/mails/create");
  };

  const onEdit: () => void = () => {
    const { id } = selected[0];
    history(`/crm/mails/${id}`);
  };

  const columns: Array<Column<CrmMailsListData>> = getColumns(classes, { statusMsg, userPlatforms });

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Mails"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetCrmMails}
        Actions={(
          <Actions
            onEdit={onEdit}
            onChangeCrmMailsStatus={onChangeCrmMailsStatus}
            onSendCrmMails={onSendCrmMails}
          />
        )}
      />
    </Box>
  );
};

export default CrmMailsList;
