// @flow
import React, { useCallback, useState } from "react";
import {
  Box, Button, Dialog, FormControlLabel, Switch, Typography,
} from "@mui/material";
import { ArrowDropDown, CheckCircleOutline } from "@mui/icons-material";
import type { User } from "@fas/ui-framework/lib/services/user";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import type { ChangeTableFilters } from "@fas/ui-framework/lib/redux/actions/table";
import { userService } from "../../services/user";
import { TemplatesListWithHOC } from "../TemplatesList";
import { getSubratesTemplates } from "../../services/subratesApi";
import TemplateModal from "../TemplateModal/TemplateModalSubrates";
import type { SaveTemplateSagaType } from "../../actions/subrates";
// eslint-disable-next-line import/max-dependencies
import type { TemplateObjType } from "../../reducers/subrates";

type useStateType<S> = [S, ((S => S) | S) => void];

type Props = {
  template: TemplateObjType,
  onTemplateChange: (TemplateObjType) => void,
  onSaveTemplate: (TemplateObjType) => SaveTemplateSagaType,
  filters: Filters,
  onChangeFilters: (Filters) => ChangeTableFilters,
}

const SubratesActionRow = (props: Props) => {
  const {
    template, onTemplateChange, onSaveTemplate, filters, onChangeFilters,
  }: Props = props;
  const [templateListState, setTemplateListState]: useStateType<boolean> = useState(false);
  const [templateModalState, setTemplateModalState]: useStateType<boolean> = useState(false);
  const [chiocedTemplate, setChiocedTemplate]: useStateType<TemplateObjType> = useState({
    name: "",
    headersOrder: [],
  });
  const user: User | null = userService.getUser();
  const canUserUpdate = userService.can([{ path: "/api/v1/dictionary/subrate", method: "POST" }]);

  const onChangeOnlyMy = useCallback((e) => {
    const val = {
      ...filters,
    };
    delete val.affiliateManager;
    if (e.target.checked && user) {
      val.affiliateManager = user.id;
    }
    onChangeFilters(val);
  });

  return (
    <Box display="flex" justifyContent="flex-start" mb={2}>
      <Box display="flex" position="relative" alignItems="center">
        <Button
          className="white-btn"
          variant="outlined"
          startIcon={<CheckCircleOutline />}
          endIcon={<ArrowDropDown />}
          onClick={() => {
            setTemplateListState(true);
          }}
          data-testid="template-list-open-btn"
        >
          <Typography variant="button">
            Template
          </Typography>
        </Button>
        {templateListState && (
          <TemplatesListWithHOC
            fetchMethod={getSubratesTemplates}
            onClickAway={() => setTemplateListState(false)}
            onAddNew={() => {
              setTemplateModalState(true);
              setTemplateListState(false);
              setChiocedTemplate({ name: "", headersOrder: template.headersOrder });
            }}
            onSelect={(temp) => {
              setTemplateModalState(true);
              setTemplateListState(false);
              setChiocedTemplate({ id: temp.id, name: temp.name, headersOrder: temp.headersOrder });
            }}
          />
        )}
      </Box>
      <Box ml={2} display="flex" alignItems="center">
        <FormControlLabel
          control={(
            <Switch
              checked={!!filters.affiliateManager}
              color="secondary"
              onChange={onChangeOnlyMy}
              data-testid="affiliateManager"
            />
          )}
          label="Show subrates of my affiliates"
        />
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={templateModalState}
        onClose={() => {
          setTemplateModalState(false);
        }}
      >
        <TemplateModal
          onSave={(val) => {
            onSaveTemplate(val);
          }}
          onApply={(val) => {
            setTemplateModalState(false);
            onTemplateChange(val);
          }}
          onCancel={() => {
            setTemplateModalState(false);
          }}
          template={chiocedTemplate}
          disableSave={!canUserUpdate}
        />
      </Dialog>
    </Box>
  );
};

export default SubratesActionRow;
