// @flow
import React, { useEffect, useRef, useState } from "react";
import type { AxiosPromise } from "axios";
import { throttle } from "lodash";
import TemplatesList from "./TemplatesList";

type useStateType<S> = [S, ((S => S) | S) => void];
type TemplateType = {
  id: string,
  name: string,
  headersOrder: Array<string>,
  author: string,
  department: string,
};

type Props = {
  onClickAway: () => void,
  fetchMethod: (string) => AxiosPromise<mixed, Array<TemplateType>>,
  onAddNew: () => void,
  onSelect: (any) => void,
}

const TemplatesListWithHOC = (props: Props) => {
  const { onClickAway, fetchMethod, onAddNew, onSelect } = props;
  const [searchString, setSearchString]: useStateType<string> = useState("");
  const [templatesList, setTemplatesList]: useStateType<Array<TemplateType>> = useState([]);
  const [isLoadingTemplate, setIsLoadingTemplate]: useStateType<boolean> = useState(false);

  const fetchTemplates = async (val) => {
    setIsLoadingTemplate(true);
    const result = await fetchMethod(val);
    setTemplatesList(result.data.data);
    setIsLoadingTemplate(false);
  };

  const throttled = useRef(throttle((option: string) => {
    if (option.length > 1) {
      fetchTemplates(option);
    }
  }, 1000));

  useEffect(() => {
    if (searchString) {
      throttled.current(searchString);
    }
  }, [searchString]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  return(
    <TemplatesList
      onClickAway={onClickAway}
      templatesList={templatesList}
      onSearchStringChange={setSearchString}
      onAddNew={onAddNew}
      onSelect={onSelect}
      isLoadingTemplate={isLoadingTemplate}
    />
  );
}

export default TemplatesListWithHOC;
