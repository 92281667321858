// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  type StatelessFunctionalComponent,
  type Element,
  type Node,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  EditOutlined,
  CheckCircleOutlined,
  AccessTimeOutlined,
  BlockOutlined,
} from "@mui/icons-material";
import {
  Table,
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  ConfirmTableActionButton,
  SelectSearchComponent,
  MultiselectSearchComponent,
  IsActiveLabel,
  ViewLink,
  LessMore,
  ShowMoreItems,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import Can from "../../components/Can";
import {
  changeStatusPotentialPartnerSaga,
  getPotentialPartnerListSaga,
  type GetPotentialPartnerSagaType,
  type ChangeStatusPotentialPartnerSagaType,
} from "../../actions/potentialPartner";
import { POTENTIAL_PARTNER_TABLE } from "../../helpers/constants/potentialPartner";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import {
  getPotentialPartnerDropdownSaga,
  type GetPotentialPartnerDropdownSagaType,
} from "../../actions/potentialPartnerForm";
import type { Classes, PotentialPartnerType } from "./types/PotentialPartner.types";
import { useList } from "../../hoocks/useDictionary";

const onCreate: () => void = () => {
  window.location = "/potentialPartner/create";
};

const statusIconMap: { [string]: Node } = {
  approved: <CheckCircleOutlined style={{ color: "#2EB000" }} />,
  pending: <AccessTimeOutlined style={{ color: "#FF9900" }} />,
  declined: <BlockOutlined style={{ color: "#C80022" }} />,
};

const useStyles: () => Classes = makeStyles((): {[key: $Keys<Classes>]: mixed } => ({
  smallTableCol: {
    width: "120px",
    minWidth: "120px",
    wordBreak: "break-all",
  },
  middleTableCol: {
    minWidth: "160px",
    maxWidth: "160px",
    wordBreak: "break-all",
  },
  longTableCol: {
    minWidth: "250px",
    maxWidth: "250px",
    wordBreak: "break-all",
  },
  veryLongTableCol: {
    minWidth: "350px",
    maxWidth: "350px",
    wordBreak: "break-all",
  },
}));

const PotentialPartner: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();

  const tableProps: TableProps = useTable(POTENTIAL_PARTNER_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const { selected }: TableProps = tableProps;
  const dropdownListsStatus: DropDownObjItemType[] = useList("status");
  const dropdownListsCountry: DropDownObjItemType[] = useList("country");
  const dropdownListsAccountType: DropDownObjItemType[] = useList("accountType");
  const dropdownListsVertical: DropDownObjItemType[] = useList("vertical");

  const onChangeStatusPotentialPartnerSaga: (status: string) => ChangeStatusPotentialPartnerSagaType = (string) => (
    dispatch(changeStatusPotentialPartnerSaga(string)));
  const onGetPotentialPartnerListSaga: () => GetPotentialPartnerSagaType = () => (
    dispatch(getPotentialPartnerListSaga()));
  const onGetPotentialPartnerDropdownSaga: () => GetPotentialPartnerDropdownSagaType = () => (
    dispatch(getPotentialPartnerDropdownSaga()));

  const columns: Array<Column<PotentialPartnerType>> = [
    {
      field: "id",
      label: "ID",
      searchable: true,
      sortable: true,
      className: classes.smallTableCol,
      render: ({ id }: PotentialPartnerType): Element<typeof Can> => (
        <Can
          permissions={[
            { path: "/api/v1/potentialPartner", method: "PUT" },
          ]}
          renderNoAccess={id}
        >
          <ViewLink id={id} name={id} viewPath="potentialPartner/:id" />
        </Can>
      ),
    },
    {
      field: "name",
      label: "Name",
      searchable: true,
      className: classes.longTableCol,
    },
    {
      field: "status",
      label: "Status",
      className: classes.smallTableCol,
      searchable: true,
      render: ({ status }: PotentialPartnerType): Node => statusIconMap[status],
      SearchComponent: (
        searchProps: SearchComponentProps
      ):Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownListsStatus}
        />
      ),
    },
    {
      field: "email",
      label: "Email",
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "skype",
      label: "Skype",
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "companyName",
      label: "Company name",
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "country",
      label: "Country",
      searchable: true,
      className: classes.longTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ):Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownListsCountry}
        />
      ),
    },
    {
      field: "accountType",
      label: "Account type",
      searchable: true,
      className: classes.smallTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ):Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          values={dropdownListsAccountType}
        />
      ),
    },
    {
      field: "vertical",
      label: "Vertical",
      searchable: true,
      className: classes.middleTableCol,
      render: ({ vertical }: PotentialPartnerType): Element<typeof ShowMoreItems> => {
        const items: string[] = vertical.split(",");
        return <ShowMoreItems data={items} />;
      },
      SearchComponent: (
        searchProps: SearchComponentProps
      ):Element<typeof MultiselectSearchComponent> => (
        <MultiselectSearchComponent
          {...searchProps}
          // $FlowFixMe MTU-94094
          options={dropdownListsVertical}
        />
      ),
    },
    {
      field: "isSubscribed",
      label: "Subscribe",
      searchable: true,
      className: classes.smallTableCol,
      render: ({ isSubscribed }: PotentialPartnerType): Element<typeof IsActiveLabel> => (
        <IsActiveLabel isActive={isSubscribed} />
      ),
      SearchComponent: (
        searchProps: SearchComponentProps
      ):Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
        />
      ),
    },
    {
      field: "referrer",
      label: "Referrer URL",
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "visitUrl",
      label: "Visit URL",
      searchable: true,
      className: classes.middleTableCol,
    },
    {
      field: "additionalData",
      label: "Additional data",
      className: classes.middleTableCol,
      render: ({ additionalData }: PotentialPartnerType): Element<typeof LessMore> => (
        <LessMore maxLength={30} value={additionalData} />
      ),
    },
  ];

  useEffect(() => {
    onGetPotentialPartnerDropdownSaga();
  }, []);

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Potential Partners"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetPotentialPartnerListSaga}
        Actions={(
          <TableActions>
            <TableActionGroup>
              <SimpleTableActionButton
                tooltip="Edit"
                disabled={selected.length > 1}
                onClick={() => {
                  const { id }: PotentialPartnerType = selected[0];
                  window.location = `/potentialPartner/${id}`;
                }}
                Icon={<EditOutlined />}
                data-testid="editButton"
              />
            </TableActionGroup>
            <TableActionGroup>
              <ConfirmTableActionButton
                items={selected}
                tooltip="Changing status to approved"
                confirmTitle="Changing status"
                confirmText="Are you sure you want to change the status for this potential partner? "
                onClick={(): ChangeStatusPotentialPartnerSagaType => onChangeStatusPotentialPartnerSaga("approved")}
                Icon={<CheckCircleOutlined />}
                viewPath="potentialPartner/:id"
              />
              <ConfirmTableActionButton
                items={selected}
                tooltip="Changing status to declined"
                confirmTitle="Changing status"
                confirmText="Are you sure you want to change the status for this potential partner? "
                onClick={(): ChangeStatusPotentialPartnerSagaType => onChangeStatusPotentialPartnerSaga("declined")}
                Icon={<BlockOutlined />}
                viewPath="potentialPartner/:id"
              />
              <ConfirmTableActionButton
                items={selected}
                tooltip="Changing status to pending"
                confirmTitle="Changing status"
                confirmText="Are you sure you want to change the status for this potential partner? "
                onClick={(): ChangeStatusPotentialPartnerSagaType => onChangeStatusPotentialPartnerSaga("pending")}
                Icon={<AccessTimeOutlined />}
                viewPath="potentialPartner/:id"
              />
            </TableActionGroup>
          </TableActions>
        )}
      />
    </Box>
  );
};

export default PotentialPartner;
