// @flow
import React, { type Node, useMemo } from "react";
import DocumentTitle from "react-document-title";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import PayoutTermsList from "../../containers/PayoutTermsList";

function PayoutTerms(): Node {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="CPA - Payout Terms">
      <Provider store={store}>
        <PayoutTermsList />
        <NotificationsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default PayoutTerms;
