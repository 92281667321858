import { useParams, useLocation } from "react-router-dom";
import { getQueryStringValue } from "../../../helpers/query/queryParamsHelper";

function useAffiliateId() {
  const { affiliateId } = useParams<{ affiliateId: string }>();

  return (affiliateId || "");
}

export function useAffiliateSearchQuery() {
  const location = useLocation();

  return getQueryStringValue("q", "", location.search.slice(1));
}

export default useAffiliateId;
