// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import Immutable from "immutable";
import thunkMiddleware from "redux-thunk";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import dashboard, { initState, type State as DashboardState, type Action as DashboardAction } from "../../reducers/dashboard";

export type State = $ReadOnly<{|
  dashboard: DashboardState,
  notifications: NotificationsState,
|}>

function mapPaymentInfoToState(): State {
  return {
    dashboard: initState(),
    notifications: initNotificationsState(),
  };
}

type Actions = DashboardAction | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  dashboard,
  notifications,
});

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;
  const middlewares = [thunkMiddleware];

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPaymentInfoToState(),
    composeEnhancers(applyMiddleware(
      ...middlewares
    ))
  );

  return store;
};
