// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, call, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getAffiliateId } from "../../selectors/payments";
import { GET_AFFILIATE_INFO_SAGA, NEXT_PAYMENT_INFO_LOADING } from "../../helpers/constants/payments";
import {
  fetchPaymentStats,
} from "../../services/payments";
import {
  setEmptyPaymentInfoState,
  setPaymentStats,
} from "../../actions/paymentInfo";
import type { LoadingTypes } from "../../selectors/loading/types";
import type { PaymentStatsType } from "../../components/PaymentInfoCard";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setEmptyPaymentInfoState(["paymentInfo"]));
    yield put(setLoading<LoadingTypes>(NEXT_PAYMENT_INFO_LOADING, true));
    const affId: string = yield select(getAffiliateId);

    const paymentStatsResponse = yield call(fetchPaymentStats, affId);

    const {
      data: paymentStats,
    }: {data: PaymentStatsType } = paymentStatsResponse;

    yield put(setPaymentStats(paymentStats));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(NEXT_PAYMENT_INFO_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_AFFILIATE_INFO_SAGA, (makeFetch: Function));
}
