// @flow
import {
  GET_SMU_BRAND_LIST_SAGA,
  CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA,
} from "../../helpers/constants/smu";

export type GetSmuBrandsSaga = {|
  type: typeof GET_SMU_BRAND_LIST_SAGA
|};

export type ChangeSmuBrandsActivesSaga = {|
  type: typeof CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA,
  isActive: boolean,
|};

export type Actions = GetSmuBrandsSaga
  | ChangeSmuBrandsActivesSaga;

export const getSmuBrandsSaga: () => GetSmuBrandsSaga = () => ({
  type: GET_SMU_BRAND_LIST_SAGA,
});

export const changeSmuBrandsActivesSaga: (
  isActive: boolean
) => ChangeSmuBrandsActivesSaga = (isActive) => ({
  type: CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA,
  isActive,
});
