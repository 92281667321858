// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetPayoutTermsLists from "../../sagas/getPayoutTermsList";
import watchChangePayoutTermsActives from "../../sagas/changePayoutTermsActives";
import watchGetDropdownListSaga from "../../sagas/getDropdownList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetPayoutTermsLists),
    call(watchGetDropdownListSaga),
    call(watchChangePayoutTermsActives),
  ]);
}
