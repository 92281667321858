// @flow

export const CRM_MAILS_TABLE: "CRM_MAILS_TABLE" = "CRM_MAILS_TABLE";
export const CRM_MAILS_FORM: "CRM_MAILS_FORM" = "CRM_MAILS_FORM";
export const CHANGE_CRM_MAILS_STATUS_SAGA: "CHANGE_CRM_MAILS_STATUS_SAGA" = "CHANGE_CRM_MAILS_STATUS_SAGA";
export const SEND_CRM_MAILS_SAGA: "SEND_CRM_MAILS_SAGA" = "SEND_CRM_MAILS_SAGA";
export const FORM_KEY_CRM_MAILS: "FORM_KEY_CRM_MAILS" = "FORM_KEY_CRM_MAILS";
export const FORM_KEY_CRM_TEMPLATE: "FORM_KEY_CRM_TEMPLATE" = "FORM_KEY_CRM_TEMPLATE";

export const CRM_MAILS_TARGETING_TYPES: { country: string, email: string } = {
  country: "country",
  email: "email",
};
export const CRM_MAILS_USER_TYPES: { affiliate: string, advertiser: string } = {
  affiliate: "affiliate",
  advertiser: "advertiser",
};

export const CRM_TEMPLATES_TABLE: "CRM_TEMPLATES_TABLE" = "CRM_TEMPLATES_TABLE";
export const DELETE_CRM_TEMPLATES_SAGA: "DELETE_CRM_TEMPLATES_SAGA" = "DELETE_CRM_TEMPLATES_SAGA";
export const GET_CRM_TEMPLATES_LIST_SAGA: "GET_CRM_TEMPLATES_LIST_SAGA" = "GET_CRM_TEMPLATES_LIST_SAGA";
