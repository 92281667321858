// @flow
import {
  call,
  put,
  select,
  takeEvery,
  all,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { DELETE_CRM_TEMPLATES_SAGA, CRM_TEMPLATES_TABLE } from "../../helpers/constants/crm";
import { getCrmTemplatesListSaga } from "../../actions/crmTemplates";
import type { CrmTemplateListData } from "../../containers/CrmTemplatesList";
import { deleteCrmTemplate } from "../../services/crmTemplatesApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(CRM_TEMPLATES_TABLE, true));

    const selections: Array<CrmTemplateListData> = yield select(getTableSelections, CRM_TEMPLATES_TABLE);
    const ids: string[] = selections.map(({ id }: CrmTemplateListData): string => String(id));

    const requests = ids.map((id: string) => call(deleteCrmTemplate, id));

    yield all(requests);

    yield put(addNotification({ message: "Successfully deleted", severity: "success" }));
    yield put(setTableSelection(CRM_TEMPLATES_TABLE, []));

    yield put(getCrmTemplatesListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: "Error while deleting", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CRM_TEMPLATES_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(DELETE_CRM_TEMPLATES_SAGA, (makeFetch: Function));
}
