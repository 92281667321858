// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { Link, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { userService } from "../../user";
import { userHaveSomeMarketingGroup } from "../../../pages/CpaOffer/components/Form/Form";

type Props = {
  id: string,
  managerId: string | null,
  marketingGroups: number[],
}

type Classes = {
  disabledLink: mixed,
}

const useStyles: () => Classes = makeStyles((theme: Theme): Classes => ({
  disabledLink: {
    pointerEvents: "none",
    color: theme.palette.borderColor,
  },
}));

const LinkId: StatelessFunctionalComponent<Props> = ({
  id,
  managerId,
  marketingGroups,
}: Props) => {
  const classes: Classes = useStyles();

  const isAllowedLink: boolean = userHaveSomeMarketingGroup(marketingGroups)
  || managerId === userService.getId()
  || userService.can(["cpa.offers.editAllOffers"]);

  return (
    <Link
      href={`/cpaOffers/${id}`}
      target="_blank"
      className={isAllowedLink ? "" : classes.disabledLink}
      underline="hover"
    >
      {id}
    </Link>
  );
};

export default React.memo<Props>(LinkId);
