// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  Box, Dialog, Paper, Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  SelectSearchComponent, Table,
} from "@fas/ui-core";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import type { Element } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Actions from "./components/Actions";
import {
  changeIsAddAdditionalPostbackModalOpen,
  getPostbacksInfoSaga,
} from "../../../../../../actions/postbacks";
import type {
  ManageAffiliate,
} from "../../../../../../reducers/manageAffiliate";
import PostbackForm from "./components/PostbackForm/PostbackForm";
import { getIsAdditionalPostbackModalOpen } from "../../../../../../selectors/manageAffiliate";
import { ADDITIONAL_POSTBACKS_TABLE } from "../../../../../../helpers/constants/postbacks";
import type { GetPostbacksInfoSaga } from "../../../../../../actions/postbacks";
import type { Offer } from "../../../CampaignSettings/types/CampaignSettings.types";
import CountryCell from "../../../CampaignSettings/components/CountryCell";
import Status from "./components/Status";

type Props = {
  manageAffiliate: ManageAffiliate,
  actionsTable: Object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

const mapStateToProps = (state) => ({
  manageAffiliate: state.manageAffiliate,
  isModalOpen: getIsAdditionalPostbackModalOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleOpenModal: (val) => dispatch(changeIsAddAdditionalPostbackModalOpen(val)),
});

const Postbacks = ({
  manageAffiliate,
  isModalOpen,
  handleOpenModal,
}: Props) => {
  const classes = useStyles();

  const tableProps: TableProps = useTable(ADDITIONAL_POSTBACKS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetPostbacksInfoSaga: () => GetPostbacksInfoSaga = () => (
    dispatch(getPostbacksInfoSaga()));

  const postbackInfo = manageAffiliate.getIn(["postbackInfo"]).toJS();
  const {
    lists: {
      conversionTypes = [],
      countries = [],
    },
  } = postbackInfo;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.value + option.title,
  });

  const columns = [
    {
      field: "id",
      label: "Actions",
      render: (row) => (<Actions row={row} />),
    },
    {
      label: "Additional postback link",
      field: "url",
      sortable: false,
      searchable: true,
    },
    {
      field: "conversionType",
      label: "Conversion type",
      sortable: false,
      searchable: true,
      render: (row: Offer): Element<typeof CountryCell> => {
        const { conversionType }: Offer = row;
        return (
          <Typography style={{ fontSize: "inherit", wordBreak: "break-all" }}>
            {conversionType}
          </Typography>
        );
      },
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          filterOptions={filterOptions}
          values={conversionTypes.map((item) => ({ title: item, value: item }))}
        />
      ),
    },
    {
      field: "offerName",
      label: "Cpa offer",
      render: (row) => {
        const { offerName } = row;
        return (
          <Typography style={{ fontSize: "inherit", wordBreak: "break-all" }}>
            {offerName}
          </Typography>
        );
      },
    },
    {
      field: "country",
      label: "Country",
      sortable: false,
      searchable: true,
      render: (row: Offer): Element<typeof CountryCell> => {
        const { country }: Offer = row;
        return (
          <Typography style={{ fontSize: "inherit", wordBreak: "break-all" }}>
            {country}
          </Typography>
        );
      },
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          filterOptions={filterOptions}
          values={countries.map((item) => ({ title: item, value: item }))}
        />
      ),
    },
    {
      field: "isActive",
      label: "Status",
      sortable: false,
      searchable: true,
      render: (row: Offer): Element<typeof CountryCell> => {
        const { isActive }: Offer = row;
        return (
          <Status value={isActive} />
        );
      },
      SearchComponent: (searchProps): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          filterOptions={filterOptions}
          values={[
            { title: "All", value: "" },
            { title: "Inactive", value: "0" },
            { title: "Active", value: "1" },
          ]}
        />
      ),
    },
  ];

  return (
    <Paper
      className={classes.root}
      elevation={3}
    >
      <Box width="100%" data-testid="additional-postbacks">
        {/* $FlowFixMe */}
        <Table
          {...tableProps}
          title="Additional postbacks"
          rowSelectAvailable={(): boolean => false}
          allSelectAvailable={(): boolean => false}
          columns={columns}
          onLoad={onGetPostbacksInfoSaga}
          onCreate={() => handleOpenModal(true)}
        />
      </Box>
      <Dialog
        open={isModalOpen}
        maxWidth={false}
      >
        <PostbackForm
          lists={postbackInfo.lists}
        />
      </Dialog>
    </Paper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Postbacks);
