// @flow
import React from "react";
import {
  Typography,
} from "@mui/material";
import Actions from "./components/Actions";

const stylingOfferName = { fontSize: "inherit" };
export const columns = [
  {
    field: "eventId",
    label: "Actions",
    render: (row) => (
      <Actions row={row} />
    ),
  },
  {
    field: "eventType",
    label: "Event type",
  },
  {
    field: "event",
    label: "Event",
  },
  {
    field: "offerName",
    label: "Cpa offer",
    render: (row) => {
      const { offerName } = row;
      return (
        <Typography style={stylingOfferName}>
          {offerName || "all"}
        </Typography>
      );
    },
  },
  {
    field: "country",
    label: "Country",
    render: ({ country }) => (
      <Typography style={stylingOfferName}>
        {country || "all"}
      </Typography>
    ),
  },
  {
    field: "eventUrl",
    label: "Event code",
    render: (row) => {
      const { eventUrl } = row;
      return (
        <Typography style={{ fontSize: "inherit", maxWidth: 500, wordBreak: "break-all" }}>
          {eventUrl}
        </Typography>
      );
    },
  },
];

export const dataTable = [
  {
    eventType: "negative",
    event: "chargeback",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
  {
    eventType: "info",
    event: "registration",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
  {
    eventType: "negative",
    event: "chargeback",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
  {
    eventType: "negative",
    event: "chargeback",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
  {
    eventType: "negative",
    event: "chargeback",
    eventUrl: "https://jira.togethernetworks.com/",
  },
  {
    eventType: "negative",
    event: "chargeback",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
  {
    eventType: "info",
    event: "registration",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
  {
    eventType: "negative",
    event: "chargeback",
    eventUrl: "https://jira.togethernetworks.com/browse/MTU-43763",
  },
];

export const mapCountry = (value) => {
  if (value && value.country) {
    return value.country.split(",");
  }

  return ["all"];
};

export const mapOfferId = (value) => {
  if (value) {
    return {
      id: value.offerId || "all",
      name: value.offerName || "all",
    };
  }

  return {
    id: 0,
    name: "",
  };
};

export const sanitizeOfferId = (value) => {
  if (value === "all") {
    return null;
  }

  return value || null;
};

export const sanitizeFieldCountry = (value) => {
  if (value && value[0] === "all") {
    return [];
  }

  return value || [];
};
