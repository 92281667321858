// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetProductCompanyList from "../../sagas/getProductCompanyList";
import watchChangeProductCompanyActives from "../../sagas/changeProductCompanyActives";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetProductCompanyList),
    call(watchChangeProductCompanyActives),
  ]);
}
