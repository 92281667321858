// @flow
import { type Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getConfig,
  getCurrentTemplate,
  getLoading,
  getReportName,
  type StoreWithTemplates as State,
} from "../../selectors/templates";
import {
  setCurrentTemplate,
  changeCurrentTemplateAttribute,
  setCurrentTemplateAttributesList,
  setCurrentTemplateAttributeVisibility,
  setCurrentTemplateData,
  setActiveTemplate,
  setTemplateModalOpen,
  saveTemplateSaga,
  exportTemplateSaga,
} from "../../actions/templates";
import type {
  StateProps,
  DispatchProps,
  OwnProps,
  Props,
} from "./TemplateModal.types";
import TemplateModal from "../../components/TemplateComponents/TemplateModal";
import { EXPORT_TEMPLATE_SAGA } from "../../helpers/constants/templates";

const mapStateToProps: (state: State) => StateProps = (state: State) => ({
  config: getConfig(state),
  reportName: getReportName(state),
  currentTemplate: getCurrentTemplate(state),
  isSavingTemplate: getLoading(state, "saveTemplate"),
  isModalConfigLoading: getLoading(state, "getTemplatesConfig"),
  isExportTemplateLoading: getLoading(state, EXPORT_TEMPLATE_SAGA),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchProps = (dispatch) => bindActionCreators({
  onSetTemplateModalOpen: setTemplateModalOpen,
  onSetCurrentTemplateData: setCurrentTemplateData,
  onSetCurrentTemplate: setCurrentTemplate,
  onChangeCurrentTemplateAttribute: changeCurrentTemplateAttribute,
  onSetCurrentTemplateAttributesList: setCurrentTemplateAttributesList,
  onSetCurrentTemplateAttributeVisibility: setCurrentTemplateAttributeVisibility,
  onSetActiveTemplate: setActiveTemplate,
  onSaveTemplate: saveTemplateSaga,
  onExportTemplate: exportTemplateSaga,
},
dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TemplateModal);
