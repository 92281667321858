// @flow
import React from "react";
import {
  Box,
} from "@mui/material";
import ApiContent from "./components/ApiContent";

const ApiPage = () => (
  <Box
    display="flex"
    alignItems="stretch"
    justifyContent="stretch"
    flexDirection="column"
    m={2}
    mb={3}
  >
    <ApiContent />
  </Box>
);

export default ApiPage;
