// @flow
import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import {
  DateRange as DateRangeIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import type { UseStylesTypes } from "../../../helpers/types";

const styles: UseStylesTypes = makeStyles((theme: *): { [string]: * } => ({
  inputClass: {
    backgroundColor: "#fff",
    borderRadius: theme.borderRadius,
    minWidth: "210px",
    border: "1px solid #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  iconRange: {
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "4px",
    margin: "auto",
  },
}));

export type Props = {
  startDate: string,
  endDate: string,
  label: string,
  isSelected: boolean,
  onIconClick: () => void,
}

const DateInputComponent: StatelessFunctionalComponent<Props> = ({
  startDate,
  endDate,
  label,
  isSelected,
  onIconClick,
}: Props) => {
  const classes: { [string]: string } = styles();
  return (
    <Box className={classes.inputClass}>
      <IconButton
        size="small"
        onClick={onIconClick}
        data-testid="calendar-icon"
      >
        <DateRangeIcon />
      </IconButton>
      <Box>
        <Box textAlign="center">{label}</Box>
        {isSelected && (
          <Box>
            {startDate}
            {" - "}
            {endDate}
          </Box>
        )}
      </Box>
      <IconButton
        size="small"
        onClick={onIconClick}
        data-testid="calendar-icon"
      >
        <ArrowDropDownIcon />
      </IconButton>
    </Box>
  );
};

export default DateInputComponent;
