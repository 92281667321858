// @flow

type Redirect = {|
  to: string,
  target?: "_blank" | "_self" | "_parent" | "_top" | "framename",
|};

export default ({
  to,
  target = "_self",
}: Redirect): null => {
  window.open(to, target);
  return null;
};
