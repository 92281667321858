// @flow
import {
  takeEvery,
  select,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_API_IP_SAGA } from "../../helpers/constants/apiPage";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";
import { fetchIp } from "../../services/manageAffiliateApi";
import { setApiFields } from "../../actions/api";

export function* makeFetch(): Saga<void> {
  const affiliateId = yield select(getManageAffiliateId);
  try {
    const response = yield call(fetchIp, affiliateId);
    const { data } = response;
    if (data.status === 200) {
      const { apiKey } = data;
      const sanitizedIpList = data.ipWhiteList ? JSON.parse(data.ipWhiteList) : [];
      const ipWhiteList = sanitizedIpList.join("\n");
      yield put(setApiFields({
        ipWhiteList,
        apiKey,
      }));
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetApiIp(): Saga<void> {
  yield takeEvery(GET_API_IP_SAGA, (makeFetch: Function));
}
