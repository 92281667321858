// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import { initState as initErrorsState, reducer as errors } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import form, {
  initFormState,
  type State as FormState,
} from "@fas/ui-framework/lib/redux/reducers/form";
import mainSaga from "./saga";
import loading, {
  initState as initLoadingState,
  type State as LoadingState,
} from "../../reducers/loading";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import type { Actions as LoadingActions } from "../../actions/loading";
import type { SetDictionary as DictionaryActions } from "../../actions/dictionary";
import type { LoadingTypes } from "../../selectors/loading/types";
import { FORM_KEY_POTENTIAL_PARTNER } from "../../helpers/constants/potentialPartner";

export type State = $ReadOnly<{|
  form: FormState,
  loading: LoadingState<LoadingTypes>,
  dictionaries: DictionariesState,
  notifications: NotificationsState,
  errors: ErrorsState,
|}>

function mapSmuDepartmentsFormToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_POTENTIAL_PARTNER]: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        skype: "",
        companyName: "",
        country: "",
        accountType: "",
        vertical: "",
        status: "",
        isSubscribed: false,
        additionalData: "",
      },
    }),
    errors: initErrorsState(),
    loading: initLoadingState<LoadingTypes>({
    }),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
  };
}

export type Actions = LoadingActions<LoadingTypes>
  | DictionaryActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  form,
  errors,
  loading,
  dictionaries,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  // eslint-disable-next-line flowtype/require-variable-type
  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapSmuDepartmentsFormToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
