// @flow
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import {
  setPaymentInfoField,
  clearPaymentInfo,
  clearErrors,
  getAffiliateSaga,
  copyPaymnetDetailsByIdSaga,
  setPersonalInfoField as setPersonalInfoFieldAction,
} from "../../../../actions/manageAffiliate";
import { getLoading } from "../../../../selectors/loading";
import PaymentInfoMain from "./components/PaymentInfoMain";
import RegistrationDetails from "./components/RegistrationDetails";
import PaymentDetails from "./components/PaymentDetails";

type Props = {};

const mapStateToProps = (state) => ({
  manageAffiliate: state.manageAffiliate,
  loading: getLoading(state, "getPersonalInfo") || getLoading(state, "updatePaymentVerify"),
});

const mapDispatchToProps = (dispatch) => ({
  setField: (field, value) => dispatch(setPaymentInfoField({ field, value })),
  setPersonalInfoField: (field, value) => dispatch(setPersonalInfoFieldAction({ field, value })),
  clearFields: () => dispatch(clearPaymentInfo()),
  resetErrors: () => dispatch(clearErrors()),
  getPaymentInfo: (affId) => dispatch(getAffiliateSaga(affId)),
  copyPaymnetDetailsById: (affId) => dispatch(copyPaymnetDetailsByIdSaga(affId)),
});

const PaymentInfo = ({
  manageAffiliate,
  loading,
  setField,
  clearFields,
  resetErrors,
  getPaymentInfo,
  copyPaymnetDetailsById,
  setPersonalInfoField,
}: Props) => {
  const {
    paymentInfo,
    lists,
    errors,
    affiliateId,
    affType,
    personalInfo,
  } = manageAffiliate.toJS();
  const { paymentMethodId } = paymentInfo;
  const { parentAffIdPayment, childAffiliates } = personalInfo;

  const hasCopyId: boolean = !!parentAffIdPayment;

  useEffect(() => {
    resetErrors();
    clearFields();
    getPaymentInfo(affiliateId);
    return () => resetErrors();
  }, []);

  return (
    <Box
      display="flex"
      alignItems="stretch"
      flexDirection="column"
      m={2}
      mb={3}
    >
      <PaymentInfoMain
        loading={loading}
        title="Payment info"
        setField={setField}
        setPersonalInfoField={setPersonalInfoField}
        copyPaymnetDetailsById={copyPaymnetDetailsById}
        clearFields={clearFields}
        resetErrors={resetErrors}
        lists={lists}
        paymentInfo={paymentInfo}
        affType={affType}
        personalInfo={personalInfo}
        parentAffIdPayment={parentAffIdPayment}
        childAffiliates={childAffiliates}
        affiliateId={affiliateId}
        errors={errors}
        fields={[
          "paymentMethodId",
          "paymentComment",
          "paymentsType",
          "paymentMinAmount",
          "paymentAvailable",
          "currency",
        ]}
      />
      {paymentMethodId && paymentMethodId.id ? (
        <>
          <RegistrationDetails
            loading={loading}
            title="Registration details"
            setField={setField}
            lists={lists}
            paymentInfo={paymentInfo}
            hasCopyId={hasCopyId}
            affType={affType}
            errors={errors}
            fields={[
              "bankAccountName",
              "beAddress",
              "beCountry",
              "beName",
            ]}
          />
          <PaymentDetails
            loading={loading}
            title="Payment details"
            setField={setField}
            lists={lists}
            personalInfo={personalInfo}
            paymentInfo={paymentInfo}
            hasCopyId={hasCopyId}
            affType={affType}
            errors={errors}
            fields={[
              "bankName",
              "bankAddress",
              "payoutCountry",
              "swiftCode",
              "iban",
              "vatNumber",
              "accountNumber",
              "paymentNote",
            ]}
          />
        </>
      ) : null}
    </Box>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentInfo);
