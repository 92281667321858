// @flow
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import {
  AFFILIATE_SMART_OFFERS_TABLE,
  DELETE_AFFILIATE_SMART_OFFERS_SAGA,
} from "../../helpers/constants/affiliateSmartOffers";
import { deleteAffiliateSmartOffers } from "../../services/affiliateSmartOffersApi";
import type {
  AffiliateSmartOfferType,
} from "../../containers/AffiliateSmartOffersList/types/AffiliateSmartOffersList.types";
import { getAffiliateSmartOffersListSaga } from "../../actions/affiliateSmartOffers";

export function* makeFetch(): Saga<void> {
  const selected: AffiliateSmartOfferType[] = yield select(getTableSelections, AFFILIATE_SMART_OFFERS_TABLE);

  try {
    yield call(deleteAffiliateSmartOffers, selected.map(({ id }) => id));
    yield put(addNotification({ message: "Affiliate smart offers deleted", severity: "success" }));
    yield put(setTableSelection(AFFILIATE_SMART_OFFERS_TABLE, []));
    yield put(getAffiliateSmartOffersListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchDeleteAffiliateSmartOffers(): Saga<void> {
  yield takeEvery(DELETE_AFFILIATE_SMART_OFFERS_SAGA, (makeFetch: Function));
}
