// @flow
import {
  debounce,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { changeTableItemsTotalAmount, setTableData } from "@fas/ui-framework/lib/redux/actions/table";
import type { Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { CAMPAIGN_SETTINGS_TABLE, GET_CAMPAIGN_SETTINGS_OFFERS_SAGA } from "../../helpers/constants/campaignSettings";
import { getAffiliateId } from "../../selectors/manageAffiliate";
import { fetchCampaignSettingsOffers } from "../../services/campaignSettingsApi";

export function* getCampaignSettingsOffers(): Saga<void> {
  const affiliateId: string = yield select(getAffiliateId);
  const page: number = yield select(getTablePage, CAMPAIGN_SETTINGS_TABLE);
  const limit: number = yield select(getTablePageSize, CAMPAIGN_SETTINGS_TABLE);
  const sorting: Sorting = yield select(getTableSorting, CAMPAIGN_SETTINGS_TABLE);
  const {
    isPublic,
    isActive,
    platform,
    ...rest
  }: Object = yield select(getTableFilters, CAMPAIGN_SETTINGS_TABLE);

  const [current]: $Keys<Sorting>[] = Object.keys(sorting);
  const direction: $Values<Sorting> = current ? sorting[current] : "asc";

  yield put(setLoading(CAMPAIGN_SETTINGS_TABLE, true));

  try {
    const result = yield call(fetchCampaignSettingsOffers, {
      ...rest,
      affiliateId,
      isPublic: isPublic || null,
      isActive: isActive || null,
      platform: platform || null,
      // TODO: fix api to receive sorting as it comes from redux reducer
      currentSort: current || null,
      direction: direction || null,
      limit,
      page,
    });

    for (const row of result.payload) {
      // eslint-disable-next-line no-prototype-builtins
      if (!row.hasOwnProperty("isActive")) {
        row.isActive = row.isPublic;
      }
    }

    yield put(setTableData(CAMPAIGN_SETTINGS_TABLE, result.payload));
    yield put(changeTableItemsTotalAmount(CAMPAIGN_SETTINGS_TABLE, result.count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fecth offers", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CAMPAIGN_SETTINGS_TABLE, false));
  }
}

export function* watchGetCampaignSettingsOffers(): Saga<void> {
  yield debounce(1000, GET_CAMPAIGN_SETTINGS_OFFERS_SAGA, getCampaignSettingsOffers);
}
