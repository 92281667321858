// @flow
import {
  Record, List, fromJS,
} from "immutable";
import type { RecordFactory } from "immutable";
import {
  SET_TEMPLATE,
  SET_OPTIONS_OFFER_FOR_SUBRATE,
  SET_SUBRATES_OPTIONS_OFFER_COUNTRY_FOR_SUBRATE,
} from "../../helpers/constants/subrates";
import type {
  StateType,
  DefaultStateType,
  TemplateType,
  SubratesInfoType,
  SubratesIndexType,
  SubrateFormDataType,
  SubrateFormRecordType,
} from "./types";
import type { Actions as SubratesActionsType } from "../../actions/subrates";

export const makeHeadersState: RecordFactory<TemplateType> = Record({
  name: "",
  headersOrder: List([
    "affId",
    "name",
    "offerId",
    "offerName",
    "country",
    "platform",
    "defaultPayoutAmount",
    "defaultShaveAmount",
    "currencyId",
    "conversionType",
    "type",
  ]),
});

export const makeHeadersInfoState: RecordFactory<TemplateType> = Record({
  name: "",
  headersOrder: List([
    "affId",
    "utmContent",
    "s3",
    "payoutAmount",
    "payoutAmountFirst",
    "payoutAmountRepeats",
    "shaveAmount",
    "offerPayoutAmount",
    "offerPayoutAmountFirst",
    "offerPayoutAmountRepeats",
    "offerShaveAmount",
    "updatedAt",
  ]),
});

export const makeSubrateInfo: RecordFactory<SubratesInfoType> = Record({
  template: makeHeadersInfoState({}),
  offerCountryOptions: List([]),
  offerOptions: List([]),
});

export const makeSubrateIndex: RecordFactory<SubratesIndexType> = Record({
  template: makeHeadersState({}),
  group: List(["affId", "offerId", "country"]),
});

export const makeFormDataState: RecordFactory<SubrateFormDataType> = Record({
  id: "",
  affId: "",
  affName: "",
  cpaCampaignId: "",
  cpaCampaignName: "",
  country: "",
  utmContent: "",
  s3: "",
  conversionType: "",
  payoutAmount: null,
  payoutAmountFirst: null,
  payoutAmountRepeats: null,
  shaveAmount: null,
  increasedCommissionAmount: null,
  increasedCommissionThreshold: null,
  isActive: 1,
  yesterdayConversionCount: null,
});

export const makeSubrateForm: RecordFactory<SubrateFormRecordType> = Record({
  data: makeFormDataState({}),
  offerOptions: [],
  offerCountryOptions: [],
  affOptions: [],
});

const defaultState: DefaultStateType = {
  subratesIndex: makeSubrateIndex({}),
  subratesInfo: makeSubrateInfo({}),
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export const initialState: StateType = makeState({});

export function initState(): StateType {
  return initialState;
}

// eslint-disable-next-line complexity
export default (state: StateType = initialState, action: SubratesActionsType): StateType => {
  switch (action.type) {
    case SET_TEMPLATE: {
      return state.withMutations((newState) => {
        const makeRecord = Record({
          name: action.payload.name,
          headersOrder: fromJS(action.payload.headersOrder),
        });
        newState.setIn(["subratesIndex", "template"], makeRecord());
      });
    }
    case SET_OPTIONS_OFFER_FOR_SUBRATE: {
      // $FlowFixMe
      return state.setIn([action.key, "offerOptions"], action.offers);
    }
    case SET_SUBRATES_OPTIONS_OFFER_COUNTRY_FOR_SUBRATE: {
      // $FlowFixMe
      return state.setIn(["subratesInfo", "offerCountryOptions"], fromJS(action.offerCountries));
    }
    default:
      return state;
  }
};
