// @flow
import {
  GET_POTENTIAL_PARTNER_DROPDOWN_SAGA,
} from "../../helpers/constants/potentialPartner";

export type GetPotentialPartnerDropdownSagaType = {|
  type: typeof GET_POTENTIAL_PARTNER_DROPDOWN_SAGA,
|}

export type Actions = GetPotentialPartnerDropdownSagaType

export function getPotentialPartnerDropdownSaga(): GetPotentialPartnerDropdownSagaType {
  return {
    type: GET_POTENTIAL_PARTNER_DROPDOWN_SAGA,
  };
}
