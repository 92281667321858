// @flow
import environment from "environment";
import { serialize } from "object-to-formdata";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";
import type {
  TopOffersFormType,
  TopOffersType,
} from "../../containers/TopOffersList/types/TopOffersTable.types";
import type { DropDownObjType, DropDownObjItemType } from "../../reducers/dictionaries";
import { TOP_OFFERS_PROJECT_TYPES } from "../../containers/TopOffersForm/types/TopOffersForm.types";

export const fetchTopOffersList = (params: {
  page: number,
  limit: number,
  filters: Filters,
  sorting: Sorting,
}): PromiseResponse<mixed, { data: TopOffersType[], count: number }> => requestService
  .post(environment.endpoints.post.getTopOffersList, {
    ...params,
    filters: Object.keys(params.filters).reduce((acc, key) => { // todo remove cast in MTU-72544
      const numberKeys = ["id", "eCPM", "payout"];
      const val = numberKeys.includes(key) ? parseFloat(params.filters[key]) : params.filters[key];
      return { ...acc, [key]: val };
    }, {}),
    // todo need update sorting in table in ui core or add api support
    sorting: params.sorting[""] ? {} : params.sorting,
  });

export const fetchTopOffersDropdownLists: () => Promise<
  DropDownObjType
> = () => requestService.get(environment.endpoints.get.getTopOffersDropdownLists)
  .then(({ data: { lists } }) => lists);

export const fetchTopOffersById: (string) => PromiseResponse<
  mixed,
  { data: TopOffersFormType }
> = (id) => requestService.get(environment.endpoints.get.getTopOffersById.replace("{id}", id))
  .then(async ({ data: { data } }) => ({
    ...data,
    isActive: !!data.isActive,
    image: data.projectType === TOP_OFFERS_PROJECT_TYPES.adsempiredirect ? await fetchTopOffersLogo(id) : "",
  }));

export const fetchSearchTopOffersByName: (string) => Promise<
{...DropDownObjItemType }[]
  > = (offerName) => requestService.get(environment.endpoints.get.searchTopOffers, { params: { offerName } })
    .then(({ data: { data } }) => data.map((i) => ({
      ...i,
      label: i.offerName,
      value: i.id,
    })));

const santilizedByPojectType = {
  [TOP_OFFERS_PROJECT_TYPES.adsempiresmartlink]: ({
    id, projectType, isActive, geo,
    vertical, platform, eCPM,
  }) => ({
    id,
    projectType,
    vertical,
    geo,
    platform,
    eCPM,
    isActive: isActive ? 1 : 0,
  }),
  [TOP_OFFERS_PROJECT_TYPES.adsempiredirect]: ({
    id, projectType, isActive, geo,
    offerId, payout, currencyId, image,
  }) => ({
    id,
    projectType,
    geo,
    offerId,
    payout,
    currencyId,
    isActive: isActive ? 1 : 0,
    image: image instanceof File ? image : undefined,
  }),
};

export function fetchSaveTopOffers(data: TopOffersFormType): Promise<mixed> {
  const body = santilizedByPojectType[data.projectType] && santilizedByPojectType[data.projectType](data);
  return requestService
    .post(environment.endpoints.post.saveTopOffers, serialize(body), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
}

const fetchTopOffersLogo: (string) => Promise<File> = (id) => requestService
  .get(environment.endpoints.get.getTopOffersLogo.replace("{id}", id), {
    responseType: "blob", // Important
  }).then(({ data }) => data);
