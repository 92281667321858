// @flow
/* eslint-disable import/max-dependencies */
import { FORM_KEY_TOP_OFFERS } from "../../helpers/constants/topOffers";
import { fetchSaveTopOffers, fetchTopOffersById } from "../topOffersApi";
import { FORM_KEY_POTENTIAL_PARTNER } from "../../helpers/constants/potentialPartner";
import { fetchPotentialPartnerById, fetchSavePotentialPartner } from "../potentialPartnerApi";
import { FORM_KEY_ADVERTISER_INVOICES } from "../../helpers/constants/advertiserInvoices";
import { fetchAdvertiserInvoicesById, fetchSaveAdvertiserInvoices } from "../advertiserInvoicesApi";
import { FORM_KEY_PAYOUT_TERMS } from "../../helpers/constants/payoutTerms";
import { getPayoutTerms, savePayoutTerm } from "../payoutTermsApi";
import { FORM_KEY_SMU_BRAND, FORM_KEY_SMU_DEPARTMENT } from "../../helpers/constants/smu";
import { FORM_KEY_SMU_PRODUCT_COMPANY } from "../../helpers/constants/productCompanyForm";
import { FORM_KEY_CRM_MAILS, FORM_KEY_CRM_TEMPLATE } from "../../helpers/constants/crm";
import { saveCrmMail, fetchCrmMail } from "../crmMailsApi";
import {
  getSmuBrand, getSmuMarketingCompany, saveSmuBrand, saveSmuMarketingCompany,
} from "../smuApi";
import { fetchProductCompanyById, fetchSaveProductCompany } from "../productCompanyApi";
import { fetchCrmTemplate, saveCrmTemplate } from "../crmTemplatesApi";
import { FORM_KEY_SUBRATE } from "../../helpers/constants/subrates";
import { fetchSaveSubrate, fetchSubrateById } from "../subratesApi";

export const apiMapSaveFormData = {
  [FORM_KEY_TOP_OFFERS]: fetchSaveTopOffers,
  [FORM_KEY_POTENTIAL_PARTNER]: fetchSavePotentialPartner,
  [FORM_KEY_ADVERTISER_INVOICES]: fetchSaveAdvertiserInvoices,
  [FORM_KEY_PAYOUT_TERMS]: savePayoutTerm,
  [FORM_KEY_SMU_BRAND]: saveSmuBrand,
  [FORM_KEY_SMU_PRODUCT_COMPANY]: fetchSaveProductCompany,
  [FORM_KEY_SMU_DEPARTMENT]: saveSmuMarketingCompany,
  [FORM_KEY_CRM_MAILS]: saveCrmMail,
  [FORM_KEY_CRM_TEMPLATE]: saveCrmTemplate,
  [FORM_KEY_SUBRATE]: fetchSaveSubrate,
};

export const apiMapGetFormData = {
  [FORM_KEY_TOP_OFFERS]: fetchTopOffersById,
  [FORM_KEY_POTENTIAL_PARTNER]: fetchPotentialPartnerById,
  [FORM_KEY_ADVERTISER_INVOICES]: fetchAdvertiserInvoicesById,
  [FORM_KEY_PAYOUT_TERMS]: getPayoutTerms,
  [FORM_KEY_SMU_BRAND]: getSmuBrand,
  [FORM_KEY_SMU_PRODUCT_COMPANY]: fetchProductCompanyById,
  [FORM_KEY_SMU_DEPARTMENT]: getSmuMarketingCompany,
  [FORM_KEY_SUBRATE]: fetchSubrateById,
  [FORM_KEY_CRM_MAILS]: fetchCrmMail,
  [FORM_KEY_CRM_TEMPLATE]: fetchCrmTemplate,
};
