// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import Immutable from "immutable";
import thunkMiddleware from "redux-thunk";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import cpaOffer, { initState } from "../../reducers/cpaoffer";
import mainSaga from "./saga";
import type {SagaMiddleware} from "redux-saga";
import createSagaMiddleware from "redux-saga";
import loading, { initLoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import type {State as LoadingState} from "@fas/ui-framework/lib/redux/reducers/loading/reducer";

export type State = $ReadOnly<{|
  cpaOffer: any,
  notifications: NotificationsState,
  loading: LoadingState<string>,
|}>

export function mapPaymentInfoToState(): State {
  return {
    cpaOffer: initState(),
    notifications: initNotificationsState(),
    loading: initLoadingState(),
  };
}

type Actions = any;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  cpaOffer,
  notifications,
  loading,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;
  const middlewares = [thunkMiddleware, sagaMiddleware];

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPaymentInfoToState(),
    composeEnhancers(applyMiddleware(
      ...middlewares
    ))
  );
  sagaMiddleware.run(mainSaga);

  return store;
};
