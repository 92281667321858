// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { getErrors, sanitizeData } from "../../actions/manageAffiliate/utils";
import { setErrors } from "../../actions/manageAffiliate";
import { saveAffiliate } from "../../services/manageAffiliateApi";
import { setTabState } from "../../services/middlewares/tabSwitchConfirm";
import { SAVE_AFFILIATE_SAGA } from "../../helpers/constants/manageAffiliate";
import type { SaveAffiliateSaga } from "../../actions/manageAffiliate";

export function* makeFetch(action: SaveAffiliateSaga): Saga<void> {
  const { payload } = action;

  try {
    const sanitized = sanitizeData(
      payload,
      ["country", "manager", "declineReasonId", "accountId"],
      "add"
    );
    const response = yield call(saveAffiliate, sanitized);
    setTabState(false);
    window.location = `/manageAffiliate/edit/${response.data.id}/personalInfo`;
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // need remove this after refactor
    if (error.response.data.errors) {
      const errors = getErrors(error.response.data.errors);
      yield put(setErrors(errors));
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchSaveAffiliateSaga(): Saga<void> {
  // todo add event type here
  yield takeEvery(SAVE_AFFILIATE_SAGA, (makeFetch: Function));
}
