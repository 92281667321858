/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { BULK_CHANGE_AFFILIATE_STATUS, CPA_AFFILIATES_LIST_TABLE } from "../../helpers/constants/manageAffiliate";
import { bulkChangeAffiliateStatus } from "../../services/manageAffiliateApi";
import type { BulkChangeAffiliateStatusSaga } from "../../actions/manageAffiliate";
import { clearIsActivesAffiliate, getManageAffiliatesDataSaga } from "../../actions/manageAffiliate";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";

export function* makeFetch(action: BulkChangeAffiliateStatusSaga): Saga<void> {
  const { isActive }: BulkChangeAffiliateStatusSaga = action;
  const rows: CpaAffiliatesListItem[] = yield select(getTableSelections, CPA_AFFILIATES_LIST_TABLE);
  const offers = rows.map(({ id }: CpaAffiliatesListItem): Object => ({ id, isActive }));

  try {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, true));

    yield call(bulkChangeAffiliateStatus, {
      activate: offers,
    });
    yield put(addNotification({ message: "Status changed", severity: "success" }));
    yield put(setTableSelection(CPA_AFFILIATES_LIST_TABLE, []));
    yield put(clearIsActivesAffiliate());
    yield put(getManageAffiliatesDataSaga());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_AFFILIATES_LIST_TABLE, false));
  }
}

export default function* watchBulkChangeAffiliateStatus(): Saga<void> {
  yield takeEvery(BULK_CHANGE_AFFILIATE_STATUS, (makeFetch: Function));
}
