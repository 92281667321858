// @flow
import { List } from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State, Filter } from "../../reducers/filters";

export type StoreWithFilters = $ReadOnly<{
  filters: State,
  ...
}>;
// eslint-disable-next-line import/prefer-default-export
export const getFiltersList: OutputSelector<*, *, Array<Filter>> = createSelector(
  (state: StoreWithFilters): List<Filter> => state.filters,
  (filters: List<Filter>): Array<Filter> => filters.toArray()
);
