// @flow
import {
  SET_PERSONAL_INFO_FIELD,
  SET_PAYMENT_INFO_FIELD,
} from "../../helpers/constants/manageAffiliate";
import {
  SET_FIELD_POSTBACKS,
} from "../../helpers/constants/postbacks";
import {
  SET_API_FIELD,
} from "../../helpers/constants/apiPage";

// eslint-disable-next-line import/no-mutable-exports
export let isTabStateChanged: boolean = false;

export const setTabState = (value: boolean) => {
  isTabStateChanged = value;
};

const actions = [
  SET_PERSONAL_INFO_FIELD,
  SET_PAYMENT_INFO_FIELD,
  SET_FIELD_POSTBACKS,
  SET_API_FIELD,
];

// eslint-disable-next-line no-unused-vars
export const tabSwitchConfirm = (store: *) => (next: *) => async (action: *) => {
  if (actions.includes(action.type)) {
    isTabStateChanged = true;
  }

  return next(action);
};
