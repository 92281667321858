// @flow
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Tooltip,
  Button,
  alpha,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getErrors } from "@fas/ui-framework/lib/redux/selectors/errors";
import { removeError } from "@fas/ui-framework/lib/redux/actions/errors";
import { userService } from "../../../../../../services/user";
import {
  generateApiKeySaga,
  saveApiKeySaga,
  setApiFields,
  setApiField,
  getApiIpSaga,
} from "../../../../../../actions/api";

type Fields = {
  ipWhiteList: string,
  apiKey: string,
};

type Props = {
  fields: Fields,
  getApiIpList: () => void,
  getApiKey: () => void,
  saveKey: (
    errors: FieldsType,
    setErrors: () => void,
  ) => void,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  title: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  multilineInput: {
    background: theme.palette.background.mainBg,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  helperIcon: {
    marginLeft: theme.spacing(1),
  },
  revokeButton: {
    marginLeft: theme.spacing(1),
    border: `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
    "&$outlined:hover": {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: "rgba(350, 79, 60, 0.04)",
    },
    color: theme.palette.error.main,
  },
  applyButton: {
    marginLeft: "auto",
  },
  outlined: {},
}));

const mapDispatchToProps = (dispatch) => ({
  getApiIpList: () => dispatch(getApiIpSaga()),
  getApiKey: () => dispatch(generateApiKeySaga()),
  saveKey: () => dispatch(saveApiKeySaga()),
  setFields: (value) => dispatch(setApiFields(value)),
  setField: (field, value) => dispatch(setApiField(field, value)),
  handleRemoveError: (val) => dispatch(removeError(val)),
});

const mapStateToProps = (state) => ({
  fields: state.manageAffiliate.getIn(["apiPage", "fields"]).toJS(),
  errorsList: getErrors(state),
});

const ApiContent = ({
  getApiIpList,
  getApiKey,
  saveKey,
  fields,
  setField,
  errorsList,
  handleRemoveError,
}: Props) => {
  const classes = useStyles();
  const errors = errorsList.toJS();

  const ipWhiteListRef = useRef(null);

  const [userHasApiGroup, setUserHasApiGroup] = useState(false);

  const handleRevokeKey = () => setField("apiKey", "");
  const handleGetApiKey = () => getApiKey();
  const handleChangeField = (e) => {
    handleRemoveError(["ipWhiteList"]);
    setField(e.target.name, e.target.value);
  };

  useEffect(() => {
    getApiIpList();
    setUserHasApiGroup(userService.can(["/user/apiKey/generateApiKey64"]));
  }, []);

  useEffect(() => {
    if (ipWhiteListRef.current) {
      ipWhiteListRef.current.value = fields.ipWhiteList;
    }
  });

  const zeroSpacing = { marginLeft: 0, marginRight: 0 };

  return (
    <Paper
      className={classes.root}
      elevation={3}
    >
      <Typography
        className={classes.title}
        variant="h6"
      >
        API
      </Typography>
      <TextField
        className={classes.multilineInput}
        disabled={!userHasApiGroup}
        fullWidth
        multiline
        variant="outlined"
        label="IP whitelist"
        placeholder="Enter ip addresses, one per line"
        name="ipWhiteList"
        data-testid="ipWhiteList"
        error={!!errors.ipWhiteList}
        helperText={errors.ipWhiteList && errors.ipWhiteList.message}
        onBlur={handleChangeField}
        inputRef={ipWhiteListRef}
        InputLabelProps={{
          shrink: true,
        }}
        rows={6}
      />
      <TextField
        disabled
        fullWidth
        className={classes.inputField}
        name="apiKey"
        data-testid="apiKey"
        value={fields.apiKey || ""}
        variant="outlined"
        label="API key"
        placeholder="API key"
        inputProps={{
          style: { background: classes.input },
        }}
        FormHelperTextProps={{
          style: zeroSpacing,
        }}
        InputProps={{
          classes: { adornedStart: classes.input },
          startAdornment: (
            <>
              <InputAdornment
                position="start"
              >
                <Tooltip title="Some text">
                  <HelpOutlineIcon />
                </Tooltip>
              </InputAdornment>
            </>
          ),
        }}
      />
      {userHasApiGroup && (
        <Box
          display="flex"
          flexDirection="row"
          mt={3}
          mb={1}
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            data-testid="generateApiKeyButton"
            onClick={handleGetApiKey}
          >
            Generate new api key
          </Button>
          <Button
            classes={{
              disabled: classes.disabled,
              outlined: classes.outlined,
            }}
            className={classes.revokeButton}
            size="large"
            data-testid="revokeApiKeyButton"
            variant="outlined"
            onClick={handleRevokeKey}
          >
            Revoke api key
          </Button>
          <Button
            className={classes.applyButton}
            size="large"
            variant="outlined"
            color="primary"
            data-testid="applyButton"
            onClick={saveKey}
          >
            Apply
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiContent);
