// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import { makeStyles } from "@mui/styles";
import { GridUc } from "@fas/ui-core";
import {
  Grid,
  Typography,
} from "@mui/material";
import type {
  NamedItemProps as Props,
  NamedItemClasses as Classes,
} from "./types/CardGridContent.types";

const useStyles: (props: {[key: string]: string}) => Classes = makeStyles((): Classes => ({
  root: {
    backgroundColor: (props: {[key: string]: string}): string => (props.backgroundColor || "inherit"),
    height: "100%",
  },
  label: {},
  value: {
    color: (props: {[key: string]: string}): string => (props.color || "#000000DA"),
  },
}));

const NamedItem: StatelessFunctionalComponent<Props> = ({
  label,
  value,
  styles = {},
}: Props) => {
  const classes: Classes = useStyles(styles);

  return (
    <GridUc
      p={2}
      container
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={6}>
        <Typography variant="body2" color="textSecondary" align="left" className={classes.label}>{label}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="left" className={classes.value}>{value || "\u2E3A"}</Typography>
      </Grid>
    </GridUc>
  );
};

export default NamedItem;
