// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import Immutable from "immutable";
import thunkMiddleware from "redux-thunk";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import mainSaga from "./saga";
import manageAffiliate, { initState } from "../../reducers/manageAffiliate";
import { tabSwitchConfirm } from "../../services/middlewares/tabSwitchConfirm";
import {
  initState as initErrorsState,
  reducer as errors,
} from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import loading, {
  type State as LoadingState,
  initLoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import type { State as ErrorsState } from "@fas/ui-framework/lib/redux/reducers/errors/reducer";
import { initTableState, tableReducer as tables } from "@fas/ui-framework/lib/redux/reducers/table";
import { AFFILIATE_SMART_OFFERS_TABLE } from "../../helpers/constants/affiliateSmartOffers";
import type { TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import { CAMPAIGN_SETTINGS_TABLE } from "../../helpers/constants/campaignSettings";
import { POSTBACK_EVENTS_TABLE } from "../../helpers/constants/EventsPostback";
import { ADDITIONAL_POSTBACKS_TABLE } from "../../helpers/constants/postbacks";
import { AFFILIATE_LINKS_TABLE } from "../../helpers/constants/affiliateLinks";

export type State = $ReadOnly<{|
  tables: TableState,
  manageAffiliate: any,
  notifications: NotificationsState,
  errors: ErrorsState,
  loading: LoadingState<string>,
|}>

function mapPaymentInfoToState(): State {
  return {
    tables: initTableState({
      [CAMPAIGN_SETTINGS_TABLE]: {
        pageSize: 20,
        rowsPerPage: [5, 15, 20, 50],
        filters: {
          isActive: '',
          isPublic: '',
          platform: '',
        }
      },
      [POSTBACK_EVENTS_TABLE]: {
        pageSize: 20,
        rowsPerPage: [20, 50, 100]
      },
      [ADDITIONAL_POSTBACKS_TABLE]: {
        pageSize: 20,
        rowsPerPage: [20, 50, 100]
      },
      [AFFILIATE_LINKS_TABLE]: {
        pageSize: 20,
        rowsPerPage: [20, 50, 100]
      },
      [AFFILIATE_SMART_OFFERS_TABLE]: {
        pageSize: 20,
      },
    }),
    manageAffiliate: initState(),
    notifications: initNotificationsState(),
    errors: initErrorsState(),
    loading: initLoadingState({
      [CAMPAIGN_SETTINGS_TABLE]: false,
      [POSTBACK_EVENTS_TABLE]: false,
      [ADDITIONAL_POSTBACKS_TABLE]: false,
      [AFFILIATE_LINKS_TABLE]: false,
    }),
  };
}

type Actions = any;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  tables,
  // $FlowFixMe need update type in reducer
  manageAffiliate,
  notifications,
  errors,
  loading,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;
  const middlewares = [sagaMiddleware, thunkMiddleware, tabSwitchConfirm];

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPaymentInfoToState(),
    composeEnhancers(applyMiddleware(
      ...middlewares,
    ))
  );
  sagaMiddleware.run(mainSaga);

  return store;
};
