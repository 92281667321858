/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import {
  MenuItem,
} from "@mui/material";
import PersonalInfo from "./components/PersonalInfo";
import PaymentInfo from "./components/PaymentInfo";
import CampaignSettings from "./components/CampaignSettings";
import API from "./components/ApiPage";
import AffiliateLinks from "./components/AffiliateLinks";
import PostbackInfo from "./components/PostbackInfo";
import AffiliateSmartOffersList from "../AffiliateSmartOffersList";

export const tabs = [
  {
    name: "personalInfo",
    label: "PERSONAL INFO",
    component: (<PersonalInfo />),
    permission: ["accessManageAffiliate"],
    isDisabled: (mode: string) => false,
  },
  {
    name: "paymentInfo",
    label: "PAYMENT INFO",
    component: (<PaymentInfo />),
    permission: ["accessManageAffiliate"],
    isDisabled: (mode: string) => mode !== "edit",
  },
  {
    name: "campaignSettings",
    label: "CAMPAIGN SETTINGS",
    component: (<CampaignSettings />),
    permission: ["accessManageAffiliate"],
    isDisabled: (mode: string) => mode !== "edit",
  },
  {
    name: "postbackInfo",
    label: "POSTBACK INFO",
    component: (<PostbackInfo />),
    permission: ["accessManageAffiliate"],
    isDisabled: (mode: string) => mode !== "edit",
  },
  {
    name: "affiliateLinks",
    label: "AFFILIATE LINKS",
    component: (<AffiliateLinks />),
    permission: [],
    isDisabled: (mode: string) => mode !== "edit",
  },
  {
    name: "smartOffers",
    label: "SMART OFFERS",
    component: (<AffiliateSmartOffersList />),
    permission: [],
    isDisabled: (mode: string) => mode !== "edit",
  },
  {
    name: "api",
    label: "API",
    component: (<API />),
    permission: ["accessManageAffiliate"],
    isDisabled: (mode: string) => mode !== "edit",
  },
];

export const datepickerValues = [
  <MenuItem key={0} disabled value="custom">Custom</MenuItem>,
  <MenuItem key={1} value="allTime">All time</MenuItem>,
  <MenuItem key={2} value="today">Today</MenuItem>,
  <MenuItem key={3} value="yesterday">Yesterday</MenuItem>,
  <MenuItem key={4} value="thisWeek">This week</MenuItem>,
  <MenuItem key={5} value="pastWeek">Past week</MenuItem>,
  <MenuItem key={6} value="lastSevenDays">Last 7 days</MenuItem>,
  <MenuItem key={7} value="thisMonth">This month</MenuItem>,
  <MenuItem key={8} value="lastThirtyDays">Last 30 days</MenuItem>,
  <MenuItem key={9} value="lastYear">Last 365 days</MenuItem>,
];

export const findAffiliateValues = [
  <MenuItem key={0} value="beName">Beneficiary name</MenuItem>,
  <MenuItem key={1} value="bankAccountName">Bank account name</MenuItem>,
  <MenuItem key={2} value="payoutCountryId">Payout country</MenuItem>,
  <MenuItem key={4} value="paymentMethodId">Payment methods</MenuItem>,
  <MenuItem key={5} value="city">City/Town</MenuItem>,
  <MenuItem key={6} value="imName">Im name</MenuItem>,
  <MenuItem key={7} value="address">Address</MenuItem>,
];
