// @flow
/* eslint-disable import/max-dependencies */
import React, { useEffect } from "react";
import type { StatelessFunctionalComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { Error, Loader, SetupCard } from "@fas/ui-core";
import { useParams, type Match } from "react-router-dom";
import {
  InlineMultiSelectForm, SelectForm, SwitchFieldForm, TextFieldForm,
} from "@fas/ui-core/lib/Form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { FORM_KEY_POTENTIAL_PARTNER } from "../../helpers/constants/potentialPartner";
import { getPotentialPartnerDropdownSaga } from "../../actions/potentialPartnerForm";
import FormHeader from "../../components/FormHeader";
import Form from "../Form";
import validate from "./validate";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { useList } from "../../hoocks/useDictionary";
import type { State } from "../../pages/PotentialPartnerForm/store";

export type Props = $ReadOnly<{||}>;

const PotentialPartnerForm: StatelessFunctionalComponent<Props> = () => {
  const dispatch: <A>(A) => A = useDispatch();

  const { id: objectId }: $PropertyType<Match, "params"> = useParams();
  useEffect(() => {
    dispatch(getPotentialPartnerDropdownSaga());
  }, []);
  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_POTENTIAL_PARTNER, objectId));
    }
  }, [objectId]);

  const id: string = useSelector((state: State) => getFormData(state, FORM_KEY_POTENTIAL_PARTNER).id);
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  const statusList: DropDownObjItemType[] = useList("status");
  const countryList: DropDownObjItemType[] = useList("country");
  const accountTypeList: DropDownObjItemType[] = useList("accountType");
  const verticalList: DropDownObjItemType[] = useList("vertical");

  const isError = useSelector((state) => getFormField(state, FORM_KEY_POTENTIAL_PARTNER, "isError"));
  if (isError) {
    return <Error />;
  }
  return (
    <Form id={FORM_KEY_POTENTIAL_PARTNER} validate={validate} redirectOnSave="/potentialPartner">
      <FormHeader
        title={id ? `Edit potential partner: ${id}` : "Create potential partner"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="firstName"
                label="First name"
              />
              <TextFieldForm
                name="lastName"
                label="Last name"
              />
              <TextFieldForm
                name="email"
                label="Email"
              />
              <TextFieldForm
                name="skype"
                label="Skype"
              />
              <TextFieldForm
                name="companyName"
                label="Company name"
              />
              <SelectForm
                name="country"
                label="Country"
                options={countryList}
              />
              <SelectForm
                name="accountType"
                label="Account type"
                options={accountTypeList}
              />
              <InlineMultiSelectForm
                name="vertical"
                label="Verticals"
                options={verticalList}
              />
              <SelectForm
                name="status"
                label="Status"
                options={statusList}
              />
              <SwitchFieldForm
                name="isSubscribed"
                label="Want to receive emails about last news and updates"
              />
              { id && (
                <TextFieldForm
                  name="additionalData"
                  label="Additional data"
                  minRows={15}
                  maxRows={15}
                  disabled
                  multiline
                />
              )}
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default PotentialPartnerForm;
