// @flow
import {
  takeEvery,
  put,
  call,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { GET_CPA_OFFER_SAGA } from "../../helpers/constants/cpaoffer";
import {
  getOfferForm,
  offerIsFetching,
  setOfferFound,
} from "../../actions/cpaoffer";
import { getCpaOfferForm } from "../../services/cpaOffersApi";
import type { GetCpaOfferSaga } from "../../actions/cpaoffer";
import { getCpaOfferIsCloneStatus } from "../../selectors/cpaOfferForm";

export function* makeFetch(action: GetCpaOfferSaga): Saga<void> {
  const { id }: GetCpaOfferSaga = action;
  yield put(offerIsFetching(true));
  const isClone: boolean = yield select(getCpaOfferIsCloneStatus);
  try {
    const { data } = yield call(getCpaOfferForm, id);
    const newForm = {
      ...data.offer,
      nextPartnerCommission: isClone ? null : data.offer.nextPartnerCommission,
      partnerCommission: isClone ? null : data.offer.partnerCommission,
      isActive: isClone ? 1 : data.offer.isActive,
      forOfferwall: isClone ? 0 : data.offer.forOfferwall,
    };
    yield put(getOfferForm(newForm, data.lists));
    yield put(setOfferFound(true));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(offerIsFetching(false));
  }
}

export default function* watchGetCpaOffer(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_CPA_OFFER_SAGA, (makeFetch: Function));
}
