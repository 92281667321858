// @flow
import {
  SET_SORT_MANAGE_AFFILIATE,
  SET_FILTER_MANAGE_AFFILIATE,
  DELETE_FILTER_MANAGE_AFFILIATE,
  SET_SELECTED_ROWS_MANAGE_AFFILIATE,
  CLEANUP_TABLE_MANAGE_AFFILIATE,
  GET_MANAGE_AFFILIATE_DATA,
  SET_FILTERS_MANAGE_AFFILIATES,
  SET_PERSONAL_INFO_FIELD,
  CLEAR_PERSONAL_INFO,
  GET_PERSONAL_INFO_LISTS,
  SET_ERRORS_PERSONAL_INFO,
  CLEAR_ERRORS_PERSONAL_INFO,
  SET_MANAGE_AFFILIATE_ID,
  SET_MANAGE_AFFILIATE_CURRENT_TABLET,
  SET_PERSONAL_INFO,
  ADD_IS_ACTIVE_MANAGE_AFFILIATE,
  REMOVE_IS_ACTIVE_MANAGE_AFFILIATE,
  CLEAR_IS_ACTIVES_MANAGE_AFFILIATE,
  SET_LOADER_MANAGE_AFFILIATE,
  SET_PAYMENT_INFO_FIELD,
  CLEAR_PAYMENT_INFO,
  SET_ERRORS_PAYMENT_INFO,
  CLEAR_ERRORS_PAYMENT_INFO,
  SET_PAYMENT_INFO,
  IS_PERSONAL_INFO_FETCHED,
  SET_TEMPLATE_HEADERS,
  SET_TEMPLATES_LIST,
  SET_MESSAGES,
  SET_FETCHED_MANAGERS,
  SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
  SWITCH_CHANGE_STATUS_MODAL_OPENED,
  CHANGE_IS_MANAGER_LIST_LOADED,
  SET_MANAGERS_LIST,
  SET_AFF_TYPE,
  GET_MANAGE_AFFILIATES_DATA_SAGA,
  GET_PERSONAL_INFO_LISTS_SAGA,
  SAVE_AFFILIATE_SAGA,
  GET_AFFILIATE_SAGA,
  UPDATE_AFFILIATE_SAGA,
  BULK_CHANGE_AFFILIATE_STATUS,
  UPDATE_PAYMENT_INFO_SAGA,
  CHANGE_AFFILIATE_STATUS_SAGA,
  CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA,
  LOGIN_AS_AFFILIATE_SAGA,
  GENERATE_AUTO_LOGIN_LINK_SAGA,
  GET_TEMPLATES_LIST_SAGA,
  GET_AUTHOR_LIST_SAGA,
  BULK_CHANGE_AUTHOR_SAGA,
  GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA,
  COPY_PAYMENT_DETAILS_BY_ID_SAGA,
  GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA,
  SET_DICTIONARY_LIST,
  INCREMENT_SUBMIT_COUNT,
} from "../../helpers/constants/manageAffiliate";
import type {
  SortType,
  FilterType,
  GetManageAffiliates,
  FiltersType,
  AffiliateFieldType,
  PersonalInfoListsType,
  AffiliateType,
  IsActiveType,
  SetLoader,
  PaymentInfoType,
  TemplateList,
} from "../../reducers/manageAffiliate";

import type {
  Manager
} from "./types";
import { SAVE_TEMPLATE_SAGA } from "../../helpers/constants/subrates";
import type { TemplateType } from "../../components/TemplateModal";
import type { CpaAffiliatesListItem } from "../../containers/ManageAffiliate/types/ManageAffiliate.types";
import {SET_DROPDOWNS} from "@fas/ui-framework/lib/redux/constants";

// Types
export type SetSortAction = {
  type: typeof SET_SORT_MANAGE_AFFILIATE,
  value: SortType,
};
export type SetAffTypeAction = {
  type: typeof SET_AFF_TYPE,
  value: string,
};
export type SetFilterAction = {
  type: typeof SET_FILTER_MANAGE_AFFILIATE,
  value: FilterType,
};
export type DeleteFilterAction = {
  type: typeof DELETE_FILTER_MANAGE_AFFILIATE,
  field: string,
};
export type SetSelectedRowsAction = {
  type: typeof SET_SELECTED_ROWS_MANAGE_AFFILIATE,
  value: Array<string | number>,
};
export type CleanupTableAction = {
  type: typeof CLEANUP_TABLE_MANAGE_AFFILIATE,
};
export type GetAffiliatesAction = {
  type: typeof GET_MANAGE_AFFILIATE_DATA,
  value: GetManageAffiliates
};
export type SetFiltersAction = {
  type: typeof SET_FILTERS_MANAGE_AFFILIATES,
  value: FiltersType
};
export type AffiliateFieldAction = {
  type: typeof SET_PERSONAL_INFO_FIELD,
  value: AffiliateFieldType,
}
export type ClearAffiliateInfoAction = {
  type: typeof CLEAR_PERSONAL_INFO,
};
export type PersonalInfoListsAction = {
  type: typeof GET_PERSONAL_INFO_LISTS,
  value: PersonalInfoListsType,
};
export type PersonalInfoErrorsAction = {
  type: typeof SET_ERRORS_PERSONAL_INFO,
  value: Object,
};
export type ClearPersonalInfoErrorsAction = {
  type: typeof CLEAR_ERRORS_PERSONAL_INFO,
}
export type SetManageAffiliateIdAction = {
  type: typeof SET_MANAGE_AFFILIATE_ID,
};
export type SetAffiliateCurrentTabletAction = {
  type: typeof SET_MANAGE_AFFILIATE_CURRENT_TABLET,
};
export type SetPersonalInfoAction = {
  type: typeof SET_PERSONAL_INFO,
  value: AffiliateType,
};
export type AddIsActiveManageAffiliateAction = {
  type: typeof ADD_IS_ACTIVE_MANAGE_AFFILIATE,
};
export type RemoveIsActiveManageAffiliateAction = {
  type: typeof REMOVE_IS_ACTIVE_MANAGE_AFFILIATE,
};
export type ClearIsActivesManageAffiliateAction = {
  type: typeof CLEAR_IS_ACTIVES_MANAGE_AFFILIATE,
};
export type SetLoaderAction = {
  type: typeof SET_LOADER_MANAGE_AFFILIATE,
  value: SetLoader,
};
export type SetPaymentInfoFieldAction = {
  type: typeof SET_PAYMENT_INFO_FIELD,
  value: Object,
}
export type ClearPaymentInfoAction = {
  type: typeof CLEAR_PAYMENT_INFO,
};
export type SetPaymentInfoErrorsAction = {
  type: typeof SET_ERRORS_PAYMENT_INFO,
  value: Object,
};
export type ClearPaymentInfoErrorsAction = {
  type: typeof CLEAR_ERRORS_PAYMENT_INFO,
};
export type SetPaymentInfoAction = {
  type: typeof SET_PAYMENT_INFO,
  value: PaymentInfoType,
};
export type IsPersonalInfoFetchedAction = {
  type: typeof IS_PERSONAL_INFO_FETCHED,
};
export type SetTemplateHeaders = {
  type: SET_TEMPLATE_HEADERS,
  value: TemplateType<$Keys<CpaAffiliatesListItem>>,
};

export type SetFetchedManagersList = {|
  type: typeof SET_FETCHED_MANAGERS,
  managersList: Manager[],
|};

export type SwitchChangeAuthorModalOpened = {|
  type: typeof SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
|};

export type SwitchChangeStatusModalOpened = {|
  type: typeof SWITCH_CHANGE_STATUS_MODAL_OPENED,
|};

export type ChangeIsManagersListLoaded = {|
  type: typeof CHANGE_IS_MANAGER_LIST_LOADED,
|};

export type GetManageAffiliatesDataSaga = {
  type: typeof GET_MANAGE_AFFILIATES_DATA_SAGA,
}

export type CopyPaymnetDetailsByIdSaga = {
  type: typeof COPY_PAYMENT_DETAILS_BY_ID_SAGA,
  payload: string,
}

export type IncrementSubmitCount = {
  type: typeof INCREMENT_SUBMIT_COUNT,
}

// Actions
export const setSort = (
  value: SortType
): SetSortAction => ({
  type: SET_SORT_MANAGE_AFFILIATE,
  value,
});
export const SetAffType = (
  value: string
): SetAffTypeAction => ({
  type: SET_AFF_TYPE,
  value,
});
export const setFilter = (
  value: FilterType
): SetFilterAction => ({
  type: SET_FILTER_MANAGE_AFFILIATE,
  value,
});
export const deleteFilter = (
  field: string
): DeleteFilterAction => ({
  type: DELETE_FILTER_MANAGE_AFFILIATE,
  field,
});
export const setSelectedRows = (
  value: Array<string | number>
): SetSelectedRowsAction => ({
  type: SET_SELECTED_ROWS_MANAGE_AFFILIATE,
  value,
});
export const cleanupTable = (): CleanupTableAction => ({
  type: CLEANUP_TABLE_MANAGE_AFFILIATE,
});
export const getAffiliates = (
  value: GetManageAffiliates
): GetAffiliatesAction => ({
  type: GET_MANAGE_AFFILIATE_DATA,
  value,
});
export const setFilters = (
  value: FiltersType
): SetFiltersAction => ({
  type: SET_FILTERS_MANAGE_AFFILIATES,
  value,
});
export const setPersonalInfoField = (
  value: AffiliateFieldType
): AffiliateFieldAction => ({
  type: SET_PERSONAL_INFO_FIELD,
  value,
});
export const clearPersonalInfo = (): ClearAffiliateInfoAction => ({
  type: CLEAR_PERSONAL_INFO,
});
export const setLists = (
  value: PersonalInfoListsType
): PersonalInfoListsAction => ({
  type: GET_PERSONAL_INFO_LISTS,
  value,
});
export const setErrors = (
  value: Object
): PersonalInfoErrorsAction => ({
  type: SET_ERRORS_PERSONAL_INFO,
  value,
});
export const clearErrors = (): ClearPersonalInfoErrorsAction => ({
  type: CLEAR_ERRORS_PERSONAL_INFO,
});
export const setAffiliateId = (
  value: string
): SetManageAffiliateIdAction => ({
  type: SET_MANAGE_AFFILIATE_ID,
  value,
});
export const setAffiliateCurrentTablet = (
  value: string
): SetAffiliateCurrentTabletAction => ({
  type: SET_MANAGE_AFFILIATE_CURRENT_TABLET,
  value,
});
export const setPersonalInfo = (
  value: AffiliateType
): SetPersonalInfoAction => ({
  type: SET_PERSONAL_INFO,
  value,
});
export const addIsActiveAffiliate = (
  value: IsActiveType
): AddIsActiveManageAffiliateAction => ({
  type: ADD_IS_ACTIVE_MANAGE_AFFILIATE,
  value,
});

export const clearIsActivesAffiliate = (): ClearIsActivesManageAffiliateAction => ({
  type: CLEAR_IS_ACTIVES_MANAGE_AFFILIATE,
});
export const setLoader = (
  value: SET_LOADER_MANAGE_AFFILIATE
): SetLoaderAction => ({
  type: SET_LOADER_MANAGE_AFFILIATE,
  value,
});
export const setPaymentInfoField = (
  value: AffiliateFieldType
): SetPaymentInfoFieldAction => ({
  type: SET_PAYMENT_INFO_FIELD,
  value,
});
export const clearPaymentInfo = (): ClearPaymentInfoAction => ({
  type: CLEAR_PAYMENT_INFO,
});
const setPaymentInfoErrors = (
  value: Object
): SetPaymentInfoErrorsAction => ({
  type: SET_ERRORS_PAYMENT_INFO,
  value,
});
export const clearPaymentInfoErrors = (): ClearPaymentInfoErrorsAction => ({
  type: CLEAR_ERRORS_PAYMENT_INFO,
});
export const setPaymentInfo = (
  value: PaymentInfoType
): SetPaymentInfoAction => ({
  type: SET_PAYMENT_INFO,
  value,
});
export const isPersonalInfoFetched = (
  value: boolean
): IsPersonalInfoFetchedAction => ({
  type: IS_PERSONAL_INFO_FETCHED,
  value,
});
export const setTemplateHeaders = (
  value: TemplateType<$Keys<CpaAffiliatesListItem>>
): SetTemplateHeaders => ({
  type: SET_TEMPLATE_HEADERS,
  value,
});
export const setTemplatesList = (
  value: TemplateList
) => ({
  type: SET_TEMPLATES_LIST,
  value,
});

export const setMessages = (
  value: { status: string, msg: string }
) => ({
  type: SET_MESSAGES,
  value,
});

export const setFetchedManagersList: (Manager[]) => SetFetchedManagersList = (managersList) => ({
  type: SET_FETCHED_MANAGERS,
  managersList,
});

export const switchChangeAuthorModalOpened: () => SwitchChangeAuthorModalOpened = () => ({
  type: SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
});

export const switchChangeStatusModalOpened: () => SwitchChangeStatusModalOpened = () => ({
  type: SWITCH_CHANGE_STATUS_MODAL_OPENED,
});

export const changeIsManagersListLoaded: () => ChangeIsManagersListLoaded = () => ({
  type: CHANGE_IS_MANAGER_LIST_LOADED,
});

export const setManagersList = (value: Array<{label: string, value: string}>) => ({
  type: SET_MANAGERS_LIST,
  value
});

export const getManageAffiliatesDataSaga: () => GetManageAffiliatesDataSaga = () => ({
  type: GET_MANAGE_AFFILIATES_DATA_SAGA,
});

export type GetPersonalInfoListsSaga = {
  type: typeof GET_PERSONAL_INFO_LISTS_SAGA,
  affId?: string,
}
export const getPersonalInfoListsSaga: (affId?: string) => GetPersonalInfoListsSaga = (affId) => ({
  type: GET_PERSONAL_INFO_LISTS_SAGA,
  affId,
});

export type SaveAffiliateSaga = {
  type: typeof SAVE_AFFILIATE_SAGA,
  payload: { [string]: * },
}
export const saveAffiliateSaga: (data: { [string]: * }) => SaveAffiliateSaga = (data) => ({
  type: SAVE_AFFILIATE_SAGA,
  payload: data,
});

export type GetAffiliateSaga = {
  type: typeof GET_AFFILIATE_SAGA,
  payload: string,
}
export const getAffiliateSaga: (id: string) => GetAffiliateSaga = (id) => ({
  type: GET_AFFILIATE_SAGA,
  payload: id,
})

export type UpdateAffiliateSaga = {
  type: typeof UPDATE_AFFILIATE_SAGA,
  data: { [string]: * },
  id: string,
}
export const updateAffiliateSaga: (id, data: { [string]: * }) => UpdateAffiliateSaga = (id, data) => ({
  type: UPDATE_AFFILIATE_SAGA,
  data,
  id,
})

export type BulkChangeAffiliateStatusSaga = {
  type: typeof BULK_CHANGE_AFFILIATE_STATUS,
  isActive: number,
}
export const bulkChangeAffiliateStatusSaga: (isActive: number) => BulkChangeAffiliateStatusSaga = (isActive) => ({
  type: BULK_CHANGE_AFFILIATE_STATUS,
  isActive,
})

export type UpdatePaymentInfoSaga = {
  type: typeof UPDATE_PAYMENT_INFO_SAGA,
}
export const updatePaymentInfoSaga = (): UpdatePaymentInfoSaga => ({
  type: UPDATE_PAYMENT_INFO_SAGA,
});

export type ChangeAffiliateStatusSaga = {
  type: typeof CHANGE_AFFILIATE_STATUS_SAGA
};
export const changeAffiliateStatusSaga = (): ChangeAffiliateStatusSaga => ({
  type: CHANGE_AFFILIATE_STATUS_SAGA,
});

export type LoginAsAffiliateSaga = {
  type: typeof LOGIN_AS_AFFILIATE_SAGA,
};
export const loginAsAffiliateSaga = (): LoginAsAffiliateSaga => ({
  type: LOGIN_AS_AFFILIATE_SAGA,
});

export type GenerateAutoLoginLinkSaga = {
  type: typeof GENERATE_AUTO_LOGIN_LINK_SAGA,
};
export const generateAutoLoginLinkSaga = (): GenerateAutoLoginLinkSaga => ({
  type: GENERATE_AUTO_LOGIN_LINK_SAGA,
});

export type GetTemplatesListSaga = {
  type: typeof GET_TEMPLATES_LIST_SAGA,
  payload?: string,
};
export const getTemplatesListSaga = (payload?: string): GetTemplatesListSaga => ({
  type: GET_TEMPLATES_LIST_SAGA,
  payload,
});

export type SaveTemplateSaga = {
  type: typeof SAVE_TEMPLATE_SAGA,
  payload: TemplateType<$Keys<CpaAffiliatesListItem>>,
};
export const saveTemplateSaga = (payload: TemplateType<$Keys<CpaAffiliatesListItem>>): SaveTemplateSaga => ({
  type: SAVE_TEMPLATE_SAGA,
  payload,
});

export type GetAuthorsListSaga = {
  type: typeof GET_AUTHOR_LIST_SAGA,
  payload: {id: number | string, name: string},
};
export const getAuthorsListSaga = (payload: {id: number | string, name: string}): GetAuthorsListSaga => ({
  type: GET_AUTHOR_LIST_SAGA,
  payload,
});

export type BulkChangeAuthorSaga = {
  type: typeof BULK_CHANGE_AUTHOR_SAGA,
  user: *,
  affiliates: Array<number | string>,
};
export const bulkChangeAuthorSaga = (user: *, affiliates: Array<number | string>): BulkChangeAuthorSaga => ({
  type: BULK_CHANGE_AUTHOR_SAGA,
  user,
  affiliates,
});

export type GetManagersListByAffiliateTypeSaga = {
  type: typeof GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA,
  payload: string,
};
export const getManagersListByAffiliateTypeSaga = (payload: string): GetManagersListByAffiliateTypeSaga => ({
  type: GET_MANAGERS_LIST_BY_AFFILIATE_TYPE_SAGA,
  payload,
})
export const copyPaymnetDetailsByIdSaga = (payload: string): CopyPaymnetDetailsByIdSaga => ({
  type: COPY_PAYMENT_DETAILS_BY_ID_SAGA,
  payload,
})

export type GetDropdownsByAffiliateTypeSaga = {
  type: typeof GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA,
  payload: { type: string, filter: string },
}
export const getDropdownsByAffiliateTypeSaga = (payload: { type: string, filter: string }): GetDropdownsByAffiliateTypeSaga => ({
  type: GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA,
  payload,
})

export type SetDictionaryList = {
  type: typeof SET_DICTIONARY_LIST,
  payload: { key: string, value: Array<*> }
}
export const setDictionaryList = (payload: { key: string, value: Array<*> }): SetDictionaryList => ({
  type: SET_DICTIONARY_LIST,
  payload,
})
export const incrementSubmitCount = (): IncrementSubmitCount => ({
  type: INCREMENT_SUBMIT_COUNT,
})

export type ChangeAffiliatePaymentVerifySaga = {
  type: typeof CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA,
  paymentInfoId: number,
  isVerified: number,
};
export const changeAffiliatePaymentVerifySaga = (paymentInfoId, isVerified): ChangeAffiliatePaymentVerifySaga => ({
  type: CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA,
  paymentInfoId,
  isVerified,
});
