/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useState,
  useEffect,
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Grid,
  Paper,
  Toolbar,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import {
  NavigationList,
  FooterInput,
  ListWithGroups,
  LoadingButton,
} from "@fas/ui-core";
import { makeStyles, type Theme, type ClassNameMap } from "@mui/styles";
import { mapDefaultAttributes, getConfigAttribute } from "../../../services/templateModels/defaultModels/utils";
import type { Item } from "../../../services/templateModels/defaultModels/types";
import type { UseState, UseStylesTypes } from "../../../helpers/types";
import type { Props, GroupListItem } from "./TemplateModal.types";
import type {
  Template,
  TemplateAttribute,
  TemplateAttributeWithConfig,
  AttributeFilter,
  ConfigGroup,
  ConfigAttribute,
  Dropdown,
} from "../../../reducers/templates";
import { TemplateHeader, ReportColumns, FilteringValues } from "..";
import Can from "../../Can";

const useStyles: UseStylesTypes = makeStyles((theme: Theme): ClassNameMap => ({
  rootToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  rootBtn: {
    margin: `0 ${theme.spacing(1)}`,
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 64px)",
    borderRadius: theme.shape.borderRadius,
  },
  modalHeader: {
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: "#fff",
  },
  bodyContainer: {
    height: "100%",
    overflow: "hidden",
  },
  navigatonListContainer: {
    padding: `${theme.spacing(2)}px 0 0`,
    borderRight: `1px solid ${theme.palette.borderColor}`,
  },
  filteringValues: {
    height: "100%",
    overflowY: "scroll",
  },
}));

const TemplateModal: StatelessFunctionalComponent<Props> = ({
  config,
  reportName,
  fetchSuggestMethod,
  dropdownsList,
  currentTemplate,
  onSetCurrentTemplate,
  onChangeCurrentTemplateAttribute,
  onSetCurrentTemplateAttributesList,
  onSetCurrentTemplateAttributeVisibility,
  onSetCurrentTemplateData,
  onSetActiveTemplate,
  onSaveTemplate,
  onExportTemplate,
  onSetTemplateModalOpen,
  isSavingTemplate,
  defaultGroup = "General information",
  baseAttribute,
  isSystemUser,
  userId,
  FactoryModel,
  isExportTemplateLoading,
}: Props) => {
  const classes: ClassNameMap = useStyles();
  const [selectedGroup, setSelectedGroup]: UseState<string> = useState("");
  const [isNavigated, setIsNavigated]: UseState<boolean> = useState(false);

  const {
    id: templateId,
    isSystem,
    name: templateName,
    attributes,
    createdBy,
  }: Template = currentTemplate;

  const configAttributes: Array<ConfigAttribute> = config.attributes
    .filter(({ isVisible }: ConfigAttribute): boolean => isVisible);
  const attributesWithConfig: Array<TemplateAttributeWithConfig> = attributes
    .map(({ id, ...rest }: TemplateAttribute): TemplateAttributeWithConfig => {
      const configAttribute: ConfigAttribute = getConfigAttribute(configAttributes, id);
      return ({ ...configAttribute, ...rest });
    });

  const selectedHeaders: Array<string> = attributes.map(({ name }: TemplateAttribute): string => name);
  const invisibleCount: number = attributes.filter(({ isHidden }: TemplateAttribute): boolean => !!isHidden).length;

  useEffect(() => {
    if (!attributes.length) {
      onSetCurrentTemplateAttributesList(mapDefaultAttributes(configAttributes));
    }
    setSelectedGroup(defaultGroup);
  }, [config]);

  const navigationListClick: (value: string) => void = (value: string) => {
    setSelectedGroup(value);
    setIsNavigated(true);
  };

  const onScroll: () => void = () => {
    if (selectedGroup && !isNavigated) {
      setSelectedGroup("");
    }
    setIsNavigated(false);
  };

  const groupsFormated = config.groups
    .map(({ id: groupid, label }: ConfigGroup) => ({
      title: label,
      filters: configAttributes
        .filter(({ dataSourceAttributeGroupId }: ConfigAttribute): boolean => (
          dataSourceAttributeGroupId === groupid
        ))
        .map((attribute: ConfigAttribute) => ({
          filterKey: attribute.name,
          id: attribute.id,
          title: attribute.label,
          isDisabled: !!attribute.isDefault,
          selected: selectedHeaders.includes(attribute.name),
        })),
    }));

  const listGroupToggleAll: (value: boolean) => void = (value: boolean) => {
    if (value) {
      onSetCurrentTemplateAttributesList(configAttributes
        .map((configAttribute: ConfigAttribute): TemplateAttribute => {
          const attribute: TemplateAttribute | typeof undefined = attributes
            .find((attr: TemplateAttribute) => configAttribute.id === attr.id);
          return attribute || {
            id: configAttribute.id,
            name: configAttribute.name,
            filters: [],
            isHidden: false,
          };
        }));
    }
    else {
      onSetCurrentTemplateAttributesList(attributesWithConfig
        .filter(({ isDefault }: TemplateAttributeWithConfig): boolean => isDefault)
        .map(({
          id,
          name,
          filters,
          isHidden,
        }: TemplateAttributeWithConfig): TemplateAttribute => ({
          id,
          name,
          filters,
          isHidden,
        })));
    }
  };

  const isAllSelected: boolean = selectedHeaders.length === configAttributes.length;

  const onItemClick: (item: GroupListItem) => void = (item: GroupListItem) => {
    if (item.value) {
      onChangeCurrentTemplateAttribute(item.filterKey, []);
    }
    else {
      onChangeCurrentTemplateAttribute(item.filterKey, null);
    }
  };

  const onDeleteItem: (item: { key: string }) => void = ({ key }) => {
    onChangeCurrentTemplateAttribute(key, null);
  };

  const onVisibilityChange: (item: { key: string }) => void = ({ key }) => {
    onSetCurrentTemplateAttributeVisibility(key);
  };

  const prepearedList = attributes
    .map(
      ({ name, filters, isHidden }: TemplateAttribute) => {
        const model = FactoryModel.getModel(name);
        const { label, dictionaryName } = model;
        const item: Item = { title: label, key: name };
        return {
          ...item,
          isDragDisabled: baseAttribute === name,
          InputComponent: model.renderAttributeInput({
            item,
            dropdowns: (((dropdownsList[dictionaryName] || []): any): Array<Dropdown>),
            fetchMethod: (_, searchString) => fetchSuggestMethod({ reportName, dictionaryName, searchString }),
            onSetFilters: (newFilters: Array<AttributeFilter>) => {
              onChangeCurrentTemplateAttribute(name, newFilters);
            },
            // $FlowFixMe
            filters,
            isHidden,
            onDeleteItem,
            onVisibilityChange,
          }),
        };
      }
    );

  const changeOrder = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }
    const currentAttribute: TemplateAttribute = attributes[source.index];
    const rest: Array<TemplateAttribute> = attributes.filter((v, index) => index !== source.index);
    onSetCurrentTemplateAttributesList([
      ...rest.slice(0, destination.index),
      currentAttribute,
      ...rest.slice(destination.index),
    ]);
  };

  const createdAtAttribute: TemplateAttribute | typeof undefined = attributes
    .find((attribute: TemplateAttribute): boolean => attribute.name === "createdAt");
  const createdAt: string = createdAtAttribute?.filters[0]?.value || "";
  const updatedAtAttribute: TemplateAttribute | typeof undefined = attributes
    .find((attribute: TemplateAttribute): boolean => attribute.name === "updatedAt");
  const updatedAt: string = updatedAtAttribute?.filters[0]?.value || "";

  const isApplyDisabled: boolean = !attributes.some((attribute: TemplateAttribute): boolean => !attribute.isHidden);

  return (
    <Box className={classes.modal} data-testid="templateModal">
      <TemplateHeader
        createdAt={createdAt}
        updatedAt={updatedAt}
        onChangeFilters={onChangeCurrentTemplateAttribute}
        onClose={() => {
          onSetCurrentTemplate(null);
          onSetTemplateModalOpen(false);
        }}
      />
      <Grid container className={classes.bodyContainer}>
        <Grid item className={classes.navigatonListContainer} xs={3}>
          <NavigationList
            list={config.groups.map(({ label }) => ({ value: label }))}
            selected={selectedGroup}
            onChange={navigationListClick}
          />
        </Grid>
        <Grid item xs={3}>
          <ListWithGroups
            groups={groupsFormated}
            isAllSelected={isAllSelected}
            onItemClick={onItemClick}
            toggleAll={listGroupToggleAll}
            scrollTo={selectedGroup}
            onScroll={onScroll}
          />
        </Grid>
        <Grid item xs={3}>
          <ReportColumns
            list={prepearedList || []}
            onOrderChange={changeOrder}
            invisibleCount={invisibleCount}
          />
        </Grid>
        <Grid item xs={3} className={classes.filteringValues}>
          <FilteringValues
            attributes={attributesWithConfig}
            onSetFilters={onChangeCurrentTemplateAttribute}
            onSetAttributesList={onSetCurrentTemplateAttributesList}
          />
        </Grid>
      </Grid>
      <Paper elevation={6} classes={classes.footer}>
        <Toolbar classes={{ root: classes.rootToolbar }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FooterInput
              onSave={() => {
                onSaveTemplate(null);
              }}
              onChangeName={(value: string) => {
                onSetCurrentTemplateData({ name: value });
              }}
              templateName={templateName}
              disableSave={!templateName}
              disableUpdate={!templateId || userId !== createdBy || !templateName}
              onUpdate={() => {
                onSaveTemplate(templateId);
              }}
              disabled={isSavingTemplate || isApplyDisabled}
              buttonLabel="Save template"
              updateLabel="Update template"
              saveLabel="Save as new template"
              inputProps={{
                placeholder: "Enter name",
              }}
            />
            {isSystemUser && (
              <Box ml={2}>
                <FormControlLabel
                  control={(
                    <Switch
                      data-testid="system-switch"
                      checked={isSystem}
                      color="secondary"
                      onChange={() => onSetCurrentTemplateData({ isSystem: !isSystem })}
                    />
                  )}
                  label="System"
                />
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Can permissions={[{ path: "/api/v1/cpa/exportOffers", method: "POST" }]}>
              <LoadingButton
                loading={isExportTemplateLoading}
                data-testid="template-modal-export-btn"
                classes={{ root: classes.rootBtn }}
                color="primary"
                onClick={() => onExportTemplate(attributes)}
              >
                Download in CSV
              </LoadingButton>
            </Can>
            <Button
              data-testid="template-modal-close-btn"
              classes={{ root: classes.rootBtn }}
              color="primary"
              onClick={() => {
                onSetCurrentTemplate(null);
                onSetTemplateModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="template-modal-apply-btn"
              classes={{ root: classes.rootBtn }}
              color="primary"
              onClick={() => {
                onSetActiveTemplate({ ...currentTemplate, attributes });
                onSetCurrentTemplate(null);
                onSetTemplateModalOpen(false);
              }}
              disabled={isApplyDisabled}
            >
              Apply
            </Button>
          </Box>
        </Toolbar>
      </Paper>
    </Box>
  );
};

export default TemplateModal;
