// @flow
import React from "react";
import {
  Box, FormControl, FormHelperText, IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import type { ItemType } from "./MassAdjustModal";

type Props = {
  item: ItemType,
  setFields: ({ [string]: string | number }) => void,
  fields: { [string]: string | number },
  isErrorsVisible: boolean,
  handleDelete: (ItemType) => void,
};

const ViewRow = ({
  item,
  setFields,
  fields,
  isErrorsVisible,
  handleDelete,
}: Props) => (
  <Box mb={2}>
    <FormControl fullWidth variant="outlined">
      <Box display="flex" alignItems="center" justifyContent="space-between" data-testid={`${item.value}-block`}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          label={item.title}
          data-testid={item.value}
          value={fields[item.value]}
          type={item.type}
          InputProps={
            {
              inputProps: {
                min: item.min,
                max: item.max,
              },
            }
          }
          error={isErrorsVisible && fields[item.value] === ""}
          onChange={({ target: { value: newValue } }) => {
            let result: string | number | null = newValue;
            if (item.type === "number") {
              result = Number(newValue) > 99999999 ? fields[item.value] : Number(newValue);
            }
            setFields({
              ...fields,
              [item.value]: result,
            });
          }}
        />
        <IconButton onClick={() => handleDelete(item)} size="large" data-testid="deleteButton">
          <Delete />
        </IconButton>
      </Box>
      <FormHelperText hidden={!isErrorsVisible || fields[item.value] !== ""} error>
        Enter value or delete field.
      </FormHelperText>
    </FormControl>
  </Box>
);

export default ViewRow;
