// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import { changeTableFilters, changeTableSorting } from "@fas/ui-framework/lib/redux/actions/table";
import type { Filters as TableFilters } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Column, TableClasses } from "@fas/ui-core/lib/Table/Table.types";
import { Table } from "@fas/ui-core";
import { makeStyles } from "@mui/styles";
import type { Offer } from "../../services/templateModels/cpaOfferModels/types";
import { getCpaOffersDropdownLists } from "../../selectors/dictionaries";
import { getActiveTemplate } from "../../selectors/templates";
import { getFiltersList } from "../../selectors/filters";
import { getCpaOfferDropdownListsSaga } from "../../actions/cpaOfferIndex";
import { getTemplateFilterDataSaga } from "../../actions/templates";
import { setFilters } from "../../actions/filters";
import { CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import type { Props } from "./types/CpaOfferIndex.types";
import { compareById } from "../../helpers/generators/generators";
import CpaTableActions from "./Actions";
import ChangeUserModal from "../../components/ChangeUserModal";
import type { ChangeManagerModalType } from "./useChangeManagerModal";
import { useChangeManagerModal } from "./useChangeManagerModal";
import { getColumns } from "../../services/templateModels/cpaOfferModels";
import type { Filter } from "../../reducers/filters";
import type { Dropdown, Template, TemplateAttribute } from "../../reducers/templates";

export type Classes = {
  middleTableCol: string,
  smallTableCol: string,
  longTableCol: string,
  veryLongTableCol: string,
};

const useStyles: () => Classes = makeStyles({
  middleTableCol: {
    minWidth: "160px",
    width: "160px",
    maxWidth: "160px",
    wordBreak: "break-word",
  },
  smallTableCol: {
    width: "120px",
    minWidth: "120px",
    wordBreak: "break-word",
  },
  longTableCol: {
    minWidth: "250px",
    width: "250px",
    maxWidth: "250px",
    wordBreak: "break-word",
  },
  veryLongTableCol: {
    minWidth: "350px",
    width: "350px",
    maxWidth: "350px",
    wordBreak: "break-word",
  },
});
const useTableStyles: () => TableClasses = makeStyles({
  tableWrapper: {
    "&:not(:nth-child(3))": {
      height: "calc(100% - 52px)",
    },
    "&:nth-child(3)": {
      height: "calc(100% - 145px)",
    },
  },
  paperWrapper: {
    height: "100%",
    "& > div:nth-child(2)": {
      height: "calc(100% - 64px)",
      "& > div:nth-child(3)": {
        height: "calc(100% - 140px)",
      },
    },
  },
});

const CpaOfferIndex: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const classes: Classes = useStyles();
  const tableClasses: TableClasses = useTableStyles();
  const tableProps: TableProps = useTable(CPA_OFFER_TABLE);
  const dispatch: <A>(A) => A = useDispatch();
  const dropdownsList: { [string]: Array<Dropdown> } = useSelector(getCpaOffersDropdownLists);
  const activeTemplate: Template = useSelector(getActiveTemplate);
  const filters: Array<Filter> = useSelector(getFiltersList);

  useEffect(() => {
    const { attributes }: Template = activeTemplate;
    if (attributes.length > 0) {
      dispatch(setFilters(attributes.map(({ name, filters: attrFilters }: TemplateAttribute): Filter => ({
        name, filters: attrFilters,
      }))));

      const tableFilters: TableFilters = attributes
        .filter(({ filters: attrFilters }) => attrFilters.length > 0)
        .reduce((acc, { name, filters: attrFilters }): TableFilters => ({
          ...acc,
          [name]: attrFilters,
        }), {});
      dispatch(changeTableFilters(CPA_OFFER_TABLE, tableFilters));
      dispatch(changeTableSorting(CPA_OFFER_TABLE, {}));
    }
  }, [activeTemplate]);

  const handleChangeFilters = (newFilters: TableFilters) => {
    dispatch(setFilters(filters.map(({ name }: Filter): Filter => ({
      name,
      // $FlowFixMe TODO: expand table filter type
      filters: newFilters[name] || [],
    }))));
    dispatch(changeTableFilters(CPA_OFFER_TABLE, newFilters));
  };

  const onLoad: () => mixed = () => {
    if (activeTemplate.attributes.length) {
      dispatch(getTemplateFilterDataSaga());
    }
  };

  const columns: Column<Offer>[] = getColumns({ attributes: activeTemplate.attributes, dropdownsList, classes });

  useEffect(() => {
    dispatch(getCpaOfferDropdownListsSaga());
  }, [getCpaOfferDropdownListsSaga]);

  const onCreate: () => void = () => {
    window.location = "/cpaOffers/add";
  };

  const userManagerModalProps: ChangeManagerModalType = useChangeManagerModal();

  return (
    <>
      <Table
        {...tableProps}
        columns={columns}
        title="CPA Offers"
        onLoad={onLoad}
        onCreate={onCreate}
        rowSelectAvailable={() => true}
        allSelectAvailable={() => true}
        compareRows={compareById}
        Actions={<CpaTableActions />}
        onChangeFilters={handleChangeFilters}
        classes={tableClasses}
      />
      <ChangeUserModal {...userManagerModalProps} />
    </>
  );
};

export default CpaOfferIndex;
