// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import thunkMiddleware from "redux-thunk";
import Immutable from "immutable";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import dropdowns, { initDropdownState, type DropdownsState } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { Actions as CpaOfferCampaignsActions } from "../../actions/cpaOfferCampaigns";
import { CPA_OFFER_CAMPAIGNS_TABLE } from "../../helpers/constants/cpaoffer";
import mainSaga from "./saga";

export type Filters = {
  offerId: Array<{ label: string, value: string }>,
  offerName: Array<{ label: string, value: string }>,
  campaignId: Array<{ label: string, value: string }>,
  campaignName: Array<{ label: string, value: string }>,
  split: Array<{ label: string, value: string }>,
  funnelName: Array<{ label: string, value: string }>,
  campaignStatus: Array<{ label: string, value: string }>,
  offerStatus: Array<{ label: string, value: string }>,
  offerInCampaignStatus: Array<{ label: string, value: string }>,
}

export type State = {|
  loading: LoadingState<typeof CPA_OFFER_CAMPAIGNS_TABLE>,
  tables: TableState,
  dropdowns: DropdownsState<string, string | number>,
  notifications: NotificationsState,
|};
export type Actions = TableActions
  | LoadingActions<typeof CPA_OFFER_CAMPAIGNS_TABLE>
  | NotificationsActions
  | CpaOfferCampaignsActions;

function mapCpaOfferCampaignsToState(): State {
  return {
    loading: initLoadingState<typeof CPA_OFFER_CAMPAIGNS_TABLE>({
      [CPA_OFFER_CAMPAIGNS_TABLE]: false,
    }),
    tables: initTableState({
      [CPA_OFFER_CAMPAIGNS_TABLE]: {
        pageSize: 20,
      },
    }),
    dropdowns: initDropdownState({
      split: [
        { label: "a", value: "a" },
        { label: "b", value: "b" },
        { label: "c", value: "c" },
        { label: "d", value: "d" },
        { label: "e", value: "e" },
        { label: "f", value: "f" },
      ],
      campaignStatus: [
        // $FlowFixMe
        { label: "Yes", value: 1 },
        // $FlowFixMe
        { label: "No", value: 0 },
      ],
      offerStatus: [
        // $FlowFixMe
        { label: "Yes", value: 1 },
        // $FlowFixMe
        { label: "No", value: 0 },
      ],
      offerInCampaignStatus: [
        // $FlowFixMe
        { label: "Yes", value: 1 },
        // $FlowFixMe
        { label: "No", value: 0 },
      ],
    }),
    notifications: initNotificationsState(),
  };
}

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  tables,
  dropdowns,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;
  const middlewares = [thunkMiddleware, sagaMiddleware];

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapCpaOfferCampaignsToState(),
    composeEnhancers(applyMiddleware(
      ...middlewares
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
