import { changeTableFilters, getTableFilters } from "@fas/ui-framework";
import { useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import {
  useLocation, useNavigate, Location,
} from "react-router-dom";
import qs from "qs";
import { useDispatch, useSelector } from "../store";
import { getFlatObjectDifference } from "./utils";
import useQueryParams from "../../components/useQueryParams";
import { getQueryStringValue } from "../../../helpers/query/queryParamsHelper";

// eslint-disable-next-line max-len
function useTableQueryFilter<FT extends object>(tableKey: string, defaultFilters: FT, prepareFilters?: (f: FT) => FT) {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getTableFilters<FT>(state, tableKey));
  const [isQueryFilterLoading, setIsQueryFilterLoading] = useState(true);
  const query = useQueryParams<{ filters: FT }>();
  const prevFiltersRef = useRef<object>({});
  const prevQueryFiltersRef = useRef<object>({});
  const navigate = useNavigate();
  const { state } = useLocation() as Location<Partial<{ isUseStoreFilters: boolean }> | null>;

  useEffect(() => {
    const newFilters = {
      ...defaultFilters,
      ...(query.filters || {}) as object,
    };
    // eslint-disable-next-line max-len
    const isUpdateStoreFiltersByQuery = (!state?.isUseStoreFilters && (!isEqual(prevQueryFiltersRef.current, newFilters))) || !mountingMap[tableKey];
    const isUpdateQueryByStoreFilters = (state?.isUseStoreFilters || !isEqual(prevFiltersRef.current, filters));

    if (!isEqual(newFilters, filters)) {
      if (isUpdateStoreFiltersByQuery) {
        dispatch(changeTableFilters(tableKey, prepareFilters ? prepareFilters(newFilters) : newFilters));
      }
      else if (isUpdateQueryByStoreFilters) {
        const diff = getFlatObjectDifference(filters, defaultFilters) as FT;
        const findAll = getQueryStringValue("findAll", "");
        const search = qs.stringify({
          filters: (prepareFilters ? prepareFilters(diff) : diff),
          ...(findAll ? { findAll } : {}),
        });
        navigate({ search }, { replace: true });
      }
    }

    prevQueryFiltersRef.current = newFilters;
    prevFiltersRef.current = filters;
    setIsQueryFilterLoading(false);
    mountingMap[tableKey] = true;
  }, [defaultFilters, dispatch, navigate, tableKey, query, filters, state, prepareFilters]);

  return isQueryFilterLoading;
}

const mountingMap: { [key: string]: boolean } = {};

export default useTableQueryFilter;
