// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store } from "redux";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import {
  reducer as errors,
  initState as initErrorsState,
  type State as ErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import type { Actions as ErrorsAction } from "@fas/ui-framework/lib/redux/actions/errors";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";

import form, { initFormState } from "@fas/ui-framework/lib/redux/reducers/form";
import type { State as FormState } from "@fas/ui-framework/lib/redux/reducers/form/reducer";
import mainSaga from "./saga";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import loading, {
  type State as LoadingState,
  type Action as LoadingAction,
  initState as initLoadingState,
} from "../../reducers/loading";
import { FORM_KEY_TOP_OFFERS } from "../../helpers/constants/topOffers";

export type State = $ReadOnly<{|
  form: FormState,
  errors: ErrorsState,
  loading: LoadingState<"saveTopOffersLoading" | "getTopOffersSearchOffersLoading">,
  dictionaries: DictionariesState,
  notifications: NotificationsState,
|}>

export function mapPageToState(): State {
  return {
    form: initFormState({
      [FORM_KEY_TOP_OFFERS]: {
        id: undefined,
        projectType: "",
        vertical: "",
        geo: [],
        platform: "",
        eCPM: "",
        isActive: true,
        offerId: "",
        offerName: "",
        conversionType: "",
        payout: "",
        currencyId: "",
        image: "",
      },
    }),
    errors: initErrorsState(),
    loading: initLoadingState({
      saveTopOffersLoading: false,
      getTopOffersSearchOffersLoading: false,
    }),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
  };
}

export type Actions = ErrorsAction
  | LoadingAction<"saveTopOffersLoading" | "getTopOffersSearchOffersLoading">
  | NotificationsActions;

const reducers = combineReducers({
  form,
  errors,
  loading,
  dictionaries,
  notifications,
});

const sagaMiddleware = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPageToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
