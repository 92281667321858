import { useQueryClient } from "@tanstack/react-query";
import type api from "../../../services/api";

type Api = keyof typeof api;

const toMap = (list: string[]): {[p: string]: boolean} => list.reduce((acc, key) => ({ ...acc, [key]: true }), {});

export default function useInvalidateOperation() {
  const queryClient = useQueryClient();
  return (operations: Api[]) => {
    const map = toMap(operations);
    return queryClient.invalidateQueries({
      predicate: (q) => q.queryKey?.some((key) => map[String(key)]),
    });
  };
}
