// @flow
import {
  select,
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import {
  getManageAffiliateId,
} from "../../selectors/manageAffiliate";
import {
  setPaymentInfo, setPersonalInfoField,
} from "../../actions/manageAffiliate";
import { copyPaymentDetailsById } from "../../services/manageAffiliateApi";
import type { CopyPaymnetDetailsByIdSaga } from "../../actions/manageAffiliate";
import { COPY_PAYMENT_DETAILS_BY_ID_SAGA } from "../../helpers/constants/manageAffiliate";

export function* makeFetch(action: CopyPaymnetDetailsByIdSaga): Saga<void> {
  const { payload: copyId }: CopyPaymnetDetailsByIdSaga = action;
  const currentId: string = yield select(getManageAffiliateId);
  try {
    yield put(setLoading("getPersonalInfo", true));

    const { data } = yield call(copyPaymentDetailsById, currentId, copyId);

    yield put(setPaymentInfo(data));
    yield put(setPersonalInfoField({ field: "parentAffIdPayment", value: copyId }));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getPersonalInfo", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(COPY_PAYMENT_DETAILS_BY_ID_SAGA, makeFetch);
}
