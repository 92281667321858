/* eslint-disable import/max-dependencies */
// @flow
import React, {
  useEffect,
  useMemo,
  type Element,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Table,
  ViewLink,
  IsActiveLabel,
  LessMore,
  SelectSearchComponent,
  MultiselectSearchComponent,
  Loader,
  ShowMoreLink,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { CPA_AFFILIATES_LIST_TABLE } from "../../helpers/constants/manageAffiliate";
import { getLoaders, getDropdownList, getManageAffiliateTableHeaders } from "../../selectors/manageAffiliate";
import {
  getPersonalInfoListsSaga,
  getManageAffiliatesDataSaga,
  generateAutoLoginLinkSaga,
  loginAsAffiliateSaga,
  bulkChangeAffiliateStatusSaga,
  changeAffiliateStatusSaga,
  getAuthorsListSaga,
  switchChangeAuthorModalOpened,
  switchChangeStatusModalOpened,
  type GetPersonalInfoListsSaga,
  type GetManageAffiliatesDataSaga,
  type BulkChangeAffiliateStatusSaga,
  type LoginAsAffiliateSaga,
  type GenerateAutoLoginLinkSaga,
  type ChangeAffiliateStatusSaga,
  type GetAuthorsListSaga,
  type SwitchChangeAuthorModalOpened,
  type SwitchChangeStatusModalOpened,
} from "../../actions/manageAffiliate";
import TableActions from "../../components/ManageAffiliateTableActions";
import ManageAffiliateContent from "./components/ManageAffiliateContent";
import type { Loaders } from "../../reducers/manageAffiliate";
import type { State } from "../../pages/ManageAffiliate";
import type { CpaAffiliatesListItem, Classes } from "./types/ManageAffiliate.types";
import { userService } from "../../services/user";

const useStyles: () => Classes = makeStyles((): {[key: $Keys<Classes>]: mixed} => ({
  smallTableCol: {
    minWidth: 100,
  },
  middleTableCol: {
    minWidth: 150,
  },
  longTableCol: {
    minWidth: 200,
  },
  veryLongTableCol: {
    minWidth: 250,
  },
}));

const onCreate: () => void = () => {
  window.location = "/manageAffiliate/create";
};

const mapper: {[key: string]: string} = {
  "0": "No",
  "1": "Yes",
};

const ManageAffiliate = (): Element<*> => {
  const tableProps: TableProps = useTable(CPA_AFFILIATES_LIST_TABLE);
  const { selected }: TableProps = tableProps;

  const countries: Array<string | Object> = useSelector((state: State): Array<string | Object> => getDropdownList(state, "countries"));
  const declineReasons: Array<string | Object> = useSelector((state: State): Array<string | Object> => getDropdownList(state, "declineReasons"));
  const { headersOrder }: Object = useSelector((state: State): Object => getManageAffiliateTableHeaders(state));

  const dispatch: <A>(A) => A = useDispatch();
  const onGetPersonalInfoLists: () => GetPersonalInfoListsSaga = () => dispatch(getPersonalInfoListsSaga());
  const onGetCpaAffiliatesList: () => GetManageAffiliatesDataSaga = () => dispatch(getManageAffiliatesDataSaga());

  const onChangeIsActive: (isActive: number) => BulkChangeAffiliateStatusSaga = (isAcive) => (
    dispatch(bulkChangeAffiliateStatusSaga(isAcive)));
  const onCopyAutoLoginLink: () => GenerateAutoLoginLinkSaga = () => dispatch(generateAutoLoginLinkSaga());
  const onAutoLogin: () => LoginAsAffiliateSaga = () => dispatch(loginAsAffiliateSaga());
  const onChangeAffiliateStatus: () => ChangeAffiliateStatusSaga = () => dispatch(changeAffiliateStatusSaga());
  const onGetAuthorsList: (payload: {id: number | string, name: string}) => GetAuthorsListSaga = (
    (payload) => dispatch(getAuthorsListSaga(payload)));
  const onSwitchChangeAuthorModalOpened: () => SwitchChangeAuthorModalOpened = () => (
    dispatch(switchChangeAuthorModalOpened()));
  const onSwitchChangeStatusModalOpened: () => SwitchChangeStatusModalOpened = () => (
    dispatch(switchChangeStatusModalOpened()));

  // TODO: remove isListsLoaded loaders, when common loading reducer will be implemented
  const { isListsLoaded }: Loaders = useSelector((state: State): Loaders => getLoaders(state));

  const classes: Classes = useStyles();

  useEffect(() => {
    onGetPersonalInfoLists();
  }, []);

  const tableColumns: Column<CpaAffiliatesListItem>[] = useMemo((): Column<CpaAffiliatesListItem>[] => {
    const columns: Column<CpaAffiliatesListItem>[] = [
      {
        field: "id",
        label: "ID",
        className: classes.smallTableCol,
        sortable: true,
        searchable: true,
        render: ({ id }: CpaAffiliatesListItem): Element<typeof ViewLink> => <ViewLink id={id} name={id} viewPath="/manageAffiliate/edit/:id/personalInfo" />,
      },
      {
        field: "isActive",
        label: "Is active",
        className: classes.smallTableCol,
        sortable: true,
        searchable: true,
        SearchComponent: (
          searchProps: SearchComponentProps
        ): Element<typeof SelectSearchComponent> => (
          <SelectSearchComponent
            {...searchProps}
            values={[
              { title: "All", value: "" },
              { title: "Active", value: "1" },
              { title: "Inactive", value: "0" },
            ]}
            label="isActive"
          />
        ),
        render: ({ isActive }: CpaAffiliatesListItem): Element<typeof IsActiveLabel> => (
          <IsActiveLabel isActive={Boolean(isActive)} />
        ),
      },
      {
        field: "name",
        label: "Affiliate name",
        className: classes.veryLongTableCol,
        sortable: true,
        searchable: true,
        render: ({ name }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={name || ""} />,
      },
      {
        field: "createdAt",
        label: "Created at",
        className: classes.smallTableCol,
        sortable: true,
        render: ({ createdAt }: CpaAffiliatesListItem): Element<typeof Typography> => (
          <Typography>
            {moment(createdAt).format("YYYY-MM-DD hh:mm:ss")}
          </Typography>
        ),
      },
      {
        field: "status",
        label: "Status",
        className: classes.middleTableCol,
        sortable: true,
        render: ({ status }: CpaAffiliatesListItem): Element<"div"> => {
          let color: string = "#FF9900";
          if (status === "Approve") color = "#2EB000";
          if (status === "Decline" || status === "Suspended") color = "#C80022";

          return (<div style={{ color }}>{status}</div>);
        },
      },
      {
        field: "type",
        label: "Affiliate type",
        className: classes.middleTableCol,
        sortable: true,
      },
      {
        field: "manager",
        label: "Manager",
        className: classes.longTableCol,
        searchable: true,
      },
      {
        field: "companyName",
        label: "Company name",
        className: classes.middleTableCol,
        searchable: true,
        render: ({ companyName }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={companyName || ""} />,
      },
      {
        field: "paid",
        label: "Paid, $",
        className: classes.smallTableCol,
        render: ({ paid }: CpaAffiliatesListItem): string => (paid !== 0 ? String(paid) : paid.toFixed(2)),
      },
      {
        field: "approved",
        label: "Approved, $",
        className: classes.smallTableCol,
        render: ({
          approved,
        }: CpaAffiliatesListItem): string => (approved !== 0 ? String(approved) : approved.toFixed(2)),
      },
      {
        field: "pending",
        label: "Pending, $",
        className: classes.smallTableCol,
        render: ({ pending }: CpaAffiliatesListItem): string => (pending !== 0 ? String(pending) : pending.toFixed(2)),
      },
      {
        field: "declined",
        label: "Declined, $",
        className: classes.smallTableCol,
        render: ({
          declined,
        }: CpaAffiliatesListItem): string => (declined !== 0 ? String(declined) : declined.toFixed(2)),
      },
      {
        field: "refererUrl",
        label: "Came from",
        className: classes.smallTableCol,
        searchable: true,
        render: ({ refererUrl }: CpaAffiliatesListItem): Element<typeof ShowMoreLink> | string => (
          refererUrl ? <ShowMoreLink link={refererUrl} /> : ""
        ),
      },
      {
        field: "registrationUrl",
        label: "Registration URL",
        className: classes.longTableCol,
        sortable: true,
        searchable: true,
        render: ({ registrationUrl }: CpaAffiliatesListItem): Element<typeof ShowMoreLink> | string => (
          registrationUrl ? <ShowMoreLink link={registrationUrl} /> : ""
        ),
      },
      {
        field: "revenue",
        label: "Commission",
        className: classes.middleTableCol,
        searchable: true,
      },
      {
        field: "trafficType",
        label: "Traffic type",
        className: classes.middleTableCol,
        searchable: true,
      },
      {
        field: "topVerticals",
        label: "Top verticals",
        className: classes.middleTableCol,
        searchable: true,
      },
      {
        field: "countryByIp",
        label: "Country by ip",
        className: classes.middleTableCol,
        searchable: true,
      },
      {
        field: "refSrc",
        label: "Hear about from",
        className: classes.middleTableCol,
        searchable: true,
      },
      {
        label: "Total shave",
        field: "shaveAmount",
        className: classes.smallTableCol,
      },
      {
        label: "Ncr amount",
        field: "ncrAmount",
        className: classes.smallTableCol,
      },
      {
        label: "Payment available",
        field: "paymentAvailable",
        className: classes.middleTableCol,
      },
      {
        label: "Ignore shave",
        field: "isShaveIgnored",
        className: classes.smallTableCol,
        render: ({ isShaveIgnored }: CpaAffiliatesListItem): string => mapper[String(isShaveIgnored)] || "",
      },
      {
        label: "Shave all",
        field: "isShavedAll",
        className: classes.smallTableCol,
        render: ({ isShavedAll }: CpaAffiliatesListItem): string => mapper[String(isShavedAll)] || "",
      },
      {
        label: "Is ad network",
        field: "isAdNetwork",
        className: classes.smallTableCol,
        render: ({ isAdNetwork }: CpaAffiliatesListItem): string => mapper[String(isAdNetwork)] || "",
      },
      {
        label: "First name",
        field: "firstName",
        className: classes.smallTableCol,
        render: ({ firstName }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={firstName || ""} />,
      },
      {
        label: "Last name",
        field: "lastName",
        className: classes.smallTableCol,
        render: ({ lastName }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={lastName || ""} />,
      },
      {
        label: "Email",
        field: "email",
        className: classes.middleTableCol,
        sortable: true,
        searchable: true,
        render: ({ email }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={email || "" || ""} />,
      },
      {
        label: "Instant messenger",
        field: "imType",
        className: classes.smallTableCol,
      },
      {
        label: "Im name",
        field: "imName",
        className: classes.smallTableCol,
        render: ({ imName }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={imName || ""} />,
      },
      {
        label: "Phone number",
        field: "phone",
        className: classes.smallTableCol,
        render: ({ phone }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={phone || ""} />,
      },
      {
        label: "Company url",
        field: "companyUrl",
        className: classes.smallTableCol,
        render: ({ companyUrl }: CpaAffiliatesListItem): Element<typeof ShowMoreLink> | string => (
          companyUrl ? <ShowMoreLink link={companyUrl} /> : ""),
      },
      {
        label: "Address",
        field: "address",
        className: classes.smallTableCol,
        render: ({ address }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={address || ""} />,
      },
      {
        label: "City",
        field: "city",
        className: classes.smallTableCol,
        render: ({ city }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={city || ""} />,
      },
      {
        label: "Zip",
        field: "zip",
        className: classes.smallTableCol,
        render: ({ zip }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={zip || ""} />,
      },
      {
        label: "State",
        field: "state",
        className: classes.smallTableCol,
        render: ({ state }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={state || ""} />,
      },
      {
        label: "Tax ssn/ein (usa only)",
        field: "taxSSN",
        className: classes.smallTableCol,
      },
      {
        label: "Country by ip",
        field: "countryByIp",
        className: classes.smallTableCol,
      },
      {
        label: "How did you get to know about?",
        field: "howFoundOut",
        className: classes.middleTableCol,
        render: ({ howFoundOut }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={howFoundOut || ""} />,
      },
      {
        label: "What top countries do you mostly run?",
        field: "topCountries",
        className: classes.longTableCol,
        render: ({ topCountries }: CpaAffiliatesListItem): Element<typeof LessMore> => <LessMore value={topCountries || ""} />,
      },
      {
        label: "Decline reason description",
        field: "declineReasonDescription",
        className: classes.longTableCol,
      },
      {
        label: "Currency",
        field: "currency",
        className: classes.smallTableCol,
      },
      {
        label: "Company type",
        field: "companyType",
        className: classes.longTableCol,
        sortable: true,
        searchable: true,
      },
      {
        label: "Fraud status",
        field: "fraudStatus",
        className: classes.middleTableCol,
        sortable: true,
        searchable: true,
        SearchComponent: (
          searchProps: SearchComponentProps
        ): Element<typeof MultiselectSearchComponent> => (
          <MultiselectSearchComponent
            {...searchProps}
            options={[
              { title: "Yes", value: "Yes" },
              { title: "No", value: "No" },
              { title: "Ignore", value: "Ignore" },
              { title: "Suspicious", value: "Suspicious" },
            ]}
          />
        ),
      },
      {
        field: "country",
        label: "Country",
        searchable: true,
        SearchComponent: (
          searchProps: SearchComponentProps
        ): Element<typeof SelectSearchComponent> => (
          <SelectSearchComponent
            {...searchProps}
            values={[
              { title: "All", value: "" },
              ...(countries && countries.map((item: Object): { title: string, value: string } => ({
                title: item.country_name,
                value: item.country_name,
              }))),
            ]}
            label="Country"
          />
        ),
      },
      {
        field: "declineReason",
        label: "Decline reason",
        searchable: true,
        SearchComponent: (
          searchProps: SearchComponentProps
        ): Element<typeof SelectSearchComponent> => (
          <SelectSearchComponent
            {...searchProps}
            values={[
              { title: "All", value: "" },
              ...(declineReasons && declineReasons.map((item: Object): { title: string, value: string } => ({
                title: item.value,
                value: item.value,
              }))),
            ]}
            label="Decline reason"
          />
        ),
      },
    ];

    return headersOrder
    // $FlowFixMe
      .map((key: $Keys<CpaAffiliatesListItem>): Column<CpaAffiliatesListItem> => columns
        .find(({ field }: Column<CpaAffiliatesListItem>): boolean => field === key));
  }, [headersOrder, countries, declineReasons]);
  const accessManageAffiliate = userService.can(["accessManageAffiliate"]);

  return (
    (!isListsLoaded) ? (
      <Loader loading size={35} mt={37} />
    ) : (
      <Box p={3}>
        <ManageAffiliateContent />
        <Table
          {...tableProps}
          title="CPA Affiliates"
          columns={tableColumns}
          rowSelectAvailable={(): boolean => accessManageAffiliate}
          allSelectAvailable={(): boolean => accessManageAffiliate}
          onCreate={accessManageAffiliate ? onCreate : undefined}
          onLoad={onGetCpaAffiliatesList}
          Actions={(
            <TableActions
              items={selected}
              onChangeIsActive={onChangeIsActive}
              onCopyAutoLoginLink={onCopyAutoLoginLink}
              onAutoLogin={onAutoLogin}
              onChangeAffiliateStatus={onChangeAffiliateStatus}
              onGetAuthorsList={onGetAuthorsList}
              onSwitchChangeAuthorModalOpened={onSwitchChangeAuthorModalOpened}
              onSwitchChangeStatusModalOpened={onSwitchChangeStatusModalOpened}
            />
          )}
        />
      </Box>
    )
  );
};

export default ManageAffiliate;
