// @flow
import {
  takeEvery,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import { CHANGE_AUTHOR_SAGA, CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import { changeCpaOfferAuthor } from "../../services/cpaOffersApi";
import type { ChangeAuthorSaga } from "../../actions/cpaoffer";
import { getTemplateFilterDataSaga } from "../../actions/templates";
import { switchChangeAuthorModalOpened } from "../../actions/cpaoffer";

export function* makeFetch(action: ChangeAuthorSaga): Saga<void> {
  const { user }: ChangeAuthorSaga = action;
  try {
    const selectedRows: Array<DataItem> = yield select(getTableSelections, CPA_OFFER_TABLE);
    yield call(changeCpaOfferAuthor, user, selectedRows.map((el: DataItem): string => el.id));
    yield put(addNotification({ message: "Manager has been changed", severity: "success" }));
    yield put(getTemplateFilterDataSaga());
    yield put(switchChangeAuthorModalOpened());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchChangeAuthor(): Saga<void> {
  yield takeEvery(CHANGE_AUTHOR_SAGA, (makeFetch: Function));
}
