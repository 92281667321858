// @flow
import {
  SET_API_FIELDS,
  SET_API_FIELD,
  GET_API_IP_SAGA,
  GENERATE_API_KEY_SAGA,
  SAVE_API_KEY_SAGA,
} from "../../helpers/constants/apiPage";

// Types
type Fields = {
  ipWhiteList?: string,
  apiKey?: string,
};
type Field = {
  field: string,
  value: string
};

export type SetApiFieldsAction = {
  type: typeof SET_API_FIELDS,
  value: Fields,
};
export type SetApiFieldAction = {
  type: typeof SET_API_FIELD,
  value: Field,
};
export type GetApiIpSaga = {
  type: typeof GET_API_IP_SAGA,
}
export type GenerateApiKeySaga = {
  type: typeof GENERATE_API_KEY_SAGA,
}
export type SaveApiKeySaga = {
  type: typeof SAVE_API_KEY_SAGA,
}

// Actions
export const getApiIpSaga: () => GetApiIpSaga = () => ({
  type: GET_API_IP_SAGA,
})
export const setApiFields = (
  value: Fields
): SetApiFieldsAction => ({
  type: SET_API_FIELDS,
  value,
});
export const setApiField = (
  field: string,
  value: string
): SetApiFieldAction => ({
  type: SET_API_FIELD,
  value: { field, value },
});
export const generateApiKeySaga: () => GenerateApiKeySaga = () => ({
  type: GENERATE_API_KEY_SAGA,
});
export const saveApiKeySaga: () => SaveApiKeySaga = () => ({
  type: SAVE_API_KEY_SAGA,
})
