// @flow
import {
  call,
  debounce, put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { SEARCH_OFFER_FOR_SUBRATE } from "../../helpers/constants/subrates";
import setLoading from "../../actions/loading/actions";
import type { SearchOfferForSubrate } from "../../actions/subrates";
import { getSubratesDropdowns } from "../../services/subratesApi";
import { setOptionsOffers } from "../../actions/subrates";

export function* searchOfferForSubrate(action: SearchOfferForSubrate): Saga<void> {
  const { key, value, field = "name" }: SearchOfferForSubrate = action;
  if (value.length < 3) return;

  yield put(setLoading("searchOfferLoading", true));
  try {
    const result = yield call(getSubratesDropdowns, {
      type: "offer",
      field,
      format: "id",
      filter: value,
    });
    yield put(setOptionsOffers(key, result));
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to search", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading("searchOfferLoading", false));
  }
}

export default function* watchSearchOfferForSubrate(): Saga<void> {
  yield debounce(1000, SEARCH_OFFER_FOR_SUBRATE, (searchOfferForSubrate: Function));
}
