// @flow
import {
  SET_LOADING,
} from "../../helpers/constants/loading";

export type SetLoadingAction<T> = {|
  type: typeof SET_LOADING,
  key: T,
  value: boolean,
|};

export type Actions<T> =
  SetLoadingAction<T>;

export default <T: string>(key: T, value: boolean): SetLoadingAction<T> => ({
  type: SET_LOADING,
  key,
  value,
});
