// @flow
import environment from "environment";
import qs from "qs";
import type {
  Filters,
  Sorting,
} from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";
import type { UtmCampaignType } from "../../containers/UtmCampaignDictionaryList/types/UtmCampaignDictionaryList.types";

// eslint-disable-next-line import/prefer-default-export
export const fetchUtmCampaignList: (
  options: {
    page: number,
    limit: number,
    filters: Filters,
    sorting: Sorting,
  },
) => Promise<{ data: Array<UtmCampaignType>, count: number }> = (
  { sorting, ...options }
) => {
  const [[current, direction] = []] = Object.entries(sorting || {});
  const sortingApi = current ? { current, direction } : {};
  return requestService
    .get(environment.endpoints.get.generatedUtmCampaign, {
      params: { ...options, sorting: sortingApi },
      paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
    })
    .then(({ data }) => data);
};
