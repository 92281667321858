// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  companyId: Joi.number().required(),
  domain: Joi.string().required(),
  vertical: Joi.string().required(),
  niche: Joi.string().required(),
  country: Joi.string().required(),
}).messages({
  "number.base": "This field is required",
  "string.empty": "This field is required",
  "date.min": "Date should not be before minimal date",
  "date.max": "Date should not be after maximal date",
  "date.base": "This field is required",
  "object.base": "This field is required",
}).options({ stripUnknown: true });

export default joiResolver(schema);
