// @flow
/* eslint-disable import/max-dependencies */
import React from "react";
import type { Element } from "react";
import { SimpleTableActionButton, TableActionGroup, TableActions } from "@fas/ui-core";
import {
  Check, CheckCircle, Close, Edit, FileCopy, RemoveCircle, SettingsBackupRestore, CallSplit,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import type { StoreType } from "@fas/ui-framework/lib/services/table/types/table.types";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { useNavigate } from "react-router-dom";
import { CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import externalRedirect from "../../components/App/ExternalRedirect";
import { userHaveSomeMarketingGroup } from "../../pages/CpaOffer/components/Form/Form";
import type { Offer } from "./types/CpaOfferIndex.types";
import { changeCpaOfferStatusSaga, changeValidationStatusSaga, resetGlobalCapSaga } from "../../actions/cpaoffer";
import { isOfferAllowedActivate } from "../../helpers/generators/generators";
import { useChangeManagerModal, type ChangeManagerModalType } from "./useChangeManagerModal";
import Can from "../../components/Can";
import { userService } from "../../services/user";

function selectIsAllowedActivate(state: StoreType): boolean {
  return getTableSelections(state, CPA_OFFER_TABLE)
    .some((offer: Offer): boolean => isOfferAllowedActivate(offer));
}

function selectSelected(state: StoreType): DataItem[] {
  return getTableSelections(state, CPA_OFFER_TABLE);
}

const CpaTableActions = (): Element<*> => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { openChangeAuthorModal }: ChangeManagerModalType = useChangeManagerModal();
  const selected: DataItem[] = useSelector(selectSelected);
  const isAllowedActivate: boolean = useSelector(selectIsAllowedActivate);

  function handleChangeActives(status: boolean) {
    dispatch(changeCpaOfferStatusSaga(status));
  }

  function isAllowedEdit(rows: Array<Offer>): boolean {
    const row: Offer = rows[0];
    return rows.length === 1 && (row.managerId === userService.getId()
      || userHaveSomeMarketingGroup(row.marketingGroups)
      || userService.can(["cpa.offers.editAllOffers"])
    );
  }

  function isAllowedResetCap(rows: Array<Offer>): boolean {
    const row: Offer = rows[0];
    return rows.length === 1 && (row.managerId === userService.getId()
      || userHaveSomeMarketingGroup(row.marketingGroups)
    );
  }

  const handleResetCap = () => {
    dispatch(resetGlobalCapSaga());
  };

  function handleChangeValidationStatus(status: boolean) {
    dispatch(changeValidationStatusSaga(status));
  }

  const moveToCpaOfferCampaigns = () => {
    const ids: string = selected.map((i: DataItem): string[] => i.id).join(",");
    history(`/cpaOffers/campaigns?id=${ids}`);
  };

  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={!isAllowedEdit(selected)}
          onClick={(): null => externalRedirect({ to: `/cpaOffers/${selected[0].id}`, target: "_blank" })}
          Icon={<Edit />}
        />
        <SimpleTableActionButton
          tooltip="Clone"
          disabled={selected.length > 1}
          onClick={(): null => externalRedirect({ to: `/cpaOffers/${selected[0].id}/clone`, target: "_blank" })}
          Icon={<FileCopy />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Reset cap"
          disabled={!isAllowedResetCap(selected)}
          onClick={handleResetCap}
          Icon={<SettingsBackupRestore />}
        />
        <SimpleTableActionButton
          tooltip="Activate"
          disabled={!isAllowedActivate}
          onClick={() => handleChangeActives(true)}
          Icon={<Check />}
        />
        <SimpleTableActionButton
          tooltip="Deactivate"
          disabled={!isAllowedActivate}
          onClick={() => handleChangeActives(false)}
          Icon={<Close />}
        />
      </TableActionGroup>
      <TableActionGroup>
        <Can permissions={["cpa.offers.validateOffer"]}>
          <SimpleTableActionButton
            tooltip="Validate"
            onClick={() => handleChangeValidationStatus(true)}
            Icon={<CheckCircle />}
          />
          <SimpleTableActionButton
            tooltip="Unvalidate"
            onClick={() => handleChangeValidationStatus(false)}
            Icon={<RemoveCircle />}
          />
        </Can>
        <Can permissions={["CpaOffer.bulkUpdateManager", { method: "PUT", path: "/api/v1/dictionary/cpaOffer/bulkUpdate" }]}>
          <SimpleTableActionButton
            tooltip="Change author"
            onClick={openChangeAuthorModal}
            Icon={<PersonOutline />}
          />
        </Can>
        <Can permissions={[{ path: "/api/v1/template/cpaOffersCampaign/filter", method: "POST" }]}>
          <SimpleTableActionButton
            tooltip="Show campaigns"
            onClick={moveToCpaOfferCampaigns}
            Icon={<CallSplit sx={{ transform: "rotate(90deg)" }} />}
            disabled={selected.length > 100}
          />
        </Can>
      </TableActionGroup>
    </TableActions>
  );
};

export default CpaTableActions;
