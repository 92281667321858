// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Check,
  Close,
} from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

type Props = {
  value: boolean,
}

type Classes = {
  check: string,
  close: string,
}

const useStyles = makeStyles(() => ({
  check: {
    color: "#2EB000",
  },
  close: {
    color: "#C80022",
  },
}));

const CheckedIcon: StatelessFunctionalComponent<Props> = ({
  value,
}: Props) => {
  const classes: Classes = useStyles();

  return (
    value ? <Check className={classes.check} /> : <Close className={classes.close} />
  );
};

export default React.memo<Props>(CheckedIcon);
