// @flow
import React from "react";
import { connect } from "react-redux";
import {
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deletePostbackEventSaga,
  getPostbackEventsSaga,
  openEventPostbackModal,
  changeCurrentEventPostback,
} from "../../../../../../../../actions/eventsPostback";

type Props = {
  removeEvent: () => any,
  row: Object,
};

const mapStateToProps = (state) => ({
  manageAffiliate: state.manageAffiliate,
});

const mapDispatchToProps = (dispatch) => ({
  removeEvent: (eventId) => dispatch(deletePostbackEventSaga(eventId)),
  getEvents: () => dispatch(getPostbackEventsSaga()),
  handleOpenEventPostbackModal: (val) => dispatch(openEventPostbackModal(val)),
  setCurrentEventPostback: (val) => dispatch(changeCurrentEventPostback(val)),
});

const Actions = ({
  row,
  removeEvent,
  handleOpenEventPostbackModal,
  setCurrentEventPostback,
}: Props) => {
  const handleRemoveEvent = () => removeEvent(row.eventId);

  return (
    <div>
      <IconButton
        onClick={() => {
          setCurrentEventPostback(row);
          handleOpenEventPostbackModal(true);
        }}
        data-testid="editButton"
        size="large"
      >
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleRemoveEvent} size="large" data-testid="deleteButton">
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);
