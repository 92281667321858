// @flow
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CreateAffiliateHeader from "../CreateAffiliateHeader";
import CreateAffiliateContent from "../CreateAffiliateContent";

type Props = {
  mode: string,
  tab: string,
  id: string,
  history: Object,
};

const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

const Affiliate = ({
  mode,
  tab,
  id,
  history,
}: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.content}
    >
      <CreateAffiliateHeader
        tab={tab}
        mode={mode}
        history={history}
      />
      <CreateAffiliateContent
        id={id}
        tab={tab}
        mode={mode}
        history={history}
      />
    </div>
  );
};

export default Affiliate;
