/* eslint-disable import/max-dependencies */
// @flow
import {
  select,
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA } from "../../helpers/constants/manageAffiliate";
import { getAffiliateSaga, type ChangeAffiliatePaymentVerifySaga } from "../../actions/manageAffiliate";
import { changeAffiliatePaymentVerify } from "../../services/manageAffiliateApi";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";

export function* makeFetch(action: ChangeAffiliatePaymentVerifySaga): Saga<void> {
  const { paymentInfoId, isVerified } = action;

  try {
    yield put(setLoading("updatePaymentVerify", true));

    yield call(changeAffiliatePaymentVerify, paymentInfoId, isVerified);
    yield put(addNotification({ message: "Payment verification information successfully updated!", severity: "success" }));

    const manageAffiliateId = yield select(getManageAffiliateId);
    yield put(getAffiliateSaga(manageAffiliateId));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("updatePaymentVerify", false));
  }
}

export default function* watchChangeAffiliatePaymentVerifySaga(): Saga<void> {
  yield takeEvery(CHANGE_AFFILIATE_PAYMENT_VERIFY_SAGA, (makeFetch: Function));
}
