// @flow
export const FORM_KEY_SMU_BRAND: "FORM_KEY_SMU_BRAND" = "FORM_KEY_SMU_BRAND";
export const FORM_KEY_SMU_DEPARTMENT: "FORM_KEY_SMU_DEPARTMENT" = "FORM_KEY_SMU_DEPARTMENT";

export const GET_SMU_MARKETING_COMPANIES_SAGA: "GET_SMU_MARKETING_COMPANIES_SAGA" = "GET_SMU_MARKETING_COMPANIES_SAGA";
export const GET_SMU_BRAND_LIST_SAGA: "GET_SMU_BRAND_LIST_SAGA" = "GET_SMU_BRAND_LIST_SAGA";
export const CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA: "CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA" = "CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA";

export const SMU_BRAND_LIST_TABLE: "SMU_BRAND_LIST_TABLE" = "SMU_BRAND_LIST_TABLE";
export const SMU_DEPARTMENT_LIST_TABLE: "SMU_DEPARTMENT_LIST_TABLE" = "SMU_DEPARTMENT_LIST_TABLE";
