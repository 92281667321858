// @flow
import React from "react";
import {
  Box,
  Chip,
  Typography,
} from "@mui/material";
import { makeStyles, type Theme } from "@mui/styles";
import { isEqual } from "lodash";
import type { TemplateAttributeWithConfig, AttributeFilter } from "../../../reducers/templates";

type Props = {|
  attribute: TemplateAttributeWithConfig,
  onSetFilters: (name: string, filters: Array<AttributeFilter>) => mixed;
|}
const useStyle = makeStyles((theme: Theme) => ({
  root: {
    fontSize: "0.8rem",
  },
  chip: {
    height: "100%",
  },
  chipLabel: {
    padding: theme.spacing(0.5),
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
}));
const Component = ({ attribute, onSetFilters }: Props) => {
  const classes = useStyle();

  if (!attribute.filters.length) {
    return null;
  }

  return (
    <>
      <Box className={classes.root} mb={1}>
        {attribute.label}
      </Box>
      <Box display="flex" flexWrap="wrap">
        {attribute.filters.map(({ value, label }) => (
          <Box
            maxWidth="100%"
            boxSizing="border-box"
            mb={1}
            mr={1}
            key={String(value)}
          >
            <Chip
              label={<Typography className={classes.chipLabel}>{label}</Typography>}
              className={classes.chip}
              size="small"
              onDelete={() => {
                onSetFilters(
                  attribute.name,
                  attribute.filters.filter((filter) => filter.value !== value)
                );
              }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default React.memo<Props>(Component, isEqual);
