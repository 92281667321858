// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { GET_AUTHOR_LIST_SAGA } from "../../helpers/constants/manageAffiliate";
import type { GetAuthorsListSaga } from "../../actions/manageAffiliate";
import { getAuthorsLists } from "../../services/manageAffiliateApi";
import { changeIsManagersListLoaded, setFetchedManagersList } from "../../actions/manageAffiliate";

export function* makeFetch(action: GetAuthorsListSaga): Saga<void> {
  const { payload }: GetAuthorsListSaga = action;
  try {
    const { data } = yield call(getAuthorsLists, payload);
    yield put(setFetchedManagersList(data.data));
    yield put(changeIsManagersListLoaded());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetAuthorsList(): Saga<void> {
  // todo add event type here
  yield takeEvery(GET_AUTHOR_LIST_SAGA, (makeFetch: Function));
}
