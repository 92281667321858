// @flow
import type { CombinedReducer, Dispatch, Store } from "redux";
/* eslint-disable import/max-dependencies */
import {
  applyMiddleware, combineReducers, compose, createStore,
} from "redux";
import type { SagaMiddleware } from "redux-saga";
import createSagaMiddleware from "redux-saga";
import Immutable from "immutable";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import mainSaga from "./saga";
import loading, { initState as initLoadingState, type State as LoadingState } from "../../reducers/loading";
import dictionaries, {
  initState as initDictionariesState,
  type State as DictionariesState,
} from "../../reducers/dictionaries";
import type { Actions as LoadingActions } from "../../actions/loading";
import type { SetDictionary as DictionaryActions } from "../../actions/dictionary";
import { ADVERTISER_INVOICES_TABLE, AVAILABLE_QUERY_FILTER_KEYS } from "../../helpers/constants/advertiserInvoices";
import { getQueryFilter } from "../../helpers/query/queryFilter";

export type State = $ReadOnly<{|
  tables: TableState,
  loading: LoadingState<string>,
  dictionaries: DictionariesState,
  notifications: NotificationsState,
|}>

function mapSmuDepartmentsFormToState(): State {
  return {
    tables: initTableState({
      [ADVERTISER_INVOICES_TABLE]: {
        pageSize: 20,
        filters: {
          ...getQueryFilter(AVAILABLE_QUERY_FILTER_KEYS),
        },
      },
    }),
    loading: initLoadingState<string>({}),
    dictionaries: initDictionariesState(),
    notifications: initNotificationsState(),
  };
}

export type Actions = LoadingActions<string>
  | DictionaryActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  tables,
  loading,
  dictionaries,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  // eslint-disable-next-line flowtype/require-variable-type
  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapSmuDepartmentsFormToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
