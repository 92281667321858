// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getFormDataSaga } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import {
  Error, FormHeader, Loader, SetupCard,
} from "@fas/ui-core";
import { Box, Container } from "@mui/material";
import {
  InlineMultiSelectForm,
} from "@fas/ui-core/lib/Form";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { useDictionaryList } from "../../hoocks/useDictionary";
import Form from "../Form/Form";
import validate from "./validate";
import { FORM_KEY_SMU_DEPARTMENT } from "../../helpers/constants/smu";

const SmuDepartmentsFormContainer: StatelessFunctionalComponent<{}> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFormDataSaga(FORM_KEY_SMU_DEPARTMENT, id));
    }
  }, [id]);

  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const [departments, departmentsLoading] = useDictionaryList("smu", "department");

  const isError: mixed = useSelector((state) => getFormField(state, FORM_KEY_SMU_DEPARTMENT, "isError"));
  const title: mixed = useSelector((state) => getFormField(state, FORM_KEY_SMU_DEPARTMENT, "title"));
  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_SMU_DEPARTMENT} redirectOnSave="/smuDepartments" validate={validate}>
      <FormHeader
        title={String(title)}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width="100%">
            <Loader loading={loading}>
              {/* $FlowFixMe need add loading prop to type in ui-core */}
              <InlineMultiSelectForm
                name="department"
                label="Departments"
                options={departments}
                loading={departmentsLoading}
              />
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default SmuDepartmentsFormContainer;
