// @flow
/* eslint-disable import/max-dependencies */
import type { StatelessFunctionalComponent, Element } from "react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { type TableProps, useTable } from "@fas/ui-framework/lib/services/table";
import {
  SimpleTableActionButton, Table, TableActionGroup, TableActions,
} from "@fas/ui-core";
import makeStyles from "@mui/styles/makeStyles";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { Edit } from "@mui/icons-material";
import { userService } from "../../services/user";
import { getTopOffersDropdownListSaga, getTopOffersListSaga } from "../../actions/topOffers";
import type {
  Classes,
  TopOffersDropdownListsType,
  TopOffersType,
} from "./types/TopOffersTable.types";
import { TOPOFFERS_TABLE } from "../../helpers/constants/topOffers";
import { compareById } from "../../helpers/generators/generators";
import getColumns from "./getColumns";
import externalRedirect from "../../components/App/ExternalRedirect";
import { useList } from "../../hoocks/useDictionary";

export type Props = $ReadOnly<{|
|}>;

const useStyles: () => Classes = makeStyles((): Classes => ({
  smallTableCol: {
    maxWidth: "100px",
  },
  middleTableCol: {
    minWidth: "150px",
    width: "150px",
  },
  longTableCol: {
    maxWidth: "200px",
  },
  veryLongTableCol: {
    minWidth: "110px",
    maxWidth: "400px",
    wordBreak: "break-word",
  },
  buttonLink: {
    justifyContent: "flex-start",
    fontWeight: "normal",
  },
}));

const TopOffersList: StatelessFunctionalComponent<Props> = ({}: Props) => {
  const tableProps: TableProps = useTable(TOPOFFERS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();
  const allowToCreate: boolean = userService.can([{ path: "/api/v1/topOffers", method: "POST" }]);

  const onCreate: typeof undefined | (() => void) = allowToCreate ? () => {
    externalRedirect({ to: "/topOffers/create" });
  } : undefined;

  const onLoad: () => mixed = () => dispatch(getTopOffersListSaga());
  const rowSelectAvailable: () => boolean = () => allowToCreate;
  const allSelectAvailable: () => boolean = () => allowToCreate;

  useEffect(() => {
    dispatch(getTopOffersDropdownListSaga());
  }, [dispatch]);

  const classes: Classes = useStyles();
  const dropdownLists: TopOffersDropdownListsType = {
    projectTypes: useList("projectTypes"),
    countries: useList("countries"),
    platforms: useList("platforms"),
    conversionTypes: useList("conversionTypes"),
    verticals: useList("verticals"),
    currencies: useList("currencies"),
  };
  const columns: Column<TopOffersType>[] = getColumns({ classes, dropdownLists });

  const Actions: Element<typeof TableActions> = (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={tableProps.selected.length > 1}
          onClick={(): null => externalRedirect({ to: `/topOffers/${tableProps.selected[0].id}` })}
          Icon={<Edit />}
        />
      </TableActionGroup>
    </TableActions>
  );

  return (
    <Table
      {...tableProps}
      columns={columns}
      title="Top Offers"
      onLoad={onLoad}
      onCreate={onCreate}
      rowSelectAvailable={rowSelectAvailable}
      allSelectAvailable={allSelectAvailable}
      compareRows={compareById}
      Actions={Actions}
    />
  );
};

export default TopOffersList;
