// @flow
import React, { useMemo, type Node } from "react";
import { useControlField } from "@fas/ui-core/lib/Form/FormContext";
import { SelectForm } from "@fas/ui-core/lib/Form";
import type { Props as SelectFormProps } from "@fas/ui-core/lib/Form/SelectForm";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import type { Optional } from "../AdvertiserInvoicesForm/hoocks";

type Props = {|
  title: string,
  loading: boolean,
  classes: {[string]: *},
  ...SelectFormProps
|}

export default function SelectFormWithDefaultValue({
  title, name, ...props
}: Props): Node {
  const { value, error, onChange } = useControlField(name);
  const { value: label } = useControlField(title);

  const defaultValue: Optional<Option> = useMemo(
    (): Optional<Option> => (value ? { label, value } : undefined),
    [label, value]
  );
  return (
    <SelectForm
      {...props}
      value={value}
      error={error}
      onChange={onChange}
      name={name}
      defaultValue={defaultValue}
    />
  );
}

SelectFormWithDefaultValue.defaultProps = {
  onChange: () => {},
  error: "",
  defaultValue: undefined,
  classes: {},
  loading: false,
  value: "",
};
