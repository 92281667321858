import { getTableFilters } from "@fas/ui-framework";
import useTenantId from "../../useTenantId";
import { useSelector } from "../../store";
import { OFFERS_TABLE_KEY } from "../../../../helpers/constants/affiliates";
import type { FiltersType } from "../IntSearchOffersBySite/types";

export default function useOffersQueryKey() {
  const tenantId = useTenantId();
  const filters = useSelector((
    state
  ) => getTableFilters<FiltersType>(state, OFFERS_TABLE_KEY));
  return [tenantId, OFFERS_TABLE_KEY, filters];
}
