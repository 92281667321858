// @flow
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Saga } from "redux-saga";
import { userService } from "../../services/user";
import { saveTemplate, updateTemplate } from "../../services/templatesApi";
import { getCurrentTemplate, getReportName } from "../../selectors/templates";
import { SAVE_TEMPLATE_SAGA } from "../../helpers/constants/templates";
import { setCurrentTemplateData, type SaveTemplateSaga } from "../../actions/templates";
import type { Template, TemplateAttribute, TemplateAttributeRaw } from "../../reducers/templates";

export function* save({ id }: SaveTemplateSaga): Saga<void> {
  yield put(setLoading("saveTemplate", true));
  const { attributes, name, isSystem }: Template = yield select(getCurrentTemplate);
  const reportName: string = yield select(getReportName);

  const attributesRaw: Array<TemplateAttributeRaw> = attributes.map(
    // eslint-disable-next-line no-unused-vars
    ({ name: templateName, ...restData }: TemplateAttribute): TemplateAttributeRaw => restData
  );
  try {
    if (!id) {
      const { templateId, createdBy }: {
        templateId: number, createdBy: number
      } = yield call(saveTemplate, reportName, {
        name: name.trim(),
        attributes: attributesRaw,
        sourceType: isSystem && userService.can(["system.template.crud"]) ? "system" : "none",
      });
      yield put(setCurrentTemplateData({ id: templateId, createdBy }));
      yield put(addNotification({ message: `Saved ${name} successfully`, severity: "success" }));
    }
    else {
      yield call(updateTemplate, reportName, id, {
        name: name.trim(),
        sourceType: isSystem ? "system" : "none",
        attributes: attributesRaw,
      });
      yield put(addNotification({ message: `Updated ${name} successfully`, severity: "success" }));
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to save template", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("saveTemplate", false));
  }
}

export function* watchSaveTemplate(): Saga<void> {
  yield takeEvery(SAVE_TEMPLATE_SAGA, save);
}
