// @flow
import type { Node } from "react";
import { AutocompleteWithFetch } from "@fas/ui-core";
import React from "react";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import getOptionLabel from "@fas/ui-core/lib/SearchComponents/helpers/getOptionLabel";
import { Box, FormControl } from "@mui/material";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

export type Props = {|
  name: string,
  label: string,
  error: string,
  title: string,
  value: *,
  onChange: (*) => *,
  onChangeOption: (*) => *,
  onChangeTitle: (*) => *,
  fetchMethod: (string) => Promise<Array<DropDownObjItemType>>,
  disabled?: boolean,
|}

function AutocompleteWithFetchForm({
  value, onChange, error, title, onChangeTitle, label, fetchMethod, name, onChangeOption, ...props
}: Props): Node {
  return (
    <FormControl fullWidth>
      <Box pt={1} pb={0.5}>
        <AutocompleteWithFetch
          {...props}
          data-testid={name}
          inputProps={{
            label,
            error: Boolean(error),
            helperText: error,
          }}
          onChange={(v) => {
            const option: DropDownObjItemType = ((v: any): DropDownObjItemType) || { label: "", value: "" };
            onChangeOption(v);
            onChange(option.value);
            onChangeTitle(getOptionLabel(option));
          }}
          selectedValue={value ? { label: title, value } : { label: "", value: "" }}
          // $FlowFixMe MTU-94094
          fetchMethod={fetchMethod}
        />
      </Box>
    </FormControl>
  );
}

AutocompleteWithFetchForm.defaultProps = {
  onChange: () => {},
  onChangeTitle: () => {},
  onChangeOption: () => {},
  error: "",
  value: "",
  title: "",
};

export default withFormField(AutocompleteWithFetchForm);
