// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Edit,
  Delete,
} from "@mui/icons-material";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
} from "@fas/ui-core";
import { userService } from "../../services/user";
import type { ActionsProps } from "./CrmTemplatesList.types";
import ConfirmTableActionButton from "../../components/SubrateInfoConfirmActionButton";

const Actions: StatelessFunctionalComponent<ActionsProps> = ({
  onEdit,
  onDelete,
  items,
}) => (
  <TableActions>
    <TableActionGroup>
      <SimpleTableActionButton
        tooltip="Edit"
        disabled={!userService.can([{ path: "/api/v1/crm/mailTemplate", method: "GET" }]) || items.length > 1}
        onClick={onEdit}
        Icon={<Edit />}
        data-testid="editButton"
      />
      <ConfirmTableActionButton
        items={items}
        tooltip="Delete"
        disabled={!userService.can([{ path: "/api/v1/crm/mailTemplate", method: "DELETE" }])}
        onClick={onDelete}
        Icon={<Delete />}
      />
    </TableActionGroup>
  </TableActions>
);

export default Actions;
