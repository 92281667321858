// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  debounce,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSelections,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  changeTableItemsTotalAmount,
  setTableData,
  setTableSelection,
} from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import type { Offer } from "../../containers/CpaOfferIndex/types/CpaOfferIndex.types";
import { fetchAdvertiserInvoicesList } from "../../services/advertiserInvoicesApi";
import setLoading from "../../actions/loading/actions";
import {
  ADVERTISER_INVOICES_TABLE,
  GET_ADVERTISER_INVOICES_LIST_SAGA,
} from "../../helpers/constants/advertiserInvoices";
import { type AdvertiserInvoicesType } from "../../containers/AdvertiserInvoices/types/AdvertiserInvoices.types";

export function* makeFetch(): Saga<void> {
  const tableKey: typeof ADVERTISER_INVOICES_TABLE = ADVERTISER_INVOICES_TABLE;
  try {
    yield put(setLoading(tableKey, true));

    const page: number = yield select(getTablePage, tableKey);
    const limit: number = yield select(getTablePageSize, tableKey);
    const sorting: Sorting = yield select(getTableSorting, tableKey);
    const filters: Filters = yield select(getTableFilters, tableKey);
    const selections: Offer[] = yield select(getTableSelections, tableKey);

    if (selections.length) {
      yield put(setTableSelection(tableKey, []));
    }

    const headers: $Keys<AdvertiserInvoicesType>[] = [
      "id",
      "cpaAdvertiser",
      "paidAt",
      "payout",
      "currency",
      "paymentMethod",
      "periodFrom",
      "periodTo",
      "status",
      "createdAt",
      "updatedAt",
      "createdByUser",
      "updatedByUser",
    ];

    const { data, count = 0 }: {data: *[], count: number } = yield call(fetchAdvertiserInvoicesList, {
      page,
      limit,
      filters,
      sorting,
      headers,
    });

    yield put(setTableData(tableKey, data));
    yield put(changeTableItemsTotalAmount(tableKey, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(tableKey, false));
  }
}

export default function* watchGetAdvertiserInvoicesListListSaga(): Saga<void> {
  yield debounce(1000, GET_ADVERTISER_INVOICES_LIST_SAGA, (makeFetch: Function));
}
