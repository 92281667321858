/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  select,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import {
  getAffiliateId,
  getAffiliateType,
  getManageAffiliatePaymentInfo,
  getManageAffiliatePersonalInfo,
} from "../../selectors/manageAffiliate";
import { getErrors, mapPaymentMethodsSchema, sanitizePaymentInfo } from "../../actions/manageAffiliate/utils";
import { updatePaymentInfo } from "../../services/manageAffiliateApi";
import { getAffiliateSaga, clearErrors, setErrors } from "../../actions/manageAffiliate";
import { setTabState } from "../../services/middlewares/tabSwitchConfirm";
import { UPDATE_PAYMENT_INFO_SAGA } from "../../helpers/constants/manageAffiliate";

export function* makeFetch(): Saga<void> {
  const paymentInfo = yield select(getManageAffiliatePaymentInfo);
  const affiliateId = yield select(getAffiliateId);
  const affType = yield select(getAffiliateType);
  const { parentAffIdPayment } = yield select(getManageAffiliatePersonalInfo);

  const paymentFields = mapPaymentMethodsSchema(paymentInfo.paymentMethodId.id, affType);
  const sanitized = sanitizePaymentInfo(
    paymentInfo,
    paymentFields,
    affType
  );

  try {
    yield put(setLoading("getPersonalInfo", true));

    yield call(updatePaymentInfo, affiliateId, { ...sanitized, parentAffIdPayment });
    yield put(getAffiliateSaga(affiliateId));

    yield put(clearErrors()); // need remove this after refactor
    yield put(addNotification({ message: "Payment method successfully updated!", severity: "success" }));
    setTabState(false); // need refactor this
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // need remove this after refactor
    if (error.response.data.errors) {
      const errors = getErrors(error.response.data.errors);
      yield put(setErrors(errors));
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getPersonalInfo", false));
  }
}

export default function* watchUpdatePaymentInfo(): Saga<void> {
  // todo add event type here
  yield takeEvery(UPDATE_PAYMENT_INFO_SAGA, (makeFetch: Function));
}
