// @flow
import type { OutputSelector } from "reselect";
import { createSelector } from "reselect";
import type { StoreWithSubrates } from "./types";
import type { TemplateObjType } from "../../reducers/subrates";

export const getSubratesListTemplate: OutputSelector<StoreWithSubrates, *, TemplateObjType> = createSelector(
  (state) => state.subrates.get("subratesIndex").get("template"),
  // $FlowFixMe
  (reducerState) => reducerState.toJS()
);

export const getSubratesInfoListTemplate: OutputSelector<StoreWithSubrates, *, TemplateObjType> = createSelector(
  (state) => state.subrates.get("subratesInfo").get("template"),
  // $FlowFixMe
  (reducerState) => reducerState.toJS()
);

export const getSubratesTableGroup: OutputSelector<StoreWithSubrates, *, Array<string>> = createSelector(
  (state) => state.subrates.get("subratesIndex").get("group"),
  // $FlowFixMe
  (reducerState) => reducerState.toJS()
);

export const getSubratesInfoOfferOptions:
  OutputSelector<StoreWithSubrates, *, Array<{ label: string, value: string }>> = createSelector(
    (state) => state.subrates.get("subratesInfo").get("offerOptions"),
    (value) => value
  );
