import { userService } from "../../services/user";
import type { Operations } from "../../services/api";
import type { OperationIds } from "./types";

function filterByOperation<T extends { operationIds: OperationIds }>({
  operationIds = [],
}: T): boolean {
  function evaluateOperations(ops: any): boolean {
    if (!Array.isArray(ops)) {
      return userService.can([ops]);
    }

    const andParts = ops.filter((op) => !Array.isArray(op));
    const orParts = ops.filter((op) => Array.isArray(op));

    const andResult = andParts.length === 0 || userService.can(andParts as Operations[]);
    const orResult = orParts.length === 0 || orParts.some(evaluateOperations);

    return andResult && orResult;
  }

  return evaluateOperations(operationIds);
}

export default filterByOperation;

export function can(operationIds: OperationIds): boolean {
  return filterByOperation({ operationIds });
}
