import makeStyles from "@mui/styles/makeStyles";

export default makeStyles((theme) => ({
  summaryDropdown: {
    width: "30%",
    marginBottom: theme.spacing(2),
    backgroundColor: "rgb(255, 255, 255)",
  },
  inlineSpace: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
  },
  rulesDropdown: {
    width: "8%",
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  templatesList: {
    position: "relative",
    "& .MuiPaper-root": {
      left: "0",
    },
  },
  buttonContent: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: 300,
    flexShrink: 0,
  },
  applyButton: {
    marginLeft: theme.spacing(20),
  },
  hr: {
    width: "90%",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  template: {
    margin: 4,
  },
  newTemplate: {
    margin: 4,
    color: theme.palette.primary.main,
  },
}));
