// @flow
import {
  put,
  select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { CHANGE_CURRENT_TEMPLATE_ATTRIBURTE } from "../../helpers/constants/templates";
import { getCurrentTemplate, getConfig } from "../../selectors/templates";
import {
  setCurrentTemplateAttribute,
  addCurrentTemplateAttribute,
  deleteCurrentTemplateAttribute,
  type ChangeCurrentTemplateAttribute,
} from "../../actions/templates";
import type { TemplateAttribute, ConfigAttribute, Template } from "../../reducers/templates";

export function* changeAttribute({ payload: { name, filters } }: ChangeCurrentTemplateAttribute): Saga<void> {
  const { attributes }: Template = yield select(getCurrentTemplate);
  const config: { attributes: Array<ConfigAttribute> } = yield select(getConfig);
  const index: number = attributes.findIndex((attribute: TemplateAttribute): boolean => attribute.name === name);
  if (filters) {
    if (index !== -1) {
      return yield put(setCurrentTemplateAttribute({ index, filters }));
    }
    const { id }: { id: number } = config.attributes
      .find((configAttribute: ConfigAttribute): boolean => configAttribute.name === name) || { id: 0 };
    return yield put(addCurrentTemplateAttribute({ id, name, filters }));
  }
  return yield put(deleteCurrentTemplateAttribute(index));
}

export function* watchChangeTemplateAttribute(): Saga<void> {
  yield takeEvery(CHANGE_CURRENT_TEMPLATE_ATTRIBURTE, changeAttribute);
}
