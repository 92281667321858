// @flow
import React from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import CrmTemplatesForm from "../../containers/CrmTemplatesForm";

const store = configureStore();

function CrmTemplates() {
  return (
    <DocumentTitle title="CRM - Templates">
      <Provider store={store}>
        <NotificationsContainer />
        <CrmTemplatesForm />
      </Provider>
    </DocumentTitle>
  );
}

export default CrmTemplates;
