// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery, call, put, select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getAffiliateId } from "../../selectors/payments";
import { GET_AFFILIATE_INFO_SAGA, SIMILAR_AFFILIATE_LOADING } from "../../helpers/constants/payments";
import {
  fetchSimilarAffiliates,
} from "../../services/payments";
import {
  setSimilarAffiliates,
  setEmptyPaymentInfoState,
} from "../../actions/paymentInfo";
import type { LoadingTypes } from "../../selectors/loading/types";
import type { SimilarAffiliateType } from "../../components/SimilarAffiliatesCard/types/SimilarAffiliatesCard.types";

export function* makeFetch(): Saga<void> {
  yield put(setEmptyPaymentInfoState(["similarAffiliates"]));
  try {
    yield put(setLoading<LoadingTypes>(SIMILAR_AFFILIATE_LOADING, true));

    const affId: string = yield select(getAffiliateId);
    const similarAffiliateResponse = yield call(fetchSimilarAffiliates, affId);

    const {
      data: similarAffiliates,
    }: { data: Array<SimilarAffiliateType> } = similarAffiliateResponse;

    yield put(setSimilarAffiliates(similarAffiliates));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading<LoadingTypes>(SIMILAR_AFFILIATE_LOADING, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_AFFILIATE_INFO_SAGA, (makeFetch: Function));
}
