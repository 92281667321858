/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  IconButton,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Loader,
} from "@fas/ui-core";
import { getErrors } from "@fas/ui-framework/lib/redux/selectors/errors";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  saveAffiliateLinkSaga,
  closeAffiliateLinkModal,
  setAffiliateLinkFormData,
} from "../../../../../../actions/affiliateLinks";
import {
  getIsAffiliateLinkModalOpen,
  getIsAffiliateLinkSaveLoading,
  getAffiliateLinkFormData,
} from "../../../../../../selectors/affiliateLinks";

const mapStateToProps = (state) => ({
  errors: getErrors(state).toJS(),
  formData: getAffiliateLinkFormData(state),
  isAffiliateLinkModalOpen: getIsAffiliateLinkModalOpen(state),
  isAffiliateLinkSaveLoading: getIsAffiliateLinkSaveLoading(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onSaveAffiliateLink: saveAffiliateLinkSaga,
  onSetAffiliateLinkFormData: setAffiliateLinkFormData,
  onSetErrors: setErrors,
  onCloseAffiliateLinkModal: closeAffiliateLinkModal,
}, dispatch);

type Props = {
  errors: *,
  isAffiliateLinkModalOpen: boolean,
  isAffiliateLinkSaveLoading: boolean,
  onSaveAffiliateLink: typeof saveAffiliateLinkSaga,
  onSetErrors: typeof setErrors,
  onCloseAffiliateLinkModal: typeof closeAffiliateLinkModal,
};

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  dialogContent: {
    background: "white",
  },
}));

const FieldsForm = ({
  errors,
  formData,
  isAffiliateLinkModalOpen,
  isAffiliateLinkSaveLoading,
  onSaveAffiliateLink,
  onSetErrors,
  onCloseAffiliateLinkModal,
  onSetAffiliateLinkFormData,
}: Props) => {
  const classes = useStyles();

  const handleOnClose = () => {
    onSetErrors({});
    onCloseAffiliateLinkModal();
  };

  const handleChangeField = (e) => onSetAffiliateLinkFormData({
    [e.target.name]: e.target.value,
  });

  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start" style={{cursor: "default"}}>
        <Tooltip title="Link name depends on smartlink's (tdsId) external name">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };
  const zeroSpacing = { marginLeft: 0, marginRight: 0 };

  return (
    <Dialog
      open={isAffiliateLinkModalOpen}
      maxWidth={false}
    >
      <Box
        width={1000}
      >
        <Loader loading={isAffiliateLinkSaveLoading}>
          <Box px={3} py={2}>
            <Typography variant="h6">
              {formData.id ? `Edit link #${formData.id}` : "Add"}
            </Typography>
            <IconButton className={classes.closeButton} onClick={handleOnClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent dividers className={classes.dialogContent}>
            <TextField
              fullWidth
              className={classes.inputField}
              error={!!errors.link}
              helperText={errors.link && errors.link.message}
              inputProps={{
                style: { background: classes.input },
              }}
              FormHelperTextProps={{
                style: zeroSpacing,
              }}
              variant="outlined"
              label="Link"
              name="link"
              data-testid="link"
              value={formData.link}
              onChange={handleChangeField}
              placeholder="Link"
              InputProps={inputPropsTextField}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onSaveAffiliateLink} color="primary" data-testid="saveModalButton">
              Save
            </Button>
            <Button autoFocus onClick={handleOnClose} color="primary" data-testid="backModalButton">
              Close
            </Button>
          </DialogActions>
        </Loader>
      </Box>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldsForm);
