// @flow
import React, { useState, useEffect } from "react";
import {
  TextField, InputAdornment, Tooltip, FormHelperText, FormControl, Box, Collapse, Alert,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { getFormErrors } from "./checkForm";
import type { Errors } from "./checkForm";
import type { CpaOfferLists } from "../../../../../reducers/cpaoffer/types";

type Props = {
  row: any,
  lists: CpaOfferLists,
  onChange: any,
  offerType: string,
  errors: Errors,
  isClone: boolean,
};

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.background.mainBg,
  },
}));

const PaymentSettings = (props: Props) => {
  const classes = useStyles();
  const {
    row,
    lists,
    offerType,
    errors,
    isClone,
  } = props;
  const isEdit = row.id && !isClone;
  const {
    currency,
    paymentModels,
  } = lists;
  const formErrors = getFormErrors(errors);
  const [ncrTypes, setNcrTypes] = useState([
    row.currency.label,
    "%"
  ]);
  const [nextPartnerCommissionMsgOpen, setNextPartnerCommissionMsgOpen] = useState(null);
  const [defaultCommissionMsgOpen, setDefaultCommissionMsgOpen] = useState(null);

  useEffect(() => {
    if(row.offerType.id === "redirect"
      && row.isExt.isExt === 1
      && Number(row.nextPartnerCommission) >= 1
      && row.conversionType === "click"
      && nextPartnerCommissionMsgOpen === null) {
      setNextPartnerCommissionMsgOpen(true);
    }
  }, [row.offerType, row.conversionType, row.isExt, row.nextPartnerCommission]);

  useEffect(() => {

    if(row.offerType.id === "redirect"
      && row.isExt.isExt === 1
      && Number(row.payoutAmount) >= 1
      && row.conversionType === "click"
      && defaultCommissionMsgOpen === null) {
      setDefaultCommissionMsgOpen(true);
    }
  }, [row.offerType, row.conversionType, row.isExt, row.payoutAmount]);

  const changeInput = (fieldName, value) => {
    let timeout = null;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => props.onChange(fieldName, value), 200);
  };

  const changeCurrency = (value) => {
    props.onChange("currency", value);
    setNcrTypes([value.label, "%"]);
  };

  const tooltipInput = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title="Some text">
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
      </>
    ),
  };

  const tooltipSelect = (params) => ({
    ...params.InputProps,
    startAdornment: (
      <>
        <InputAdornment
          position="start"
        >
          <Tooltip title="Some text">
            <HelpOutlineIcon />
          </Tooltip>
        </InputAdornment>
      </>
    ),
  });

  return (
    <Box mb={3} width={1}>
      {
        offerType === "Ext" ? (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!formErrors.paymentModel}
            variant="standard">
            <Autocomplete
              fullWidth
              disableClearable
              options={paymentModels}
              getOptionLabel={(option) => option}
              onChange={(e, value) => props.onChange("payment_model", value)}
              value={row.payment_model}
              data-testid="payment_model"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment model"
                  variant="outlined"
                  margin="normal"
                  InputProps={tooltipSelect(params)}
                  className={classes.input}
                  error={!!formErrors.paymentModel}
                />
              )}
            />
            <FormHelperText>{formErrors.paymentModel}</FormHelperText>
          </FormControl>
        ) : null
      }
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.currency}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={currency}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          onChange={(e, value) => changeCurrency(value)}
          value={row.currency}
          data-testid="currency"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Currency"
              variant="outlined"
              margin="normal"
              InputProps={tooltipSelect(params)}
              className={classes.input}
              error={!!formErrors.currency}
            />
          )}
        />
        <FormHelperText>{formErrors.currency}</FormHelperText>
      </FormControl>
      {
        row.conversionType === "revshare" ? (
          <>
            <TextField
              fullWidth
              id="filled-basic"
              label="Default commission for firsts, %"
              variant="outlined"
              margin="normal"
              onChange={(e) => changeInput("payoutAmountFirst", e.target.value)}
              InputProps={tooltipInput}
              defaultValue={row.payoutAmountFirst}
              error={!!formErrors.payoutAmountFirst}
              helperText={formErrors.payoutAmountFirst}
              data-testid="payoutAmountFirst"
            />
            <TextField
              fullWidth
              id="filled-basic"
              label="Default commission for repeats, %"
              variant="outlined"
              margin="normal"
              onChange={(e) => changeInput("payoutAmountRepeats", e.target.value)}
              InputProps={tooltipInput}
              defaultValue={row.payoutAmountRepeats}
              error={!!formErrors.payoutAmountRepeats}
              helperText={formErrors.payoutAmountRepeats}
              data-testid="payoutAmountRepeats"
            />
          </>
        ) : (
        <>
          <TextField
            fullWidth
            id="filled-basic"
            label="Default commission"
            placeholder="0.00000"
            variant="outlined"
            margin="normal"
            onChange={(e) => changeInput("payoutAmount", e.target.value)}
            InputProps={{
              ...tooltipInput,
              inputProps: { min: 0, type: "number", step: "0.0001" },
            }}
            defaultValue={row.payoutAmount}
            error={!!formErrors.payoutAmount}
            helperText={formErrors.payoutAmount}
            data-testid="payoutAmount"
          />
          <Collapse in={defaultCommissionMsgOpen}>
            <Alert severity="warning" onClose={() => setDefaultCommissionMsgOpen(false)}>
              Default Commission shouldn't exceed or be equal to 1
            </Alert>
          </Collapse>
        </>
        )
      }
      {["click", "1000clicks", "webPushSubscription"].includes(row.conversionType) &&
      ![
        "rtb",
        "rtb_email",
        "rtb_bo",
        "rtb_pop",
        "rtb_push",
        "rtb_so",
        "rtb_ipp"
      ].includes(row.offerType.id) && (
        <>
          <TextField
            fullWidth
            id="nextPartnerCommission"
            label={isEdit ? "Next day Partner commission" : "Partner commission"}
            placeholder="0.0000"
            variant="outlined"
            margin="normal"
            onChange={(e) => changeInput("nextPartnerCommission", e.target.value)}
            data-testid="nextPartnerCommission"
            InputProps={{
              classes: { adornedStart: classes.input },
              startAdornment: (
                <>
                  <InputAdornment
                    position="start"
                  >
                    <Tooltip title={`Сommission is shown in Advery Advertizer panel. Changes will be applied ${(isEdit ? "at 00:00 UTC the next day" : "now")}.`}>
                      <HelpOutlineIcon />
                    </Tooltip>
                  </InputAdornment>
                </>
              ),
              inputProps: { min: 0, type: "number", step: "0.0001" },
              endAdornment: isEdit && (
                <Tooltip title="Current partner commission">
                  <Box>{row.partnerCommission}</Box>
                </Tooltip>
              ),
            }}
            defaultValue={row.nextPartnerCommission}
            error={!!formErrors.nextPartnerCommission}
            helperText={formErrors.nextPartnerCommission}
          />
          <Collapse in={nextPartnerCommissionMsgOpen}>
            <Alert severity="warning" onClose={() => setNextPartnerCommissionMsgOpen(false)}>
              Partner Commission shouldn't exceed or be equal to 1
            </Alert>
          </Collapse>
        </>
      )}
      <TextField
        fullWidth
        id="filled-basic"
        label="Default shave, %"
        placeholder="0"
        variant="outlined"
        margin="normal"
        onChange={(e) => changeInput("shaveAmount", e.target.value)}
        InputProps={tooltipInput}
        defaultValue={row.shaveAmount}
        error={!!formErrors.shaveAmount}
        helperText={formErrors.shaveAmount}
        data-testid="shaveAmount"
      />
      <FormControl
        fullWidth
        className={classes.formControl}
        error={!!formErrors.ncrUnit}
        variant="standard">
        <Autocomplete
          fullWidth
          disableClearable
          options={ncrTypes}
          getOptionLabel={(option) => option}
          onChange={(e, value) => props.onChange("ncrUnit", value)}
          value={row.ncrUnit === "%" ? row.ncrUnit : row.currency.label}
          data-testid="ncrUnit"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ncr type"
              variant="outlined"
              margin="normal"
              InputProps={tooltipSelect(params)}
              className={classes.input}
              error={!!formErrors.ncrUnit}
            />
          )}
        />
        <FormHelperText>{formErrors.ncrUnit}</FormHelperText>
      </FormControl>
      <TextField
        fullWidth
        id="filled-basic"
        label="Default ncr"
        placeholder="0.00"
        variant="outlined"
        margin="normal"
        onChange={(e) => changeInput("ncrAmount", e.target.value)}
        InputProps={tooltipInput}
        defaultValue={row.ncrAmount}
        error={!!formErrors.ncrAmount}
        helperText={formErrors.ncrAmount}
        data-testid="ncrAmount"
      />
    </Box>
  );
};

export default PaymentSettings;
