// @flow
import {
  takeEvery,
  select,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { CHANGE_VALIDATION_STATUS_SAGA, CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import { changeCpaOfferValidationStatus } from "../../services/cpaOffersApi";
import type { ChangeValidationStatusSaga } from "../../actions/cpaoffer";
import { getTemplateFilterDataSaga } from "../../actions/templates";

export function* makeFetch(action: ChangeValidationStatusSaga): Saga<void> {
  const { status }: ChangeValidationStatusSaga = action;
  try {
    const selectedRows: Array<DataItem> = yield select(getTableSelections, CPA_OFFER_TABLE);
    yield call(changeCpaOfferValidationStatus, status, selectedRows.map((el: DataItem): string => el.id));
    yield put(addNotification({ message: "Status changed", severity: "success" }));
    yield put(getTemplateFilterDataSaga());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchChangeValidationStatus(): Saga<void> {
  yield takeEvery(CHANGE_VALIDATION_STATUS_SAGA, (makeFetch: Function));
}
