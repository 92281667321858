import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { userService } from "../../services/user";
import useStorageTenantId from "../components/useStorageTenantId";

function useUserMe() {
  const queryClient = useQueryClient();
  const [storageCurrentTenant] = useStorageTenantId();
  return useQuery(["getUserMe"], () => api.getUserMe("unknown"), {
    enabled: !queryClient.getQueryData(["getUserMe"]), // Prevents multiple requests
    onSuccess: (data) => {
      const [tenantId = ""] = data.allowedTenantIds;
      const currentTenant = (storageCurrentTenant && data.allowedTenantIds.includes(storageCurrentTenant)
        ? storageCurrentTenant
        : tenantId);
      const list = data?.allowedTenantOperationIds?.[currentTenant];
      userService.setDynamicPermissions(list || []);
    },
  });
}

export default useUserMe;
