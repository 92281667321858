import type { OperationIds } from "./modules/Affiliates/types";

type OldPermissions = "accessManageAffiliate"
  | "/cpa/payments"
  | "/cpa/invoices"
  | "/cpa/payoutAddress/index"
  | "/accountantsReport"

export default function toOperation(v: ({ path: string, method: string } | OldPermissions)[]) {
  return v as unknown as OperationIds;// cast to OperationIds old version permissions
}
