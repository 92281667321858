// @flow
import React, { useMemo } from "react";
import configureStore from "./store";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer";
import CreateAffiliate from "../../containers/CreateAffiliate";
import DocumentTitle from "react-document-title";

const CreateAffiliatePage = ({ match, history, mode }: { match: Object, history: Object, mode: string }) => {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="CPA - Affiliate">
      <Provider store={store}>
        <NotificationsContainer />
        <CreateAffiliate match={match} history={history} mode={mode} />
      </Provider>
    </DocumentTitle>
  );
}

export default CreateAffiliatePage;
