// @flow
import React, { useState } from "react";
import {
  ConfirmDialog,
} from "@fas/ui-core";
import { Tab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NavigationTabs from "../../../../components/NavigationTabs";
import ContentTabs from "../../../../components/ContentTabs";
import {
  tabs,
} from "../../../ManageAffiliate/utils";
import {
  isTabStateChanged,
  setTabState,
} from "../../../../services/middlewares/tabSwitchConfirm";
import { userService } from "../../../../services/user";

type Props = {
  id: string,
  mode: string,
  tab: string,
  history: Object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  tabRoot: {
    "&$tabSelected": {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabSelected: {},
}));

const CreateAffiliateContent = ({
  id,
  mode,
  tab,
  history,
}: Props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [nextTab, setNextTab] = useState("");

  const handleOpenConfirm = () => setOpenDialog(false);
  const handleGoNextTab = () => {
    setOpenDialog(false);
    setTabState(false);
    history(`/manageAffiliate/edit/${id}/${nextTab}`);
  };
  const handleOnClickTab = (e, value) => {
    e.preventDefault();
    if (tab !== value) {
      setNextTab(value);
      if (isTabStateChanged) {
        setOpenDialog(true);
      }
      else {
        history(`/manageAffiliate/edit/${id}/${value}`);
      }
    }
  };

  const defaultTabStyling = {
    root: classes.tabRoot,
    selected: classes.tabSelected,
  };

  return (
    <div className={classes.root}>
      <div style={{ background: "white" }}>
        <ConfirmDialog
          open={openDialog && isTabStateChanged}
          title="Confirm"
          message="You have unsaved changes. Do you really want to move to the next tab?"
          onMainBtnClick={handleGoNextTab}
          onSecondaryBtnClick={handleOpenConfirm}
        />
      </div>
      <NavigationTabs
        tab={tab}
        handleChangeTab={handleOnClickTab}
      >
        {tabs.filter((tabItem) => userService.can(tabItem.permission)).map((tabItem) => (
          <Tab
            classes={defaultTabStyling}
            value={tabItem.name}
            label={tabItem.label}
            disabled={tabItem.isDisabled(mode)}
          />
        ))}
      </NavigationTabs>
      <ContentTabs
        data={tabs}
        tab={tab}
      />
    </div>
  );
};

export default CreateAffiliateContent;
