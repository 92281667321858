// @flow
/* eslint-disable import/max-dependencies */
import qs from "qs";
import environment from "environment";
import { serialize } from "object-to-formdata";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";
import { userService } from "../user";
import type {
  PayloadCompanyFormType,
  IncomePayloadItemType,
} from "../../containers/SmuDepartmentsList/types/SmuDepartmentsList.types";
import type { PayloadItemType as PayloadBrandsItemType } from "../../containers/SmuBrandList/types/SmuBrandList.types";
import type { PayloadItemType as PayloadBrandType, IncomePayloadItemType as IncomeBrandItemType } from "../../containers/SmuBrandForm/types/SmuBrandForm.types";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

export const getSmuMarketingCompanies: () => PromiseResponse<
  mixed,
  { data: IncomePayloadItemType[] }
> = (params) => requestService
  .get(environment.endpoints.get.getSmuMarketingCompaniesList, {
    params,
  });

export const getSmuMarketingCompany: (string) => Promise<PayloadCompanyFormType> = (id) => requestService
  .get(environment.endpoints.get.getSmuMarketingCompanyById.replace("{id}", id))
  .then(({
    data: {
      data: {
        company,
        marketingCompanyId,
        department,
      },
    },
  }: { data: { data: IncomePayloadItemType } }): PayloadCompanyFormType => ({
    id,
    marketingCompanyId,
    department,
    title: company.name,
  }));

type SavePayloadCompanyType = {|
  department: string[],
  marketingCompanyId: string,
|}

export const saveSmuMarketingCompany: (PayloadCompanyFormType) => PromiseResponse<SavePayloadCompanyType> = (
  { id, department, marketingCompanyId }
) => requestService.put(environment.endpoints.put.updateSmuMarketingCompany.replace("{id}", id), { department: department.split(","), marketingCompanyId });

export const getSmuBrandList: (
  options: {
    page: number,
    limit: number,
    filters: Filters,
    sorting: Sorting,
  },
) => PromiseResponse<mixed, { data: PayloadBrandsItemType[], count: number }> = (options) => {
  const [[current, direction] = []] = Object.entries(options.sorting);

  return requestService
    .get<{ data: PayloadBrandsItemType[], count: number }>(environment.endpoints.get.getSmuBrandsList, {
      params: {
        ...options,
      },
      paramsSerializer: (params: *): string => qs.stringify({
        ...params,
        // $FlowFixMe
        filters: Object.keys(params.filters).length ? params.filters : JSON.stringify({}),
        sorting: current ? {
          current,
          direction,
        } : JSON.stringify({}),
        // $FlowFixMe
        headers: params.headers.length ? params.headers : JSON.stringify([]),
      }, { arrayFormat: "brackets", encode: false }),
    });
};

export const getSmuBrand: (string) => Promise<PayloadBrandType> = (id) => requestService
  .get(environment.endpoints.get.getSmuBrandById.replace("{id}", id))
  .then(({
    data: {
      data: {
        countries,
        image,
        company,
        ...data
      },
    },
  }: { data: { data: IncomeBrandItemType }}): PayloadBrandType => ({
    ...data,
    country: countries,
    banner: image,
    companyId: company.id,
    companyName: company.name,
  }));

export async function saveSmuBrand({
  id,
  banner,
  companyId,
  domain,
  vertical,
  niche,
  country,
  url,
  isActive,
}: PayloadBrandType) {
  let brandId: string = id;
  const brand = {
    companyId,
    domain,
    vertical,
    niche,
    countries: country,
    url,
    isActive,
  };

  if (id) {
    await requestService.put(environment.endpoints.put.updateSmuBrand.replace("{id}", id), brand);
  }
  else {
    brandId = await requestService.post(environment.endpoints.post.createSmuBrand, brand)
      .then(({ data }) => data.id);
  }

  if (banner instanceof File) {
    await saveSmuBrandImage(brandId, banner);
  }
}

export const saveSmuBrandImage: (
  id: string,
  image: File
) => PromiseResponse<mixed> = (id, image) => requestService
  .post(environment.endpoints.put.updateSmuBrandImage.replace("{id}", id), serialize({ image }), {
    headers: {
      "Accept": "multipart/form-data",
      "Content-Type": "multipart/form-data",
      "X-Api-Key": userService.getApiKey(),
    },
    withCredentials: false,
  });

export const getSmuDropdownList: ({
  type: string,
  filter?: string
}) => Promise<DropDownObjItemType[]> = ({ type }) => requestService
  .get(environment.endpoints.get.getSmuDropdown, {
    params: { type },
    paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: false }),
  }).then(({ data: { data } }) => data);

export const saveBulkSmuBrandsActive: (
  ids: string[],
  isActive: boolean
) => PromiseResponse<mixed, { ids: string[], isActive: boolean }> = (ids, isActive) => requestService
  .put(environment.endpoints.put.bulkSmuBrandsActiveChange, ids.map((id) => ({ id, isActive })));
