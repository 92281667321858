import { userService } from "../../services/user";

type Data = {
  id: number;
  name: string;
};

export default function useUser(): Data {
  return {
    name: userService.getUser()?.name || "",
    id: userService.getUser()?.id || 0,
  };
}
