// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  IconButton,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import {
  setPersonalInfoField,
  setAffiliateId,
  saveAffiliateSaga,
  updateAffiliateSaga,
  updatePaymentInfoSaga,
} from "../../../../actions/manageAffiliate";
import {
  mapManageAffiliates,
} from "./utils";
import { getLoading } from "../../../../selectors/loading";

type ManageAffiliates = {
  personalInfo: Object,
  paymentInfo: Object,
  affiliateId: string,
  affType: string,
};

type Props = {
  manageAffiliate: ManageAffiliates,
  setAffiliateField: (
    field: string,
    value: string
  ) => void,
  saveAffiliate: (
    data: Object
  ) => void,
  updateAffiliate: (
    id: string,
    data: Object
  ) => void,
  setDefaultAffiliateId: (
    value: string
  ) => void,
  updatePaymentInfo: (
    affId: string,
    data: Object
  ) => void,
  mode: string,
  tab: string
};

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    flex: 0,
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginLeft: 20,
  },
  isActive: {
    marginRight: 100,
  },
  saveButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  affiliateText: {
    color: theme.palette.primary.main,
  },
  editAffiliateText: {
    marginLeft: 20,
    color: theme.palette.primary.main,
  },
  affButtons: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
}));

const mapStateToProps = (state) => ({
  manageAffiliate: mapManageAffiliates(state),
  loading: getLoading(state, "getPersonalInfo"),
});

const mapDispatchToProps = (dispatch) => ({
  setAffiliateField: (field, value) => dispatch(setPersonalInfoField({ field, value })),
  saveAffiliate: (data) => dispatch(saveAffiliateSaga(data, "add")),
  updateAffiliate: (id, data) => dispatch(updateAffiliateSaga(id, data)),
  setDefaultAffiliateId: (value) => dispatch(setAffiliateId(value)),
  updatePaymentInfo: () => dispatch(updatePaymentInfoSaga()),
});

const CreateAffiliateHeader = ({
  manageAffiliate,
  setAffiliateField,
  saveAffiliate,
  updateAffiliate,
  setDefaultAffiliateId,
  updatePaymentInfo,
  loading,
  mode,
  tab,
}: Props) => {
  const classes = useStyles();

  const {
    personalInfo,
    affiliateId,
    isPersonalInfoFetcing,
  } = manageAffiliate;

  const { isActive } = personalInfo;
  const handleChangeSwitch = (e) => {
    const value = e.target.checked ? 1 : 0;
    setAffiliateField(e.target.name, value);
  };
  const handleSave = () => {
    if (tab === "paymentInfo") {
      return updatePaymentInfo();
    }
    if (mode === "edit" && affiliateId) {
      return updateAffiliate(affiliateId, personalInfo);
    }
    return saveAffiliate(personalInfo);
  };
  const handleClose = () => {
    window.location = "/manageAffiliate";
  };
  const allowedTabs = [
    "personalInfo",
    "paymentInfo",
  ];

  useEffect(() => () => {
    setDefaultAffiliateId("");
  }, []);

  return (
    <Paper
      className={classes.dialogHeader}
      variant="outlined"
    >
      <IconButton onClick={handleClose} size="large">
        <CloseIcon />
      </IconButton>
      {mode === "add" && (
        <>
          <Typography
            className={classes.label}
            variant="h6"
          >
            New CPA Affiliate
          </Typography>
        </>
      )}
      {mode === "edit" && (
        <Typography
          className={classes.editAffiliateText}
          variant="h6"
        >
          Edit affiliate:
          <span
            className={classes.affiliateText}
          >
          &nbsp;
            {affiliateId}
          </span>
        </Typography>
      )}
      <div
        className={classes.affButtons}
      >
        {(tab === "personalInfo" && !isPersonalInfoFetcing) && (
          <FormControlLabel
            className={classes.isActive}
            data-testid="isActive"
            control={(
              <Switch
                name="isActive"
                checked={!!isActive}
                onChange={handleChangeSwitch}
                color="secondary"
              />
            )}
            label="Is active"
          />
        )}
        {allowedTabs.includes(tab) && (
          <Button
            className={classes.saveButton}
            disabled={loading}
            color="primary"
            variant="contained"
            size="medium"
            data-testid="applyButton"
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAffiliateHeader);
