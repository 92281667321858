import environment from "environment";
import { parseResponse } from "@fas/ui-framework";
import * as baseApi from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import type { operations } from "@mtu/contract/lib/operations/rest/service/backofficeTenants";
import { optimistic } from "oazapfts";
import { get } from "lodash";

const { defaults, servers, ...sdk } = optimistic(baseApi);

function parseErrorMessage(p: object) {
  return get(p, "error[0].message", "");
}

defaults.credentials = "include";
defaults.baseUrl = environment.baseUrl;
defaults.fetch = (input: RequestInfo | URL, init?: RequestInit) => fetch(input, init).then(async (r) => {
  if (r.status === 401) {
    window.location.href = `${environment.endpoints.get.loginUrl}?r=${encodeURIComponent(window.location.href)}`;
  }
  if (!r.ok) {
    const b = await r.json();
    b.errorMessage = parseResponse(b) || parseErrorMessage(b);
    return Promise.reject(b);
  }
  return r;
}).catch((e) => {
  e.errorMessage = parseResponse(e) || parseErrorMessage(e);
  return Promise.reject(e);
});

const api = { ...sdk };

export type Operations = keyof operations;

export default api;

export type ApiErrorType = {
  errorMessage: string,
  message?: string,
  errors: Array<{ field?: string, message?: string, error?: string }>,
};
