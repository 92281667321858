// @flow
import type { StatelessFunctionalComponent } from "react";
import React, { useCallback } from "react";
import { SelectForm, TextFieldForm } from "@fas/ui-core/lib/Form";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { useDispatch, useSelector } from "react-redux";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { useList } from "../../hoocks/useDictionary";
import MultiSelectForm from "../Form/MultiSelectForm";
import { FORM_KEY_TOP_OFFERS } from "../../helpers/constants/topOffers";
import type { State } from "../../pages/PotentialPartnerForm/store";

export type Props = $ReadOnly<{|
|}>;

const numberInputProps = { inputProps: { min: 0 } };

const SmartlinkTopOffers: StatelessFunctionalComponent<Props> = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const countries: DropDownObjItemType[] = useList("countries");
  const platforms: DropDownObjItemType[] = useList("platforms");
  const verticals: DropDownObjItemType[] = useList("verticals");

  const onChangeGeo = useCallback((country) => {
    dispatch(setFormData(FORM_KEY_TOP_OFFERS, {
      geo: country ? [country] : [],
    }));
  }, [dispatch]);
  const [country = ""]: any = useSelector((state: State) => getFormField(state, FORM_KEY_TOP_OFFERS, "geo")) || [];
  return (
    <>
      <SelectForm
        name="vertical"
        label="Vertical"
        options={verticals}
      />
      <SelectForm
        name="geo"
        label="Country"
        options={countries}
        value={country}
        onChange={onChangeGeo}
      />
      <MultiSelectForm
        name="platform"
        label="Platform"
        options={platforms}
        enableAddAllBtn
      />
      <TextFieldForm
        {...numberInputProps}
        name="eCPM"
        label="eCPM, $"
        type="number"
      />
    </>
  );
};

export default SmartlinkTopOffers;
