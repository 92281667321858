// @flow
import { SET_MESSAGE } from "../../helpers/constants/message";
import type { StateObj } from "../../reducers/message";

export type SetMessageType = {|
  type: typeof SET_MESSAGE,
  payload: StateObj,
|}

export const setMessage: (StateObj) => SetMessageType = (payload) => ({
  type: SET_MESSAGE,
  payload,
});
