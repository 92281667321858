// @flow
import React from "react";
import { ComboBox } from "@fas/ui-core";
import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import type { ItemType } from "./MassAdjustModal";
import type { OfferCountryOptionsArrayType } from "../../reducers/subrates";

type Props = {
  item: ItemType,
  value: { title: string, value: string | null } | null,
  options: OfferCountryOptionsArrayType,
  setFields: ({ [string]: string | number }) => void,
  fields: { [string]: string | number },
  isErrorsVisible: boolean,
  handleDelete: (ItemType) => void,
};

const ViewRowCountry = ({
  item,
  value,
  options,
  setFields,
  fields,
  isErrorsVisible,
  handleDelete,
}: Props) => (
  <Box
    mb={2}
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    data-testid={`${item.value}-block`}
  >
    <ComboBox
      id={`mui-${item.value}`}
      label={item.title}
      value={value}
      data-testid={item.value}
      fullWidth
      type={item.type}
      options={options}
      getOptionLabel={(option: { title: string, value: string }) => option.title || null}
      isOptionEqualToValue={(option:
        { title: string, value: string },
      val: { title: string, value: string }) => option.value === val.value}
      onChange={(e, val) => {
        setFields({
          ...fields,
          [item.value]: val ? val.value : null,
        });
      }}
      error={{
        isError: isErrorsVisible && fields[item.value] === "",
        helperText: "Chose value or delete field.",
      }}
    />
    <IconButton onClick={() => handleDelete(item)} size="large" data-testid="deleteButton">
      <Delete />
    </IconButton>
  </Box>
);

export default ViewRowCountry;
