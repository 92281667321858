import {
  addNotification,
  setTableData, TableProps, useTable,
} from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "../../store";
import {
  SEARCH_EXT_APPLICATIONS_TABLE_KEY,
} from "../../../../helpers/constants/affiliates";
import useTenantId from "../../useTenantId";
import api from "../../../../services/api";
import type { ArrayElement } from "../../types";
import useTableQueryFilter from "../../utils/useTableQueryFilter";
import filter from "../../utils/filter";

export type FiltersType = {
  dataProviderName: string,
  offerId?: string,
  affiliateId?: string,
  status?: "Approved" | "Declined" | "Pending" | "Stopped",
}

export const defaultApplicationExtFilters: FiltersType = {
  dataProviderName: "",
  offerId: "",
  status: undefined,
};

export default function useSearchExtApplications() {
  type TData = ArrayElement<Awaited<ReturnType<typeof api.getExtManagerMyApplications>>>;
  const dispatch = useDispatch();
  const tableProps = useTable<TData, FiltersType>(SEARCH_EXT_APPLICATIONS_TABLE_KEY);
  const { filters: { status, ...filters } } = tableProps;
  const enabled = (filters.dataProviderName || "").trim().length > 1;
  const tenantId = useTenantId();
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery(
    [SEARCH_EXT_APPLICATIONS_TABLE_KEY, tenantId, filters],
    async () => api.getExtManagerMyApplications(tenantId, filters.dataProviderName || "", {
      offerId: filters.offerId || undefined,
    }),
    {
      enabled,
      onError: (e: { errorMessage: string }) => {
        dispatch(addNotification({ message: e.errorMessage || "Something went wrong...", severity: "error" }));
      },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setTableData(SEARCH_EXT_APPLICATIONS_TABLE_KEY, data));
    }
  }, [data, dispatch]);

  useTableQueryFilter(SEARCH_EXT_APPLICATIONS_TABLE_KEY, defaultApplicationExtFilters);

  return {
    ...tableProps,
    isDataAvailable: !!data?.length,
    data: enabled ? filter(tableProps.data, { status }) : [],
    isLoading: isLoading && enabled,
    isRefetching,
  } as TableProps<TData, FiltersType> & {
    isRefetching: boolean,
    isDataAvailable: boolean,
  };
}
