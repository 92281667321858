// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { ShowMoreLink } from "@fas/ui-core";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { useDispatch } from "react-redux";

type Props = {
  location: string,
}

const Location: StatelessFunctionalComponent<Props> = ({
  location,
}: Props) => {
  const dispatch = useDispatch();

  const showCopiedMessage: () => void = () => {
    dispatch(addNotification({ message: "Link Copied", severity: "success" }));
  };

  return location && <ShowMoreLink link={location} target="_blank" onCopyLink={showCopiedMessage} disablePortal={false} />;
};

export default React.memo<Props>(Location);
