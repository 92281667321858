// @flow
/* eslint-disable import/max-dependencies */
import {
  put,
  debounce,
  select,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTablePage, getTablePageSize, getTableSorting } from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  setTableData,
  setTableSelection,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { getAffiliateId } from "../../selectors/payments";
import { GET_PAYOUT_HISTORY_LIST_SAGA, PAYOUT_HISTORY_LIST_TABLE } from "../../helpers/constants/payoutHistory";
import { fetchPayoutHistory } from "../../services/payments";
import type { LoadingTypes } from "../../selectors/loading/types";
import type {
  PayoutIncomingType,
} from "../../components/PayoutHistoryTable/types/PayoutHistoryTable.types";

export function* makeFetch(): Saga<void> {
  yield put(setTableData(PAYOUT_HISTORY_LIST_TABLE, []));
  yield put(setTableSelection(PAYOUT_HISTORY_LIST_TABLE, []));
  try {
    yield put(setLoading<LoadingTypes>(PAYOUT_HISTORY_LIST_TABLE, true));

    const affId: string = yield select(getAffiliateId);
    const page: number = yield select(getTablePage, PAYOUT_HISTORY_LIST_TABLE);
    const pageSize: number = yield select(getTablePageSize, PAYOUT_HISTORY_LIST_TABLE);
    const sorting: Sorting = yield select(getTableSorting, PAYOUT_HISTORY_LIST_TABLE);

    const {
      data: {
        data,
        count,
      },
    }: { data: { data: PayoutIncomingType[], count: number }} = yield call(fetchPayoutHistory, affId, {
      page,
      pageSize,
      sorting,
    });
    yield put(setTableData(PAYOUT_HISTORY_LIST_TABLE, data));
    yield put(changeTableItemsTotalAmount(PAYOUT_HISTORY_LIST_TABLE, count));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(PAYOUT_HISTORY_LIST_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_PAYOUT_HISTORY_LIST_SAGA, (makeFetch: Function));
}
