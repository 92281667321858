// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import {
  SimpleTableActionButton, Table, TableActionGroup, TableActions,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import makeStyles from "@mui/styles/makeStyles";
import { Delete } from "@mui/icons-material";
import {
  deleteAffiliateSmartOffersSaga,
  type DeleteAffiliateSmartOffersSagaType,
  getAffiliateSmartOffersListSaga,
  type GetAffiliateSmartOffersListSagaType,
} from "../../actions/affiliateSmartOffers";
import type { AffiliateSmartOfferType } from "./types/AffiliateSmartOffersList.types";
import { AFFILIATE_SMART_OFFERS_TABLE } from "../../helpers/constants/affiliateSmartOffers";

const useStyles = makeStyles(() => ({
  table: {
    "& #table-pagination": {
      display: "none",
    },
  },
  smallTableCol: {
    maxWidth: "100px",
    width: "100px",
  },
  middleTableCol: {
    minWidth: "150px",
    width: "350px",
  },
}));

const AffiliateSmartOffersList: StatelessFunctionalComponent<{}> = () => {
  const classes = useStyles();
  const tableProps: TableProps = useTable(AFFILIATE_SMART_OFFERS_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const handleLoad: () => GetAffiliateSmartOffersListSagaType = () => dispatch(getAffiliateSmartOffersListSaga());
  const handleDelete: () => DeleteAffiliateSmartOffersSagaType = () => dispatch(deleteAffiliateSmartOffersSaga());

  const columns: Array<Column<AffiliateSmartOfferType>> = [
    {
      field: "id",
      label: "Id",
      className: classes.smallTableCol,
    },
    {
      field: "name",
      label: "Name",
      className: classes.middleTableCol,
    },
    {
      field: "link",
      label: "Link",
    },
  ];

  const Actions: Element<typeof TableActions> = (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Delete"
          onClick={handleDelete}
          Icon={<Delete />}
        />
      </TableActionGroup>
    </TableActions>
  );

  return (
    <Box p={2} className={classes.table}>
      <Table
        {...tableProps}
        title="Smart offers"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onLoad={handleLoad}
        Actions={Actions}
      />
    </Box>
  );
};

export default AffiliateSmartOffersList;
