import { useQuery } from "@tanstack/react-query";
import {
  addNotification,
  changeTableItemsTotalAmount,
  setTableData,
  TableProps,
  useTable,
} from "@fas/ui-framework";
import { useEffect } from "react";
import useTenantId from "../../useTenantId";
import api from "../../../../services/api";
import type { FiltersType, OfferType } from "./types";
import useOffersQueryKey from "./useOffersQueryKey";
import { useDispatch } from "../../store";
import { OFFERS_TABLE_KEY } from "../../../../helpers/constants/affiliates";
import useTableQueryFilter from "../../utils/useTableQueryFilter";

export function getOfferId(item?: OfferType | null) {
  if (!item) return "";
  return `${item.id}`;
}

export function isOffers(a?: OfferType, b?: OfferType) {
  return !!a && !!b && getOfferId(a) === getOfferId(b);
}

export const defaultOffersFilters: FiltersType = {
  search: "",
};

function useOffers() {
  const dispatch = useDispatch();
  const tableProps = useTable<OfferType, FiltersType>(OFFERS_TABLE_KEY);
  const tenantId = useTenantId();

  const search = (tableProps.filters.search || "").trim();
  const enabled = search.length > 0;
  const queryKey = useOffersQueryKey();
  const {
    isLoading,
    isRefetching,
    data,
    error,
  } = useQuery(
    queryKey,
    async () => api.findAndCountSpecificOffers(tenantId, { search }),
    {
      enabled,
      onError: (e: { errorMessage: string }) => {
        dispatch(addNotification({ message: e.errorMessage || "Something went wrong...", severity: "error" }));
      },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setTableData(OFFERS_TABLE_KEY, data.data));
      dispatch(changeTableItemsTotalAmount(OFFERS_TABLE_KEY, data.count));
    }
  }, [data, dispatch]);

  useTableQueryFilter(OFFERS_TABLE_KEY, defaultOffersFilters);

  return {
    ...tableProps,
    data: enabled && !error ? tableProps.data : [],
    count: enabled && !error ? tableProps.count : 0,
    isLoading: isLoading && enabled,
    isRefetching,
  } as TableProps<OfferType, FiltersType> & { isRefetching: boolean };
}

export default useOffers;
