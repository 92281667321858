// @flow
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDictionaryLoadingKey, getDictionaryKey, getStaticLoadingKey } from "../helpers/generators";
import { getDropdownList, getOptionList } from "../selectors/dictionaries";
import { type DictionaryParams, getDictionarySaga, getDropdownsStaticSaga } from "../actions/dictionary";
import { useLoading } from "./useLoading";
import type { DropDownObjItemType } from "../reducers/dictionaries";

type UseDictionaryType<T> = [
  T[],
  boolean,
];

type Selector<T> = (state: Object, key: string) => T[];

/**
 * Base get dictionary
 * @example
 * const params = { dictionary: "advertiser", type: "apiCountry", name: "offer", format: "name", ... }
 * const [countries, isCountryLoading] = useDictionary(params);
 * @param {DictionaryParams} params name of list
 * @param {Selector} selector selector to get list from state
 * @returns {[T[],boolean]} cortege [list, loading]
 */
export default function useDictionary<T>(params: DictionaryParams, selector: Selector<T>): UseDictionaryType<T> {
  const dispatch = useDispatch();
  const list: T[] = useSelector((state) => selector(state, getDictionaryKey(params)));
  const loading: boolean = useLoading<string>(getDictionaryLoadingKey(params));

  useEffect(() => {
    dispatch(getDictionarySaga(params));
  }, [dispatch, params.format, params.filter, params.type, params.field, params.dictionary]);

  return [
    list,
    loading,
  ];
}

/**
 * Get dictionary list by name
 * @example
 * const [countries, isCountryLoading] = useDictionary("advertiser", "apiCountry");
 * @param {string} dictionary name
 * @param {string} listType type of list
 * @returns {[DropDownObjItemType[],boolean]} cortege [list, loading]
 */
export function useDictionaryList(
  dictionary: string,
  listType: string
): UseDictionaryType<DropDownObjItemType> {
  return useDictionary<DropDownObjItemType>({
    dictionary,
    type: listType,
  }, getDropdownList);
}

export function useList(listKey: string): DropDownObjItemType[] {
  return useSelector((state) => getOptionList(state, listKey));
}

export function useDropdownsStaticList(dictionaryName: string): boolean {
  const dispatch = useDispatch();
  const loading: boolean = useLoading<string>(getStaticLoadingKey(dictionaryName));

  useEffect(() => {
    dispatch(getDropdownsStaticSaga(dictionaryName));
  }, [dispatch, dictionaryName]);

  return loading;
}
