// @flow
import React, {
  useState,
  useEffect,
  type StatelessFunctionalComponent,
} from "react";
import {
  Popover,
  Grid,
  Select,
  IconButton,
  Button,
  Typography,
  Theme,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Today, ArrowDropDown, Close } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import type {
  Filters,
} from "@fas/ui-core/lib/Table/Table.types";
import { datepickerValues } from "../../utils";

type Props = {
  setFiltering: (filters: Filters) => void,
  handleSendFilters: () => void,
}

type Classes = {
  button: string,
  datepicker: string,
  datepickerDropdown: string,
  inlineSpace: string,
}

type Presets = "today"
 | "yesterday"
 | "thisWeek"
 | "pastWeek"
 | "lastSevenDays"
 | "thisMonth"
 | "lastThirtyDays"
 | "lastYear"
 | "custom";

const dateRegex: RegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  button: {
    background: "white",
    width: "200px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  datepicker: {
    margin: theme.spacing(3),
  },
  datepickerDropdown: {
    width: "30%",
    margin: theme.spacing(2),
  },
  inlineSpace: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
  },
}));

const setDate: (days: number) => Date = (days) => {
  const date: Date = new Date();
  return new Date(date.setDate(date.getDate() - days));
};

const getDatepickerValue: (range: Presets) => string = (range) => {
  switch (range) {
    case "today":
      return "Today";
    case "yesterday":
      return "Yesterday";
    case "thisWeek":
      return "This week";
    case "pastWeek":
      return "Past week";
    case "lastSevenDays":
      return "Last 7 days";
    case "thisMonth":
      return "This month";
    case "lastThirtyDays":
      return "Last 30 days";
    case "lastYear":
      return "Last 365 days";
    case "custom":
      return "Custom";
    default:
      return "All time";
  }
};

const getRange: (range: Presets) => { from: Date, to: Date } = (range) => {
  const date: Date = new Date();
  const dayOfWeek: number = date.getDay();
  let from: Date;
  let to: Date = date;

  switch (range) {
    case "today":
      from = date;
      to = date;
      break;
    case "yesterday":
      from = setDate(1);
      to = setDate(1);
      break;
    case "thisWeek":
      from = setDate(dayOfWeek);
      break;
    case "pastWeek": {
      const pastWeek: number = dayOfWeek + 6;
      from = setDate(pastWeek);
      to = setDate(dayOfWeek);
      break;
    }
    case "lastSevenDays":
      from = setDate(7);
      break;
    case "thisMonth": {
      const days: number = date.getDate() - 1;
      from = setDate(days);
      break;
    }
    case "lastThirtyDays":
      from = setDate(30);
      break;
    case "lastYear":
      from = setDate(365);
      break;
    default:
      from = new Date("01.01.2010");
      break;
  }

  return { from, to };
};

const Datepicker: StatelessFunctionalComponent<Props> = ({
  setFiltering,
  handleSendFilters,
}: Props) => {
  const classes: Classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [datepickerValue, setDatepickerValue] = useState("All Time");

  const [dateFrom, setDateFrom] = useState(new Date("01.01.2010"));
  const [dateTo, setDateTo] = useState(new Date());
  const [dateRange, setDateRange] = useState("allTime");
  const [errors, setErrors] = useState({
    dateFrom: "",
    dateTo: "",
  });

  const handleOpenDatepicker = (event) => setAnchorEl(event.currentTarget);
  const handleCloseDatepicker = () => setAnchorEl(null);
  const handleDatepickerValue = (value) => setDatepickerValue(getDatepickerValue(value));

  useEffect(() => {
    if (!errors.dateFrom && !errors.dateTo) {
      setFiltering({
        from: moment(dateFrom).format("YYYY-MM-DD"),
        to: moment(dateTo).format("YYYY-MM-DD"),
      });
    }
  }, [dateFrom, dateTo]);

  const handleDateFrom = (date, value) => {
    if (value && !dateRegex.test(value)) {
      setErrors({ ...errors, dateFrom: "Invalid date format" });
      return;
    }
    setDateFrom(date && date.toDate());
    setDateRange("custom");
    handleDatepickerValue("custom");
    if (date) {
      if (
        date.toString() !== "Invalid Date"
        && dateTo
        && dateTo.toString() !== "Invalid Date"
      ) {
        setErrors({ ...errors, dateFrom: "" });
      }
      else {
        setErrors({ ...errors, dateFrom: "Invalid date" });
      }
    }
  };

  const hanldeDateTo = (date, value) => {
    if (value && !dateRegex.test(value)) {
      setErrors({ ...errors, dateTo: "Invalid date format" });
      return;
    }
    setDateTo(date && date.toDate());
    setDateRange("custom");
    handleDatepickerValue("custom");
    if (date) {
      if (
        date.toString() !== "Invalid Date"
        && dateTo
        && dateTo.toString() !== "Invalid Date"
      ) {
        setErrors({ ...errors, dateTo: "" });
      }
      else {
        setErrors({ ...errors, dateTo: "Invalid date" });
      }
    }
  };

  const handleDateRange = (e) => {
    const { from, to } = getRange(e.target.value);

    setDateFrom(from);
    setDateTo(to);
    setDateRange(e.target.value);
    handleDatepickerValue(e.target.value);

    setFiltering({ from: moment(from).format("YYYY-MM-DD"), to: moment(to).format("YYYY-MM-DD") });
    handleSendFilters();
  };

  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        onClick={handleOpenDatepicker}
        startIcon={<Today style={{ float: "left" }} />}
        endIcon={<ArrowDropDown />}
        size="medium"
        data-testid="createdAt-list-open-btn"
      >
        <Typography align="left" variant="button" style={{ width: "150px" }}>
          {datepickerValue}
        </Typography>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseDatepicker}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <IconButton
          aria-label="close"
          color="inherit"
          style={{ float: "right" }}
          onClick={handleCloseDatepicker}
          size="large"
        >
          <Close fontSize="inherit" />
        </IconButton>
        <div className={classes.inlineSpace}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container justifyContent="space-around">
              <DesktopDatePicker
                disableToolbar
                variant="inline"
                inputFormat="MM/DD/YYYY"
                margin="normal"
                label="From"
                value={dateFrom}
                onChange={handleDateFrom}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant="standard"
                    error={!!errors.dateFrom}
                    helperText={errors.dateFrom}
                    className={classes.datepicker}
                  />
                )}
              />
              <DesktopDatePicker
                disableToolbar
                variant="inline"
                inputFormat="MM/DD/YYYY"
                margin="normal"
                label="To"
                value={dateTo}
                onChange={hanldeDateTo}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    variant="standard"
                    error={!!errors.dateTo}
                    helperText={errors.dateTo}
                    className={classes.datepicker}
                  />
                )}
              />
              <Select
                className={classes.datepickerDropdown}
                value={dateRange}
                onChange={handleDateRange}
                variant="standard"
              >
                {datepickerValues.map((value) => value)}
              </Select>
            </Grid>
          </LocalizationProvider>
        </div>
      </Popover>
    </>
  );
};

export default Datepicker;
