// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
} from "@mui/material";
import PostbackInfoMain from "./components/PostbackInfoMain";
import EventsPostbackInfo from "./components/EventsPostbackInfo";
import Postbacks from "./components/Postbacks";
import {
  getPostbacksInfoSaga,
  setPostbackFields,
} from "../../../../actions/postbacks";
import { getListsSaga } from "../../../../actions/postbacks/actions";

type Props = {
  getLists: () => any,
};

const mapDispatchToProps = (dispatch) => ({
  getLists: () => dispatch(getListsSaga()),
  setFields: (value) => dispatch(setPostbackFields(value)),
  getPostbackInfo: () => dispatch(getPostbacksInfoSaga()),
});

const mapStateToProps = (state) => ({
  fields: state.manageAffiliate.getIn(["postbackInfo", "fields"]).toJS(),
});

const PostbackInfo = ({
  getLists,
  fields,
  getPostbackInfo,
}: Props) => {
  useEffect(() => {
    getLists();
    getPostbackInfo();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      alignSelf="stretch"
      m={2}
      mb={3}
    >
      <PostbackInfoMain
        fields={fields}
      />
      <EventsPostbackInfo />
      <Postbacks />
    </Box>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostbackInfo);
