import { getTableFilters } from "@fas/ui-framework";
import useTenantId from "../useTenantId";
import { useSelector } from "../store";
import { SHAVE_MODIFICATIONS_TABLE_KEY } from "../../../helpers/constants/affiliates";
import type { FiltersType } from "./types";

export default function useShaveModificationsQueryKey() {
  const tenantId = useTenantId();
  const filters = useSelector((
    state
  ) => getTableFilters<FiltersType>(state, SHAVE_MODIFICATIONS_TABLE_KEY));
  return [tenantId, SHAVE_MODIFICATIONS_TABLE_KEY, filters];
}
