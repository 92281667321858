import {
  changeTableItemsTotalAmount,
  setTableData,
  useTable,
} from "@fas/ui-framework";
import { useEffect } from "react";
import { useDispatch } from "../store";
import { EXT_RATE_AFFILIATES_ADV_TABLE_KEY } from "../../../helpers/constants/affiliates";
import useExtRate from "./useExtRate";
import type { ExtRateType, FiltersType } from "./types";
import useTableQueryFilter from "../utils/useTableQueryFilter";

export const defaultExtRatesFilters = {
  search: "",
  platform: "",
  country: "",
  conversionType: "",
  extOfferGroup: "",
};

export default function useAffiliateExtRates() {
  const dispatch = useDispatch();
  const tableProps = useTable<ExtRateType, FiltersType>(EXT_RATE_AFFILIATES_ADV_TABLE_KEY);

  const {
    isLoading,
    isRefetching,
    data,
  } = useExtRate();

  useEffect(() => {
    if (data) {
      dispatch(setTableData(EXT_RATE_AFFILIATES_ADV_TABLE_KEY, data.extRates));
      dispatch(changeTableItemsTotalAmount(EXT_RATE_AFFILIATES_ADV_TABLE_KEY, data.count));
    }
  }, [data, dispatch]);

  useTableQueryFilter(EXT_RATE_AFFILIATES_ADV_TABLE_KEY, defaultExtRatesFilters);

  return {
    ...tableProps,
    suggestions: data?.suggestions,
    isLoading,
    isRefetching,
  };
}
