// @flow
import React from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  Loader,
  SetupCard,
} from "@fas/ui-core";
import { Box } from "@mui/material";
import CardGridContent from "../CardGridContent";
import type { FieldItem } from "../CardGridContent/types/CardGridContent.types";
import {
  MAX_ROWS_PER_CARD,
} from "../../helpers/constants/payments";
import type { Props } from "./types/PaymentDetailsCard.types";
import { getPaymentTypeModel } from "../../pages/PaymentInfo/PaymentTypeFactory";
import PaymentType from "../../pages/PaymentInfo/PaymentTypeFactory/PaymentType";

const PaymentDetailsCard: StatelessFunctionalComponent<Props> = ({
  loading,
  paymentDetails,
}: Props) => {
  const PaymentTypeModel: PaymentType = getPaymentTypeModel(paymentDetails.paymentMethodId);
  const fieldsMapper: FieldItem[] = PaymentTypeModel.getPaymentDetailsMapper(paymentDetails);

  return (
    <SetupCard title="Payment details">
      <Box width={1}>
        <Loader loading={loading} size={35}>
          <Box mt={2}>
            <CardGridContent
              fieldsMapper={fieldsMapper}
              gridCols={fieldsMapper.length > MAX_ROWS_PER_CARD ? 2 : 1}
              tableRows={1}
            />
          </Box>
        </Loader>
      </Box>
    </SetupCard>
  );
};

export default PaymentDetailsCard;
