// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import PaymentInfoContainer from "../../containers/PaymentInfo";

function PaymentInfo() {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="CPA - Payment Info">
      <Provider store={store}>
        <NotificationsContainer />
        <PaymentInfoContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default PaymentInfo;
