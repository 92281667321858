// @flow
import {
  CHANGE_FORM_FIELD_LINK_TYPE,
  CHANGE_FORM_FIELD_OFFER,
  CHANGE_FORM_FIELD_OFFER_TYPE,
  CHANGE_FORM_FIELD_PARTNER,
  CHANGE_FORM_LINK_VALIDATION_TYPE,
  CLEAR_IS_ACTIVES_OFFER,
  GET_OFFER_FORM,
  OFFER_ERROR,
  OFFER_IS_FETCHING,
  REMOVE_IS_ACTIVE_OFFER,
  SET_FETCH_DATA_LISTS,
  SET_OFFER_LISTS_FIELD,
  SET_URL_STATUS_MESSAGE,
  SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
  SET_FETCHED_MARKETERS,
  CHANGE_IS_MANAGER_LIST_LOADED,
  SET_OFFER_FOUND,
  GET_OFFER_GROUPS_LIST_SAGA,
  GET_OFFER_NAME_LIST_SAGA,
  GET_CPA_OFFERS_LISTS_SAGA,
  GET_CPA_OFFER_SAGA,
  SAVE_CPA_OFFER_SAGA,
  SET_IS_CLONE,
  RESET_GLOBAL_CAP_SAGA,
  CHANGE_CPA_OFFER_STATUS_SAGA,
  CHANGE_VALIDATION_STATUS_SAGA,
  CHECK_VALIDATION_STATUS_SAGA,
  GET_MANAGERS_LIST_SAGA,
  CHANGE_AUTHOR_SAGA,
} from "../../helpers/constants/cpaoffer";
import type {
  ChangeForm,
  ChangeFormOfferType,
  ChangeLinkType,
  ChangeLinkValidationType,
  ChangePartner,
  ClearIsActivesOfferAction,
  GetOfferForm,
  OfferError,
  OfferIsFetching,
  RemoveIsActiveOfferAction,
  SetFetchDataLists,
  SetOfferListsFieldAction,
  SetUrlStatusMessage,
  SwitchChangeAuthorModalOpened,
  SetFetchedManagersList,
  ChangeIsManagerListLoaded,
  ManagerList,
  SetOfferFound
} from "./types";

export const setFetchDataLists = (
  value: Object
): SetFetchDataLists => ({
  type: SET_FETCH_DATA_LISTS,
  value,
});
export const changeForm = (
  fieldName: string,
  value: any
): ChangeForm => ({
  type: CHANGE_FORM_FIELD_OFFER,
  fieldName,
  value,
});
export const changePartner = (
  fieldName: string,
  value: any
): ChangePartner => ({
  type: CHANGE_FORM_FIELD_PARTNER,
  fieldName,
  value,
});
export const changeLinkType = (
  fieldName: string,
  value: any
): ChangeLinkType => ({
  type: CHANGE_FORM_FIELD_LINK_TYPE,
  fieldName,
  value,
});
export const changeLinkValidationType = (
  value: string,
): ChangeLinkValidationType => {
  return ({
    type: CHANGE_FORM_LINK_VALIDATION_TYPE,
    value,
  });
};
export const changeFormOfferType = (
  fieldName: string,
  value: any
): ChangeFormOfferType => ({
  type: CHANGE_FORM_FIELD_OFFER_TYPE,
  fieldName,
  value,
});
export const getOfferForm = (
  offer: Object,
  list: Object
): GetOfferForm => ({
  type: GET_OFFER_FORM,
  offer,
  list,
});
export const offerIsFetching = (
  status: boolean
): OfferIsFetching => ({
  type: OFFER_IS_FETCHING,
  status,
});
export const offerError = (
  errors: Array<Object>
): OfferError => ({
  type: OFFER_ERROR,
  errors,
});

export const setUrlStatusMessage = (status: string, message: string): SetUrlStatusMessage => ({
  type: SET_URL_STATUS_MESSAGE,
  status,
  message,
});
export const setOfferListsField = (
  field: string,
  value: Array<Object>
): SetOfferListsFieldAction => ({
  type: SET_OFFER_LISTS_FIELD,
  value: { field, value },
});
export const removeIsActiveOffer = (
  index: number
): RemoveIsActiveOfferAction => ({
  type: REMOVE_IS_ACTIVE_OFFER,
  index,
});
export const clearIsActivesOffer = (): ClearIsActivesOfferAction => ({
  type: CLEAR_IS_ACTIVES_OFFER,
});
export const switchChangeAuthorModalOpened = (): SwitchChangeAuthorModalOpened => ({
  type: SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
});
export const setFetchedManagersList = (managersList: ManagerList): SetFetchedManagersList => ({
  type: SET_FETCHED_MARKETERS,
  managersList
});
export const changeIsManagerListLoaded = (): ChangeIsManagerListLoaded => ({
  type: CHANGE_IS_MANAGER_LIST_LOADED,
});

export const setOfferFound = (
  isOfferFound: boolean
): SetOfferFound => ({
  type: SET_OFFER_FOUND,
  isOfferFound
});

export type GetOfferGroupsListSaga = {
  type: typeof GET_OFFER_GROUPS_LIST_SAGA,
};
export const getOfferGroupsListSaga = (): GetOfferGroupsListSaga => ({
  type: GET_OFFER_GROUPS_LIST_SAGA,
});

export type GetOfferNameListSaga = {
  type: typeof GET_OFFER_NAME_LIST_SAGA,
};
export const getOfferNameListSaga = (): GetOfferNameListSaga => ({
  type: GET_OFFER_NAME_LIST_SAGA,
});

export type GetCpaOffersListsSaga = {
  type: typeof GET_CPA_OFFERS_LISTS_SAGA,
};
export const getCpaOffersListsSaga = (): GetCpaOffersListsSaga => ({
  type: GET_CPA_OFFERS_LISTS_SAGA,
})

export type GetCpaOfferSaga = {
  type: typeof GET_CPA_OFFER_SAGA,
  id: string,
};
export const getCpaOfferSaga = (id: string): GetCpaOfferSaga => ({
  type: GET_CPA_OFFER_SAGA,
  id,
});

export type SaveCpaOfferSaga = {
  type: typeof SAVE_CPA_OFFER_SAGA,
}
export const saveCpaOfferSaga = (): SaveCpaOfferSaga => ({
  type: SAVE_CPA_OFFER_SAGA,
});

export type SetIsClone = {
  type: typeof SET_IS_CLONE,
  payload: boolean
};
export const setIsClone = (payload: boolean): SetIsClone => ({
  type: SET_IS_CLONE,
  payload,
});

export type ResetGlobalCapSaga = {
  type: typeof RESET_GLOBAL_CAP_SAGA,
};
export const resetGlobalCapSaga = (): ResetGlobalCapSaga => ({
  type: RESET_GLOBAL_CAP_SAGA,
});

export type ChangeCpaOfferStatusSaga = {
  type: typeof CHANGE_CPA_OFFER_STATUS_SAGA,
  status: boolean,
};
export const changeCpaOfferStatusSaga = (status: boolean): ChangeCpaOfferStatusSaga => ({
  type: CHANGE_CPA_OFFER_STATUS_SAGA,
  status,
});

export type ChangeValidationStatusSaga = {
  type: typeof CHANGE_VALIDATION_STATUS_SAGA,
  status: boolean,
};
export const changeValidationStatusSaga = (status: boolean): ChangeValidationStatusSaga => ({
  type: CHANGE_VALIDATION_STATUS_SAGA,
  status,
});

export type CheckValidationStatusSaga = {
  type: typeof CHECK_VALIDATION_STATUS_SAGA,
};
export const checkValidationStatusSaga = (): CheckValidationStatusSaga => ({
  type: CHECK_VALIDATION_STATUS_SAGA,
});

export type GetManagersListSaga = {
  type: typeof GET_MANAGERS_LIST_SAGA,
  marketingGroup: { id: string, name: string },
}
export const getManagersListSaga: ({ id: string, name: string }) => GetManagersListSaga = (marketingGroup) => ({
  type: GET_MANAGERS_LIST_SAGA,
  marketingGroup,
})

export type ChangeAuthorSaga = {
  type: typeof CHANGE_AUTHOR_SAGA,
  user: { label: string, value: string },
}
export const changeAuthorSaga = (user: { label: string, value: string }): ChangeAuthorSaga => ({
  type: CHANGE_AUTHOR_SAGA,
  user,
});
