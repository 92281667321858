// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware, { type SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import thunkMiddleware from "redux-thunk";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import loading, {
  type State as LoadingState,
  initLoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import manageAffiliate, { initState } from "../../reducers/manageAffiliate";
import { CPA_AFFILIATES_LIST_TABLE } from "../../helpers/constants/manageAffiliate";
import mainSaga from "./saga";

export type State = $ReadOnly<{|
  manageAffiliate: any,
  tables: TableState,
  notifications: NotificationsState,
  loading: LoadingState<string>,
|}>

function mapManageAffiliateToState(): State {
  return {
    tables: initTableState({
      [CPA_AFFILIATES_LIST_TABLE]: {
        pageSize: 20,
        filters: {
          status: ["Approve"],
        },
      },
    }),
    manageAffiliate: initState(),
    notifications: initNotificationsState(),
    loading: initLoadingState({
      [CPA_AFFILIATES_LIST_TABLE]: false,
      isTemplatesListLoading: false,
    }),
  };
}

type Actions = any;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  tables,
  // $FlowFixMe need update type in reducer
  manageAffiliate,
  notifications,
  loading,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;
  const middlewares = [thunkMiddleware];

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapManageAffiliateToState(),
    composeEnhancers(applyMiddleware(
      ...middlewares,
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);
  return store;
};
