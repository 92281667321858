// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import { RESET_GLOBAL_CAP_SAGA } from "../../helpers/constants/cpaoffer";
import { resetGlobalCap } from "../../services/cpaOffersApi";
import { getTemplateFilterDataSaga } from "../../actions/templates";

export function* makeFetch(): Saga<void> {
  try {
    const rows: Array<DataItem> = yield select(getTableSelections, "CPA_OFFER_TABLE");
    yield call(resetGlobalCap, rows[0].id);
    yield put(getTemplateFilterDataSaga());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchResetGlobalCapSaga(): Saga<void> {
  // todo add event type here
  yield takeEvery(RESET_GLOBAL_CAP_SAGA, (makeFetch: Function));
}
