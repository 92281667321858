// @flow
export const ADVERTISER_INVOICES_TABLE: "ADVERTISER_INVOICES_TABLE" = "ADVERTISER_INVOICES_TABLE";
export const ADVERTISER_INVOICES_DOWNLOAD_SAGA: "ADVERTISER_INVOICES_DOWNLOAD_SAGA" = "ADVERTISER_INVOICES_DOWNLOAD_SAGA";
export const GET_ADVERTISER_INVOICES_LIST_SAGA: "GET_ADVERTISER_INVOICES_LIST_SAGA" = "GET_ADVERTISER_INVOICES_LIST_SAGA";

export const FORM_KEY_ADVERTISER_INVOICES: "FORM_KEY_ADVERTISER_INVOICES" = "FORM_KEY_ADVERTISER_INVOICES";

export const AVAILABLE_QUERY_FILTER_KEYS: string[] = [
  "cpaAdvertiser",
];
