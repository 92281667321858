// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Paper,
  Button,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { savePostbackInfoSaga } from "../../../../../../actions/postbacks";
import PostbackRadioGroup from "../../../../../PostbackRadioGroup";
import PostbackInput from "./PostbackInput";
import type { PostbackInfoMainClasses } from "./types/PostbackInfoMain.types";
import Can from "../../../../../../components/Can";
import PostbackSwitcher from "./PostbackSwitcher";

type UsePostbackInfoMain = {
  onSave: () => mixed,
};

const useStyles: () => PostbackInfoMainClasses = makeStyles((): PostbackInfoMainClasses => ({
  applyButton: {
    marginLeft: "auto",
  },
}));

const usePostbackInfoMain: () => UsePostbackInfoMain = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const onSave: () => mixed = () => dispatch(savePostbackInfoSaga());

  return { onSave };
};

const PostbackInfoMain: StatelessFunctionalComponent<{}> = () => {
  const classes: PostbackInfoMainClasses = useStyles();
  const { onSave }: UsePostbackInfoMain = usePostbackInfoMain();

  return (
    <Paper elevation={3}>
      <Box display="flex" flexDirection="column" p={3}>
        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="h6" sx={{ pr: 3 }}>
            Postback info
          </Typography>
          <PostbackSwitcher label="Postbacks enabled" field="postbacksEnabled" />
        </Box>
        <PostbackInput field="defaultPostback" />
        <PostbackInput field="affiliatePostbackLink" />
        <Can permissions={["cpaAffiliate.gfToggle"]}>
          <PostbackInput field="affiliatePostbackLinkGf" />
        </Can>
        <Can permissions={["cpaAffiliate.lpFilter"]}>
          <PostbackInput field="affiliatePostbackLinkLpFilter" />
        </Can>
        <PostbackRadioGroup field="boSoWebpush" />
        <Can permissions={["cpaAffiliate.gfToggle"]}>
          <PostbackRadioGroup field="gfToggle" />
        </Can>
        <Can permissions={["cpaAffiliate.lpFilter"]}>
          <PostbackRadioGroup field="lpFilter" />
        </Can>
        <Can permissions={["cpaAffiliate.paymentInfoPostbackRepeat"]}>
          <PostbackSwitcher label="Include repeat sales" field="paymentInfoPostbackRepeat" />
        </Can>
        <Box display="flex" mb={1} mt={1}>
          <Button
            className={classes.applyButton}
            data-testid="applyButton"
            color="primary"
            variant="contained"
            onClick={onSave}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default PostbackInfoMain;
