// @flow

import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import { useSelector } from "react-redux";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import type { Option } from "@fas/ui-core/lib/Multiselect/Multiselect.types";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { FORM_KEY_ADVERTISER_INVOICES } from "../../helpers/constants/advertiserInvoices";
import { userService } from "../../services/user";

export function useResetFieldByFieldChange(watchFieldName: string, targetFieldName: string, resetValue: mixed = "") {
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const { onChange, value: targetValue } = useFormField(FORM_KEY_ADVERTISER_INVOICES, targetFieldName);
  const { value } = useFormField(FORM_KEY_ADVERTISER_INVOICES, watchFieldName);

  const isMount = useRef<boolean>(true);
  useEffect(() => {
    if (isMount.current) {
      isMount.current = false;
      return;
    }

    if (loading || targetValue === resetValue) return;
    onChange(resetValue);
  }, [value]);
}

export function useInitialValue(fieldName: string) {
  const { value: id } = useFormField(FORM_KEY_ADVERTISER_INVOICES, "id");
  const { value } = useFormField(FORM_KEY_ADVERTISER_INVOICES, fieldName);
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [id]);

  return val;
}

export function useValue<T>(field: string): T {
  return (useSelector((state) => getFormField(state, FORM_KEY_ADVERTISER_INVOICES, field)): any);
}

/**
 * Disabled fields logic
 * @returns {{lockUpdatePaidAt: boolean, lockUpdateCpaAdvertiser: boolean, lockUpdate: boolean}} flags
 */
export function useDisabledFieldForm() {
  const id: string = useValue("id");
  const status: string = useValue("status");
  const initialStatus = useInitialValue("status");

  const isEditForm: boolean = Boolean(id);
  const canEdit: boolean = userService.can(["cpaAdmin.advertiserInvoices.updatePaidInvoice"]) || initialStatus !== "paid";

  const lockUpdate: boolean = isEditForm && !canEdit;
  const lockUpdatePaidAt: boolean = status !== "paid" || !canEdit;
  const lockUpdateCpaAdvertiser: boolean = isEditForm;

  return {
    lockUpdate,
    lockUpdatePaidAt,
    lockUpdateCpaAdvertiser,
  };
}

export type Optional<T> = void | T;

export function useOptionalOption(labelName: string, valueName: string): Optional<Option> {
  const label: string = useValue(labelName);
  const value: string = useValue(valueName);
  return useMemo((): Optional<Option> => (value ? { label, value } : undefined), [label, value]);
}
