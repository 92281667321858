/* eslint-disable complexity */
// @flow
import { Record, List, Map } from "immutable";
import type { RecordFactory } from "immutable";
import type { Reducer } from "redux";
import {
  SET_TEMPLATES_CONFIG,
  SET_TEMPLATES_LIST,
  SET_CURRENT_TEMPLATE,
  SET_CURRENT_TEMPLATE_DATA,
  SET_CURRENT_TEMPLATE_ATTRIBUTE,
  ADD_CURRENT_TEMPLATE_ATTRIBUTE,
  SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST,
  SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY,
  DELETE_CURRENT_TEMPLATE_ATTRIBUTE,
  SET_ACTIVE_TEMPLATE,
  SET_TEMPLATES_SEARCH_STRING,
  SET_TEMPLATES_CATEGORY,
  SET_TEMPLATE_MODAL_OPEN,
  CLEAR_TEMPLATES_LIST_STATE,
} from "../../helpers/constants/templates";
import type {
  Actions,
  SetCurrentTemplateAttribute,
  AddCurrentTemplateAttribute,
  SetCurrentTemplateAttributesList,
  SetCurrentTemplateAttributeVisibility,
  DeleteCurrentTemplateAttribute,
} from "../../actions/templates";
import type {
  Config,
  ConfigGroup,
  ConfigAttribute,
  Template,
  TemplateState,
  TemplatesListData,
  TemplatesListState,
  TemplateAttribute,
} from "./types";

export const defaultTemplate: TemplateState = {
  id: 0,
  reportId: 0,
  name: "",
  createdBy: 0,
  author: "",
  createdAt: "",
  updatedBy: 0,
  updatedAt: "",
  isFavorite: false,
  isDefault: false,
  isSystem: false,
  attributes: List([]),
};

export const makeTemplate: RecordFactory<TemplateState> = Record(defaultTemplate);

export const makeModalConfig: RecordFactory<{
  groups: List<ConfigGroup>,
  attributes: List<ConfigAttribute>,
}> = Record({
  groups: List([]),
  attributes: List([]),
});

export const makeTemplatesListState: RecordFactory<TemplatesListData> = Record({
  modalConfig: makeModalConfig(),
  reportName: "",
  templatesList: List([]),
  searchString: "",
  category: "system",
  currentTemplate: makeTemplate(),
  activeTemplate: makeTemplate(),
  isTemplateModalOpen: false,
  dropdownsList: Map(),
});

export const initiaTemplatesListlState: TemplatesListState = makeTemplatesListState({});

const reducer: Reducer<TemplatesListState, Actions> = (state = initiaTemplatesListlState, action) => {
  switch (action.type) {
    case SET_TEMPLATES_CONFIG: {
      const { groups, attributes }: Config = action.payload;
      return state.set("modalConfig", makeModalConfig({
        groups: List(groups),
        attributes: List(attributes),
      }));
    }

    case SET_TEMPLATES_LIST: {
      return state.set("templatesList", List(action.payload));
    }

    case SET_CURRENT_TEMPLATE: {
      const { attributes, ...templateData }: Template | { attributes: [] } = action.payload || { attributes: [] };
      return state.set("currentTemplate", makeTemplate({ ...templateData, attributes: List(attributes) }));
    }

    case SET_CURRENT_TEMPLATE_DATA: {
      return state.set("currentTemplate", state.get("currentTemplate").mergeDeep(action.payload));
    }

    case SET_CURRENT_TEMPLATE_ATTRIBUTE: {
      const { payload: { filters, index } }: SetCurrentTemplateAttribute = action;
      const attributes: List<TemplateAttribute> = state.getIn(["currentTemplate", "attributes"], List());
      return state.setIn(["currentTemplate", "attributes"], attributes.update(
        index,
        (atrribute: TemplateAttribute): TemplateAttribute => ({ ...atrribute, filters })
      ));
    }

    case ADD_CURRENT_TEMPLATE_ATTRIBUTE: {
      const { payload }: AddCurrentTemplateAttribute = action;
      const attributes: List<TemplateAttribute> = state.getIn(["currentTemplate", "attributes"], List());
      return state.setIn(["currentTemplate", "attributes"], attributes.push({
        ...payload,
        isHidden: false,
      }));
    }

    case DELETE_CURRENT_TEMPLATE_ATTRIBUTE: {
      const { payload: { index } }: DeleteCurrentTemplateAttribute = action;
      const attributes: List<TemplateAttribute> = state.getIn(["currentTemplate", "attributes"], List());
      return state.setIn(["currentTemplate", "attributes"], attributes.delete(index));
    }

    case SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST: {
      const { payload: attributes }: SetCurrentTemplateAttributesList = action;
      return state.setIn(["currentTemplate", "attributes"], List(attributes));
    }

    case SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY: {
      const { payload: { name } }: SetCurrentTemplateAttributeVisibility = action;
      const attributes: List<TemplateAttribute> = state.getIn(["currentTemplate", "attributes"], List());
      const index: number = attributes.findIndex((attribute: TemplateAttribute): boolean => attribute.name === name);
      return state.setIn(["currentTemplate", "attributes"], attributes.update(
        index,
        (atrribute: TemplateAttribute): TemplateAttribute => ({ ...atrribute, isHidden: !atrribute.isHidden })
      ));
    }

    case SET_ACTIVE_TEMPLATE: {
      const { attributes, ...templateData }: Template = action.payload;
      return state.set("activeTemplate", makeTemplate({ ...templateData, attributes: List(attributes) }));
    }

    case SET_TEMPLATES_SEARCH_STRING: {
      return state.set("searchString", action.payload);
    }

    case SET_TEMPLATES_CATEGORY: {
      return state.set("category", action.payload);
    }

    case SET_TEMPLATE_MODAL_OPEN: {
      return state.set("isTemplateModalOpen", action.payload);
    }

    case CLEAR_TEMPLATES_LIST_STATE: {
      return state.withMutations((newState: TemplatesListState) => {
        newState.set("templatesList", List([]));
        newState.set("searchString", "");
        newState.set("category", "system");
      });
    }

    default:
      return state;
  }
};

export default reducer;
