// @flow
import React, { type Node, useMemo } from "react";
import { Provider } from "react-redux";
import configureStore from "./store";
import UtmCampaignDictionaryList from "../../containers/UtmCampaignDictionaryList";
import NotificationsContainer from "../../components/NotificationsContainer/NotificationsContainer";

function UtmCampaignDictionaryPage(): Node {
  const store = useMemo(() => configureStore());

  return (
    <Provider store={store}>
      <NotificationsContainer />
      <UtmCampaignDictionaryList />
    </Provider>
  );
}

export default UtmCampaignDictionaryPage;
