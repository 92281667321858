// @flow
import { type Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getTemplates,
  getSearchString,
  getLoading,
  getCategory,
  getActiveTemplate,
  type StoreWithTemplates as State,
} from "../../selectors/templates";
import { getFiltersList } from "../../selectors/filters";
import {
  getTemplatesSagaDebounced,
  setSearchString,
  setTemplateModalOpen,
  setTemplatesList,
  setCurrentTemplate,
  setActiveTemplate,
  setCategory,
  changeTemplateStatusSaga,
  deleteTemplateSaga,
  clearTemplatesListState,
} from "../../actions/templates";
import type {
  OwnProps,
  StateProps,
  DispatchProps,
  Props,
} from "./TemplatesList.types";
import TemplatesList from "../../components/TemplateComponents/TemplatesList";

const mapStateToProps: (state: State) => StateProps = (state: State) => ({
  templatesList: getTemplates(state),
  searchString: getSearchString(state),
  category: getCategory(state),
  activeTemplate: getActiveTemplate(state),
  filters: getFiltersList(state),
  isTemplateListLoading: getLoading(state, "getTemplatesList"),
  isTemplateConfigLoading: getLoading(state, "getTemplatesConfig"),
  isDefaultTemplateLoading: getLoading(state, "getDefaultTemplate"),
});

const mapDispatchToProps: (Dispatch<*>) => DispatchProps = (dispatch) => bindActionCreators({
  onGetTemplatesListSagaDebounced: getTemplatesSagaDebounced,
  onSetSearchString: setSearchString,
  onSetTemplateModalOpen: setTemplateModalOpen,
  onSetCurrentTemplate: setCurrentTemplate,
  onSetActiveTemplate: setActiveTemplate,
  onSetCategory: setCategory,
  onChangeTemplateStatusSaga: changeTemplateStatusSaga,
  onDeleteTemplateSaga: deleteTemplateSaga,
  onSetTemplatesList: setTemplatesList,
  onClearTemplatesListState: clearTemplatesListState,
},
dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(TemplatesList);
