// @flow
import {
  put,
  select,
  call,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA, SMU_BRAND_LIST_TABLE } from "../../helpers/constants/smu";
import { getSmuBrandsSaga } from "../../actions/smuBrands";
import { saveBulkSmuBrandsActive } from "../../services/smuApi";
import type { ChangeSmuBrandsActivesSaga } from "../../actions/smuBrands";
import type { PayloadItemType } from "../../containers/SmuBrandList/types/SmuBrandList.types";

export function* makeFetch(action: ChangeSmuBrandsActivesSaga): Saga<void> {
  try {
    yield put(setLoading(SMU_BRAND_LIST_TABLE, true));

    const { isActive }: ChangeSmuBrandsActivesSaga = action;

    const selections: PayloadItemType[] = yield select(getTableSelections, SMU_BRAND_LIST_TABLE);
    const ids: string[] = selections.map(({ id }: { id: string}): string => id);

    yield call(saveBulkSmuBrandsActive, ids, isActive);

    yield put(setTableSelection(SMU_BRAND_LIST_TABLE, []));
    yield put(getSmuBrandsSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Active status change failed", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SMU_BRAND_LIST_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_SMU_BRAND_LIST_ACTIVES_SAGA, (makeFetch: Function));
}
