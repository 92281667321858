/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  IconButton,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Loader,
} from "@fas/ui-core";
import { getErrors } from "@fas/ui-framework/lib/redux/selectors/errors";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  saveCampaignSettingsSaga,
  setCampaignSettingsFormData,
  openCampaignSettingsModal,
  closeCampaignSettingsModal,
} from "../../../../../../actions/campaignSettings";
import {
  getCampaignSettingsFormData,
  getIsCampaignSettingsModalOpen,
  getLoading,
} from "../../../../../../selectors/campaignSettings";

const mapStateToProps = (state) => ({
  fields: getCampaignSettingsFormData(state),
  errors: getErrors(state).toJS(),
  isCampaignSettingsModalOpen: getIsCampaignSettingsModalOpen(state),
  isOfferSaveLoading: getLoading(state, "isOfferSaveLoading"),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onSetField: setCampaignSettingsFormData,
  onOpenFormModal: openCampaignSettingsModal,
  onCloseFormModal: closeCampaignSettingsModal,
  onEditOffer: saveCampaignSettingsSaga,
  onSetErrors: setErrors,
}, dispatch);

type Props = {
  errors: *,
  isCampaignSettingsModalOpen: boolean,
  isOfferSaveLoading: boolean,
  onSaveAffiliateLink: typeof saveAffiliateLinkSaga,
  onSetErrors: typeof setErrors,
  onCloseAffiliateLinkModal: typeof closeAffiliateLinkModal,
};

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    width: 700,
  },
  dialogContent: {
    background: "white",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
}));

const statuses = [
  {
    value: "1",
    label: "Approved",
  },
  {
    value: "0",
    label: "Pending",
  },
];

const FieldsForm = ({
  errors = {},
  fields,
  isCampaignSettingsModalOpen,
  onSetField,
  isOfferSaveLoading,
  onCloseFormModal,
  onEditOffer,
  onSetErrors,
}: Props) => {
  const classes = useStyles();

  const handleOnClose = () => {
    onSetErrors({});
    onCloseFormModal();
  };

  const handleChangeField = (e) => onSetField({
    [e.target.name]: e.target.value,
  });

  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Some text">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };

  return (
    <Dialog
      open={isCampaignSettingsModalOpen}
      maxWidth={false}
    >
      <Loader loading={isOfferSaveLoading}>
        <div className={classes.dialogWrapper}>
          <Box className={classes.title}>
            <Typography variant="h6">
              Edit offer name ID #
              {fields.offerId}
            </Typography>
            <IconButton className={classes.closeButton} onClick={handleOnClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent dividers className={classes.dialogContent}>
            <TextField
              className={classes.inputField}
              error={!!errors.postbackUrl}
              helperText={errors.postbackUrl && errors.postbackUrl.message}
              fullWidth
              inputProps={{
                style: { background: classes.input },
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              variant="outlined"
              label="Custom postback"
              name="postbackUrl"
              data-testid="postbackUrl"
              value={fields.postbackUrl}
              onChange={handleChangeField}
              placeholder="Custom postback"
              InputProps={inputPropsTextField}
            />
            {fields.conversionType === "revshare" && (
              <>
                <TextField
                  className={classes.inputField}
                  error={!!errors.payoutAmountFirst}
                  helperText={errors.payoutAmountFirst && errors.payoutAmountFirst.message}
                  fullWidth
                  inputProps={{
                    style: { background: classes.input },
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: 0, marginRight: 0 },
                  }}
                  variant="outlined"
                  label="Commission for firsts %"
                  name="payoutAmountFirst"
                  data-testid="payoutAmountFirst"
                  value={fields.payoutAmountFirst}
                  onChange={handleChangeField}
                  placeholder="Commission for firsts %"
                  InputProps={inputPropsTextField}
                />
                <TextField
                  className={classes.inputField}
                  fullWidth
                  inputProps={{
                    style: { background: classes.input },
                  }}
                  variant="outlined"
                  label="Default commission for firsts %"
                  data-testid="payoutAmountFirstOffer"
                  value={fields.payoutAmountFirstOffer === null ? "-" : fields.payoutAmountFirstOffer}
                  disabled
                  InputProps={inputPropsTextField}
                />
                <TextField
                  className={classes.inputField}
                  error={!!errors.payoutAmountRepeats}
                  helperText={errors.payoutAmountRepeats && errors.payoutAmountRepeats.message}
                  fullWidth
                  inputProps={{
                    style: { background: classes.input },
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: 0, marginRight: 0 },
                  }}
                  variant="outlined"
                  label="Commission for repeats %"
                  name="payoutAmountRepeats"
                  data-testid="payoutAmountRepeats"
                  value={fields.payoutAmountRepeats}
                  onChange={handleChangeField}
                  placeholder="Commission for repeats %"
                  InputProps={inputPropsTextField}
                />
                <TextField
                  className={classes.inputField}
                  fullWidth
                  inputProps={{
                    style: { background: classes.input },
                  }}
                  variant="outlined"
                  label="Default commission for repeats %"
                  data-testid="payoutAmountRepeatsOffer"
                  value={fields.payoutAmountRepeatsOffer === null ? "-" : fields.payoutAmountRepeatsOffer}
                  disabled
                  InputProps={inputPropsTextField}
                />
              </>
            )}
            {fields.conversionType !== "revshare" && (
              <>
                <TextField
                  className={classes.inputField}
                  error={!!errors.payoutAmount}
                  helperText={errors.payoutAmount && errors.payoutAmount.message}
                  fullWidth
                  inputProps={{
                    style: { background: classes.input },
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: 0, marginRight: 0 },
                  }}
                  variant="outlined"
                  label="Custom commission"
                  name="payoutAmount"
                  data-testid="payoutAmount"
                  value={fields.payoutAmount}
                  onChange={handleChangeField}
                  placeholder="Custom commission"
                  InputProps={inputPropsTextField}
                />
                <TextField
                  className={classes.inputField}
                  fullWidth
                  inputProps={{
                    style: { background: classes.input },
                  }}
                  variant="outlined"
                  label="Default commission"
                  data-testid="payoutAmountOffer"
                  value={fields.payoutAmountOffer === null ? "-" : fields.payoutAmountOffer}
                  disabled
                  InputProps={inputPropsTextField}
                />
              </>
            )}
            <TextField
              className={classes.inputField}
              error={!!errors.shaveAmount}
              helperText={errors.shaveAmount && errors.shaveAmount.message}
              fullWidth
              inputProps={{
                style: { background: classes.input },
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              variant="outlined"
              label="Custom shave %"
              name="shaveAmount"
              data-testid="shaveAmount"
              value={fields.shaveAmount}
              onChange={handleChangeField}
              placeholder="Custom shave %"
              InputProps={inputPropsTextField}
            />
            <TextField
              className={classes.inputField}
              fullWidth
              inputProps={{
                style: { background: classes.input },
              }}
              variant="outlined"
              label="Default shave %"
              data-testid="shaveAmountOffer"
              value={fields.shaveAmountOffer === null ? "-" : fields.shaveAmountOffer}
              disabled
              InputProps={inputPropsTextField}
            />
            <TextField
              className={classes.inputField}
              error={!!errors.ncrAmount}
              helperText={errors.ncrAmount && errors.ncrAmount.message}
              fullWidth
              inputProps={{
                style: { background: classes.input },
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
              variant="outlined"
              label="Ncr"
              name="ncrAmount"
              data-testid="ncrAmount"
              value={fields.ncrAmount}
              onChange={handleChangeField}
              placeholder="Ncr"
              InputProps={inputPropsTextField}
            />
            <Autocomplete
              className={classes.inputField}
              fullWidth
              disableClearable
              classes={{
                inputRoot: classes.input,
              }}
              options={["", "$", "%"]}
              value={fields.ncrUnit}
              data-testid="ncrUnit"
              inputValue={fields.ncrUnit}
              onChange={(e, item) => onSetField({ ncrUnit: item })}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Ncr unit"
                  variant="outlined"
                  label="Ncr unit"
                  error={!!errors.ncrUnit}
                  helperText={errors.ncrUnit && errors.ncrUnit.message}
                  InputProps={{
                    ...params.InputProps,
                    classes: { adornedStart: classes.input },
                    startAdornment: (
                      <>
                        <InputAdornment
                          className={classes.helperIcon}
                          position="start"
                        >
                          <Tooltip title="Some text">
                            <HelpOutlineIcon />
                          </Tooltip>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: 0, marginRight: 0 },
                  }}
                />
              )}
            />
            <Autocomplete
              className={classes.inputField}
              style={{ marginTop: 20, marginBottom: 10 }}
              fullWidth
              disableClearable
              classes={{
                inputRoot: classes.input,
              }}
              options={statuses}
              value={statuses.find((status) => status.value === fields.isActive)}
              data-testid="isActive"
              onChange={(e, item) => onSetField({ isActive: item.value })}
              isOptionEqualToValue={(option) => option.value === fields.isActive}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Status"
                  variant="outlined"
                  label="Status"
                  InputProps={{
                    ...params.InputProps,
                    classes: { adornedStart: classes.input },
                    startAdornment: (
                      <>
                        <InputAdornment
                          className={classes.helperIcon}
                          position="start"
                        >
                          <Tooltip title="Some text">
                            <HelpOutlineIcon />
                          </Tooltip>
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                  FormHelperTextProps={{
                    style: { marginLeft: 0, marginRight: 0 },
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onEditOffer} color="primary" data-testid="saveModalButton">
              Save changes
            </Button>
            <Button autoFocus onClick={handleOnClose} color="primary" data-testid="backModalButton">
              Close
            </Button>
          </DialogActions>
        </div>
      </Loader>
    </Dialog>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldsForm);
