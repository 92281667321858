// @flow
import environment from "environment";
import { requestService } from "../axiosRequest";

export const saveOffer = (affiliateId: string, fields: Object) => requestService
  .post(environment.endpoints.post.saveSubscription, { ...fields, affiliateId })
  .then(({ data }) => data);

export const fetchCampaignSettingsOffers = (options: Object) => requestService
  .get(environment.endpoints.get.getOffersSubscriptions, {
    params: options,
  })
  .then(({ data }) => data);

export const restoreOffer = ({ affiliateId, offerId }: { affiliateId: string, offerId: string }) => requestService
  .post(environment.endpoints.post.restoreOffer.replace("{affiliateId}", affiliateId).replace("{subscriptionId}", offerId))
  .then(({ data }) => data);
