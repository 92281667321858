// @flow
import React, { useRef, useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  Select,
  FormControlLabel,
  Switch,
  Typography,
  MenuItem,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ConfirmDialog,
} from "@fas/ui-core";
import Autocomplete from "@mui/material/Autocomplete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonalInfoTablet from "../../../PersonalInfoTablet";

type Props = {
  title: string,
  name: string,
  editTitle: string,
  currentTablet: string,
  handleChangeTablet: () => any,
  personalInfo: Object,
  lists: Array<any>,
  setAffiliateField: (string, string) => any,
  errors: Object,
  fields: Array<string>,
};

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  generalOptionsItem: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  offersApproved: {
    marginLeft: "auto",
    display: "flex",
  },
  inputSelect: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  select: {
    background: theme.palette.background.mainBg,
    width: "70px",
  },
  offersText: {
    marginLeft: 4,
    color: theme.palette.primary.main,
  },
  helperIcon: {
    marginLeft: 5,
  },
}));

const GeneralOptions = ({
  title,
  name,
  editTitle,
  currentTablet,
  personalInfo,
  lists,
  errors,
  fields,
  handleChangeTablet,
  setAffiliateField,
}: Props) => {
  const classes = useStyles();

  const shaveAmountRef = useRef(null);
  const ncrAmountRef = useRef(null);
  const [openDialogShave, setOpenDialogShave] = useState(false);
  const [tempShavedAll, setTempShavedAll] = useState(0);

  const {
    shaveAmount,
    ncrAmount,
    ncrUnit,
    isAdNetwork,
    type,
    accountId,
    offersApproved,
    isShaveIgnored,
    isShavedAll,
  } = personalInfo;
  const {
    networkAccounts = [],
  } = lists;

  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setAffiliateField(name, value);
  };
  const handleChangeSwitch = (e) => {
    const value = e.target.checked ? 1 : 0;
    setAffiliateField(e.target.name, value);
    if (!value) {
      setAffiliateField("accountId", { id: 0, name: "" });
    }
  };
  const handleNcrUnitAndAmount = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setAffiliateField(name, value);
    if (!value) {
      setAffiliateField("ncrUnit", "");
    }
  };
  const handleChangeCheckbox = (e) => {
    const value = e.target.checked ? 1 : 0;
    const { name } = e.target;
    setAffiliateField(name, value);
  };

  const handleShaveAll = (e) => {
    const checked = e.target.checked ? 1 : 0;
    const { name } = e.target;
    setOpenDialogShave(!openDialogShave);
    setTempShavedAll(checked);
  };
  const handleConfirmShaveAll = (e) => {
    setAffiliateField("isShavedAll", tempShavedAll);
    setOpenDialogShave(!openDialogShave);
  };
  const handleRejectShaveAll = (e) => {
    setOpenDialogShave(!openDialogShave);
  };

  const messageConfirm = "Are you sure that you want to set up 100% shave for all the traffic from this account?";
  const messageReject = "Are you sure that you want to disable shave for all the traffic from this account?";

  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Some text">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };

  useEffect(() => {
    if (shaveAmountRef.current) {
      shaveAmountRef.current.value = shaveAmount;
    }
    if (ncrAmountRef.current) {
      ncrAmountRef.current.value = ncrAmount;
    }
  });

  return (
    <PersonalInfoTablet
      title={title}
      name={name}
      currentTablet={currentTablet}
      editTitle={editTitle}
      errors={errors}
      fields={fields}
      handleChangeTablet={handleChangeTablet}
    >
      <TextField
        className={classes.inputField}
        error={!!errors.shaveAmount}
        helperText={errors.shaveAmount && errors.shaveAmount}
        fullWidth
        inputProps={{
          style: { background: classes.input },
        }}
        inputRef={shaveAmountRef}
        FormHelperTextProps={{
          style: { marginLeft: 0, marginRight: 0 },
        }}
        variant="outlined"
        label="Total shave (%)"
        name="shaveAmount"
        data-testid="shaveAmount"
        onBlur={handleChangeField}
        placeholder="Total shave (%)"
        InputProps={inputPropsTextField}
      />
      <div className={classes.inputSelect}>
        <TextField
          fullWidth
          error={!!errors.ncrAmount}
          helperText={errors.ncrAmount && errors.ncrAmount}
          variant="outlined"
          label="Ncr amount"
          inputProps={{
            style: { background: classes.input },
          }}
          inputRef={ncrAmountRef}
          name="ncrAmount"
          data-testid="ncrAmount"
          onBlur={handleNcrUnitAndAmount}
          placeholder="Ncr amount"
          InputProps={inputPropsTextField}
        />
        <Select
          name="ncrUnit"
          data-testid="ncrUnit"
          variant="outlined"
          className={classes.select}
          value={ncrUnit}
          disabled={!ncrAmount}
          onChange={handleChangeField}
        >
          <MenuItem value="%">%</MenuItem>
          <MenuItem value="$">$</MenuItem>
        </Select>
      </div>
      {type === "cpa" && (
        <Autocomplete
          className={classes.inputField}
          fullWidth
          classes={{
            inputRoot: classes.input,
          }}
          options={networkAccounts}
          value={accountId}
          data-testid="accountId"
          onChange={(e, item) => setAffiliateField("accountId", item)}
          disabled={!isAdNetwork}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Network account"
              variant="outlined"
              label="Network account"
              error={!!errors.accountId}
              helperText={errors.accountId && errors.accountId}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              FormHelperTextProps={{
                style: { marginLeft: 0, marginRight: 0 },
              }}
            />
          )}
        />

      )}
      <div className={classes.generalOptionsItem}>
        <FormControlLabel
          label="Ignore shave"
          data-testid="isShaveIgnored"
          control={(
            <Switch
              checked={!!isShaveIgnored}
              onChange={handleChangeCheckbox}
              name="isShaveIgnored"
              color="primary"
            />
          )}
        />
      </div>
      <div className={classes.generalOptionsItem}>
        <FormControlLabel
          label="Shave all"
          data-testid="isShavedAll"
          control={(
            <Switch
              checked={!!isShavedAll}
              onChange={handleShaveAll}
              name="isShavedAll"
              color="primary"
            />
          )}
        />
      </div>
      <ConfirmDialog
        open={openDialogShave}
        title="Confirm shave all"
        message={!isShavedAll ? messageConfirm : messageReject}
        onMainBtnClick={handleConfirmShaveAll}
        onSecondaryBtnClick={handleRejectShaveAll}
      />
      <div
        className={classes.generalOptionsItem}
      >
        <FormControlLabel
          control={(
            <Switch
              name="isAdNetwork"
              color="primary"
              checked={!!isAdNetwork}
              onChange={handleChangeSwitch}
            />
          )}
          label="Is ad network"
          data-testid="isAdNetwork"
        />
        <div
          className={classes.offersApproved}
        >
          <Typography>
            Offers approved:
          </Typography>
          <Typography
            className={classes.offersText}
          >
            {offersApproved}
          </Typography>
        </div>
      </div>
    </PersonalInfoTablet>
  );
};

export default GeneralOptions;
