// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { selectPostbackInfoField } from "../../../../../../selectors/manageAffiliate";
import { setPostbackField } from "../../../../../../actions/postbacks";
import type { PostbackInputClasses } from "./types/PostbackInfoMain.types";

type Props = {
  field: string,
};

type Fields = "defaultPostback" | "affiliatePostbackLink" | "affiliatePostbackLinkGf" | "affiliatePostbackLinkLpFilter";

type UsePostbackInput = {
  postback: string,
  onChange: (string) => mixed,
};

const fieldToLabel: { [Fields]: string } = {
  defaultPostback: "Default postback",
  affiliatePostbackLink: "Bo, so, webpush postback",
  affiliatePostbackLinkGf: "GF postback",
  affiliatePostbackLinkLpFilter: "LP filter postback",
};

const fieldToTooltip: { [Fields]: string } = {
  defaultPostback: "",
  affiliatePostbackLink: "",
  affiliatePostbackLinkGf: "",
  affiliatePostbackLinkLpFilter: "",
};

const useStyles: () => PostbackInputClasses = makeStyles((theme): PostbackInputClasses => ({
  inputField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  input: {
    background: theme.palette.background.mainBg,
  },
}));

const usePostbackInput: () => UsePostbackInput = (field) => {
  const dispatch: <A>(A) => A = useDispatch();
  const postback: string = useSelector((state) => selectPostbackInfoField(state, field));

  const onChange: (string) => mixed = (value) => dispatch(setPostbackField({ field, value }));

  return { postback, onChange };
};

const PostbackInput: StatelessFunctionalComponent<Props> = ({ field }) => {
  const classes: PostbackInputClasses = useStyles();
  const { postback, onChange }: UsePostbackInput = usePostbackInput(field);

  return (
    <TextField
      fullWidth
      className={classes.inputField}
      value={postback}
      variant="outlined"
      label={fieldToLabel[field]}
      placeholder={fieldToLabel[field]}
      name={field}
      data-testid={field}
      onChange={(e): mixed => onChange(e.target.value)}
      InputProps={{
        classes: { adornedStart: classes.input },
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={fieldToTooltip[field]}>
              <HelpOutlineIcon />
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PostbackInput;
