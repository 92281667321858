// @flow
import React, { useCallback } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import type { FieldsType, FiltersStateType } from "./SubratesFilterModal";

type Props = {
  filters: FiltersStateType,
  filtersTitles: { [string]: string },
  handleChangeFilter: ({ field: string, value: boolean }) => void,
  fields: FieldsType,
}

const SubratesFilterContent = (props: Props) => {
  const {
    handleChangeFilter, filters, fields, filtersTitles,
  }: Props = props;
  const changeSwitcher: (
    e: SyntheticInputEvent<HTMLInputElement>) => void = useCallback((e: SyntheticInputEvent<HTMLInputElement>) => {
      handleChangeFilter({ field: e.target.name, value: e.target.checked });
    }, [handleChangeFilter]);

  return (
    <>
      {Object.keys(filters).map((key) => (
        <div key={key}>
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                checked={filters[key]}
                onClick={changeSwitcher}
                name={key}
                data-testid={key}
                // $FlowFixMe
                disabled={fields[key] && Boolean(fields[key].condition)}
              />
            )}
            label={`Empty ${filtersTitles[key] || key}`}
          />
        </div>
      ))}
    </>
  );
};

export default SubratesFilterContent;
