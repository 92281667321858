// @flow
import {
  SET_SORT_EVENTS_POSTBACK,
  SET_FILTER_EVENTS_POSTBACK,
  DELETE_FILTER_EVENTS_POSTBACK,
  CLEANUP_TABLE_EVENTS_POSTBACK,
  SET_SELECTED_ROWS_EVENTS_POSTBACK,
  SET_EVENTS_POSTBACK_LIST,
  GET_POSTBACK_EVENTS_SAGA,
  SAVE_POSTBACK_EVENT_SAGA,
  DELETE_POSTBACK_EVENT_SAGA,
  CHANGE_EVENT_POSTBACK_MODAL_OPEN,
  CHANGE_CURRENT_EVENT_POSTBACK,
} from "../../helpers/constants/EventsPostback";
import type {
  SortType,
  FilterType,
  SelectedRowsType,
} from "../../reducers/manageAffiliate";

// Types
export type SetSortAction = {
  type: typeof SET_SORT_EVENTS_POSTBACK,
  value: SortType,
};
export type SetFilterAction = {
  type: typeof SET_FILTER_EVENTS_POSTBACK,
  value: FilterType,
};
export type DeleteFilterAction = {
  type: typeof DELETE_FILTER_EVENTS_POSTBACK,
  field: string,
};
export type SetSelectedRowsAction = {
  type: typeof SET_SELECTED_ROWS_EVENTS_POSTBACK,
  value: SelectedRowsType,
};
export type CleanupTableAction = {
  type: typeof CLEANUP_TABLE_EVENTS_POSTBACK,
};
export type SetEventsPostbackListAction = {
  type: typeof SET_EVENTS_POSTBACK_LIST,
  value: Array<Object>,
};

export type GetPostbackEventsSaga = {
  type: typeof GET_POSTBACK_EVENTS_SAGA,
};

export type SavePostbackEventSaga = {
  type: typeof SAVE_POSTBACK_EVENT_SAGA,
  fields: { [string]: string }
}

export type DeletePostbackEventSaga = {
  type: typeof DELETE_POSTBACK_EVENT_SAGA,
  eventId: string,
}

export type OpenEventPostbackModal = {
  type: typeof CHANGE_EVENT_POSTBACK_MODAL_OPEN,
  value: boolean,
}

export type ChangeCurrentEventPostback = {
  type: typeof CHANGE_CURRENT_EVENT_POSTBACK,
  payload: *,
}

// Actions
export const setSort = (
  value: SortType
): SetSortAction => ({
  type: SET_SORT_EVENTS_POSTBACK,
  value,
});
export const setFilter = (
  value: FilterType
): SetFilterAction => ({
  type: SET_FILTER_EVENTS_POSTBACK,
  value,
});
export const deleteFilter = (
  field: string
): DeleteFilterAction => ({
  type: DELETE_FILTER_EVENTS_POSTBACK,
  field,
});
export const setSelectedRows = (
  value: SelectedRowsType
): SetSelectedRowsAction => ({
  type: SET_SELECTED_ROWS_EVENTS_POSTBACK,
  value,
});
export const cleanupTable = (): CleanupTableAction => ({
  type: CLEANUP_TABLE_EVENTS_POSTBACK,
});
export const setEventsList = (
  value: Array<Object>
): SetEventsPostbackListAction => ({
  type: SET_EVENTS_POSTBACK_LIST,
  value,
});

export const getPostbackEventsSaga: () => GetPostbackEventsSaga = () => ({
  type: GET_POSTBACK_EVENTS_SAGA,
});

export const savePostbackEventSaga: ({ [string]: string }) => SavePostbackEventSaga = (fields) => ({
  type: SAVE_POSTBACK_EVENT_SAGA,
  fields,
})

export const deletePostbackEventSaga: (string) => DeletePostbackEventSaga = (eventId) => ({
  type: DELETE_POSTBACK_EVENT_SAGA,
  eventId,
});

export const openEventPostbackModal: (boolean) => OpenEventPostbackModal = (value) => ({
  type: CHANGE_EVENT_POSTBACK_MODAL_OPEN,
  value,
});

export const changeCurrentEventPostback: (val: *) => ChangeCurrentEventPostback = (val) => ({
  type: CHANGE_CURRENT_EVENT_POSTBACK,
  payload: val,
});
