// @flow
import type { Node } from "react";
import React from "react";
import type { Theme } from "@mui/material";
import { FormControl, FormHelperText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DropzoneArea } from "@fas/ui-core";
import type { Props as DropzoneAreaProps } from "@fas/ui-core/lib/DropzoneArea/DropzoneArea.types";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";

type Classes = {
  dropzone: string,
  disabled: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): * => ({
  dropzone: {
    minHeight: "auto",
    height: "100px",
    marginBottom: theme.spacing(4),
    borderRadius: theme.borderRadius,
    borderColor: theme.palette.borderColor,
    borderWidth: "1px",
    background: theme.palette.background.mainBg,
  },
  disabled: {
    backgroundColor: "#D4D9E6",
    color: "#8D96AE",
    opacity: 0.5,
  },
}));

export type Props = DropzoneAreaProps & {
  name: string,
  error: string,
  value: *,
  onChange: (*) => *,
  dropzoneText?: string,
  showRemoveButton?: boolean,
  showPreviewList?: boolean,
  showFileName?: boolean,
  maxFiles?: number,
  acceptedFiles?: string[],
  classes?: {
    dropzone?: string;
    alert?: string;
    fileList?: string;
    file?: string;
    avatarWrapper?: string;
    avatar?: string;
    fileName?: string;
    deleteButton?: string;
  }
}

function DropzoneAreaForm({
  error,
  value,
  onChange,
  acceptedFiles,
  dropzoneText,
  disabled,
  maxFiles,
  showRemoveButton,
  showPreviewList,
  showFileName,
  ...dropzoneOptions
}: Props): Node {
  const classes: Classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined" disabled>
      <DropzoneArea
        {...dropzoneOptions}
        disabled={disabled}
        classes={{
          dropzone: `${classes.dropzone} ${disabled ? classes.disabled : ""}`,
        }}
        dropzoneText={dropzoneText}
        initialFiles={value ? [value] : []}
        maxFiles={maxFiles}
        accept={acceptedFiles}
        onChange={(files) => {
          const [file = null] = files;
          onChange(maxFiles === 1 ? file : files);
        }}
        showRemoveButton={showRemoveButton}
        showPreviewList={showPreviewList}
        showFileName={showFileName}
      />
      <FormHelperText error>
        {error}
      </FormHelperText>
    </FormControl>
  );
}

DropzoneAreaForm.defaultProps = {
  onChange: () => {},
  error: "",
  value: "",
  maxFiles: 1,
  maxSize: 1024 * 1024 * 5,
  disabled: false,
  showRemoveButton: false,
  showPreviewList: true,
  showFileName: true,
  acceptedFiles: ["image/jpeg", "image/png"],
  dropzoneText: "Drag and drop a file here or click",
  initialFiles: [],
};

export default withFormField(DropzoneAreaForm);
