// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import SmuDepartmentsContainer from "../../containers/SmuDepartmentsList";

function SmuDepartments() {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="SMU - Departments">
      <Provider store={store}>
        <NotificationsContainer />
        <SmuDepartmentsContainer />
      </Provider>
    </DocumentTitle>
  );
}

export default SmuDepartments;
