// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { GET_PERSONAL_INFO_LISTS_SAGA } from "../../helpers/constants/manageAffiliate";
import { getPersonalInfoLists } from "../../services/manageAffiliateApi";
import { type GetPersonalInfoListsSaga, setLists, setLoader } from "../../actions/manageAffiliate";

export function* makeFetch({ affId }: GetPersonalInfoListsSaga): Saga<void> {
  try {
    yield put(setLoading("getPersonalInfoList", true));
    const { data }: { data: * } = yield call(getPersonalInfoLists, affId);
    yield put(setLists(data.lists));
    yield put(setLoader({
      key: "isListsLoaded",
      value: true,
    })); // need remove this after refactor
    yield put(setLoading("getPersonalInfoList", false));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    yield put(setLoader({
      key: "isListsLoaded",
      value: true,
    })); // need remove this after refactor
    yield put(setLoading("getPersonalInfoList", false));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetPersonalInfoLists(): Saga<void> {
  yield takeEvery(GET_PERSONAL_INFO_LISTS_SAGA, (makeFetch: Function));
}
