// @flow
export const SET_SORT_POSTBACKS: "SET_SORT_POSTBACKS" = "SET_SORT_POSTBACKS";
export const SET_FILTER_POSTBACKS: "SET_FILTER_POSTBACKS" = "SET_FILTER_POSTBACKS";
export const DELETE_FILTER_POSTBACKS: "DELETE_FILTER_POSTBACKS" = "DELETE_FILTER_POSTBACKS";
export const CLEANUP_TABLE_POSTBACKS: "CLEANUP_TABLE_POSTBACKS" = "CLEANUP_TABLE_POSTBACKS";
export const SET_SELECTED_ROWS_POSTBACKS: "SET_SELECTED_ROWS_POSTBACKS" = "SET_SELECTED_ROWS_POSTBACKS";
export const SET_POSTBACKS_LIST: "SET_POSTBACKS_LIST" = "SET_POSTBACKS_LIST";
export const SET_LISTS_POSBACKS: "SET_LISTS_POSBACKS" = "SET_LISTS_POSBACKS";
export const SET_FIELDS_POSTBACKS: "SET_FIELDS_POSTBACKS" = "SET_FIELDS_POSTBACKS";
export const SET_FIELD_POSTBACKS: "SET_FIELD_POSTBACKS" = "SET_FIELD_POSTBACKS";
export const GET_POSTBACK_INFO_SAGA: "GET_POSTBACK_INFO_SAGA" = "GET_POSTBACK_INFO_SAGA";
export const GET_LISTS_SAGA: "GET_LISTS_SAGA" = "GET_LISTS_SAGA";
export const CHANGE_POSTBACK_STATUS_SAGA: "CHANGE_POSTBACK_STATUS_SAGA" = "CHANGE_POSTBACK_STATUS_SAGA";
export const SAVE_POSTBACK_INFO_SAGA: "SAVE_POSTBACK_INFO_SAGA" = "SAVE_POSTBACK_INFO_SAGA";
export const SAVE_ADDITIONAL_POSTBACK_SAGA: "SAVE_ADDITIONAL_POSTBACK_SAGA" = "SAVE_ADDITIONAL_POSTBACK_SAGA";
export const CHANGE_CURRENT_ADDITIONAL_POSTBACK: "CHANGE_CURRENT_ADDITIONAL_POSTBACK" = "CHANGE_CURRENT_ADDITIONAL_POSTBACK";
export const CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN: "CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN" = "CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN";

export const ADDITIONAL_POSTBACKS_TABLE: "ADDITIONAL_POSTBACKS_TABLE" = "ADDITIONAL_POSTBACKS_TABLE";
