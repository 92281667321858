// @flow

export const getFormErrors = (data: Array<{field: string, error: string}>): { [string]: string } => {
  const errors = {};

  data.forEach((item) => {
    errors[item.field] = item.error;
  });

  return errors;
};

export type Errors = Array<{field: string, error: string}>

export const checkForm = (data: Errors): { generalOptions: Errors, offerClass: Errors, targetings: Errors, paymentSettings: Errors } => ({
  generalOptions: data.filter((item) => [
    "department",
    "isExt",
    "name",
    "isPublic",
    "isAvailableForAllGroups",
    "conversionType",
    "globalCap",
    "globalCapLogic",
    "offerType",
    "location",
    "partner",
    "account",
    "timeout",
    "readyForGdpr",
    "isTest",
    "isActive",
    "description",
    "restriction",
    "blackList",
    "globalCapDistributionMode",
    "adverIDAttr",
    "adverIDValue",
    "stream",
    "campaignID"
  ].includes(item.field)),
  offerClass: data.filter((item) => [
    "class",
    "offerOS",
    "group",
    "offerName",
    "vertical",
    "niche",
    "extNiche",
    "extOfferGroup",
    "siteId"
  ].includes(item.field)),
  targetings: data.filter((item) => [
    "country",
    "platform",
    "gender",
  ].includes(item.field)),
  paymentSettings: data.filter((item) => [
    "paymentModel",
    "currency",
    "payoutAmount",
    "payoutAmountFirst",
    "payoutAmountRepeats",
    "shaveAmount",
    "ncrUnit",
    "ncrAmount",
    "nextPartnerCommission",
  ].includes(item.field)),
});
