// @flow
import { useDispatch, useSelector } from "react-redux";
import { getIsChangeAuthorModalOpened, getIsManagersListLoaded, getManagersList } from "../../selectors/cpaOfferIndex";
import { changeAuthorSaga, getManagersListSaga, switchChangeAuthorModalOpened } from "../../actions/cpaoffer";
import type { Manager, ManagerList } from "../../actions/cpaoffer";
import { userService } from "../../services/user";

export type ChangeManagerModalType = {
  isChangeAuthorModalOpened: boolean,
  closeChangeAuthorModal: () => void,
  changeAuthorAction: (selectedUser: Manager) => void,
  managersList: ManagerList,
  isManagersListLoaded: boolean,
  openChangeAuthorModal: () => mixed,
}

// eslint-disable-next-line import/prefer-default-export
export function useChangeManagerModal(): ChangeManagerModalType {
  const dispatch: <A>(A) => A = useDispatch();
  const isChangeAuthorModalOpened: boolean = useSelector(getIsChangeAuthorModalOpened);
  const managersList: ManagerList = useSelector(getManagersList);
  const isManagersListLoaded: boolean = useSelector(getIsManagersListLoaded);

  function closeChangeAuthorModal() {
    dispatch(switchChangeAuthorModalOpened());
  }

  function changeAuthorAction(selectedUser: Manager) {
    dispatch(changeAuthorSaga(selectedUser));
  }

  function openChangeAuthorModal() {
    const group: any = userService.getMarketingGroupInfo();
    dispatch(getManagersListSaga(group));
    dispatch(switchChangeAuthorModalOpened());
  }

  return {
    isChangeAuthorModalOpened,
    managersList,
    isManagersListLoaded,
    closeChangeAuthorModal,
    openChangeAuthorModal,
    changeAuthorAction,
  };
}
