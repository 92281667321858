// @flow
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { changeTableItemsTotalAmount, setTableData, setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { GET_CPA_OFFER_CAMPAIGNS_SAGA, CPA_OFFER_CAMPAIGNS_TABLE } from "../../helpers/constants/cpaoffer";
import {
  type CpaOfferCampaignData,
  type CpaOfferCampaignsProps,
  getCpaOffersCampaigns,
  type Headers,
} from "../../services/cpaOffersApi/cpaOffers";
import type { Filters } from "../../pages/CpaOfferCampaigns/store";

// eslint-disable-next-line complexity
export function* getCpaOfferCampaignsList(): Saga<void> {
  const search = new URLSearchParams(window.location.search);
  const cpaOfferId: string | null = search.get("id");
  const additionalFilter: Array<string> = cpaOfferId ? cpaOfferId.split(",") : [];
  if (!additionalFilter.length) {
    return;
  }
  try {
    yield put(setLoading(CPA_OFFER_CAMPAIGNS_TABLE, true));

    const page: number = yield select(getTablePage, CPA_OFFER_CAMPAIGNS_TABLE);
    const limit: number = yield select(getTablePageSize, CPA_OFFER_CAMPAIGNS_TABLE);
    const { offerId, ...filters }: Filters = yield select(getTableFilters, CPA_OFFER_CAMPAIGNS_TABLE);
    const sorting: Sorting = yield select(getTableSorting, CPA_OFFER_CAMPAIGNS_TABLE);

    const headers: Array<Headers> = [
      "offerId",
      "offerName",
      "campaignId",
      "campaignName",
      "funnelName",
      "created",
      "split",
      "campaignStatus",
      "offerStatus",
      "offerInCampaignStatus",
    ];

    const stringFilters: string[] = [
      "split",
      "campaignStatus",
      "offerStatus",
      "offerInCampaignStatus",
      "createdBy",
    ];

    const formatedFilters = {};

    for (const key: string in filters) {
      if (filters[key] && filters[key].length) {
        if (stringFilters.includes(key) && filters[key].length) { // format for filters which should be string
          formatedFilters[key] = filters[key][0].value;
        }
        else if (Array.isArray(filters[key])) { // format filters from [{label, value}] to string[]
          formatedFilters[key] = filters[key]
            .map((i: { label: string, value: string | number }): string | number => {
              if (typeof i === "object") {
                return i.value;
              }
              return i;
            });
        }
        else {
          formatedFilters[key] = filters[key][0].value;
        }
      }
    }

    const params: CpaOfferCampaignsProps = {
      filters: {
        offerId: offerId ? offerId.map((i) => i.value) : additionalFilter, // rewrite offerId field if have custom filter
        ...formatedFilters,
      },
      limit,
      page,
      headers,
      sorting: {},
    };
    if (sorting && Object.keys(sorting).length) {
      params.sorting = {
        current: Object.keys(sorting)[0],
        direction: sorting[Object.keys(sorting)[0]],
      };
    }

    const { data }: {
      data: {
        data: Array<CpaOfferCampaignData>,
        count: number,
        status: number,
      }
    } = yield call(getCpaOffersCampaigns, params);

    yield put(setTableData(CPA_OFFER_CAMPAIGNS_TABLE, data.data));
    yield put(setTableSelection(CPA_OFFER_CAMPAIGNS_TABLE, []));
    yield put(changeTableItemsTotalAmount(CPA_OFFER_CAMPAIGNS_TABLE, data.count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Error while fetching data", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_OFFER_CAMPAIGNS_TABLE, false));
  }
}

export default function* watchGetCpaOfferCampaignsList(): Saga<void> {
  yield takeEvery(GET_CPA_OFFER_CAMPAIGNS_SAGA, (getCpaOfferCampaignsList: Function));
}
