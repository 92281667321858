// @flow
import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

type Props = {
  value: number,
};

const useStyles = makeStyles(() => ({
  textApproved: {
    color: "#2EB000",
  },
  textPending: {
    color: "#C80022",
  },
}));

const StatusCell = ({
  value = 1,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(value === 1 ? classes.textApproved : classes.textPending)}
    >
      {value === 1 ? "Approved" : "Pending"}
    </Typography>
  );
};

export default StatusCell;
