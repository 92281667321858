// @flow
import {
  select,
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { SAVE_ADDITIONAL_POSTBACK_SAGA } from "../../helpers/constants/postbacks";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";
import { saveAdditionalPostback } from "../../services/manageAffiliateApi";
import { sanitizeFields } from "../../actions/postbacks/utils";
import {
  changeCurrentAdditionalPostback,
  changeIsAddAdditionalPostbackModalOpen, getPostbacksInfoSaga,
} from "../../actions/postbacks";
import type { SaveAdditionalPostbackSaga } from "../../actions/postbacks";

export function* makeFetch(action: SaveAdditionalPostbackSaga): Saga<void> {
  const affiliateId = yield select(getManageAffiliateId);
  const { fields }: SaveAdditionalPostbackSaga = action;

  try {
    yield call(saveAdditionalPostback, affiliateId, sanitizeFields(fields));
    yield put(changeCurrentAdditionalPostback(null));
    yield put(changeIsAddAdditionalPostbackModalOpen(false));
    yield put(addNotification({ message: "Postback saved", severity: "success" }));
    yield put(getPostbacksInfoSaga());
  }
  catch (error) {
    yield put(setErrors(prepareFetchErrors(error.response.data.errors || [])));
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(SAVE_ADDITIONAL_POSTBACK_SAGA, (makeFetch: Function));
}
