// @flow
import React, { type StatelessFunctionalComponent, type Element, useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useActionButtonStyles } from "@fas/ui-core/lib/TableActions/TableActions";
import type { TableActionButtonClasses, UseState, Item } from "@fas/ui-core/lib/TableActions/TableActions.types";
import ConfirmActionModal from "../SubrateInfoConfirmActionModal";

type Props = {
  items: Item[],
  tooltip: string,
  disabled?: boolean,
  onClick: () => mixed,
  Icon: Element<*>,
};

const SubrateInfoConfirmActionButton: StatelessFunctionalComponent<Props> = ({
  items,
  tooltip,
  disabled = false,
  onClick,
  Icon,
}) => {
  const classes: TableActionButtonClasses = useActionButtonStyles();
  const [isConfirmModalOpen, setIsConfirmModalOpen]: UseState<boolean> = useState(false);

  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            color="inherit"
            disabled={disabled}
            onClick={(): void => setIsConfirmModalOpen(true)}
            className={classes.button}
            data-testid={`table-actions-${tooltip}-button`}
          >
            {Icon}
          </IconButton>
        </span>
      </Tooltip>
      <ConfirmActionModal
        items={items}
        open={isConfirmModalOpen}
        onClose={(): void => setIsConfirmModalOpen(false)}
        onApply={onClick}
      />
    </>
  );
};

export default SubrateInfoConfirmActionButton;
