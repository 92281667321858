// @flow
import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

type Props = {
  open: boolean,
  text: string,
  severity: string,
  closeMessage: () => void,
};

const MessageInfo = ({
  open, text, severity, closeMessage,
}: Props) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeMessage();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {text}
      </MuiAlert>
    </Snackbar>
  );
};

export default MessageInfo;
