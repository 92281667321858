// @flow
import { List, Record, Map } from "immutable";
import type { RecordFactory, RecordOf } from "immutable";
import {
  SET_SORT_DASHBOARD,
  SET_FILTER_DASHBOARD,
  DELETE_FILTER_DASHBOARD,
  SET_SELECTED_ROWS_DASHBOARD,
  CLEANUP_TABLE_DASHBOARD,
  SET_FETCH_DATA,
} from "../../helpers/constants/dashboard";
import type { Actions as DashboardActions } from "../../actions/dashboard";

export type SortType = {
  field: string,
  direction: string,
};

export type FilterType = {
  field: string,
  filter: string,
};

export type SelectedRowsType = Array<number | string>;

type IsActiveType = {
  id: string,
  isActive: number,
};
type IsActivesType = List<IsActiveType>;
export type TableDataType = {
  point: string,
  today: number,
  yesterday: number,
  lastSevenDays: number,
  thisMonth: number,
  lastMonth: number,
  allTime: string,
};

export type ChartDataType = {
  name: string,
  uv: number,
  pv: number,
  amt: number,
  cnt: number,
};

export type Dashboard = {
  sorting: RecordOf<SortType>,
  filters: Map<string, string>,
  selectedRows: List<number | string>,
  isActives: IsActivesType,
  tableRows: List<TableDataType>,
  chartRows: List<ChartDataType>,
  summaryBoard: List<string>,
  managers: List<{id: number, name: string}>,
};

export const makeSortingState: RecordFactory<SortType> = Record({
  field: "",
  direction: "",
});

const defaultState: Dashboard = {
  sorting: makeSortingState(),
  filters: Map({
    type: "all",
    managerId: "all",
  }),
  selectedRows: List(),
  isActives: List(),
  tableRows: List(),
  chartRows: List(),
  summaryBoard: List(),
  managers: List(),
}

export const makeState: RecordFactory<Dashboard> = Record(defaultState);
export type State = RecordOf<Dashboard>;
export type Action = DashboardActions;

export const initState = (): State => makeState({});

export const initialState: State = makeState({});

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_SORT_DASHBOARD: {
      const { field, direction } = action.value;
      return state.merge({
        sorting: makeSortingState({
          field,
          direction,
        }),
      });
    }
    case SET_FILTER_DASHBOARD: {
      const { field, filter } = action.value;
      return state.setIn(["filters", field], filter);
    }
    case DELETE_FILTER_DASHBOARD: {
      return state.deleteIn(["filters", action.field]);
    }
    case SET_SELECTED_ROWS_DASHBOARD: {
      return state.setIn(["selectedRows"], List(action.value));
    }
    case CLEANUP_TABLE_DASHBOARD: {
      return initialState;
    }
    case SET_FETCH_DATA: {
      return state
        .set("tableRows", List(action.value.data.rows))
        .set("chartRows", List(action.value.data.chart))
        .set("summaryBoard", List(action.value.data.summaryBoard))
        .set("managers", List(action.value.data.managers));
    }
    default:
      return state;
  }
};
