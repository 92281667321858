// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CHANGE_POSTBACK_STATUS_SAGA } from "../../helpers/constants/postbacks";
import { getPostbacksInfoSaga } from "../../actions/postbacks";
import type { ChangePostbackStatusSaga } from "../../actions/postbacks";
import { changeStatusPostback } from "../../services/manageAffiliateApi";

export function* makeFetch(action: ChangePostbackStatusSaga): Saga<void> {
  const { data }: ChangePostbackStatusSaga = action;
  try {
    yield call(changeStatusPostback, data);
    yield put(getPostbacksInfoSaga());
    yield put(addNotification({ message: "Status succesfully changed", severity: "success" }));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watch(): Saga<void> {
  // todo add event type here
  yield takeEvery(CHANGE_POSTBACK_STATUS_SAGA, (makeFetch: Function));
}
