// @flow
import type { Map as MapType, List as ListType } from "immutable";
import moment from "moment";
import { Map, List, fromJS } from "immutable";
import {
  SET_SORT_MANAGE_AFFILIATE,
  SET_FILTER_MANAGE_AFFILIATE,
  DELETE_FILTER_MANAGE_AFFILIATE,
  SET_SELECTED_ROWS_MANAGE_AFFILIATE,
  CLEANUP_TABLE_MANAGE_AFFILIATE,
  GET_MANAGE_AFFILIATE_DATA,
  SET_FILTERS_MANAGE_AFFILIATES,
  SET_PERSONAL_INFO_FIELD,
  CLEAR_PERSONAL_INFO,
  GET_PERSONAL_INFO_LISTS,
  SET_ERRORS_PERSONAL_INFO,
  CLEAR_ERRORS_PERSONAL_INFO,
  SET_MANAGE_AFFILIATE_ID,
  SET_MANAGE_AFFILIATE_CURRENT_TABLET,
  SET_PERSONAL_INFO,
  ADD_IS_ACTIVE_MANAGE_AFFILIATE,
  REMOVE_IS_ACTIVE_MANAGE_AFFILIATE,
  CLEAR_IS_ACTIVES_MANAGE_AFFILIATE,
  SET_LOADER_MANAGE_AFFILIATE,
  SET_PAYMENT_INFO_FIELD,
  CLEAR_PAYMENT_INFO,
  SET_ERRORS_PAYMENT_INFO,
  CLEAR_ERRORS_PAYMENT_INFO,
  SET_PAYMENT_INFO,
  IS_PERSONAL_INFO_FETCHED,
  SET_TEMPLATE_HEADERS,
  SET_TEMPLATES_LIST,
  SET_MESSAGES,
  SET_FETCHED_MANAGERS,
  SWITCH_CHANGE_AUTHOR_MODAL_OPENED,
  SWITCH_CHANGE_STATUS_MODAL_OPENED,
  CHANGE_IS_MANAGER_LIST_LOADED,
  SET_MANAGERS_LIST,
  SET_AFF_TYPE,
  SET_DICTIONARY_LIST,
  INCREMENT_SUBMIT_COUNT,
} from "../../helpers/constants/manageAffiliate";
import {
  SET_SORT_CAMPAIGN_SETTINGS,
  SET_FILTER_CAMPAIGN_SETTINGS,
  DELETE_FILTER_CAMPAIGN_SETTINGS,
  CLEANUP_TABLE_CAMPAIGN_SETTINGS,
  SET_SELECTED_ROWS_CAMPAIGN_SETTINGS,
  SET_OFFERS,
  SET_CAMPAIGN_SETTINGS_FORM_DATA,
  OPEN_CAMPAIGN_SETTINGS_FORM_MODAL,
  CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL,
} from "../../helpers/constants/campaignSettings";
import {
  SET_AFFILIATE_LINKS,
  OPEN_AFFILIATE_LINK_MODAL,
  CLOSE_AFFILIATE_LINK_MODAL,
  SET_AFFILIATE_LINK_FORM_DATA,
} from "../../helpers/constants/affiliateLinks";
import {
  SET_SORT_EVENTS_POSTBACK,
  SET_FILTER_EVENTS_POSTBACK,
  DELETE_FILTER_EVENTS_POSTBACK,
  CLEANUP_TABLE_EVENTS_POSTBACK,
  SET_SELECTED_ROWS_EVENTS_POSTBACK,
  SET_EVENTS_POSTBACK_LIST,
  CHANGE_EVENT_POSTBACK_MODAL_OPEN, CHANGE_CURRENT_EVENT_POSTBACK,
} from "../../helpers/constants/EventsPostback";
import {
  SET_SORT_POSTBACKS,
  SET_FILTER_POSTBACKS,
  DELETE_FILTER_POSTBACKS,
  CLEANUP_TABLE_POSTBACKS,
  SET_SELECTED_ROWS_POSTBACKS,
  SET_POSTBACKS_LIST,
  SET_LISTS_POSBACKS,
  SET_FIELDS_POSTBACKS,
  SET_FIELD_POSTBACKS,
  CHANGE_CURRENT_ADDITIONAL_POSTBACK,
  CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN,
} from "../../helpers/constants/postbacks";
import {
  SET_API_FIELDS,
  SET_API_FIELD,
} from "../../helpers/constants/apiPage";
import type { Manager } from "../../actions/manageAffiliate/types";

export type SortType = {
  field: string,
  direction: string,
};
export type FilterType = {
  field: string,
  filter: string,
};
export type SelectedRowsType = List<number | string>;
export type IsActiveType = {
  id: string,
  isActive: number,
};
export type IsActivesType = List<IsActiveType>;

export type AffiliateType = {
  id: string,
  name: string,
  affiliateGroup: string,
  firstName: string,
  lastName: string,
  email: string,
  accountId: string,
  status: string,
  imType: string,
  imName: string,
  phone: string,
  companyUrl: string,
  address: string,
  city: string,
  zip: string,
  state: string,
  country: string,
  taxSSN: string,
  countryByIp: string,
  manager: string,
  revenue: string,
  howFoundOut: string,
  refSrc: string,
  trafficType: Array<string>,
  topVerticals: string,
  companyName: string,
  declineReasonId: string,
  declineReasonDescription: string,
  topCountries: string,
  type: string,
  currency: string,
  isActive: number,
  shaveAmount: string,
  ncrAmount: string,
  isAdNetwork: number,
  offersApproved: number,
  isShaveIgnored: number,
  refSrcComment: string,
  refererUrl: string,
  registrationUrl: string,
  sourceComplianceStatus: boolean,
  parentAffIdPayment: string,
  childAffiliates: string[],
};
export type AffiliateFieldType = {
  field: string,
  value: string,
};
export type PersonalInfoListsType = {
  countries: ListType<>,
  declineReasons: ListType<>,
  managers: ListType<>,
  statuses: ListType<>,
  ncrUnits: ListType<>,
  paymentMethods: ListType<>,
  networkAccounts: ListType<>,
  messengers: ListType<>,
  revenues: ListType<>,
  trafficTypes: ListType<>,
  affiliateTypes: ListType<>,
  refSrc: ListType<>,
  platforms: ListType<>,
  currencyType: ListType<>,
  paymentsType: ListType<>,
  topVerticals: ListType<>,
};

export type Loaders = {
  isAffiliatesLoaded: boolean,
  isStatusChanged: boolean,
  isListsLoaded: boolean,
  isManagersListsLoaded: boolean,
  isFiltersLoaded: boolean,
  isAffiliateLinksLoaded: boolean,
};

export type ManageAffiliate = {
  sorting: MapType<SortType>,
  filters: MapType<FilterType>,
  selectedRows: SelectedRowsType,
  isActives: IsActivesType,
  personalInfo: MapType<AffiliateType>,
  lists: MapType<PersonalInfoListsType>,
  errors: MapType<Object>,
  messages: MapType<{status: string, msg: string}>,
  affiliateId: string,
  affiliateCurrentTablet: string,
  loaders: MapType<Loaders>,
  isChangeAuthorModalOpened: boolean,
  isChangeStatusModalOpened: boolean,
  managersList: Manager[],
  isManagersListLoaded: boolean,
  submitCount: number,
};
export type PaymentInfoType = {
  paymentMethodId: number,
  paymentsType: string,
  paymentAvailable: string,
  paymentComment: string,
  paymentMinAmount: number,
  payoutCountry: number,
  beCountry: Object,
  beName: string,
  beAddress: string,
  bankAccountName: string,
  bankName: string,
  bankAddress: string,
  swiftCode: string,
  accountNumber: string,
  iban: string,
  vatNumber: string,
  paymentNote: string,
  eWallet: string,
  qiwi: string,
  webmoneyWallet: string,
  yandex: string,
  bitcoin: string,
  usdt: string,
  paxumMail: string,
  capitalistWallet: string,
  payoneerWallet: string,
  genome: string,
  paysera: string,
  isDataChanged: boolean,
  isVerified: number,
  id: number,
};

export type TemplateHeaders = MapType<{
  id: string | null,
  name: string,
  headersOrder: Array<string>
}>;

export type TemplateList = ListType<{
  id: string,
  name: string,
  author: string,
  department: string,
}>;

export type TableType = {
  sorting: MapType<string, string>,
  filters: MapType<Object>,
  selectedRows: ListType<Object>,
  isActives: ListType<Object>,
  headers: MapType<string, atring | Array<string>>,
  templateList: TemplateList,
};

export type FilterAffiliates = {
  name: string,
  isChacked: boolean
};

export type FiltersType = {
  type: ListType<MapType<FilterAffiliates>>,
  status: ListType<MapType<FilterAffiliates>>
};

export const initialState: ManageAffiliate = Map({
  table: Map({
    sorting: Map({
      field: "",
      direction: "",
    }),
    filters: Map({
      limit: 20,
      page: 1,
      joined: {
        from: moment("01.01.2010").format("YYYY-MM-DD"),
        to: moment().format(),
      },
    }),
    selectedRows: List(),
    isActives: List(),
    headers: Map({
      name: "",
      headersOrder: [
        "id",
        "isActive",
        "name",
        "email",
        "country",
        "createdAt",
        "status",
        "declineReason",
        "type",
        "manager",
        "companyName",
        "paid",
        "approved",
        "pending",
        "declined",
        "refererUrl",
        "registrationUrl",
        "revenue",
        "trafficType",
        "topVerticals",
        "countryByIp",
        "howFoundOut",
        "companyType",
      ],
    }),
    templateList: List(),
  }),
  affiliates: List(),
  totalAffiliates: 0,
  activate: List(),
  loaders: Map({
    isAffiliatesLoaded: false,
    isStatusChanged: true,
    isListsLoaded: false,
    isManagersListsLoaded: false,
    isFiltersLoaded: true,
    isPersonalInfoFetcing: true,
    isAffiliateLinksLoaded: true,
    isOfferSaveLoading: false,
    isAffiliateLinkSaveLoading: false,
  }),
  personalInfo: Map({
    id: "",
    name: "",
    affiliateGroup: "",
    firstName: "",
    lastName: "",
    email: "",
    accountId: { id: 0, name: "" },
    status: "Pending",
    imType: "",
    imName: "",
    phone: "",
    companyUrl: "",
    companyType: "",
    companyTypeComment: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    country: { id: 0, country_name: "" },
    taxSSN: "",
    countryByIp: "",
    manager: { id: 0, name: "" },
    revenue: "",
    howFoundOut: "",
    howFoundOutGroup: "",
    howFoundOutComment: "",
    refSrc: "Friend",
    trafficType: [],
    topVerticals: "",
    topVerticalsComment: "",
    companyName: "",
    declineReasonId: { id: 0, value: "" },
    declineReasonDescription: "",
    topCountries: "",
    type: "",
    currency: "USD",
    isActive: 0,
    shaveAmount: "",
    ncrAmount: "",
    ncrUnit: "",
    isAdNetwork: 0,
    offersApproved: 0,
    isShaveIgnored: 0,
    isShavedAll: 0,
    refSrcComment: "",
    refererUrl: "",
    registrationUrl: "",
    sourceComplianceStatus: false,
    fraudStatus: "no",
    parentAffIdPayment: "",
    childAffiliates: [],
  }),
  paymentInfo: Map({
    paymentMethodId: { id: 0, name: "" },
    paymentsType: "monthly",
    paymentAvailable: "yes",
    paymentComment: "",
    paymentMinAmount: "0.00",
    payoutCountry: { id: 0, country_name: "" },
    beCountry: { id: 0, country_name: "" },
    beName: null,
    beAddress: null,
    bankAccountName: null,
    bankName: null,
    bankAddress: null,
    swiftCode: null,
    accountNumber: null,
    iban: null,
    vatNumber: null,
    paymentNote: null,
    eWallet: null,
    qiwi: null,
    webmoneyWallet: null,
    yandex: null,
    bitcoin: null,
    usdt: null,
    paxumMail: null,
    capitalistWallet: null,
    payoneerWallet: null,
    payPalWallet: null,
    genome: null,
    paysera: null,
    isDataChanged: false,
    isVerified: 0,
    id: 0,
  }),
  campaignSettings: Map({
    offers: List(),
    count: 0,
    isCampaignSettingsModalOpen: false,
    formData: Map({
      offerId: 0,
      postbackUrl: "",
      payoutAmount: "",
      payoutAmountFirst: "",
      payoutAmountRepeats: "",
      shaveAmount: "",
      ncrAmount: "",
      ncrUnit: "",
      isActive: "",
    })

  }),
  affiliateLinks: Map({
    links: List(),
    isAffiliateLinkModalOpen: false,
    formData: Map({
      id: 0,
      name: "",
      link: "",
    })
  }),
  postbackInfo: Map({
    fields: Map({
      defaultPostback: "",
      affiliatePostbackLink: "",
      affiliatePostbackLinkGf: "",
      affiliatePostbackLinkLpFilter: "",
      boSoWebpush: "off",
      gfToggle: "off",
      lpFilter: "off",
      paymentInfoPostbackRepeat: 0,
      postbacksEnabled: 1,
    }),
    events: List(),
    postbacks: List(),
    lists: Map(),
    isAddEventPostbackModalOpen: false,
    isAdditionalPostbackModalOpen: false,
    currentEventPostback: null,
    currentAdditionalPostback: null,
  }),
  apiPage: Map({
    fields: Map({
      ipWhiteList: "",
      apiKey: "",
    }),
  }),
  lists: Map(),
  errors: Map(),
  messages: Map(),
  affiliateId: "",
  affiliateCurrentTablet: "",
  isChangeAuthorModalOpened: false,
  isChangeStatusModalOpened: false,
  managersList: [],
  isManagersListLoaded: false,
  affType: "",
  submitCount: 0,
});

export const initState = () => initialState;

export type affiliate = [{
  id: string,
  name: string,
  country: string,
  createdAt: string,
  status: string,
  declineReason: string,
  type: string,
  manager: string,
  companyName: string,
  isActive: number,
  paid: number,
  pending: number,
  approved: number,
  declined: number,
  refererUrl: string,
  registrationUrl: string,
  revenue: string,
  trafficType: string,
  topVerticals: string,
  countryByIp: string,
  refSrc: string,
  email: string,
}];

export type GetManageAffiliates = {
  data: List<affiliate>,
  count: number,
};

// eslint-disable-next-line complexity
export default (state: Map<ManageAffiliate> = initialState, action: any) => {
  switch (action.type) {
    case SET_SORT_MANAGE_AFFILIATE: {
      return state.setIn(["table", "sorting"], Map(action.value));
    }
    case GET_MANAGE_AFFILIATE_DATA: {
      const {
        data,
        count,
      } = action.value;
      return state.set("affiliates", List(data))
        .set("totalAffiliates", count);
    }
    case SET_FILTERS_MANAGE_AFFILIATES: {
      const { types, statuses } = action.value;
      const currentState = state.getIn(["table", "filters"]).toJS();
      return state.setIn(["table", "filters"], Map({
        ...currentState,
        type: types,
        status: statuses,
      }));
    }
    case SET_LOADER_MANAGE_AFFILIATE: {
      const { key, value } = action.value;
      return state.setIn(["loaders", key], value);
    }
    case SET_FILTER_MANAGE_AFFILIATE: {
      const { field, filter } = action.value;
      return state.setIn(["table", "filters", field], filter);
    }
    case DELETE_FILTER_MANAGE_AFFILIATE: {
      return state.deleteIn(["table", "filters", action.field]);
    }
    case SET_SELECTED_ROWS_MANAGE_AFFILIATE: {
      return state.setIn(["table", "selectedRows"], List(action.value));
    }
    case CLEANUP_TABLE_MANAGE_AFFILIATE: {
      const initialManageAffiliates = initialState.get("table");
      return state.set("table", initialManageAffiliates);
    }
    case SET_PERSONAL_INFO_FIELD: {
      const { field, value } = action.value;
      return state.setIn(["personalInfo", field], value);
    }
    case CLEAR_PERSONAL_INFO: {
      const personalInfo = initialState.get("personalInfo");
      return state.setIn(["personalInfo"], personalInfo);
    }
    case GET_PERSONAL_INFO_LISTS: {
      return state.setIn(["lists"], Map(action.value));
    }
    case SET_ERRORS_PERSONAL_INFO: {
      return state.setIn(["errors"], action.value);
    }
    case CLEAR_ERRORS_PERSONAL_INFO: {
      return state.setIn(["errors"], Map());
    }
    case SET_MANAGE_AFFILIATE_ID: {
      return state.setIn(["affiliateId"], action.value);
    }
    case SET_MANAGE_AFFILIATE_CURRENT_TABLET: {
      return state.setIn(["affiliateCurrentTablet"], action.value);
    }
    case SET_PERSONAL_INFO: {
      return state.update("personalInfo", (fields) => fields.merge(Map(action.value)));
    }
    case ADD_IS_ACTIVE_MANAGE_AFFILIATE: {
      const { id, isActive } = action.value;
      const isActives = state.getIn(["table", "isActives"]).push({
        id,
        isActive,
      });
      return state.setIn(["table", "isActives"], isActives);
    }
    case REMOVE_IS_ACTIVE_MANAGE_AFFILIATE: {
      const isActives = state.getIn(["table", "isActives"]).delete(action.index);
      return state.setIn(["table", "isActives"], isActives);
    }
    case CLEAR_IS_ACTIVES_MANAGE_AFFILIATE: {
      return state.setIn(["table", "isActives"], List());
    }
    case SET_PAYMENT_INFO_FIELD: {
      const { field, value } = action.value;
      const prevValue = state.getIn(["paymentInfo", field]);
      if (prevValue === value || (value === "" && prevValue === null)) {
        return state;
      }
      return state.setIn(["paymentInfo", field], value).setIn(["paymentInfo", "isDataChanged"], true);
    }
    case CLEAR_PAYMENT_INFO: {
      const paymentInfo = initialState.get("paymentInfo");
      return state.setIn(["paymentInfo"], paymentInfo)
        .setIn(["paymentInfo", "paymentComment"], state.getIn(["paymentInfo", "paymentComment"]))
        .setIn(["paymentInfo", "paymentAvailable"], state.getIn(["paymentInfo", "paymentAvailable"]))
        .setIn(["paymentInfo", "paymentsType"], state.getIn(["paymentInfo", "paymentsType"]))
        .setIn(["paymentInfo", "paymentMinAmount"], state.getIn(["paymentInfo", "paymentMinAmount"]));
    }
    case SET_ERRORS_PAYMENT_INFO: {
      return state.setIn(["errors"], action.value);
    }
    case CLEAR_ERRORS_PAYMENT_INFO: {
      return state.setIn(["errors"], Map());
    }
    case SET_SORT_CAMPAIGN_SETTINGS: {
      const { value } = action;
      return state.setIn(["campaignSettings", "table", "sorting"], Map(value));
    }
    case SET_FILTER_CAMPAIGN_SETTINGS: {
      const { field, filter } = action.value;
      return state.setIn(["campaignSettings", "table", "filters", field], filter);
    }
    case DELETE_FILTER_CAMPAIGN_SETTINGS: {
      return state.deleteIn(["campaignSettings", "table", "filters", action.field]);
    }
    case SET_SELECTED_ROWS_CAMPAIGN_SETTINGS: {
      return state.setIn(["campaignSettings", "table", "selectedRows"], List(action.value));
    }
    case CLEANUP_TABLE_CAMPAIGN_SETTINGS: {
      const initialCampaignSettings = initialState.get("campaignSettings");
      return state.set("campaignSettings", initialCampaignSettings);
    }
    case SET_OFFERS: {
      const {
        offers,
        count,
      } = action.value;
      return state
        .setIn(["campaignSettings", "offers"], List(offers))
        .setIn(["campaignSettings", "count"], count);
    }
    case SET_PAYMENT_INFO: {
      return state.update("paymentInfo", (fields) => fields.merge(Map(action.value)));
    }
    case SET_AFFILIATE_LINKS: {
      return state.setIn(["affiliateLinks", "links"], List(action.value));
    }
    case SET_SORT_EVENTS_POSTBACK: {
      return state.setIn(["postbackInfo", "eventsTable", "sorting"], action.value);
    }
    case SET_FILTER_EVENTS_POSTBACK: {
      const { field, filter } = action.value;
      return state.setIn(["postbackInfo", "eventsTable", "filters", field], filter);
    }
    case DELETE_FILTER_EVENTS_POSTBACK: {
      return state.deleteIn(["postbackInfo", "eventsTable", "filters", action.field]);
    }
    case SET_SELECTED_ROWS_EVENTS_POSTBACK: {
      return state.setIn(["postbackInfo", "eventsTable", "selectedRows"], List(action.value));
    }
    case CLEANUP_TABLE_EVENTS_POSTBACK: {
      const initialPostbackInfo = initialState.getIn(["postbackInfo", "eventsTable"]);
      return state.setIn(["postbackInfo", "eventsTable"], initialPostbackInfo);
    }
    case SET_SORT_POSTBACKS: {
      return state.setIn(["postbackInfo", "postbacksTable", "sorting"], action.value);
    }
    case SET_FILTER_POSTBACKS: {
      const { field, filter } = action.value;
      return state.setIn(["postbackInfo", "postbacksTable", "filters", field], filter);
    }
    case DELETE_FILTER_POSTBACKS: {
      return state.deleteIn(["postbackInfo", "postbacksTable", "filters", action.field]);
    }
    case SET_SELECTED_ROWS_POSTBACKS: {
      return state.setIn(["postbackInfo", "postbacksTable", "selectedRows"], List(action.value));
    }
    case CLEANUP_TABLE_POSTBACKS: {
      const initialPostbackInfo = initialState.getIn(["postbackInfo", "postbacksTable"]);
      return state.setIn(["postbackInfo", "postbacksTable"], initialPostbackInfo);
    }
    case SET_EVENTS_POSTBACK_LIST: {
      return state.setIn(["postbackInfo", "events"], List(action.value));
    }
    case SET_POSTBACKS_LIST: {
      return state.setIn(["postbackInfo", "postbacks"], List(action.value));
    }
    case SET_LISTS_POSBACKS: {
      return state.setIn(["postbackInfo", "lists"], Map(action.value));
    }
    case SET_FIELD_POSTBACKS: {
      const { field, value } = action.value;
      return state.setIn(["postbackInfo", "fields", field], value);
    }
    case SET_FIELDS_POSTBACKS: {
      return state.setIn(["postbackInfo", "fields"], Map(action.value));
    }
    case IS_PERSONAL_INFO_FETCHED: {
      return state.setIn(["loaders", "isPersonalInfoFetcing"], action.value);
    }
    case SET_API_FIELD: {
      const { field, value } = action.value;
      return state.setIn(["apiPage", "fields", field], value);
    }
    case SET_API_FIELDS: {
      return state.updateIn(["apiPage", "fields"], (fields) => fields.merge(Map(action.value)));
    }
    case SET_TEMPLATE_HEADERS: {
      return state.setIn(["table", "headers"], Map({ ...action.value }));
    }
    case SET_TEMPLATES_LIST: {
      return state.setIn(["table", "templateList"], List(action.value));
    }
    case SET_MESSAGES: {
      return state.setIn(["messages"], Map(action.value));
    }
    case SWITCH_CHANGE_AUTHOR_MODAL_OPENED: {
      return state.set("isChangeAuthorModalOpened", !state.get("isChangeAuthorModalOpened")).set("isManagersListLoaded", false);
    }
    case SWITCH_CHANGE_STATUS_MODAL_OPENED: {
      return state.set("isChangeStatusModalOpened", !state.get("isChangeStatusModalOpened"));
    }
    case SET_FETCHED_MANAGERS: {
      return state.set("managersList", action.managersList);
    }
    case SET_AFF_TYPE: {
      return state.set("affType", action.value);
    }
    case CHANGE_IS_MANAGER_LIST_LOADED: {
      return state.set("isManagersListLoaded", true);
    }
    case SET_MANAGERS_LIST: {
      const { value } = action;
      const managers = value.map(({ label, value}) => ({ id: value, name: label }));
      return state.setIn(["lists", "managers"], managers);
    }
    case OPEN_CAMPAIGN_SETTINGS_FORM_MODAL: {
      return state.setIn(["campaignSettings", "isCampaignSettingsModalOpen"], true);
    }
    case CLOSE_CAMPAIGN_SETTINGS_FORM_MODAL: {
      return state.setIn(["campaignSettings", "isCampaignSettingsModalOpen"], false);
    }
    case SET_CAMPAIGN_SETTINGS_FORM_DATA: {
      const { value } = action;
      return state.mergeIn(["campaignSettings", "formData"], Map(value));
    }
    case CHANGE_EVENT_POSTBACK_MODAL_OPEN: {
      const { value } = action;
      return state.setIn(["postbackInfo", "isAddEventPostbackModalOpen"], value);
    }
    case CHANGE_CURRENT_EVENT_POSTBACK: {
      const { payload } = action;
      return state.setIn(["postbackInfo", "currentEventPostback"], fromJS(payload));
    }
    case CHANGE_CURRENT_ADDITIONAL_POSTBACK: {
      const { payload } = action;
      return state.setIn(["postbackInfo", "currentAdditionalPostback"], fromJS(payload));
    }
    case CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN: {
      const { payload } = action;
      return state.setIn(["postbackInfo", "isAdditionalPostbackModalOpen"], payload);
    }
    case OPEN_AFFILIATE_LINK_MODAL: {
      return state.setIn(["affiliateLinks", "isAffiliateLinkModalOpen"], true);
    }
    case CLOSE_AFFILIATE_LINK_MODAL: {
      return state.withMutations((newState) => {
        newState.mergeIn(["affiliateLinks", "formData"], fromJS({
          id: 0,
          name: "",
          link: "",
        }));
        newState.setIn(["affiliateLinks", "isAffiliateLinkModalOpen"], false);
      })
    }
    case SET_AFFILIATE_LINK_FORM_DATA: {
      const { value } = action;
      return state.mergeIn(["affiliateLinks", "formData"], fromJS(value));
    }
    case SET_DICTIONARY_LIST: {
      const { payload } = action;
      return state.setIn(["lists", payload.key], payload.value);
    }
    case INCREMENT_SUBMIT_COUNT: {
      return state.set("submitCount", state.get("submitCount") + 1);
    }
    default:
      return state;
  }
};
