// @flow
import {
  call,
  debounce,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { GET_TEMPLATES_LIST_SAGA } from "../../helpers/constants/manageAffiliate";
import { setTemplatesList } from "../../actions/manageAffiliate";
import type { GetTemplatesListSaga } from "../../actions/manageAffiliate";
import { getManageAffiliateTemplates } from "../../services/manageAffiliateApi";

export function* makeFetch(action: GetTemplatesListSaga): Saga<void> {
  const { payload }: GetTemplatesListSaga = action;
  try {
    yield put(setLoading("isTemplatesListLoading", true));
    const { data } = yield call(getManageAffiliateTemplates, payload);
    yield put(setTemplatesList(data.data));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("isTemplatesListLoading", false));
  }
}

export default function* watchGetManageAffiliateTemplate(): Saga<void> {
  yield debounce(300, GET_TEMPLATES_LIST_SAGA, (makeFetch: Function));
}
