// @flow
/* eslint-disable import/max-dependencies */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  IconButton,
  Box,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Chip,
  FormControl,
  FormLabel,
  CircularProgress,
  FormHelperText,
  debounce,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Autocomplete from "@mui/material/Autocomplete";
import { getErrors } from "@fas/ui-framework/lib/redux/selectors/errors";
import { removeError, setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import {
  changeCurrentAdditionalPostback,
  changeIsAddAdditionalPostbackModalOpen,
  saveAdditionalPostbackSaga,
} from "../../../../../../../../actions/postbacks";
import {
  mapOfferId,
  mapCountry,
  mapConversionType,
  sanitizeCountry,
} from "../../utils";
import {
  getPostbackOffers,
  getOffersCountries,
} from "../../../../../../../../services/manageAffiliateApi";
import { getCurrentAdditionalPostback } from "../../../../../../../../selectors/manageAffiliate";

type Props = {};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  dialogWrapper: {
    width: 700,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  spacingField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  input: {
    background: theme.palette.background.mainBg,
  },
  checksGroup: {
    margin: theme.spacing(3),
  },
  dialogContent: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
}));

const mapStateToProps = (state) => ({
  currentAdditionalPostback: getCurrentAdditionalPostback(state),
  errors: getErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
  savePostbacks: (fields) => dispatch(saveAdditionalPostbackSaga(fields)),
  clearError: (val) => dispatch(removeError(val)),
  handleOpenAdditionalPostbackModal: (val) => dispatch(changeIsAddAdditionalPostbackModalOpen(val)),
  handleChangeCurrentAdditionalPostback: (val) => dispatch(changeCurrentAdditionalPostback(val)),
  handleSetErrors: (val) => dispatch(setErrors(val)),
});

const PostbackForm = ({
  lists = {},
  savePostbacks,
  clearError,
  handleOpenAdditionalPostbackModal,
  currentAdditionalPostback,
  errors,
  handleChangeCurrentAdditionalPostback,
  handleSetErrors,
}: Props) => {
  const classes = useStyles();
  const errorsObject = errors.toJS();

  const [searchString, setSearchString] = useState("");

  const [isOffersLoading, setOffersLoading] = useState(false);
  const [isCountriesLoading, setCountriesLoading] = useState(false);
  const [fields, setFields] = useState({
    url: "",
    offerId: {
      id: "",
      name: "",
    },
    country: [],
    conversionType: [],
    isActive: 0,
  });

  useEffect(() => {
    if (currentAdditionalPostback) {
      setFields({
        url: currentAdditionalPostback && currentAdditionalPostback.url,
        offerId: currentAdditionalPostback && mapOfferId(currentAdditionalPostback),
        country: currentAdditionalPostback && mapCountry(currentAdditionalPostback),
        conversionType: currentAdditionalPostback && mapConversionType(currentAdditionalPostback),
        isActive: currentAdditionalPostback && currentAdditionalPostback.isActive,
      });
    }
  }, [currentAdditionalPostback]);

  const [offers, setOffers] = useState([{ id: "all", name: "all" }]);
  const [countries, setCountries] = useState([]);

  const handleOnClose = () => {
    handleOpenAdditionalPostbackModal(false);
    handleChangeCurrentAdditionalPostback(null);
    handleSetErrors({});
  };
  const handleSave = () => {
    const sanitizedFields = {
      ...fields,
      offerId: fields.offerId.id === 0 ? "" : fields.offerId.id,
    };
    if (currentAdditionalPostback && currentAdditionalPostback.id) {
      savePostbacks({ ...sanitizedFields, id: currentAdditionalPostback.id });
      return;
    }

    savePostbacks(sanitizedFields);
  };
  const handleChangeField = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
    clearError([name]);
  };
  const handleChangeConversionType = (e) => {
    const { name } = e.target;
    const { checked } = e.target;
    if (checked) {
      return setFields({
        ...fields,
        conversionType: [
          ...fields.conversionType,
          name,
        ],
      });
    }
    const newConversionType = fields.conversionType.filter((el) => el !== name);
    return setFields({
      ...fields,
      conversionType: newConversionType,
    });
  };

  const fetchPostbackOffers = async (val) => {
    if (val.length < 3) {
      return;
    }
    setOffersLoading(true);
    const result = await getPostbackOffers(val);
    setOffers(result.data.data);
    setOffersLoading(false);
  };
  const fetchPostbackCountries = async (val) => {
    if (val.length < 3) {
      return;
    }
    setCountriesLoading(true);
    const result = await getOffersCountries(val);
    setCountries(result.data.countries);
    setCountriesLoading(false);
  };

  useEffect(() => {
    fetchPostbackOffers(searchString);
  }, [searchString]);
  useEffect(() => {
    fields.offerId && fetchPostbackCountries(fields.offerId.id);
  }, [fields.offerId]);

  const handleChangeOffers = (e) => {
    const { value } = e.target;
    debounce(setSearchString, 1000)(value);
  };
  const handleSelectOffer = (e, item) => {
    setFields({
      ...fields,
      offerId: item,
      country: [],
    });
  };

  const inputPropsTextField = {
    classes: { adornedStart: classes.input },
    startAdornment: (
      <InputAdornment position="start">
        <Tooltip title="Some text">
          <HelpOutlineIcon />
        </Tooltip>
      </InputAdornment>
    ),
  };
  const handleRenderTags = (tagValue, getTagProps) => tagValue.map((option, index) => (
    <Chip
      label={option}
      {...getTagProps({ index })}
    />
  ));
  const zeroSpacing = { marginLeft: 0, marginRight: 0 };

  return (
    <div
      className={classes.dialogWrapper}
    >
      <Box px={3} py={2}>
        <Typography variant="h6">
          {currentAdditionalPostback && currentAdditionalPostback.id ? `Edit postback #${currentAdditionalPostback.id}` : "Add postback"}
        </Typography>
        <IconButton className={classes.closeButton} onClick={handleOnClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        className={classes.dialogContent}
        dividers
      >
        <TextField
          className={classes.spacingField}
          error={(!!errorsObject.url || !!errorsObject.postback)}
          helperText={
            (errorsObject.url && errorsObject.url.message)
            || (errorsObject.postback && errorsObject.postback.message)
          }
          fullWidth
          inputProps={{
            style: { background: classes.input },
          }}
          FormHelperTextProps={{
            style: zeroSpacing,
          }}
          variant="outlined"
          label="Additional postback link"
          name="url"
          data-testid="url"
          value={fields.url}
          onChange={handleChangeField}
          placeholder="Additional postback link"
          InputProps={inputPropsTextField}
        />
        <Autocomplete
          className={classes.selectSpacing}
          fullWidth
          disableClearable
          classes={{
            inputRoot: classes.input,
          }}
          options={offers}
          value={fields.offerId}
          data-testid="offerId"
          onChange={handleSelectOffer}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Cpa offer name"
              variant="outlined"
              label="Cpa offer name"
              error={!!errors.offerId}
              onChange={handleChangeOffers}
              helperText={errors.offerId && errors.offerId.message}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                endAdornment: (
                  <>
                    {isOffersLoading ? (
                      <CircularProgress color="inherit" size={20} disableShrink />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              FormHelperTextProps={{
                style: zeroSpacing,
              }}
            />
          )}
        />
        <Autocomplete
          className={classes.selectSpacing}
          fullWidth
          multiple
          classes={{
            inputRoot: classes.input,
          }}
          options={sanitizeCountry(
            lists.countries,
            fields.country,
            countries,
            fields.offerId.id
          )}
          value={fields.country}
          data-testid="country"
          onChange={(e, item) => setFields({ ...fields, country: item })}
          getOptionLabel={(option) => option}
          renderTags={handleRenderTags}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Country"
              variant="outlined"
              label="Country"
              error={!!errors.country}
              helperText={errors.country && errors.country.message}
              InputProps={{
                ...params.InputProps,
                classes: { adornedStart: classes.input },
                endAdornment: (
                  <>
                    {isCountriesLoading ? (
                      <CircularProgress color="inherit" size={20} disableShrink />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
                startAdornment: (
                  <>
                    <InputAdornment
                      className={classes.helperIcon}
                      position="start"
                    >
                      <Tooltip title="Some text">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              FormHelperTextProps={{
                style: zeroSpacing,
              }}
            />
          )}
        />
        <FormControl
          error={!!errorsObject.conversionType}
          className={classes.spacingField}
          component="fieldset"
          variant="standard"
          data-testid="conversionType"
        >
          <FormLabel error={!!errorsObject.conversionType} component="legend">Conversion Type</FormLabel>
          <FormHelperText>{errorsObject.conversionType && errorsObject.conversionType.message}</FormHelperText>
          {(lists && lists.conversionTypes) ? lists.conversionTypes.map((item, index) => (
            <FormControlLabel
              key={index} // eslint-disable-line
              label={item}
              control={(
                <Checkbox
                  color="primary"
                  checked={fields.conversionType.includes(item)}
                  onChange={handleChangeConversionType}
                  name={item}
                  data-testid={item}
                />
              )}
            />
          )) : null}
        </FormControl>
        <FormControlLabel
          className={classes.spacingField}
          label="Is active"
          control={(
            <Checkbox
              checked={!!fields.isActive}
              data-testid="isActive"
              onChange={(e) => {
                const value = e.target.checked ? 1 : 0;
                setFields({ ...fields, isActive: value });
              }}
              color="primary"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSave} color="primary" data-testid="saveModalButton">
          Save
        </Button>
        <Button autoFocus onClick={handleOnClose} color="primary" data-testid="backModalButton">
          Close
        </Button>
      </DialogActions>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostbackForm);
