// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { Table } from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { UTM_CAMPAIGN_TABLE } from "../../helpers/constants/utmCampaign";
import { getUtmCampaignListSaga, type GetUtmCampaignListSagaType } from "../../actions/utmCampaign";
import type { UtmCampaignType } from "./types/UtmCampaignDictionaryList.types";

const UtmCampaignDictionaryList: StatelessFunctionalComponent<{}> = () => {
  const tableProps: TableProps = useTable(UTM_CAMPAIGN_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const onGetUtmCampaignList: () => GetUtmCampaignListSagaType = () => dispatch(getUtmCampaignListSaga());

  const columns: Array<Column<UtmCampaignType>> = [
    {
      field: "id",
      label: "Id",
      searchable: true,
      sortable: true,
    },
    {
      field: "value",
      label: "Value",
      searchable: true,
      sortable: true,
    },
    {
      field: "updated_at_ts",
      label: "Updated at",
      sortable: true,
      render: ({ updated_at_ts: updated }: UtmCampaignType): string => moment(updated).format("YYYY-MM-DD, HH:mm:ss"),
    },
  ];

  return (
    <Box p={3}>
      <Table
        {...tableProps}
        title="Redefined utm_campaigns"
        columns={columns}
        rowSelectAvailable={(): boolean => false}
        allSelectAvailable={(): boolean => false}
        onLoad={onGetUtmCampaignList}
      />
    </Box>
  );
};

export default UtmCampaignDictionaryList;
