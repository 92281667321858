// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetUtmCampaignListSaga from "../../sagas/getUtmCampaignListSaga";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetUtmCampaignListSaga),
  ]);
}
