// @flow
/* eslint-disable import/max-dependencies */
import React, { useCallback, useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import Box from "@mui/material/Box";
import type { Match } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getFormDataSaga, setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { SelectForm, SwitchFieldForm } from "@fas/ui-core/lib/Form";
import { Error, FormHeader, Loader } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { getTopOffersDropdownListSaga } from "../../actions/topOffers";
import SmartlinkTopOffers from "../SmartlinkTopOffers";
import DirectTopOffers from "../DirectTopOffers";
import { useList } from "../../hoocks/useDictionary";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import type { State } from "../../pages/PotentialPartnerForm/store";
import { useLoading } from "../../hoocks/useLoading";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import Form from "../Form";
import { FORM_KEY_TOP_OFFERS } from "../../helpers/constants/topOffers";
import DropzoneAreaForm from "../AdvertiserInvoicesForm/DropzoneAreaForm";
import ShowByValue from "../AdvertiserInvoicesForm/ShowByValue";
import validate from "./validate";
import { TOP_OFFERS_PROJECT_TYPES } from "./types/TopOffersForm.types";

export type Props = $ReadOnly<{||}>;

const maxFileSize: number = 1024 * 1024 * 10;// 10 MG

const TopOffersFormContainer = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();
  const { id: objectId }: $PropertyType<Match, "params"> = useParams();

  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_TOP_OFFERS, objectId));
    }
    dispatch(getTopOffersDropdownListSaga());
  }, [dispatch, objectId]);

  const id = useSelector((state: State) => getFormField(state, FORM_KEY_TOP_OFFERS, "id"));
  const projectType = useSelector((state: State) => getFormField(state, FORM_KEY_TOP_OFFERS, "projectType"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);
  const projectTypes: DropDownObjItemType[] = useList("projectTypes");

  const isAvailableToSave: boolean = !!TOP_OFFERS_PROJECT_TYPES[String(projectType)];

  const onChangeProjectType = useCallback((e) => {
    dispatch(setErrors({}));
    dispatch(setFormData(FORM_KEY_TOP_OFFERS, {
      projectType: e,
      vertical: "",
      geo: [],
      platform: [],
      eCPM: "",
      isActive: true,
      offerId: "",
      offerName: "",
      conversionType: "",
      payout: "",
      currencyId: "",
      image: "",
    }));
  }, []);

  const isError = useSelector((state: State) => getFormField(state, FORM_KEY_TOP_OFFERS, "isError"));
  if (isError) {
    return <Error />;
  }

  return (
    <Form id={FORM_KEY_TOP_OFFERS} validate={validate} redirectOnSave="/topOffers">
      <FormHeader
        title={id ? `Edit top offer: ${String(id)}` : "Create top offer"}
        loading={loading}
        isActionVisible={!loading && !!isAvailableToSave}
      >
        <SwitchFieldForm
          name="isActive"
          label="Is active"
        />
      </FormHeader>
      <Container maxWidth="md">
        <SetupCard title="General options">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <SelectForm
                name="projectType"
                label="Project type"
                options={projectTypes}
                onChange={onChangeProjectType}
                disabled={!!id}
              />
              <ShowByValue name="projectType" compare={(p) => p === TOP_OFFERS_PROJECT_TYPES.adsempiresmartlink}>
                <SmartlinkTopOffers />
              </ShowByValue>
              <ShowByValue name="projectType" compare={(p) => p === TOP_OFFERS_PROJECT_TYPES.adsempiredirect}>
                <DirectTopOffers />
              </ShowByValue>
            </Loader>
          </Box>
        </SetupCard>

        <ShowByValue name="projectType" compare={(p) => p === TOP_OFFERS_PROJECT_TYPES.adsempiredirect}>
          <SetupCard title="Logo">
            <Box width={1}>
              <Loader loading={loading}>
                <DropzoneAreaForm
                  name="image"
                  showFileName={false}
                  showRemoveButton
                  maxSize={maxFileSize}
                  showErrors={false}
                />
              </Loader>
            </Box>
          </SetupCard>
        </ShowByValue>
      </Container>
    </Form>
  );
};

export default TopOffersFormContainer;
