// @flow
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State } from "../../pages/CreateAffiliate";
import type { ManageAffiliate } from "../../reducers/manageAffiliate";
import type { Link } from "../../actions/affiliateLinks";

export const getAffiliateId: OutputSelector<State, *, string> = createSelector(
  (state: State): ManageAffiliate => state.manageAffiliate.toObject(),
  (value: ManageAffiliate): string => value.affiliateId
);

export const getIsAffiliateLinkSaveLoading: OutputSelector<State, *, boolean> = createSelector(
  (state: State): { [string]: boolean } => state.manageAffiliate.get("loaders").toObject(),
  (value: { [string]: boolean }): boolean => value.isAffiliateLinkSaveLoading
);

export const getIsAffiliateLinkModalOpen: OutputSelector<State, *, boolean> = createSelector(
  (state: State): boolean => state.manageAffiliate.getIn(["affiliateLinks", "isAffiliateLinkModalOpen"]),
  (value: boolean): boolean => value
);

export const getAffiliateLinkFormData: OutputSelector<State, *, Link> = createSelector(
  (state: State):* => state.manageAffiliate.getIn(["affiliateLinks", "formData"]),
  (value: *): Link => value && value.toObject()
);
