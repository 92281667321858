// @flow
import qs from "qs";
import url from "url";

export function setQueryStringWithoutPageReload(search: string) {
  const newUrl: string = url.format({
    ...window.location,
    search,
  });
  window.history.replaceState({ path: newUrl }, "", newUrl);
}

function getCurrentSearch(): string {
  return new URLSearchParams(window.location.search).toString();
}

export function setQueryStringValue(key: string, value: mixed, queryString: string = getCurrentSearch()) {
  const values: Object = qs.parse(queryString);
  const newSearch: string = qs.stringify({ ...values, [key]: value }, { encode: true });

  setQueryStringWithoutPageReload(newSearch && `?${newSearch}`);
}

export function getQueryStringValue<T>(key: string, defaultValue: T, queryString: string = getCurrentSearch()): T {
  const values: Object = qs.parse(queryString);
  if (values[key]) return values[key];

  return defaultValue;
}
