// @flow
import type { AffiliateType, PaymentInfoType } from "../../reducers/manageAffiliate";
import {howFoundOutListWithComment} from "../../helpers/manageAffiliate/affiliateHelper";

export const sanitizeData = (data, fields, mode) => {
  const sanitized = {
    ...data,
  };
  delete sanitized.countryByIp;
  delete sanitized.offersApproved;
  delete sanitized.refererUrl;
  delete sanitized.registrationUrl;
  delete sanitized.howFoundOutGroup;
  delete sanitized.childAffiliates;

  if (mode === "add") {
    delete sanitized.userId;
    delete sanitized.sourceComplianceStatus;
  }

  if (data.howFoundOutGroup) {
    sanitized.howFoundOut = data.howFoundOutGroup + "/" + data.howFoundOut;
  }

  fields.forEach((item) => {
    sanitized[item] = sanitized[item] && sanitized[item].id ? sanitized[item].id : null;
  });

  ["shaveAmount"].forEach((item) => {
    sanitized[item] = sanitized[item] ? parseFloat(sanitized[item]) : null;
  });

  if (!["Pending", "Decline"].includes(sanitized.status)) {
    delete sanitized.declineReasonId;
    delete sanitized.declineReasonDescription;
  }

  if (sanitized.status !== "Approve") {
    sanitized.parentAffIdPayment = "";
  }

  return sanitized;
};

export const mapFetchedAffiliateFields = (data, result) => {
  const { offersApproved } = result.meta;
  const fetchedData = result.data;
  const sanitized: AffiliateType = {};
  Object.keys(data).forEach((key) => {
    if (key === "offersApproved") {
      sanitized[key] = offersApproved;
      return;
    }
    if (key === "howFoundOut" && fetchedData.howFoundOut && fetchedData[key].split("/").length === 1) {
      sanitized.howFoundOut = fetchedData[key].split("/")[0];
      return;
    }
    if(key === "howFoundOut" && fetchedData.howFoundOut && fetchedData.howFoundOut.split("/").length === 2) {
      sanitized.howFoundOutGroup = fetchedData.howFoundOut.split("/")[0];
      sanitized.howFoundOut = fetchedData.howFoundOut.split("/")[1];
      return;
    }
    if (fetchedData[key] === null) {
      sanitized[key] = "";
      return;
    }
    sanitized[key] = fetchedData[key];
  });

  return sanitized;
};

export const mapPaymentInfoFields = (data, result) => {
  const fetchedData = result.data.paymentInfo;
  const sanitized: PaymentInfoType = Object.keys(data).reduce((res, item) => ({
    ...res,
    [item]: fetchedData[item] || null,
  }), {});
  sanitized.isDataChanged = false;

  return sanitized;
};

export const sanitizePaymentInfo = (data, fields, affType) => {
  const sanitized = {};

  fields.forEach((item) => {
    if (data[item] && data[item].hasOwnProperty("id")) {
      sanitized[item] = data[item].id;
      return;
    }
    sanitized[item] = data[item] || "";
  });

  return sanitized;
};

export const getErrors = (errors) => errors.reduce((res, item) => ({ ...res, [item.field]: item.error }), {});

const paymentMethodsFields = {
  adromeda: {
    0: [],
    1: [
      "bankAccountName",
      "beAddress",
      "beCountry",
      "bankName",
      "bankAddress",
      "payoutCountry",
      "swiftCode",
      "iban",
      "vatNumber",
      "accountNumber",
      "paymentNote",
    ],
    2: [
      "beName",
      "beAddress",
      "beCountry",
      "vatNumber",
      "paxumMail",
    ],
    3: [
      "beName",
      "beCountry",
      "vatNumber",
      "webmoneyWallet",
    ],
    4: [
      "beName",
      "vatNumber",
      "beCountry",
      "eWallet",
    ],
    5: [
      "beName",
      "vatNumber",
      "beCountry",
      "bitcoin",
    ],
    6: [
      "beName",
      "vatNumber",
      "beCountry",
      "qiwi",
    ],
    7: [
      "beName",
      "vatNumber",
      "beCountry",
      "yandex",
    ],
    8: [
      "beName",
      "vatNumber",
      "beCountry",
      "capitalistWallet",
    ],
    9: [
      "beName",
      "vatNumber",
      "beAddress",
      "beCountry",
      "payoneerWallet",
      "paymentNote",
    ],
    10: [
      "beName",
      "vatNumber",
      "beCountry",
      "beAddress",
      "payPalWallet",
    ],
    11: [
      "beName",
      "vatNumber",
      "beAddress",
      "beCountry",
      "genome",
    ],
    12: [
      "beName",
      "vatNumber",
      "beAddress",
      "beCountry",
      "paysera",
    ],
  },
  other: {
    0: [],
    1: [
      "bankAccountName",
      "beAddress",
      "beCountry",
      "bankName",
      "bankAddress",
      "payoutCountry",
      "swiftCode",
      "iban",
      "vatNumber",
      "accountNumber",
      "paymentNote",
    ],
    2: [
      "beName",
      "beCountry",
      "paxumMail",
    ],
    3: [
      "beName",
      "beCountry",
      "webmoneyWallet",
    ],
    4: [
      "beName",
      "beCountry",
      "eWallet",
    ],
    5: [
      "beName",
      "beCountry",
      "bitcoin",
    ],
    6: [
      "beName",
      "beCountry",
      "qiwi",
    ],
    7: [
      "beName",
      "beCountry",
      "yandex",
    ],
    8: [
      "beName",
      "beCountry",
      "capitalistWallet",
    ],
    9: [
      "beName",
      "beCountry",
      "payoneerWallet",
    ],
    10: [
      "beName",
      "beCountry",
      "payPalWallet",
    ],
    11: [
      "beName",
      "beCountry",
      "genome",
    ],
    12: [
      "beName",
      "beCountry",
      "paysera",
    ],
  },
  adsempiredirect: {
    0: [],
    1: [
      "bankAccountName",
      "beAddress",
      "beCountry",
      "bankName",
      "bankAddress",
      "payoutCountry",
      "swiftCode",
      "iban",
      "vatNumber",
      "accountNumber",
      "paymentNote",
    ],
    2: [
      "beName",
      "beCountry",
      "paxumMail",
      "vatNumber",
      "beAddress",
    ],
    3: [
      "beName",
      "beCountry",
      "webmoneyWallet",
      "vatNumber",
    ],
    4: [
      "beName",
      "beCountry",
      "eWallet",
      "vatNumber",
    ],
    5: [
      "beName",
      "beCountry",
      "bitcoin",
      "vatNumber",
    ],
    6: [
      "beName",
      "beCountry",
      "qiwi",
      "vatNumber",
    ],
    7: [
      "beName",
      "beCountry",
      "yandex",
      "vatNumber",
    ],
    8: [
      "beName",
      "beCountry",
      "capitalistWallet",
      "vatNumber",
    ],
    9: [
      "beName",
      "beAddress",
      "beCountry",
      "payoneerWallet",
      "vatNumber",
      "paymentNote",
    ],
    10: [
      "beName",
      "beCountry",
      "payPalWallet",
      "vatNumber",
      "beAddress",
    ],
    11: [
      "beName",
      "beCountry",
      "genome",
      "vatNumber",
    ],
    12: [
      "beName",
      "beCountry",
      "paysera",
      "vatNumber",
    ],
    13: [
      "beName",
      "beCountry",
      "usdt",
      "vatNumber",
    ],
  },
  adsempiresmartlink: {
    0: [],
    1: [
      "bankAccountName",
      "beAddress",
      "beCountry",
      "bankName",
      "bankAddress",
      "payoutCountry",
      "swiftCode",
      "iban",
      "vatNumber",
      "accountNumber",
      "paymentNote",
    ],
    2: [
      "beName",
      "beCountry",
      "paxumMail",
      "vatNumber",
      "beAddress",
    ],
    3: [
      "beName",
      "beCountry",
      "webmoneyWallet",
      "vatNumber",
    ],
    4: [
      "beName",
      "beCountry",
      "eWallet",
      "vatNumber",
    ],
    5: [
      "beName",
      "beCountry",
      "bitcoin",
      "vatNumber",
    ],
    6: [
      "beName",
      "beCountry",
      "qiwi",
      "vatNumber",
    ],
    7: [
      "beName",
      "beCountry",
      "yandex",
      "vatNumber",
    ],
    8: [
      "beName",
      "beCountry",
      "capitalistWallet",
      "vatNumber",
    ],
    9: [
      "beName",
      "beAddress",
      "beCountry",
      "payoneerWallet",
      "vatNumber",
      "paymentNote",
    ],
    10: [
      "beName",
      "beCountry",
      "payPalWallet",
      "vatNumber",
      "beAddress",
    ],
    11: [
      "beName",
      "beCountry",
      "genome",
      "vatNumber",
    ],
    12: [
      "beName",
      "beCountry",
      "paysera",
      "vatNumber",
    ],
    13: [
      "beName",
      "beCountry",
      "usdt",
      "vatNumber",
    ],
  },
  adveryarbitrage: {
    0: [],
    1: [
      "bankAccountName",
      "beAddress",
      "beCountry",
      "bankName",
      "bankAddress",
      "payoutCountry",
      "swiftCode",
      "iban",
      "vatNumber",
      "accountNumber",
      "paymentNote",
    ],
    2: [
      "beName",
      "beAddress",
      "beCountry",
      "paxumMail",
      "vatNumber",
    ],
    3: [
      "beName",
      "beCountry",
      "webmoneyWallet",
      "vatNumber",
    ],
    4: [
      "beName",
      "beCountry",
      "eWallet",
      "vatNumber",
    ],
    5: [
      "beName",
      "beCountry",
      "bitcoin",
      "vatNumber",
    ],
    6: [
      "beName",
      "beCountry",
      "qiwi",
      "vatNumber",
    ],
    7: [
      "beName",
      "beCountry",
      "yandex",
      "vatNumber",
    ],
    8: [
      "beName",
      "beCountry",
      "capitalistWallet",
      "vatNumber",
    ],
    9: [
      "beName",
      "beCountry",
      "payoneerWallet",
      "vatNumber",
    ],
    10: [
      "beName",
      "beCountry",
      "payPalWallet",
      "vatNumber",
    ],
    11: [
      "beName",
      "beCountry",
      "genome",
      "vatNumber",
    ],
    12: [
      "beName",
      "beCountry",
      "paysera",
      "vatNumber",
    ],
  },
  directdeals: {
    0: [],
    1: [
      "bankAccountName",
      "beAddress",
      "beCountry",
      "bankName",
      "bankAddress",
      "payoutCountry",
      "swiftCode",
      "iban",
      "vatNumber",
      "accountNumber",
      "paymentNote",
    ],
    2: [
      "beName",
      "beAddress",
      "beCountry",
      "paxumMail",
      "vatNumber",
    ],
    3: [
      "beName",
      "beCountry",
      "webmoneyWallet",
      "vatNumber",
    ],
    4: [
      "beName",
      "beCountry",
      "eWallet",
      "vatNumber",
    ],
    5: [
      "beName",
      "beCountry",
      "bitcoin",
      "vatNumber",
    ],
    6: [
      "beName",
      "beCountry",
      "qiwi",
      "vatNumber",
    ],
    7: [
      "beName",
      "beCountry",
      "yandex",
      "vatNumber",
    ],
    8: [
      "beName",
      "beCountry",
      "capitalistWallet",
      "vatNumber",
    ],
    9: [
      "beName",
      "beCountry",
      "payoneerWallet",
      "vatNumber",
    ],
    10: [
      "beName",
      "beAddress",
      "beCountry",
      "payPalWallet",
      "vatNumber",
    ],
    11: [
      "beName",
      "beCountry",
      "genome",
      "vatNumber",
    ],
    12: [
      "beName",
      "beCountry",
      "paysera",
      "vatNumber",
    ],
  },
};

export const mapPaymentMethodsSchema = (paymentMethodId, affType) => {
  const fields = [
    "paymentMethodId",
    "paymentsType",
    "paymentMinAmount",
    "paymentComment",
    "paymentAvailable",
  ];
  const paymentFields = [
    ...fields,
    ...(paymentMethodsFields[affType]?.[paymentMethodId] || []),
  ];

  return paymentFields;
};
