// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import {
  reducer as errors,
  initState as initErrorsState,
  type State as ErrorsState,
} from "@fas/ui-framework/lib/redux/reducers/errors";
import type {
  Actions as ErrorsActions,
} from "@fas/ui-framework/lib/redux/actions/errors";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import {
  tableReducer as tables,
  initTableState,
  type TableState,
} from "@fas/ui-framework/lib/redux/reducers/table";
import loading, {
  initLoadingState,
  type State as LoadingState,
} from "@fas/ui-framework/lib/redux/reducers/loading";
import type { Actions as LoadingActions } from "@fas/ui-framework/lib/redux/actions/loading";
import mainSaga from "./saga";
import paymentInfo, { initState as initPaymentInfoState } from "../../reducers/paymentInfo";
import type { State as PaymentInfoState } from "../../reducers/paymentInfo";

import type { LoadingTypes } from "../../selectors/loading/types";
import type { Actions as PaymentInfoActions } from "../../actions/paymentInfo";
import type { Actions as PayotHistoryActions } from "../../actions/payoutHistory";
import { PAYOUT_HISTORY_LIST_TABLE } from "../../helpers/constants/payoutHistory";
import {
  AFFILIATE_INFO_LOADING, DOWNLOAD_INVOICE_LOADING,
  NEXT_PAYMENT_DATE_LOADING,
  NEXT_PAYMENT_INFO_LOADING, PAYMENT_DETAILS_LOADING, SIMILAR_AFFILIATE_LOADING,
} from "../../helpers/constants/payments";

export type State = $ReadOnly<{|
  loading: LoadingState<LoadingTypes>,
  errors: ErrorsState,
  tables: TableState,
  paymentInfo: PaymentInfoState,
  notifications: NotificationsState,
|}>

function mapPaymentInfoToState(): State {
  return {
    loading: initLoadingState<LoadingTypes>({
      [AFFILIATE_INFO_LOADING]: false,
      [NEXT_PAYMENT_DATE_LOADING]: false,
      [NEXT_PAYMENT_INFO_LOADING]: false,
      [PAYMENT_DETAILS_LOADING]: false,
      [SIMILAR_AFFILIATE_LOADING]: false,
      [DOWNLOAD_INVOICE_LOADING]: false,
      [PAYOUT_HISTORY_LIST_TABLE]: false,
    }),
    errors: initErrorsState(),
    tables: initTableState({
      [PAYOUT_HISTORY_LIST_TABLE]: {
        pageSize: 200,
      },
    }),
    paymentInfo: initPaymentInfoState(),
    notifications: initNotificationsState(),
  };
}

type Actions =
  LoadingActions<LoadingTypes>
  | ErrorsActions
  | PaymentInfoActions
  | PayotHistoryActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  loading,
  errors,
  tables,
  paymentInfo,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPaymentInfoToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
