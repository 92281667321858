// @flow
export const CHANGE_AFFILIATE_ID: "CHANGE_AFFILIATE_ID" = "CHANGE_AFFILIATE_ID";
export const GET_AFFILIATE_INFO_SAGA: "GET_AFFILIATE_INFO_SAGA" = "GET_AFFILIATE_INFO_SAGA";
export const GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA: "GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA" = "GET_PAYMENT_NEXT_PAYMENT_DATE_SAGA";

export const SET_AFFILIATE_INFO: "SET_AFFILIATE_INFO" = "SET_AFFILIATE_INFO";
export const SET_PAYMENT_STATS: "SET_PAYMENT_STATS" = "SET_PAYMENT_STATS";
export const SET_PAYMENT_DETAILS: "SET_PAYMENT_DETAILS" = "SET_PAYMENT_DETAILS";
export const SET_PAYMENT_NEXT_PAYMENT_DATE: "SET_PAYMENT_NEXT_PAYMENT_DATE" = "SET_PAYMENT_NEXT_PAYMENT_DATE";
export const SET_PAYMENT_SIMILAR_AFFILIATES: "SET_PAYMENT_SIMILAR_AFFILIATES" = "SET_PAYMENT_SIMILAR_AFFILIATES";
export const SET_EMPTY_PAYMENT_INFO_STATE: "SET_EMPTY_PAYMENT_INFO_STATE" = "SET_EMPTY_PAYMENT_INFO_STATE";

export const AFFILIATE_INFO_LOADING: "PAYMENTS_AFFILIATE_INFO_LOADING" = "PAYMENTS_AFFILIATE_INFO_LOADING";
export const NEXT_PAYMENT_DATE_LOADING: "NEXT_PAYMENT_DATE_LOADING" = "NEXT_PAYMENT_DATE_LOADING";
export const NEXT_PAYMENT_INFO_LOADING: "PAYMENTS_NEXT_PAYMENT_INFO_LOADING" = "PAYMENTS_NEXT_PAYMENT_INFO_LOADING";
export const PAYMENT_DETAILS_LOADING: "PAYMENT_DETAILS_LOADING" = "PAYMENT_DETAILS_LOADING";
export const SIMILAR_AFFILIATE_LOADING: "SIMILAR_AFFILIATE_LOADING" = "SIMILAR_AFFILIATE_LOADING";
export const DOWNLOAD_INVOICE_LOADING: "DOWNLOAD_INVOICE_LOADING" = "DOWNLOAD_INVOICE_LOADING";

export const MAX_ROWS_PER_CARD: number = 9;
