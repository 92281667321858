// @flow
import React, { type Node } from "react";
import dayjs from "dayjs";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import {
  ColumnItemCard,
  ColumnItemCardManual,
  ColumnItemCardSelect,
  ColumnItemCardMultiselect,
  SimpleSelect,
  ManualMultiselect,
  MultiSelectWithSuggest,
  SimpleMultiselect,
  SimpleInput,
} from "@fas/ui-core";
import { fetchSuggestDropdown } from "../../templatesApi";
import type {
  ConfigAttribute,
  TemplateAttribute,
  Dropdown,
} from "../../../reducers/templates";
import type {
  AttributeInputParams,
  DateRangePreset,
  FilterTypes,
} from "./types";

export const mapDefaultAttributes = (attributes: Array<ConfigAttribute>): Array<TemplateAttribute> => attributes
  .filter(({ isDefault }: ConfigAttribute): boolean => isDefault)
  .map(({ id, name }: ConfigAttribute): TemplateAttribute => ({
    id,
    name,
    filters: [],
    isHidden: false,
  }));

export const getConfigAttribute = (
  configAttributes: Array<ConfigAttribute>, attributeId: number
): ConfigAttribute => configAttributes.find(({ id }: ConfigAttribute): boolean => id === attributeId) || {};

const attributeInputs: { [FilterTypes]: (AttributeInputParams) => Node } = {
  "simpleInput": function ({
    filters,
    isDisabled = false,
    onSetFilters,
    onDeleteItem,
    onVisibilityChange,
    isHidden = false,
  }: AttributeInputParams): Node {
    return (
      <ColumnItemCardManual
        limit={1}
        name={this.label}
        item={{ key: this.name, title: this.label }}
        disabled={isDisabled}
        validationErrorMsg=""
        checkValues={(value) => !(this.valueType === "number" && isNaN(value))}
        onDelete={!this.isDefault ? onDeleteItem : undefined}
        count={filters.length}
        selectedList={filters.map(({ label, value }) => ({ label, value: String(value) }))}
        onClose={onSetFilters}
        isVisible={!isHidden}
        onVisibilityChange={!this.isDefault ? onVisibilityChange : undefined}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "multiSelectManual": function ({
    filters,
    isDisabled = false,
    onSetFilters,
    onDeleteItem,
    onVisibilityChange,
    isHidden = false,
  }: AttributeInputParams): Node {
    return (
      <ColumnItemCardManual
        name={this.label}
        item={{ key: this.name, title: this.label }}
        disabled={isDisabled}
        validationErrorMsg=""
        checkValues={(value) => !(this.valueType === "number" && isNaN(value))}
        onDelete={!this.isDefault ? onDeleteItem : undefined}
        count={filters.length}
        selectedList={filters.map(({ label, value }) => ({ label, value: String(value) }))}
        onClose={onSetFilters}
        isVisible={!isHidden}
        onVisibilityChange={onVisibilityChange}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "simpleSelect": function ({
    filters,
    isDisabled = false,
    dropdowns,
    onSetFilters,
    onDeleteItem,
    onVisibilityChange,
    isHidden = false,
  }: AttributeInputParams): Node {
    return (
      <ColumnItemCardSelect
        name={this.label}
        item={{ key: this.name, title: this.label }}
        dropdown={dropdowns}
        disabled={isDisabled}
        onDelete={!this.isDefault ? onDeleteItem : undefined}
        count={filters.length}
        selectedList={filters}
        onClose={onSetFilters}
        isVisible={!isHidden}
        onVisibilityChange={onVisibilityChange}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "simpleMultiSelect": function ({
    filters,
    isDisabled = false,
    dropdowns,
    onSetFilters,
    onDeleteItem,
    onVisibilityChange,
    isHidden = false,
  }: AttributeInputParams): Node {
    return (
      <ColumnItemCardMultiselect
        name={this.label}
        item={{ key: this.name, title: this.label }}
        dropdown={dropdowns}
        disabled={isDisabled}
        onDelete={!this.isDefault ? onDeleteItem : undefined}
        count={filters.length}
        selectedList={filters}
        onClose={onSetFilters}
        isVisible={!isHidden}
        onVisibilityChange={onVisibilityChange}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "multiSelectWithSuggest": function ({
    filters,
    isDisabled = false,
    fetchMethod,
    onSetFilters,
    onDeleteItem,
    onVisibilityChange,
    isHidden = false,
  }: AttributeInputParams): Node {
    return (
      <ColumnItemCardMultiselect
        name={this.label}
        item={{ key: this.name, title: this.label }}
        searchLimit={this.searchLimit}
        // $FlowFixMe
        fetchMethod={fetchMethod}
        disabled={isDisabled}
        onDelete={!this.isDefault ? onDeleteItem : undefined}
        count={filters.length}
        selectedList={filters}
        onClose={onSetFilters}
        isVisible={!isHidden}
        onVisibilityChange={onVisibilityChange}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "none": function ({
    filters,
    onSetFilters,
    onDeleteItem,
    onVisibilityChange,
    isHidden = false,
  }: AttributeInputParams): Node {
    return (
      <ColumnItemCard
        name={this.label}
        item={{ key: this.name, title: this.label }}
        disabled
        onDelete={!this.isDefault ? onDeleteItem : undefined}
        count={filters.length}
        selectedList={filters}
        onClose={onSetFilters}
        isVisible={!isHidden}
        onVisibilityChange={onVisibilityChange}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
};

const searchComponents: { [FilterTypes]: <T: Object>(SearchComponentProps) => Node } = {
  "simpleInput": function ({
    value,
    onChange = () => {},
  }: SearchComponentProps): Node {
    return (
      <SimpleInput
        data-testid={`search-${this.name}`}
        value={value}
        onChange={onChange}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "multiSelectManual": function ({
    value,
    onChange = () => {},
  }: SearchComponentProps): Node {
    return (
      <ManualMultiselect
        data-testid={`search-${this.name}`}
        choicedList={value || []}
        onClose={onChange}
        limit={this.limit}
      />
    );
  },
  "simpleSelect": function ({
    value,
    onChange = () => {},
  }: SearchComponentProps): Node {
    return (
      <SimpleSelect
        selectedValues={value || []}
        onChange={onChange}
        name={this.name}
        values={[{ label: "All", value: "" }, ...this.dropdowns]}
        loading={this.dropdowns.length === 0}
        disabled={this.dropdowns.length === 0}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "simpleMultiSelect": function ({
    value,
    onChange = () => {},
  }: SearchComponentProps): Node {
    return (
      <SimpleMultiselect
        selectedValues={value || []}
        onChange={onChange}
        data-testid={`search-${this.name}`}
        options={this.dropdowns}
        disabled={this.dropdowns.length === 0}
        inputProps={{
          autoComplete: "off",
        }}
      />
    );
  },
  "multiSelectWithSuggest": function ({
    value,
    onChange = () => {},
  }: SearchComponentProps): Node {
    return (
      <MultiSelectWithSuggest
        name={this.name}
        selectedList={value || []}
        onClose={onChange}
        searchLimit={this.searchLimit}
        limit={this.limit}
        // $FlowFixMe MTU-94094
        fetchMethod={(_, searchString: string): Promise<Array<Dropdown>> => fetchSuggestDropdown({
          reportName: this.reportName,
          searchString,
          dictionaryName: this.dictionaryName,
        })}
      />
    );
  },
};

export const getAttributeInput: (type: FilterTypes) => AttributeInputParams => Node = (
  type
) => attributeInputs[type] || attributeInputs.none;

export const getSearchComponent: (
  type: FilterTypes
) => ((SearchComponentProps) => Node) | typeof undefined = (
  type
) => searchComponents[type];

export const PRESETS: Array<DateRangePreset> = [
  {
    label: "Today",
    value: "today",
    start: dayjs().startOf("d").format("YYYY-MM-DD"),
    end: dayjs().endOf("d").format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    value: "yesterday",
    start: dayjs().startOf("d").subtract(1, "d").format("YYYY-MM-DD"),
    end: dayjs().endOf("d").subtract(1, "d").format("YYYY-MM-DD"),
  },
  {
    label: "Last 3 days",
    value: "last3days",
    start: dayjs().subtract(3, "d").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  },
  {
    label: "Last week",
    value: "lastweek",
    start: dayjs().subtract(1, "w").startOf("w")
      .format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "w").endOf("w")
      .format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: dayjs().subtract(1, "M").startOf("M").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "M").endOf("M").format("YYYY-MM-DD"),
  },
  {
    label: "This month",
    value: "thismonth",
    start: dayjs().startOf("M").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "This week",
    value: "thisweek",
    start: dayjs().startOf("w").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "This year",
    value: "thisyear",
    start: dayjs().startOf("y").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
];
