// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import {
  Table,
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  ShowMoreItems,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import { SMU_DEPARTMENT_LIST_TABLE } from "../../helpers/constants/smu";
import {
  getSmuMarketingCompaniesSaga,
  type GetSmuMarketingCompaniesSaga,
} from "../../actions/smuDepartments";
import type { PayloadItemType } from "./types/SmuDepartmentsList.types";

const SmuDepartments: StatelessFunctionalComponent<{}> = () => {
  const tableProps: TableProps = useTable(SMU_DEPARTMENT_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const { selected }: TableProps = tableProps;

  const onGetSmuMarketingCompanies: () => GetSmuMarketingCompaniesSaga = () => (
    dispatch(getSmuMarketingCompaniesSaga()));

  const columns: Array<Column<PayloadItemType>> = [
    {
      field: "title",
      label: "Marketing company name",
      sortable: false,
      searchable: false,
    },
    {
      field: "departments",
      label: "Departments",
      render: ({ departments }: PayloadItemType): Element<typeof ShowMoreItems> => <ShowMoreItems data={departments} />,
      sortable: false,
      searchable: false,
    },
  ];

  return (
    <Box p={3}>
      {/* $FlowFixMe */}
      <Table
        {...tableProps}
        title="SMU Departments"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => false}
        onLoad={onGetSmuMarketingCompanies}
        Actions={(
          <TableActions>
            <TableActionGroup>
              <SimpleTableActionButton
                tooltip="Edit"
                disabled={selected.length > 1}
                onClick={() => {
                  const { id }: PayloadItemType = selected[0];
                  window.location = `/smuDepartments/${id}`;
                }}
                Icon={<Edit />}
                data-testid="editButton"
              />
            </TableActionGroup>
          </TableActions>
        )}
      />
    </Box>
  );
};

export default SmuDepartments;
