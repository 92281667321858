/* eslint-disable import/max-dependencies */
import React from "react";
import { AccessDenied } from "@fas/ui-core";
import { Navigate } from "react-router-dom";
import type { Feature } from "./modules/Affiliates/types";
import Dashboard from "./pages/Dashboard";
import CpaOfferCampaigns from "./pages/CpaOfferCampaigns";
import CpaOffer from "./pages/CpaOfferIndex";
import CpaOfferForm from "./pages/CpaOffer";
import ManageAffiliate from "./pages/ManageAffiliate";
import PrivatRoute from "./components/App/PrivateRoute";
import CreateAffiliate from "./pages/CreateAffiliate";
import Affiliates from "./modules/Affiliates";
import TopOffers from "./pages/TopOffers";
import TopOffersForm from "./pages/TopOffersForm/TopOffersForm";
import PaymentInfo from "./pages/PaymentInfo";
import RouterSlots from "./modules/components/RouterSlots";
import Subrates from "./pages/Subrates";
import SubratesIndex from "./containers/SubratesIndex";
// eslint-disable-next-line import/default
import SubrateForm from "./containers/SubrateForm";
import SubrateInfo from "./containers/SubrateInfo";
import PayoutTermsCreate from "./pages/PayoutTermsCreate";
import PayoutTerms from "./pages/PayoutTerms/PayoutTerms";
import SmuDepartments from "./pages/SmuDepartments";
import SmuDepartmentsForm from "./pages/SmuDepartmentsForm";
import SmuBrandList from "./pages/SmuBrandList";
import SmuBrandForm from "./pages/SmuBrandForm";
import ProductCompany from "./pages/ProductCompany/ProductCompany";
import ProductCompanyForm from "./pages/ProductCompanyForm/ProductCompanyForm";
import PotentialPartner from "./pages/PotentialPartner/PotentialPartner";
import PotentialPartnerForm from "./pages/PotentialPartnerForm/PotentialPartnerForm";
import AdvertiserInvoicesPage from "./pages/AdvertiserInvoices";
import AdvertiserInvoicesFormPage from "./pages/AdvertiserInvoicesForm/AdvertiserInvoicesForm";
import UtmCampaignDictionary from "./pages/UtmCampaignDictionary";
import CrmMails from "./pages/CrmMails";
import CrmMailsList from "./containers/CrmMailsList";
// eslint-disable-next-line import/default
import CrmMailsForm from "./containers/CrmMailsForm";
import CrmTemplates from "./pages/CrmTemplates";
import CrmTemplatesForm from "./pages/CrmTemplatesForm";
import toOperation from "./toOperation";

function RedirectPath({ fromStart, to }: { fromStart: string, to: string }) { // todo remove after backend fix routes
  // eslint-disable-next-line no-console
  console.warn(`Redirecting from ${window.location.pathname} to ${window.location.pathname.replace(fromStart, to) || "/"}`);
  return <Navigate replace to={(window.location.pathname.replace(fromStart, to) + window.location.search) || "/"} />;
}

const slots: Feature[] = [
  {
    Component: Affiliates,
    operationIds: [],
    url: "*",
    label: "Affiliates",
  },
  {
    Component: Dashboard,
    operationIds: toOperation([{ path: "/api/v1/cpa/dashboard", method: "GET" }, "accessManageAffiliate"]),
    url: "dashboard",
    label: "Dashboard",
  },
  ...[
    "affiliates",
    "affiliates/offers/*",
    "affiliates/offer/*",
    "affiliates/tools/*",
    "affiliates/management/*",
    "affiliates/advertiser/*",
    "affiliates/adNetwork/*",
  ].map((url) => ({
    Component: () => <RedirectPath fromStart="/affiliates" to="" />,
    operationIds: [],
    url,
    label: "redirect",
  })),
  {
    Component: PaymentInfo,
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }]),
    url: "payments/paymentInfo",
    label: "payments",
  },
  {
    Component: PayoutTermsCreate,
    operationIds: toOperation([{ path: "/api/v1/payoutTerms", method: "POST" }]),
    url: "payoutTerms/create",
    label: "payoutTerms",
  },
  {
    Component: PayoutTermsCreate,
    operationIds: toOperation([{ path: "/api/v1/payoutTerms", method: "POST" }]),
    url: "payoutTerms/:id",
    label: "payoutTerms",
  },
  {
    Component: PayoutTerms,
    operationIds: toOperation([{ path: "/api/v1/payoutTerms/filter", method: "POST" }]),
    url: "payoutTerms",
    label: "payoutTerms",
  },
  {
    Component: SmuDepartments,
    operationIds: toOperation([{ path: "/api/v1/bank/departments", method: "GET" }]),
    url: "smuDepartments",
    label: "smuDepartments",
  },
  {
    Component: SmuDepartmentsForm,
    operationIds: toOperation([{ path: "/api/v1/bank/departments", method: "GET" }]),
    url: "smuDepartments/:id",
    label: "smuDepartments",
  },
  {
    Component: SmuBrandList,
    operationIds: toOperation([{ path: "/api/v1/bank/brands", method: "GET" }]),
    url: "smuBrands",
    label: "smuBrands",
  },
  {
    Component: SmuBrandForm,
    operationIds: toOperation([{ path: "/api/v1/bank/brands", method: "GET" }]),
    url: "smuBrands/create",
    label: "smuBrands",
  },
  {
    Component: SmuBrandForm,
    operationIds: toOperation([{ path: "/api/v1/bank/brands", method: "GET" }]),
    url: "smuBrands/:id",
    label: "smuBrands",
  },
  {
    Component: ProductCompany,
    operationIds: toOperation([{ path: "/api/v1/bank/companies", method: "GET" }]),
    url: "smuProductCompany",
    label: "smuProductCompany",
  },
  {
    Component: ProductCompanyForm,
    operationIds: toOperation([{ path: "/api/v1/bank/companies", method: "GET" }]),
    url: "smuProductCompany/create",
    label: "smuProductCompany",
  },
  {
    Component: ProductCompanyForm,
    operationIds: toOperation([{ path: "/api/v1/bank/companies", method: "GET" }]),
    url: "smuProductCompany/:id",
    label: "smuProductCompany",
  },
  ...[
    "sumProductCompany",
    "sumProductCompany/*",
  ].map((url) => ({
    Component: () => <RedirectPath fromStart="/sumProductCompany" to="/smuProductCompany" />,
    operationIds: [],
    url,
    label: "redirect",
  })),
  {
    Component: UtmCampaignDictionary,
    operationIds: toOperation([{ path: "/api/v1/generatedUtmCampaign", method: "GET" }]),
    url: "redefinedUtmCampaign",
    label: "redefinedUtmCampaign",
  },
  {
    Component: () => (
      <RouterSlots
        Layout={CrmMails}
        Index={<CrmMailsList />}
        slots={[
          {
            Component: CrmMailsForm,
            operationIds: toOperation([{ path: "/api/v1/crm/mail", method: "POST" }]),
            url: "create",
            label: "crm",
          },
          {
            Component: CrmMailsForm,
            operationIds: toOperation([{ path: "/api/v1/crm/mail", method: "POST" }]),
            url: ":id",
            label: "crm",
          },
        ]}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/crm/mail/filter", method: "POST" }]),
    url: "crm/mails/*",
    label: "crm",
  },
  {
    Component: CrmTemplates,
    operationIds: toOperation([{ path: "/api/v1/template/mailTemplate/filter", method: "POST" }]),
    url: "crm/templates",
    label: "crm",
  },
  {
    Component: CrmTemplatesForm,
    operationIds: toOperation([{ path: "/api/v1/crm/mailTemplate", method: "POST" }]),
    url: "crm/templates/create",
    label: "crm",
  },
  {
    Component: CrmTemplatesForm,
    operationIds: toOperation([{ path: "/api/v1/crm/mailTemplate", method: "GET" }]),
    url: "crm/templates/:id",
    label: "crm",
  },
  {
    Component: CpaOfferCampaigns,
    operationIds: toOperation([{ path: "/api/v1/template/cpaOffersCampaign/filter", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/campaigns",
    label: "CpaOfferCampaigns",
  },
  {
    Component: CpaOffer,
    operationIds: toOperation([{ path: "/api/v1/offer/list", method: "GET" }, "accessManageAffiliate"]),
    url: "cpaOffers",
    label: "CpaOffer",
  },
  {
    Component: CpaOfferForm,
    operationIds: toOperation([{ path: "/api/v1/cpa/offer", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/add",
    label: "CpaOfferForm",
  },
  {
    Component: CpaOfferForm,
    operationIds: toOperation([{ path: "/api/v1/cpa/offer", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/:id",
    label: "CpaOfferForm",
  },
  {
    Component: CpaOfferForm,
    operationIds: toOperation([{ path: "/api/v1/cpa/offer", method: "POST" }, "accessManageAffiliate"]),
    url: "cpaOffers/:id/clone",
    label: "CpaOfferForm",
  },
  {
    Component: ManageAffiliate,
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "GET" }, "accessManageAffiliate"]),
    url: "manageAffiliate",
    label: "manageAffiliate",
  },
  {
    Component: () => (
      <PrivatRoute
        permissions={[]}
        component={(props) => <CreateAffiliate {...props} mode="add" />}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]),
    url: "manageAffiliate/create",
    label: "manageAffiliate",
  },
  {
    Component: () => (
      <PrivatRoute
        permissions={[]}
        component={(props) => <CreateAffiliate {...props} mode="edit" />}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]),
    url: "manageAffiliate/edit/:id",
    label: "manageAffiliate",
  },
  {
    Component: () => (
      <PrivatRoute
        permissions={[]}
        component={(props) => <CreateAffiliate {...props} mode="edit" />}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/cpa/affiliate", method: "POST" }, "accessManageAffiliate"]),
    url: "manageAffiliate/edit/:id/:tab",
    label: "manageAffiliate",
  },
  {
    Component: TopOffers,
    operationIds: toOperation([{ path: "/api/v1/topOffers/filters", method: "POST" }]),
    url: "topOffers",
    label: "topOffers",
  },
  {
    Component: TopOffersForm,
    operationIds: toOperation([{ path: "/api/v1/topOffers", method: "POST" }]),
    url: "topOffers/create",
    label: "topOffers",
  },
  {
    Component: TopOffersForm,
    operationIds: toOperation([{ path: "/api/v1/topOffers", method: "POST" }]),
    url: "topOffers/:id",
    label: "topOffers",
  },
  {
    Component: () => (
      <RouterSlots
        Layout={Subrates}
        Index={<SubratesIndex />}
        slots={[
          {
            Component: SubrateForm,
            operationIds: [],
            url: "create",
            label: "subrates",
          },
          {
            Component: SubrateForm,
            operationIds: [],
            url: "edit/:id",
            label: "subrates",
          },
          {
            Component: SubrateForm,
            operationIds: [],
            url: "clone/:id",
            label: "subrates",
          },
          {
            Component: SubrateInfo,
            operationIds: [],
            url: "info",
            label: "subrates",
          },
        ]}
      />
    ),
    operationIds: toOperation([{ path: "/api/v1/dictionary/subrate", method: "POST" }]),
    url: "subrates/*",
    label: "subrates",
  },
  {
    Component: PotentialPartner,
    operationIds: toOperation([{ path: "/api/v1/potentialPartner", method: "GET" }]),
    url: "potentialPartner",
    label: "potentialPartner",
  },
  {
    Component: PotentialPartnerForm,
    operationIds: toOperation([{ path: "/api/v1/potentialPartner", method: "GET" }]),
    url: "potentialPartner/create",
    label: "potentialPartner",
  },
  {
    Component: PotentialPartnerForm,
    operationIds: toOperation([{ path: "/api/v1/potentialPartner", method: "GET" }]),
    url: "potentialPartner/:id",
    label: "potentialPartner",
  },
  {
    Component: AdvertiserInvoicesPage,
    operationIds: toOperation([{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]),
    url: "advertiserInvoices",
    label: "advertiserInvoices",
  },
  {
    Component: AdvertiserInvoicesFormPage,
    operationIds: toOperation([{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]),
    url: "advertiserInvoices/create",
    label: "advertiserInvoices",
  },
  {
    Component: AdvertiserInvoicesFormPage,
    operationIds: toOperation([{ path: "/api/v1/cpa/advertiser/invoices", method: "POST" }]),
    url: "advertiserInvoices/:id",
    label: "advertiserInvoices",
  },
  {
    Component: AccessDenied,
    operationIds: [],
    url: "accessDenied",
    label: "accessDenied",
  },
];

export default slots;
