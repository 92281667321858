// @flow
import PaymentType, * as PaymentTypeModels from "./PaymentType";

// eslint-disable-next-line import/prefer-default-export,complexity
export const getPaymentTypeModel: (type: number) => PaymentType = (type) => {
  switch (type) {
    case 1:
      return new PaymentTypeModels.WireTransfer();
    case 2:
      return new PaymentTypeModels.Paxum();
    case 3:
      return new PaymentTypeModels.WebMoneyWallet();
    case 4:
      return new PaymentTypeModels.EWallet();
    case 5:
      return new PaymentTypeModels.BitcoinWallet();
    case 6:
      return new PaymentTypeModels.QiWi();
    case 7:
      return new PaymentTypeModels.YandexMoney();
    case 8:
      return new PaymentTypeModels.CapitalistWallet();
    case 9:
      return new PaymentTypeModels.PayoneerWallet();
    case 10:
      return new PaymentTypeModels.PayPalWallet();
    case 11:
      return new PaymentTypeModels.GenomeWallet();
    case 12:
      return new PaymentTypeModels.PayseraWallet();
    case 13:
      return new PaymentTypeModels.UsdtWallet();
    default:
      return new PaymentType();
  }
};
