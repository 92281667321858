// @flow
import contentDisposition from "content-disposition";

export default function downloadCsv(data: string, name: string) {
  const hiddenElement: HTMLAnchorElement = document.createElement("a");
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = name;
  document.body && document.body.appendChild(hiddenElement); // for FF
  hiddenElement.click();
  hiddenElement.remove();
}

export function getFileNameFromHeaders(headers: { [key: string]: mixed } | Headers, defaultName: string = ""): string {
  if (!headers) return defaultName;
  try {
    let disposition: string = "";
    if (headers instanceof Headers) {
      disposition = headers.get("content-disposition") || "";
    }
    else {
      disposition = String(headers["content-disposition"] || headers["Content-Disposition"] || "");
    }
    return contentDisposition.parse(disposition).parameters.filename || defaultName;
  }
  catch {
    return defaultName;
  }
}
