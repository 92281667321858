/* eslint-disable import/max-dependencies */
// @flow
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA } from "../../helpers/constants/campaignSettings";
import { getAffiliateId } from "../../selectors/manageAffiliate";
import { restoreOffer } from "../../services/campaignSettingsApi";
import { getCampaignSettingsOffersSaga, type RestoreCampaignSettingsSaga } from "../../actions/campaignSettings";

export function* restoreCampaignSettingsOffer({ offerId }: RestoreCampaignSettingsSaga): Saga<void> {
  const affiliateId: string = yield select(getAffiliateId);
  yield put(setLoading("isRestoreOfferLoading", true));

  try {
    yield call(restoreOffer, { affiliateId, offerId });
    yield put(addNotification({ message: "Changes removed", severity: "success" }));
    yield put(getCampaignSettingsOffersSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to restore offer", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("isRestoreOfferLoading", false));
  }
}

export function* watchRestoreCampaignSettingsOffer(): Saga<void> {
  yield takeEvery(RESTORE_CAMPAIGN_SETTINGS_OFFER_SAGA, (restoreCampaignSettingsOffer: Function));
}
