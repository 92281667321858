// @flow
/* eslint-disable import/max-dependencies */
import {
  call,
  select,
  put,
  debounce,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/ui-framework/lib/redux/selectors/table";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { GET_SMU_BRAND_LIST_SAGA, SMU_BRAND_LIST_TABLE } from "../../helpers/constants/smu";
import { getSmuBrandList } from "../../services/smuApi";
import type { IncomePayloadItemType } from "../../containers/SmuBrandList/types/SmuBrandList.types";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(SMU_BRAND_LIST_TABLE, true));

    const page: number = yield select(getTablePage, SMU_BRAND_LIST_TABLE);
    const limit: number = yield select(getTablePageSize, SMU_BRAND_LIST_TABLE);
    const sorting: Sorting = yield select(getTableSorting, SMU_BRAND_LIST_TABLE);
    const filters: Filters = yield select(getTableFilters, SMU_BRAND_LIST_TABLE);

    const {
      data: {
        data: payload,
        count,
      },
    }: { data: { data: IncomePayloadItemType[], count: number } } = yield call(getSmuBrandList, {
      page,
      limit,
      sorting,
      filters,
      headers: ["id", "productCompanyName", "countries", "vertical", "niche", "url", "image", "isActive", "domain"],
    });

    const tableData = payload.map(({ countries, ...item }) => ({ ...item, countries: (countries || "").split(",") }));

    yield put(setTableData(SMU_BRAND_LIST_TABLE, tableData));
    yield put(changeTableItemsTotalAmount(SMU_BRAND_LIST_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch SMU brands list", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(SMU_BRAND_LIST_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(1000, GET_SMU_BRAND_LIST_SAGA, (makeFetch: Function));
}
