// @flow
import {
  takeEvery,
  select,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  setTableData,
  changeTableItemsTotalAmount,
} from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_POSTBACK_EVENTS_SAGA, POSTBACK_EVENTS_TABLE } from "../../helpers/constants/EventsPostback";
import { getManageAffiliateId } from "../../selectors/manageAffiliate";
import { getPostbackEvents } from "../../services/manageAffiliateApi";

export function* makeFetch(): Saga<void> {
  setLoading(POSTBACK_EVENTS_TABLE, true);
  const affiliateId = yield select(getManageAffiliateId);
  try {
    const { data } = yield call(getPostbackEvents, affiliateId);
    yield put(setTableData(POSTBACK_EVENTS_TABLE, data.events));
    yield put(changeTableItemsTotalAmount(POSTBACK_EVENTS_TABLE, data.events.length));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage, severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    setLoading(POSTBACK_EVENTS_TABLE, false);
  }
}

export default function* watchGetPostback(): Saga<void> {
  yield takeEvery(GET_POSTBACK_EVENTS_SAGA, (makeFetch: Function));
}
