// @flow
import { GET_ADVERTISER_INVOICES_LIST_SAGA, ADVERTISER_INVOICES_DOWNLOAD_SAGA } from "../../helpers/constants/advertiserInvoices";

export type GetAdvertiserInvoicesListSaga = {|
  type: typeof GET_ADVERTISER_INVOICES_LIST_SAGA,
|}

export function getAdvertiserInvoicesListSaga(): GetAdvertiserInvoicesListSaga {
  return {
    type: GET_ADVERTISER_INVOICES_LIST_SAGA,
  };
}

export type DownloadAdvertiserInvoicesSaga = {|
  type: typeof ADVERTISER_INVOICES_DOWNLOAD_SAGA,
|}

export function downloadAdvertiserInvoicesSaga(): DownloadAdvertiserInvoicesSaga {
  return {
    type: ADVERTISER_INVOICES_DOWNLOAD_SAGA,
  };
}
