export default [
  {
    field: "point",
    title: "Point",
    width: 50,
  },
  {
    field: "today",
    title: "Today",
    width: 50,
  },
  {
    field: "yesterday",
    title: "Yesterday",
    width: 50,
  },
  {
    field: "lastSevenDays",
    title: "Last 7 days",
    width: 50,
  },
  {
    field: "thisMonth",
    title: "This month",
    width: 50,
  },
  {
    field: "lastMonth",
    title: "Last month",
    width: 50,
  },
  {
    field: "allTime",
    title: "All time",
    width: 50,
  },
];