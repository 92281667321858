export const tabs = [
  { name: "personalInfo", permission: ["accessManageAffiliate"] },
  { name: "paymentInfo", permission: ["accessManageAffiliate"] },
  { name: "campaignSettings", permission: ["accessManageAffiliate"] },
  { name: "postbackInfo", permission: ["accessManageAffiliate"] },
  { name: "affiliateLinks", permission: [] },
  { name: "smartOffers", permission: [] },
  { name: "api", permission: ["accessManageAffiliate"] },
];

export const getIsListsLoaded = (state) => state.manageAffiliate.getIn(["loaders", "isListsLoaded"]);
