/* eslint-disable complexity */
/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { connect } from "react-redux";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EditIcon from "@mui/icons-material/Edit";
import UndoIcon from "@mui/icons-material/Undo";
import {
  restoreCampaignSettingsOffersSaga,
  setCampaignSettingsFormData,
  openCampaignSettingsModal,
} from "../../../../../../actions/campaignSettings";
import { getCampaignSettingsFormData, getIsCampaignSettingsModalOpen } from "../../../../../../selectors/campaignSettings";

type Props = {};

type Classes = {
  root: string,
  dialogWrapper: string,
  dialogContent: string,
  closeButton: string,
  inputField: string,
  input: string,
}

const useStyles: () => Classes = makeStyles((): Classes => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
}));

const mapStateToProps = (state) => ({
  fields: getCampaignSettingsFormData(state),
  isCampaignSettingsModalOpen: getIsCampaignSettingsModalOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSetField: (field) => dispatch(setCampaignSettingsFormData(field)),
  onOpenFormModal: () => dispatch(openCampaignSettingsModal()),
  onRestoreOffer: (offerId) => dispatch(restoreCampaignSettingsOffersSaga(offerId)),
});

const Actions: StatelessFunctionalComponent<Props> = ({
  row,
  onRestoreOffer,
  onSetField,
  onOpenFormModal,
}: Props) => {
  const classes = useStyles();

  const handleRestoreOffer = () => {
    onRestoreOffer(row.offerId);
  };

  const handleOpen = () => {
    onSetField({
      ...row,
      offerId: row.offerId || "",
      postbackUrl: row.postbackUrl || "",
      payoutAmount: row.payoutAmount || row.payoutAmount === 0 ? row.payoutAmount : "",
      payoutAmountFirst: row.payoutAmountFirst || row.payoutAmountFirst === 0 ? row.payoutAmountFirst : "",
      payoutAmountRepeats: row.payoutAmountRepeats || row.payoutAmountRepeats === 0 ? row.payoutAmountRepeats : "",
      shaveAmount: row.shaveAmount || row.shaveAmount === 0 ? row.shaveAmount : "",
      ncrAmount: row.ncrAmount || row.ncrAmount === 0 ? row.ncrAmount : "",
      ncrUnit: row.ncrUnit ? row.ncrUnit : "",
      isActive: String(row.isActive),
    });
    onOpenFormModal();
    setErrors({});
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleOpen} size="large" data-testid="editButton">
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleRestoreOffer} size="large" data-testid="deleteButton">
        <UndoIcon />
      </IconButton>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions);
