/* eslint-disable no-unused-vars */
// @flow
import React from "react";
import {
  Typography,
} from "@mui/material";
import Actions from "./components/Actions";

const stylingWithBreak = { wordBreak: "break-all" };
const stylingName = { width: 200, wordBreak: "break-all" };
const stylingId = { width: 80, wordBreak: "break-all" };
const stylingUtmTerm = { width: 100, wordBreak: "break-all" };

export const baseColumns = [
  {
    field: "id",
    label: "ID",
    render: (row) => {
      const { id } = row;
      return (
        <Typography style={stylingId}>{id}</Typography>
      );
    },
  },
  {
    field: "name",
    label: "Name",
    render: (row) => {
      const { name } = row;
      return (
        <Typography style={stylingName}>{name}</Typography>
      );
    },
  },
  {
    field: "utmTerm",
    label: "utm_term",
    render: (row) => {
      const { utmTerm } = row;
      return (
        <Typography style={stylingUtmTerm}>{utmTerm}</Typography>
      );
    },
  },
  {
    field: "link",
    label: "Link",
    render: (row) => {
      const { link } = row;
      return (
        <Typography style={stylingWithBreak}>{link}</Typography>
      );
    },
  },
];

export const columnsWithActions = [
  {
    label: "Actions",
    render: (row) => (
      <Actions row={row} />
    ),
  },
  ...baseColumns,
];
