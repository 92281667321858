// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer";
import ProductCompanyList from "../../containers/ProductCompanyList";
import configureStore from "./store";

function ProductCompanyPage() {
  const store = useMemo(() => configureStore());

  return (
    <Provider store={store}>
      <NotificationsContainer />
      <ProductCompanyList />
    </Provider>
  );
}

export default ProductCompanyPage;
