/* eslint-disable import/max-dependencies */
// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import TopOffersForm from "../../containers/TopOffersForm";

function TopOffersFormPage() {
  const store = useMemo(() => configureStore());

  return (
    <DocumentTitle title="CPA - TopOffers">
      <Provider store={store}>
        <NotificationsContainer />
        <TopOffersForm />
      </Provider>
    </DocumentTitle>
  );
}

export default TopOffersFormPage;
