// @flow
import { GET_CPA_OFFER_CAMPAIGNS_SAGA, DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA } from "../../helpers/constants/cpaoffer";
import type { GetCpaOfferCampaignsSaga, DownloadCpaOfferCampaignsSaga } from "./index";

export const getCpaOfferCampaignsSaga: () => GetCpaOfferCampaignsSaga = () => ({
  type: GET_CPA_OFFER_CAMPAIGNS_SAGA,
});

export const downloadCpaOfferCampaignsSaga: () => DownloadCpaOfferCampaignsSaga = () => ({
  type: DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA,
});
