// @flow
import {
  CRM_MAILS_TABLE,
  CHANGE_CRM_MAILS_STATUS_SAGA,
  SEND_CRM_MAILS_SAGA,
} from "../../helpers/constants/crm";

export type GetCrmMailsListSaga = {|
  type: typeof CRM_MAILS_TABLE
|};

export type ChangeCrmMailsStatusSaga = {|
  type: typeof CHANGE_CRM_MAILS_STATUS_SAGA,
  statusMsg: string,
|};

export type SendCrmMailsSaga = {|
  type: typeof SEND_CRM_MAILS_SAGA,
  typeMsg: string,
|};

export type Actions = GetCrmMailsListSaga
  | ChangeCrmMailsStatusSaga
  | SendCrmMailsSaga;

export const getCrmMailsListSaga: () => GetCrmMailsListSaga = () => ({
  type: CRM_MAILS_TABLE,
});

export const changeCrmMailsStatusSaga: (string) => ChangeCrmMailsStatusSaga = (statusMsg) => ({
  type: CHANGE_CRM_MAILS_STATUS_SAGA,
  statusMsg,
});

export const sendCrmMailsSaga: (string) => SendCrmMailsSaga = (typeMsg) => ({
  type: SEND_CRM_MAILS_SAGA,
  typeMsg,
});
