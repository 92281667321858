// @flow
export const GET_TEMPLATES_CONFIG_SAGA: "GET_TEMPLATES_CONFIG_SAGA" = "GET_TEMPLATES_CONFIG_SAGA";
export const SET_TEMPLATES_CONFIG: "SET_TEMPLATES_CONFIG" = "SET_TEMPLATES_CONFIG";
export const SET_TEMPLATES_LIST: "SET_TEMPLATES_LIST" = "SET_TEMPLATES_LIST";
export const SET_CURRENT_TEMPLATE: "SET_CURRENT_TEMPLATE" = "SET_CURRENT_TEMPLATE";
export const SET_CURRENT_TEMPLATE_DATA: "SET_CURRENT_TEMPLATE_DATA" = "SET_CURRENT_TEMPLATE_DATA";
export const CHANGE_CURRENT_TEMPLATE_ATTRIBURTE: "CHANGE_CURRENT_TEMPLATE_ATTRIBURTE" = "CHANGE_CURRENT_TEMPLATE_ATTRIBURTE";
export const SET_CURRENT_TEMPLATE_ATTRIBUTE: "SET_CURRENT_TEMPLATE_ATTRIBUTE" = "SET_CURRENT_TEMPLATE_ATTRIBUTE";
export const ADD_CURRENT_TEMPLATE_ATTRIBUTE: "ADD_CURRENT_TEMPLATE_ATTRIBUTE" = "ADD_CURRENT_TEMPLATE_ATTRIBUTE";
export const SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST: "SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST" = "SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST";
export const SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY: "SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY" = "SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY";
export const DELETE_CURRENT_TEMPLATE_ATTRIBUTE: "DELETE_CURRENT_TEMPLATE_ATTRIBUTE" = "DELETE_CURRENT_TEMPLATE_ATTRIBUTE";
export const SET_ACTIVE_TEMPLATE: "SET_ACTIVE_TEMPLATE" = "SET_ACTIVE_TEMPLATE";
export const EXPORT_TEMPLATE_SAGA: "EXPORT_TEMPLATE" = "EXPORT_TEMPLATE";
export const SET_TEMPLATES_SEARCH_STRING: "SET_TEMPLATES_SEARCH_STRING" = "SET_TEMPLATES_SEARCH_STRING";
export const SET_TEMPLATES_CATEGORY: "SET_TEMPLATES_CATEGORY" = "SET_TEMPLATES_CATEGORY";
export const SET_TEMPLATE_MODAL_OPEN: "SET_TEMPLATE_MODAL_OPEN" = "SET_TEMPLATE_MODAL_OPEN";
export const GET_TEMPLATES_SAGA: "GET_TEMPLATES_SAGA" = "GET_TEMPLATES_SAGA";
export const GET_TEMPLATES_SAGA_DEBOUNCED: "GET_TEMPLATES_SAGA_DEBOUNCED" = "GET_TEMPLATES_SAGA_DEBOUNCED";
export const CHANGE_TEMPLATE_STATUS_SAGA: "CHANGE_TEMPLATE_STATUS_SAGA" = "CHANGE_TEMPLATE_STATUS_SAGA";
export const SAVE_TEMPLATE_SAGA: "SAVE_TEMPLATE_SAGA" = "SAVE_TEMPLATE_SAGA";
export const DELETE_TEMPLATE_SAGA: "DELETE_TEMPLATE_SAGA" = "DELETE_TEMPLATE_SAGA";
export const CLEAR_TEMPLATES_LIST_STATE: "CLEAR_TEMPLATES_LIST_STATE" = "CLEAR_TEMPLATES_LIST_STATE";
export const GET_DEFAULT_TEMPLATE_SAGA: "GET_DEFAULT_TEMPLATE_SAGA" = "GET_DEFAULT_TEMPLATE_SAGA";
export const GET_TEMPLATE_FILTER_DATA_SAGA: "GET_TEMPLATE_FILTER_DATA_SAGA" = "GET_TEMPLATE_FILTER_DATA_SAGA";
