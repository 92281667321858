import React, {
  useState,
  useEffect,
  cloneElement,
  type StatelessFunctionalComponent,
  type Node,
} from "react";
import { Map } from "immutable";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Checkbox,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FilterField from "./components/FilterField";

type Props = {
  title?: string,
  columns: Array<any>,
  data: Array<any>,
  checkableField?: string,
  tableToolbar?: Node,
  tableInfo: Map<any>,
  actionsTable: Object,
  fetchActions?: Object,
  rowsPage?: number,
  totalPagination: number,
  hideCheckAll?: boolean,
  isStickyTable?: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  sticky: {
    width: "100%",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  spaceDivider: {
    visibility: "hidden",
  },
  tableRow: {
    "&$selectedRow, &$selectedRow:hover": {
      backgroundColor: theme.palette.background.mainBg,
    },
  },
  selectedRow: {},
  checkAll: {
    "&$indeterminateAll": {
      color: theme.palette.secondary.main,
    },
  },
  indeterminateAll: {},
  noData: {
    margin: "25px auto",
    color: theme.palette.primary.middleGrey,
    textAlign: "center",
  },
  sortLabel: {
    "&$rootSortLabel, &$rootSortLabel:hover": {
      color: "rgba(0,0,0,0.87)",
    },
  },
  rootSortLabel: {},
}));

const MultiTable: StatelessFunctionalComponent<Props> = ({
  title,
  columns,
  data,
  checkableField,
  selectedFields = [],
  tableInfo,
  rowsPage = 20,
  actionsTable,
  fetchActions = {},
  tableToolbar,
  noDataMessage,
  totalPagination = 0,
  hideCheckAll = true,
  isStickyTable = false,
}: Props) => {
  const classes = useStyles();

  const {
    sorting, filters, selectedRows,
  } = tableInfo;
  const {
    setSorting,
    setFiltering,
    deleteFiltering,
    setSelectingRows,
    cleaningTable,
  } = actionsTable;
  const { sendFilters, handlePagination } = fetchActions;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPage);
  let timeout = null;
  const rowsOptions = [5, 15, 20, 50];

  useEffect(() => () => cleaningTable(), []);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
    if (handlePagination) {
      setFiltering("page", newPage + 1);
      handlePagination(e);
    }
  };
  const handleSetPage = () => {
    setPage(0);
    if (handlePagination) {
      setFiltering("page", 1);
      setFiltering("limit", rowsPerPage);
    }
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    if (handlePagination) {
      setFiltering("page", 1);
      setFiltering("limit", e.target.value);
      handlePagination(e);
    }
  };

  const getDirectionSort = (currentSort) => {
    switch (currentSort) {
      case "asc": {
        return "desc";
      }
      case "desc": {
        return "";
      }
      case "": {
        return "asc";
      }
      default:
        return "";
    }
  };

  const handleSort = (field) => (e) => {
    const direction = getDirectionSort(sorting.direction);
    if (field !== sorting.field) {
      setSorting(field, "asc");
    }
    else {
      setSorting(field, direction);
    }
    if (sendFilters) {
      sendFilters(e);
    }
  };

  const handleChangeFilter = (field) => (e) => {
    const { value } = e.target;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setFiltering(field, value);
      if (page) {
        handleSetPage(e);
      }
    }, 200);
  };
  const handleDeleteFilter = (field) => (e) => {
    deleteFiltering(field);
    if (sendFilters) {
      handleSetPage(e);
      sendFilters(e);
    }
  };
  const handleSelectFilter = (field) => (e) => {
    const { value } = e.target;
    setFiltering(field, value);
    if (sendFilters) {
      handleSetPage(e);
      sendFilters(e);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newSelecteds = data.map((item) => selectedFields
        .reduce((res, field) => ({ ...res, [field]: item[field] }), {}));
      setSelectingRows(newSelecteds);
      return;
    }
    setSelectingRows([]);
  };

  const handleSelect = (e, row) => {
    const selectedField = selectedFields.reduce((res, field) => ({ ...res, [field]: row[field] }), {});

    const selectedIndex = selectedRows.map((n) => n[checkableField]).indexOf(selectedField[checkableField]);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, selectedField);
    }
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    }
    else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectingRows(newSelected);
  };

  const isSelected = (value) => selectedRows.map((n) => n[checkableField]).indexOf(value) !== -1;

  const selectedCount = selectedRows.length;
  const totalRows = data.length;
  const startDataCount = handlePagination ? 0 : page * rowsPerPage;
  const endDataCount = handlePagination ? rowsPerPage : page * rowsPerPage + rowsPerPage;

  return (
    <Paper
      className={isStickyTable ? classes.sticky : classes.root}
      elevation={3}
    >
      {tableToolbar && (
        cloneElement(tableToolbar, {
          title,
          checkableField,
          selectedRows,
        })
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {checkableField && (
                <TableCell padding="checkbox">
                  {!hideCheckAll && (
                    <Checkbox
                      className={classes.checkAll}
                      classes={{
                        indeterminate: classes.indeterminateAll,
                      }}
                      indeterminate={selectedCount > 0 && selectedCount < totalRows}
                      checked={totalRows > 0 && selectedCount === totalRows}
                      onChange={handleSelectAll}
                    />
                  )}
                </TableCell>
              )}
              {columns.map((column, index) => (
                <TableCell
                  key={index} // eslint-disable-line react/no-array-index-key
                  style={{
                    minWidth: column.width,
                    textAlign: column.align ? column.align : "left",
                  }}
                >
                  {column.isSortable ? (
                    <TableSortLabel
                      className={sorting.direction ? "" : classes.sortLabel}
                      classes={!sorting.direction ? { root: classes.rootSortLabel } : {}}
                      active={Boolean(sorting.direction && column.field === sorting.field)}
                      direction={sorting.direction && column.field === sorting.field ? sorting.direction : "asc"}
                      onClick={handleSort(column.field)}
                    >
                      <strong>
                        {column.title}
                      </strong>
                    </TableSortLabel>
                  ) : (
                    <>
                      <strong>{column.title}</strong>
                      <br />
                    </>
                  )}
                  {column.filter ? (
                    <FilterField
                      value={filters[column.field]}
                      filter={column.filter}
                      handleChangeFilter={handleChangeFilter(column.field)}
                      handleDeleteFilter={handleDeleteFilter(column.field)}
                      handleSelectFilter={handleSelectFilter(column.field)}
                      handleSetPage={handleSetPage}
                      sendFilters={sendFilters}
                    />
                  ) : (
                    <TextField
                      className={classes.spaceDivider}
                      size="small"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(startDataCount, endDataCount).map((row, index) => {
              const isItemSelected = checkableField ? isSelected(row[checkableField]) : null;
              return (
                <TableRow
                  hover
                  key={index} // eslint-disable-line react/no-array-index-key
                  classes={{
                    selected: classes.selectedRow,
                  }}
                  className={classes.tableRow}
                  selected={checkableField ? !!isItemSelected : false}
                >
                  {checkableField && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(e) => handleSelect(e, row)}
                      />
                    </TableCell>
                  )}
                  {columns.map((column, i) => {
                    const value = row[column.field];
                    return (
                      <TableCell
                        key={i} // eslint-disable-line react/no-array-index-key
                      >
                        {column.customCell
                          ? column.customCell(value, column.field, row)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {totalRows !== 0 ? (
        <TablePagination
          rowsPerPageOptions={rowsOptions}
          component="div"
          count={totalPagination}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <p
          className={classes.noData}
        >
          {noDataMessage}
        </p>
      )}
    </Paper>
  );
};

export default MultiTable;
