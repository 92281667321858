// @flow
import { List } from "immutable";
import { SET_FILTERS } from "../../helpers/constants/filters";
import type { Actions, SetFilters } from "../../actions/filters";

export type Filter = {
  name: string,
  filters: Array<{ label: string, value: string }>,
};

export const initialState: List<Filter> = List([]);
export type State = List<Filter>;

export const initState: (filters?: Array<Filter>) => List<Filter> = (filters = []) => List(filters);

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_FILTERS: {
      const { payload: filters }: SetFilters = action;
      return List(filters);
    }
    default:
      return state;
  }
};
