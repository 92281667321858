// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import Styles from "../Styles";
import Columns from "../TableColumns";
import MultiTable from "../../../../components/MultiTable";
import ChartSpendings from "../../../../components/ChartSpendings";
import {
  setSort,
  setFilter,
  deleteFilter,
  setSelectedRows,
  cleanupTable,
  fetchDataGet,
  fetchDataPost,
} from "../../../../actions/dashboard";
import {
  type DashboardObjectType,
  mapDashboard,
} from "./utils";
import type {
  CleanupTableAction,
  DeleteFilterAction,
  SetFilterAction,
  SetSelectedRowsAction,
  SetSortAction
} from "../../../../actions/dashboard";
type OwnProps = {||};

type StatePropsType = {|
  dashboard: DashboardObjectType,
|};

type DispatchPropType = {|
  actionsTable: {|
    setSorting: (field: string, direction: string) => SetSortAction,
    setFiltering: (field: string, filter: string) => SetFilterAction,
    deleteFiltering: (field: string) => DeleteFilterAction,
    setSelectingRows: (value: Array<number | string>) => SetSelectedRowsAction,
    cleaningTable: () => CleanupTableAction,
    handleFetchData: () => Promise<*>,
    handleFetchDataPost: (body: {[string]: string}) => Promise<*>,
  |},
|}


export type Props = {|
  ...OwnProps,
  ...StatePropsType,
  ...DispatchPropType,
|};

const mapStateToProps = (state): StatePropsType => ({
  dashboard: mapDashboard(state),
});

const mapDispatchToProps = (dispatch): DispatchPropType => ({
  actionsTable: {
    setSorting: (field, direction) => dispatch(setSort({ field, direction })),
    setFiltering: (field, filter) => dispatch(setFilter({ field, filter })),
    deleteFiltering: (field) => dispatch(deleteFilter(field)),
    setSelectingRows: (rows) => dispatch(setSelectedRows(rows)),
    cleaningTable: () => dispatch(cleanupTable()),
    handleFetchData: () => dispatch(fetchDataGet()),
    handleFetchDataPost: (body) => dispatch(fetchDataPost(body)),
  },
});

const DashboardContent = ({
  dashboard,
  actionsTable,
}: Props) => {
  const classes = Styles();

  const {
    sorting,
    filters,
    selectedRows,
    isActives,
    tableRows,
    chartRows,
    summaryBoard,
    managers,
  } = dashboard;
  const table = {
    sorting,
    filters,
    selectedRows,
    isActives,
  };

  const handleChangeType = (event) => {
    actionsTable
      .setFiltering("type", event.target.value);
  };

  const handleChangeManager = (event) => {
    actionsTable
      .setFiltering("managerId", event.target.value);
  };

  useEffect(() => {
    const body = {};

    if (filters.type && filters.type !== "all") {
      body.type = filters.type;
    }

    if (filters.managerId && filters.managerId !== "all") {
      body.managerId = filters.managerId;
    }

    actionsTable
      .handleFetchDataPost(body);
  }, [filters.type, filters.managerId]);

  return (
    <div className={classes.root}>
      <div className={classes.inlineSpace}>
        <Select
          className={classes.summaryDropdown}
          value={filters.type || "all"}
          data-testid="summary-board"
          onChange={handleChangeType}
          variant="standard">
          <MenuItem key={0} value="all">Summary Board</MenuItem>
          {summaryBoard.map((item, index) => <MenuItem key={index + 1} value={item.toLowerCase()}>{item}</MenuItem>)}
        </Select>
      </div>
      <MultiTable
        title="Spend Manager Conversions"
        columns={Columns}
        data={tableRows}
        tableInfo={table}
        totalPagination={tableRows.length}
        actionsTable={actionsTable}
      />
      <Paper
        className={classes.paper}
        elevation={3}
      >
        <div>
          <Select
            className={classes.managers}
            value={filters.managerId || "all"}
            data-testid="manager"
            onChange={handleChangeManager}
            variant="standard">
            <MenuItem key={0} value="all">All managers</MenuItem>
            {managers.map((item, index) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
          </Select>
        </div>
        <div className={classes.hr} />
        <div className={classes.contentBody}>
          <ChartSpendings data={chartRows} />
        </div>
      </Paper>
    </div>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContent);
