// @flow
import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

type Props = {};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    textAlign: "left",
  },
  editTablet: {
    padding: theme.spacing(2),
    cursor: "pointer",
    backgroundColor: theme.palette.background.mainBg,
  },
  errors: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

const PersonalInfoTablet = ({
  title,
  editTitle,
  name,
  currentTablet,
  handleChangeTablet,
  errors,
  fields,
  children,
}: Props) => {
  const classes = useStyles();

  const [openTablet, setOpenTablet] = useState(false);

  const handleTablet = () => {
    setOpenTablet(!openTablet);
    handleChangeTablet(name);
  };
  const isTabletOpen = currentTablet === name;
  const isAnyErrors = fields.some((field) => errors.hasOwnProperty(field));

  return (
    <Paper
      className={clsx(classes.root, isAnyErrors && classes.errors)}
      data-testid={name}
      elevation={3}
    >
      <Typography
        className={classes.title}
        variant="h6"
      >
        {title}
      </Typography>
      {isTabletOpen ? (
        React.Children.map(children, (child, index) => (
          <div
            key={index}
          >
            {child}
          </div>
        ))
      ) : (
        <Paper
          className={classes.editTablet}
          onClick={handleTablet}
          data-testid="option-modal-button"
        >
          {editTitle}
        </Paper>
      )}
    </Paper>
  );
};

export default PersonalInfoTablet;
