// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_SUBRATES_DICTIONARY_SAGA } from "../../helpers/constants/subrates";
import { fetchSubratesDictionary } from "../../services/subratesApi";
import { setDictionary } from "../../actions/dictionary";
import type { DropDownObjType } from "../../reducers/dictionaries";

export function* getSubratesDictionary(): Saga<void> {
  try {
    const data: DropDownObjType = yield call(fetchSubratesDictionary);
    yield put(setDictionary(data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch dictionary", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetSubratesDictionary(): Saga<void> {
  yield takeEvery(GET_SUBRATES_DICTIONARY_SAGA, (getSubratesDictionary: Function));
}
