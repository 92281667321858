// @flow
import { createSelector } from "reselect";
import { List, type RecordOf, Map } from "immutable";
import type { OutputSelector } from "reselect";
import type { State as LoadingState } from "@fas/ui-framework/lib/redux/reducers/loading";
import { createLoadingSelector } from "@fas/ui-framework/lib/redux/selectors/loading";
import type {
  Config,
  ConfigGroup,
  ConfigAttribute,
  Dropdown,
  Template,
  TemplateState,
  TemplatesListState,
} from "../../reducers/templates";
import { EXPORT_TEMPLATE_SAGA } from "../../helpers/constants/templates";

export type TemplatesLoadingNames = "getTemplatesConfig" | "getTemplatesList" | "saveTemplate" | "getDefaultTemplate" | typeof EXPORT_TEMPLATE_SAGA;
export type StoreWithTemplates = $ReadOnly<{
  templates: TemplatesListState,
  loading: LoadingState<TemplatesLoadingNames>,
  ...
}>;

export const getConfig: OutputSelector<StoreWithTemplates, *, Config> = createSelector(
  (state: StoreWithTemplates): RecordOf<{ groups: List<ConfigGroup>, attributes: List<ConfigAttribute> }> => state.templates.get("modalConfig"),
  (value: RecordOf<{ groups: List<ConfigGroup>, attributes: List<ConfigAttribute> }>): Config => {
    const { groups, attributes } = value.toObject();
    return { groups: groups.toArray(), attributes: attributes.toArray() };
  }
);

export const getDropdownsList: OutputSelector<StoreWithTemplates, *, { [string]: Array<Dropdown> }> = createSelector(
  (state: StoreWithTemplates): Map<string, Array<Dropdown>> => state.templates.get("dropdownsList"),
  (value: Map<string, Array<Dropdown>>): { [string]: Array<Dropdown> } => value.toObject()
);

export const getTemplates: OutputSelector<StoreWithTemplates, *, Array<Template>> = createSelector(
  (state: StoreWithTemplates): List<Template> => state.templates.get("templatesList"),
  (value: List<Template>): Array<Template> => value.toArray()
);

export const getCurrentTemplate: OutputSelector<StoreWithTemplates, *, Template> = createSelector(
  (state: StoreWithTemplates): RecordOf<TemplateState> => state.templates.get("currentTemplate"),
  (value: RecordOf<TemplateState>): Template => {
    const { attributes, ...templateData }: TemplateState = value.toObject();
    return { ...templateData, attributes: attributes.toArray() };
  }
);

export const getActiveTemplate: OutputSelector<StoreWithTemplates, *, Template> = createSelector(
  (state: StoreWithTemplates): RecordOf<TemplateState> => state.templates.get("activeTemplate"),
  (value: RecordOf<TemplateState>): Template => {
    const { attributes, ...templateData }: TemplateState = value.toObject();
    return { ...templateData, attributes: attributes.toArray() };
  }
);

export const getReportName: (state: StoreWithTemplates) => string = (state) => state.templates.get("reportName");
export const getSearchString: (state: StoreWithTemplates) => string = (state) => state.templates.get("searchString");
export const getCategory: (state: StoreWithTemplates) => string = (state) => state.templates.get("category");
export const getIsModalTemplateOpen: (state: StoreWithTemplates) => boolean = (state) => state.templates.get("isTemplateModalOpen");

export const getLoading: OutputSelector<
  StoreWithTemplates, TemplatesLoadingNames, boolean
> = createLoadingSelector<TemplatesLoadingNames>();
