import React, { useRef } from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {
  handleChangeFilter: () => any,
  handleDeleteFilter: () => any,
  handleSelectFilter: () => any,
  handleSetPage: () => any,
  value: string,
  filter: any,
};

const useStyles = makeStyles(() => ({
  selectField: {
    width: "100%",
    marginTop: 8,
    marginBottom: 4,
  },
}));

const FilterField = ({
  handleChangeFilter,
  handleDeleteFilter,
  handleSelectFilter,
  handleSetPage,
  sendFilters,
  value,
  filter,
}: Props) => {
  const classes = useStyles();
  const fieldEl = useRef(null);

  const handleClearFilter = (e) => {
    fieldEl.current.value = "";
    handleDeleteFilter(e);
  };
  const sendFiltersOnEnter = (e) => {
    if (e.key === "Enter" && sendFilters) {
      handleSetPage(e);
      sendFilters(e);
    }
  };

  return filter.select ? (
    <Select
      className={classes.selectField}
      value={value || ""}
      onChange={handleSelectFilter}
      variant="outlined"
      size="small"
    >
      {filter.select.map((item) => (
        <MenuItem
          key={item}
          value={item}
        >
          {item}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      defaultValue=""
      inputRef={fieldEl}
      onChange={handleChangeFilter}
      onKeyPress={sendFiltersOnEnter}
      InputProps={{
        endAdornment: (
          value && (
            <InputAdornment position="end">
              <IconButton onClick={handleClearFilter} edge="end" size="large">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        ),
      }}
    />
  );
};

export default FilterField;
