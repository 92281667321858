// @flow
import { Grid, Box, Typography, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

const useStyles = makeStyles((theme) => ({
  editTablet: {
    marginBottom: theme.spacing(4),
    padding: theme.spacing(3),
    cursor: "pointer",
    backgroundColor: theme.palette.background.mainBg,
  },
  errorCard: {
    border: "1px solid #E91E63",
  },
}));

const SetupCardForm = (props: Props) => {
  const classes = useStyles();
  const {
    children,
    title,
    titleButton,
    isOpen,
    nameCard,
    errors,
  } = props;

  const handleTablet = () => {
    props.onOpen(nameCard);
  };

  return (
    <Box mt={3} mb={3} data-testid={nameCard}>
      <Paper
        elevation={3}
        className={errors.length ? classes.errorCard : ""}
      >
        <Box ml={3} mr={3}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="inherit"
              flexWrap="wrap"
              mb={2}
              mt={2}
            >
              <Typography variant="h5">
                {title}
              </Typography>
            </Box>
            {isOpen ? children : (
              <Paper
                variant="outlined"
                className={classes.editTablet}
                onClick={handleTablet}
                data-testid="option-modal-button"
              >
                {titleButton}
              </Paper>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default SetupCardForm;
