// @flow
export default function escapeCsvValue(cell: *) {
  // RFC 4180 standard
  // Fields containing a line-break, double-quote or commas should be quoted.
  // If double-quotes are used to enclose fields, then a double-quote must be represented by two double-quote characters.
  if (typeof cell === "string" && cell.replace(/ /g, "").match(/[\s,";]/)) {
    return `"${cell.replace(/"/g, "\"\"")}"`;
  }
  return cell;
}
