// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetSmuBrandsListSaga from "../../sagas/getSmuBrandsList";
import watchChangeSmuBrandsActivesSaga from "../../sagas/changeSmuBrandsActives";
import watchGetDropdownListSaga from "../../sagas/getDropdownList";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetSmuBrandsListSaga),
    call(watchChangeSmuBrandsActivesSaga),
    call(watchGetDropdownListSaga),
  ]);
}
