// @flow
import {
  GET_TEMPLATES_CONFIG_SAGA,
  SET_TEMPLATES_CONFIG,
  SET_TEMPLATES_LIST,
  SET_CURRENT_TEMPLATE,
  SET_CURRENT_TEMPLATE_ATTRIBUTE,
  CHANGE_CURRENT_TEMPLATE_ATTRIBURTE,
  SET_ACTIVE_TEMPLATE,
  SET_CURRENT_TEMPLATE_DATA,
  SET_TEMPLATES_SEARCH_STRING,
  SET_TEMPLATES_CATEGORY,
  SET_TEMPLATE_MODAL_OPEN,
  GET_TEMPLATES_SAGA,
  GET_TEMPLATES_SAGA_DEBOUNCED,
  CHANGE_TEMPLATE_STATUS_SAGA,
  SAVE_TEMPLATE_SAGA,
  DELETE_TEMPLATE_SAGA,
  CLEAR_TEMPLATES_LIST_STATE,
  ADD_CURRENT_TEMPLATE_ATTRIBUTE,
  SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST,
  SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY,
  DELETE_CURRENT_TEMPLATE_ATTRIBUTE,
  GET_DEFAULT_TEMPLATE_SAGA,
  GET_TEMPLATE_FILTER_DATA_SAGA,
  EXPORT_TEMPLATE_SAGA,
} from "../../helpers/constants/templates";
import type {
  GetTemplatesConfigSaga,
  SetTemplatesConfig,
  SetTemplatesList,
  SetCurrentTemplate,
  SetCurrentTemplateAttribute,
  AddCurrentTemplateAttribute,
  SetCurrentTemplateAttributesList,
  SetCurrentTemplateAttributeVisibility,
  DeleteCurrentTemplateAttribute,
  ChangeCurrentTemplateAttribute,
  SetActiveTemplate,
  SetCurrentTemplateData,
  SetSearchString,
  SetCategory,
  SetTemplateModalOpen,
  GetTemplatesSaga,
  GetTemplatesSagaDebounced,
  ChangeTemplateStatusSaga,
  SaveTemplateSaga,
  DeleteTemplateSaga,
  ClearTemplatesListState,
  GetDefaultTemplateSaga,
  GetTemplateFilterDataSaga,
  ExportTemplateSaga,
} from "./types";
import type {
  Config,
  Template,
  AttributeFilter,
  TemplateData,
  TemplateAttribute,
  Item,
} from "../../reducers/templates";

export const setTemplatesConfig: (config: Config) => SetTemplatesConfig = (config) => ({
  type: SET_TEMPLATES_CONFIG,
  payload: config,
});

export const getTemplatesConfigSaga: () => GetTemplatesConfigSaga = () => ({
  type: GET_TEMPLATES_CONFIG_SAGA,
});

export const setTemplatesList: (templatesList: Array<Template>) => SetTemplatesList = (templatesList) => ({
  type: SET_TEMPLATES_LIST,
  payload: templatesList,
});

export const setCurrentTemplate: (template: Template | null) => SetCurrentTemplate = (template) => ({
  type: SET_CURRENT_TEMPLATE,
  payload: template,
});

export const changeCurrentTemplateAttribute: (
  name: string,
  filters: Array<AttributeFilter> | null,
) => ChangeCurrentTemplateAttribute = (name, filters) => ({
  type: CHANGE_CURRENT_TEMPLATE_ATTRIBURTE,
  payload: { name, filters },
});

export const setCurrentTemplateAttribute: ({
  index: number,
  filters: Array<AttributeFilter>,
}) => SetCurrentTemplateAttribute = ({ index, filters }) => ({
  type: SET_CURRENT_TEMPLATE_ATTRIBUTE,
  payload: { index, filters },
});

export const addCurrentTemplateAttribute: ({
  id: number,
  name: string,
  filters: Array<AttributeFilter>,
}) => AddCurrentTemplateAttribute = ({ id, name, filters }) => ({
  type: ADD_CURRENT_TEMPLATE_ATTRIBUTE,
  payload: { id, name, filters },
});

export const deleteCurrentTemplateAttribute: (index: number) => DeleteCurrentTemplateAttribute = (index) => ({
  type: DELETE_CURRENT_TEMPLATE_ATTRIBUTE,
  payload: { index },
});

export const setCurrentTemplateAttributesList: (
  attributes: Array<TemplateAttribute>
) => SetCurrentTemplateAttributesList = (attributes) => ({
  type: SET_CURRENT_TEMPLATE_ATTRIBUTES_LIST,
  payload: attributes,
});

export const setCurrentTemplateAttributeVisibility: (
  name: string,
) => SetCurrentTemplateAttributeVisibility = (name) => ({
  type: SET_CURRENT_TEMPLATE_ATTRIBUTE_VISIBILITY,
  payload: { name },
});

export const setCurrentTemplateData: (
  data: $Shape<TemplateData>
) => SetCurrentTemplateData = (data) => ({
  type: SET_CURRENT_TEMPLATE_DATA,
  payload: data,
});

export const setActiveTemplate: (template: Template) => SetActiveTemplate = (template) => ({
  type: SET_ACTIVE_TEMPLATE,
  payload: template,
});

export const exportTemplateSaga: (templateAttributes: TemplateAttribute[]) => ExportTemplateSaga = (
  templateAttributes
) => ({
  type: EXPORT_TEMPLATE_SAGA,
  payload: templateAttributes,
});

export const setSearchString: (searchString: string) => SetSearchString = (searchString) => ({
  type: SET_TEMPLATES_SEARCH_STRING,
  payload: searchString,
});

export const setCategory: (category: string) => SetCategory = (category) => ({
  type: SET_TEMPLATES_CATEGORY,
  payload: category,
});

export const setTemplateModalOpen: (open: boolean) => SetTemplateModalOpen = (open) => ({
  type: SET_TEMPLATE_MODAL_OPEN,
  payload: open,
});

export const getTemplatesSaga: () => GetTemplatesSaga = () => ({
  type: GET_TEMPLATES_SAGA,
});

export const getTemplatesSagaDebounced: () => GetTemplatesSagaDebounced = () => ({
  type: GET_TEMPLATES_SAGA_DEBOUNCED,
});

export const saveTemplateSaga: (id: number | null) => SaveTemplateSaga = (id) => ({
  type: SAVE_TEMPLATE_SAGA,
  id,
});

export const changeTemplateStatusSaga: (item: Item, relation: string) => ChangeTemplateStatusSaga = (
  item, relation
) => ({
  type: CHANGE_TEMPLATE_STATUS_SAGA,
  payload: { item, relation },
});

export const deleteTemplateSaga: (item: Item) => DeleteTemplateSaga = (item) => ({
  type: DELETE_TEMPLATE_SAGA,
  payload: item,
});

export const clearTemplatesListState: () => ClearTemplatesListState = () => ({
  type: CLEAR_TEMPLATES_LIST_STATE,
});

export const getDefaultTemplateSaga: () => GetDefaultTemplateSaga = () => ({
  type: GET_DEFAULT_TEMPLATE_SAGA,
});

export const getTemplateFilterDataSaga: () => GetTemplateFilterDataSaga = () => ({
  type: GET_TEMPLATE_FILTER_DATA_SAGA,
});
