// @flow
import React from "react";
import dayjs from "dayjs";
import {
  Box,
  IconButton,
} from "@mui/material";
import {
  makeStyles,
  type ClassNamesMap,
  type Theme,
} from "@mui/styles";
import { Close } from "@mui/icons-material";
import { DateRangePickerV2 } from "@fas/ui-core";
import type { StatelessFunctionalComponent, Element } from "react";
import { PRESETS } from "../../../services/templateModels/defaultModels/utils";
import type { DateRangePreset } from "../../../services/templateModels/defaultModels/types";
import DateInputComponent, { type Props as DateInputComponentProps } from "./DateInputComponent";
import type { AttributeFilter, DateObj } from "../../../reducers/templates";

type Props = {
  onChangeFilters: (name: string, filters: Array<AttributeFilter> | null) => mixed,
  onClose: () => mixed,
  createdAt: string,
  updatedAt: string,
}

type Classes = {
  datePicker: ClassNamesMap,
  modalHeader: ClassNamesMap,
  closeIcon: ClassNamesMap,
}

const useStyles: () => Classes = makeStyles((theme: Theme): ClassNamesMap => ({
  datePicker: {
    zIndex: 9999,
  },
  modalHeader: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: theme.palette.primary.main,
    color: "#fff",
  },
  closeIcon: {
    color: "#fff",
  },
}));

const TemplateHeader: StatelessFunctionalComponent<Props> = ({
  onChangeFilters,
  onClose,
  createdAt,
  updatedAt,
}: Props) => {
  const classes: Classes = useStyles();

  const createdAtDateObject: DateObj = JSON.parse(createdAt || "{}");
  const updatedAtDateObject: DateObj = JSON.parse(updatedAt || "{}");

  const createdDatePreset: DateRangePreset | void = PRESETS
    .find((item: DateRangePreset): boolean => !!createdAtDateObject && item.start === createdAtDateObject.from
      && item.end === createdAtDateObject.to);
  const lastSeenDatePreset: DateRangePreset | void = PRESETS
    .find((item: DateRangePreset): boolean => !!updatedAtDateObject && item.start === updatedAtDateObject.from
      && item.end === updatedAtDateObject.to);

  const onChangeCreatedAt = (value: { startDate: string, endDate: string }) => {
    onChangeFilters("createdAt", [{
      value: JSON.stringify({ from: value.startDate, to: value.endDate }),
      label: `${value.startDate} - ${value.endDate}`,
    }]);
  };

  const onChangeUpdatedAt = (value: { startDate: string, endDate: string }) => {
    onChangeFilters("updatedAt", [{
      value: JSON.stringify({ from: value.startDate, to: value.endDate }),
      label: `${value.startDate} - ${value.endDate}`,
    }]);
  };

  return (
    <Box className={classes.modalHeader} display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Box fontSize="small" color="text.primary" className={classes.datePicker} data-testid="date-createdAt">
          <DateRangePickerV2
            presets={PRESETS}
            onChangeDate={onChangeCreatedAt}
            startDate={createdAtDateObject.from || dayjs().format("YYYY-MM-DD")}
            endDate={createdAtDateObject.to || dayjs().format("YYYY-MM-DD")}
            maxDate={dayjs().format("YYYY-MM-DD")}
            minDate={dayjs("2010-01-01").format("YYYY-MM-DD")}
            currentPreset={createdDatePreset ? createdDatePreset.label : ""}
            DateInputComponent={(props: {
              startDate: string,
              endDate: string,
              currentPreset: string,
              onIconClick: () => void,
            }): Element<StatelessFunctionalComponent<DateInputComponentProps>> => (<DateInputComponent {...props} label="Created date" isSelected={!!createdAt} />)}
          />
        </Box>
        <Box fontSize="small" color="text.primary" ml={2} className={classes.datePicker} data-testid="date-updatedAt">
          <DateRangePickerV2
            presets={PRESETS}
            onChangeDate={onChangeUpdatedAt}
            startDate={updatedAtDateObject.from || dayjs().format("YYYY-MM-DD")}
            endDate={updatedAtDateObject.to || dayjs().format("YYYY-MM-DD")}
            maxDate={dayjs().format("YYYY-MM-DD")}
            minDate={dayjs("2010-01-01").format("YYYY-MM-DD")}
            currentPreset={lastSeenDatePreset ? lastSeenDatePreset.label : ""}
            DateInputComponent={(props: {
              startDate: string,
              endDate: string,
              currentPreset: string,
              onIconClick: () => void,
            }): Element<StatelessFunctionalComponent<DateInputComponentProps>> => (<DateInputComponent {...props} label="Updated date" isSelected={!!updatedAt} />)}
          />
        </Box>
      </Box>
      <IconButton
        data-testid="modal-close-icon"
        className={classes.closeIcon}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </Box>
  );
};

export default React.memo<Props>(TemplateHeader);
