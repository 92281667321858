// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetDropdownsStaticSaga from "../../sagas/getDropdownsStatic";
import watchChangeCrmMailsStatus from "../../sagas/changeCrmMailsStatus";
import watchSendCrmMails from "../../sagas/sendCrmMails";
import watchGetFormDataSaga from "../../sagas/getFormDataSaga";
import watchSaveFormDataSaga from "../../sagas/saveFormDataSaga";
import { getTableSaga } from "../../sagas/getTableSaga";
import { CRM_MAILS_TABLE } from "../../helpers/constants/crm";
import { fetchCrmMailsList } from "../../services/crmMailsApi";

const watchGetCrmMailsList = getTableSaga({ tableName: CRM_MAILS_TABLE, fetchMethod: fetchCrmMailsList });

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetCrmMailsList),
    call(watchGetDropdownsStaticSaga),
    call(watchChangeCrmMailsStatus),
    call(watchSendCrmMails),
    call(watchGetFormDataSaga),
    call(watchSaveFormDataSaga),
  ]);
}
