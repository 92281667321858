/* eslint-disable import/max-dependencies */
// @flow
import {
  all,
  call, put,
  takeEvery,
} from "redux-saga/effects";
import type { Effect, Saga } from "redux-saga";
import { get } from "lodash";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getSubratesDropdowns } from "../../services/subratesApi";
import {
  COUNTRY_NULL_URL_VIEW,
  FORM_KEY_SUBRATE,
  SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA,
} from "../../helpers/constants/subrates";
import { SELECT_FIELDS_FOR_SUBRATE } from "../../helpers/constants/loading";
import type { SelectFieldByLocationForSubrateSaga } from "../../actions/subrates";
import setLoading from "../../actions/loading/actions";
import { setDictionary } from "../../actions/dictionary";

export function* selectFieldsByLocationForSubrate(action: SelectFieldByLocationForSubrateSaga): Saga<void> {
  try {
    const {
      country,
      affId,
      offerId,
      shaveAmount,
      payoutAmount,
    } = action.props;

    yield put(setLoading(SELECT_FIELDS_FOR_SUBRATE, true));
    const tasks: Effect[] = [
      call(getSubratesDropdowns, {
        type: "offer",
        field: "id",
        format: "id",
        filter: offerId,
      }),
      call(getSubratesDropdowns, {
        type: "affiliate",
        field: "id",
        format: "id",
        filter: affId,
      }),
      call(getSubratesDropdowns, {
        type: "offerConversionType",
        filter: offerId,
      }),
      call(getSubratesDropdowns, {
        type: "offerCountry",
        filter: offerId,
      }),
    ];

    const [
      offerRes,
      affRes,
      offerConversionTypeRes,
      offerCountriesRes,
    ] = yield all(tasks);

    yield put(setFormData(FORM_KEY_SUBRATE, {
      // convert all country value to null, because all is null in db
      country: country === COUNTRY_NULL_URL_VIEW ? null : country,
      cpaCampaign: get(offerRes, "[0]", { label: "", value: "" }),
      affiliate: get(affRes, "[0]", { label: "", value: "" }),
      conversionType: offerConversionTypeRes[0]?.value,
      payoutAmount: payoutAmount || "",
      shaveAmount: shaveAmount || "",
      payoutAmountFirst: "",
      payoutAmountRepeats: "",
      increasedCommissionAmount: "",
      increasedCommissionThreshold: "",
    }));
    yield put(setDictionary({ countriesList: offerCountriesRes }));
  }
  catch (err) {
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to select", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SELECT_FIELDS_FOR_SUBRATE, false));
  }
}

export default function* watchSelectFieldsByLocationForSubrate(): Saga<void> {
  yield takeEvery(SELECT_FIELD_BY_LOCATION_FOR_SUBRATE_SAGA, (selectFieldsByLocationForSubrate: Function));
}
