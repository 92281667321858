// @flow
import {
  GET_PAYOUT_HISTORY_LIST_SAGA,
  DOWNLOAD_INVOICE_SAGA,
} from "../../helpers/constants/payoutHistory";

export type GetPayoutHistoryListSaga = {|
  type: typeof GET_PAYOUT_HISTORY_LIST_SAGA,
|}

export type DownloadInvoiceSaga = {|
  type: typeof DOWNLOAD_INVOICE_SAGA,
  ids: string[],
|}

export type Actions =
  GetPayoutHistoryListSaga
  | DownloadInvoiceSaga

export const getPayoutHistoryListSaga: () => GetPayoutHistoryListSaga = () => ({
  type: GET_PAYOUT_HISTORY_LIST_SAGA,
});

export const downloadInvoiceSaga: (ids: string[]) => DownloadInvoiceSaga = (ids: string[]): DownloadInvoiceSaga => ({
  type: DOWNLOAD_INVOICE_SAGA,
  ids,
});
