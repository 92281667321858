// @flow
import saveAs from "file-saver";
import { requestService } from "../axiosRequest";

export default {
  basicOptions: {},

  setOptions(apiKey: string | null = null, options: {[key: string]: *} = {}) {
    const headers = {
      ...options.headers,
      "X-Api-Key": apiKey,
    };

    this.basicOptions = {
      ...options,
      headers,
    };
  },

  async get(url: string): any {
    return requestService.get(url);
  },

  async getFile(url: string, filename: string) {
    return requestService
      .get(url, { responseType: "blob" })
      .then(({ data }) => saveAs(data, filename))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.errorMessage);
      });
  },

  async post(url: string, body: any): any {
    return requestService.post(url, body);
  },

  async delete(url: string): any {
    return requestService.delete(url);
  },
};
