// @flow
import React, { useCallback, useEffect, useState } from "react";
import {
  Box, IconButton, Divider, DialogTitle, Button, DialogContent, Grid,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { SelectCustom } from "@fas/ui-core";
import TextField from "@mui/material/TextField";
import type {
  FilterOperationType,
  ConditionType,
} from "../../reducers/subrates";
import SubratesFilterContent from "./SubratesFilterContent";

type useStateType<S> = [S, ((S => S) | S) => void];

export type Props = {
  filters: {[key: string ]: * },
  handleClose: () => void,
  handleSave: (filters: {[key: string ]: * }) => void,
}

export type FieldsType = {
  payoutAmount: {
    condition: ConditionType,
    operation: FilterOperationType,
  },
  shaveAmount: {
    condition: ConditionType,
    operation: FilterOperationType,
  },
}

export type FiltersStateType = {|
  s3: boolean,
  utmContent: boolean,
  payoutAmount: boolean,
  shaveAmount: boolean,
|}

const filtersTitles: { [string]: string } = {
  s3: "s3",
  utmContent: "utm_content",
  payoutAmount: "commission",
  shaveAmount: "shave %",
};

const operators = [
  { title: "Equal", value: "equal" },
  { title: "Not equal", value: "notEqual" },
  { title: "Less than", value: "lessThan" },
  { title: "Greater than", value: "moreThan" },
];

const SubratesFilterModal = (props: Props) => {
  const {
    filters, handleClose, handleSave,
  }: Props = props;

  const [filtersState, setFilterState]: useStateType<FiltersStateType> = useState({
    s3: false, utmContent: false, payoutAmount: false, shaveAmount: false,
  });
  const [fields, setFields]: useStateType<FieldsType> = useState({
    payoutAmount: {
      condition: null,
      operation: "equal",
    },
    shaveAmount: {
      condition: null,
      operation: "equal",
    },
  });

  useEffect(() => {
    const newFilterValue: FiltersStateType = {
      s3: false,
      utmContent: false,
      payoutAmount: false,
      shaveAmount: false,
    };
    const newFieldsValue: FieldsType = {
      payoutAmount: {
        condition: filters.payoutAmount ? filters.payoutAmount.condition : null,
        operation: filters.payoutAmount ? filters.payoutAmount.operation : "equal",
      },
      shaveAmount: {
        condition: filters.shaveAmount ? filters.shaveAmount.condition : null,
        operation: filters.shaveAmount ? filters.shaveAmount.operation : "equal",
      },
    };
    if (filters.s3 === "") {
      newFilterValue.s3 = true;
    }
    if (filters.payoutAmount && filters.payoutAmount.condition === "") {
      newFilterValue.payoutAmount = true;
    }
    if (filters.utmContent === "") {
      newFilterValue.utmContent = true;
    }
    if (filters.shaveAmount && filters.shaveAmount.condition === "") {
      newFilterValue.shaveAmount = true;
    }
    setFilterState(newFilterValue);
    setFields(newFieldsValue);
  }, [filters]);

  const onApplyClick = useCallback(() => {
    const result = {
      ...filters,
      payoutAmount: fields.payoutAmount,
      shaveAmount: fields.shaveAmount,
    };
    if (result.s3 === "" && !filtersState.s3) {
      delete result.s3;
    }
    if (filtersState.s3) {
      result.s3 = "";
    }
    if (result.utmContent === "" && !filtersState.utmContent) {
      delete result.utmContent;
    }
    if (filtersState.utmContent) {
      result.utmContent = "";
    }
    if (fields.payoutAmount.condition === null) {
      delete result.payoutAmount;
    }
    if (filtersState.payoutAmount) {
      result.payoutAmount = {
        condition: "",
        operation: "equal",
      };
    }
    if (fields.shaveAmount.condition === null) {
      delete result.shaveAmount;
    }
    if (filtersState.shaveAmount) {
      result.shaveAmount = {
        condition: "",
        operation: "equal",
      };
    }
    handleSave(result);
    // eslint-disable-next-line max-len
  }, [fields.payoutAmount, fields.shaveAmount, filters, filtersState.payoutAmount, filtersState.s3, filtersState.utmContent, handleSave]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          size="large"
        >
          <Close fontSize="inherit" />
        </IconButton>
        <Divider orientation="vertical" />
        <Box width="100%">
          <DialogTitle>
            Filters
          </DialogTitle>
        </Box>
        <Box mr={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={onApplyClick}
            data-testid="applyButton"
          >
            Apply
          </Button>
        </Box>
      </Box>
      <Divider />
      <DialogContent data-testid="subrate-filters-component">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box py={1} data-testid="shaveAmount-operation">
              <SelectCustom
                variant="outlined"
                title=""
                size="small"
                value={fields.shaveAmount.operation}
                disabled={filtersState.shaveAmount}
                values={operators}
                onTypeChange={(e) => {
                  setFields({
                    ...fields,
                    shaveAmount: {
                      condition: fields.shaveAmount.condition || "",
                      operation: e.target.value,
                    },
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={1} data-testid="shaveAmount-condition">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Shave %"
                disabled={filtersState.shaveAmount}
                value={fields.shaveAmount.condition || ""}
                type="number"
                onChange={({ target: { value: newValue } }) => {
                  let result = null;
                  if (newValue) {
                    result = Number(newValue) > 99999999 ? fields.shaveAmount.condition : Number(newValue);
                  }
                  setFields({
                    ...fields,
                    shaveAmount: {
                      condition: result,
                      operation: fields.shaveAmount.operation,
                    },
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box py={1} data-testid="payoutAmount-operation">
              <SelectCustom
                variant="outlined"
                size="small"
                title=""
                disabled={filtersState.payoutAmount}
                value={fields.payoutAmount.operation}
                values={operators}
                onTypeChange={(e) => {
                  setFields({
                    ...fields,
                    payoutAmount: {
                      condition: fields.payoutAmount.condition,
                      operation: e.target.value,
                    },
                  });
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box py={1} data-testid="payoutAmount-condition">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                label="Commission"
                disabled={filtersState.payoutAmount}
                value={fields.payoutAmount.condition || ""}
                type="number"
                onChange={({ target: { value: newValue } }) => {
                  let result = null;
                  if (newValue) {
                    result = Number(newValue) > 99999999 ? fields.payoutAmount.condition : Number(newValue);
                  }
                  setFields({
                    ...fields,
                    payoutAmount: {
                      condition: result,
                      operation: fields.payoutAmount.operation,
                    },
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <SubratesFilterContent
          filtersTitles={filtersTitles}
          filters={filtersState}
          fields={fields}
          handleChangeFilter={({ field, value }: { field: string, value: boolean }) => {
            setFilterState({ ...filtersState, [field]: value });
            if (["payoutAmount", "shaveAmount"].includes(field)) {
              setFields({
                ...fields,
                [field]: {
                  condition: null,
                  operation: "equal",
                },
              });
            }
          }}
        />
      </DialogContent>
    </>
  );
};

export default SubratesFilterModal;
