// @flow
import React, { type Node, useMemo } from "react";
import DocumentTitle from "react-document-title";
import { Provider } from "react-redux";
import NotificationsContainer from "../../components/NotificationsContainer/NotificationsContainer";
import configureStore from "./store";
import PotentialPartnerForm from "../../containers/PotentialPartnerForm";

function PotentialPartnerFormPage(): Node {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="Potential partner">
      <Provider store={store}>
        <NotificationsContainer />
        <PotentialPartnerForm />
      </Provider>
    </DocumentTitle>
  );
}

export default PotentialPartnerFormPage;
