import { useLocalStorage } from "usehooks-ts";
import { useEffect } from "react";
import { getQueryStringValue } from "../../helpers/query/queryParamsHelper";
import useUser from "../Affiliates/useUser";

export default function useStorageTenantId(): [string, (v: string) => unknown] {
  const type = getQueryStringValue("type", "");
  const affiliateTypeByTenantMap: { [k: string]: string } = {
    "adsempiredirect": "aed",
    "adsempiresmartlink": "aes",
    "adromeda": "adr",
    "directdeals": "dd",
    "adveryarbitrage": "adv",
  };
  const tenantId = affiliateTypeByTenantMap[type];
  const { id } = useUser();
  const [value, SetValue] = useLocalStorage(`${id}-currentTenant`, "");
  useEffect(() => {
    if (tenantId) {
      SetValue(tenantId);
    }
  }, []);

  return [tenantId || value, SetValue];
}
