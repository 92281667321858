// @flow
import { type Match, matchPath } from "react-router-dom";
import type { Breadcrumb } from "@fas/ui-core/lib/AppNavbar";
import { findBreadcrumbsNodes, type BreadcrumbsNodeType } from "./util";

export default (path: string, breadcrumbsMap: BreadcrumbsNodeType): Breadcrumb[] => {
  const nodes: BreadcrumbsNodeType[] = findBreadcrumbsNodes(breadcrumbsMap, path);

  return nodes.map((item: BreadcrumbsNodeType, index: number, array: BreadcrumbsNodeType[]) => {
    const match: Match = matchPath(item.path, path) || item.path;
    const title: string = typeof item.title === "function" ? item.title(match) : item.title;

    const breadcrumb: Breadcrumb = {
      path: typeof item.redirect === "function" ? item.redirect(match) : item.redirect || "",
      component: item.component || "",
      title: "",
    };

    // todo need move this logic(show link or title) to ui-core view component
    const isLast: boolean = index === (array.length - 1);
    if (isLast || !item.redirect) {
      breadcrumb.component = title;
    }
    else {
      breadcrumb.title = title;
    }

    return breadcrumb;
  });
};
