// @flow
import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

type Props = {};

const useStyles = makeStyles(() => ({
  textActive: {
    color: "#2EB000",
  },
  textInactive: {
    color: "#C80022",
  },
}));

const Status = ({
  value,
}: Props) => {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(value === 1 ? classes.textActive : classes.textInactive)}
    >
      {value === 1 ? "Active" : "Inactive"}
    </Typography>
  );
};

export default Status;
