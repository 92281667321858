// @flow
/* eslint-disable import/max-dependencies */
import React, { useCallback, useEffect, type Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Box,
} from "@mui/material";
import SetupCard from "@fas/ui-core/lib/SetupCard";
import { useParams, type Match } from "react-router-dom";
import { getFormDataSaga, setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import {
  SelectForm,
  TextFieldForm,
  AutocompleteWithFetchForm,
  DatePickerForm,
  ShowByValue,
} from "@fas/ui-core/lib/Form";
import { FormHeader, Loader } from "@fas/ui-core";
import { getFormField } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { useDropdownsStaticList, useList } from "../../hoocks/useDictionary";
import { useLoading } from "../../hoocks/useLoading";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING } from "../../helpers/constants/loading";
import { FORM_KEY_CRM_MAILS, CRM_MAILS_TARGETING_TYPES, CRM_MAILS_USER_TYPES } from "../../helpers/constants/crm";
import Form from "../Form";
import { fetchCrmMailsSuggestDropdown } from "../../services/crmMailsApi";
import validate from "./validate";
import MultiSelectForm from "../Form/MultiSelectForm";
import { defaultFormData, type State } from "../../pages/CrmMails";

const CrmMailsForm = (): Node => {
  const dispatch: <A>(A) => A = useDispatch();
  const { id: objectId }: $PropertyType<Match, "params"> = useParams();

  useEffect(() => {
    if (objectId) {
      dispatch(getFormDataSaga(FORM_KEY_CRM_MAILS, objectId));
    }
  }, [dispatch, objectId]);

  useEffect(() => () => {
    dispatch(setFormData(FORM_KEY_CRM_MAILS, defaultFormData));
  }, []);

  const id = useSelector((state: State) => getFormField(state, FORM_KEY_CRM_MAILS, "id"));
  const loading: boolean = useLoading<>(GET_FORM_DATA_LOADING, SAVE_FORM_DATA_LOADING);

  useDropdownsStaticList("mail");
  const countries: DropDownObjItemType[] = useList("countries");
  const statuses: DropDownObjItemType[] = useList("status");
  const userPlatforms: DropDownObjItemType[] = useList("userPlatforms");

  const onChangeTargetingType = useCallback((value: string) => {
    dispatch(setErrors({}));
    dispatch(setFormData(FORM_KEY_CRM_MAILS, {
      targetingType: value,
      userGroup: "",
      userCountry: [],
      userEmails: [],
    }));
  }, []);

  return (
    <Form id={FORM_KEY_CRM_MAILS} redirectOnSave="/crm/mails" validate={validate}>
      <FormHeader
        title={id ? `Edit mail: ${String(id)}` : "Create mail"}
        loading={loading}
        isActionVisible={!loading}
      />
      <Container maxWidth="md">
        <SetupCard title="Mail">
          <Box width={1} mt={1}>
            <Loader loading={loading}>
              <TextFieldForm
                name="name"
                label="Name"
              />
              <TextFieldForm
                name="subject"
                label="Title"
              />
              <SelectForm
                name="userType"
                label="User type"
                options={[
                  { value: CRM_MAILS_USER_TYPES.affiliate, label: "affiliate" },
                  { value: CRM_MAILS_USER_TYPES.advertiser, label: "advertiser" },
                ]}
              />
              <SelectForm
                name="userPlatform"
                label="User platform"
                options={userPlatforms}
              />
              <SelectForm
                name="targetingType"
                label="Targeting type"
                onChange={onChangeTargetingType}
                options={[
                  { value: CRM_MAILS_TARGETING_TYPES.country, label: "country" },
                  { value: CRM_MAILS_TARGETING_TYPES.email, label: "email" },
                ]}
                disabled={!!id}
              />
              <ShowByValue name="targetingType" compare={(p) => p === CRM_MAILS_TARGETING_TYPES.country}>
                <SelectForm
                  name="userGroup"
                  label="User status"
                  options={statuses}
                />
                <MultiSelectForm
                  name="userCountry"
                  label="Country"
                  options={countries}
                  enableAddAllBtn
                />
              </ShowByValue>
              <ShowByValue name="targetingType" compare={(p) => p === CRM_MAILS_TARGETING_TYPES.email}>
                <AutocompleteWithFetchForm
                  multiple
                  primitiveValue
                  name="userEmails"
                  label="Email"
                  fetchMethod={fetchCrmMailsSuggestDropdown}
                />
              </ShowByValue>
              <AutocompleteWithFetchForm
                primitiveValue
                name="sender"
                label="Sender"
                fetchMethod={fetchCrmMailsSuggestDropdown}
              />
              <AutocompleteWithFetchForm
                primitiveValue
                name="templateId"
                label="Template"
                fetchMethod={fetchCrmMailsSuggestDropdown}
              />
              <DatePickerForm label="Date Send" name="dateSend" />
            </Loader>
          </Box>
        </SetupCard>
      </Container>
    </Form>
  );
};

export default CrmMailsForm;
