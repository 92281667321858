// @flow
import {
  put,
  select,
  call,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { CHANGE_CRM_MAILS_STATUS_SAGA, CRM_MAILS_TABLE } from "../../helpers/constants/crm";
import { getCrmMailsListSaga } from "../../actions/crmMails";
import { changeCrmMailsStatus } from "../../services/crmMailsApi";
import type { ChangeCrmMailsStatusSaga } from "../../actions/crmMails";
import type { CrmMail } from "../../containers/CrmMailsList";

export function* makeFetch(action: ChangeCrmMailsStatusSaga): Saga<void> {
  try {
    yield put(setLoading(CRM_MAILS_TABLE, true));

    const { statusMsg }: ChangeCrmMailsStatusSaga = action;

    const selections: CrmMail[] = yield select(getTableSelections, CRM_MAILS_TABLE);
    const ids: number[] = selections.map(({ id }: { id: number}): number => id);

    yield call(changeCrmMailsStatus, { id: ids, statusMsg });

    yield put(addNotification({ message: "Status changed", severity: "success" }));

    yield put(setTableSelection(CRM_MAILS_TABLE, []));
    yield put(getCrmMailsListSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Status change failed", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CRM_MAILS_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(CHANGE_CRM_MAILS_STATUS_SAGA, (makeFetch: Function));
}
