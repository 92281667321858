// @flow
import type { AxiosPromise } from "axios";
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import qs from "qs";
import type { Filters, Sorting } from "@fas/ui-framework/lib/redux/reducers/table";
import { requestService } from "../axiosRequest";
import type { PotentialPartnerType } from "../../containers/PotentialPartnerForm/types/PotentialPartnerForm.types";

export const fetchPotentialPartnerById = (id: string): AxiosPromise<*> => requestService
  .get(environment.endpoints.get.getPotentialPartnerById.replace("{id}", id)).then(({ data: { data } }) => data);

export const getPotentialPartnerDropdown = (dictionary: string): AxiosPromise<*> => requestService
  .get(environment.endpoints.get.getPotentialPartnerDropdown, {
    params: {
      dictionary,
    },
  }).then(({ data: { data } }) => data);

export const saveMassAdjustApi = (params: Object): PromiseResponse<{}> => requestService
  .put(environment.endpoints.put.bulkPotentialPartner, params);
export const fetchSavePotentialPartner: (PotentialPartnerType) => AxiosPromise<*> = (
  // eslint-disable-next-line no-unused-vars, object-curly-newline
  { id, additionalData, referrer, visitUrl, ...data }
) => (
  id
    ? requestService.put(environment.endpoints.put.updatePotentialPartner.replace("{id}", id), data)
    : requestService.post(environment.endpoints.post.createPotentialPartner, data)
);

export function fetchPotentialPartnerList(options: {
  page: number,
  limit: number,
  filters: Filters,
  sorting: Sorting,
  headers?: string[],
}): AxiosPromise<mixed, { data: *[], count: number }> {
  const [[current, direction] = []] = Object.entries(options.sorting);

  return requestService
    .get(environment.endpoints.get.getPotentialPartnerList, {
      params: {
        ...options,
        sorting: current ? { current, direction } : undefined,
      },
      paramsSerializer: (params: *): string => qs.stringify(params, { arrayFormat: "brackets", encode: true }),
    });
}

export function fetchRemovePotentialPartner(ids: string[]): AxiosPromise<{}> {
  return requestService
    .delete(environment.endpoints.delete.removePotentialPartner, {
      data: { ids },
    });
}
