// @flow
import React, { type Node, useMemo } from "react";
import { Provider } from "react-redux";
import configureStore from "./store";
import NotificationsContainer from "../../components/NotificationsContainer/NotificationsContainer";
import PotentialPartner from "../../containers/PotentialPartner";

function PotentialPartnerPage(): Node {
  const store = useMemo(() => configureStore());

  return (
    <Provider store={store}>
      <NotificationsContainer />
      <PotentialPartner />
    </Provider>
  );
}

export default PotentialPartnerPage;
