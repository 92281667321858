// @flow
/* eslint-disable import/max-dependencies */
import {
  all,
  call,
  put, select,
  takeEvery,
} from "redux-saga/effects";
import { get } from "lodash";
import type { Saga, Effect } from "redux-saga";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form/selectors";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import type { FormObject } from "@fas/ui-framework/lib/redux/reducers/form";
import type { SelectOfferForSubrateSaga } from "../../actions/subrates";
import { FORM_KEY_SUBRATE, SELECT_OFFER_FOR_SUBRATE_SAGA } from "../../helpers/constants/subrates";
import { SELECT_FIELDS_FOR_SUBRATE } from "../../helpers/constants/loading";
import setLoading from "../../actions/loading/actions";
import { getSubratesDropdowns } from "../../services/subratesApi";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { setDictionary } from "../../actions/dictionary";

// eslint-disable-next-line complexity
export function* selectOfferForSubrate(action: SelectOfferForSubrateSaga): Saga<void> {
  const subrateFields = {
    country: "",
    cpaCampaign: action.offer,
    conversionType: "",
    payoutAmount: "",
    payoutAmountFirst: "",
    payoutAmountRepeats: "",
    increasedCommissionAmount: "",
    increasedCommissionThreshold: "",
  };
  const formData: FormObject = yield select(getFormData, FORM_KEY_SUBRATE);
  if (!action.offer.value) {
    yield put(setFormData(FORM_KEY_SUBRATE, subrateFields));
    return;
  }
  try {
    yield put(setLoading(SELECT_FIELDS_FOR_SUBRATE, true));
    const tasks: Effect[] = [
      call(getSubratesDropdowns, { type: "offerCountry", filter: action.offer.value }),
      call(getSubratesDropdowns, { type: "offerConversionType", filter: action.offer.value }),
    ];

    const [offerCountriesRes, offerConversionTypeRes] = yield all(tasks);
    const prevCountry: ?DropDownObjItemType = offerCountriesRes.find(
      (country: DropDownObjItemType) => country.value === formData.country
    );
    const conversionType = get(offerConversionTypeRes, "[0].value", "");
    yield put(setDictionary({ countriesList: offerCountriesRes }));
    yield put(setFormData(FORM_KEY_SUBRATE, {
      ...subrateFields,
      conversionType,
      country: prevCountry ? prevCountry.value : "",
      payoutAmountFirst: conversionType === "revshare" ? formData.payoutAmountFirst || "" : null,
      payoutAmountRepeats: conversionType === "revshare" ? formData.payoutAmountRepeats || "" : null,
      increasedCommissionAmount: conversionType !== "revshare" ? formData.increasedCommissionAmount || "" : null,
      increasedCommissionThreshold: conversionType !== "revshare" ? formData.increasedCommissionThreshold || "" : null,
      payoutAmount: conversionType !== "revshare" ? formData.payoutAmount || "" : null,
    }));
  }
  catch (err) {
    yield put(setFormData(FORM_KEY_SUBRATE, subrateFields));
    if (err.isAxiosError
      && err.response
      && err.response.data
      && err.response.data.errors
      && Array.isArray(err.response.data.errors)) {
      yield put(setErrors(prepareFetchErrors(err.response.data.errors)));
    }
    else {
      yield put(addNotification({ message: err.errorMessage || "Failed to select", severity: "error" }));
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
  finally {
    yield put(setLoading(SELECT_FIELDS_FOR_SUBRATE, false));
  }
}

export default function* watchSelectOfferForSubrate(): Saga<void> {
  yield takeEvery(SELECT_OFFER_FOR_SUBRATE_SAGA, (selectOfferForSubrate: Function));
}
