/* eslint-disable import/max-dependencies */
// @flow
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTableSelections,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CHANGE_PAYOUT_TERMS_ACTIVES_SAGA, PAYOUT_TERMS_LIST_TABLE } from "../../helpers/constants/payoutTermsList";
import { changePayoutTermsActive } from "../../services/payoutTermsApi";
import { getPayoutTermsListSaga } from "../../actions/payoutTermsList";
import type { PayoutTermsObjectType } from "../../containers/PayoutTermsList/types/PayoutTermsList.types";

export function* changePayoutTermsActives(): Saga<void> {
  try {
    yield put(setLoading(PAYOUT_TERMS_LIST_TABLE, true));

    const selected: PayoutTermsObjectType[] = yield select(getTableSelections, PAYOUT_TERMS_LIST_TABLE);
    const selectedIds: Array<string> = selected.map(({ id }: PayoutTermsObjectType): string => id);

    if (selected) {
      yield call(changePayoutTermsActive, selectedIds);
      yield put(addNotification({ message: "Status changed", severity: "success" }));
      yield put(setTableSelection(PAYOUT_TERMS_LIST_TABLE, []));
      yield put(getPayoutTermsListSaga());
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Active status change failed", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(PAYOUT_TERMS_LIST_TABLE, false));
  }
}

export default function* watchChangePayoutTermsActives(): Saga<void> {
  yield takeEvery(CHANGE_PAYOUT_TERMS_ACTIVES_SAGA, (changePayoutTermsActives: Function));
}
