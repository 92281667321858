// @flow
import environment from "environment";
import { requestService } from "../axiosRequest";
import { type DictionaryParams } from "../../actions/dictionary";
import type { DropDownObjItemType } from "../../reducers/dictionaries";

// eslint-disable-next-line import/prefer-default-export
export function fetchDropdownList({ dictionary, ...params }: DictionaryParams): Promise<DropDownObjItemType[]> {
  return requestService
    .get(environment.endpoints.get.getDropdownList.replace("{dictionary}", dictionary), {
      params,
    }).then(({ data: { data } }) => data);
}

export function fetchDropdownsStatic(dictionaryName: string): Promise<{ [string]: DropDownObjItemType[] }> {
  return requestService
    .get(environment.endpoints.get.getDropdownStatic.replace("{dictionaryName}", dictionaryName))
    .then(({ data: { data } }) => data);
}
