// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  name: Joi.string().required(),
  performers: Joi.array().min(1).required(),
}).messages({
  "string.empty": "This field is required",
  "array.min": "This field is required",
}).options({ stripUnknown: true });

export default joiResolver(schema);
