// @flow

import {
  CHANGE_PAYOUT_TERMS_ACTIVES_SAGA,
  GET_PAYOUT_TERMS_LIST_SAGA,
} from "../../helpers/constants/payoutTermsList";

export type GetPayoutTermsListSaga = {|
  type: typeof GET_PAYOUT_TERMS_LIST_SAGA,
|}

export type ChangePayoutTermsActives = {|
  type: typeof CHANGE_PAYOUT_TERMS_ACTIVES_SAGA,
  isActive: 1 | 0,
|}

export type Actions = GetPayoutTermsListSaga
  | ChangePayoutTermsActives

export const getPayoutTermsListSaga = (): GetPayoutTermsListSaga => ({
  type: GET_PAYOUT_TERMS_LIST_SAGA,
});

export const changePayoutTermsActives = (isActive: 1 | 0): ChangePayoutTermsActives => ({
  type: CHANGE_PAYOUT_TERMS_ACTIVES_SAGA,
  isActive,
});
