// @flow
/* eslint-disable import/max-dependencies */
import environment from "environment";
import type { DropdownItem } from "@fas/ui-framework/lib/redux/reducers/dropdowns";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../axiosRequest";
import type { CrmMailFromData } from "../../containers/CrmMailsForm";

export const fetchCrmMailsList: ({
  filters: Array<{ name: string, filters: Array<string | number | boolean> }>,
  sorting: { current: string, direction: mixed },
  page: number,
  limit: number,
}) => PromiseResponse<*> = (options) => requestService
  .post(environment.endpoints.post.getTableFilterData.replace("{dataSourceName}", "mail"), {
    ...options,
    headers: [
      "id",
      "name",
      "dateSend",
      "statusMsg",
      "userPlatform",
      "createdAt",
      "updatedBy",
    ],
  })
  .then(({ data }) => data);

export const fetchCrmMail: (id: number) => Promise<*> = (id) => requestService
  .get(environment.endpoints.get.getCrmMailById.replace("{id}", String(id)))
  .then(({ data }) => data);

export const changeCrmMailsStatus: ({ statusMsg: string, id: Array<number> }) => Promise<*> = (
  params
) => requestService.post(environment.endpoints.post.changeCrmMailsStatus, params);

export const sendCrmMails: ({ typeMsg: string, id: Array<number> }) => Promise<*> = (
  params
) => requestService.post(environment.endpoints.post.sendCrmMails, params);

export const fetchCrmMailsSuggestDropdown: ({
  type: string, filter: string,
}) => Promise<Array<DropdownItem<string>>> = ({
  type, filter,
}) => requestService
  .get(environment.endpoints.get.getDropdownList.replace("{dictionary}", "mail"), {
    params: {
      type,
      filter,
    },
  })
  .then(({ data: { data: list } }) => list);

export async function saveCrmMail({
  id,
  ...body
}: CrmMailFromData) {
  if (id) {
    await requestService.put(environment.endpoints.put.updateCrmMail.replace("{id}", String(id)), body);
  }
  else {
    await requestService.post(environment.endpoints.post.createCrmMail, body)
      .then(({ data }) => data.id);
  }
}
