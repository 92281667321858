// @flow
import {
  put,
  select,
  takeEvery,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import moment from "moment";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CPA_OFFER_CAMPAIGNS_TABLE, DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA } from "../../helpers/constants/cpaoffer";
import type { CpaOfferCampaignData } from "../../services/cpaOffersApi/cpaOffers";
import downloadCsv from "../../helpers/downloadCsvFile/downloadCsvFile";
import escapeCsvValue from "../../helpers/downloadCsvFile/escapeCsvValue";

export function* downloadCpaOfferCampaigns(): Saga<void> {
  try {
    yield put(setLoading(CPA_OFFER_CAMPAIGNS_TABLE, true));

    const selectedRows: Array<CpaOfferCampaignData> = yield select(getTableSelections, CPA_OFFER_CAMPAIGNS_TABLE);
    if (selectedRows.length) {
      const csvHeader: string = "Offer ID, Offer name, Basic ID, Basic name, Funnel name, Created at, Split, Basic status, Offer status, Offer status in basic\n";
      const csvValues: string = selectedRows.map((i: CpaOfferCampaignData): string => {
        const item: * = {
          ...escapeCsvValue(i),
          funnel: i.funnel.funnelName,
          created: moment(i.created).format("MM.DD.YY hh:mm:ss"),
          campaignStatus: i.campaignStatus ? "Yes" : "No",
          offerStatus: i.offerStatus ? "Yes" : "No",
          offerInCampaignStatus: i.offerInCampaignStatus ? "Yes" : "No",
        };
        return Object.values(item).join(",");
      }).join("\n");

      yield call(downloadCsv, csvHeader + csvValues, `CpaOfferCampaigns_${moment().format("MM.DD.YY")}.csv`);
    }
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Error while downloading csv", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_OFFER_CAMPAIGNS_TABLE, false));
  }
}

export default function* watchDownloadCpaOfferCampaigns(): Saga<void> {
  yield takeEvery(DOWNLOAD_CPA_OFFER_CAMPAIGN_SAGA, (downloadCpaOfferCampaigns: Function));
}
