// @flow
import Joi from "joi";
import joiResolver from "../../services/form/joiResolver";

const schema = Joi.object({
  department: Joi.string().required(),
}).messages({
  "string.empty": "This field is required",
}).options({ stripUnknown: true });

export default joiResolver(schema);
