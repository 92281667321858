/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Table } from "@fas/ui-core";
import type { TableProps } from "@fas/ui-framework/lib/services/table";
import { useTable } from "@fas/ui-framework/lib/services/table";
import {
  getPostbackEventsSaga,
  openEventPostbackModal,
} from "../../../../../../actions/eventsPostback";
import {
  columns,
} from "./utils";
import type {
  ManageAffiliate,
} from "../../../../../../reducers/manageAffiliate";
import FieldsForm from "./components/FieldsForm/FieldsForm";
import { getIsAddEventPostbackModalOpen } from "../../../../../../selectors/manageAffiliate";
import type { GetPostbackEventsSaga } from "../../../../../../actions/eventsPostback";
import { POSTBACK_EVENTS_TABLE } from "../../../../../../helpers/constants/EventsPostback";

type Props = {
  manageAffiliate: ManageAffiliate,
  getEvents: () => any,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

const mapStateToProps = (state) => ({
  manageAffiliate: state.manageAffiliate,
  isOpenModal: getIsAddEventPostbackModalOpen(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleOpenEventPostbackModal: (val) => dispatch(openEventPostbackModal(val)),
});

const EventsPostbackInfo = ({
  manageAffiliate,
  handleOpenEventPostbackModal,
  isOpenModal,
}: Props) => {
  const classes = useStyles();
  const tableProps: TableProps = useTable(POSTBACK_EVENTS_TABLE);

  const dispatch: <A>(A) => A = useDispatch();

  const onGetPostbackEventsSaga: () => GetPostbackEventsSaga = () => (
    dispatch(getPostbackEventsSaga()));

  const { lists } = manageAffiliate.getIn(["postbackInfo"]).toJS();

  return (
    <Paper
      className={classes.root}
      elevation={3}
    >
      <Box width="100%" data-testid="events-postbacks">
        {/* $FlowFixMe */}
        <Table
          {...tableProps}
          title="Info events postbacks"
          rowSelectAvailable={(): boolean => false}
          allSelectAvailable={(): boolean => false}
          columns={columns}
          onLoad={onGetPostbackEventsSaga}
          onCreate={() => handleOpenEventPostbackModal(true)}
        />
      </Box>
      {isOpenModal && (
        <FieldsForm
          lists={lists}
        />
      )}
    </Paper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsPostbackInfo);
