// @flow
import {
  GET_TOPOFFERS_LIST_SAGA,
  GET_TOPOFFERS_DROPDOWN_LIST_SAGA,
} from "../../helpers/constants/topOffers";

export type GetTopOffersListSaga = {|
  type: typeof GET_TOPOFFERS_LIST_SAGA,
|};

export type GetTopOffersDropdownListsSaga = {|
  type: typeof GET_TOPOFFERS_DROPDOWN_LIST_SAGA,
|};

export type Actions =
  GetTopOffersListSaga
  | GetTopOffersDropdownListsSaga;

export const getTopOffersListSaga = (): GetTopOffersListSaga => ({
  type: GET_TOPOFFERS_LIST_SAGA,
});

export const getTopOffersDropdownListSaga: () => GetTopOffersDropdownListsSaga = (): GetTopOffersDropdownListsSaga => ({
  type: GET_TOPOFFERS_DROPDOWN_LIST_SAGA,
});
