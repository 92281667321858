// @flow
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import DocumentTitle from "react-document-title";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NotificationsContainer from "../../components/NotificationsContainer";
import configureStore from "./store";
import CpaOfferIndex from "../../containers/CpaOfferIndex";
import CpaOffersActionRow from "../../components/CpaOffersActionRow";

type Classes = {
  pageWrapper: string,
}

const useTableStyles: () => Classes = makeStyles({
  pageWrapper: {
    maxHeight: "calc(100vh - 64px)",
    boxSizing: "border-box",
    "& > div:nth-child(2)": {
      height: "calc(100% - 32px)",
    },
  },
});

function CpaOffer() {
  const store = useMemo(() => configureStore(), []);
  const classes: Classes = useTableStyles();

  return (
    <DocumentTitle title="CPA - CpaOffers list">
      <Provider store={store}>
        <NotificationsContainer />
        <Box p={3} className={classes.pageWrapper}>
          <CpaOffersActionRow />
          <CpaOfferIndex />
        </Box>
      </Provider>
    </DocumentTitle>
  );
}

export default CpaOffer;
