// @flow
/* eslint-disable import/max-dependencies */
import {
  combineReducers,
  applyMiddleware,
  createStore,
  compose,
} from "redux";
import type { Dispatch, Store, CombinedReducer } from "redux";
import createSagaMiddleware from "redux-saga";
import type { SagaMiddleware } from "redux-saga";
import Immutable from "immutable";
import notifications, {
  type State as NotificationsState,
  initNotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type {
  Action as NotificationsActions,
} from "@fas/ui-framework/lib/redux/actions/notifications";
import { tableReducer as tables, initTableState, type TableState } from "@fas/ui-framework/lib/redux/reducers/table";
import type { Actions as TableActions } from "@fas/ui-framework/lib/redux/actions/table/actions";
import mainSaga from "./saga";
import dictionaries, {
  type State as DictionariesState,
  initState as initDictionariesState,
} from "../../reducers/dictionaries";
import loading, {
  type State as LoadingState,
  type Action as LoadingAction,
  initState as initLoadingState,
} from "../../reducers/loading";
import { TOPOFFERS_TABLE } from "../../helpers/constants/topOffers";

export type State = $ReadOnly<{|
  dictionaries: DictionariesState,
  loading: LoadingState<typeof TOPOFFERS_TABLE>,
  tables: TableState,
  notifications: NotificationsState,
|}>

export function mapPageToState(): State {
  return {
    dictionaries: initDictionariesState(),
    loading: initLoadingState({
      [TOPOFFERS_TABLE]: false,
    }),
    tables: initTableState({
      [TOPOFFERS_TABLE]: { pageSize: 20, sorting: { id: "desc" } },
    }),
    notifications: initNotificationsState(),
  };
}

export type Actions = LoadingAction<typeof TOPOFFERS_TABLE>
  | TableActions
  | NotificationsActions;

const reducers: CombinedReducer<State, Actions> = combineReducers({
  dictionaries,
  loading,
  tables,
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): Store<State, Actions> => {
  // eslint-disable-next-line
  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapPageToState(),
    composeEnhancers(applyMiddleware(
      sagaMiddleware
    ))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};
