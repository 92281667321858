import { getTableFilters } from "@fas/ui-framework";
import useTenantId from "../useTenantId";
import useAffiliateId from "../ProfileAffiliate/useAffiliateId";
import { useSelector } from "../store";
import { EXT_RATE_AFFILIATES_TABLE_KEY } from "../../../helpers/constants/affiliates";
import type { FiltersType } from "./types";

export default function useExtRateQueryKey() {
  const tenantId = useTenantId();
  const affiliateId = useAffiliateId();
  const filters = useSelector((
    state
  ) => getTableFilters<FiltersType>(state, EXT_RATE_AFFILIATES_TABLE_KEY));
  return [tenantId, affiliateId, EXT_RATE_AFFILIATES_TABLE_KEY, filters, ""];
}
