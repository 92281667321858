// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type Element,
  type StatelessFunctionalComponent,
} from "react";
import { useDispatch } from "react-redux";
import { useTable, type TableProps } from "@fas/ui-framework/lib/services/table";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Check,
  Edit,
  RemoveCircle,
} from "@mui/icons-material";
import {
  Table,
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  SelectSearchComponent,
  NumberSearchComponent,
  IsActiveLabel,
} from "@fas/ui-core";
import type { Column } from "@fas/ui-core/lib/Table/Table.types";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import { PRODUCT_COMPANY_LIST_TABLE } from "../../helpers/constants/productCompany";
import { userService } from "../../services/user";
import {
  getProductCompanyListSaga,
  changeProductCompanyActivesSaga,
  type GetProductCompanyListSaga,
  type ChangeProductCompanyActivesSaga,
} from "../../actions/productCompany";
import type { ProductCompanyType } from "./types/ProductCompanyList.types";

const onCreate: () => void = () => {
  window.location = "/smuProductCompany/create";
};

const useStyles = makeStyles((theme) => ({
  clearFiltersButton: {
    textTransform: "none",
    marginBottom: theme.spacing(1),
  },
  smallTableCol: {
    width: "120px",
    minWidth: "120px",
  },
}));

const ProductCompanyListContainer: StatelessFunctionalComponent<{}> = () => {
  const classes = useStyles();
  const tableProps: TableProps = useTable(PRODUCT_COMPANY_LIST_TABLE);
  const dispatch: <A>(A) => A = useDispatch();

  const { selected, onChangeFilters }: TableProps = tableProps;

  const onGetProductCompanyList: () => GetProductCompanyListSaga = () => (
    dispatch(getProductCompanyListSaga()));
  const onChangeProductCompanyActives: (isActive: boolean) => ChangeProductCompanyActivesSaga = (isActive) => (
    dispatch(changeProductCompanyActivesSaga(isActive)));

  const columns: Array<Column<ProductCompanyType>> = [
    {
      field: "id",
      label: "Product company id",
      searchable: true,
      sortable: true,
      SearchComponent: NumberSearchComponent,
    },
    {
      field: "name",
      label: "Product company name",
      sortable: true,
      searchable: true,
    },
    {
      field: "isActive",
      label: "Is Active",
      searchable: true,
      sortable: true,
      render: IsActiveLabel,
      className: classes.smallTableCol,
      SearchComponent: (
        searchProps: SearchComponentProps
      ): Element<typeof SelectSearchComponent> => (
        <SelectSearchComponent
          {...searchProps}
          values={[
            { label: "All", value: "" },
            { label: "Yes", value: "1" },
            { label: "No", value: "0" },
          ]}
        />
      ),
    },
  ];

  return (
    <Box p={3} textAlign="right">
      <Button
        className={classes.clearFiltersButton}
        variant="outlined"
        color="primary"
        data-testid="clearFilterButton"
        onClick={() => onChangeFilters({})}
      >
        Clear filters
      </Button>
      <Table
        {...tableProps}
        title="SMU Product Companies"
        columns={columns}
        rowSelectAvailable={(): boolean => true}
        allSelectAvailable={(): boolean => true}
        onCreate={onCreate}
        onLoad={onGetProductCompanyList}
        Actions={(
          <TableActions>
            <TableActionGroup>
              <SimpleTableActionButton
                tooltip="Edit"
                disabled={selected.length > 1 || !userService.can([{ path: "/api/v1/bank/companies", method: "POST" }])}
                onClick={() => {
                  const { id }: ProductCompanyType = selected[0];
                  window.location = `/smuProductCompany/${id}`;
                }}
                Icon={<Edit />}
                data-testid="editButton"
              />
              <SimpleTableActionButton
                tooltip="Activate"
                disabled={(
                  selected.some(({ isActive }: ProductCompanyType): boolean => isActive)
                  || !userService.can([{ path: "/api/v1/bank/companies", method: "POST" }])
                )}
                onClick={(): ChangeProductCompanyActivesSaga => onChangeProductCompanyActives(true)}
                Icon={<Check />}
                data-testid="activateButton"
              />
              <SimpleTableActionButton
                tooltip="Deactivate"
                disabled={(
                  selected.some(({ isActive }: ProductCompanyType): boolean => !isActive)
                  || !userService.can([{ path: "/api/v1/bank/companies", method: "POST" }])
                )}
                onClick={(): ChangeProductCompanyActivesSaga => onChangeProductCompanyActives(false)}
                Icon={<RemoveCircle />}
                data-testid="deactivateButton"
              />
            </TableActionGroup>
          </TableActions>
        )}
      />
    </Box>
  );
};

export default ProductCompanyListContainer;
