/* eslint-disable import/max-dependencies */
// @flow
import React from "react";
import environment from "environment";
import type { StatelessFunctionalComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableActions,
  TableActionGroup,
  SimpleTableActionButton,
  ExternalRedirect,
} from "@fas/ui-core";
import { getTableSelections, getTableIsGlobalSelected } from "@fas/ui-framework/lib/redux/selectors/table";
import {
  Delete, DoneAllOutlined, Edit, AccessAlarms,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import type { SubrateDataObjType } from "../../reducers/subrates";
import redirect from "../../components/App/ExternalRedirect";
import { COUNTRY_NULL_URL_VIEW, SUBRATES_INFO_TABLE } from "../../helpers/constants/subrates";
import type { State } from "../../pages/Subrates/store";
import { deleteSubrateSaga, type DeleteSubrateSagaType } from "../../actions/subrates";
import ConfirmTableActionButton from "../../components/SubrateInfoConfirmActionButton";
import Can from "../../components/Can/Can";

type Props = $ReadOnly<{|
  onToggleMassAdjust: (boolean) => mixed,
|}>;

type UseSubrateInfoActionsHook = {|
  selected: SubrateDataObjType[],
  isGlobalSelected: boolean,
  onEdit: () => mixed,
  onDelete: () => mixed,
  onCronusTriggerCreate: () => mixed,
|};

const selectTableSelections: (State) => SubrateDataObjType[] = (state) => getTableSelections(
  state,
  SUBRATES_INFO_TABLE
);
const selectIsGlobalSelected: (State) => boolean = (state) => getTableIsGlobalSelected(
  state,
  SUBRATES_INFO_TABLE
);

const useSubrateInfoActions: () => UseSubrateInfoActionsHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const search: URLSearchParams = new URLSearchParams(useLocation().search);

  const selected: SubrateDataObjType[] = useSelector(selectTableSelections);
  const isGlobalSelected: boolean = useSelector(selectIsGlobalSelected);

  const onEdit: () => mixed = () => {
    const { id }: SubrateDataObjType = selected[0] || {};
    redirect({ to: `/subrates/edit/${id || ""}` });
  };
  const onDelete: () => DeleteSubrateSagaType = () => dispatch(deleteSubrateSaga());

  const onCronusTriggerCreate = () => {
    const params = new URLSearchParams();
    const paramsList: string[] = ["affId", "offerId", "country", "s3", "utmContent"];
    const keyMapper = {
      affId: "affId",
      offerId: "cpaCampaignId",
      country: "country",
      s3: "s3",
      utmContent: "utmContent",
    };
    paramsList.forEach((p) => {
      const selectedItem = selected[0];
      const newParam: string | null = search.get(p) || selectedItem[p];
      if (newParam) {
        params.set(keyMapper[p], newParam);
      }
    });
    if (params.get("country") === COUNTRY_NULL_URL_VIEW) {
      params.set("country", "");
    }
    ExternalRedirect({ to: `${environment.appLinks.cronusAdmin}subrate/create?${params.toString()}`, target: "_blank" });
  };

  return {
    selected,
    isGlobalSelected,
    onEdit,
    onDelete,
    onCronusTriggerCreate,
  };
};

const SubrateInfoActions: StatelessFunctionalComponent<Props> = ({ onToggleMassAdjust }) => {
  const {
    selected,
    isGlobalSelected,
    onEdit,
    onDelete,
    onCronusTriggerCreate,
  }: UseSubrateInfoActionsHook = useSubrateInfoActions();
  return (
    <TableActions>
      <TableActionGroup>
        <SimpleTableActionButton
          tooltip="Edit"
          disabled={selected.length > 1 || isGlobalSelected}
          onClick={onEdit}
          Icon={<Edit />}
          data-testid="editButton"
        />
        <ConfirmTableActionButton
          items={selected}
          disabled={isGlobalSelected}
          tooltip="Delete"
          onClick={onDelete}
          Icon={<Delete />}
        />
        <SimpleTableActionButton
          tooltip="Mass Adjust"
          onClick={(): mixed => onToggleMassAdjust(true)}
          Icon={<DoneAllOutlined />}
        />
        <Can permissions={[{ method: "POST", path: "/api/v1/cronus/trigger/subrate" }]}>
          <SimpleTableActionButton
            tooltip="Create cronus trigger"
            Icon={<AccessAlarms />}
            onClick={onCronusTriggerCreate}
            disabled={selected.length > 1}
          />
        </Can>
      </TableActionGroup>
    </TableActions>
  );
};

export default SubrateInfoActions;
