// @flow
import {
  takeEvery,
  call,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { BULK_CHANGE_AUTHOR_SAGA } from "../../helpers/constants/manageAffiliate";
import { bulkChangeAuthor } from "../../services/manageAffiliateApi";
import type { BulkChangeAuthorSaga } from "../../actions/manageAffiliate";
import { setSelectedRows, switchChangeAuthorModalOpened } from "../../actions/manageAffiliate";

export function* makeFetch(action: BulkChangeAuthorSaga): Saga<void> {
  const {
    affiliates,
    user,
  }: BulkChangeAuthorSaga = action;
  try {
    yield call(bulkChangeAuthor, user, affiliates);
    yield put(addNotification({ message: "Manager has been changed", severity: "success" }));
    yield put(setSelectedRows([]));
    yield put(switchChangeAuthorModalOpened());
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchBulkChangeAuthor(): Saga<void> {
  // todo add event type here
  yield takeEvery(BULK_CHANGE_AUTHOR_SAGA, (makeFetch: Function));
}
