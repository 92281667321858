// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import watchGetTopOffer from "../../sagas/getTopOffersList/getTopOffers";
import watchGetTopOffersDropdownLists from "../../sagas/getTopOffersDropdownLists";

export default function* mainSaga(): Saga<void> {
  yield all([
    call(watchGetTopOffer),
    call(watchGetTopOffersDropdownLists),
  ]);
}
