// @flow
import React, {
  useState, useCallback, useRef, useEffect,
} from "react";
import type { StatelessFunctionalComponent } from "react";
import {
  Box,
  Button,
  MenuItem,
  Menu,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AddContent,
} from "@fas/ui-core";
import type { ClassNameMap } from "@mui/styles/withStyles";
import ViewRow from "./ViewRowDefault";
import ViewRowCountry from "./ViewRowCountry";
import type { UseStylesTypes, UseStateType } from "../../containers/SubrateInfo";

const useStyles: UseStylesTypes = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: `${theme.shape.borderRadius}`,
  },
  modalHeader: {
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: "#fff",
  },
  bodyContainer: {
    height: "100%",
    overflow: "hidden",
    minHeight: "300px",
  },
  rootBtn: {
    margin: `0 ${theme.spacing(2)}`,
  },
  modalFooter: {
    borderTop: `1px solid ${theme.palette.borderColor}`,
    padding: theme.spacing(2),
  },
}));

export type ItemType = { title: string, value: string, type: string, min?: number, max?: number };
type Props = {
  selectedCount: number,
  handleCancel: () => void,
  handleApply: ({ [string]: string | number }) => void,
  valueList: Array<ItemType>,
}

const MassAdjustModal: StatelessFunctionalComponent<Props> = ({
  selectedCount, handleCancel, handleApply, valueList,
}) => {
  const classes: ClassNameMap = useStyles();

  const anchorEl = useRef(null);

  const [isListOpened, setIsListOpened]: UseStateType<boolean> = useState(false);
  const [selectedList, setSelectedList]:
    UseStateType<Array<ItemType>> = useState([]);
  const [isErrorsVisible, setIsErrorVisible]: UseStateType<boolean> = useState(false);
  const [fields, setFields]: UseStateType<{ [string]: string | number }> = useState({});

  const handleChoose: (ItemType) => void = useCallback((item) => {
    setSelectedList([...selectedList, { ...item }]);
    setFields({
      ...fields,
      [item.value]: "",
    });
    setIsListOpened(false);
  }, [fields, selectedList]);
  const handleDelete: (ItemType) => void = useCallback((field) => {
    setSelectedList(selectedList.filter((item: ItemType) => item.value !== field.value));
    const newFields: { [string]: string | number } = {
      ...fields,
    };
    delete newFields[field.value];
    setFields(newFields);
  }, [fields, selectedList]);
  const onApply: () => void = useCallback(() => {
    const fieldValues: Array<string | number> = (Object.values(fields): any);
    if (fieldValues.includes("")) {
      setIsErrorVisible(true);
      return;
    }
    if (!fieldValues.length) {
      handleCancel();
      return;
    }
    handleApply(fields);
  }, [fields, handleApply, handleCancel]);
  const onTitileClick = useCallback(() => {
    setIsListOpened(true);
  }, [setIsListOpened]);

  useEffect(() => {
    setIsErrorVisible(false);
  }, [fields, isListOpened]);

  const viewRowComponentMap: {[string]: StatelessFunctionalComponent<{item: ItemType}>} = {
    shaveAmount: ({ item }: {item: ItemType}) => {
      const options = [{ title: "Empty", value: null }, ...new Array(101).fill(0).map((v, i) => ({ title: String(i), value: i }))];
      const value = options.find((i) => i.value === fields[item.value]) || null;
      return (
        <ViewRowCountry
          item={item}
          value={value}
          // $FlowFixMe option value is number | null
          options={options}
          setFields={setFields}
          fields={fields}
          isErrorsVisible={isErrorsVisible}
          handleDelete={handleDelete}
        />
      );
    },
  };

  const defaultRow: StatelessFunctionalComponent<{item: ItemType}> = ({ item }: {item: ItemType}) => (
    <ViewRow
      item={item}
      setFields={setFields}
      fields={fields}
      isErrorsVisible={isErrorsVisible}
      handleDelete={handleDelete}
    />
  );

  return (
    <Box className={classes.modal} data-testid="templateModal">
      <Box className={classes.modalHeader}>
        Mass adjust:
        {" "}
        { selectedCount }
        {" "}
        Subrates
      </Box>
      <Box p={2} className={classes.bodyContainer}>
        <Box mb={2}>
          <div ref={anchorEl}>
            <AddContent
              title="+ Add subrates option"
              isArrowVisible
              onTitileClick={onTitileClick}
              onArrowClick={onTitileClick}
            />
          </div>
          <Menu
            PaperProps={{
              style: {
                width: "calc(100% - 64px)",
                maxWidth: "600px",
              },
            }}
            anchorEl={anchorEl.current}
            open={isListOpened}
            onClose={() => {
              setIsListOpened(false);
            }}
          >
            {valueList.map((item: ItemType) => (
              <MenuItem
                key={item.value}
                disabled={Boolean(selectedList.find((selItem: ItemType) => selItem.value === item.value))}
                onClick={() => handleChoose(item)}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {selectedList.map((item: ItemType) => {
          const Row: StatelessFunctionalComponent<{item: ItemType}> = viewRowComponentMap[item.value] || defaultRow;
          return <Row key={item.value} item={item} />;
        })}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        p={2}
        borderTop={1}
        borderColor="background.mainBg"
      >
        <Button
          classes={{ root: classes.rootBtn }}
          color="primary"
          onClick={handleCancel}
          data-testid="backModalButton"
        >
          Cancel
        </Button>
        <Button
          classes={{ root: classes.rootBtn }}
          color="primary"
          onClick={onApply}
          data-testid="saveModalButton"
        >
          Apply
        </Button>
      </Box>
    </Box>
  );
};

export default MassAdjustModal;
