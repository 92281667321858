// @flow
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  CircularProgress,
} from "@mui/material";
import GeneralOptions from "./components/GeneralOptions";
import AffiliateInfo from "./components/AffiliateInfo";
import AffiliateAddress from "./components/AffiliateAddress";
import ManagerSettings from "./components/ManagerSettings";
import {
  setPersonalInfoField,
  setAffiliateCurrentTablet,
  clearPersonalInfo,
  clearErrors,
  isPersonalInfoFetched,
  getAffiliateSaga,
} from "../../../../actions/manageAffiliate";
import {
  mapManageAffiliates,
} from "./utils";

type Props = {};

const mapStateToProps = (state) => ({
  manageAffiliate: mapManageAffiliates(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAffiliateField: (field, value) => dispatch(setPersonalInfoField({ field, value })),
  setCurrentTablet: (value) => dispatch(setAffiliateCurrentTablet(value)),
  clearPersonalInfoFields: () => dispatch(clearPersonalInfo()),
  resetErrors: () => dispatch(clearErrors()),
  getPersonalInfo: (id) => dispatch(getAffiliateSaga(id)),
  setPersonalInfoFetched: () => dispatch(isPersonalInfoFetched()),
});

const PersonalInfo = ({
  manageAffiliate,
  setAffiliateField,
  setCurrentTablet,
  clearPersonalInfoFields,
  resetErrors,
  getPersonalInfo,
  setPersonalInfoFetched,
}: Props) => {
  const {
    personalInfo,
    lists,
    errors,
    affiliateCurrentTablet,
    affiliateId,
    isPersonalInfoFetcing,
  } = manageAffiliate;

  useEffect(() => {
    if (affiliateId) {
      clearPersonalInfoFields();
      getPersonalInfo(affiliateId);
      setPersonalInfoFetched();
    }
    else {
      setPersonalInfoFetched();
    }
    return () => {
      resetErrors();
      setCurrentTablet("");
    };
  }, []);

  return (
    <Box
      display="flex"
      alignItems="stretch"
      flexDirection="column"
      m={2}
      mb={0}
    >
      {isPersonalInfoFetcing ? (
        <Box
          alignSelf="center"
          mt={10}
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <>
          <GeneralOptions
            title="General options"
            name="generalOptions"
            currentTablet={affiliateCurrentTablet}
            editTitle="Edit general options"
            handleChangeTablet={setCurrentTablet}
            setAffiliateField={setAffiliateField}
            personalInfo={personalInfo}
            lists={lists}
            errors={errors}
            fields={[
              "shaveAmount",
              "ncrAmount",
              "ncrUnit",
              "accountId",
            ]}
          />
          <AffiliateInfo
            title="Affiliate info"
            name="affiliateInfo"
            currentTablet={affiliateCurrentTablet}
            editTitle="Edit affiliate info"
            handleChangeTablet={setCurrentTablet}
            setAffiliateField={setAffiliateField}
            affiliateId={affiliateId}
            personalInfo={personalInfo}
            lists={lists}
            errors={errors}
            fields={[
              "id",
              "name",
              "firstName",
              "lastName",
              "email",
              "imType",
              "imName",
              "phone",
            ]}
          />
          <AffiliateAddress
            title="Affiliate address"
            name="affiliateAddress"
            currentTablet={affiliateCurrentTablet}
            editTitle="Edit affiliate address"
            handleChangeTablet={setCurrentTablet}
            setAffiliateField={setAffiliateField}
            personalInfo={personalInfo}
            lists={lists}
            errors={errors}
            fields={[
              "address",
              "city",
              "zip",
              "state",
              "country",
              "taxSSN",
              "countryByIp",
            ]}
          />
          <ManagerSettings
            title="Manager settings"
            name="managerSettings"
            currentTablet={affiliateCurrentTablet}
            editTitle="Edit manager settings"
            handleChangeTablet={setCurrentTablet}
            setAffiliateField={setAffiliateField}
            affiliateId={affiliateId}
            personalInfo={personalInfo}
            lists={lists}
            errors={errors}
            fields={[
              "manager",
              "revenue",
              "refSrc",
              "topVerticals",
              "companyName",
              "declineReasonId",
              "declineReasonDescription",
              "topCountries",
              "type",
              "status",
              "currency",
              "paymentMethodId",
              "fraudStatus",
              "topVerticalsComment",
              "trafficType",
              "howFoundOutComment",
              "howFoundOut",
              "companyUrl",
              "companyType",
              "companyTypeComment",
            ]}
          />
        </>
      )}
    </Box>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfo);
