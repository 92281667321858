// @flow
import {
  call,
  put,
  select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { CHANGE_TEMPLATE_STATUS_SAGA } from "../../helpers/constants/templates";
import { changeTemplateStatus } from "../../services/templatesApi";
import { getReportName } from "../../selectors/templates";
import { type ChangeTemplateStatusSaga, getTemplatesSaga } from "../../actions/templates";

export function* changeStatus({ payload: { item, relation } }: ChangeTemplateStatusSaga): Saga<void> {
  yield put(setLoading("getTemplatesList", true));
  try {
    const reportName: string = yield select(getReportName);

    yield call(changeTemplateStatus, reportName, item.id, relation);
    yield put(addNotification({ message: `Template ${item.name} ${relation} status changed`, severity: "success" }));
    yield put(getTemplatesSaga());
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to change status", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
    yield put(setLoading("getTemplatesList", false));
  }
}

export function* watchChangeTemplateStatus(): Saga<void> {
  yield takeEvery(CHANGE_TEMPLATE_STATUS_SAGA, changeStatus);
}
