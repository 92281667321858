// @flow
import React, { type Node, useMemo } from "react";
import DocumentTitle from "react-document-title";
import { Provider } from "react-redux";
import { Box } from "@mui/material";
import NotificationsContainer from "../../components/NotificationsContainer/NotificationsContainer";
import configureStore from "./store";
import AdvertiserInvoices from "../../containers/AdvertiserInvoices";

function AdvertiserInvoicesPage(): Node {
  const store = useMemo(() => configureStore(), []);
  return (
    <DocumentTitle title="Advertiser invoices">
      <Provider store={store}>
        <NotificationsContainer />
        <Box p={2}>
          <AdvertiserInvoices />
        </Box>
      </Provider>
    </DocumentTitle>
  );
}

export default AdvertiserInvoicesPage;
