// @flow

// Remove after Form update in framework, to work with deep arrays

export function onChangeItem<T>({
  handler, arr, index, fieldName, value,
}: {
  handler: (T[]) => void,
  arr: T[],
  index: number,
  fieldName: string,
  value: *,
}) {
  handler(arr.map((item: T, idx: number): T => {
    if (index === idx) {
      return { ...item, [fieldName]: value };
    }
    return item;
  }));
}

export function onAddItem<T>({
  handler, arr, emptyItem,
}: {
  handler: (T[]) => void,
  arr: T[],
  emptyItem: T,
}) {
  handler([...arr, emptyItem]);
}

export function onRemoveItem<T>({
  handler, arr, index,
}: {
  handler: (T[]) => void,
  arr: T[],
  index: number,
}) {
  handler(arr.filter((_, idx: number): boolean => index !== idx));
}
