// @flow
import {
  takeEvery,
  put,
  call,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import { UPDATE_AFFILIATE_SAGA } from "../../helpers/constants/manageAffiliate";
import {
  setAffiliateCurrentTablet,
  setPersonalInfoField,
  setAffiliateId,
  incrementSubmitCount,
  SetAffType, setErrors,
} from "../../actions/manageAffiliate";
import { getErrors, sanitizeData } from "../../actions/manageAffiliate/utils";
import { updateAffiliate } from "../../services/manageAffiliateApi";
import { setTabState } from "../../services/middlewares/tabSwitchConfirm";
import type { UpdateAffiliateSaga } from "../../actions/manageAffiliate";

export function* makeFetch(action: UpdateAffiliateSaga): Saga<void> {
  const { data, id } = action;
  const sanitized = sanitizeData(
    data,
    ["country", "manager", "declineReasonId", "accountId"],
    "edit"
  );

  try {
    const result = yield call(updateAffiliate, id, sanitized);
    yield put(setAffiliateId(result.data.id));
    yield put(SetAffType(sanitized.type));
    yield put(setAffiliateCurrentTablet(""));
    if (sanitized.status !== "Approve") {
      yield put(setPersonalInfoField({ field: "parentAffIdPayment", value: "" }));
    }
    yield put(incrementSubmitCount());
    yield put(addNotification({ message: `Affiliate ${result.data.id} successfully updated!`, severity: "success" }));
    setTabState(false);
    yield put(setErrors([]));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // need remove this after refactor
    if (error.response.data.errors) {
      const errors = getErrors(error.response.data.errors);
      yield put(setErrors(errors));
    }
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchUpdateAffiliate(): Saga<void> {
  // todo add event type here
  yield takeEvery(UPDATE_AFFILIATE_SAGA, (makeFetch: Function));
}
