// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type { Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import type { FormObject } from "@fas/ui-framework/lib/redux/reducers/form";
import { requestService } from "../axiosRequest";
import type { TemplateObjType, SubrateDataObjType } from "../../reducers/subrates";
import type { DropDownsOptions, IncomingSubrateFormType } from "../../reducers/subrates/types";
import type { DropDownObjType } from "../../reducers/dictionaries";
/* eslint-disable no-nested-ternary */

export const fetchSubratesList = ({
  filters,
  limit,
  sorting,
  page,
  headers,
  group,
  ignoreDefault,
}: {
  filters: Filters,
  limit: number,
  sorting: {
    current: string,
    direction: string,
  },
  page: number,
  headers: Array<string>,
  group?: Array<string>,
  ignoreDefault?: boolean,
}): PromiseResponse<mixed, { data: SubrateDataObjType[], count: number, page: number }> => requestService
  .post(environment.endpoints.post.getSubratesList, {
    filters,
    limit,
    sorting,
    page,
    headers,
    group,
    ignoreDefault,
  });

export const fetchSubratesDictionary = (): PromiseResponse<
  DropDownObjType
  > => requestService.get(environment.endpoints.get.getSubratesDictionary)
  .then(({ data: { data } }) => data);

type DropDownsParamsKey = string;

export const getSubratesDropdowns = (params: {[DropDownsParamsKey]: mixed}): PromiseResponse<mixed, {
  data: DropDownsOptions
}> => requestService.get(environment.endpoints.get.getSubratesDropdowns, { params }).then((data) => data.data.data);

export const getSubratesTemplates: (string) => PromiseResponse<mixed, Array<{
  id: string,
  name: string,
  headersOrder: Array<string>,
  author: string,
  department: string,
}>> = (param) => requestService.get(environment.endpoints.get.getSubratesTemplates, { params: { name: param } });

export const saveSubrateTemplateApi: (TemplateObjType) => PromiseResponse<mixed, {
  data: [],
  count: number,
  page: number,
}> = (params) => requestService
  .post(environment.endpoints.post.saveTemplateSubrates, params);

export const deleteSubratesApi: (Array<string>) => PromiseResponse<mixed> = (ids) => requestService
  .delete(environment.endpoints.delete.deleteSubrate, { data: { id: ids } });

export const saveMassAdjustApi = (
  params: { filters: Filters, data: { [string]: string | number }}
): PromiseResponse<{}> => requestService.put(
  environment.endpoints.put.bulkUpdateSubrates,
  params
);

/**
 * Get subrate by subrate id
 * @param {string} subrateId subrate id
 * @returns {Promise<AxiosResponse<IncomingSubrateFormType>>} subrate data
 */
export const fetchSubrateById = (subrateId: string): Promise<FormObject> => requestService
  .get(environment.endpoints.get.getSubrateById.replace("{id}", subrateId)).then((data: {data: { data: IncomingSubrateFormType}}) => {
    const {
      row: {
        id,
        affId,
        cpaCampaignId, // cpa offer id
        country,
        utmContent, // allow(null)
        s3, // allow(null)
        offer: {
          conversionType,
          name: cpaCampaignName,
        },
        affiliate: {
          name: affName,
        },
        payoutAmount,
        payoutAmountFirst,
        payoutAmountRepeats,
        shaveAmount, // allow(null),
        isActive, // valid(1, 0),
        increasedCommissionAmount,
        increasedCommissionThreshold,
        yesterdayConversionCount,
      },
    } = data.data.data;
    return {
      id,
      affiliate: { label: affName, value: affId },
      cpaCampaign: { label: cpaCampaignName, value: cpaCampaignId },
      country: country === "" ? null : country,
      utmContent,
      s3,
      conversionType,
      payoutAmount: payoutAmount === null ? "" : payoutAmount.toString(),
      payoutAmountFirst: payoutAmountFirst === null ? "" : payoutAmountFirst.toString(),
      payoutAmountRepeats: payoutAmountRepeats === null ? "" : payoutAmountRepeats.toString(),
      shaveAmount: shaveAmount === null ? "" : shaveAmount.toString(),
      isActive,
      increasedCommissionAmount: increasedCommissionAmount === null ? "" : increasedCommissionAmount.toString(),
      increasedCommissionThreshold: increasedCommissionThreshold === null ? "" : increasedCommissionThreshold.toString(),
      yesterdayConversionCount,
    };
  });

/**
 * Save subrate, update or create new subrate by subrate id
 * @param {SubrateFormDataType} subrate to save
 * @returns {Promise} Promise
 */
// eslint-disable-next-line complexity
export const fetchSaveSubrate = ({
  id,
  cpaCampaign,
  affiliate,
  increasedCommissionAmount,
  increasedCommissionThreshold,
  payoutAmount,
  payoutAmountFirst,
  payoutAmountRepeats,
  shaveAmount,
  // eslint-disable-next-line no-unused-vars
  yesterdayConversionCount,
  country,
  conversionType,
  ...restForm
}: FormObject): PromiseResponse<{}> => {
  const isRevshare = conversionType === "revshare";
  const subrate: FormObject = {
    ...restForm,
    conversionType,
    // eslint-disable-next-line no-nested-ternary
    country: country === null ? "" : country === "" ? null : country, // bad api contract, should be fixed
    affId: affiliate.value,
    cpaCampaignId: cpaCampaign.value,
    increasedCommissionAmount: isRevshare ? null : increasedCommissionAmount ? Number(increasedCommissionAmount) : null,
    // eslint-disable-next-line max-len
    increasedCommissionThreshold: isRevshare ? null : increasedCommissionThreshold ? Number(increasedCommissionThreshold) : null,
    payoutAmountFirst: isRevshare ? payoutAmountFirst ? Number(payoutAmountFirst) : null : null,
    payoutAmountRepeats: isRevshare ? payoutAmountRepeats ? Number(payoutAmountRepeats) : null : null,
    shaveAmount: shaveAmount === "" ? null : shaveAmount ? Number(shaveAmount) : "",
    payoutAmount: isRevshare ? null : payoutAmount ? Number(payoutAmount) : null,
  };

  if (id) {
    return requestService
      .put(environment.endpoints.put.updateSubrate.replace("{id}", id), subrate);
  }
  return requestService.post(environment.endpoints.post.saveSubrate, subrate);
};
