// @flow
/* eslint-disable import/max-dependencies */
import {
  takeEvery,
  call,
  put,
  select,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableSelections } from "@fas/ui-framework/lib/redux/selectors/table";
import type { DataItem } from "@fas/ui-framework/lib/redux/reducers/table";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { CHANGE_CPA_OFFER_STATUS_SAGA, CPA_OFFER_TABLE } from "../../helpers/constants/cpaoffer";
import type { ChangeCpaOfferStatusSaga } from "../../actions/cpaoffer";
import { changeCpaOfferStatus } from "../../services/cpaOffersApi";
import { getTemplateFilterDataSaga } from "../../actions/templates";
import { isOfferAllowedActivate } from "../../helpers/generators/generators";

export function* makeFetch(action: ChangeCpaOfferStatusSaga): Saga<void> {
  const { status }: ChangeCpaOfferStatusSaga = action;
  try {
    const selectedRows: Array<DataItem> = yield select(getTableSelections, CPA_OFFER_TABLE);
    const prepearedRows: Array<{ id: string, isActive: number }> = selectedRows
      .filter((item: DataItem) => isOfferAllowedActivate(item))
      .map((item: DataItem) => ({ id: item.id, isActive: Number(status) }));
    if (prepearedRows.length) {
      yield call(changeCpaOfferStatus, prepearedRows);
      yield put(addNotification({ message: "Status changed", severity: "success" }));
      yield put(getTemplateFilterDataSaga());
    }
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchChangeCpaOfferStatus(): Saga<void> {
  yield takeEvery(CHANGE_CPA_OFFER_STATUS_SAGA, (makeFetch: Function));
}
