// @flow
import React from "react";
import { connect } from "react-redux";
import {
  IconButton,
} from "@mui/material";
import {
  Check as CheckIcon,
  Edit as EditIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import {
  changeCurrentAdditionalPostback,
  changeIsAddAdditionalPostbackModalOpen,
  changePostbackStatusSaga,
  getPostbacksInfoSaga,
} from "../../../../../../../../actions/postbacks";

type Props = {};

const mapDispatchToProps = (dispatch) => ({
  changeStatus: (data) => dispatch(changePostbackStatusSaga(data)),
  getPostbacks: () => dispatch(getPostbacksInfoSaga()),
  handleOpenModal: (val) => dispatch(changeIsAddAdditionalPostbackModalOpen(val)),
  handleChangeCurrentAdditionalPostback: (val) => dispatch(changeCurrentAdditionalPostback(val)),
});

const Actions = ({
  row,
  changeStatus,
  handleOpenModal,
  handleChangeCurrentAdditionalPostback,
}: Props) => {
  const handleOpen = () => {
    handleChangeCurrentAdditionalPostback(row);
    handleOpenModal(true);
  };
  const handleChangeStatus = () => {
    const data = {
      id: row.id,
      isActive: +(!row.isActive),
    };
    changeStatus(data);
  };

  return (
    <div>
      <IconButton onClick={handleOpen} size="large" data-testid="editButton">
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleChangeStatus} size="large" data-testid="isActiveButton">
        {row.isActive === 1 ? (
          <ClearIcon />
        ) : (
          <CheckIcon />
        )}
      </IconButton>
    </div>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(Actions);
