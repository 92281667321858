// @flow
import { type Node } from "react";
import type { SearchComponentProps } from "@fas/ui-core/lib/SearchComponents";
import type { AttributeInputParams, FilterTypes } from "./types";
import { getAttributeInput, getSearchComponent } from "./utils";
import type { Dropdown } from "../../../reducers/templates";

// eslint-disable-next-line import/prefer-default-export
export class Attribute {
  constructor(name?: string) {
    if (name) {
      this.name = name;
    }
  }

  id: string | number;

  name: string;

  label: string;

  dictionaryName: string;

  isDefault: boolean = false;

  filterType: FilterTypes = "none";

  valueType: string | null = null;

  searchLimit: number = 2;

  limit: number = 1000;

  className: "smallTableCol" | "middleTableCol" | "longTableCol" | "veryLongTableCol" = "middleTableCol";

  sortable: boolean = true;

  searchable: boolean = true;

  reportName: string;

  dropdowns: Array<Dropdown> = [];

  render: (*) => *;

  getName(): string {
    return this.name;
  }

  getType(): FilterTypes {
    return this.filterType;
  }

  setDropdowns(dropdownsList: { [string]: Array<Dropdown> }) {
    this.dropdowns = dropdownsList[this.dictionaryName] || [];
  }

  setConfig({
    label,
    dictionaryName,
    isDefault,
    filterType,
    valueType,
    id,
    searchable,
    reportName,
  }: {
    label: string,
    dictionaryName: string,
    isDefault: boolean,
    filterType: FilterTypes,
    valueType: string | null,
    id: string | number,
    searchable: boolean,
    reportName: string,
  }) {
    this.id = id;
    this.label = label;
    this.dictionaryName = dictionaryName;
    this.isDefault = isDefault;
    this.filterType = filterType || this.filterType;
    this.valueType = valueType;
    this.reportName = reportName;
    this.searchable = searchable;
    this.renderAttributeInput = getAttributeInput(this.filterType).bind(this);
    this.SearchComponent = getSearchComponent(this.filterType)?.bind(this);
  }

  renderAttributeInput: (params: $Shape<AttributeInputParams>) => Node = getAttributeInput("none");

  SearchComponent: ((params: SearchComponentProps) => Node) | typeof undefined;
}
