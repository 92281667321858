// eslint-disable-next-line import/prefer-default-export
export const mapManageAffiliates = (state) => {
  const {
    personalInfo,
    lists,
    errors,
    affiliateCurrentTablet,
    affiliateId,
    loaders: { isPersonalInfoFetcing },
  } = state.manageAffiliate.toJS();
  return {
    personalInfo,
    lists,
    errors,
    affiliateCurrentTablet,
    affiliateId,
    isPersonalInfoFetcing,
  };
};
