// @flow
import {
  Map,
  type List as ListType,
  type Map as MapType,
} from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import { createLoadingSelector, type StoreWithLoading } from "@fas/ui-framework/lib/redux/selectors/loading";
import type { State as CreateAffiliateStoreState } from "../../pages/CreateAffiliate";
import type { State as ManageAffiliateStoreState } from "../../pages/ManageAffiliate";
import type { Loaders } from "../../reducers/manageAffiliate";
import type { PostbackValues } from "../../containers/PostbackRadioGroup/types/PostbackRadioGroup.types";

// Create Affiliate Section

export const getDropdownList: OutputSelector<
  CreateAffiliateStoreState | ManageAffiliateStoreState, string, Array<string | Object>
> = createSelector(
  (state: CreateAffiliateStoreState | ManageAffiliateStoreState, key: string): Array<string | Object> => state.manageAffiliate.getIn(["lists", key], []),
  (dropdownList: Array<string | Object>): Array<string | Object> => dropdownList
);

export const getManageAffiliateId: OutputSelector<CreateAffiliateStoreState, *, string> = createSelector(
  (state: CreateAffiliateStoreState): string => state.manageAffiliate.get("affiliateId"),
  (affiliateId: string): string => affiliateId
);

export const getManageAffiliateApiKey: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): Map<string, string> => state.manageAffiliate.getIn(["apiPage", "fields"]),
  (fields: Map<string, string>): { ipWhiteList: string, apiKey: string } => fields.toJSON()
);

export const getAffiliateId: OutputSelector<CreateAffiliateStoreState, *, string> = createSelector(
  (state: CreateAffiliateStoreState): string => state.manageAffiliate.get("affiliateId"),
  (value: string): string => value
);
export const getIsAddEventPostbackModalOpen: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): boolean => state.manageAffiliate.getIn(["postbackInfo", "isAddEventPostbackModalOpen"]),
  (val: boolean) => val
);

export const getIsAdditionalPostbackModalOpen: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): boolean => state.manageAffiliate.getIn(["postbackInfo", "isAdditionalPostbackModalOpen"]),
  (val: boolean) => val
);

export function getCurrentEventPostback(state: CreateAffiliateStoreState) {
  const data = state.manageAffiliate.getIn(["postbackInfo", "currentEventPostback"]);
  return data && data.toJS();
}

export function getCurrentAdditionalPostback(state: CreateAffiliateStoreState) {
  const data = state.manageAffiliate.getIn(["postbackInfo", "currentAdditionalPostback"]);
  return data && data.toJS();
}

export const getPostbackTable: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): * => state.manageAffiliate.getIn(["postbackInfo", "postbacksTable"]),
  (val: *) => val && val.toJS()
);

export const getPostbackInfoFields: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): * => state.manageAffiliate.getIn(["postbackInfo", "fields"]),
  (val: *) => val && val.toJS()
);

export const getManageAffiliateTableHeaders: OutputSelector<
  CreateAffiliateStoreState | ManageAffiliateStoreState, *, Object
> = createSelector(
  (state: CreateAffiliateStoreState | ManageAffiliateStoreState): MapType<string, mixed> => state.manageAffiliate.getIn(["table", "headers"]),
  (headers: MapType<string, mixed>): Object => headers.toObject()
);

export const getManageAffiliateTableTemplateList: OutputSelector<
  CreateAffiliateStoreState | ManageAffiliateStoreState, *, Array<{}>
> = createSelector(
  (state: CreateAffiliateStoreState | ManageAffiliateStoreState): ListType<{}> => state.manageAffiliate.getIn(["table", "templateList"]),
  (templateList: ListType<{}>): Array<{}> => templateList.toArray()
);

export const getManageAffiliatePersonalInfo: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): Map<string, *> => state.manageAffiliate.get("personalInfo"),
  (val: Map<string, *>): * => val.toJSON()
);

export const getManageAffiliatePaymentInfo: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): Map<string, *> => state.manageAffiliate.get("paymentInfo"),
  (val: Map<string, *>): * => val.toJSON()
);

export const getAffiliateType: OutputSelector<CreateAffiliateStoreState, *, *> = createSelector(
  (state: CreateAffiliateStoreState): string => state.manageAffiliate.get("affType"),
  (val: string): string => val
);

export const getLoading: OutputSelector<
  StoreWithLoading<string>, string, boolean
> = createLoadingSelector<string>();

// TODO: remove when all loaders will be in loading reducer
export const getLoaders: OutputSelector<ManageAffiliateStoreState, *, Loaders> = createSelector(
  (state: ManageAffiliateStoreState): MapType<string, boolean> => state.manageAffiliate.get("loaders"),
  (loaders: MapType<string, boolean>): Loaders => loaders.toObject()
);

export const getMessages: OutputSelector<
  ManageAffiliateStoreState, *, { status: string, msg: string }
> = createSelector(
  (state: ManageAffiliateStoreState): MapType<string, string> => state.manageAffiliate.get("messages"),
  (messages: MapType<string, string>): { status: string, msg: string } => messages.toObject()
);

export const selectPostbackInfoField: OutputSelector<
  CreateAffiliateStoreState,
  string,
  PostbackValues
> = createSelector(
  [
    (state: CreateAffiliateStoreState): Map<string, PostbackValues> => state.manageAffiliate.getIn(["postbackInfo", "fields"]),
    (_: CreateAffiliateStoreState, key: string): string => key,
  ],
  (fields: Map<string, PostbackValues>, key: string): PostbackValues => fields.get(key, "off")
);
