import type { FiltersType } from "./types";

// eslint-disable-next-line import/prefer-default-export
export const defaultSubrateOptimisationFilters: FiltersType = {
  utmContentSearch: "off",
  s3Search: "off",
  offerSite: "",
  offer: null,
  affiliate: null,
  country: [],
  platform: null,
  utmContent: [],
  s3: [],
};
