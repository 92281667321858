// @flow
import {
  SET_SORT_POSTBACKS,
  SET_FILTER_POSTBACKS,
  DELETE_FILTER_POSTBACKS,
  CLEANUP_TABLE_POSTBACKS,
  SET_SELECTED_ROWS_POSTBACKS,
  SET_POSTBACKS_LIST,
  SET_LISTS_POSBACKS,
  SET_FIELDS_POSTBACKS,
  SET_FIELD_POSTBACKS,
  GET_POSTBACK_INFO_SAGA,
  GET_LISTS_SAGA,
  CHANGE_POSTBACK_STATUS_SAGA,
  SAVE_POSTBACK_INFO_SAGA,
  SAVE_ADDITIONAL_POSTBACK_SAGA,
  CHANGE_CURRENT_ADDITIONAL_POSTBACK,
  CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN,
} from "../../helpers/constants/postbacks";
import type {
  SortType,
  FilterType,
  SelectedRowsType,
} from "../../reducers/manageAffiliate";

// Types
export type SetSortAction = {
  type: typeof SET_SORT_POSTBACKS,
  value: SortType,
};
export type SetFilterAction = {
  type: typeof SET_FILTER_POSTBACKS,
  value: FilterType,
};
export type DeleteFilterAction = {
  type: typeof DELETE_FILTER_POSTBACKS,
  field: string,
};
export type SetSelectedRowsAction = {
  type: typeof SET_SELECTED_ROWS_POSTBACKS,
  value: SelectedRowsType,
};
export type CleanupTableAction = {
  type: typeof CLEANUP_TABLE_POSTBACKS,
};
export type SetPostbacksListAction = {
  type: typeof SET_POSTBACKS_LIST,
  value: Array<Object>,
};
export type SetListsAction = {
  type: typeof SET_LISTS_POSBACKS,
  value: Object,
};
export type SetPostbackFieldsAction = {
  type: typeof SET_FIELDS_POSTBACKS,
  value: Object,
};
export type SetPostbackFieldAction = {
  type: typeof SET_FIELDS_POSTBACKS,
  value: Object,
};

export type GetPostbacksInfoSaga = ({
  type: typeof GET_POSTBACK_INFO_SAGA
})

export type GetListsSaga = ({
  type: typeof GET_LISTS_SAGA,
})

export type ChangePostbackStatusSaga = ({
  type: typeof CHANGE_POSTBACK_STATUS_SAGA,
  data: {
    id: string,
    isActive: boolean,
  }
})

export type SavePostbackInfoSaga = ({
  type: typeof SAVE_POSTBACK_INFO_SAGA,
})

export type SaveAdditionalPostbackSaga = ({
  type: typeof SAVE_ADDITIONAL_POSTBACK_SAGA,
  fields: { [string]: * },
})

export type ChangeCurrentAdditionalPostback = {
  type: typeof CHANGE_CURRENT_ADDITIONAL_POSTBACK,
  payload: *,
}

export type ChangeIsAddAdditionalPostbackModalOpen = {
  type: typeof CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN,
  payload: *,
}

// Actions
export const setSort = (
  value: SortType
): SetSortAction => ({
  type: SET_SORT_POSTBACKS,
  value,
});
export const setFilter = (
  value: FilterType
): SetFilterAction => ({
  type: SET_FILTER_POSTBACKS,
  value,
});
export const deleteFilter = (
  field: string
): DeleteFilterAction => ({
  type: DELETE_FILTER_POSTBACKS,
  field,
});
export const setSelectedRows = (
  value: SelectedRowsType
): SetSelectedRowsAction => ({
  type: SET_SELECTED_ROWS_POSTBACKS,
  value,
});
export const cleanupTable = (): CleanupTableAction => ({
  type: CLEANUP_TABLE_POSTBACKS,
});
export const setPostbacksList = (
  value: Object<Array>
): SetPostbacksListAction => ({
  type: SET_POSTBACKS_LIST,
  value,
});
export const setLists = (
  value: Object
): SetListsAction => ({
  type: SET_LISTS_POSBACKS,
  value,
});
export const setPostbackFields = (
  value: Object
): SetPostbackFieldsAction => ({
  type: SET_FIELDS_POSTBACKS,
  value,
});
export const setPostbackField = (
  value: Object
): SetPostbackFieldAction => ({
  type: SET_FIELD_POSTBACKS,
  value,
});

export const getPostbacksInfoSaga = (): GetPostbacksInfoSaga => ({
  type: GET_POSTBACK_INFO_SAGA
})

export const getListsSaga = (): GetListsSaga => ({
  type: GET_LISTS_SAGA,
});

export const changePostbackStatusSaga = (data): ChangePostbackStatusSaga => ({
  type: CHANGE_POSTBACK_STATUS_SAGA,
  data,
});

export const savePostbackInfoSaga = (): SavePostbackInfoSaga => ({
  type: SAVE_POSTBACK_INFO_SAGA,
});

export const saveAdditionalPostbackSaga = (fields: { [string]: * }): SaveAdditionalPostbackSaga => ({
  type: SAVE_ADDITIONAL_POSTBACK_SAGA,
  fields,
});

export const changeCurrentAdditionalPostback: (val: *) => ChangeCurrentAdditionalPostback = (val) => ({
  type: CHANGE_CURRENT_ADDITIONAL_POSTBACK,
  payload: val,
});

export const changeIsAddAdditionalPostbackModalOpen: (val: boolean) => ChangeIsAddAdditionalPostbackModalOpen = (val) => ({
  type: CHANGE_IS_ADDITIONAL_POSTBACK_MODAL_OPEN,
  payload: val,
});
