// @flow
/* eslint-disable import/max-dependencies */
import {
  debounce,
  call,
  select,
  put,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import {
  getTablePage,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
  getTableSelections,
} from "@fas/ui-framework/lib/redux/selectors/table";
import { setTableData, changeTableItemsTotalAmount, setTableSelection } from "@fas/ui-framework/lib/redux/actions/table";
import { setLoading } from "@fas/ui-framework/lib/redux/actions/loading";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse/parseResponse";
import type { Sorting, Filters } from "@fas/ui-framework/lib/redux/reducers/table";
import {
  GET_CPA_OFFER_LIST_SAGA,
  CPA_OFFER_TABLE,
} from "../../helpers/constants/cpaoffer";
import { getCpaOffersData } from "../../services/cpaOffersApi";
import type { TableDataType } from "../../reducers/cpaofferIndex/reducer";
import type { Offer } from "../../containers/CpaOfferIndex/types/CpaOfferIndex.types";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading(CPA_OFFER_TABLE, true));

    const page: number = yield select(getTablePage, CPA_OFFER_TABLE);
    const limit: number = yield select(getTablePageSize, CPA_OFFER_TABLE);
    const sorting: Sorting = yield select(getTableSorting, CPA_OFFER_TABLE);
    const filters: Filters = yield select(getTableFilters, CPA_OFFER_TABLE);
    const selections: Offer[] = yield select(getTableSelections, CPA_OFFER_TABLE);

    if (selections.length) {
      yield put(setTableSelection(CPA_OFFER_TABLE, []));
    }
    const options: {
      page: number,
      limit: number,
      filters: *,
      sorting: {
        current: string,
        direction: "asc" | "desc",
      },
      headers: Array<string>,
    } = {
      page,
      limit,
      filters: { ...filters },
      sorting: {
        current: Object.keys(sorting)[0],
        // $FlowFixMe
        direction: Object.values(sorting)[0],
      },
      headers: [
        "id",
        "isActive",
        "isValid",
        "department",
        "account",
        "name",
        "group",
        "location",
        "description",
        "restriction",
        "linkValidationType",
        "adverIDAttr",
        "adverIDValue",
        "dataProvider",
        "manager",
        "vertical",
        "niche",
        "country",
        "platform",
        "isExt",
        "conversionType",
        "currency",
        "payoutAmount",
        "globalCap",
        "globalCapDistributionMode",
        "globalCapLogic",
        "isPublic",
        "offerType",
        "createdBy",
        "createdAt",
        "updatedBy",
        "updatedAt",
        "isAvailableForAllGroups",
        "globalCapCounter", // this need for data in bracket
      ],
    };
    if (filters.isExt) {
      options.filters.isExt = Number(filters.isExt);
    }
    const {
      data: {
        data,
        count,
      },
    }: { data: { data: TableDataType[], count: number } } = yield call(getCpaOffersData, options);

    yield put(setTableData(CPA_OFFER_TABLE, data));
    yield put(changeTableItemsTotalAmount(CPA_OFFER_TABLE, count));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));

    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(CPA_OFFER_TABLE, false));
  }
}

export default function* watch(): Saga<void> {
  yield debounce(600, GET_CPA_OFFER_LIST_SAGA, (makeFetch: Function));
}
