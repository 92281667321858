// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications/actions";
import { GET_TOPOFFERS_DROPDOWN_LIST_SAGA } from "../../helpers/constants/topOffers";
import { fetchTopOffersDropdownLists } from "../../services/topOffersApi";
import { setDictionary } from "../../actions/dictionary";
import type { DropDownObjType } from "../../reducers/dictionaries";

export function* getTopOffersDropdownLists(): Saga<void> {
  try {
    const data: DropDownObjType = yield call(fetchTopOffersDropdownLists);
    yield put(setDictionary(data));
  }
  catch (error) {
    yield put(addNotification({ message: error.errorMessage || "Failed to fetch dropdown lists", severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetTopOffersDropdownLists(): Saga<void> {
  yield takeEvery(GET_TOPOFFERS_DROPDOWN_LIST_SAGA, getTopOffersDropdownLists);
}
