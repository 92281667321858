// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../axiosRequest";
import type { TemplateAttributeRaw, Dropdown } from "../../reducers/templates";

export const getTemplatesConfig: (reportName: string) => PromiseResponse<*> = (
  reportName
) => requestService
  .get(environment.endpoints.get.getTemplatesConfig.replace("{dataSourceName}", reportName))
  .then(({ data }) => data);

export const getTemplates: (reportName: string, { name: string, relationType: string }) => PromiseResponse<*> = (
  reportName, { name, relationType }
) => requestService
  .get(environment.endpoints.get.getTemplates.replace("{dataSourceName}", reportName), {
    params: {
      name: name || null,
      relationType: relationType || null,
    },
  })
  .then(({ data }) => data);

export const changeTemplateStatus: (reportName: string, id: number | string, relation: string) => PromiseResponse<*> = (
  reportName, id, relation
) => requestService.post(environment.endpoints.post.changeTemplateStatus
  .replace("{dataSourceName}", reportName)
  .replace("{id}", String(id))
  .replace("{relationType}", relation));

export const removeTemplate: (reportName: string, id: number | string) => PromiseResponse<*> = (
  reportName, id
) => requestService
  .delete(environment.endpoints.delete.deleteTemplate.replace("{dataSourceName}", reportName).replace("{id}", String(id)));

export const saveTemplate: (
  reportName: string, { name: string, attributes: Array<TemplateAttributeRaw>, sourceType: string }
) => PromiseResponse<*> = (reportName, template) => requestService
  .post(environment.endpoints.post.saveTemplate.replace("{dataSourceName}", reportName), template)
  .then(({ data }) => data);

export const updateTemplate: (
  reportName: string,
  id: number,
  template: { name: string, attributes: Array<TemplateAttributeRaw>, sourceType: "system" | "none" }
) => PromiseResponse<*> = (
  reportName, id, template
) => requestService
  .put(environment.endpoints.put.updateTemplate.replace("{dataSourceName}", reportName).replace("{id}", String(id)), template);

export const fetchSuggestDropdown: ({
  reportName: string, dictionaryName: string, searchString: string,
}) => Promise<Array<Dropdown>> = ({
  reportName, dictionaryName, searchString,
}) => requestService
  .get(environment.endpoints.get.getDropdownList.replace("{dictionary}", reportName), {
    params: {
      type: dictionaryName,
      filter: searchString,
    },
  })
  .then(({ data: { data: list } }) => list);

export const fetchDefaultTemplate: (reportName: string) => PromiseResponse<*> = (
  reportName
) => requestService
  .get(environment.endpoints.get.getDefaultTemplate.replace("{dataSourceName}", reportName))
  .then(({ data }) => data);

export const fetchTemplateFilterData: ({
  reportName: string,
  attributes: Array<{ name: string, filters: Array<string | number | boolean> }>,
  sorting: { current: string, direction: mixed },
  page: number,
  limit: number,
}) => PromiseResponse<*> = ({
  reportName,
  ...rest
}) => requestService
  .post(environment.endpoints.post.getTemplatesFilterData.replace("{dataSourceName}", reportName), rest)
  .then(({ data }) => data);
