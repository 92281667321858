// @flow
import { matchPath } from "react-router-dom";
import type { Match } from "react-router-dom";
import type { Node } from "react";

export type BreadcrumbsNodeType = {
  path: string,
  title: string | (params: Match) => string,
  redirect?: string | (params: Match) => string,
  parent?: BreadcrumbsNodeType;
  children?: BreadcrumbsNodeType[],
  component?: Node,
}

export type OptionalBreadcrumbsNodeType = BreadcrumbsNodeType | null;

export function getWithParentLinking(node: BreadcrumbsNodeType, parent?: BreadcrumbsNodeType): BreadcrumbsNodeType {
  // eslint-disable-next-line no-param-reassign
  node.parent = parent;
  if (node.children) {
    // eslint-disable-next-line no-param-reassign
    node.children = node.children.map((child: BreadcrumbsNodeType) => getWithParentLinking(child, node));
  }
  return node;
}

function findNode(node: BreadcrumbsNodeType, cb: (BreadcrumbsNodeType) => boolean): OptionalBreadcrumbsNodeType {
  const nodes: BreadcrumbsNodeType[] = [...(node.children || [])];

  while (nodes.length > 0) {
    const item: BreadcrumbsNodeType = nodes.shift();

    if (cb(item)) {
      return item;
    }

    if (item.children) {
      nodes.push(...item.children);
    }
  }
  return null;
}

export function findBreadcrumbsNodes(node: BreadcrumbsNodeType, path: string): BreadcrumbsNodeType[] {
  const child: OptionalBreadcrumbsNodeType = findNode(node, (item: BreadcrumbsNodeType) => {
    const match = matchPath(item.path, path);
    return Boolean(match);
  });
  const pathNodes: BreadcrumbsNodeType[] = [];

  let item: OptionalBreadcrumbsNodeType = child;
  while (item !== null) {
    if (item.title) {
      pathNodes.push(item);
    }
    item = item.parent || null;
  }
  return pathNodes.reverse();
}
