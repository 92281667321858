// @flow
import { Attribute } from "./defaultModels";

export class FactoryAttributes {
  generatedModels: { [string]: * } = { default: new Attribute() };

  getModel(name: string = "default"): Attribute {
    let model: Attribute = this.generatedModels[name];
    if (!model) {
      model = new Attribute(name);
      this.generatedModels[name] = model;
    }
    return model;
  }
}

export const FactoryModel: FactoryAttributes = new FactoryAttributes();

export * from "./defaultModels";
