import { getQueryStringValue, setQueryStringValue } from "./queryParamsHelper";

/**
 * Set query string in browser line
 * @example
 * // in component
 * useEffect(() => {
 *   setQueryFilter(filters);
 * }, [filters]);
 * @param {Object} filters all filters what we have
 * @param {Array<string>} availableKeys keys to get from filter and put to browser line
 * @returns {void}
 */
export function setQueryFilter<T extends { [key: string]: any }>(filters: T, availableKeys: string[]) {
  setQueryStringValue("filters", filterObject(filters, availableKeys));
}

/**
 * Get querystring object from browser line
 * @example
 * // in store
 * tables: initTableState({
 *    [TABLE]: {
 *      filters: {
 *        ...getQueryFilter(),
 *      },
 *    },
 *  }),
 * @param {Array<string>} availableKeys keys to get from browser line and put to filter
 * @returns {Object} patch filters to read from browser line
 */
export function getQueryFilter(availableKeys: string[]) {
  return filterObject(getQueryStringValue("filters", {}), availableKeys);
}

export function filterObject<T extends { [key: string]: any }>(object: T, keys: string[]): Partial<T> {
  return Object.keys(object).reduce((acc, key): Partial<T> => {
    const isValue: boolean = !!object[key] || object[key] === 0 || object[key] === false;
    if (keys.includes(key) && isValue) {
      return {
        ...acc,
        [key]: object[key],
      };
    }
    return acc;
  }, {} as Partial<T>);
}
