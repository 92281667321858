// @flow
import {
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import parseResponse from "@fas/ui-framework/lib/services/parseResponse";
import type { GetDropdownsByAffiliateTypeSaga } from "../../actions/manageAffiliate";
import { setLoader } from "../../actions/manageAffiliate";
import { GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA } from "../../helpers/constants/manageAffiliate";
import {
  getDropdownsByAffiliateType,
} from "../../services/manageAffiliateApi/manageAffiliateApi";
import { setDictionaryList } from "../../actions/manageAffiliate/actions";

export function* makeFetch(action: GetDropdownsByAffiliateTypeSaga): Saga<void> {
  const { payload }: GetDropdownsByAffiliateTypeSaga = action;
  try {
    yield put(setLoader({
      key: `is${payload.filter}Loading`,
      value: true,
    }));
    const { data } = yield call(getDropdownsByAffiliateType, payload);
    yield put(setDictionaryList({ key: payload.filter, value: data.data }));
    yield put(setLoader({
      key: `is${payload.filter}Loading`,
      value: false,
    }));
  }
  catch (error) {
    yield put(addNotification({ message: parseResponse(error), severity: "error" }));
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* watchGetDropdownsByAffiliateTypeSaga(): Saga<void> {
  yield takeEvery(GET_DROPDOWNS_BY_AFFILIATE_TYPE_SAGA, (makeFetch: Function));
}
