/* eslint-disable import/max-dependencies */
import {
  addNotification, setTableData, TableProps, useTable,
} from "@fas/ui-framework";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch } from "../../store";
import { SEARCH_INT_APPLICATIONS_TABLE_KEY } from "../../../../helpers/constants/affiliates";
import useTenantId from "../../useTenantId";
import api from "../../../../services/api";
import type { ArrayElement } from "../../types";
import { userService } from "../../../../services/user";
import useTableQueryFilter from "../../utils/useTableQueryFilter";
import filter from "../../utils/filter";

export type FiltersType = {
  affiliateId?: string,
  status?: string,
  isExt?: string,
}

export const defaultApplicationIntFilters: FiltersType = {
  affiliateId: "",
  status: "",
  isExt: "",
};

export default function useSearchIntApplications() {
  type TData = ArrayElement<Awaited<ReturnType<typeof api.getOneAffiliateApplications>>["applications"]>;
  const dispatch = useDispatch();
  const tableProps = useTable<TData, FiltersType>(SEARCH_INT_APPLICATIONS_TABLE_KEY);
  const affiliateId = (tableProps.filters.affiliateId || "").trim();
  const enabled = affiliateId?.length > 4;
  const tenantId = useTenantId();
  const havePermissionToAllAffiliate = userService.can(["getOneAffiliateApplications"]);
  const {
    isLoading,
    isRefetching,
    data,
  } = useQuery(
    [SEARCH_INT_APPLICATIONS_TABLE_KEY, tenantId, affiliateId],
    async () => {
      if (havePermissionToAllAffiliate) {
        return api.getOneAffiliateApplications(tenantId, affiliateId);
      }
      const res = await api.getOneMyAffiliateApplications(tenantId, affiliateId);

      return {
        applications: res,
        manager: null,
        assistant: null,
        assistantWarningMessage: null,
        allowedToMutate: true,
      };
    },
    {
      enabled,
      onError: (e: { errorMessage: string }) => {
        dispatch(setTableData(SEARCH_INT_APPLICATIONS_TABLE_KEY, []));
        dispatch(addNotification({ message: e.errorMessage || "Something went wrong...", severity: "error" }));
      },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(setTableData(SEARCH_INT_APPLICATIONS_TABLE_KEY, data.applications));
    }
  }, [data, dispatch]);

  useTableQueryFilter(SEARCH_INT_APPLICATIONS_TABLE_KEY, defaultApplicationIntFilters);

  return {
    ...tableProps,
    isDataAvailable: !!data?.applications.length,
    data: enabled ? filter(tableProps.data, {
      status: tableProps.filters.status,
      isExt: tableProps.filters.isExt,
    }) : [],
    isLoading: isLoading && enabled,
    isRefetching,
    manager: data?.manager,
    assistant: data?.assistant,
    assistantWarningMessage: data?.assistantWarningMessage,
    allowedToMutate: data?.allowedToMutate,
  } as TableProps<TData, FiltersType> & {
    isRefetching: boolean,
    manager: string | null,
    assistant: string | null,
    assistantWarningMessage: string | null,
    allowedToMutate: boolean,
    isDataAvailable: boolean,
  };
}
